import { AutocompleteScrollerComponent, getBaseAutocompleteScrollerProvider } from '../../autocomplete-scroller.component';

import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SearchService } from '@app/core/services/search/base.search.service';
import { BuildProgrammeLogicService } from '@app/logic/build-programme';
import { TradeTypesLogicService } from '@app/logic/trade-types/trade-types.logic.service';
import { takeOne } from 'cb-hub-lib';
import { Observable, map } from 'rxjs';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';
import { ITradeTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-trade-type-autocomplete-scroller',
    templateUrl: '../../autocomplete-scroller.component.html',
    providers: [
        ...getBaseFormComponentDirectiveProvider(TradeTypeAutocompleteScrollerComponent),
        getBaseAutocompleteScrollerProvider(TradeTypeAutocompleteScrollerComponent),
        SearchService,
    ]
})
export class TradeTypeAutocompleteScrollerComponent
    extends AutocompleteScrollerComponent<ITradeTypeDto> {
    public results: ITradeTypeDto[] = [];
    private resultsLoaded = false;

    @Input() public readonly isParentOnly: boolean = false;

    constructor(
        public readonly buildProgrammeLogicService: BuildProgrammeLogicService,
        public readonly dialog: MatDialog,
        public readonly searchService: SearchService,
        protected readonly tradeTypeLogicService: TradeTypesLogicService
    ) {
        super(dialog, searchService);
        this.searchService.doSearch = this.doSearch;
    }

    public doSearch = (query: string): Observable<any[]> => {
        return this.getResults()
            .pipe(takeOne(), map((results) => {
                const filtered = results.filter(x =>
                    (this.displayWith(x as any) || '').toLowerCase().indexOf(query.toLowerCase()) > -1
                );
                return filtered;
            }));
    };

    public displayWith(tradeType: any): string {
        if (tradeType) {
            if (tradeType.parentLabel) {
                return `${tradeType.parentLabel} - ${tradeType.label}`;
            } else {
                return tradeType.label;
            }
        }
    }

    private getResults(): Observable<ITradeTypeDto[]> {
        return new Observable<ITradeTypeDto[]>((subscriber) => {
            if (!this.resultsLoaded) {
                this.resultsLoaded = true;
                const resultsObservable = this.tradeTypeLogicService.$getList();
                resultsObservable
                    .subOnce(x => {
                        if (this.isParentOnly) {
                            this.results = x.filter(tt => !tt.parentId);
                        } else {
                            this.results = x.filter(tt => !!tt.parentId);
                        }
                        subscriber.next(this.results);
                        subscriber.complete();
                    });
            } else {
                subscriber.next(this.results);
                subscriber.complete();
            }
        });
    }

    // overriding the parent Method
    public onScroll(): void {
        return;
    }
}
