<div class="start-center flex-row">
    <h3 class="flex mat-title">CostX Export</h3>
    <button mat-raised-button
            color="primary"
            (click)="downloadRateLibrariesExport()">
        <mat-icon>file_download</mat-icon>
        &nbsp;Download All
    </button>
</div>
<cb-table *ngIf="loaded"
          class="cb-margin-top"
          label="Address Regions"
          [stringColumns]="displayedColumns"
          [loaded]="true">
    <tbody cbTableBody>
        <tr *ngFor="let mappedItem of costxRateLibraries">
            <cb-td-text [value]="mappedItem.name"></cb-td-text>
            <cb-td-text [value]="mappedItem.locationPath"></cb-td-text>
            <td>
                {{mappedItem.lastExportedUserDate}}
            </td>
            <td cbTableShrinkCol
                cbTableStickyCol="end"
                class="button-row">
                <button mat-icon-button
                        matTooltip="Download"
                        (click)="downloadRateLibraryExport(mappedItem)">
                    <mat-icon>file_download</mat-icon>
                </button>
                <button *ngIf="mappedItem.canManage"
                        mat-icon-button
                        matTooltip="Edit"
                        (click)="editRateLibrary(mappedItem)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button *ngIf="mappedItem.canManage"
                        mat-icon-button
                        matTooltip="Delete"
                        (click)="deleteRateLibrary(mappedItem)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </tr>
        <tr *ngIf="canManage">
            <td colspan="3"></td>
            <td cbTableShrinkCol
                cbTableStickyCol="end">
                <button mat-icon-button
                        matTooltip="Create New"
                        (click)="addRateLibrary()">
                    <mat-icon>add</mat-icon>
                </button>
            </td>
        </tr>
    </tbody>
</cb-table>
