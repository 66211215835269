import { Injectable, Injector } from '@angular/core';
import { BaseLogicService } from '../base/base-logic.service';
import { ClientAccountMappedItem } from './client-account.mapped';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { IClientAccountDto } from './interfaces/i.client-account.dto';
import { IClientAccountLogicService } from './interfaces/i.client-account.logic.service';
import { IClientAccountMappedItem } from './interfaces/i.client-account.mapped';
import { IClientAccountSearchParams } from './interfaces/i.client-account-search-params';
import { INoteMappedItem } from '@app/logic/notes';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { Observable } from 'rxjs';
import { ISkinnyLeadDto } from '@app/logic/leads';
import { IProjectDetailsDto, ISkinnyClientSaleDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { IContactDto } from '../contacts/interfaces/i.contact.dto';

@Injectable()
export class ClientAccountLogicService
    extends BaseLogicService<IClientAccountDto, IClientAccountMappedItem>
    implements IClientAccountLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'clientaccounts',
            ClientAccountMappedItem
        );
    }

    public getJobs(accountId: number): Observable<ISkinnyClientSaleDto[]> {
        return this.$http
            .get(`${this.$baseUri}/${accountId}/jobs`);
    }

    public getThirdPartySales(accountId: number): Observable<ISkinnyClientSaleDto[]> {
        return this.$http
            .get(`${this.$baseUri}/${accountId}/thirdpartysales`);
    }

    public getProjects(accountId: number): Observable<IProjectDetailsDto[]> {
        return this.$http
            .get(`${this.$baseUri}/${accountId}/projects`);
    }

    public getLeads(accountId: number): Observable<ISkinnyLeadDto[]> {
        return this.$http
            .get(`${this.$baseUri}/${accountId}/leads`);
    }

    public getNotesList(accountId: number): Observable<INoteMappedItem[]> {
        return this.$http
            .get(`${this.$baseUri}/${accountId}/notes`);
    }

    public getContacts(accountId: number): Observable<IContactDto[]> {
        return this.$http
            .get(`${this.$baseUri}/${accountId}/contacts`);
    }

    public search(params: IClientAccountSearchParams, ignoreEmptyQueries = true): Observable<ISearchResult<IClientAccountDto>> {
        if (ignoreEmptyQueries && (params.query == null || params.query.trim().length < 1)) {
            return;
        }
        return this.$http
            .get<ISearchResult<IClientAccountDto>>(`${this.$baseUri}/search`, params);
    }

    public $getSearchList(params: IClientAccountSearchParams): Observable<ISearchResult<IClientAccountDto>> {
        return this.$http.get<ISearchResult<IClientAccountDto>>(`${this.$baseUri}/search`, params);
    }

    public updatePortalLogin(params: { id: string; username: string; password: string }): Observable<IClientAccountMappedItem> {
        return this.$http
            .post<IClientAccountMappedItem>(`${this.$baseUri}/portalLogin/update`, params);
    }

    public activatePortalAccess(params: { activate: boolean; accountId: number }): Observable<boolean> {
        return this.$http
            .post<boolean>(`${this.$baseUri}/portalLogin/activate`, params);
    }
}
