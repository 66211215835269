<cb-dialog dialogHeading="{{data.selectedContractTypeId > 0 ? 'Edit' : 'Add'}}  Build Type">
    <form #buildTypeForm="ngForm">
        <cb-select label="Lot Contract Type"
                   name="contractType"
                   [(ngModel)]="selectedContractTypeId"
                   [required]="true"
                   [options]="availableContractTypes"
                   (change)="filterAvailableBuildTypes()"></cb-select>
        <cb-select label="Build Type"
                   name="buildType"
                   [(ngModel)]="selectedBuildTypeId"
                   [required]="true"
                   [options]="availableBuildTypes"
                   [disabled]="!selectedContractTypeId"></cb-select>
        <cb-checkbox label="Default"
                     name="default"
                     [(ngModel)]="isDefaultPaymentBuildType"></cb-checkbox>
        <cb-info-message *ngIf="defaultTemplate && defaultTemplate.id !== data.paymentTemplateId"
                         [message]="'Currently set as a default on ' + defaultTemplate.label + ' template. Setting this as default will override the existing default' "
                         [icon]="'info_circle'"></cb-info-message>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="!selectedBuildTypeId || !selectedContractTypeId"
                (cbClick)="save()"
                [clickLimit]="1">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
