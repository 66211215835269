import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { IBuildActivitiesLogicService } from './interfaces/i.build-activities.logic.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IBuildActivityMappedItem } from './interfaces/i.build-activity.mapped';
import { BuildActivityMappedItem } from './build-activity.mapped';
import { IBuildActivityDto, ISkinnyBuildActivityDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { ISkinnyBuildActivitySearchParams } from '@app/logic/build-activities/interfaces/I.skinny-build-activity-search-params';

@Injectable()
export class BuildActivitiesLogicService extends BaseLogicService<IBuildActivityDto, IBuildActivityMappedItem> implements IBuildActivitiesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'buildactivities',
            BuildActivityMappedItem,
        );
    }

    public getSkinnyList(): Observable<ISkinnyBuildActivityDto[]> {
        return this.$http
            .get(`${this.$baseUri}/all`);
    }

    public $getSearchList(params: ISkinnyBuildActivitySearchParams): Observable<ISkinnyBuildActivityDto[]> {
        if (!params.query) {
            delete params.query;
        }
        if (!params.activityCode) {
            delete params.activityCode;
        } else {
            params.c = params.activityCode;
            delete params.activityCode;
        }
        if (!params.activityName) {
            delete params.activityName;
        } else {
            params.n = params.activityName;
            delete params.activityName;
        }

        return this.$http
            .get<Array<ISkinnyBuildActivityDto>>(`${this.$baseUri}`, params);
    }

    // TODO: any should be typed to be a file
    public generateReport(): Observable<any> {
        return this.$http.download(`/${this.$baseUri}/report`, 'Generating Build Activities Report...');
    }

    // TODO: any should be typed to be a file
    public generateActivityReport(buildActivityId: number): Observable<any> {
        return this.$http.download(`/${this.$baseUri}/${buildActivityId}/report`, 'Generating Build Activity Report...');
    }
}
