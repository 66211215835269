<cb-dialog dialogHeading="Brochure Upload">
    <cb-drag-and-drop-document [(documentEntityMappedItem)]="data.documentEntity">
    </cb-drag-and-drop-document>
    <footer>
        <button mat-raised-button
                [disabled]="!data.documentEntity.document.uploadedFile"
                color="primary"
                (click)="save()">
            Upload
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
