import { BaseMappedItem } from '@app/logic/base/base-mapped-item';
import { IPaymentTemplatesLogicService } from './interface/i.payment-templates.logic.service';
import { IPaymentTemplateDto, IPaymentTemplateStageDto } from './interface/i.payment-template.dto';
import { IPaymentTemplateMappedItem } from './interface/i.payment-template.mapped';
import { DtoProp } from '../base/dto-prop.decorator';
import { IPaymentTemplateBuildTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';

export class PaymentTemplateMappedItem
    extends BaseMappedItem<IPaymentTemplateDto, IPaymentTemplateMappedItem, IPaymentTemplatesLogicService>
    implements IPaymentTemplateMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public label: string;
    @DtoProp public isActive: boolean;
    @DtoProp public stages: IPaymentTemplateStageDto[];
    @DtoProp public buildTypes: IPaymentTemplateBuildTypeDto[];

    constructor(
        sourceData: IPaymentTemplateDto,
        logicService: IPaymentTemplatesLogicService
    ) {
        super(sourceData, logicService, PaymentTemplateMappedItem, {
            stages: [],
            buildTypes: [],
            isActive: true,
        });
    }
}
