import { CLIENT_SALE_STATUS_ENUM, IBusinessAccountDetailsDto, ISkinnyClientSaleDto, LOT_JOB_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { Component, Input, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { ClientAccountLogicService } from '@app/logic/client-account/client-account.logic.service';
import { IClientAccountMappedItem } from '@app/logic/client-account/interfaces/i.client-account.mapped';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { PermissionsPermissions } from '@app/core/permissions';
import { switchMap } from 'rxjs';
import { toPromise } from 'cb-hub-lib';

@Component({
    selector: 'cb-client-account-sales',
    templateUrl: './client-account-sales.component.html',
    styleUrls: ['./client-account-sales.component.scss']
})
export class ClientAccountSalesComponent implements OnInit {

    @Input() public account: IClientAccountMappedItem;

    public LOT_JOB_STATUS_ENUM = LOT_JOB_STATUS_ENUM;
    public CLIENT_SALE_STATUS_ENUM = CLIENT_SALE_STATUS_ENUM;

    public readonly displayedColumns: string[] = [
        'saleNumber',
        'lotJobNumber',
        'buildType',
        'clientSaleStatus',
        'street',
        'suburb',
        'lotStatus',
        'buildingConsultant',
        'actions'
    ];

    public jobs: ISkinnyClientSaleDto[];
    public thirdPartySales: ISkinnyClientSaleDto[];

    public jobsLoaded = false;
    public thirdPartySalesLoaded = false;

    constructor(
        public readonly navigationService: NavigationService,
        public readonly dialogRef: MatDialog,
        protected readonly clientAccountLogicService: ClientAccountLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly route: ActivatedRoute,
    ) { }

    public ngOnInit(): void {

        toPromise(this.route.params.pipe(
            switchMap((params: { id: number }) => {
                return this.clientAccountLogicService.getJobs(params.id);
            })
        )).then((mappedItem: ISkinnyClientSaleDto[]) => {
            this.jobs = mappedItem;
            this.jobsLoaded = true;
        });

        toPromise(this.route.params.pipe(
            switchMap((params: { id: number }) => {
                return this.clientAccountLogicService.getThirdPartySales(params.id);
            })
        )).then((mappedItem: ISkinnyClientSaleDto[]) => {
            this.thirdPartySales = mappedItem;
            this.thirdPartySalesLoaded = true;
        });

    }

    public viewClientAccount(account: IBusinessAccountDetailsDto, event: MouseEvent): void {
        this.navigationService.navigate([`clientsales/view/${account.id}/details`], event);
    }
}
