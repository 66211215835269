<div #infiniteScrollContainer
     class="cb-padding-left-2x cb-padding-right-2x cb-padding-bottom-2x cb-padding-top flex-row flex">
    <mat-card class="flex-row flex-100">
        <div class="cb-margin-right-3x flex-col flex-33">
            <div>
                <h4 class="cb-title">Contact Info</h4>
            </div>
            <mat-divider [inset]="true"
                         class="cb-margin-bottom"> </mat-divider>
            <cb-display-value-new label="Name"
                                  [value]="getFullName()"></cb-display-value-new>
            <cb-display-value-new label="Contact Status"
                                  [value]="CONTACT_STATUS_ENUM[contact.status]"></cb-display-value-new>
            <div class="flex-row">
                <cb-display-value-new label="Email"
                                      [value]="contact.email"></cb-display-value-new>
                <span class="flex"></span>
                <mat-icon *ngIf="contact.preferredContact === 4"
                          color="accent"
                          title="Preferred Contact Method">check_circle</mat-icon>
            </div>
            <div class="flex-row">
                <cb-display-value-new label="Home Phone"
                                      [value]="contact.phoneHome"></cb-display-value-new>
                <span class="flex"></span>
                <mat-icon *ngIf="contact.preferredContact === 1"
                          color="accent"
                          title="Preferred Contact Method">check_circle</mat-icon>
            </div>
            <div class="flex-row">
                <cb-display-value-new label="Work Phone"
                                      [value]="contact.phoneWork"></cb-display-value-new>
                <span class="flex"></span>
                <mat-icon *ngIf="contact.preferredContact === 2"
                          color="accent"
                          title="Preferred Contact Method">check_circle</mat-icon>
            </div>
            <div class="flex-row">
                <cb-display-value-new label="Mobile Phone"
                                      [value]="contact.phoneMobile"></cb-display-value-new>
                <span class="flex"></span>
                <mat-icon *ngIf="contact.preferredContact === 3"
                          color="accent"
                          title="Preferred Contact Method">check_circle</mat-icon>
            </div>
            <cb-display-value-new label="Preferred Contact Method"
                                  [value]="CONTACT_METHOD_ENUM[contact.preferredContact]"></cb-display-value-new>
        </div>
        <div class="cb-margin-left-3x cb-margin-right-3x flex-col flex-33">
            <h4 class="cb-title">Physical Address</h4>
            <mat-divider [inset]="true"
                         class="cb-margin-bottom"> </mat-divider>
            <p>{{ contact.physicalAddress?.street }}</p>
            <p>
                {{ contact.physicalAddress?.suburb }}
            </p>
            <p>
                {{ contact.physicalAddress?.city }}
                {{ contact.physicalAddress?.postCode }}
            </p>
            <p>
                {{ contact.physicalAddress?.regionName }}
            </p>
            <h4 class="cb-title cb-padding-top-3x">Marketing</h4>
            <mat-divider [inset]="true"
                         class="cb-margin-bottom"> </mat-divider>
            <cb-display-value-new label="Gender"
                                  [value]="getGender()"></cb-display-value-new>
            <cb-display-value-new label="Age range"
                                  [value]="AGE_RANGES_ENUM[contact.ageRange]"></cb-display-value-new>
            <mat-checkbox name="getsSurveys"
                          [(ngModel)]="contact.getsSurveys">Participate in surveys</mat-checkbox>
            <mat-checkbox name="getsNewsletter"
                          [(ngModel)]="contact.getsNewsletter">Receive e-newsletter</mat-checkbox>
            <mat-checkbox name="isStaffMember"
                          [(ngModel)]="contact.isStaffMember">Staff Member</mat-checkbox>
        </div>
        <div class="cb-margin-left-3x cb-margin-right-3x flex-col flex-33">
            <h4 class="cb-title">Postal Address</h4>
            <mat-divider [inset]="true"
                         class="cb-margin-bottom"> </mat-divider>
            <p>{{ postalAddress?.street }}</p>
            <p>
                {{ postalAddress?.suburb }}
            </p>
            <p>
                {{ postalAddress?.city }}
                {{ postalAddress?.postCode }}
            </p>
            <p>
                {{ postalAddress?.regionName }}
            </p>
        </div>
    </mat-card>
</div>
