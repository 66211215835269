<h3 class="mat-title cb-margin cb-margin-left-3x"
    *ngIf="isBuildingConsultant">{{getTitle()}}</h3>
<mat-tab-group [(selectedIndex)]="selectedTabIndex"
               [cbTabRoute]="LEADS_ROUTE"
               defaultTabId="details">
    <mat-tab label="Details"
             cbTabRouteId="details">
        <form #leadEditForm="ngForm">
            <cb-lead-view *cbWaitFor="mappedItem"
                          (leadChanged)="updateLead($event)"
                          [mappedItem]="mappedItem"
                          [canEdit]="canEditLead()">
            </cb-lead-view>
        </form>
    </mat-tab>
    <mat-tab label="Tasks"
             cbTabRouteId="tasks"
             *ngIf="canViewTasks()">
        <ng-template matTabContent>
            <cb-tasks class="flex-col flex"
                      *cbWaitFor="mappedItem"
                      [entity]="mappedItem"
                      [edit]="mappedItem.isHotOrCold()"
                      [permissions]="taskPermissions">
            </cb-tasks>
        </ng-template>
    </mat-tab>
    <mat-tab label="Notes"
             cbTabRouteId="notes"
             *ngIf="canViewNotes()">
        <ng-template matTabContent>
            <cb-notes *cbWaitFor="mappedItem"
                          [entity]="mappedItem"
                          [edit]="true"
                          [permissions]="notePermissions">
            </cb-notes>
        </ng-template>
    </mat-tab>
    <mat-tab label="Documents"
             cbTabRouteId="documents"
             *ngIf="canViewDocuments()">
        <ng-template matTabContent>
            <cb-document *cbWaitFor="mappedItem"
                         [entity]="mappedItem"
                         [permissions]="documentPermissions"
                         [includeLinked]="true"
                         [systemArea]="systemArea">
            </cb-document>
        </ng-template>
    </mat-tab>
    <mat-tab label="History"
             cbTabRouteId="history"
             *ngIf="canViewHistory()">
        <ng-template matTabContent>
            <cb-history-list *cbWaitFor="mappedItem"
                             [entityId]="mappedItem?.id"
                             [entity]="HISTORY_SEARCH_ENTITY.Lead">
            </cb-history-list>
        </ng-template>
    </mat-tab>
</mat-tab-group>
