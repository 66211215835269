<mat-sidenav-container>
    <mat-sidenav-content>
        <div class="flex-col">
            <mat-tab-group *cbWaitFor="mappedItem$$ | async"
                           (selectedIndexChange)="onTabChanged($event);"
                           [selectedIndex]="selectedTabIndex"
                           cbTabUrlParam="cbTabRouteId"
                           [cbTabRoute]="LOTS_ROUTE"
                           class="cs-indent-right-tabs flex">
                <mat-tab label="Lot Summary"
                         cbTabRouteId="summary"
                         cbNestedTabLevels="0">
                    <ng-template matTabContent>
                        <cb-lot-summary *cbWaitFor="mappedItem$$ | async"
                                        [lotMappedItem]="mappedItem">
                        </cb-lot-summary>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Units"
                         [disabled]="!mappedItem?.hasUnits"
                         cbTabRouteId="units"
                         cbNestedTabLevels="0">
                    <ng-template matTabContent>
                        <cb-lot-units-list *cbWaitFor="mappedItem$$ | async"
                                           [lotMappedItem]="mappedItem"></cb-lot-units-list>
                    </ng-template>
                </mat-tab>
                <span *ngIf="!mappedItem?.hasUnits">
                    <mat-tab label="Leads"
                             cbTabRouteId="leads"
                             cbNestedTabLevels="0">
                        <ng-template matTabContent>
                            <cb-lot-lead-list *cbWaitFor="mappedItem$$ | async"
                                              [lotMappedItem]="mappedItem"></cb-lot-lead-list>
                        </ng-template>
                    </mat-tab>
                </span>
                <span *ngIf="!mappedItem?.hasUnits">
                    <mat-tab label="Specification"
                             cbTabRouteId="spec"
                             cbNestedTabLevels="1">
                        <ng-template matTabContent>
                            <cb-lot-specification-area *cbWaitFor="mappedItem$$ | async"
                                                       [lotMappedItem]="mappedItem"></cb-lot-specification-area>
                        </ng-template>
                    </mat-tab>
                </span>
                <span *ngIf="!mappedItem?.hasUnits">
                    <mat-tab label="Design"
                             cbTabRouteId="design"
                             cbNestedTabLevels="1">
                        <ng-template matTabContent>
                            <cb-lot-design-area *cbWaitFor="mappedItem$$ | async"
                                                [lotMappedItem]="mappedItem"
                                                (reloadLotMappedItem)="lotMappedItemChanged()"></cb-lot-design-area>
                        </ng-template>
                    </mat-tab>
                </span>
                <span *ngIf="!mappedItem?.hasUnits">
                    <mat-tab label="Changes"
                             cbTabRouteId="changes"
                             cbNestedTabLevels="1">
                        <ng-template matTabContent>
                            <cb-lot-change-area *cbWaitFor="mappedItem$$ | async"
                                                [lotMappedItem]="mappedItem"></cb-lot-change-area>
                        </ng-template>
                    </mat-tab>
                </span>
                <mat-tab *ngIf="canViewCostSummary"
                         label="Costs"
                         cbTabRouteId="costs"
                         cbNestedTabLevels="1">
                    <ng-template matTabContent>
                        <cb-lot-costs-area *ngIf="!mappedItem?.hasUnits"
                                           [lotMappedItem]="mappedItem"
                                           (reloadLotMappedItem)="lotMappedItemChanged()">
                        </cb-lot-costs-area>
                        <cb-lot-block-costs *ngIf="mappedItem?.hasUnits"
                                            [lot]="mappedItem">
                        </cb-lot-block-costs>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Build"
                         cbTabRouteId="build"
                         cbNestedTabLevels="1">
                    <ng-template matTabContent>
                        <cb-lot-build-area *cbWaitFor="mappedItem$$ | async"
                                           [lotMappedItem]="mappedItem$$ | async"
                                           (reloadLotMappedItem)="lotMappedItemChanged()"></cb-lot-build-area>
                    </ng-template>
                </mat-tab>
                <mat-tab *ngIf="canSeeARTab() && !mappedItem?.hasUnits"
                         label="AR"
                         cbTabRouteId="ar"
                         cbNestedTabLevels="0">
                    <ng-template matTabContent>
                        <cb-lot-ar [lotMappedItem]="mappedItem$$ | async"></cb-lot-ar>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Notes"
                         cbTabRouteId="notes"
                         cbNestedTabLevels="0">
                    <ng-template matTabContent>
                        <cb-notes *cbWaitFor="mappedItem$$ | async"
                                      [entity]="mappedItem"
                                      [edit]="true"
                                      [permissions]="lotPermissions.getNotePermissions()"></cb-notes>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Documents"
                         cbTabRouteId="documents"
                         cbNestedTabLevels="0">
                    <ng-template matTabContent>
                        <cb-document *cbWaitFor="mappedItem$$ | async"
                                     [entity]="mappedItem"
                                     [permissions]="lotPermissions.getDocumentPermissions()"
                                     [includeLinked]="true"
                                     [systemArea]="systemArea"
                                     [enableCheckbox]="true"
                                     (documentUploaded)="lotDocumentUploaded($event)"></cb-document>
                    </ng-template>
                </mat-tab>
                <span *ngIf="!mappedItem?.hasUnits">
                    <mat-tab label="Questions"
                             cbTabRouteId="questions"
                             cbNestedTabLevels="0">
                        <ng-template matTabContent>
                            <cb-lot-questions *cbWaitFor="mappedItem$$ | async"
                                              [teamCode]="TEAM_CODES_CONST.DesignTeamKey"
                                              [lotId]="mappedItem?.id">
                            </cb-lot-questions>
                        </ng-template>
                    </mat-tab>
                </span>
                <mat-tab label="History"
                         cbTabRouteId="history"
                         cbNestedTabLevels="0">
                    <ng-template matTabContent>
                        <cb-history-list *cbWaitFor="mappedItem$$ | async"
                                         [entityId]="mappedItem?.id"
                                         [entity]="HISTORY_SEARCH_ENTITY.Lot"></cb-history-list>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
            <button mat-mini-fab
                    color="accent"
                    class="collapse-button"
                    (click)="drawer.toggle()">
                <mat-icon> {{ drawer.opened ? 'chevron_right' : 'chevron_left '}}</mat-icon>
            </button>
        </div>
    </mat-sidenav-content>
    <mat-sidenav #drawer
                 mode="side"
                 position="end"
                 opened>
        <cb-lot-sidebar *cbWaitFor="mappedItem$$ | async"
                        [lotMappedItem]="mappedItem"
                        (lotMappedItemChange)="lotMappedItemChanged()"></cb-lot-sidebar>
    </mat-sidenav>
</mat-sidenav-container>
