import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import {MatLegacyCheckboxChange as MatCheckboxChange} from '@angular/material/legacy-checkbox';

@Component({
    selector: 'select-all-option',
    templateUrl: './select-check-all.component.html',
    styleUrls: ['./select-check-all.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class SelectCheckAllComponent {
    @Input() public selectElement: AbstractControl;
    @Input() public updateSelectElement: Function;
    @Input() public values = [];
    @Input() public text = 'All';

    @Output() public checkAllCheckboxChangedEventEmitter = new EventEmitter<AbstractControl>();

    public isChecked(): boolean {
        return this.selectElement.value
            && this.values.length
            && this.selectElement.value.length === this.values.length;
    }

    public isIndeterminate(): boolean {
        return this.selectElement.value
            && this.values.length
            && this.selectElement.value.length
            && this.selectElement.value.length < this.values.length;
    }

    public onChange(event): void {
        this.toggleSelection(event);
        this.checkAllCheckboxChangedEventEmitter.emit(this.selectElement);
        this.updateSelectElement();
    }

    public toggleSelection(change: MatCheckboxChange): void {
        if (change.checked) {
            this.selectElement.setValue(this.values);
        } else {
            this.selectElement.setValue([]);
        }
    }
}
