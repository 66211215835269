<form #searchForm="ngForm">
    <div infiniteScroll
         [infiniteScrollDistance]="1"
         [fromRoot]="true"
         [infiniteScrollContainer]="'div#mainContainer'"
         (scrolled)="doSearch()"
         class="cb-margin start flex-col">
        <mat-card class="start flex-col">
            <div class="cb-margin flex-row flex">
                <cb-input class="flex"
                          type="text"
                          label="Type in Name, Legal Name or ABM Number to Search"
                          [(ngModel)]="userCacheItem.data.query"
                          name="search"></cb-input>
            </div>
        </mat-card>
        <div class="flex-parent flex-wrap xs-column">
            <div class="account-card md-33 lg-25 sm-50 xl-20 xs-100 flex-33"
                 *ngFor="let account of searchResults">
                <mat-card>
                    <mat-card-title>
                        {{account.name}}
                    </mat-card-title>
                    <mat-card-content>
                        <div class="flex-row">
                            <div class="flex-col">
                                <cb-display-value-new label="Account Type"
                                                      [value]="account.accountType"></cb-display-value-new>
                                <cb-display-value-new label="ABM code"
                                                      [value]="account.abmCode"></cb-display-value-new>
                                <cb-display-value-new label="Account Status"
                                                      [value]="CLIENT_ACCOUNT_STATUS_ENUM[account.status]">
                                </cb-display-value-new>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-divider [inset]="true">
                    </mat-divider>
                    <mat-card-actions class="flex">
                        <span class="flex"></span>
                        <button mat-stroked-button
                                color="accent"
                                (click)="viewAccount(account, $event)">
                            <mat-icon class="material-icons">
                                pageview
                            </mat-icon>
                            &nbsp;View
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
</form>
