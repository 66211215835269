<div class="cb-padding flex-col flex">
    <mat-card *ngIf="!isPopUp"
              class="cb-margin-bottom-2x start-center flex-row">
        <form class="cb-margin-right-3x flex-col flex">
            <cb-input label="Keywords"
                      name="searchText"
                      [(ngModel)]="searchText"
                      (change)="queryUpdate.next(null)"></cb-input>
        </form>
        <div class="flex-col">
            <button mat-raised-button
                    color="accent"
                    [disabled]="!hasUnread"
                    (click)="markAllAsRead()">
                <mat-icon>markunread</mat-icon>
                &nbsp;Mark All Read
            </button>
        </div>
    </mat-card>
    <mat-card class="scroll-container"
              [cbInfiniteScroll]="listContainer"
              [fetch]="fetchResults()"
              [queryUpdated]="queryUpdate"
              [enabled]="searchEnabled$"
              [(results)]="results"
              [(currentPage)]="currentPage">
        <div #listContainer
             class="list-container flex-wrap xs-column flex">
            <mat-list class="flex">
                <mat-list-item *ngFor="let notification of results">
                    <mat-icon class="notification-icon cb-margin-left-2x"
                              [ngClass]="{'mat-accent' : !notification.isRead}">{{getNotifcationIcon(notification)}}</mat-icon>
                    <div class="flex-col flex"
                         (click)="goToItem(notification)">
                        <h3 class="mat-subheading-2"
                            [ngStyle]="{'font-weight': notification.isRead ? '400' : 'bold'}">
                            {{notification.subject}}
                        </h3>
                        <span class="mat-body-1">{{notification.body}}</span>
                        <p class="mat-caption cb-grey">
                            <cb-user-date-time-new [label]="'Received at'"
                                                   [date]="notification.date"></cb-user-date-time-new>
                        </p>
                    </div>
                    <button *ngIf="!isPopUp"
                            mat-icon-button
                            color="accent"
                            class="cb-margin-right-2x"
                            [disabled]="notification.isRead"
                            (click)="markAsRead(notification)">
                        <mat-icon>markunread</mat-icon>
                    </button>
                </mat-list-item>
                <mat-list-item *ngIf="!results?.length">
                    <mat-icon>notifications_none</mat-icon>
                    <div class="mat-list-item-text flex-col">
                        <p *ngIf="showUnreadOnly"
                           class="mat-body-1">You have no unread notifications.</p>
                        <p *ngIf="!showUnreadOnly"
                           class="mat-body-1">You have no notifications.</p>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </mat-card>
</div>
