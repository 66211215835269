<cb-dialog dialogHeading="Variation Changes">
    <mat-slide-toggle *ngIf="variationLines?.length > 0"
                      class="cb-margin-top cb-margin-right-2x"
                      [(ngModel)]="reorderingEnabled">Enable Reordering
    </mat-slide-toggle>
    <form class="flex-col flex"
          #createChangeForm="ngForm">
        <cb-table [hideNoResultsMessage]="true"
                  [loaded]="true"
                  maxHeightOffset="310">
            <thead cbTableHead>
                <tr>
                    <td>Change Number</td>
                    <td>Description</td>
                    <td>Amount</td>
                    <td></td>
                </tr>
            </thead>
            <tfoot cbTableFoot>
                <tr cbTableFooterRow
                    class-="cb-table-footer-row">
                    <td colspan="2"></td>
                    <td *ngIf="mappedItem.isExtraToClient">
                        <strong> {{mappedItem.variationTotal | cbCurrency:'addGst'}} (Inc Gst)</strong>
                    </td>
                    <td *ngIf="!mappedItem.isExtraToClient">
                        <strong> {{mappedItem.variationTotal | cbCurrency}} </strong>
                    </td>
                    <td></td>
                </tr>
            </tfoot>
            <tbody cbTableBody
                   [dragula]="dragulaGroup"
                   [(dragulaModel)]="dragulaModel">
                <tr cbTableRow
                    *ngFor="let variationLine of variationLines"
                    [ngClass]="variationLineStyle(variationLine)">
                    <td>{{variationLine.changeRecordNumber}}</td>
                    <td>{{variationLine.changeDetails}}</td>
                    <td *ngIf="mappedItem.isExtraToClient">
                        <strong>{{variationLine.lineCost | cbCurrency:'addGst'}} (Inc Gst)</strong>
                    </td>
                    <td *ngIf="!mappedItem.isExtraToClient">
                        <strong>{{variationLine.lineCost | cbCurrency}}</strong>
                    </td>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <button *ngIf="!variationLineIsRemoved(variationLine)"
                                mat-icon-button
                                (click)="removeVariationLine(variationLine)">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <button *ngIf="variationLineIsRemoved(variationLine)"
                                mat-icon-button
                                (click)="keepVariationLine(variationLine)">
                            <mat-icon>undo</mat-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </cb-table>
        <div class="cb-margin-top flex-row layoutGap-16 flex">
            <mat-form-field style="width: 100%;">
                <mat-select placeholder="Unassigned Change Records"
                            (selectionChange)="onUnassignedChangeRecordSelected($event?.value?.changeRecordId)">
                    <mat-option *ngFor="let change of unassignedChangeRecords"
                                [value]="change">
                        #{{ change.changeNumber }} - {{ change.changeRecordDetails}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-icon-button
                    (click)="addVariationLine()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    (cbClick)="saveChanges()"
                    [disabled]="saveDisabled()"> Save
            </button>
            <button mat-raised-button
                    (click)="
                    cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
