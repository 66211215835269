import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IHouseAndLandSearch } from '@app/core/services/user-cache/user-cache-areas';
import { garageTypesSelect } from '@app/views/standard-plans/const/garage.constants';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@Component({
    selector: 'app-house-and-land-search-filter',
    templateUrl: './house-and-land-search-filter.component.html',
    styleUrls: ['./house-and-land-search-filter.component.scss']
})
export class HouseAndLandSearchFilterComponent implements OnInit {
    @Input() public costs: number[];
    @Input() public floorAreas: number[];
    @Input() public landAreas: number[];
    @Input() public searchData: IHouseAndLandSearch;

    @Output() public emitEvent = new EventEmitter();

    public searchQueryUpdate = new Subject<string | number>();

    public optionsCost: Options;
    public optionsCostLoaded = false;
    public minCost: number;
    public maxCost: number;

    public optionsFloorArea: Options;
    public optionsFloorAreaLoaded = false;
    public minFloorArea: number;
    public maxFloorArea: number;

    public optionsLandArea: Options;
    public optionsLandAreaLoaded = false;
    public minLandArea: number;
    public maxLandArea: number;

    public optionsBedRoom: Options = {
        floor: 0,
        ceil: 10,
        step: 1,
        showTicks: false,
        translate: (value: number, label: LabelType): string => {
            switch (label) {
                case LabelType.Low:
                    if (value === 0) {
                        return 'Bedroom: Any';
                    }
                    return `Bedroom: ${value}`;
                default:
                    return value.toString();
            }
        }
    };

    public optionsBathRoom: Options = {
        floor: 0,
        ceil: 10,
        step: 1,
        showTicks: false,
        translate: (value: number, label: LabelType): string => {
            switch (label) {
                case LabelType.Low:
                    if (value === 0) {
                        return 'Bathroom: Any';
                    }
                    return `Bathroom:  ${value}`;
                default:
                    return value.toString();
            }
        }
    };

    public optionsLiving: Options = {
        floor: 0,
        ceil: 10,
        step: 1,
        showTicks: false,
        translate: (value: number, label: LabelType): string => {
            switch (label) {
                case LabelType.Low:
                    if (value === 0) {
                        return 'Living: Any';
                    }
                    return `Living: ${value}`;
                default:
                    return value.toString();
            }
        }
    };

    public garageTypes = garageTypesSelect;


    constructor(
    ) {
        this.searchQueryUpdate.pipe(
            debounceTime(800),
            distinctUntilChanged())
            .subscribe((value) => {
                this.doSearch();
            });
    }


    public ngOnInit(): void {
        this.setoptionsCost();
        this.setoptionsFloorArea();
        this.setoptionsLandArea();
    }

    public ngOnDestroy(): void {
        this.emitEvent.unsubscribe();
    }

    public setoptionsCost(): void {

        this.minCost = this.costs[0];
        this.maxCost = this.costs[1];

        this.optionsCost = {
            floor: this.costs[0],
            ceil: this.costs[1],
            showTicks: false,
            translate: (value: number, label: LabelType): string => {
                if (value > 0 && Number.isFinite(value) && this.costs[0] !== this.costs[1]) {
                    switch (label) {
                        case LabelType.Low:
                            return `Min : $${value}`;
                        case LabelType.High:
                            return `Max : $${value}`;
                        default:
                            return `$${value}`;
                    }
                } else {
                    return '';
                }
            }
        };

        this.optionsCostLoaded = true;
    }

    public setoptionsFloorArea(): void {

        this.minFloorArea = this.floorAreas[0];
        this.maxFloorArea = this.floorAreas[1];

        this.optionsFloorArea = {
            floor: this.minFloorArea,
            ceil: this.maxFloorArea,
            showTicks: false,
            translate: (value: number, label: LabelType): string => {
                if (value > 0 && Number.isFinite(value) && this.floorAreas[0] !== this.floorAreas[1]) {
                    switch (label) {
                        case LabelType.Low:
                            return `Min : ${value}m²`;
                        case LabelType.High:
                            return `Max : ${value}m²`;
                        default:
                            return `$${value}m²`;
                    }
                } else {
                    return '';
                }
            }
        };

        this.optionsFloorAreaLoaded = true;
    }

    public setoptionsLandArea(): void {

        this.minLandArea = this.landAreas[0];
        this.maxLandArea = this.landAreas[1];

        this.optionsLandArea = {
            floor: this.landAreas[0],
            ceil: this.landAreas[1],
            showTicks: false,
            translate: (value: number, label: LabelType): string => {
                if (value > 0 && Number.isFinite(value) && this.landAreas[0] !== this.landAreas[1]) {
                    switch (label) {
                        case LabelType.Low:
                            return `Min : ${value}m²`;
                        case LabelType.High:
                            return `Max : ${value}m²`;
                        default:
                            return `$${value}m²`;
                    }
                } else {
                    return '';
                }
            }
        };

        this.optionsLandAreaLoaded = true;
    }

    public doSearch = (): void => {
        this.emitEvent.emit(true);
    };

    public landAreaChange($event: number): void {
        this.searchData.landAreaMin = this.minLandArea;
        this.searchData.landAreaMax = this.maxLandArea;
        this.searchQueryUpdate.next($event);
    }

    public floorAreaChange($event: number): void {
        this.searchData.floorAreaMin = this.minFloorArea;
        this.searchData.floorAreaMax = this.maxFloorArea;
        this.searchQueryUpdate.next($event);
    }

    public priceChange($event: number): void {
        this.searchData.priceMin = this.minCost;
        this.searchData.priceMax = this.maxCost;
        this.searchQueryUpdate.next($event);
    }

    public clearSearch = (): void => {
        this.searchData.floorAreaMin = this.floorAreas[0];
        this.searchData.floorAreaMax = this.floorAreas[1];
        this.minFloorArea = this.floorAreas[0];
        this.maxFloorArea = this.floorAreas[1];

        this.searchData.landAreaMin = this.landAreas[0];
        this.searchData.landAreaMax = this.landAreas[1];
        this.minLandArea = this.landAreas[0];
        this.maxLandArea = this.landAreas[1];

        this.searchData.priceMin = this.costs[0];
        this.searchData.priceMax = this.costs[1];
        this.minCost = this.costs[0];
        this.maxCost = this.costs[1];

        this.searchData.numberOfBathrooms = null;
        this.searchData.numberOfBedrooms = null;
        this.searchData.numberOfGarages = null;
        this.searchData.numberOfLivingRooms = null;
        this.searchData.locationId = null;

        this.searchData.query = null;

        this.emitEvent.emit(true);
    };
}
