import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenericQuestionsLogicService } from '@app/logic/generic-questions';
import { CbDialogService } from '../../dialog/cb-dialog.service';
import { GenericQuestionEditDialogComponent } from '../generic-question-edit-dialog/generic-question-edit-dialog.component';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { IGenericQuestionDto, SystemAreaEnumId } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-generic-question-list',
    templateUrl: './generic-question-list.component.html',
    styleUrls: ['./generic-question-list.component.scss']
})
export class GenericQuestionListComponent implements OnInit {

    @Input() public edit: boolean;
    @Input() public lotId: number;
    @Input() public entityId: number;
    @Input() public systemArea: SystemAreaEnumId;
    @Output() public questionCountChange = new EventEmitter<number>();
    @Input() public childItems: any[];
    @Input() public childItemLabel: string;
    @Input() public childSystemArea: SystemAreaEnumId;
    @Input() public questionNumber: number;

    public questions: any[];

    constructor(
        public readonly questionLogic: GenericQuestionsLogicService,
        public readonly navigation: NavigationService,
        public readonly cbDialog: CbDialogService,
    ) { }

    public ngOnInit(): void {
        this.loadQuestionFromQueryParams();
        this.loadQuestions();
    }

    public loadQuestionFromQueryParams(): void {
        const params = this.navigation.getQueryParams();
        if (params?.qnsId) {
            this.questionNumber = params?.qnsId as number;
        }
    }

    public createQuestion(): void {
        this.cbDialog
            .open(GenericQuestionEditDialogComponent, {
                minWidth: '40%',
                data: {
                    genericQuestion: this.questionLogic.$createMappedItem({
                        entityId: this.entityId,
                        systemArea: this.systemArea,
                        childSystemArea: this.childSystemArea,
                    } as IGenericQuestionDto),
                    childItemLabel: this.childItemLabel,
                    childItems: this.childItems,
                    childSystemArea: this.childSystemArea,
                    entityId: this.entityId,
                    lotId: this.lotId,
                    systemArea: this.systemArea
                }
            })
            .afterClosed()
            .subOnce(() => {
                this.loadQuestions();
            });
    }

    private readonly loadQuestions = (): void => {
        this.questionLogic.getByEntity(this.entityId, this.systemArea)
            .subOnce((results) => {
                this.questions = results;
                this.questionCountChange.emit(this.questions?.length ?? 0);
            });
    };

}
