import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { PermissionsPermissions } from '@app/core/permissions';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { IBuildActivityMappedItem } from '@app/logic/build-activities/interfaces/i.build-activity.mapped';
import { DocumentTypesLogicService } from '@app/logic/document-types/document-types.logic.service';
import { SupplyTypesLogicService } from '@app/logic/supply-types';
import { TradeTypesLogicService } from '@app/logic/trade-types/trade-types.logic.service';
import { UsersLogicService } from '@app/logic/users/users.logic.service';
import { Debounce } from '@app/shared/decorators/debounce.decorator';
import { FormMode } from '@app/shared/enums/form';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { BUILD_ACTIVITY_REQUIRED_LEVEL_ENUM, IDocumentTypeDto, ISupplyTypeDto, ITradeTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { IPromised } from 'cb-hub-lib';
import { Observable, map, startWith } from 'rxjs';
import { SelectListItem } from 'src/app/core/services/enum/enum.service';

export interface BuildActivitiesExtraSearchFilters {
    activityCode: string;
    activityName: string;
}

@Component({
    selector: 'build-activity-trade-and-supply-types',
    templateUrl: './build-activity-trade-and-supply-types.component.html',
    styleUrls: ['./build-activity-trade-and-supply-types.component.scss'],
    providers: [
        UsersLogicService,
        DocumentTypesLogicService,
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class BuildActivityTradeAndSupplyTypesComponent implements OnInit {
    public formMode: FormMode;
    public buildActivityRequiredLevels: Array<SelectListItem>;
    public isView: () => boolean;
    public isEdit: () => boolean;
    public triStateSelector = false;
    public cannotAddTradeOrSupplyType : boolean = false;
    @ViewChild(MatTable, {}) public table: MatTable<any>;
    @Input() public buildActivity: IBuildActivityMappedItem;
    @Input() public buildActivityForm: NgForm;
    public filteredOptions: Observable<ITradeTypeDto[]>;
    public filteredOptionsSupply: Observable<ISupplyTypeDto[]>;
    public taskAnalysisFormRequired: boolean;
    public tradeTypeAutocomplete = new UntypedFormControl();
    public supplyTypeAutocomplete = new UntypedFormControl();
    public selectedTradeType: ITradeTypeDto;
    public selectedSupplyType: ISupplyTypeDto;
    public tradeTypes: ITradeTypeDto[];
    public supplyTypes: ISupplyTypeDto[];
    public isSupplierTypesEnabled: boolean;
    public availableDocTypes: IPromised<IDocumentTypeDto[]>;
    public availableDocTypessupply: IPromised<IDocumentTypeDto[]>;

    constructor(
        public readonly navigationService: NavigationService,
        public readonly currentUser: CurrentUserService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: MatDialog,
        private readonly tradeTypesLogicService: TradeTypesLogicService,
        private readonly supplyTypesLogicService: SupplyTypesLogicService,
        private readonly documentTypesLogicService: DocumentTypesLogicService,
        private readonly documentSupplyLogicService: DocumentTypesLogicService,
        protected readonly featureToggleService: FeatureToggleStatesService
    ) {
        this.formMode = FormMode.View;
        this.buildActivityRequiredLevels = BUILD_ACTIVITY_REQUIRED_LEVEL_ENUM.toSelectList();
        featureToggleService.init().then(() => {
            this.isSupplierTypesEnabled = featureToggleService.isSupplierTypesEnabled;
        });
    }

    public ngOnInit(): void {
        this.availableDocTypes = this.documentTypesLogicService.loadDocTypesLookupForTrades(this.buildActivity?.tradeTypes);
        this.tradeTypesLogicService.$getList().subOnce(tts => {
            this.tradeTypes = tts.filter(tt => !!tt.parentId);
            this.filteredOptions = this.tradeTypeAutocomplete.valueChanges
                .pipe(
                    startWith(''),
                    map(value => typeof value === 'string' ? value : value.label),
                    map(label => label ? this._filterTrades(label) : this.tradeTypes.slice())
                );
        });
        this.availableDocTypessupply = this.documentSupplyLogicService.loadDocTypesLookupForSupplyTypes(this.buildActivity?.supplyTypes);
        if (this.isSupplierTypesEnabled) {
            this.supplyTypesLogicService.$getList().subOnce(sts => {
                this.supplyTypes = sts.filter(st => !!st.parentId);
                this.filteredOptionsSupply = this.supplyTypeAutocomplete.valueChanges
                    .pipe(
                        startWith(''),
                        map(value => typeof value === 'string' ? value : value.label),
                        map(label => label ? this._filterSupply(label) : this.supplyTypes.slice())
                    );
            });
        }
        this.setTypeFlags();
        this.updateCannotAddTradeOrSupplyType();
    }

    public setTypeFlags(): void {
        if (this.buildActivity?.tradeTypesList?.length > 0 || this.selectedTradeType?.isActive) {
            this.triStateSelector = true;
        }
        else if (this.buildActivity?.supplyTypesList?.length > 0  || this.selectedSupplyType?.isActive) {
            this.triStateSelector = false;
        }
        else {
            this.triStateSelector = null;
        }
    }

    private _filterTrades(label: string): ITradeTypeDto[] {
        const filterValue = label.toLowerCase();
        return this.tradeTypes.filter(option => option.label.toLowerCase().indexOf(filterValue) === 0)
            .concat(this.tradeTypes.filter(option => option.parentLabel.toLowerCase().indexOf(filterValue) === 0));
    }

    private _filterSupply(label: string): ISupplyTypeDto[] {
        const filterValue = label.toLowerCase();
        return this.supplyTypes.filter(option => option.label.toLowerCase().indexOf(filterValue) === 0)
            .concat(this.supplyTypes.filter(option => option.parentLabel.toLowerCase().indexOf(filterValue) === 0));
    }

    public displayFnTrade(tradeType?: ITradeTypeDto): string | undefined {
        return tradeType ?
            tradeType.label ?
                tradeType.parentLabel ? `${tradeType.parentLabel} - ${tradeType.label}` :
                    tradeType.label :
                tradeType.parentLabel ? tradeType.parentLabel :
                    undefined :
            undefined;
    }

    public displayFnSupply(supplyType?: ISupplyTypeDto): string | undefined {
        return supplyType ?
            supplyType.label ?
                supplyType.parentLabel ? `${supplyType.parentLabel} - ${supplyType.label}` :
                    supplyType.label :
                supplyType.parentLabel ? supplyType.parentLabel :
                    undefined :
            undefined;
    }

    @Debounce(500)
    public tradeTypesChanged(): void {
        this.availableDocTypes = this.documentTypesLogicService.loadDocTypesLookupForTrades(this.buildActivity?.tradeTypesList?.map(x => x.id));
        this.setTypeFlags();
        this.updateCannotAddTradeOrSupplyType();
    }

    @Debounce(500)
    public supplyTypesChanged(): void {
        this.availableDocTypessupply = this.documentTypesLogicService.loadDocTypesLookupForSupplyTypes(this.buildActivity?.supplyTypesList?.map(x => x.id));
        this.setTypeFlags();
        this.updateCannotAddTradeOrSupplyType();
    }

    public isBuildActivityATradeType(): boolean {
        return this.triStateSelector;
    }

    public isBuildActivityASupplyType(): boolean {
        return this.triStateSelector===false && this.isSupplierTypesEnabled;
    }

    private isOneOrMoreTradeOrSupplyType(): boolean {
        return this.buildActivity?.tradeTypesList?.length > 0 || this.buildActivity?.supplyTypesList?.length > 0;
    }

    private updateCannotAddTradeOrSupplyType(): void {
        this.cannotAddTradeOrSupplyType = this.isOneOrMoreTradeOrSupplyType();
    }
}
