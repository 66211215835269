import { IProductDto, IRateDto } from '../products/interfaces/i.product.dto';
import { IPurchaseOrderLineAttributeDto, IPurchaseOrderLineDto } from './interfaces/i.purchase-order-line.dto';
import { IIdAndLabelDto, UNIT_OF_MEASURE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { remove } from 'lodash';
import { Observable, tap } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IClonedMappedItemSetup } from '../base/interfaces/i.cloned-mapped-item';
import { IPurchaseOrderLineMappedItem } from './interfaces/i.purchase-order-line.mapped';
import { IPurchaseOrderMappedItem } from './interfaces/i.purchase-order.mapped';
import { IPurchaseOrdersLogicService } from './interfaces/i.purchase-orders.logic.service';

export class PurchaseOrderLineMappedItem
    extends BaseMappedItem<IPurchaseOrderLineDto, IPurchaseOrderLineMappedItem, any>
    implements IPurchaseOrderLineMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public quantity: number;
    @DtoProp public gstApplies: boolean;
    @DtoProp public code: string;
    @DtoProp public productCode: string;
    @DtoProp public isNoteLine: boolean;
    @DtoProp public description: string;
    @DtoProp public uomId: number;
    @DtoProp public uom: string;
    @DtoProp public rate: number;
    @DtoProp public lineSubtotal: number;
    @DtoProp public gstRate: number;
    @DtoProp public gstAmount: number;
    @DtoProp public lineTotal: number;
    @DtoProp public sortOrder: number;
    @DtoProp public purchaseOrderId: number;
    @DtoProp public offeringId: number;
    @DtoProp public deletedDate: string;
    @DtoProp public isDeleted: boolean;
    @DtoProp public notes: string;
    @DtoProp public internalNotes: string;
    @DtoProp public commentsForSupplier: string;
    @DtoProp public costNature: IIdAndLabelDto;
    @DtoProp public reason: IIdAndLabelDto;
    @DtoProp public extraToSupplier: IIdAndLabelDto;
    @DtoProp public attributes: IPurchaseOrderLineAttributeDto[];
    @DtoProp public costToBeDetermined: boolean;
    @DtoProp public createdDate: string;

    constructor(
        sourceData: IPurchaseOrderLineDto,
        logicService: IPurchaseOrdersLogicService,
        public readonly parent: IPurchaseOrderMappedItem,
    ) {
        super(sourceData, logicService, PurchaseOrderLineMappedItem);
    }

    protected $cloneConstructor(): IClonedMappedItemSetup<IPurchaseOrderLineDto, IPurchaseOrderLineMappedItem, IPurchaseOrdersLogicService> {
        return new PurchaseOrderLineMappedItem(
            this.$getMappedDtoItem(),
            this.$logicService,
            this.parent,
        ) as IClonedMappedItemSetup<IPurchaseOrderLineDto, IPurchaseOrderLineMappedItem, IPurchaseOrdersLogicService>;
    }

    public lineSubtotalCalc(): number {
        if (this.rate == null || this.quantity == null) {
            return;
        }
        return this.rate * this.quantity;
    }

    public setProductDetails(
        selectedProduct: IProductDto,
        searchText: string,
        defaultRate?: IRateDto,
    ): void {
        if (selectedProduct) {
            this.offeringId = selectedProduct.id;
            const uomDisplay = (selectedProduct as any).uomDisplay;
            this.uom = uomDisplay != null ? uomDisplay : UNIT_OF_MEASURE_ENUM[selectedProduct.uom];
            this.description = selectedProduct.name;
            this.uomId = UNIT_OF_MEASURE_ENUM.toLookup().find(uomItem => uomItem.label.toLowerCase() === this.uom.toLowerCase())?.id;
            this.productCode = selectedProduct.code;
            if (defaultRate?.value) {
                this.rate = defaultRate.value;
                this.code = defaultRate.sku;
            } else {
                // The supplier has no rate defined for this product, so it must
                // get set to a zero rate
                this.rate = 0;
            }
        } else {
            this.offeringId = undefined;
            if (!this.isNoteLine) {
                this.description = searchText;
            }
        }
    }

    public $save(): Observable<IPurchaseOrderLineDto> {
        if (this.id <= 0) {
            this.sortOrder = (this.parent.mappedLines || []).length + 1;
        }
        this.uom = (this.uom || '').trim();


        return this.$logicService
            .saveLineItem(this.parent.id, this.$getMappedDtoItem())
            .pipe(
                tap(result => this.$updateThisAndOriginal(result)),
                tap(() => {
                    const parent = this.parent.$getMappedDtoItem();
                    parent.purchaseOrderLines = this.parent.mappedLines.map(x => x.$getMappedDtoItem());
                    if (parent.purchaseOrderLines.findIndex(x => x.id === this.id) < 0) {
                        parent.purchaseOrderLines.push(this.$getMappedDtoItem());
                    }
                    this.parent.$updateThisAndOriginal(parent);
                })
            );
    }

    public delete(): Observable<boolean> {
        return this.$logicService
            .deleteLineItem(this.purchaseOrderId, this.id)
            .pipe(
                tap(result => {
                    if (result) {
                        const parent = this.parent.$getMappedDtoItem();
                        parent.purchaseOrderLines = this.parent.mappedLines.map(x => x.$getMappedDtoItem());
                        remove(parent.purchaseOrderLines, item => item.id === this.id);
                        this.parent.$updateThisAndOriginal(parent);
                    }
                })
            );
    }

}
