<cb-dialog [dialogHeading]="data.dialogHeading || 'Edit Council RFI'">
    <form class="flex-col flex"
          #editCouncilRfiForm="ngForm">
        <div class="flex-col flex">
            <cb-input type="text"
                      label="Council"
                      name="councilName"
                      [(ngModel)]="mappedItem.councilName"
                      [readonly]="true"></cb-input>
            <div class="flex-col"
                 *ngIf="availableConsents.length > 0">
                <cb-selection-list name="relatedConsents"
                                   label="Related Consent(s)"
                                   [(ngModel)]="mappedItem.relatedConsentIds"
                                   [options]="availableConsents"
                                   [optionLabel]="relatedConsentLabel"
                                   [readonly]="isReadonly"
                                   [required]="true">
                </cb-selection-list>
            </div>
            <div class="flex-col"
                 *ngIf="consentsRequiringConsentNumber.length > 0">
                <cb-checkbox *ngFor="let consent of consentsRequiringConsentNumber"
                             [ngModel]="false"
                             [ngModelOptions]="{ standalone: true }"
                             [readonly]="true"
                             [slim]="true">
                    {{consent.consentTypeLabel}} - {{consent.consentFor}}
                    <mat-icon color="warn"
                              matTooltip="This consent requires Consent Number">warning</mat-icon>
                </cb-checkbox>
            </div>
            <cb-input type="text"
                      label="Received From"
                      name="receivedFrom"
                      [(ngModel)]="mappedItem.receivedFrom"
                      [readonly]="isReadonly"
                      [required]="true"></cb-input>
            <cb-input type="text"
                      label="External Reference"
                      name="externalReference"
                      [(ngModel)]="mappedItem.externalReference"
                      [readonly]="isReadonly"
                      [required]="true"></cb-input>
            <cb-datepicker label="RFI Date"
                           name="rfiDate"
                           pickerType="calendar"
                           [(ngModel)]="mappedItem.rfiDate"
                           [readonly]="isReadonly"
                           [required]="true"></cb-datepicker>
            <cb-datepicker label="RFI Due Date"
                           name="rfiDueDate"
                           pickerType="calendar"
                           [(ngModel)]="mappedItem.rfiDueDate"
                           [readonly]="isReadonly"
                           [required]="true"></cb-datepicker>
            <div class="flex-row">
                <cb-selection-list class="flex"
                                   name="rFIAspects"
                                   label="Relates To"
                                   [(ngModel)]="mappedItem.rfiAspects"
                                   [readonly]="isReadonly"
                                   [options]="rFIAspects"
                                   [required]="true"></cb-selection-list>
                <cb-select class="flex"
                           name="workingDrawing"
                           label="Working Drawing Revision"
                           [required]="true"
                           [options]="workingDrawings"
                           [(ngModel)]="mappedItem.workingDrawingId"></cb-select>
            </div>
            <div class="start-center flex-row">
                <div class="flex-row">
                    <cb-user-autocomplete-scroller class="flex"
                                                   label="Assign To"
                                                   name="assignTo"
                                                   [(ngModel)]="assignee"
                                                   [readonly]="isReadonly"
                                                   [requireMatch]="true"
                                                   [matchProps]="['id']"
                                                   [active]="true"
                                                   (change)="assigneeChanged()"
                                                   [required]="true"></cb-user-autocomplete-scroller>
                    <button *ngIf="isReadonly"
                            mat-icon-button
                            color="primary"
                            class="cb-margin-left"
                            (click)="onViewAssignToClicked()">
                        <mat-icon>pageview</mat-icon>
                    </button>
                </div>
                <span *ngIf="assigneeTeams.length > 1"
                      class="cb-margin-left-2x cb-margin-right-2x">as</span>
                <cb-select *ngIf="assigneeTeams.length > 1"
                           label="Team"
                           name="team"
                           [(ngModel)]="assignedToTeamCode"
                           [readonly]="isReadonly"
                           [options]="assigneeTeams"
                           [required]="true"
                           valueProp="key"></cb-select>
            </div>
            <cb-text-area label="Notes"
                          name="notes"
                          [maxlength]="1000"
                          [(ngModel)]="mappedItem.notes"
                          [readonly]="isReadonly"
                          [required]="true"></cb-text-area>
        </div>
        <div *ngIf="!isReadonly"
             class="cb-margin-top flex-col">
            <label class="cb-margin-bottom cb-required">Upload Document</label>
            <cb-document-upload *cbWaitFor="document"
                                class="cb-margin-bottom"
                                [(documentEntityMappedItem)]="document"
                                (documentEntityMappedItemChange)="editCouncilRfiForm.form.markAsDirty()"
                                [hideName]="true"
                                [hideDescription]="true"></cb-document-upload>
        </div>
        <button mat-flat-button
                *ngIf="document?.document?.id"
                (click)="downloadLatestRfiDocument()">
            <mat-icon>file_download</mat-icon>
            &nbsp;Download
        </button>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="saveDisabled(editCouncilRfiForm)"
                (cbClick)="save()"
                [clickLimit]="1">Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
