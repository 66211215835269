import { Component, Input } from '@angular/core';
import { PermissionsPermissions } from '@app/core/permissions';
import { CbEnumService } from '@app/core/services/enum/enum.service';
import { BusinessAccountContactTypeIcon, BusinessAccountLogicService, IBusinessAccountLocationDto } from '@app/logic/business-accounts';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM, IBusinessAccountDetailsDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { filter, map } from 'lodash';
import { BusinessAccountManageLocationsDialogComponent } from '../business-account-manage-locations-dialog/business-account-manage-locations-dialog.component';

@Component({
    selector: 'cb-business-account-locations',
    templateUrl: './business-account-locations.component.html',
    styleUrls: ['./business-account-locations.component.scss']
})
export class BusinessAccountLocationsComponent {
    @Input() public account: IBusinessAccountDetailsDto;

    public region: number;
    public regionLabel = '';
    public district: number;
    public districtLabel = '';
    public area: number;
    public areaLabel = '';
    public contactType = 0;
    public contactTypes = BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.toLookup();
    public accountLocations: IBusinessAccountLocationDto[];
    public cached: IBusinessAccountLocationDto[];
    public availableLocations: number[];

    constructor(
        public readonly permissions: PermissionsPermissions,
        protected readonly businessAccountLogicService: BusinessAccountLogicService,
        protected readonly cbEnumService: CbEnumService,
        protected readonly cbDialog: CbDialogService,
    ) {
        this.contactTypes.unshift({ id: 0, label: 'All' } as any);
    }

    public ngOnChanges(): void {
        if (this.account?.id) {
            this.availableLocations = map(this.account?.locations ?? [], 'id');
            this.accountLocations = [];
            this.businessAccountLogicService.getAccountContacts(this.account.id).subOnce(contacts => {
                if (contacts?.length) {
                    const contactTypes = BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.toLookupDictionary();
                    contacts.forEach(contact => {
                        contact.areas.forEach(location => {
                            const accountLocation: IBusinessAccountLocationDto = {
                                name: contact.name,
                                contactType: contactTypes[location.contactType]?.label,
                                contactTypeId: location.contactType,
                                contactTypeIcon: BusinessAccountContactTypeIcon[contactTypes[location.contactType].code],
                                email: contact.email,
                                position: contact.position,
                                phoneMobile: contact.phoneMobile,
                                tradeTypes: contact.tradeTypes,
                                region: location.location?.region?.id,
                                regionLabel: location.location?.region?.label,
                                district: location.location?.district?.id,
                                districtLabel: location.location?.district?.label,
                                area: location.location?.area?.id,
                                areaLabel: location.location?.area?.label
                            };
                            accountLocation.locationLabel =
                                filter([accountLocation.regionLabel, accountLocation.districtLabel, accountLocation.areaLabel], text => !!text).join(', ');
                            this.accountLocations.push(accountLocation);
                        });
                    });
                    this.cached = this.accountLocations;
                }
            });
        }
    }

    public refreshData(): void {
        this.accountLocations = filter(this.cached, location => {
            let isValid = true;
            if (this.region && this.region !== location.region) {
                isValid = false;
            }
            if (this.district && this.district !== location.district) {
                isValid = false;
            }
            if (this.area && this.area !== location.area) {
                isValid = false;
            }
            if (this.contactType && this.contactType !== location.contactTypeId) {
                isValid = false;
            }
            return isValid;
        });
    }

    public onLocationChanged(location: any): void {
        this.region = location.region;
        this.regionLabel = location.regionLabel;
        this.district = location.district;
        this.districtLabel = location.districtLabel;
        this.area = location.area;
        this.areaLabel = location.areaLabel;
        this.refreshData();
    }

    public onManageLocationsClicked(): void {
        this.cbDialog.open(BusinessAccountManageLocationsDialogComponent, {
            data: {
                dialogHeading: 'Add Regions to the Account',
                accountId: this.account.id,
            },
        });
    }
}
