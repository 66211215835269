import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ChangeOptionLineLogicService} from '@app/logic/change-option-line/change-option-line.logic.service';
import {IChangeOptionLineMappedItem} from '@app/logic/change-option-line/interfaces/i.change-option-line.mapped';
import {IChangeOptionMappedItem} from '@app/logic/change-option/interfaces/i.change-option.mapped';
import {IChangeRecordMappedItem} from '@app/logic/change-records/interfaces/i.change-record.mapped';
import {SpecGroupsLogicService} from '@app/logic/spec-groups';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {FormMode} from '@app/shared/enums/form';
import {CHANGE_DIRECTION_ENUM, ChangeDirectionEnumId, COST_TYPE_ENUM, IChangeOptionLineDto, ISpecGroupDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {orderBy} from 'lodash';
import {NonSimChangeOptionLineDialogComponent} from '../non-sim-change-option-line-dialog/non-sim-change-option-line-dialog.component';

@Component({
    selector: 'cb-spec-items-add-credit-table',
    templateUrl: './spec-items-add-credit-table.component.html',
    styleUrls: ['./spec-items-add-credit-table.component.scss']
})
export class SpecItemsAddCreditTableComponent implements OnInit {
    @Input() public changeRecordMappedItem: IChangeRecordMappedItem;
    @Input() public changeOptionMappedItem: IChangeOptionMappedItem;
    @Input() public parentForm: UntypedFormGroup;

    public COST_TYPE_ENUM = COST_TYPE_ENUM;
    public CHANGE_DIRECTION_ENUM = CHANGE_DIRECTION_ENUM;

    public houseAreas: ISpecGroupDto[];

    public sumCreditAmounts = 0;
    public sumAddAmounts = 0;

    public readonly addCreditTableColumns = [
        'House Area',
        'Qty',
        'Item Details',
        'Amount',
        'Cost Type',
        'Quoted',
        'QS Comments',
        ''
    ];

    public readonly allocatedLineTableColumns = [
        'Ref',
        'Type',
        'Item',
        'House Area',
        'Qty',
        'UOM',
        'Rate (GST)',
    ];

    constructor(
        private readonly cbDialog: CbDialogService,
        private readonly changeOptionLineLogicService: ChangeOptionLineLogicService,
        private readonly specGroupLogic: SpecGroupsLogicService) {
        this.specGroupLogic.$getList().subOnce((results) => {
            this.houseAreas = orderBy(results, 'sortOrder', 'asc');
        });
    }

    public ngOnInit(): void {
        this.calculateSumAmounts();
    }

    public addLineItem(_changeDirection: ChangeDirectionEnumId): void {

        this.cbDialog.open(NonSimChangeOptionLineDialogComponent,
            {
                data: {
                    houseAreas: this.houseAreas,
                    lotId: this.changeRecordMappedItem.lotId,
                    formMode: FormMode.Add,
                    mappedItem: this.changeOptionLineLogicService.$createMappedItem({ changeDirection: _changeDirection }),
                    changeRecordMappedItem: this.changeRecordMappedItem,
                    changeOptionMappedItem: this.changeOptionMappedItem
                },
            }).afterClosed()
            .subOnce(result => {
                if (result) {
                    this.changeOptionMappedItem.changeOptionLines = this.changeOptionMappedItem.changeOptionLines || [];

                    // Add Item to Add Table
                    this.changeOptionMappedItem.changeOptionLines.push(result);

                    this.calculateSumAmounts();

                    this.parentForm.markAsDirty();

                }
            });
    }

    public editLineItem(changeOptionLine: IChangeOptionLineDto): void {

        this.cbDialog
            .open(NonSimChangeOptionLineDialogComponent, {
                data: {
                    houseAreas: this.houseAreas,
                    lotId: this.changeRecordMappedItem.lotId,
                    formMode: FormMode.Edit,
                    mappedItem: this.changeOptionLineLogicService.$createMappedItem(changeOptionLine),
                    changeRecordMappedItem: this.changeRecordMappedItem,
                    changeOptionMappedItem: this.changeOptionMappedItem,
                },
            }).afterClosed()
            .subOnce(result => {
                if (result) {

                    const indexOfExistingchangeOptionLine = this.changeOptionMappedItem.changeOptionLines.findIndex(
                        (item) => item.id === changeOptionLine.id
                    );

                    // Replace the existing item with Edited Item
                    this.changeOptionMappedItem.changeOptionLines.splice(indexOfExistingchangeOptionLine, 1, result);

                    this.calculateSumAmounts();

                    this.parentForm.markAsDirty();
                }
            });

    }


    public calculateSumAmounts(): void {
        this.sumCreditAmounts = 0;
        this.sumAddAmounts = 0;
        this.changeOptionMappedItem.changeOptionLines.forEach(optionLine => {
            if (this.filterChangeDirection(CHANGE_DIRECTION_ENUM.Credit)(optionLine)) {
                if (optionLine.costAmount) {
                    this.sumCreditAmounts += optionLine.costAmount;
                }
            } else if (this.filterChangeDirection(CHANGE_DIRECTION_ENUM.Add)(optionLine)) {
                if (optionLine.costAmount) {
                    this.sumAddAmounts += optionLine.costAmount;
                }
            }
        });
    }

    public filterChangeDirection(changeDirection: number) {
        return (optionLine) => {
            return optionLine.changeDirection === changeDirection;
        };
    }

    public removeOptionLine(changeOptionLine: IChangeOptionLineMappedItem): void {

        const indexOfChangeOptionLineToDelete = this.changeOptionMappedItem.changeOptionLines.indexOf(changeOptionLine);

        if (indexOfChangeOptionLineToDelete > -1) {
            this.changeOptionMappedItem.changeOptionLines.splice(indexOfChangeOptionLineToDelete, 1);
            if (changeOptionLine.id > 0) {
                this.changeOptionMappedItem.changeOptionLinesToDelete.push(changeOptionLine.id);
            }
            this.parentForm.markAsDirty();
        }

        this.calculateSumAmounts();

    }


}
