import { Injectable, Injector } from '@angular/core';
import { IUserSearch } from '@app/core/services/user-cache/user-cache-areas';
import { IFileResponse } from '@app/shared/interfaces/i.file-response.dto';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import { IClassicUserDocumentDto, IUserSystemAccessDto, UserTagConstantsConstId } from '@classictechsolutions/hubapi-transpiled-enums';
import { cleanObject, cleanObjectDeep } from 'cb-hub-lib';
import { ISearchResult } from 'projects/cb-hub-lib/src/lib/interfaces/search-result.interface';
import { map, Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicServiceWithSearch } from '../base/base-logic-service-with-search.component';
import { IUserSearchDto } from './interfaces/i.user-search.dto';
import { IUserDto } from './interfaces/i.user.dto';
import { IUserMappedItem } from './interfaces/i.user.mapped';
import { IUsersLogicService } from './interfaces/i.users.logic.service';
import { UserMappedItem } from './user.mapped';

@Injectable()
export class UsersLogicService
    extends BaseLogicServiceWithSearch<
    IUserDto,
    IUserMappedItem,
    IUserSearch,
    IUserSearchDto>
    implements IUsersLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('users', UserMappedItem);
    }

    public $getItem(userId: string): Observable<IUserDto> {
        return this.$http.get(`auth/users/${userId}/details`);
    }

    public $getList(): Observable<IUserDto[]> {
        return this.$http
            .get<Array<IUserDto>>(this.$baseUri);
    }

    public getListByTag(params: { tag: string }): Observable<IUserDto[]> {
        return this.$http
            .get<Array<IUserDto>>(this.$baseUri, params);
    }

    public getListByTagAndBusinessEntity(params: { tag: UserTagConstantsConstId; businessEntities: Array<number> }): Observable<IUserDto[]> {
        return this.$http
            .get<Array<IUserDto>>(this.$baseUri, params);
    }

    public getSearchList = (params: IUserSearch): Observable<ISearchResult<IUserSearchDto[]>> => {
        return this.$http
            .get<ISearchResult<IUserSearchDto[]>>('auth/users/search', params);
    };

    public $getSearchList(params: IUserSearch): Observable<IClassicUserDocumentDto[]> {
        return this.$http.get<ISearchResult<IClassicUserDocumentDto>>('auth/users/search', cleanObjectDeep(cloneDeepSafe(params)))
            .pipe(map(results => results.items));
    }

    public getMappedSearchList(params: IUserSearch): Observable<IUserMappedItem[]> {
        return this.$getSearchList(params).pipe(map((x: any) => x.items.map((i: IUserDto) => this.$createMappedItem(i))));
    }

    public generateUserSecurityReport(): Observable<IFileResponse> {
        return this.$http.download('auth/users/security/report', 'Generating User Security Report...');
    }

    public generateUserDetailsReport(): Observable<IFileResponse> {
        return this.$http.download('auth/users/details/report', 'Generating User Details Report...');
    }

    public generateFullUserDetailReport(id: string): Observable<IFileResponse> {
        return this.$http.download(`auth/users/${id}/report`, 'Generating User Details Report...');
    }

    public $saveItem(entity: IUserDto): Observable<IUserDto> {
        const uri = 'auth/users/details';
        let params: { id: string };
        if (entity.id) {
            params = { id: entity.id };
        }
        return this.$http.put<IUserDto>(uri, entity, undefined, undefined, params);
    }

    public saveSystemAccess(entity: IUserSystemAccessDto): Observable<IUserSystemAccessDto> {
        const uri = 'auth/users/systemaccess';
        let params: { id: string };
        if (entity.id) {
            params = { id: entity.id };
        }
        return this.$http.put<IUserSystemAccessDto>(uri, entity, undefined, undefined, params);
    }

    public search(params: Partial<IUserSearch>, ignoreEmptyQueries = true): Observable<ISearchResult<IUserSearchDto>> {
        if (ignoreEmptyQueries && (params.query == null || params.query.trim().length < 1)) {
            return;
        }
        return this.$http
            .get<ISearchResult<IUserSearchDto>>(`auth/${this.$baseUri}/search`, cleanObject(params));
    }

    public fetchIsUsernameUnique(userId: string, username: string): Observable<boolean> {
        return this.$http.get(`auth/users/${userId}/checkusername`, { username });
    }
}
