import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IDocumentTemplateMappedItem } from './interfaces/i.document-template.mapped';
import { IDocumentTemplateDto } from './interfaces/i.document-template.dto';
import { IDocumentTemplateLogicService } from './interfaces/i.document-template.logic.service';

export class DocumentTemplateMappedItem
    extends BaseMappedItem<IDocumentTemplateDto, IDocumentTemplateMappedItem, IDocumentTemplateLogicService>
    implements IDocumentTemplateMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public category: string;
    @DtoProp public code: string;
    @DtoProp public name: string;
    @DtoProp public description: string;
    @DtoProp public isActive: boolean;
    @DtoProp public dateUploaded: string;
    @DtoProp public file: any;
    @DtoProp public extension: string;
    @DtoProp public fileSize: number;
    @DtoProp public filename: string;

    constructor(
        sourceData: IDocumentTemplateDto,
        logicService: IDocumentTemplateLogicService
    ) {
        super(sourceData, logicService, DocumentTemplateMappedItem);
    }
}
