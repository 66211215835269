import { Injectable, Injector } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { ISupplyTypeTreeMappedItem } from './interfaces/i.supply-type-tree.mapped';
import { ISupplyTypesTreeLogicService } from './interfaces/i.supply-types-tree.logic.service';
import { SupplyTypeTreeMappedItem } from './supply-type-tree.mapped';
import { ISupplyTypeTreeDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Injectable()
export class SupplyTypesTreeLogicService
    extends BaseLogicService<ISupplyTypeTreeDto, ISupplyTypeTreeMappedItem>
    implements ISupplyTypesTreeLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'supplytypes',
            SupplyTypeTreeMappedItem,
        );
    }

    public getTree(): Observable<ISupplyTypeTreeDto[]> {
        return this.$http.get<ISupplyTypeTreeDto[]>(`${this.$baseUri}/tree`);
    }

    public getMappedTree(): Observable<ISupplyTypeTreeMappedItem[]> {
        return this.getTree()
            .pipe(
                map(res => res.map(x => this.$createMappedItem(x)))
            );
    }
}
