import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '@src/environments/environment';

@Component({
    selector: 'cb-related-slots',
    templateUrl: './related-slots.component.html',
    styleUrls: ['./related-slots.component.scss']
})
export class RelatedSlotsComponent {

    @Input() public readonly relatedSlots: any;

    public static readonly MIN_WIDTH = '95%';
    selectedItems: Set<any> = new Set();
    @Output() public readonly selectedSlots = new EventEmitter<Set<any>>();

    constructor() { }

    trackById(index: number, product: any): number {
        return index;
    }

    getProductImage(product: any): string {
        if (!product.hasImage) {
            return 'https://productdistributionstrategy.com/wp-content/uploads/2019/09/retail-distribution-strategy.svg';
        }
        return `${environment.api}/${product.imageUrl}`;
    }

    toggleSelection(product: any): void {
        if (this.selectedItems.has(product)) {
            this.selectedItems.delete(product);
        } else {
            this.selectedItems.add(product);
        }
        this.selectedSlots.emit(this.selectedItems);
    }

    isSelected(product: any): boolean {
        return this.selectedItems.has(product);
    }
}
