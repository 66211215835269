import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { IPurchaseOrderDto } from '@app/logic/purchase-orders';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import { IPurchaseOrderDocumentDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { cleanObjectDeep } from 'cb-hub-lib';
import { map, Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { IManualOrdersLogicService } from './interfaces/i.manual-orders.logic.service';

export interface IAllManualOrderSearchParams {
    query?: string;
    currentPage?: number;
    pageSize?: number;
}

@Injectable()
export class ManualOrdersLogicService
    extends BaseLogicService<IPurchaseOrderDto, Object>
    implements IManualOrdersLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'manualorders',
            Object,
        );
    }

    public $getSearchList(params: IAllManualOrderSearchParams): Observable<IPurchaseOrderDocumentDto[]> {
        return this.$http
            .get<any>(`${this.$baseUri}/search`, cleanObjectDeep(cloneDeepSafe(params)))
            .pipe(map(results => {
                if (results.items) {
                    return results.items;
                } else {
                    return results;
                }
            }));
    }
}
