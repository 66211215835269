<cb-dialog dialogHeading="{{data.dialogHeading || 'Note Dialog'}}">
    <form class="flex-col flex"
          #noteForm="ngForm">
        <cb-input type="text"
                  name="subject"
                  label="Subject"
                  [maxlength]="100"
                  [required]="true"
                  [(ngModel)]="data.note.subject"></cb-input>
        <cb-text-area name="comments"
                      label="Comments"
                      [required]="true"
                      [(ngModel)]="data.note.body"
                      [maxlength]="1000">
        </cb-text-area>
        <cb-checkbox name="isActive"
                     label="Mark as Important"
                     [(ngModel)]="data.note.isImportant">
        </cb-checkbox>
    </form>
    <footer class="end-center">
        <button mat-raised-button
                [disabled]="!noteForm.dirty || !noteForm.valid"
                color="primary"
                (click)="save()">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
