import {
    AfterContentChecked, AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { ProductLogicService } from '@app/logic/products';
import { BUSINESS_ACCOUNT_STATUS_ENUM, IIdAndLabelDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Subscription } from 'rxjs';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IProductRateExceptionSearch } from '@app/core/services/user-cache/user-cache-areas';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ProductPermissions } from '@app/core/permissions';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { ProductRateExceptionSearchParams } from '@app/logic/products/product.logic.service';
import { IProductRateExceptionSearchDto } from '@app/logic/products/i.product-rate-exception-search.dto';
import moment from 'moment/moment';
import { MatSort, Sort } from '@angular/material/sort';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BlockingDialogComponent } from '@app/shared/components/dialog/blocking-dialog/blocking-dialog.component';
import { DatePipe } from '@angular/common';
import { BusinessEntitiesLogicService } from '@app/logic/business-entity';
import { UntypedFormControl } from '@angular/forms';
import { DateFormats } from '@app/shared/declarations/date-formats.constants';
import { ExportCsvUtil } from '@app/shared/utils/export.csv.util';

export enum ColumnNames {
    Checkbox = 'checkbox',
    Supplier = 'Supplier',
    JobNumber = 'Job#',
    PurchaseOrderNumber = 'PO #',
    ProductCode = 'Item ID',
    ItemName = 'Item Name',
    JobCost = 'Rate PO',
    CurrentRate = 'Rate Hub',
    Variance = 'Variance',
    Quantity = 'Qty',
    StartDate = 'Start',
    EndDate = 'Finish',
    Actions = 'actions'
}

@Component({
    selector: 'cb-product-rate-exception-tab',
    templateUrl: './product-rate-exception-tab.component.html',
    styleUrls: ['./product-rate-exception-tab.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [NavigationService]
})
export class ProductRateExceptionTabComponent implements OnInit, AfterContentChecked, AfterViewInit, OnDestroy {

    public readonly COLUMN_NAMES = ColumnNames;

    public filterChips: any[];
    public selectedCategory: number[];
    public dateFormat = DateFormats.DEAULT_DATE;

    public dataSource = new MatTableDataSource<IProductRateExceptionSearchDto>([]);

    public searchResults: IProductRateExceptionSearchDto[] = [];
    public businessEntities: IIdAndLabelDto[] = [];

    public selectedPurchaseOrders: IProductRateExceptionSearchDto[];
    public checkedPurchaseOrders: { [purchaseOrderNumber: number]: boolean } = {};

    public searchIsLoading = false;
    public loaded = true;
    public allChecked = false;

    public subscriptions$ = new Subscription();

    public blockingRef: MatDialogRef<BlockingDialogComponent>;

    private readonly _defaultColumns = {
        [ColumnNames.Checkbox]: () => true,
        [ColumnNames.Supplier]: () => true,
        [ColumnNames.JobNumber]: () => true,
        [ColumnNames.PurchaseOrderNumber]: () => true,
        [ColumnNames.ProductCode]: () => true,
        [ColumnNames.ItemName]: () => true,
        [ColumnNames.Quantity]: () => true,
        [ColumnNames.JobCost]: () => true,
        [ColumnNames.CurrentRate]: () => true,
        [ColumnNames.Variance]: () => true,
        [ColumnNames.StartDate]: () => true,
        [ColumnNames.EndDate]: () => true,
        [ColumnNames.Actions]: () => true,
    };

    private readonly _exportedColumns = [
        {id: 'supplier', displayValue: 'Supplier'},
        {id: 'jobNumber', displayValue: 'Job #'},
        {id: 'purchaseOrderNumber', displayValue: 'PO #'},
        {id: 'productCode', displayValue: 'Item Id'},
        {id: 'itemName', displayValue: 'Item Name'},
        {id: 'quantity', displayValue: 'Qty'},
        {id: 'jobCost', displayValue: 'Rate PO'},
        {id: 'currentRate', displayValue: 'Rate Hub'},
        {id: 'variance', displayValue: 'Variance'},
        {id: 'startDate', displayValue: 'Start'},
        {id: 'endDate', displayValue: 'Finish'}];


    @ViewChild('exceptionTable', { static: false }) public matTable: MatTable<any>;
    @ViewChild('poTbSort') public sort = new MatSort();


    public productUsageBusinessAccountSearchParams = {
        s: [
            BUSINESS_ACCOUNT_STATUS_ENUM.Active,
            BUSINESS_ACCOUNT_STATUS_ENUM.Overridden,
            BUSINESS_ACCOUNT_STATUS_ENUM.OnHold,
            BUSINESS_ACCOUNT_STATUS_ENUM.Closed
        ]
    };

    private readonly _displayedColumns = new ComputedProperty(() => {
        return Object.keys(this._defaultColumns).filter(colKey => this._defaultColumns[colKey]());
    });

    public get noSearchResultsFound(): boolean {
        return !this.searchResults || this.searchResults.length === 0;
    }

    public get displayedColumns(): string[] {
        return this._displayedColumns.value;
    }

    public get userCacheItem(): UserCacheItem<IProductRateExceptionSearch> {
        return this.userCacheService.productRateExceptionSearch;
    }

    constructor(
        private readonly cbDialogService: CbDialogService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly datePipe: DatePipe,
        private readonly businessEntitiesLogicService: BusinessEntitiesLogicService,
        public readonly navigationService: NavigationService,
        public readonly productLogicService: ProductLogicService,
        public readonly userCacheService: UserCacheService,
        public readonly productPermissions: ProductPermissions,
    ) { }

    public ngOnInit(): void {
        this.userCacheItem.init().then(() => {

            this.updateFilterChips();
            this.subscriptions$.add(
                this.userCacheItem.updated$.subscribe({
                    next: () => {
                        this.updateFilterChips();
                    }
                })
            );
        });

        this.loadBusinessEntities();
    }

    public ngAfterContentChecked(): void {
        this.cdRef.detectChanges();
    }

    public ngAfterViewInit(): void {

        this.dataSource.sort = this.sort;
    }

    public ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    public sortExceptionList(sort: Sort): void{

        if (!sort.active || isNullOrWhiteSpace(sort.direction)) {
            return;
        }
        const rateObjects = this.searchResults.slice() ?? [];
        this.searchResults = rateObjects.slice().sort((a, b) => {
            const isAsc = sort.direction === 'asc' ? 1 : -1;
            switch (sort.active) {
                case ColumnNames.Supplier: return this.compare(a.supplier, b.supplier, isAsc);
                case ColumnNames.JobNumber: return this.compare(a.jobNumber, b.jobNumber, isAsc);
                case ColumnNames.PurchaseOrderNumber: return this.compare(a.purchaseOrderNumber, b.purchaseOrderNumber, isAsc);
                case ColumnNames.ItemName: return this.compare(a.itemName, b.itemName, isAsc);
                case ColumnNames.ProductCode: return this.compare(a.productCode, b.productCode, isAsc);
                case ColumnNames.Quantity: return this.compare(a.quantity, b.quantity, isAsc);
                case ColumnNames.JobCost: return this.compare(a.jobCost, b.jobCost, isAsc);
                case ColumnNames.CurrentRate: return this.compare(a.currentRate, b.currentRate, isAsc);
                case ColumnNames.Variance: return this.compare(this.getVariance(a), this.getVariance(b), isAsc);
                case ColumnNames.StartDate: return this.compare(a.startDate, b.startDate, isAsc);
                case ColumnNames.EndDate: return this.compare(a.endDate, b.endDate, isAsc);
                default: return 0;
            }
        });
    }

    public getVariance(rateObject: IProductRateExceptionSearchDto): number {
        return (rateObject.jobCost ?? 0) - (rateObject.currentRate ?? 0);
    }

    private compare(a: number | string, b: number | string, isAsc: number): number {
        return (a < b ? -1 : a > b ? 1 : 0) * isAsc;
    }

    private loadBusinessEntities(): void {
        this.businessEntitiesLogicService.$getSkinnyList().subOnce(x => {
            this.businessEntities = x.sort((a, b) => a.label.localeCompare(b.label));
        });
    }

    public anyPurchaseOrdersChecked(): boolean {
        return Object.values(this.checkedPurchaseOrders)?.some(x => x);
    }

    public toggleAllChecked(event: MouseEvent | null, value = !this.allChecked): void {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.allChecked = value;
        this.checkedPurchaseOrders = {};

        if (this.allChecked) {
            this.selectedPurchaseOrders.forEach(x => this.checkedPurchaseOrders[x.purchaseOrderNumber] = true);
        } else {
            this.selectedPurchaseOrders.forEach(x => this.checkedPurchaseOrders[x.purchaseOrderNumber] = false);
        }
    }

    public toggleAllLabel(): string {
        return this.allChecked ? 'Deselect All' : 'Select All';
    }

    public reconfirmTooltipLabel(): string {
        return !this.anyPurchaseOrdersChecked()
            ? 'Nothing selected'
            : 'Update and reconfirm selected';
    }

    public getHubRateTooltip(purchaseOrder: IProductRateExceptionSearchDto): string {
        if (purchaseOrder.dateRateApplies &&
            moment(purchaseOrder.dateRateApplies).year() > 2000){
            return `Rate Start Date - ${this.datePipe.transform(purchaseOrder.dateRateApplies, 'dd MMM yyyy')}`;
        }
        return '';
    }

    public selectedBusinessEntity(selectMultipleElement: UntypedFormControl): void {
        this.userCacheItem.data.selectedBusinessEntity = selectMultipleElement.value as number[];
        this.updateFilterChips();
    }

    public reconfirmPurchaseOrders(): void {
        this.cbDialogService.simpleMessageDialog('Functionality has not been implemented yet');
    }

    public performSearch(): void {
        this.blockingRef = this.cbDialogService.block('Performing search, please wait...');

        this.loaded = false;
        this.searchIsLoading = true;
        this.productLogicService.getRateExceptionList(this.getSearchParams()).subOnce(
            {
                next:(result: IProductRateExceptionSearchDto[])=>{
                    this.searchResults = result;
                    this.selectedPurchaseOrders = result;
                    this.loaded = true;
                    this.searchIsLoading = false;
                    this.checkedPurchaseOrders = {};
                    this.allChecked = this.selectedPurchaseOrders.every(x => this.checkedPurchaseOrders[x.purchaseOrderNumber]);
                    this.dataSource.data = this.selectedPurchaseOrders;
                },
                complete:()=> {
                    this.blockingRef.close();
                }
            });
    }

    public getSearchParams(): ProductRateExceptionSearchParams {
        const query = isNullOrWhiteSpace(this.userCacheService?.productRateExceptionSearch?.silentData?.query)
            ? undefined
            : this.userCacheService?.productRateExceptionSearch?.silentData?.query;
        return {
            poNo: this.userCacheService.productRateExceptionSearch?.silentData?.purchaseOrderNumber,
            businessAccountId: this.userCacheItem.silentData.selectedBusinessEntity,
            supplierId: this.userCacheService.productRateExceptionSearch?.silentData?.selectedSupplier?.id,
            createdDateFrom: this.userCacheService.productRateExceptionSearch?.silentData?.createdFromDate,
            createdDateTo: this.userCacheService.productRateExceptionSearch?.silentData?.createdToDate,
            jobNo: query,
            activityStartDate: this.userCacheService.productRateExceptionSearch?.silentData?.activityStartDate,
            activityEndDate: this.userCacheService.productRateExceptionSearch?.silentData?.activityEndDate,
            offeringId: this.userCacheService.productRateExceptionSearch?.silentData?.selectedProduct?.id,
        };
    }


    public readonly removeChip = (chip): void => {
        switch (chip.id) {
            case 'createdFromDate':
                this.userCacheItem.data.createdFromDate = undefined;
                break;
            case 'createdToDate':
                this.userCacheItem.data.createdToDate = undefined;
                break;
            case 'activityStartDate':
                this.userCacheItem.data.activityStartDate = undefined;
                break;
            case 'activityEndDate':
                this.userCacheItem.data.activityEndDate = undefined;
                break;
            case 'selectedBusinessEntities':
                this.userCacheItem.data.selectedBusinessEntity = undefined;
                break;
            default:
                this.userCacheItem.data[chip.id] = undefined;
                break;
        }
        this.filterChips = this.filterChips.filter(x => x.id !== chip.id);
        this.filterChips[chip.id] = undefined;
        this.updateFilterChips();
    };

    private updateFilterChips(): void {
        this.filterChips = [];

        if (this.userCacheItem.silentData.selectedSupplier) {
            const supplierName = (this.userCacheItem.silentData.selectedSupplier?.legalName)
                ? this.userCacheItem.silentData.selectedSupplier.legalName
                : this.userCacheItem.silentData.selectedSupplier.tradingName;
            this.filterChips.push({ id: 'selectedSupplier', text: 'Supplier - ' + supplierName });
        }

        if (this.userCacheItem.silentData.query !== undefined &&
            this.userCacheItem.silentData.query !== '') {
            this.filterChips.push({ id: 'query', text: `Job# - ${this.userCacheItem.silentData.query}` });
        }

        if (this.userCacheItem.silentData.selectedBusinessEntity) {
            const entityNames = this.businessEntities
                .filter(x=> this.userCacheItem.silentData.selectedBusinessEntity.includes(x.id))
                .map(status => status.label)
                .filter(x => !!x);
            if (entityNames.length > 0) {
                this.filterChips.push({ id: 'selectedBusinessEntities', text: 'CB Business Entity - ' + entityNames.join(', ') });
            }
        }

        if (this.userCacheItem.silentData.purchaseOrderNumber) {
            this.filterChips.push({ id: 'purchaseOrderNumber', text: `PO# - ${this.userCacheItem.silentData.purchaseOrderNumber}` });
        }

        if (this.userCacheItem.silentData.selectedProduct) {
            this.filterChips.push({ id: 'selectedProduct', text: 'Product - ' + this.userCacheItem.silentData.selectedProduct.name });
        }

        if (this.userCacheItem.silentData.activityStartDate) {
            this.filterChips.push({ id: 'activityStartDate', text: `Activity Start - ${moment(this.userCacheItem.silentData.activityStartDate).format(this.dateFormat)}` });
        }

        if (this.userCacheItem.silentData.activityEndDate) {
            this.filterChips.push({ id: 'activityEndDate', text: `Activity End - ${moment(this.userCacheItem.silentData.activityEndDate).format(this.dateFormat)}` });
        }

        if (this.userCacheItem.silentData.createdFromDate) {
            this.filterChips.push({ id: 'createdFromDate', text: `Created From - ${moment(this.userCacheItem.silentData.createdFromDate).format(this.dateFormat)}` });
        }

        if (this.userCacheItem.silentData.createdToDate) {
            this.filterChips.push({ id: 'createdToDate', text: `Created To - ${moment(this.userCacheItem.silentData.createdToDate).format(this.dateFormat)}` });
        }
    }

    public clearAll(): void {
        this.userCacheItem.data = {
            query: '',
            selectedSupplier: null,
            selectedBusinessEntity: null,
            purchaseOrderNumber: null,
            createdFromDate: null,
            createdToDate: null,
            activityStartDate: null,
            activityEndDate: null,
            selectedProduct: null
        };
    }

    public exportToCsv(): void{
        const fileName = `rate-exceptions-${moment(new Date()).format(DateFormats.DATETIME_STANDARD_FILE_SAFE)}.csv`;

        // Variance is not a column that exists on our DTO, it gets calculated as a subtraction of 2 other properties
        // In these cases, we can pass in a custom formatter so that the exported can perform the same operation
        const customColumnFormatter = {
            variance: this.getVariance.bind(this),
            currentRate: (element: IProductRateExceptionSearchDto) => (element.currentRate ?? 0).toString(),
            jobCost: (element: IProductRateExceptionSearchDto) => (element.jobCost ?? 0).toString(),
            startDate: (element: IProductRateExceptionSearchDto) => moment(element.startDate).format(DateFormats.YEAR_MONTH_DAY_DATE_ONLY),
            endDate: (element: IProductRateExceptionSearchDto) => moment(element.endDate).format(DateFormats.YEAR_MONTH_DAY_DATE_ONLY),
        };

        const csvExported = new ExportCsvUtil<IProductRateExceptionSearchDto>();
        csvExported.downloadFromMatTable(this.matTable, this._exportedColumns, customColumnFormatter, fileName);
    }

}
