import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { DocumentTypesLogicService } from '@app/logic/document-types';
import { DocumentGroupLogicService, DocumentsLogicService, IDocumentEntityDto } from '@app/logic/documents';
import { IDocumentEntityMappedItem } from '@app/logic/documents/interfaces/i.document.mapped';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { IQuoteMappedItem, IQuoteLogicService, QuoteLogicService } from '@app/logic/quote';
import { SlotsLogicService } from '@app/logic/slots/slots.logic-service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { FormMode } from '@app/shared/enums/form';
import {
    ChangeSourceEnumId,
    CHANGE_SOURCE_ENUM,
    DOCUMENT_GROUP_CODES_CONST,
    DOCUMENT_TYPE_CODES_CONST,
    IDocumentUploadDto,
    IQuoteDto, BUSINESS_ACCOUNT_STATUS_ENUM, IBusinessAccountDetailsDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { isNullOrWhiteSpace } from 'projects/cb-hub-lib/src/lib/utils/string.util';
import { combineLatest, Observable } from 'rxjs';
import {
    BusinessAccountStatusEnumId
} from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/enums/BusinessAccountStatus';

interface IData {
    mappedItem: IQuoteMappedItem;
    isRequiredQuote: boolean;
    lotMappedItem: ILotMappedItem;
}


@Component({
    selector: 'app-lot-quote-create-dialog',
    templateUrl: './lot-quote-create-dialog.component.html',
    styleUrls: ['./lot-quote-create-dialog.component.scss']
})
export class LotQuoteCreateDialogComponent extends BaseDialogFormViewDirective<IQuoteDto, IQuoteMappedItem, IQuoteLogicService> {

    public static readonly MIN_WIDTH = '600px';
    public changeSourcesLookup: IEnumLookup<ChangeSourceEnumId>[];

    public selectedSupplier: IBusinessAccountDetailsDto;
    public isRequiredQuote: boolean;
    public displayLotConceptWarning: boolean;
    public lotMappedItem: ILotMappedItem;
    public documentEntity: IDocumentEntityMappedItem;
    public expectedStatuses: BusinessAccountStatusEnumId[] = [
        BUSINESS_ACCOUNT_STATUS_ENUM.NewRequest,
        BUSINESS_ACCOUNT_STATUS_ENUM.Pending,
        BUSINESS_ACCOUNT_STATUS_ENUM.PendingRequestApproval,
        BUSINESS_ACCOUNT_STATUS_ENUM.OnHold,
        BUSINESS_ACCOUNT_STATUS_ENUM.Active
    ];

    constructor(
        public readonly dialogRef: MatDialogRef<LotQuoteCreateDialogComponent>,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(SlotsLogicService) public readonly quoteLogicService: QuoteLogicService,
        @Inject(DocumentGroupLogicService) public readonly documentGroupLogic: DocumentGroupLogicService,
        @Inject(DocumentTypesLogicService) public readonly documentTypeLogic: DocumentTypesLogicService,
        @Inject(DocumentsLogicService) public readonly documentsLogic: DocumentsLogicService,
        @Inject(LotsLogicService) public readonly lotLogicService: LotsLogicService,
    ) {
        super(dialogRef, toastService, cbDialog);

        this.mappedItem = this.data.mappedItem;
        this.formMode = this.mappedItem?.id > 0 ? FormMode.Edit : FormMode.Add;

        this.isRequiredQuote = this.data.isRequiredQuote;

        this.displayLotConceptWarning = !data.lotMappedItem?.lotSpecLocked;
        this.lotMappedItem = data.lotMappedItem;
    }

    public ngOnInit(): void {
        this.setupUIFlags();
        this.setupChangeSource();
        this.loadDocument();
        this.setupSupplier();
    }

    public showExistingDocumentInfo(): boolean {
        return this.isEdit() && this.documentEntity !== undefined && this.documentEntity?.document?.id > 0;
    }

    public isDocumentUploaded(): boolean {
        return (this.documentEntity?.document?.uploadedFile !== null && this.documentEntity?.document?.uploadedFile !== undefined) || this.documentEntity?.document?.id > 0;
    }

    private loadDocument(): void {
        if (this.isEdit()) {
            this.loadUploadedDocument();
        } else {
            this.loadDocumentEntityStub();
        }
    }

    private loadDocumentEntityStub(): void {
        combineLatest([
            this.documentGroupLogic
                .getByCode(DOCUMENT_GROUP_CODES_CONST.LOT_QUOTE_DOC_GROUP)
            ,
            this.documentTypeLogic
                .getByCode(DOCUMENT_TYPE_CODES_CONST.QUOTE)
        ]).subOnce({
            next: ([documentGroup, documentType]) => {
                if (documentType == null) {
                    return;
                }
                this.documentEntity = this.documentsLogic.$createMappedItem(
                    {
                        documentGroup,
                        documentType,
                        document: {}
                    } as IDocumentEntityDto);
            }
        });
    }

    private readonly loadUploadedDocument = (): void => {
        this.documentsLogic?.getDocumentsInGroupCode(
            this.mappedItem.documentEntityUri,
            this.mappedItem.documentEntityId,
            DOCUMENT_GROUP_CODES_CONST.LOT_QUOTE_DOC_GROUP)
            .subOnce(docs => {
                const lotQuoteDoc = docs.filter((x) => x.documentType?.codeName === DOCUMENT_TYPE_CODES_CONST.QUOTE);

                if (lotQuoteDoc[0] !== undefined) {
                    this.documentEntity = lotQuoteDoc[0];
                } else {
                    this.loadDocumentEntityStub();
                }

            });
    };

    private readonly setupChangeSource = (): void => {

        this.changeSourcesLookup = CHANGE_SOURCE_ENUM.toLookup({
            transform: changeSourcesResult => {
                if (!this.lotMappedItem?.hasClientSale) {
                    changeSourcesResult = changeSourcesResult.filter(source => source.id !== CHANGE_SOURCE_ENUM.ClientRequest);
                }

                if (!this.lotMappedItem?.hasNonDeletedConsentRecord) {
                    changeSourcesResult = changeSourcesResult.filter(source => source.id !== CHANGE_SOURCE_ENUM.CouncilRequest);
                }
                return changeSourcesResult;
            }
        });
    };

    private readonly setupUIFlags = (): void => {
        this.mappedItem.changeRecordRequired = !this.canSkipChangeRecordCreation();
    };

    private readonly setupSupplier = (): void => {
        this.selectedSupplier = { id: this.mappedItem.supplierId, tradingName: this.mappedItem.supplierTradingName } as IBusinessAccountDetailsDto;
    };

    public canSkipChangeRecordCreation(): boolean {
        return !this.lotMappedItem.lotSpecLocked;
    }

    public supplierSelected(): void {
        this.mappedItem.freeTextSupplierName = null;
        this.mappedItem.supplierId = this.selectedSupplier.id;
        this.mappedItem.supplierTradingName = this.selectedSupplier.tradingName;
    }

    public supplierSearchTextChanged(str: string): void {
        this.mappedItem.freeTextSupplierName = str;
        this.mappedItem.supplierId = null;
        this.mappedItem.supplierTradingName = null;
    }

    public saveMethod(): Observable<IQuoteDto> {

        if (isNullOrWhiteSpace(this.mappedItem.requiredDate)) {
            this.mappedItem.requiredDate = new Date().toDateString();
        }

        return this.mappedItem.$save();
    }

    protected handleNext = (quoteDto: IQuoteDto): void => {
        this.mappedItem.$updateThisAndOriginal(quoteDto);

        if (quoteDto && !this.isRequiredQuote && this.documentEntity.document.uploadedFile) {

            if (this.documentEntity.document.id > 0) {
                // Document Reupload

                const doctoReUpload = {
                    documentGroupId: this.documentEntity.documentGroup?.id,
                    documentTypeId: this.documentEntity.documentType?.id,
                    documentEntityId: this.documentEntity.id,
                    file: this.documentEntity.document.uploadedFile,
                    name: this.documentEntity.document.name,
                } as IDocumentUploadDto;

                this.documentsLogic
                    .reUploadDocumentEntity(doctoReUpload)
                    .subOnce(() => {
                        this.dialogRef.close(quoteDto);
                        this.toastSerivce.saveSuccess();
                    });

            } else {
                // First Time Upload
                this.documentsLogic
                    .uploadDocumentEntity(this.mappedItem.documentEntityUri, this.mappedItem.documentEntityId, {
                        documentTypeId: this.documentEntity.documentType?.id,
                        documentGroupId: this.documentEntity.documentGroup?.id,
                        documentEntityId: this.mappedItem.documentEntityId,
                        file: this.documentEntity.document.uploadedFile,
                        name: this.documentEntity.document.name,
                    } as IDocumentUploadDto)
                    .subOnce(() => {
                        this.dialogRef.close(quoteDto);
                        this.toastSerivce.saveSuccess();
                    });

            }


        } else {
            this.dialogRef.close(quoteDto);
            this.toastSerivce.saveSuccess();
        }
    };

}
