import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LotPermissions } from '@app/core/permissions';
import { AccountsReceivablePermissions } from '@app/core/permissions/accountsreceivable.permission';
import { IDocumentEntityMappedItem } from '@app/logic/documents';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { DOCUMENT_TYPE_CODES_CONST, SYSTEM_AREA_ENUM, TEAM_CODES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LOTS_ROUTE } from '../lots-route.constant';
import { HISTORY_SEARCH_ENTITY } from '@app/shared/enums/history/history-search-entity.enum';

@Component({
    selector: 'cb-lots-area',
    templateUrl: './lots-area.component.html',
    styleUrls: ['./lots-area.component.scss'],
})
export class LotsAreaComponent implements OnDestroy {

    public showFiller = false;

    public readonly LOTS_ROUTE = LOTS_ROUTE;
    public systemArea = SYSTEM_AREA_ENUM.Lot;
    public TEAM_CODES_CONST = TEAM_CODES_CONST;
    public selectedTabIndex: number;
    private _subscriptions = new Subscription();

    public readonly mappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);

    @Input() public set mappedItem(mappedItem: ILotMappedItem) {
        if (mappedItem) {
            this.mappedItem$$.next(mappedItem);
        }
    }

    public get mappedItem(): ILotMappedItem {
        return this.mappedItem$$?.value;
    }

    constructor(
        private readonly logicService: LotsLogicService,
        public readonly lotPermissions: LotPermissions,
        public readonly accountsReceivablePermissions: AccountsReceivablePermissions,
        private route: ActivatedRoute,
        public readonly cdRef: ChangeDetectorRef,
    ) {
    }

    public get canViewCostSummary(): boolean {
        return this.lotPermissions.canViewCostSummary();
    }
    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public ngOnInit(): void {
        this.route.params.
            subOnce((params: { id: string }) => {
                if (params?.id) {
                    this.logicService.$getMappedItem(params.id).subOnce((mappedItem) => { this.mappedItem = mappedItem; }
                    );
                }
            });
    }

    public onTabChanged(selectedTabIndex: number): void {
        this.selectedTabIndex = selectedTabIndex;
    }

    public lotMappedItemChanged(): void {
        this.mappedItem.$reload().subOnce((_lotDto) => {
            this.mappedItem = this.logicService.$createMappedItem(_lotDto);
            this.mappedItem$$.next(this.mappedItem);
            this.cdRef.detectChanges();
        });
    }

    public lotDocumentUploaded(document: IDocumentEntityMappedItem): void {
        if (document?.documentType?.codeName === DOCUMENT_TYPE_CODES_CONST.CODE_OF_COMPLIANCE) {
            this.lotMappedItemChanged();
        }
    }

    public ngOnChanges(): void {
        this.cdRef.detectChanges();
    }

    public canSeeARTab = (): boolean => this.accountsReceivablePermissions && this.accountsReceivablePermissions.canView();
    protected readonly HISTORY_SEARCH_ENTITY = HISTORY_SEARCH_ENTITY;
}
