<cb-dialog dialogHeading="Lead Finance Information">
    <form class="flex-col flex"
          #leadFinanceInfoForm="ngForm">
        <cb-currency label="Budget"
                     name="contingencyAmount"
                     [required]="true"
                     [(ngModel)]="mappedItem.budget"
                     [inclGst]="true">
        </cb-currency>
        <label class="cb-margin-top-2x cb-margin-bottom">Client has Finance?</label>
        <mat-radio-group class="flex-col"
                         aria-label="Client has Finance?"
                         name="hasFinance"
                         [(ngModel)]="mappedItem.hasFinance"
                         [required]="true"
                         (change)="updateLeadStatus()">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No or unknown</mat-radio-button>
        </mat-radio-group>
        <label *ngIf="mappedItem.hasFinance"
               class="cb-margin-top-2x cb-margin-bottom">Finance Type</label>
        <mat-radio-group class="flex-col"
                         *ngIf="mappedItem.hasFinance"
                         aria-label="Finance Type"
                         name="financeIsCash"
                         [(ngModel)]="mappedItem.financeIsCash"
                         [required]="true"
                         (change)="updateLeadStatus()">
            <mat-radio-button [value]="true">Cash</mat-radio-button>
            <mat-radio-button [value]="false">Finance</mat-radio-button>
        </mat-radio-group>
        <div *ngIf="mappedItem.hasFinance && !mappedItem.financeIsCash">
            <cb-input type="text"
                      label="Bank Name"
                      name="bank"
                      [(ngModel)]="mappedItem.bank"
                      [required]="mappedItem.hasFinance && !mappedItem.financeIsCash">
            </cb-input>
            <cb-checkbox label="Finance Pre-Approved?"
                         name="hasApproval"
                         [(ngModel)]="mappedItem.hasApproval">
            </cb-checkbox>
        </div>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="leadFinanceInfoForm.pristine || leadFinanceInfoForm.invalid"
                (cbClick)="save()"
                [clickLimit]="1">Save</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
