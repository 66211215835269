import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { SpecTemplateMappedItem, ISpecTemplateDto, ISpecTemplateMappedItem, ISpecTemplateLogicService } from '@app/logic/spec-template';
import { IBusinessEntityDto, BusinessEntitiesLogicService } from '@app/logic/business-entity';
import { ProjectsLogicService } from '@app/logic/projects';
import { IIdAndLabelDto, IProjectDetailsDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { orderBy, map } from 'lodash';

interface IData {
    mappedItem: SpecTemplateMappedItem;
    baseTemplateName: string;
}

enum TemplateType {
    Nationwide,
    Regional,
    Project,
}

@Component({
    selector: 'cb-spec-template-dialog',
    templateUrl: './spec-template-dialog.component.html',
    styleUrls: ['./spec-template-dialog.component.scss']
})
export class SpecTemplateDialogComponent extends BaseDialogFormViewDirective<ISpecTemplateDto, ISpecTemplateMappedItem, ISpecTemplateLogicService> {
    public static readonly MIN_WIDTH = '50%';
    public mappedItem: SpecTemplateMappedItem;
    public businessEnitities: IBusinessEntityDto[];
    public projects: IIdAndLabelDto[];
    public templateTypeEnum = TemplateType;
    public templateType = TemplateType.Nationwide;

    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<SpecTemplateDialogComponent>,
        private readonly businessEntityLogic: BusinessEntitiesLogicService,
        private readonly projectsLogic: ProjectsLogicService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;

        this.businessEntityLogic.$getList().subOnce((businessEnitities: IBusinessEntityDto[]) => {
            this.businessEnitities = businessEnitities;
        });
        this.projectsLogic.$getList().subOnce((projects: IProjectDetailsDto[]) => {
            projects = orderBy(projects, project => project.projectName);
            this.projects = map(projects, (project: IProjectDetailsDto) => {
                return { id: project.id, label: project.projectName };
            });
        });

        if (!this.mappedItem.id) {
            this.mappedItem.isActive = true;
        }

        if (!this.mappedItem.regionIds?.length && !this.mappedItem.project && !this.mappedItem.projectId) {
            this.templateType = TemplateType.Nationwide;
        } else if (this.mappedItem.regionIds?.length) {
            this.templateType = TemplateType.Regional;
        } else if (this.mappedItem.project || this.mappedItem.projectId) {
            this.mappedItem.projectId = this.mappedItem.projectId ?? this.mappedItem.project.id;
            this.templateType = TemplateType.Project;
        }
    }

    public isRegionChecked(regionId: number): boolean {
        return this.mappedItem.regionIds && this.mappedItem.regionIds.indexOf(regionId) > -1;
    }

    public onRegionChanged(regionId: number, isChecked: boolean): void {
        this.mappedItem.regionIds = this.mappedItem.regionIds && this.mappedItem.regionIds.length ? this.mappedItem.regionIds : [];
        if (isChecked) {
            if (this.mappedItem.regionIds.indexOf(regionId) === -1) {
                this.mappedItem.regionIds.push(regionId);
            }
        } else {
            const index = this.mappedItem.regionIds.indexOf(regionId);
            if (index > -1) {
                this.mappedItem.regionIds.splice(index, 1);
            }
        }
    }

    public onSaveClicked(): void {
        switch (this.templateType) {
            case TemplateType.Nationwide:
                this.mappedItem.regionIds = [];
                this.mappedItem.projectId = undefined;
                break;
            case TemplateType.Regional:
                this.mappedItem.projectId = undefined;
                break;
            case TemplateType.Project:
                this.mappedItem.regionIds = [];
                break;
        }
        this.mappedItem.specTemplateGroups = this.mappedItem.specTemplateGroups ?? [];
        super.save();
    }
}
