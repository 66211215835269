import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DateFilterValue } from '@app/logic/wip/wip.service';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-date-filter',
    templateUrl: './date-filter.component.html',
    standalone: true,
    imports: [
        FormsModule,
        CommonModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
    ],
})
export class DateFilterComponent {
    @Input() dateValue: DateFilterValue | undefined;

    public units = ['days', 'weeks', 'months', 'quarters', 'years'];
    @Output() dateValueChange = new EventEmitter<DateFilterValue>();

    public onDateValueChange(): void {
        this.dateValueChange.emit({ ...this.dateValue });
    }
}
