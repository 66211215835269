<cb-dialog dialogHeading="Quote Refs">
    <cb-table [loaded]="true"
              [stringColumns]="columns">
        <tbody cbTableBody>
            <tr *ngIf="isRequestFinished && quoteLine.id > 0">
                <td>{{item.qRef}}</td>
                <cb-td-text [value]="COST_TYPE_ENUM[item.costType]"></cb-td-text>
                <cb-td-text [value]="quoteLine.lineItem"></cb-td-text>
                <cb-td-currency [value]="quoteLine.subTotal"></cb-td-currency>
                <cb-td-text [value]="item.supplierDisplay"></cb-td-text>
                <cb-td-date [value]="quoteLine.quoteDate"></cb-td-date>
                <cb-td-text [value]="quoteLine.quoteNumber"></cb-td-text>
                <cb-td-text [value]="quoteLine.changeNumber"></cb-td-text>
                <cb-td-text [value]="quoteLine.variationNumber"></cb-td-text>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <button mat-icon-button
                            matTooltip="Copy Qref to clipboard"
                            (click)="copyToClipboardClicked()">
                        <mat-icon svgIcon="clipboard-text-play-outline"></mat-icon>
                    </button>
                </td>
            </tr>
            <tr *ngIf="isRequestFinished && !(quoteLine.id > 0)">
                <td colspan="10">
                    <mat-icon class="mat-accent">info</mat-icon>
                    There are no Quote References that include this Lot Spec Item.
                </td>
            </tr>
        </tbody>
    </cb-table>
    <footer class="end-center flex-row">
        <button mat-raised-button
                (click)="cancel()">Close</button>
    </footer>
</cb-dialog>
