<form #userDetailsForm="ngForm">
    <div class="flex-row cb-margin"
         *cbWaitFor="mappedItem">
        <div class="cb-margin-right-2x flex-col flex">
            <h4 class="cb-title">User Details</h4>
            <mat-divider class="cb-margin-bottom">
            </mat-divider>
            <cb-input name="firstName"
                      label="First Name"
                      [required]="true"
                      [maxlength]="150"
                      [readonly]="isView$ | async"
                      [ngModel]="mappedItem.firstName"
                      (ngModelChange)="mappedItem.firstName=$event">
            </cb-input>
            <cb-input name="lastName"
                      label="Last Name"
                      [required]="true"
                      [maxlength]="150"
                      [readonly]="isView$ | async"
                      [ngModel]="mappedItem.lastName"
                      (ngModelChange)="mappedItem.lastName=$event">
            </cb-input>
            <cb-input name="jobTitle"
                      label="Job Title"
                      [required]="true"
                      [maxlength]="150"
                      [readonly]="isView$ | async"
                      [ngModel]="mappedItem.jobTitle"
                      (ngModelChange)="mappedItem.jobTitle=$event">
            </cb-input>
            <cb-input name="company"
                      label="Company"
                      [required]="true"
                      [maxlength]="150"
                      [readonly]="isView$ | async"
                      (ngModelChange)="mappedItem.company=$event"
                      [ngModel]="mappedItem.company">
            </cb-input>
            <cb-input type="email"
                      name="email"
                      label="Email Address"
                      [required]="true"
                      [maxlength]="250"
                      [readonly]="isView$ | async"
                      [ngModel]="mappedItem.email"
                      (ngModelChange)="mappedItem.email=$event">
            </cb-input>
            <cb-input type="text"
                      label="Mobile Phone"
                      name="mobilePhone"
                      [readonly]="isView$ | async"
                      [required]="isMobileMandatory"
                      [(ngModel)]="mappedItem.phoneMobile"
                      maskType="MOBILE">
            </cb-input>
            <cb-input name="activeDirectoryUsername"
                      label="AD Username"
                      [required]="true"
                      [maxlength]="250"
                      [readonly]="isView$ | async"
                      [cbUniqueUserName]="mappedItem"
                      (ngModelChange)="mappedItem.activeDirectoryUsername=$event"
                      [ngModel]="mappedItem.activeDirectoryUsername">
            </cb-input>
            <span *ngIf="userDetailsForm.controls?.activeDirectoryUsername?.errors?.activeDirectoryUsername"
                  class="error-msg mat-caption cb-error"
                  style="margin-top: -15px">
                {{userDetailsForm.controls?.activeDirectoryUsername?.errors?.activeDirectoryUsername}}
            </span>
            <cb-checkbox name="isActive"
                         label="Active User Account"
                         [readonly]="isView$ | async"
                         [ngModel]="mappedItem.isActive"
                         (ngModelChange)="mappedItem.isActive=$event">
            </cb-checkbox>
        </div>
        <div class="cb-margin-right-2x flex-col flex">
            <h4 class="cb-title">Business Entities</h4>
            <mat-divider class="cb-margin-bottom">
            </mat-divider>
            <span class="mat-caption">Choose the Business Entity this User operates in</span>
            <cb-selection-list name="selectedBusinessEntities"
                               [ngModel]="mappedItem?.selectedBusinessEntities"
                               (ngModelChange)="selectedBusinessEntitiesChanged($event, userDetailsForm.form)"
                               [options]="businessEntitiesOptions$ | async"
                               [readonly]="isView$ | async"
                               maxHeight="400px"
                               [slim]="true"
                               [ngClass]="{'cb-margin-bottom-2x': isView$ | async}">
            </cb-selection-list>
            <cb-select label="Primary Business Entity"
                       name="primaryBusinessEntity"
                       [required]="true"
                       *cbWaitFor="mappedItem"
                       [ngModel]="mappedItem.primaryBusinessEntity"
                       (ngModelChange)="mappedItem.primaryBusinessEntity=$event"
                       [options]="selectedBusinessEntitiesOptionsAsDtos$ | async"
                       [readonly]="isView$ | async"
                       [ngClass]="{'cb-margin-bottom-2x': isView$ | async}">
            </cb-select>
        </div>
        <div class="cb-margin-right-2x flex-col flex">
            <h4 class="cb-title">Teams</h4>
            <mat-divider class="cb-margin-bottom"></mat-divider>
            <span class="mat-caption">Select the Teams this User is a part of</span>
            <cb-selection-list name="selectedTeams"
                               [ngModel]="mappedItem.selectedTeams"
                               (ngModelChange)="selectedTeamsChanged($event, userDetailsForm.form)"
                               [options]="teamsOptions$ | async"
                               [readonly]="isView$ | async"
                               [slim]="true"
                               [ngClass]="{'cb-margin-bottom-2x': isView$ | async}">
            </cb-selection-list>
            <h4 class="cb-title">Tags</h4>
            <mat-divider class="cb-margin-bottom"></mat-divider>
            <span class="mat-caption">Associate this User with Tags</span>
            <cb-selection-list name="selectedTags"
                               [ngModel]="mappedItem.selectedTags"
                               (ngModelChange)="selectedTagsChanged($event, userDetailsForm.form)"
                               [options]="userRoleTagsOptions$ | async"
                               [readonly]="isView$ | async"
                               [slim]="true"
                               [ngClass]="{'cb-margin-bottom-2x': isView$ | async}">
            </cb-selection-list>
        </div>
    </div>
</form>
