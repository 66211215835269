<mat-card class="cb-margin flex-col">
    <div class="house-land-flame help-cursor"
         title="Lot Interests"
         *ngIf="lot.numberOfInterests > 0">
        <div class="flame-container">
            <div class="spacer">{{ lot.numberOfInterests }}</div>
            <span class="display-text">{{ lot.numberOfInterests }}</span>
        </div>
    </div>
    <div class="flex-row flex">
        <img mat-card-image
             src="{{ getDisplayImage(lot) }}"
             cbHandleInvalidImage
             [height]="400"
             [width]="600"
             alt="Plan Image"/>
    </div>
    <mat-card-content>
        <div class="lot-info-area flex-col layoutGap-col-10">
            <div class="start-center flex-row layoutGap-20 flex">
                <mat-card-header>
                    <div mat-card-avatar
                         style="margin: auto">
                        <mat-icon *ngIf="lot.marketStatus === 1"
                                  color="accent"
                                  matTooltip="Available">check_circle
                        </mat-icon>
                        <mat-icon *ngIf="lot.marketStatus === 3"
                                  color="accent"
                                  matTooltip="Pre-release">info_circle
                        </mat-icon>
                    </div>
                    <mat-card-title>Lot {{ lot.lotNumber }}</mat-card-title>
                    <mat-card-subtitle>{{ lot.projectName }},
                        {{ lot.addressCity }}</mat-card-subtitle>
                </mat-card-header>
            </div>
            <div class="flex-row layoutGap-10 flex">
                <div class="flex-col flex">
                    <cb-display-value-new class="flex"
                                          label="Bedroom">
                        <div class="start-center">
                            <mat-icon class="cb-grey">hotel</mat-icon>
                            <span class="mat-body-1">&nbsp;{{
                                lot.amenities.noBedrooms | cbDasher
                                }}</span>
                        </div>
                    </cb-display-value-new>
                    <cb-display-value-new class="flex"
                                          label="Living">
                        <div class="start-center">
                            <mat-icon class="cb-grey">weekend</mat-icon>
                            <span class="mat-body-1">&nbsp;{{
                                lot.amenities.noLivingRooms | cbDasher
                                }}</span>
                        </div>
                    </cb-display-value-new>
                </div>
                <div class="flex-col flex">
                    <cb-display-value-new class="flex"
                                          label="Bathroom">
                        <div class="start-center">
                            <mat-icon class="cb-grey">wc</mat-icon>
                            <span class="mat-body-1">&nbsp;{{
                                lot.amenities.noBathrooms | cbDasher
                                }}</span>
                        </div>
                    </cb-display-value-new>
                    <cb-display-value-new class="flex"
                                          label="Garage">
                        <div class="start-center">
                            <mat-icon class="cb-grey">directions_car</mat-icon>
                            <span class="mat-body-1">&nbsp;{{
                                lot.amenities.noGarages | cbDasher
                                }}</span>
                        </div>
                    </cb-display-value-new>
                </div>
                <div class="flex-col flex">
                    <div class="flex-col">
                        <cb-display-value-new class="flex"
                                              label="Floor Area">
                            <div class="start-center">
                                <mat-icon class="cb-grey"
                                          svgIcon="floor-plan"></mat-icon>
                                <span class="mat-body-1">&nbsp;{{
                                    lot.amenities.floorArea | cbDasher
                                    }}m²</span>
                            </div>
                        </cb-display-value-new>
                    </div>
                    <div class="flex-col">
                        <cb-display-value-new class="flex"
                                              label="Land Area">
                            <div class="start-center">
                                <mat-icon class="cb-grey">border_style</mat-icon>
                                <span class="mat-body-1">&nbsp;{{
                                    lot.amenities.landArea | cbDasher
                                    }}m²</span>
                            </div>
                        </cb-display-value-new>
                    </div>
                </div>
            </div>
            <div class="flex-row layoutGap-10 flex">
                <div class="flex-col flex">
                    <cb-display-value-new class="flex"
                                          label="Site">
                        <div class="start-center">
                            <span class="mat-body-1">&nbsp;{{ lot.siteNumber }}</span>
                        </div>
                    </cb-display-value-new>
                </div>
                <div class="flex-col flex">
                    <cb-display-value-new class="flex"
                                          label="Contract Type">
                        <div class="start-center">
                            <span class="mat-body-1">&nbsp;{{ LOT_CONTRACT_TYPE_ENUM[lot.contractType] }}</span>
                        </div>
                    </cb-display-value-new>
                </div>
                <div class="flex-col flex">
                    <cb-display-value-new class="flex"
                                          label="Sell Price">
                        <div class="start-center">
                            <span class="mat-body-1">&nbsp;{{
                                lot.financials.sellPrice
                                | cbCurrency: "addGst"
                                }}</span>
                        </div>
                    </cb-display-value-new>
                </div>
            </div>
        </div>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-actions>
        <div class="cb-margin-bottom cb-margin-right end-center flex-row">
            <button mat-stroked-button
                    color="accent"
                    (click)="viewLot(lot)">
                View Lot
            </button>
        </div>
    </mat-card-actions>
</mat-card>
