<div class="flex-row flex">
    <form class="flex-row flex"
          #houseLandSpecForm="ngForm">
        <div class="flex-col flex">
            <div class="cb-margin end start flex-row"
                 style="margin-bottom: -10px;">
                <cb-input type="text"
                          name="noBedrooms"
                          label="Lot Spec Version"
                          [readonly]="true"
                          class="amenities"
                          [(ngModel)]="mappedItem.specVersion">
                </cb-input>
                <span class="flex"></span>
                <button *ngIf="!isAllAccordionItemsExpanded"
                        mat-raised-button
                        color="primary"
                        (cbClick)="expandAllItems()">
                    <span>
                        Expand All&nbsp;
                        <mat-icon style="vertical-align: middle;">keyboard_arrow_down</mat-icon>
                    </span>
                </button>
                <button *ngIf="isAllAccordionItemsExpanded"
                        mat-raised-button
                        color="primary"
                        (cbClick)="collapseAllItems()">
                    <span>
                        Collapse All&nbsp;
                        <mat-icon style="vertical-align: middle;">keyboard_arrow_up</mat-icon>
                    </span>
                </button>
            </div>
            <div class="accordion-container">
                <mat-accordion multi="true"
                               style="display:block"
                               #firstAccordion="matAccordion">
                    <ng-container *ngFor="let group of mappedItem.$areas">
                        <mat-expansion-panel class="flex"
                                             [(expanded)]="group.expanded"
                                             (expandedChange)="expandedChange($event)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <h1 class="mat-body-2">{{getAreaTitle(group)}}</h1>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="flex-col flex">
                                <cb-table class="flex"
                                          label="Lot Spec Items"
                                          [stringColumns]="displayedColumns"
                                          [loaded]="true"
                                          emptyMessage="There are no Items in this House Area">
                                    <tr cbTableRow
                                        *ngFor="let theItem of getItemsForHouseArea(group.id)">
                                        <ng-container *ngIf="!theItem.item.colourItem">
                                            <td>{{theItem.displayText}}</td>
                                            <td>
                                                <ul class="inline-list"
                                                    *ngIf="theItem.item.offeringOptions && theItem.item.offeringOptions.length > 0">
                                                    <li *ngFor="let opt of theItem.item.offeringOptions">
                                                        {{opt.name}}
                                                    </li>
                                                </ul>
                                                <span class="inline-list"
                                                      *ngIf="!theItem.item.offeringOptions || theItem.item.offeringOptions.length < 1">-</span>
                                            </td>
                                            <td>{{getQuantity(theItem.item)}}</td>
                                        </ng-container>
                                        <ng-container *ngIf="theItem.item.colourItem">
                                            <td>{{theItem.displayText}}</td>
                                            <td>-</td>
                                            <td>-</td>
                                        </ng-container>
                                    </tr>
                                </cb-table>
                            </div>
                        </mat-expansion-panel>
                    </ng-container>
                </mat-accordion>
            </div>
        </div>
    </form>
</div>
