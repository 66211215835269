import { CouncilRfiViewService } from './../service/council-rfi-view.service';
import { Component, Input, OnChanges, EventEmitter, Output, OnInit } from '@angular/core';
import { CouncilRfiLogicService } from '@app/logic/council-rfis';
import { COUNCIL_RFI_STATUS_ENUM, ICouncilRfiDocumentDto, ICouncilRfiDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';

@Component({
    selector: 'cb-council-rfi-list-card',
    templateUrl: './council-rfi-list-card.component.html',
    styleUrls: ['./council-rfi-list-card.component.scss']
})
export class CouncilRfiListCardComponent implements OnInit, OnChanges {
    public rfiNo: number;
    @Input() public councilRfiDocumentDto: ICouncilRfiDocumentDto;
    @Input() public councilRfiDto: ICouncilRfiDto;

    @Output() public readonly viewCouncilRfi = new EventEmitter<number>();
    @Input() public redirect = false;

    public readonly status = new ComputedProperty(() => {
        return {
            icon: (() => {
                switch (parseInt(this.councilRfiDocumentDto?.statusId as any, 10)) {
                    case COUNCIL_RFI_STATUS_ENUM.Complete:
                        return 'check_circle';
                    case COUNCIL_RFI_STATUS_ENUM.InQueue:
                    case COUNCIL_RFI_STATUS_ENUM.InProgress:
                        return 'assignment';
                    case COUNCIL_RFI_STATUS_ENUM.OnHold:
                        return 'pause_circle_filled';
                    case COUNCIL_RFI_STATUS_ENUM.Pending:
                        return 'timelapse';
                    default:
                        return 'timelapse';
                }
            })(),
            class: (() => {
                switch (parseInt(this.councilRfiDocumentDto?.statusId as any, 10)) {
                    case COUNCIL_RFI_STATUS_ENUM.Complete:
                    case COUNCIL_RFI_STATUS_ENUM.OnHold:
                        return 'mat-primary';
                    case COUNCIL_RFI_STATUS_ENUM.InQueue:
                    case COUNCIL_RFI_STATUS_ENUM.InProgress:
                    case COUNCIL_RFI_STATUS_ENUM.Pending:
                        return 'mat-accent';
                    default:
                        return 'mat-accent';
                }
            })(),
            title: (() => {
                switch (parseInt(this.councilRfiDocumentDto?.statusId as any, 10)) {
                    case COUNCIL_RFI_STATUS_ENUM.Complete:
                        return 'Completed';
                    case COUNCIL_RFI_STATUS_ENUM.InQueue:
                        return 'In Queue';
                    case COUNCIL_RFI_STATUS_ENUM.InProgress:
                        return 'In Progress';
                    case COUNCIL_RFI_STATUS_ENUM.OnHold:
                        return 'On Hold';
                    case COUNCIL_RFI_STATUS_ENUM.Pending:
                        return 'Pending';
                    default:
                        return 'none';
                }
            })(),
        };
    });

    constructor(
        public readonly councilRfiLogic: CouncilRfiLogicService,
        public readonly councilRfiViewService: CouncilRfiViewService,
    ) {

    }

    public ngOnInit(): void {
        this.councilRfiDocumentDto = this.councilRfiDocumentDto ?? this.mapCouncilRfoDtoToDocumentDto();
    }

    public ngOnChanges(): void {
        this.status.recompute();
    }

    public downloadLatestRfiDocument(): void {
        this.councilRfiLogic
            .downloadLatestRfiDocument(this.councilRfiDocumentDto.id)
            .subOnce();
    }

    public view(): void {
        if (this.redirect) {
            this.viewCouncilRfi.emit(this.councilRfiDocumentDto?.id);
        } else {
            this.councilRfiViewService.loadCouncilRfi(this.councilRfiDocumentDto.id);
        }
    }

    public mapCouncilRfoDtoToDocumentDto(): ICouncilRfiDocumentDto {
        return {
            id: this.councilRfiDto.id,
            assignedToTeamName: this.councilRfiDto.assignedToTeamLabel,
            assignedToUserName: this.councilRfiDto.assignedToUserName,
            statusId: this.councilRfiDto.statusId,
            rfiDate: this.councilRfiDto.rfiDate,
            externalReference: this.councilRfiDto.externalReference,
            rfiDueDate: this.councilRfiDto.rfiDueDate,
            receivedFrom: this.councilRfiDto.receivedFrom,
            workingDrawingNumber: this.councilRfiDto.workingDrawingNumber
        } as ICouncilRfiDocumentDto;
    }
}
