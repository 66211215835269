import { Computed } from '@app/logic/base/computed-prop.decorator';
import { BaseMappedItem } from '../base/base-mapped-item';
import { ITradeTypeTreeMappedItem } from './interfaces/i.trade-type-tree.mapped';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { ITradeTypesTreeLogicService } from './interfaces/i.trade-types-tree.logic.service';
import { DtoProp } from '../base/dto-prop.decorator';
import { IComplianceRegistrationDto, ITradeTypeTreeDto } from '@classictechsolutions/hubapi-transpiled-enums';

export class TradeTypeTreeMappedItem
    extends BaseMappedItem<ITradeTypeTreeDto, ITradeTypeTreeMappedItem, ITradeTypesTreeLogicService>
    implements ITradeTypeTreeMappedItem {
    @DtoProp public canRequestSevenDayPayments: boolean;
    @DtoProp public includeInLiveSchedule: boolean;
    @DtoProp public id: number;
    @DtoProp public isActive: boolean;
    @DtoProp public label: string;
    @DtoProp public parentId: number;
    @DtoProp public parentLabel: string;
    @DtoProp public requiredComplianceRegistrations: IComplianceRegistrationDto[];
    /** do not use, only for computing mappedChildren */
    @DtoProp public children: ITradeTypeTreeDto[];

    private _mappedChildrenStore: ITradeTypeTreeMappedItem[] = [];
    @Computed() public get mappedChildren(): ITradeTypeTreeMappedItem[] {
        if (this._mappedChildrenStore == null) {
            this._mappedChildrenStore = [];
        }
        if (this.children != null) {
            this._mappedChildrenStore = this.children.map(x => this.$logicService.$createMappedItem(x));
        }
        this.filteredChildren = this._mappedChildrenStore;
        return this._mappedChildrenStore;
    }

    public filteredChildren: ITradeTypeTreeMappedItem[];

    constructor(
        sourceData: ITradeTypeTreeDto,
        logicService: ITradeTypesTreeLogicService
    ) {
        super(sourceData, logicService, TradeTypeTreeMappedItem, {
            requiredComplianceRegistrations: [],
            isActive: true,
            canRequestSevenDayPayments: false,
            includeInLiveSchedule: false,
        });
    }

    protected $preSave(toSave: ITradeTypeTreeDto): void {
        if (!toSave.parentId) {
            toSave.requiredComplianceRegistrations = undefined;
            toSave.canRequestSevenDayPayments = undefined;
            toSave.includeInLiveSchedule = undefined;
        }
    }

    public filterChildren(query?: string | null): void {
        if (isNullOrWhiteSpace(query)) {
            this.filteredChildren = this.mappedChildren;
            return;
        }
        this.filteredChildren = this.mappedChildren.filter(x => {
            return x.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
    }
}
