<cb-dialog>
    <form class="flex-col flex"
          #bundleTemplateItemForm="ngForm">
        <cb-input type="text"
                  name="name"
                  label="Name"
                  [required]="true"
                  [(ngModel)]="item.name">
        </cb-input>
        <cb-product-category-autocomplete-scroller class="cb-margin-top"
                                                   #autocompleteControl
                                                   label="Categories"
                                                   name="categories"
                                                   [required]="true"
                                                   [(ngModel)]="selectedProductCategoryModel"
                                                   [requireMatch]="true"
                                                   [matchProps]="['id']"
                                                   (optionSelected)="productCategorySelected($event)">
        </cb-product-category-autocomplete-scroller>
        <cb-checkbox name="isLocked"
                     label="Is Locked"
                     [(ngModel)]="item.isLocked">
        </cb-checkbox>
        <cb-checkbox name="isRequired"
                     label="Is Required"
                     [(ngModel)]="item.isRequired">
        </cb-checkbox>
    </form>
    <footer class="flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="apply()"
                [disabled]="getDisabled()">
            Apply
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
