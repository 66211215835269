<cb-dialog class="flex-col"
           dialogHeading="Download Multiple Documents"
           [canClose]="!downloading">
    <cb-table [loaded]="loaded"
              maxHeightOffset="310">
        <thead cbTableHead>
            <tr>
                <td></td>
                <td *ngFor="let extraColumn of data.prefixColumns">{{extraColumn.label}}</td>
                <td>Name</td>
                <td>Type</td>
            </tr>
        </thead>
        <tbody cbTableBody>
            <tr *ngFor="let element of downloadableDocuments"
                class="overflow-hidden">
                <td class="overflow-hidden">
                    <mat-icon class="cb-grey"
                              *ngIf="!downloading && !successful[element.id] && !failed[element.id]"
                              matTooltip="Download not started">radio_button_unchecked</mat-icon>
                    <mat-spinner *ngIf="downloading && !successful[element.id] && !failed[element.id]"
                                 diameter="20"
                                 matTooltip="Download in progress"></mat-spinner>
                    <mat-icon *ngIf="successful[element.id]"
                              color="primary"
                              matTooltip="Download Succeded">check_circle</mat-icon>
                    <mat-icon *ngIf="failed[element.id]"
                              class="cb-error"
                              [matTooltip]="failed[element.id]">cancel</mat-icon>
                </td>
                <td *ngFor="let extraColumn of data.prefixColumns"
                    class="overflow-hidden">
                    <dl>
                        <dd class="mat-body-1"
                            *ngFor="let prop of extraColumn.props">{{element[prop]}}</dd>
                    </dl>
                </td>
                <td class="overflow-hidden">
                    <cb-info-popup *ngIf="element.document.isLinkedDocument"
                                   [icon]="'linked'"
                                   [tooltip]="'This is a Linked Document'">
                    </cb-info-popup>
                    <cb-info-popup *ngIf="element.document"
                                   [label]="element.document.name"
                                   [tooltip]="element.document.description">
                    </cb-info-popup>
                </td>
                <td class="overflow-hidden">
                    <cb-info-popup [label]="element.documentType.label"
                                   [tooltip]="element.documentType.description">
                    </cb-info-popup>
                </td>
            </tr>
        </tbody>
    </cb-table>
    <footer class="start-center flex-row">
        <form #form="ngForm"
              class="cb-margin-right flex-col flex">
            <cb-input class="flex"
                      label="Zip File Name"
                      name="zipFileName"
                      [(ngModel)]="zipFileName"
                      [required]="true"></cb-input>
        </form>
        <button mat-raised-button
                color="primary"
                (cbClick)="download()"
                [throttleTime]="2000"
                [disabled]="form.invalid || downloading">Download</button>
        <button mat-raised-button
                [disabled]="downloading"
                (click)="cancel()">Close</button>
    </footer>
</cb-dialog>
