import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { PermissionsPermissions } from '@app/core/permissions';
import { ContactsLogicService } from '@app/logic/contacts';
import { IContactMappedItem } from '@app/logic/contacts/interfaces/i.contact.mapped';
import { IContactDto, ISkinnyBusinessAccountContactDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { toPromise } from 'cb-hub-lib';
import { switchMap } from 'rxjs';
import { ContactCreateLoginDialogComponent } from '../contact-create-login-dialog/contact-create-login-dialog.component';

@Component({
    selector: 'cb-my-classic',
    templateUrl: './my-classic.component.html',
    styleUrls: ['./my-classic.component.scss']
})
export class MyClassicComponent implements OnInit {

    private _contact: IContactMappedItem;
    @Input()
    public set contact(contact: IContactMappedItem) {
        this._contact = contact;
        this.checkExistingActiveUserWithDuplicateEmail(contact);
    };

    public get contact(): IContactMappedItem {
        return this._contact;
    }

    public readonly displayedColumns: string[] = [
        'tradingName',
        'type',
        'actions',
    ];

    public businessAccounts: ISkinnyBusinessAccountContactDto[];
    public businessAccountsLoaded = false;
    public hasExistingActiveUserWithDuplicateEmail: boolean;

    constructor(
        public readonly matDialog: MatDialog,
        protected readonly contactsLogicService: ContactsLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly route: ActivatedRoute) {
    }

    public ngOnInit(): void {
        toPromise(this.route.params.pipe(
            switchMap((params: { id: string }) => {
                return this.contactsLogicService.getBusinessAccountsList(params.id);
            })
        )).then((arr: ISkinnyBusinessAccountContactDto[]) => {
            this.businessAccounts = arr;
            this.businessAccountsLoaded = true;
        });
    }

    public checkExistingActiveUserWithDuplicateEmail(contact: IContactMappedItem): void {
        this.contactsLogicService
            .findExistingByEmailAddress(contact.email)
            .subOnce((results) => {
                const duplicates = results.filter(x => x.id !== contact.id);
                this.hasExistingActiveUserWithDuplicateEmail = duplicates?.length > 0;
            });
    };

    public createLogin(): void {
        const clone = this.contact.$clone();
        this.matDialog.open(ContactCreateLoginDialogComponent, {
            data: {mappedItem: clone, mode: 'createLogin'},
            panelClass: 'cb-dialog-container',
            minWidth: 400
        })
            .afterClosed()
            .subOnce(this.handleLoginChanged);
    }

    public changeUsername(): void {
        const clone = this.contact.$clone();
        this.matDialog.open(ContactCreateLoginDialogComponent, {
            data: { mappedItem: clone, mode: 'changeUsername' },
            panelClass: 'cb-dialog-container',
            minWidth: 400
        })
            .afterClosed()
            .subOnce({
                next: this.handleLoginChanged
            });
    }

    public resetPassword(): void {
        const clone = this.contact.$clone();
        this.matDialog.open(ContactCreateLoginDialogComponent, {
            data: { mappedItem: clone, mode: 'resetPassword' },
            panelClass: 'cb-dialog-container',
            minWidth: 400
        })
            .afterClosed()
            .subOnce({
                next: this.handleLoginChanged
            });
    }

    public handleLoginChanged = (result: IContactDto | any): void => {
        if (result) {
            result.id = result.id ?? result.contactId;
            result.portalUsername = result.username;
            result.contactId = result.contactId ?? result.id;
            this.contact = this.contactsLogicService.$createMappedItem(result);
            this.contact.id = result.id ?? result.contactId;
        }
    };

    public enableAccess(enableAccess: boolean): void {

        if (!this.contact.isPortalAccessEnabled && this.hasExistingActiveUserWithDuplicateEmail) {
            return;
        } else {
            this.contactsLogicService.activatePortalAccess({activate: enableAccess, contactId: this.contact.id})
                .subOnce(returnValue => {
                    this.checkExistingActiveUserWithDuplicateEmail(this.contact);
                    this.contact.isPortalAccessEnabled = enableAccess;
                });
        }
    }

}
