import { ComputedProperty } from './../../../../../shared/utils/computed-property.util';
import { Component, OnInit, Inject } from '@angular/core';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { LotBuildPermissions } from '@app/core/permissions';
import { IProductDto } from '@app/logic/products/interfaces/i.product.dto';
import { IProductMappedItem, ProductLogicService } from '@app/logic/products';
import { IProductLogicService } from '@app/logic/products/interfaces/i.product.logic.service';
import { FormMode } from '@app/shared/enums/form';
import { PRODUCT_TYPE_ENUM, OFFERING_STATUS_ENUM, UNIT_OF_MEASURE_ENUM, IBundleTemplateDto, ITradeTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { BundleTemplatesLogicService } from '@app/logic/bundle-templates';
import { IProductCategoryDto } from '@app/logic/product-categories/interfaces/i.product-category.dto';
import { find, reduce, filter } from 'lodash';
import { TradeTypesLogicService } from '@app/logic/trade-types/trade-types.logic.service';
import { tap } from 'rxjs';
import { Debounce } from '@app/shared/decorators/debounce.decorator';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';

interface IData {
    mappedItem: IProductMappedItem;
}

@Component({
    templateUrl: './manage-product-dialog.component.html',
    styleUrls: ['./manage-product-dialog.component.scss'],
    providers: [
        LookupService,
        BundleTemplatesLogicService,
        ProductLogicService,
        TradeTypesLogicService
    ]
})
export class ManageProductDialogComponent
    extends BaseDialogFormViewDirective<IProductDto, IProductMappedItem, IProductLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '600px';
    public selectedCategory: any;
    public uom = UNIT_OF_MEASURE_ENUM.toLookup().sort((a, b) => a.label.localeCompare(b.label));
    public PRODUCT_TYPE_ENUM = PRODUCT_TYPE_ENUM;
    public OFFERING_STATUS_ENUM = OFFERING_STATUS_ENUM;
    public selectedTradeTypeModel: ITradeTypeDto;
    public initialBundleTemplateId = 0;
    public bundleTemplates: Array<IBundleTemplateDto>;
    public selectedProductCategoryModel: IProductCategoryDto | any;
    public inheritedColourAttribute = false;
    public bundleTemplateChanged: ($event) => void;
    public selectedProductModel: IProductDto;
    public originalSelectedProductModel: IProductDto;
    public selectedProduct: IProductDto;
    public compositeSelected: boolean;
    public tradeTypes;

    public readonly tradeTypeSelectDisabled = new ComputedProperty(() => {
        const isLabourCompositeItemParent = this.selectedProductModel?.tradeTypeId > 0 && this.selectedProductModel?.productType === PRODUCT_TYPE_ENUM.Labour;
        const isExistingItem = this.mappedItem?.id > 0;
        return this.mappedItem?.compositeItemId > 0
            && (isLabourCompositeItemParent || isExistingItem);
    });

    constructor(
        public readonly dialogRef: MatDialogRef<ManageProductDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(LookupService) public readonly lookupService: LookupService,
        @Inject(LotBuildPermissions) public readonly lotBuildPermissions: LotBuildPermissions,
        protected readonly bundleTemplatesLogicService: BundleTemplatesLogicService,
        protected readonly productsLogicService: ProductLogicService,
        protected readonly tradeTypesLogicService: TradeTypesLogicService,
        protected readonly cbDialog: CbDialogService,
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;

        if (this.mappedItem.id) {
            this.formMode = FormMode.Edit;
        } else {
            this.formMode = FormMode.Add;
        }

        this.tradeTypesLogicService
            .$getList()
            .pipe(
                tap(this.setSelectedTradeType)
            )
            .subOnce(this.filterTradeTypes);
    }

    public setSelectedTradeType = (tradeTypes): void => {
        this.selectedTradeTypeModel = tradeTypes.find(tt => tt.id === this.mappedItem.tradeTypeId);
    };

    public ngOnInit(): void {
        this.setUpCategory();
        this.setInheritedColourAttribute();
        this.setupCompositeItem();
        this.tradeTypeSelectDisabled.recompute();
    }

    public defaultFields(): void {
        if (!this.selectedProductModel?.id) {
            this.tradeTypeSelectDisabled.recompute();
            return;
        }

        if (!this.mappedItem.name) {
            this.mappedItem.name = this.selectedProductModel.name;
        }

        if (!this.mappedItem.offeringCategoryId) {
            this.mappedItem.offeringCategoryId = this.selectedProductModel.offeringCategoryId;
            this.mappedItem.categoryPath = this.selectedProductModel.categoryPath;
            this.setInheritedColourAttribute();
            this.setUpCategory();
            this.productCategorySelected(this.selectedProductCategoryModel);
        }

        if (!this.mappedItem.uom) {
            this.mappedItem.uom = this.selectedProductModel.uom;
            this.mappedItem.uomDisplay = this.selectedProductModel.uomDisplay;
        }

        if (!this.mappedItem.clientSpecDescription) {
            this.mappedItem.clientSpecDescription = this.selectedProductModel.clientSpecDescription;
        }

        if (this.mappedItem.isLabour() && (!this.mappedItem.tradeTypeId || this.selectedProductModel.productType === PRODUCT_TYPE_ENUM.Labour)) {
            this.mappedItem.tradeTypeId = this.selectedProductModel.tradeTypeId;
            if (this.mappedItem.tradeTypeId > 0) {
                this.tradeTypesLogicService.$getItem(this.mappedItem.tradeTypeId).subOnce(tradeType => {
                    this.selectedTradeTypeModel = tradeType;
                    this.mappedItem.tradeType = tradeType;
                });
            }
        }
        this.tradeTypeSelectDisabled.recompute();
    }

    public productSelected = (value: IProductDto | null): void => {
        if (!value) {
            return;
        }
        if (this.mappedItem.id > 0 && value.productType === PRODUCT_TYPE_ENUM.Labour) {
            this.cbDialog.confirm({
                dialogHeading: 'Confirm Composite Item Selection',
                message: 'The Trade Type on this Item does not match the Composite Item, do you want the the Trade Type updated to match the Composite Item?',
                confirmed: () => this.assignCompositeItemId(value),
                declined: this.resetSelectedProduct
            });
        } else {
            this.assignCompositeItemId(value);
        }
    };

    @Debounce(500)
    private assignCompositeItemId(value: IProductDto): void {
        this.mappedItem.compositeItemId = value.id;
        this.defaultFields();
    }

    private readonly resetSelectedProduct = (): void => {
        const original = this.originalSelectedProductModel ? { ...this.originalSelectedProductModel } : null;
        this.selectedProductModel = original as IProductDto;
        this.clearProduct();
        this.defaultFields();
    };

    public clearProduct(): void {
        if (typeof (this.selectedProductModel) === 'string') {
            this.mappedItem.compositeItemId = null;
        }
        this.tradeTypeSelectDisabled.recompute();
    }

    private readonly setUpCategory = (): void => {
        if (this.mappedItem.categoryPath && this.mappedItem.categoryPath.length > 0) {
            this.selectedProductCategoryModel = {};
            this.selectedProductCategoryModel.id = this.mappedItem.offeringCategoryId;
        } else {
            this.selectedProductCategoryModel = undefined;
        }
        if (this.mappedItem.categoryPath) {
            if (!this.selectedProductCategoryModel) {
                this.selectedProductCategoryModel = {};
            }
            this.selectedProductCategoryModel.treeDisplay = reduce(
                this.mappedItem.categoryPath,
                (result, cat, idx) => {
                    if (typeof cat === 'string') {
                        return `${result}${(idx > 0 ? ' > ' : '')}${cat}`;
                    } else {
                        return `${result}${(idx > 0 ? ' > ' : '')}${cat.label}`;
                    }
                },
                '');
        }
    };

    private setupCompositeItem(): void {
        if (this.mappedItem.compositeItemId) {
            this.selectedProductModel = {} as any;
            this.selectedProductModel.id = this.mappedItem.compositeItemId;
            this.selectedProductModel.uomDisplay = '';
            this.productsLogicService.$getItem(this.selectedProductModel.id).subOnce({
                next: (x) => {
                    this.selectedProductModel = x;
                    this.selectedProductModel.uomDisplay = UNIT_OF_MEASURE_ENUM[x.uom];
                }
            });
            this.originalSelectedProductModel = { ...this.selectedProductModel };
            this.tradeTypeSelectDisabled.recompute();
        }
    }

    protected filterTradeTypes = (tradeTypes: Array<any>) => {
        this.tradeTypes = filter(tradeTypes, (trade) => {
            if (trade.id === this.mappedItem.tradeTypeId && !trade.isActive) {
                trade.label = `${trade.label} (inactive)`;
                return true;
            }
            return trade.isActive;
        });
        return this.tradeTypes;
    };

    public tradeTypeSelected(value: ITradeTypeDto): void {
        this.mappedItem.tradeTypeId = value.id;
    }

    private setInheritedColourAttribute(): void {
        const colourItem = find(this.mappedItem.restrictions, (item) => {
            return item.name === 'Colour';
        });
        if (colourItem !== undefined) {
            this.inheritedColourAttribute = true;
            this.mappedItem.manualColourEntryRequired = false;
        }
    }

    public productCategorySelected(value: IProductCategoryDto | void | any): void {
        if (value) {
            this.mappedItem.offeringCategoryId = value.id;
            this.mappedItem.categoryPath = value.treeDisplay;
        }
        this.setInheritedColourAttribute();
    }

    public isProductOrLabourItem = (item: IProductMappedItem): boolean => {
        return item.isLabour() || item.isProduct();
    };
}
