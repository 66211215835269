<cb-dialog dialogHeading="Reassign for Approval">
    <form class="flex-col flex"
          #reassignConsentForm="ngForm">
        <div class="cb-margin-top-2x cb-margin-bottom-2x">
            <label>Reassign to</label>
            <mat-radio-group name="gender"
                             [required]="true"
                             class="vertical-radio-group"
                             [(ngModel)]="approval">
                <mat-radio-button [value]="'owner'"
                                  class="vertical-radio-button">Change Owner - {{mappedItem.owner}}
                </mat-radio-button>
                <mat-radio-button [value]="'someoneElse'"
                                  class="vertical-radio-button">Someone Else</mat-radio-button>
            </mat-radio-group>
        </div>
        <ng-container *ngIf="isTeamAndUserRequired()">
            <cb-select label="Team"
                       name="assignedTeam"
                       [(ngModel)]="mappedItem.assignedTeamId"
                       [options]="teams"
                       [required]="true"
                       (change)="teamSelected()"></cb-select>
            <cb-user-autocomplete-scroller class="flex"
                                           label="User"
                                           name="user"
                                           [(ngModel)]="assignedUser"
                                           [requireMatch]="true"
                                           [matchProps]="['id']"
                                           [team]="[assignedTeam?.key]"
                                           [active]="true"
                                           [required]="true">
            </cb-user-autocomplete-scroller>
        </ng-container>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="reassignConsentForm.invalid"
                (cbClick)="save()"
                [clickLimit]="1">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
