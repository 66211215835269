<cb-dialog>
    <form class="flex-col flex"
          #specTemplateForm="ngForm">
        <cb-input label="Base Template"
                  *ngIf="data.baseTemplateName"
                  [readonly]="true"
                  [ngModel]="data.baseTemplateName"
                  [ngModelOptions]="{standalone: true}"></cb-input>
        <cb-input label="Name"
                  name="name"
                  [required]="true"
                  [(ngModel)]="mappedItem.name"></cb-input>
        <mat-radio-group class="cb-margin-top-2x flex-col flex"
                         [(ngModel)]="templateType"
                         [ngModelOptions]="{standalone: true}">
            <mat-radio-button class="mat-accent"
                              [value]="0"
                              [disabled]="mappedItem.isRegional && mappedItem.id != null">Nationwide Template
            </mat-radio-button>
            <mat-radio-button class="cb-margin-top business-enitities-radio"
                              [value]="1">
                Regional Template
                <div class="flex-col flex">
                    <cb-checkbox *ngFor="let item of businessEnitities"
                                 [label]="item.name"
                                 [name]="'region'+item.id"
                                 [disabled]="templateType !== 1"
                                 [ngModel]="isRegionChecked(item.id)"
                                 (change)="templateType=1;onRegionChanged(item.id, $event)"></cb-checkbox>
                </div>
            </mat-radio-button>
            <mat-radio-button class="cb-margin-top projects-radio"
                              [value]="2">
                <div class="flex-row flex">
                    Project Template
                    <cb-select label="Project"
                               name="project"
                               [options]="projects"
                               [disabled]="templateType !== 2"
                               [(ngModel)]="mappedItem.projectId"
                               (change)="templateType=2"></cb-select>
                </div>
            </mat-radio-button>
        </mat-radio-group>
        <cb-checkbox label="Active"
                     name="isActive"
                     class="cb-margin-top-2x"
                     [(ngModel)]="mappedItem.isActive"></cb-checkbox>
    </form>
    <footer class="flex-row flex center-center">
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="onSaveClicked()"
                [disabled]="!specTemplateForm.dirty || !specTemplateForm.valid">Save</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
