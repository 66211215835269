<div class="cb-margin"
     *cbWaitFor="cacheLoaded$ | async">
    <mat-card class="cb-margin-bottom flex-col">
        <form #form="ngForm">
            <div class="start-center flex-row flex">
                <mat-icon>search</mat-icon>
                <cb-input type="text"
                          label="Search"
                          [(ngModel)]="searchFiltersCache.data.query"
                          (ngModelChange)="searchFiltersChanged$.next($event)"
                          name="search"
                          class="cb-margin-right-2x flex">
                </cb-input>
                <cb-select label="Assigned To"
                           name="userId"
                           *ngIf="designTeamPermissions.canSeeAll()"
                           [(ngModel)]="searchFiltersCache.data.userId"
                           [options]="teamUsersOptions$ | async"
                           class="cb-margin-right-2x">
                </cb-select>
                <cb-select-list label="Status"
                                name="statusId"
                                class="cb-margin-right-2x flex"
                                [allOptions]="preconsentPlanStatuses$ | async"
                                [multiple]="true"
                                [(value)]="searchFiltersCache.data.statusId">
                </cb-select-list>
                <cb-select label="Order"
                           name="resultOrders"
                           [(ngModel)]="searchFiltersCache.data.order"
                           [options]="resultOrders$ | async"
                           class="cb-margin-right-2x">
                </cb-select>
                <cb-select class="cb-margin-right"
                           label="Direction"
                           [(ngModel)]="searchFiltersCache.data.direction"
                           name="direction"
                           [options]="resultDirections$ | async">
                </cb-select>
                <span class="flex"></span>
                <cb-button class="cb-margin"
                           (clicked)="openDesignTeamWorkLoadDialog()">
                    Workload
                </cb-button>
            </div>
        </form>
        <cb-table class="cb-margin-top flex"
                  label="Pre-consent Plans"
                  maxHeightOffset="280"
                  [infiniteScrollEnabled]="infiniteScrollEnabled"
                  [fetch]="fetchResults()"
                  [loaded]="loaded"
                  [queryUpdated]="searchFiltersChanged$"
                  [(results)]="results"
                  [(currentPage)]="currentPage">
            <thead cbTableHead>
                <tr>
                    <td>Job #</td>
                    <td>Lot #</td>
                    <td>Project</td>
                    <td>Address</td>
                    <td>Client Account Name</td>
                    <td>Plan</td>
                    <td>Complexity</td>
                    <td>Created</td>
                    <td>Due</td>
                    <td>Status</td>
                    <td>Assigned To</td>
                    <td></td>
                </tr>
            </thead>
            <tbody cbTableBody>
                <tr *ngFor="let preconsentPlan of results">
                    <cb-td-text [value]="preconsentPlan.jobNumber"></cb-td-text>
                    <cb-td-text [value]="preconsentPlan.lotNumber"></cb-td-text>
                    <cb-td-text [value]="preconsentPlan.projectName"></cb-td-text>
                    <cb-td-text [value]="getStreetAddress(preconsentPlan)"></cb-td-text>
                    <cb-td-text [value]="preconsentPlan.clientAccountName"></cb-td-text>
                    <cb-td-text [value]="preconsentPlan.planName"></cb-td-text>
                    <cb-td-text [value]="preconsentPlan.designComplexityName"></cb-td-text>
                    <cb-td-date [value]="preconsentPlan.createdDate"></cb-td-date>
                    <cb-td-date [value]="preconsentPlan.dueDate"></cb-td-date>
                    <cb-td-text [value]="preconsentPlan.preConsentPlanStatusName"></cb-td-text>
                    <cb-td-text [value]="preconsentPlan.assignedToUserName"></cb-td-text>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <cb-button icon="pageview"
                                   (clicked)="viewPlan(preconsentPlan.lotId)">
                        </cb-button>
                    </td>
                </tr>
            </tbody>
        </cb-table>
    </mat-card>
</div>
