<ng-container *cbWaitFor="lotMappedItem$$ | async">
    <cb-design-concept-search *ngIf="!selectedItem"
                              (designConceptSelected)="designConceptSelected($event)"
                              [lotId]="lotMappedItem.id">
    </cb-design-concept-search>
    <cb-design-concept *ngIf="selectedItem"
                       [(designConcept)]="selectedItem"
                       (designConceptChange)="cdRef.detectChanges()"
                       (lotMappedItemChange)="lotMappedItemChanged()"
                       (backToSearch)="goBackToSearch()"
                       [lot]="lotMappedItem">
    </cb-design-concept>
</ng-container>
