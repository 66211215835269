import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { UsersLogicService } from '@app/logic/users/users.logic.service';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { BehaviorSubject, debounceTime, defaultIfEmpty, filter, Observable, startWith, Subject, Subscription } from 'rxjs';
import { InputFindType } from '../../../input-find-type.enum';
import { IUserDto } from './../../../../../../../logic/users/interfaces/i.user.dto';

interface IData {
    mappedItem: any;
    extraSearchParams: { [key: string]: any };
    dialogHeading: string;
    contentHeading: string;
    placeholder: string;
}

@Component({
    selector: 'cb-user-input-find-dialog-table',
    templateUrl: './users-input-find-dialog-table.component.html',
    styleUrls: ['./users-input-find-dialog-table.component.scss'],
    providers: [UsersLogicService]
})
export class UsersInputFindDialogTableComponent implements OnInit, OnDestroy {
    public static readonly MIN_WIDTH = '900px';

    @Input() public entity: 'user';
    @Input() public entityId: number;
    public results: any[] = [];
    public type: InputFindType = InputFindType.User;
    public readonly queryUpdated = new Subject();

    public searchText = new UntypedFormControl();
    public mappedItem: any;
    public extraSearchParams: { [key: string]: any };
    public dialogHeading: string;
    public contentHeading: string;
    public placeholder: string;
    public currentPage: number;
    public searchEnabled = true;
    public readonly subscriptions = new Subscription();
    public infiniteScrollEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(
        public readonly cbDialog: MatDialogRef<UsersInputFindDialogTableComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        protected readonly logicService: UsersLogicService,
        public readonly navigationService: NavigationService,
    ) {
        this.mappedItem = data.mappedItem;
        this.extraSearchParams = data.extraSearchParams;
        this.dialogHeading = data.dialogHeading;
        this.contentHeading = data.contentHeading;
        this.placeholder = data.placeholder;
    }

    public ngOnInit(): void {
        this.createSearchTextSub();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public selectionChosen(val: IUserDto): void {
        this.cbDialog.close(val);
    }

    private createSearchTextSub(): void {
        this.subscriptions.add(
            this.searchText.valueChanges
                .pipe(
                    defaultIfEmpty(''),
                    startWith(''),
                    debounceTime(400),
                    filter(q => typeof q === 'string')
                )
                .subscribe(() => {
                    this.cleanSearch();
                })
        );
    }

    public search(): Observable<ISearchResult<any>> {
        return this.logicService.search({
            query: this.searchText.value || '',
            currentPage: this.currentPage,
            ...this.extraSearchParams
        }, false);
    }

    public cleanSearch(): void {
        this.searchEnabled = true;
        this.queryUpdated.next(null);
    }

    public cancel(): void {
        this.cbDialog.close(false);
    }

    public viewItem = (id): void => {
        this.navigationService.navigate([`contacts/${id}/summary`]);
    };
}
