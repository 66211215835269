import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbDocumentsModule } from '@app/shared/components/documents/documents.module';
import { CbFilterChipsModule } from '@app/shared/components/filter-chips/filter-chips.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbHistoryModule } from '@app/shared/components/history/history.module';
import { CbImageButtonModule } from '@app/shared/components/image-handlers/image-button/image-button.module';
import { CbImageGalleryModule } from '@app/shared/components/image-handlers/image-gallery/image-gallery.module';
import { CbLightboxModule } from '@app/shared/components/image-handlers/lightbox/lightbox.module';
import { CbSideBarModule } from '@app/shared/components/side-bar/side-bar.module';
import { CbSortableHeaderCellModule } from '@app/shared/components/sortable-header-cell/sortable-header-cell.module';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { CbButtonControlModule } from '@app/shared/directives/button-control/button-control.module';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { CbTabRouteModule } from '@app/shared/directives/tab-route/tab-route.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { CbCallbackModule } from '@app/shared/pipe/callback/callback.module';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { CbEnumToLabelModule } from '@app/shared/pipe/enum-to-label/enum-to-label.module';
import { CbPrettyBoolModule } from '@app/shared/pipe/pretty-bool/pretty-bool.module';
import { CbSortByModule } from '@app/shared/pipe/sort-by/sort-by.module';
import { ProductRateExceptionTabComponent } from '@app/views/products/tabs/product-rate-exceptions/product-rate-exception-tab.component';
import {
    CbDasherModule,
    CbInfoMessageModule,
    CbInfoPopupModule,
    CbLoadingSpinnerModule,
    CbSelectListModule,
    CbTableModule
} from 'cb-hub-lib';
import { DragulaModule } from 'ng2-dragula';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ManageBundleDialogComponent } from './tabs/product-catalogue/product-catalogue-item-dialog/manage-bundle-dialog.component';
import { ManageProductDialogComponent } from './tabs/product-catalogue/product-catalogue-item-dialog/manage-product-dialog.component';
import { ProductCatalogueTabComponent } from './tabs/product-catalogue/product-catalogue-tab.component';
import { ManageCategoryAttributeDialogComponent } from './tabs/product-categories/manage-category-attribute-dialog/manage-category-attribute-dialog.component';
import { ManageProductCategoryDialogComponent } from './tabs/product-categories/manage-product-category-dialog/manage-product-category-dialog.component';
import { ProductCategoriesTabComponent } from './tabs/product-categories/product-categories-tab.component';
import { CostxExportDateDialogComponent } from './tabs/product-export/costx-export-date-dialog/costx-export-date-dialog.component';
import { CostxExportComponent } from './tabs/product-export/costx-export/costx-export.component';
import { ManageCostxExportDialogComponent } from './tabs/product-export/manage-costx-export-dialog/manage-costx-export-dialog.component';
import { ProductExportTabComponent } from './tabs/product-export/product-export-tab.component';
import { ProductUsageExportComponent } from './tabs/product-export/tabs/product-usage-export.component';
import { ProductHistoryTabComponent } from './tabs/product-history/product-history-tab.component';
import { ProductImportTabComponent } from './tabs/product-import/product-import-tab.component';
import { ProductCatalogueItemsTabComponent } from './tabs/product-import/tabs/product-catalogue-items/product-catalogue-items-tab.component';
import { ProductRatesTabComponent } from './tabs/product-import/tabs/product-rates/product-rates-tab.component';
import { ProductTabsComponent } from './tabs/product-tabs/product-tabs.component';
import { AddProductToBundleDialogComponent } from './view-product/dialogs/add-product-to-bundle-dialog/add-product-to-bundle-dialog.component';
import { AddProductToTemplatedBundleDialogComponent } from './view-product/dialogs/add-product-to-templated-bundle-dialog/add-product-to-templated-bundle-dialog.component';
import { AttributeOptionsDialogComponent } from './view-product/dialogs/attribute-options-dialog/attribute-options-dialog.component';
import { ManageAvailabilityDialogComponent } from './view-product/dialogs/manage-availability-dialog/manage-availability-dialog.component';
import { ManageImagesDialogComponent } from './view-product/dialogs/manage-images-dialog/manage-images-dialog.component';
import { ManageRatesDialogComponent } from './view-product/dialogs/manage-rates-dialog/manage-rates-dialog.component';
import { RateCompositionDialogComponent } from './view-product/dialogs/rate-composition-dialog/rate-composition-dialog.component';
import { ItemUsageTabComponent } from './view-product/tabs/item-usage/item-usage-tab.component';
import { ItemUsageBuildProgrammeTabComponent } from './view-product/tabs/item-usage/tabs/build-programme/item-usage-build-programme-tab/item-usage-build-programme-tab.component';
import { ItemUsageConfirmedQuantityComponent } from './view-product/tabs/item-usage/tabs/confirmed-quantity/item-usage-confirmed-quantity/item-usage-confirmed-quantity.component';
// eslint-disable-next-line max-len
import { ItemUsageLotSpecificationTabComponent } from './view-product/tabs/item-usage/tabs/lot-specification/item-usage-lot-specification-tab/item-usage-lot-specification-tab.component';
import { ItemUsagePurchaseOrderTabComponent } from './view-product/tabs/item-usage/tabs/purchase-order/item-usage-purchase-order-tab/item-usage-purchase-order-tab.component';
// eslint-disable-next-line max-len
import { PurchaseOrderUsageReportDialogComponent } from './view-product/tabs/item-usage/tabs/purchase-order/purchase-order-usage-report-dialog/purchase-order-usage-report-dialog/purchase-order-usage-report-dialog.component';
import { ItemUsageSpecTemplateTabComponent } from './view-product/tabs/item-usage/tabs/spec-template/item-usage-spec-template-tab.component';
import { ItemUsageSummaryCardComponent } from './view-product/tabs/item-usage/tabs/summary/item-usage-summary-card.component';
import { ItemUsageSummaryTabComponent } from './view-product/tabs/item-usage/tabs/summary/item-usage-summary-tab.component';
import { ProductDetailsTabComponent } from './view-product/tabs/product-details/product-details-tab.component';
import { ViewProductComponent } from './view-product/view-product/view-product.component';

import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ProductBuildOverheadCostsComponent } from '@app/views/products/tabs/product-build-overhead-costs/product-build-overhead-costs.component';
import {
    ProductBuildOverheadCostsDialogComponent
} from '@app/views/products/tabs/product-build-overhead-costs/product-build-overhead-costs-dialog/product-build-overhead-costs-dialog.component';
import { SelectionCentreComponent } from './tabs/selection-centre/selection-centre.component';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacySliderModule } from '@angular/material/legacy-slider';
import { MatLegacyPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { SelectionCentreDialogComponent } from './tabs/selection-centre/selection-centre-dialog/selection-centre-dialog.component';
import { RelatedSlotsComponent } from './tabs/selection-centre/related-slots/related-slots.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatBadgeModule } from '@angular/material/badge';

const MAT_MODULES = [
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatIconModule,
    MatDividerModule,
    MatTooltipModule,
    MatMenuModule,
    MatChipsModule,
    MatRadioModule,
    MatTabsModule,
    MatFormFieldModule,
    MatProgressSpinnerModule
];

const PRODUCT_ROUTE_DATA = {
    breadcrumb: 'View Product',
    breadcrumbPredecessors: [
        { label: 'Products', url: '/products', ngJsRoute: true }
    ]
};

@NgModule({
    declarations: [
        AttributeOptionsDialogComponent,
        AddProductToBundleDialogComponent,
        AddProductToTemplatedBundleDialogComponent,
        ProductCatalogueTabComponent,
        ProductCategoriesTabComponent,
        ItemUsageSummaryCardComponent,
        ProductTabsComponent,
        ItemUsageSpecTemplateTabComponent,
        ProductImportTabComponent,
        ManageProductDialogComponent,
        ProductUsageExportComponent,
        ManageProductCategoryDialogComponent,
        ManageCategoryAttributeDialogComponent,
        ItemUsageLotSpecificationTabComponent,
        PurchaseOrderUsageReportDialogComponent,
        ProductCatalogueItemsTabComponent,
        ProductRateExceptionTabComponent,
        ProductRatesTabComponent,
        ProductExportTabComponent,
        ProductHistoryTabComponent,
        ItemUsageConfirmedQuantityComponent,
        ItemUsageBuildProgrammeTabComponent,
        ItemUsagePurchaseOrderTabComponent,
        ItemUsageSummaryTabComponent,
        ManageBundleDialogComponent,
        ManageRatesDialogComponent,
        ManageImagesDialogComponent,
        ManageAvailabilityDialogComponent,
        RateCompositionDialogComponent,
        ProductDetailsTabComponent,
        ViewProductComponent,
        ItemUsageTabComponent,
        CostxExportComponent,
        ManageCostxExportDialogComponent,
        CostxExportDateDialogComponent,
        ProductBuildOverheadCostsComponent,
        ProductBuildOverheadCostsDialogComponent,
        SelectionCentreComponent,
        SelectionCentreDialogComponent,
        RelatedSlotsComponent,
    ],
    exports: [
        ProductCatalogueTabComponent
    ],
    imports: [
        RouterModule.forRoot(
            [
                {
                    component: ProductTabsComponent,
                    path: 'products/:cbTabRouteId/:cbTabRouteId2/:cbTabRouteId3',
                    data: {
                        breadcrumb: 'Products',
                        breadcrumbPredecessors: [
                            { label: 'Product', url: '/products', ngJsRoute: true }
                        ]
                    }
                },
                {
                    component: ProductTabsComponent,
                    path: 'products/:cbTabRouteId/:cbTabRouteId2',
                    data: {
                        breadcrumb: 'Products',
                        breadcrumbPredecessors: [
                            { label: 'Product', url: '/products', ngJsRoute: true }
                        ]
                    }
                },
                {
                    component: ProductTabsComponent,
                    path: 'products/:cbTabRouteId',
                    data: {
                        breadcrumb: 'Products',
                        breadcrumbPredecessors: [
                            { label: 'Product', url: '/products', ngJsRoute: true }
                        ]
                    }
                },
                {
                    component: ProductTabsComponent,
                    path: 'products',
                    data: {
                        breadcrumb: 'Products'
                    }
                },
                {
                    component: ViewProductComponent,
                    path: 'view-product/:id/:cbTabRouteId/:cbTabRouteId2/:cbTabRouteId3',
                    data: {
                        breadcrumb: 'View Product',
                        breadcrumbPredecessors: [
                            { label: 'Products', url: '/products', ngJsRoute: true }
                        ]
                    }
                },
                {
                    component: ViewProductComponent,
                    path: 'view-product/:id/:cbTabRouteId/:cbTabRouteId2',
                    data: {
                        breadcrumb: 'View Product',
                        breadcrumbPredecessors: [
                            { label: 'Products', url: '/products', ngJsRoute: true }
                        ]
                    }
                },
                {
                    component: ViewProductComponent,
                    path: 'view-product/:id/:cbTabRouteId',
                    data: {
                        breadcrumb: 'View Product',
                        breadcrumbPredecessors: [
                            { label: 'Products', url: '/products', ngJsRoute: true }
                        ]
                    }
                },
                {
                    component: ViewProductComponent,
                    path: 'view-product/:id',
                    data: {
                        breadcrumb: 'View Product',
                        breadcrumbPredecessors: [
                            { label: 'Products', url: '/products', ngJsRoute: true }
                        ]
                    }
                }
            ],
            {
                onSameUrlNavigation: 'reload'
            }
        ),
        CommonModule,
        FormsModule,
        DragulaModule,
        ReactiveFormsModule,
        BrowserModule,
        CbFormsModule,
        InfiniteScrollModule,
        CbLoadingSpinnerModule,
        CbInfoMessageModule,
        CbDialogModule,
        CbPrettyBoolModule,
        CbSortByModule,
        CbDocumentsModule,
        CbHubTableModule,
        CbTableModule,
        CbButtonControlModule,
        CbDasherModule,
        CbSideBarModule,
        CbEnumToLabelModule,
        CbHistoryModule,
        CbLightboxModule,
        CbImageGalleryModule,
        CbImageButtonModule,
        CbCallbackModule,
        CbDisplayValueModule,
        CbClickModule,
        CbTabRouteModule,
        CbUserDateTimeModule,
        ...MAT_MODULES,
        MatCheckboxModule,
        CbWaitForModule,
        CbFilterChipsModule,
        CbCurrencyModule,
        CbInfoPopupModule,
        CbSortableHeaderCellModule,
        CdkTableModule,
        MatSortModule,
        CbSelectListModule,
        MatDialogModule,
        MatLegacySelectModule,
        MatLegacySliderModule,
        MatLegacyPaginatorModule,
        MatLegacyInputModule,
        MatLegacyListModule,
        MatExpansionModule,
        MatStepperModule,
        MatGridListModule,
        MatBadgeModule,
    ]
})

export class ProductsModule { }
