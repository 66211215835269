import { AfterViewInit, Component, Inject, ViewChild  } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ClientAccountLogicService } from '@app/logic/client-account/client-account.logic.service';
import { ClientSaleContractPricingUpdateDto } from '@app/logic/client-sale/dtos/client-sale-contract-pricing-update.dto';
import { IClientSaleMappedItem } from '@app/logic/client-sale/interfaces/i.client-sale.mapped';
import { IPaymentTemplateDto, IPaymentTemplateStageDto } from '@app/logic/payment-templates/interface/i.payment-template.dto';
import { PaymentTemplatesLogicService } from '@app/logic/payment-templates/payment-templates.logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { WHOLE_OPTION_VALUE_PROP } from '@app/shared/utils/select.util';
import { ManageClientAccountDialogComponent } from '@app/views/client-accounts/manage-client-account-dialog/manage-client-account-dialog.component';
import {
    CLIENT_SALE_BALANCE_DUE_TRIGGER_ENUM,
    IClassicUserDocumentDto,
    IClientAccountDto, IClientSaleContractPricingUpdateDto,
    ILotProgressPaymentDto,
    PAYMENT_TEMPLATE_AMOUNT_TYPE_ENUM, USER_TAG_CONSTANTS_CONST
} from '@classictechsolutions/hubapi-transpiled-enums';
import { orderBy } from 'lodash';
import { concatMap, iif, mergeMap, of } from 'rxjs';
import { ProgressPaymentsTableComponent } from './progress-payments-table/progress-payments-table.component';

interface IData {
    clientSale: IClientSaleMappedItem;
}

@Component({
    selector: 'cb-contract-pricing-details-edit-dialog',
    templateUrl: './contract-pricing-details-edit-dialog.component.html',
    styleUrls: ['./contract-pricing-details-edit-dialog.component.scss'],
    providers: [
        PaymentTemplatesLogicService
    ]
})
export class ContractPricingDetailsEditDialogComponent extends
    BaseDialogFormViewDirective<IClientSaleContractPricingUpdateDto, any, any>
    implements AfterViewInit {

    public static readonly MIN_WIDTH = '900px';
    public originalPaymentTemplateId: number;
    public readonly WHOLE_OPTION_VALUE_PROP = WHOLE_OPTION_VALUE_PROP;
    public readonly CLIENT_SALE_BALANCE_DUE_TRIGGER_ENUM = CLIENT_SALE_BALANCE_DUE_TRIGGER_ENUM;
    public readonly CONTRACT_MANAGER_TAG = [USER_TAG_CONSTANTS_CONST.CONTRACT_MANAGER];
    @ViewChild(ProgressPaymentsTableComponent, {}) public paymentsTableComponent: ProgressPaymentsTableComponent;
    public paymentTemplates: IPaymentTemplateDto[];
    /**
     * Used to set the cache for payment template before switching to a
     * new payment template
     */
    public oldPaymentTemplate: IPaymentTemplateDto;
    public selectedPaymentTemplate: IPaymentTemplateDto;
    public contractPricingUpdateDto = {} as IClientSaleContractPricingUpdateDto;
    public selectedContractManager: IClassicUserDocumentDto;
    public selectedLotVendor: any;

    constructor(
        public readonly dialogRef: MatDialogRef<ContractPricingDetailsEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly paymentTemplateLogic: PaymentTemplatesLogicService,
        public toastService: ToastService,
        public readonly dialog: MatDialog,
        public readonly clientAccountLogicService: ClientAccountLogicService,
    ) {
        super(dialogRef, toastService);

        this.initDto();

        this.selectedContractManager = {
            id: this.data.clientSale.contractMangerId,
            name: this.data.clientSale.contractMangerName
        } as IClassicUserDocumentDto;

        if (this.data.clientSale.isExternalDesignAndLand ||
            this.data.clientSale.isThirdPartyHouseAndLand) {
            this.selectedLotVendor = {
                id: this.data.clientSale.thirdPartyClientAccountId,
                name: this.data.clientSale.thirdPartyClientAccountName
            };
        }
    }

    public ngAfterViewInit(): void {
        this.paymentTemplateLogic
            .getListByContractTypeAndBuildType(
                this.data.clientSale.lot.lotContractTypeId,
                this.data.clientSale.lot.buildTypeId
            )
            .subOnce({
                error: this.handleError,
                next: this._onGetPaymentTemplatesSuccess
            });
    }

    public onPaymentTemplateChanged(paymentTemplate: IPaymentTemplateDto): void {
        this._hydratePaymentsAndCacheOnPaymentTemplateChange(paymentTemplate);
        this._hydratePaymentsFromCache(paymentTemplate.id);
    }

    public initDto(): void {
        this.contractPricingUpdateDto = new ClientSaleContractPricingUpdateDto(this.data.clientSale);
    }

    public openAddDialog(contractPricingForm: NgForm): void {
        this.dialog
            .open(
                ManageClientAccountDialogComponent,
                {
                    data: { mappedItem: this.clientAccountLogicService.$createMappedItem() },
                    minWidth: '95%',
                    panelClass: 'cb-dialog-container',
                }
            )
            .afterClosed()
            .subOnce((result?: IClientAccountDto) => {
                if (result) {
                    contractPricingForm.controls.lotVendorSearch.markAsDirty();
                    this.selectedLotVendor = {
                        id: result.id,
                        name: result.name
                    };
                }
            });
    }

    public save(): void {
        this._preSave();
        of(this._hasPaymentTemplateChanged)
            .pipe(
                mergeMap(hasPaymentTemplateChanged =>
                    iif(() => hasPaymentTemplateChanged,
                        this.data.clientSale
                            .applyPaymentTemplate(this.contractPricingUpdateDto.paymentTemplateId),
                        of(true)
                    )
                ),
                concatMap(() => this.data.clientSale
                    .updateContractPricingDetails(this.contractPricingUpdateDto)
                )
            )
            .subOnce({
                next: this.handleNext,
                error: this.handleError
            });
    }

    private _preSave(): void {
        this.contractPricingUpdateDto.contractManagerId = this.selectedContractManager.id;
        this._convertAllDollarValueToInt();

        if (this.data.clientSale.isThirdPartyHouseAndLand || this.data.clientSale.isExternalDesignAndLand) {
            this.contractPricingUpdateDto.thirdPartyLotClientAccountId = this.selectedLotVendor.id;
        }
    }

    private _convertAllDollarValueToInt(): void {
        this.contractPricingUpdateDto.lotProgressPayments.forEach(payment => {
            payment.dollarValue = Math.round(payment.dollarValue);
        });
    }

    private get _hasPaymentTemplateChanged(): boolean {
        return !this.originalPaymentTemplateId ||
            (this.originalPaymentTemplateId &&
                this.originalPaymentTemplateId !== this.contractPricingUpdateDto.paymentTemplateId);
    }

    private readonly _onGetPaymentTemplatesSuccess = (paymentTemplates: IPaymentTemplateDto[]): void => {
        this.paymentTemplates = paymentTemplates;
        this._initialiseSelectedPaymentTemplate();
        this._initialseCacheWithBlankProgressPayments();
    };

    private readonly _initialiseSelectedPaymentTemplate = (): void => {
        this.selectedPaymentTemplate = this.paymentTemplates
            .find(template => template.id === this.contractPricingUpdateDto.paymentTemplateId);
        if (this.selectedPaymentTemplate !== undefined &&
            this.selectedPaymentTemplate !== null) {
            this.originalPaymentTemplateId = this.selectedPaymentTemplate.id;
            if (this.selectedPaymentTemplate?.stages) {
                this.selectedPaymentTemplate.stages = orderBy(this.selectedPaymentTemplate.stages, ['sortOrder'], ['asc']);
            }
            this.onPaymentTemplateChanged(this.selectedPaymentTemplate);
        }
    };

    /**
     * Sets the payments table values from the cache.
     */
    private readonly _hydratePaymentsFromCache = (paymentTemplateId: number): void => {
        this.contractPricingUpdateDto.lotProgressPayments = this
            .paymentsTableComponent
            .progressPaymentsCache[paymentTemplateId]
            .map(this._hydratePaymentDollarValueAndPercentage);
    };

    private readonly _initialseCacheWithBlankProgressPayments = (): void => {
        this.paymentTemplates.forEach(template => {
            this.paymentsTableComponent
                .progressPaymentsCache[template.id] = template.stages
                    .map(this._mapPaymentTemplateStageToProgressPayment);
        });
    };

    /**
     * Caches the old payment template progress payments
     * before switching to the new payment template.
     */
    private readonly _hydratePaymentsAndCacheOnPaymentTemplateChange = (newPaymentTemplate: IPaymentTemplateDto): void => {
        if (this.oldPaymentTemplate) {
            this._hydratePaymentsAndCacheFromPaymentTemplate(this.oldPaymentTemplate);
        } else {
            this._hydratePaymentsAndCacheFromPaymentTemplate(newPaymentTemplate);
        }
        this.oldPaymentTemplate = newPaymentTemplate;
        this.contractPricingUpdateDto.paymentTemplateId = newPaymentTemplate.id;
    };

    /**
     * Caches the old payment template progress payments
     * from the old payment template.
     */
    private readonly _hydratePaymentsAndCacheFromPaymentTemplate = (paymentTemplate: IPaymentTemplateDto): void => {
        if (!this.contractPricingUpdateDto.lotProgressPayments ||
            this.contractPricingUpdateDto.lotProgressPayments.length < 1) {
            this.contractPricingUpdateDto.lotProgressPayments = paymentTemplate.stages
                .map(this._mapPaymentTemplateStageToProgressPayment);
        } else {
            this.contractPricingUpdateDto.lotProgressPayments = this
                .contractPricingUpdateDto.lotProgressPayments
                .map(this._hydratePaymentDollarValueAndPercentage);
        }

        this.paymentsTableComponent
            .progressPaymentsCache[paymentTemplate.id] = this.contractPricingUpdateDto.lotProgressPayments;

    };

    private readonly _hydratePaymentDollarValueAndPercentage = (progressPayment: ILotProgressPaymentDto): ILotProgressPaymentDto => {
        if (progressPayment.amountType === PAYMENT_TEMPLATE_AMOUNT_TYPE_ENUM.DollarValue) {
            this.paymentsTableComponent.setPercentageFromDollarValue(progressPayment);
        } else if (progressPayment.amountType === PAYMENT_TEMPLATE_AMOUNT_TYPE_ENUM.Percentage) {
            this.paymentsTableComponent.setDollarValueFromPercentage(progressPayment);
        }
        return progressPayment;
    };

    private readonly _mapPaymentTemplateStageToProgressPayment = (
        paymentTemplateStage: IPaymentTemplateStageDto): ILotProgressPaymentDto => {
        let progressPayment = ({
            lotId: this.data.clientSale.lot.id,
            buildStageId: paymentTemplateStage.buildStage?.id,
            buildStageLabel: paymentTemplateStage.buildStage?.label,
            amountType: paymentTemplateStage.amountType,
            dollarValue: (paymentTemplateStage.amountType === PAYMENT_TEMPLATE_AMOUNT_TYPE_ENUM.DollarValue) ? paymentTemplateStage.paymentAmount : undefined,
            percentage: (paymentTemplateStage.amountType === PAYMENT_TEMPLATE_AMOUNT_TYPE_ENUM.Percentage) ? paymentTemplateStage.paymentAmount : undefined,
            trigger: paymentTemplateStage.trigger,
            sortOrder: paymentTemplateStage.sortOrder
        } as ILotProgressPaymentDto);

        progressPayment = this._hydratePaymentDollarValueAndPercentage(progressPayment);

        return progressPayment;
    };

    public TriggerContractPriceUpdate(): void {
        this.contractPricingUpdateDto = {
            ...this.contractPricingUpdateDto
        };
    }
}
