<cb-dialog dialogHeading="Select Variation">
    <form class="flex-col flex"
          #selectVariation="ngForm">
        <div class="flex-row flex">
            <cb-select label="Available Variations"
                       name="availableVariations"
                       class="cb-margin-right flex"
                       [(ngModel)]="data.mappedPurchaseOrder.variationId"
                       [options]="data.variations"
                       [disabled]="!hasVariations">
            </cb-select>
            <button mat-icon-button
                    [disabled]="!data.mappedPurchaseOrder.variationId || !hasVariations"
                    (click)="removeVariation()">
                <mat-icon>remove_circle</mat-icon>
            </button>
        </div>
        <cb-info-message *ngIf="!hasVariations"
                         message="No variations available"></cb-info-message>
        <footer class="cb-margin-top-2x end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    (click)="save()"
                    [disabled]="!selectVariation.dirty || !selectVariation.valid">Save</button>
            <button mat-raised-button
                    (click)="cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
