import { IBaseMappedItem } from '../../base/interfaces/i.base-mapped-item';
import { ILotSpecItemColourYourDreamsDto } from './i.lot-spec.dto';
import { ILotSpecItemColourYourDreamsLogicService, ILotSpecLogicService } from './i.lot-spec.logic.service';
import { IIdAndLabelDto, ILotSpecDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { ILotSpecAllItem } from './i.lot-spec-all-item.dto';
import { Observable } from 'rxjs';
import { ILotSpecItemMappedItem } from '@app/logic/lot-spec-item';
import { ILotSpecColourItemMappedItem } from '@app/logic/lot-spec-colour-item';

export class ListItem {
    public isProduct: boolean;
    public item: any;
    public displayText: string;
    public expanded: boolean;
    public id: number;
}

export interface IListItem {
    isProduct: boolean;
    item: any;
    displayText: string;
    expanded: boolean;
    id: number;
}

export interface ILotSpecItemColourYourDreamsMappedItem
    extends IBaseMappedItem<ILotSpecItemColourYourDreamsDto, ILotSpecItemColourYourDreamsMappedItem, ILotSpecItemColourYourDreamsLogicService>, ILotSpecItemColourYourDreamsDto {
    lotId: number;
}

export interface ILotSpecMappedItem
    extends IBaseMappedItem<ILotSpecDto, ILotSpecMappedItem, ILotSpecLogicService>, ILotSpecDto {
    readonly allItems: { [specGroupId: number]: ILotSpecAllItem<ILotSpecItemMappedItem | ILotSpecColourItemMappedItem>[] };
    $areas: IListItem[];
    applySpecTemplate(templateId: number): Observable<ILotSpecDto>;
    clear(isLotSpecScheduleTemplate: boolean): Observable<ILotSpecDto>;
}

export interface IGroupWithExpansion extends IIdAndLabelDto {
    expanded: boolean;
}
