import { Component, Input } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { ChangeRecordLogicService } from '@app/logic/change-records/change-record.logic-service';
import { IChangeRecordMappedItem } from '@app/logic/change-records/interfaces/i.change-record.mapped';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { ITeamDto, TEAM_CODES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'cb-lot-changes',
    templateUrl: './lot-changes.component.html',
    styleUrls: ['./lot-changes.component.scss']
})
export class LotChangesComponent {

    @Input() public set lotId(lotId: number) {
        this.lotsLogicService.$getMappedItem(lotId).subscribe((result) => {
            this.lotMappedItem = result;
            this.loadChangeRecords();
            this.loadTeams();
        });
    }


    public lotMappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);
    @Input() public set lotMappedItem(mappedItem: ILotMappedItem) {
        if (mappedItem) {
            this.lotMappedItem$$.next(mappedItem);
            this.loadChangeRecords();
            this.loadTeams();
        }
    }
    public get lotMappedItem(): ILotMappedItem {
        return this.lotMappedItem$$?.value;
    }

    public changeRecordMappedItems: IChangeRecordMappedItem[];
    public isChangeRecordsLoaded = false;
    public selectedChangeRecord: IChangeRecordMappedItem;

    public teams: ITeamDto[];

    constructor(
        private readonly lotsLogicService: LotsLogicService,
        private readonly changeRecordLogicService: ChangeRecordLogicService,
        private readonly teamsLogicService: TeamsLogicService,
        public readonly currentUser: CurrentUserService,) { }

    private loadChangeRecords(): void {
        this.changeRecordLogicService.getChangeRecords(this.lotMappedItem.id).subOnce((result) => {
            this.changeRecordMappedItems = result.sort((a, b) => (a.changeNumber < b.changeNumber ? 1 : -1));
            this.isChangeRecordsLoaded = true;
        });
    }

    private loadTeams(): void {
        this.teamsLogicService.$getList().subOnce((allTeams) => {
            this.teams = allTeams
                .filter(x => x.key === TEAM_CODES_CONST.QsTeamKey ||
                    x.key === TEAM_CODES_CONST.DesignTeamKey ||
                    x.key === TEAM_CODES_CONST.SalesTeamKey
                );
        });
    }

    public readonly changeRecordSelected = (event: IChangeRecordMappedItem): void => {
        this.selectedChangeRecord = event;
    };


    public readonly changeRecordCreated = (changeRecord: IChangeRecordMappedItem): void => {
        this.changeRecordMappedItems = [changeRecord, ...this.changeRecordMappedItems];
    };

    public readonly changesUpdated = (updatedChangeRecordsDictionary: number[]): void => {
        updatedChangeRecordsDictionary.forEach(element => {
            const changeRecordMappedItemToReload = this.changeRecordMappedItems.find(x => x.id === element);
            if (changeRecordMappedItemToReload.$id > 0) {
                changeRecordMappedItemToReload.$reload().subOnce();
            }
        });

    };
}
