import { map } from 'rxjs';
import { Component, OnInit, Inject } from '@angular/core';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ITradeTypeTreeMappedItem } from '@app/logic/trade-types/interfaces/i.trade-type-tree.mapped';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { remove } from 'lodash';
import { FormMode } from '@app/shared/enums/form';
import { ComplianceRegistrationLogicService } from '@app/logic/compliance-registration';
import { TradeTypesLogicService } from '@app/logic/trade-types/trade-types.logic.service';
import { ITradeTypesTreeLogicService } from '@app/logic/trade-types/interfaces/i.trade-types-tree.logic.service';
import { TradeTypesTreeLogicService } from '@app/logic/trade-types/trade-types-tree.logic.service';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { IComplianceRegistrationDto, ITradeTypeDto, ITradeTypeTreeDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    templateUrl: './trade-type-dialog.component.html',
    styleUrls: ['./trade-type-dialog.component.scss'],
    providers: [
        ComplianceRegistrationLogicService,
        TradeTypesLogicService,
        TradeTypesTreeLogicService,
    ]
})
export class TradeTypeDialogComponent extends BaseDialogFormViewDirective<Partial<ITradeTypeTreeDto>, ITradeTypeTreeMappedItem, ITradeTypesTreeLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '50%';

    public readonly columns = ['Compliance Registrations', ''];
    public loaded = true;
    public activeChildren: boolean;
    public searchText: string;
    public availableTradeTypes: ITradeTypeDto[];
    public complianceRegs: IComplianceRegistrationDto[];
    public selectedCompReg: IComplianceRegistrationDto;
    public complianceRegsChanged = false;
    public autocompleteLoading = false;

    constructor(
        public readonly dialogRef: MatDialogRef<TradeTypeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: { mappedItem: ITradeTypeTreeMappedItem },
        public readonly toastService: ToastService,
        private readonly complianceRegLogic: ComplianceRegistrationLogicService,
        private readonly tradeTypeLogic: TradeTypesLogicService,
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
    }

    public ngOnInit(): void {
        this.formMode = this.mappedItem?.id > 0 ? FormMode.Edit : FormMode.Add;

        this.setAvailableTradeTypes();
        this.setComplianceRegistrations();
        this.setHasActiveChildren();
    }

    public setHasActiveChildren(): void {
        if (this.mappedItem.mappedChildren && this.mappedItem.mappedChildren.length > 0) {
            for (const child of this.mappedItem.mappedChildren) {
                if (child.isActive) {
                    this.activeChildren = true;
                    break;
                }
            }
        }
    }

    public setComplianceRegistrations(): void {
        this.complianceRegLogic.$getList()
            .pipe(
                map(x => x.filter(y => y.isActive))
            )
            .subOnce(results => this.complianceRegs = results);
    }

    public setAvailableTradeTypes(): void {
        this.tradeTypeLogic.$getList()
            .pipe(
                map(x => x.filter(y => !y.parentId && (!this.mappedItem?.id || y.id !== this.mappedItem?.id)))
            )
            .subOnce(results => this.availableTradeTypes = results);
    }

    public displayWith(lookup: IComplianceRegistrationDto): string | null {
        return lookup ? lookup.label : null;
    }

    public querySearch = (searchText: string): IComplianceRegistrationDto[] => {
        const unusedRegs = this.complianceRegs?.filter(x => !this.mappedItem.requiredComplianceRegistrations.map(req => req.id).includes(x.id)) ?? [];
        if (isNullOrWhiteSpace(searchText)) {
            return unusedRegs;
        }
        return unusedRegs.filter((item: IComplianceRegistrationDto) => {
            return item.label.toLowerCase().includes(searchText.toLowerCase());
        });
    };

    public disableAddCompReg(): boolean {
        if (this.selectedCompReg) {
            return this.mappedItem.requiredComplianceRegistrations.findIndex(x => x.id === this.selectedCompReg.id) !== -1;
        }
        return true;
    }

    public addCompReg(): void {
        if (!this.selectedCompReg) {
            return;
        }
        this.autocompleteLoading = true;
        this.mappedItem.requiredComplianceRegistrations.push(this.selectedCompReg);
        this.selectedCompReg = null;
        this.complianceRegsChanged = true;
        setTimeout(() => {
            this.autocompleteLoading = false;
        });
    }

    public removeCompReg(compRegId): void {
        this.autocompleteLoading = true;
        remove(this.mappedItem.requiredComplianceRegistrations, { id: compRegId });
        this.complianceRegsChanged = true;
        setTimeout(() => {
            this.autocompleteLoading = false;
        });
    }
}
