import {
    CostNatureEnumId, IVariationDto, ManualOrderReasonEnumId,
    ManualPurchaseOrderOriginEnumId, PurchaseOrderApprovalStatusEnumId,
    PurchaseOrderStatusEnumId, PURCHASE_ORDER_APPROVAL_STATUS_ENUM,
    PURCHASE_ORDER_STATUS_ENUM, IAddressDto, IBuildProgrammeActivityLookupDto, IBusinessAccountDetailsDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { map, Observable, tap } from 'rxjs';
import { sumBy } from 'lodash';
import { BaseMappedItem } from '../base/base-mapped-item';
import { Computed } from '../base/computed-prop.decorator';
import { DtoProp } from '../base/dto-prop.decorator';
import { IPurchaseOrderLineDto } from './interfaces/i.purchase-order-line.dto';
import { IPurchaseOrderLineMappedItem } from './interfaces/i.purchase-order-line.mapped';
import { IPurchaseOrderDto } from './interfaces/i.purchase-order.dto';
import { IPurchaseOrderMappedItem } from './interfaces/i.purchase-order.mapped';
import { IPurchaseOrdersLogicService } from './interfaces/i.purchase-orders.logic.service';
import { PurchaseOrderLineMappedItem } from './purchase-order-line.mapped';

export class PurchaseOrderMappedItem
    extends BaseMappedItem<IPurchaseOrderDto, IPurchaseOrderMappedItem, IPurchaseOrdersLogicService>
    implements IPurchaseOrderMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public buildActivityId: number;
    @DtoProp public activityCode: string;
    @DtoProp public activityEndDate: string;
    @DtoProp public activityName: string;
    @DtoProp public activityStartDate: string;
    @DtoProp public buildProgrammeActivityId: number;
    @DtoProp public comments: string;
    @DtoProp public complianceDocumentName: string;
    @DtoProp public complianceDocumentStatusId: number;
    @DtoProp public complianceDocumentStatusLabel: string;
    @DtoProp public constructionManagerId: number;
    @DtoProp public constructionManagerName: string;
    @DtoProp public jobNumber: string;
    @DtoProp public lotId: number;
    @DtoProp public orderDate: string;
    @DtoProp public orderNumber: number;
    @DtoProp public orderSubtotal: number;
    @DtoProp public ssrId: number;
    @DtoProp public statusId: PurchaseOrderStatusEnumId;
    @DtoProp public statusLabel: string;
    @DtoProp public supplierName: string;
    @DtoProp public originId: ManualPurchaseOrderOriginEnumId;
    @DtoProp public readonly isManualOrder: boolean;
    @DtoProp public buildProgrammeActivity: IBuildProgrammeActivityLookupDto;
    @DtoProp public supplierId: number;
    @DtoProp public supplierStreetAddress: string;
    @DtoProp public supplierSuburb: string;
    @DtoProp public supplierAddressRegion: string;
    @DtoProp public supplierAddressRegionId: number;
    @DtoProp public supplierPostCode: string;
    @DtoProp public supplierCity: string;
    @DtoProp public supplierEmailAddress: string;
    @DtoProp public approvedById: string;
    @DtoProp public approvedByName: string;
    @DtoProp public approvalStatusId: number;
    @DtoProp public approvedDate: string;
    @DtoProp public approvalComments: string;
    @DtoProp public readonly purchaseOrderLines: IPurchaseOrderLineDto[];
    @DtoProp public businessEntityStreetAddress: string;
    @DtoProp public businessEntitySuburb: string;
    @DtoProp public businessEntityCity: string;
    @DtoProp public businessEntityAddressRegion: string;
    @DtoProp public phoneNumber: string;
    @DtoProp public web: string;
    @DtoProp public invoiceEmail: string;
    @DtoProp public queriesEmail: string;
    @DtoProp public complianceEmail: string;
    @DtoProp public forceNoCompliance: boolean;
    @DtoProp public constructionManagerMobile: string;
    @DtoProp public constructionManagerEmail: string;
    @DtoProp public buildStageId: number;
    @DtoProp public costToBeDetermined: boolean;
    @DtoProp public costNature: CostNatureEnumId;
    @DtoProp public clientName: string;
    @DtoProp public clientMobile: string;
    @DtoProp public clientEmail: string;
    @DtoProp public supplierContactName: string;
    @DtoProp public businessEntityId: number;
    @DtoProp public businessEntityName: string;
    @DtoProp public qsTeamMemberId: string;
    @DtoProp public qsTeamMemberName: string;
    @DtoProp public stageCode: string;
    @DtoProp public stageName: string;
    @DtoProp public canBePaid: boolean;
    @DtoProp public amountPaid: number;
    @DtoProp public orderGst: number;
    @DtoProp public orderTotal: number;
    @DtoProp public activityDuration: number;
    @DtoProp public reasonId: ManualOrderReasonEnumId;
    @DtoProp public reasonDescription: string;

    @DtoProp public readonly submittedById: string;
    @DtoProp public readonly submittedByName: string;
    @DtoProp public readonly submittedDate: string;

    @DtoProp public chargeableBusinessAccountId: number;
    @DtoProp public chargeableBusinessAccountName: string;
    @DtoProp public chargeableBusinessAccountContactId: number;
    @DtoProp public chargeableBusinessAccountContactName: string;
    @DtoProp public freeTextChargeableBusinessAccountContact: string;
    @DtoProp public discussionDescription: string;
    @DtoProp public discussionDate: string;

    @DtoProp public readonly createdDate: string;
    @DtoProp public readonly createdByName: string;
    @DtoProp public readonly updatedDate: string;
    @DtoProp public readonly updatedByName: string;

    @DtoProp public variationNumber: number;
    @DtoProp public variationAmount: number;
    @DtoProp public variationId: number;

    @DtoProp public hasMatchedInvoices: boolean;
    @DtoProp public hasPayments: boolean;


    @Computed() public get canDownload(): boolean {
        return this.statusId === PURCHASE_ORDER_STATUS_ENUM.Confirmed
            || this.statusId === PURCHASE_ORDER_STATUS_ENUM.Completed;
    }

    @Computed() public get approvalStatusLabel(): boolean {
        return PURCHASE_ORDER_APPROVAL_STATUS_ENUM[this.approvalStatusId];
    }

    @Computed() public get mappedLines(): IPurchaseOrderLineMappedItem[] {
        return this.purchaseOrderLines ? this.purchaseOrderLines.map(item => new PurchaseOrderLineMappedItem(item, this.$logicService, this)) : [];
    }

    @Computed() public get hasBuildProgrammeActivity(): boolean {
        return this.buildProgrammeActivityId > 0;
    }

    constructor(
        sourceData: IPurchaseOrderDto,
        logicService: IPurchaseOrdersLogicService
    ) {
        super(sourceData, logicService, PurchaseOrderMappedItem);
    }

    public download(): void {
        if (!this.canDownload) {
            return;
        }

        if (this.ssrId != null) {
            this.$logicService
                .downloadSsrPO(this.ssrId)
                .subOnce();
        } else {
            this.$logicService
                .downloadManualPO(this.id)
                .subOnce();
        }
    }

    public getAvailableVariations(): Observable<IVariationDto[]> {
        return this.$logicService
            .getAvailableVariations(this.id)
            .pipe(
                map((response) => {
                    return response;
                })
            );
    }

    public assignVariation(): Observable<IPurchaseOrderDto> {
        return this.$logicService
            .assignvariation(this.$getMappedDtoItem().id, this.$getMappedDtoItem().variationId)
            .pipe(
                map((response) => {
                    this.$updateThisAndOriginal(response);
                    return response;
                })
            );
    }

    public unassignVariation(): Observable<IPurchaseOrderDto> {
        return this.$logicService
            .unassignvariation(this.$getMappedDtoItem().id)
            .pipe(
                map((response) => {
                    this.$updateThisAndOriginal(response);
                    return response;
                })
            );
    }

    public newLineItem(
        defaultData: Partial<IPurchaseOrderLineDto> = {}): IPurchaseOrderLineMappedItem {
        return new PurchaseOrderLineMappedItem(defaultData as IPurchaseOrderLineDto, this.$logicService, this);
    }

    public orderSubtotalCalc(): number {
        return sumBy(this.mappedLines, (item) => item.lineSubtotalCalc());
    }

    public orderGstCalc(): number {
        return sumBy(
            this.mappedLines.filter(x => x.gstApplies),
            (item) => item.lineSubtotalCalc() * (item.gstRate / 100)
        );
    }

    public orderTotalCalc(): number {
        return this.orderSubtotalCalc() + this.orderGstCalc();
    }

    public resetSupplierDetails(supplier?: IBusinessAccountDetailsDto): IAddressDto {
        const supplierDetails = !supplier ? { physicalAddress: {} } as any : supplier;

        this.supplierId = supplierDetails.id;
        this.supplierName = supplierDetails.tradingName;

        const addressToMap = !supplierDetails.postalAddress || !supplierDetails.postalAddress.id
            ? supplierDetails.physicalAddress : supplierDetails.postalAddress;

        this.supplierStreetAddress = addressToMap.street;
        this.supplierSuburb = addressToMap.suburb;
        this.supplierPostCode = addressToMap.postCode;
        this.supplierCity = addressToMap.city;
        this.supplierAddressRegion = addressToMap.regionName;
        return addressToMap;
    }

    public submit(): Observable<IPurchaseOrderDto> {
        return this.$logicService
            .submitPurchaseOrder(this.id)
            .pipe(
                tap(result => this.$updateThisAndOriginal(result))
            );
    }

    public unsubmit(): Observable<IPurchaseOrderDto> {
        return this.$logicService
            .unsubmitPurchaseOrder(this.id)
            .pipe(
                tap(result => this.$updateThisAndOriginal(result))
            );
    }

    public cancel(): Observable<IPurchaseOrderDto> {
        return this.$logicService
            .cancelPurchaseOrder(this.id)
            .pipe(
                tap(result => this.$updateThisAndOriginal(result))
            );
    }

    public submitApprovalDescision(status: PurchaseOrderApprovalStatusEnumId, comments = ''): Observable<IPurchaseOrderDto> {
        return this.$logicService
            .submitApprovalDecision(this.id, status, comments)
            .pipe(
                tap(result => this.$updateThisAndOriginal(result))
            );
    }
}
