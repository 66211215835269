<cb-dialog [dialogHeading]="data.title">
    <cb-table class="flex"
              label="Purchase Order History"
              [stringColumns]="displayedColumns"
              [loaded]="true">
        <tr cbTableRow
            *ngFor="let purchaseOrder of data.purchaseOrderHistories"
            [ngClass]="activityRowClasses(purchaseOrder)">
            <td>
                {{ purchaseOrder.orderNumber}}
            </td>
            <td>
                {{ purchaseOrder.supplierName}}
            </td>
            <cb-td-enum [value]="purchaseOrder.statusId"
                        [enum]="PURCHASE_ORDER_STATUS_ENUM">
            </cb-td-enum>
            <td>
                {{ purchaseOrder.versionNumber}}
            </td>
            <td>
                {{ purchaseOrder.externalVersionNumber}}
            </td>
            <td>{{purchaseOrder.orderTotal | cbCurrency}}</td>
            <cb-td-date [value]="purchaseOrder.activityStartDate"></cb-td-date>
            <cb-td-date [value]="purchaseOrder.activityEndDate"></cb-td-date>
            <td>
                {{ purchaseOrder.updatedByName}}
            </td>
            <cb-td-date [value]="purchaseOrder.updatedDate"></cb-td-date>
            <td cbTableShrinkCol>
                <cb-td-icon *ngIf="purchaseOrder.isManualOrder"
                            svgValue="information-outline"
                            [toolTip]="manualOrderReasonText(purchaseOrder)"
                            class="cb-grey">
                </cb-td-icon>
            </td>
            <td cbTableShrinkCol
                cbTableStickyCol="end">
                <button *ngIf="!isCancelled(purchaseOrder)"
                        mat-icon-button
                        matTooltip="View PO"
                        (click)="downloadDocument(purchaseOrder)">
                    <mat-icon>picture_as_pdf</mat-icon>
                </button>
            </td>
        </tr>
    </cb-table>
</cb-dialog>
