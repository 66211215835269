import { Component, Input } from '@angular/core';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { IVariationMappedItem } from '@app/logic/variation/interfaces/i.variation.mapped';
import { VariationLogicService } from '@app/logic/variation/variation.logic-service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'cb-lot-variations',
    templateUrl: './lot-variations.component.html',
    styleUrls: ['./lot-variations.component.scss']
})
export class LotVariationsComponent {

    @Input() public set lotId(lotId: number) {
        this.lotsLogicService.$getMappedItem(lotId).subscribe((result) => {
            this.lotMappedItem = result;
            this.loadVariations();
        });
    }

    public lotMappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);
    @Input() public set lotMappedItem(mappedItem: ILotMappedItem) {
        if (mappedItem) {
            this.lotMappedItem$$.next(mappedItem);
            this.loadVariations();
        }
    }
    public get lotMappedItem(): ILotMappedItem {
        return this.lotMappedItem$$?.value;
    }

    public variationMappedItems: IVariationMappedItem[];
    public isVariationsLoaded = false;
    public selectedVariation: IVariationMappedItem;


    constructor(
        private readonly lotsLogicService: LotsLogicService,
        private readonly variationLogicService: VariationLogicService,) { }

    private loadVariations(): void {
        this.variationLogicService.getByLot(this.lotMappedItem.id).subOnce((results) => {
            this.variationMappedItems = results.sort((a, b) => (a.variationNumber < b.variationNumber ? 1 : -1));
            this.isVariationsLoaded = true;
        });
    }


    public readonly variationSelected = (variation: IVariationMappedItem): void => {
        this.variationLogicService.$getMappedItem(variation.id).subOnce((result) => {
            this.selectedVariation = result;
        });
    };

    public updateSelectedVariation(_variation: IVariationMappedItem): void {
        const itemIndex = this.variationMappedItems?.findIndex(variation => variation.id === _variation.id);

        if (itemIndex > -1) {
            // Replace the item if added to an existing parent
            this.variationMappedItems?.splice(itemIndex, 1, _variation);
        }
        this.lotMappedItem.$reload().subOnce();
    }

    public variationCreated(): void {
        this.lotMappedItem.$reload().subOnce(() => {
            this.loadVariations();
        });
    }

}
