import { Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, OnDestroy, OnInit, Output, Provider, QueryList, ViewChildren } from '@angular/core';
import { debounceTime, exhaustMap, filter, Observable, scan, startWith, Subject, Subscription, switchMap, tap } from 'rxjs';
import { BaseFormComponentDirective, getBaseFormComponentDirectiveProvider } from '../base-form-component';

import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SearchService } from '@app/core/services/search/base.search.service';
import { Debounce } from '@app/shared/decorators/debounce.decorator';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { takeWhileInclusive } from '@app/shared/utils/take-while-inclusive.util';

export interface IBasicAutocompleteLookup {
    name?: string;
    label?: string;
}

@Component({
    selector: 'cb-autocomplete-scroller',
    templateUrl: './autocomplete-scroller.component.html',
    styleUrls: ['./autocomplete-scroller.component.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(AutocompleteScrollerComponent),
        SearchService,
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class AutocompleteScrollerComponent<T> extends BaseFormComponentDirective implements OnInit, OnDestroy {

    @Output() public optionSelected: EventEmitter<T> = new EventEmitter();
    @Output() public searchTextChanged: EventEmitter<string> = new EventEmitter();
    @Input() public readonly requireMatch: boolean;
    @Input() public readonly isInvalid: boolean = false;
    @Input() public readonly matchProps: string[];
    @Input() public maxlength: number;

    @Input() public readonly querySearch: (query: string, currentPage?: number) => any[] | null;

    public get searchInput(): ElementRef {
        return this.SearchInputParent.first;
    }
    @ViewChildren('searchInput') public SearchInputParent: QueryList<ElementRef>;
    @HostBinding('class') public class = 'flex-row flex';


    public filteredLookups$: Observable<IBasicAutocompleteLookup[]>;
    private readonly nextPage$ = new Subject();
    private readonly subscriptions$ = new Subscription();

    constructor(
        public readonly dialog: MatDialog,
        public searchService: SearchService) {
        super();
    }

    public ngOnInit(): void {
        const filter$ = this.searchTextChanged.pipe(
            startWith(''),
            debounceTime(200),
            filter(q => typeof q === 'string'));

        this.filteredLookups$ = filter$.pipe(
            switchMap(filterStr => {
                let currentPage = 1;
                return this.nextPage$.pipe(
                    startWith(currentPage),
                    exhaustMap(_ => this.getResultObservable(filterStr, currentPage)),
                    tap(() => { currentPage++; }),
                    takeWhileInclusive((p: any) => p.length > 0),
                    scan((items, newItems) => items.concat(newItems), []),
                );
            }));
    }

    public getResultObservable(filterStr: string, currentPage: number): Observable<any[]> {
        if (this.querySearch) {
            return new Observable((ob) => ob.next(this.querySearch(filterStr, currentPage)));
        }
        return this.searchService.handleSearch(filterStr, currentPage);
    }

    public ngOnDestroy(): void {
        this.nextPage$.unsubscribe();
        this.subscriptions$.unsubscribe();
        this.optionSelected.unsubscribe();
        this.searchTextChanged.unsubscribe();
    }

    public onKeyUp = (): void => {
        if (!this.value) {
            this.optionSelected.emit(undefined);
        }

        if (typeof (this.value) === 'string') {
            return this.searchTextChanged.emit(this.value);
        }
        const display = this.displayWith(this.value);
        if (typeof (display) === 'string') {
            this.searchTextChanged.emit(display);
        }
    };

    public onScroll(): void {
        this.nextPage$.next({ addPage: 1 });
    }

    /** @returns string can contain html tags, it will be rendered/bound using [innerHtml] */
    public displayWith(lookup: any): string | null {
        return lookup ? lookup.label || lookup.name : null;
    }

    public handleOptionSelected(event: MatAutocompleteSelectedEvent): void {
        this.updateOption(event.option.value);
        this.searchInput.nativeElement.blur();
    }

    public reperformSearch(): void {
        this.value = this.value || '';
        this.onKeyUp();
    }

    @Debounce()
    private updateOption(value: T): void {
        this.optionSelected.emit(value);
    }
}

export function getBaseAutocompleteScrollerProvider(component: any): Provider {
    return {
        provide: AutocompleteScrollerComponent,
        useExisting: forwardRef(() => component),
    };
}
