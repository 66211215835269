<cb-dialog [dialogHeading]="'Selection Centre'">
    <mat-stepper #stepper>
        <!-- Step 1 -->
        <mat-step>
            <ng-template matStepLabel>{{ data.houseArea }} > {{ data.slotName }}</ng-template>
            <cb-selection-centre
                [isSlotSelection]="true"
                [slotId]="data.slotId"
                [slotName]="data.slotName"
                [houseArea]="data.houseArea"
                [lotId]="data.lotId"
                [reg]="data.reg"
                [cat]="data.cat"
                (relatedSlotsChanged)="relatedSlotsUpdated($event)"
                (swappedItemChanged)="swappedItemChanged($event)"
                (selectedItemChanged)="selectedItemChanged($event)"
            >
            </cb-selection-centre>
            <div class="flex">
                <span class="flex"></span>
                <button mat-button matStepperNext [disabled]="!relatedSlots || relatedSlots.length === 0">Next</button>
            </div>
        </mat-step>
        <!-- Step 2 -->
        <mat-step *ngIf="relatedSlots?.length > 0">
            <ng-template matStepLabel>Related Slots</ng-template>
            <div *ngIf="isRelatedSlotsLoading" class="loading-container">
                <mat-spinner diameter="24"></mat-spinner> Loading related slots...
            </div>
            <div *ngIf="!isRelatedSlotsLoading">
                <cb-related-slots [relatedSlots]="relatedSlots" (selectedSlots)="saveSelection($event)"></cb-related-slots>
            </div>
            <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button matStepperNext [disabled]="!swappedItem">Next</button>
            </div>
        </mat-step>
        <!-- Step 3 -->
        <mat-step *ngIf="selectedItem && swappedItem">
            <ng-template matStepLabel>Review & Finalise</ng-template>
            <h2 class="section-title">Review & Finalise</h2>
            <div class="review-container">
                <!-- Before Card -->
                <div class="item-card">
                    <h3 class="card-title">{{ selectedItem.houseArea }} > {{selectedItem.slotName}}</h3>
                    <mat-card class="example-card" *ngIf="selectedItem">
                        <mat-card-header>
                            <mat-card-title>{{ selectedItem.name }}</mat-card-title>
<!--                            <mat-card-subtitle>{{ selectedItem.houseArea }} > {{selectedItem.slotName}}</mat-card-subtitle>-->
                        </mat-card-header>
                        <img mat-card-image [src]="getProductImage(selectedItem)" alt="{{ selectedItem.name }}" />
                        <mat-card-content>
                            <p><strong>Rate:</strong> ${{ selectedItem.nationalRate | number: '1.2-2' }}</p>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="item-card">
                    <h3 class="card-title"></h3>
                    <mat-card class="example-card"  style="box-shadow: unset" *ngIf="selectedItem">
                        <mat-card-header>
                        </mat-card-header>
                        <img mat-card-image height="100px" [src]="getArrowImage()" alt="Swapped out" />
                        <mat-card-content [innerHTML]="getRateChangeText(selectedItem.nationalRate, swappedItem.nationalRate)">
                        </mat-card-content>
                    </mat-card>
                </div>
                <!-- After Card -->
                <div class="item-card">
                    <h3 class="card-title">New</h3>
                    <mat-card class="example-card" *ngIf="swappedItem">
                        <mat-card-header>
                            <mat-card-title>{{ swappedItem.name }}</mat-card-title>
<!--                            <mat-card-subtitle>{{ swappedItem.houseArea }} > {{swappedItem.slotName}}</mat-card-subtitle>-->
                        </mat-card-header>
                        <img mat-card-image [src]="getProductImage(swappedItem)" alt="{{ swappedItem.name }}" />
                        <mat-card-content>
                            <p><strong>Rate:</strong> ${{ swappedItem.nationalRate | number: '1.2-2' }}</p>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <span *ngFor="let item of relatedSlots">
                <div class="review-container" *ngIf="itemWasSwapped(item)">
                    <!-- Before Card -->
                    <div class="item-card">
                        <h3 class="card-title">{{ item.houseArea }} > {{item.slotName}}</h3>
                        <mat-card class="example-card" *ngIf="item">
                            <mat-card-header>
                                <mat-card-title>{{ item.name }}</mat-card-title>
<!--                                <mat-card-subtitle></mat-card-subtitle>-->
                            </mat-card-header>
                            <img mat-card-image [src]="getProductImage(item)" alt="{{ item.name }}" />
                            <mat-card-content>
                                <p><strong>Rate:</strong> ${{ item.nationalRate | number: '1.2-2' }}</p>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="item-card">
                        <h3 class="card-title"></h3>
                        <mat-card class="example-card" style="box-shadow: unset" *ngIf="item">
                            <mat-card-header>
                            </mat-card-header>
                            <img mat-card-image height="100px" [src]="getArrowImage()" alt="Swapped out" />
                            <mat-card-content [innerHTML]="getRateChangeText(item.nationalRate, swappedItem.nationalRate)">
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <!-- After Card -->
                    <div class="item-card">
                        <mat-card class="example-card" *ngIf="swappedItem">
                            <h3 class="card-title">New</h3>
                            <mat-card-header>
                                <mat-card-title>{{ swappedItem.name }}</mat-card-title>
                            </mat-card-header>
                            <img mat-card-image [src]="getProductImage(swappedItem)" alt="{{ swappedItem.name }}" />
                            <mat-card-content>
                                <p><strong>Rate:</strong> ${{ swappedItem.nationalRate | number: '1.2-2' }}</p>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </span>
            <div class="button-container">
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button>Close</button>
            </div>
        </mat-step>
    </mat-stepper>
</cb-dialog>
