<mat-card class="flex-col flex">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon">
            <mat-icon class="cb-primary"
                      title="Complete"
                      *ngIf="mappedItem?.stepFinanceInfoComplete">
                check_circle
            </mat-icon>
            <mat-icon class="cb-error"
                      title="Incomplete"
                      *ngIf="!mappedItem?.stepFinanceInfoComplete">
                error
            </mat-icon>
        </div>
        <mat-card-title [ngClass]="!(mappedItem?.stepFinanceInfoComplete) ? 'cb-error' : ''">
            4. Finance Information
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="start-start flex-col flex">
        <cb-currency label="Budget"
                     name="budget"
                     [(ngModel)]="mappedItem.budget"
                     [inclGst]="true"
                     [readonly]="true">
        </cb-currency>
        <cb-display-value-new label="Client has Finance?">
            {{mappedItem?.hasFinance ? 'Yes' : 'No'}}
        </cb-display-value-new>
        <cb-display-value-new *ngIf="mappedItem.hasFinance"
                              label="Finance Type">
            {{mappedItem?.financeIsCash ? 'Cash' : 'Finance'}}
        </cb-display-value-new>
        <div class="flex-col"
             *ngIf="mappedItem.hasFinance && !mappedItem.financeIsCash">
            <cb-display-value-new label="bankName">
                {{mappedItem?.bank}}
            </cb-display-value-new>
            <cb-checkbox name="hasApproval"
                         label="Finance Pre-Approved?"
                         [readonly]="true"
                         [(ngModel)]="mappedItem.hasApproval">
            </cb-checkbox>
        </div>
    </mat-card-content>
    <div position="relative"
         *ngIf="canEdit && !mappedItem.isQualified()">
        <mat-divider></mat-divider>
    </div>
    <mat-card-actions class="end-center flex-row"
                      *ngIf="canEdit && !mappedItem.isQualified()">
        <button mat-stroked-button
                color="accent"
                (cbClick)="editFinanceInfo()">
            <mat-icon>edit</mat-icon>
            &nbsp;Edit
        </button>
    </mat-card-actions>
</mat-card>
