<cb-dialog dialogHeading="Rate Explanation powered by MattGPT">
    <div class="flex-row cb-margin-bottom-2x">
        <div class="flex-col flex">
            <cb-info-message
                    [message]="'Please note: AI answers can contain errors due to limited understanding of context and nuances. Always double-check crucial details for accuracy.'"
                    [icon]="'warning'"
                    [iconClass]="'mat-warn'"></cb-info-message>
        </div>
    </div>
    <form class="flex-col flex"
          #questionForm="ngForm">

        <div class="flex-row">
            <div class="flex-col flex">
                <cb-display-value-new label="Build Activity"
                                      [value]="activityName"></cb-display-value-new>
            </div>
            <div class="flex-col flex">
                <cb-display-value-new label="Product Code"
                                      [value]="productCode"></cb-display-value-new>
            </div>
            <div class="flex-col flex">
                <cb-display-value-new label="Product Name"
                                      [value]="productName"></cb-display-value-new>
            </div>
        </div>
        <div class="flex-row">
            <div class="flex-col flex">
                <cb-currency label="Selected Rate"
                             *ngIf="mappedItem.answer"
                             name="selectedRate"
                             [(ngModel)]="mappedItem.answer.rateSelected"
                             [readonly]="true"></cb-currency>
            </div>
            <div class="flex-col flex">
                <cb-currency label="QS Budget"
                             *ngIf="mappedItem.answer"
                             name="qsBudget"
                             [(ngModel)]="mappedItem.answer.qsBudget"
                             [readonly]="true"></cb-currency>
            </div>
            <div class="flex-col flex">
                <cb-display-value-new label="Selected Supplier"
                                      *ngIf="mappedItem.answer"
                                      [value]="mappedItem.answer.selectedSupplier"></cb-display-value-new>
            </div>
        </div>
        <div class="flex-row">
            <div class="flex-col flex">
                <cb-display-value-new label="Why was this selected?"
                                      *ngIf="mappedItem.answer"
                                      [value]="mappedItem.answer.reason"></cb-display-value-new>
            </div>
        </div>
        <div class="flex-row"
             *ngIf="mappedItem.fullExplanation">
            <div class="flex-col flex">
                <mat-card class="collapsible-card flex-col">
                    <div class="flex-row"
                         *ngIf="mappedItem.answer">
                        <div class="flex-col flex">
                            <span *ngIf="collapsed" class="collapsed-title"><h4>See full explanation</h4></span>
                            <span *ngIf="!collapsed" class="collapsed-title"><h4>Hide full explanation</h4></span>
                        </div>
                        <div class="flex-col flex right-align cb-margin-left-3x">
                            <button mat-mini-fab
                                    color="accent"
                                    class="collapse-button"
                                    (click)="collapsed = !collapsed">
                                <mat-icon>
                                    {{ collapsed ? 'chevron_right' : 'chevron_left ' }}
                                </mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="flex-col flex"
                         *ngIf="!collapsed && mappedItem.fullExplanation">
                        <cb-text-area label="Step-by-Step Decision Breakdown"
                                      name="fullExplanation"
                                      *ngIf="mappedItem.fullExplanation"
                                      [(ngModel)]="mappedItem.fullExplanation"
                                      [readonly]="true"></cb-text-area>
                    </div>
                </mat-card>
            </div>
        </div>
    </form>
    <footer class="end-center flex-row">
        <div class="flex-col flex">
            <cb-display-value-new label="Hub wait time"
                                  class="cb-margin-right"
                                  *ngIf="mappedItem.usageStats"
                                  [value]="getRoundedNumber(mappedItem.usageStats.timeSpentHubDataMs/1000)"></cb-display-value-new>
        </div>
        <div class="flex-col flex">
            <cb-display-value-new label="GPT wait time"
                                  class="cb-margin-right"
                                  *ngIf="mappedItem.usageStats"
                                  [value]="getRoundedNumber(mappedItem.usageStats.timeSpentGptMs/1000)"></cb-display-value-new>
        </div>
        <div class="flex-col flex">
            <cb-display-value-new label="Input Tokens"
                                  class="cb-margin-right"
                                  *ngIf="mappedItem.usageStats"
                                  [value]="mappedItem.usageStats.inputTokens.toString()"></cb-display-value-new>
        </div>
        <div class="flex-col flex">
            <cb-display-value-new label="Output Tokens"
                                  class="cb-margin-right"
                                  *ngIf="mappedItem.usageStats"
                                  [value]="mappedItem.usageStats.outputTokens.toString()"></cb-display-value-new>
        </div>
        <div class="flex-col flex">
            <cb-display-value-new label="Est Cost NZD"
                                  class="cb-margin-right"
                                  *ngIf="mappedItem.usageStats"
                                  [value]="getRoundedCurrency(mappedItem.usageStats.estimatedCostNzd)"></cb-display-value-new>
        </div>
        <div class="flex-col flex">
            <cb-display-value-new label="Inference Model"
                                  class="cb-margin-right"
                                  *ngIf="mappedItem.usageStats"
                                  [value]="mappedItem.usageStats.modelInformation.modelName"></cb-display-value-new>
        </div>
        <div class="flex-col flex">
            <cb-display-value-new label="Request ID"
                                  class="cb-margin-right"
                                  *ngIf="mappedItem"
                                  [value]="requestId"></cb-display-value-new>
        </div>
        <div class="flex-col flex">
            <button mat-raised-button
                    (click)="cancel()">Close
            </button>
        </div>
    </footer>
</cb-dialog>
