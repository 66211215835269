<mat-card class="cb-margin flex-col">
    <mat-card-header>
        <mat-card-title>Leads with Interest</mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex">
        <cb-table class="flex"
                  label="Leads for this Lot"
                  [stringColumns]="displayedColumns"
                  [loaded]="lotLeadsLoaded">
            <tr cbTableRow
                *ngFor="let lead of lotLeads$$ | async">
                <td>
                    {{lead.name}}
                </td>
                <cb-td-enum [value]="lead?.status"
                            [enum]="LEAD_STATUS_ENUM">
                </cb-td-enum>
                <td>
                    {{lead.createdDate | cbDate}}
                </td>
                <td>
                    {{lead.noContacts}}
                </td>
                <td>
                    {{lead.mainContact}}
                </td>
                <td>
                    {{lead.lotInterests}}
                </td>
                <td>
                    {{lead.buildingConsultant}}
                </td>
                <td>
                    {{lead.lastCallBackDate | cbDate}}
                </td>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <button mat-icon-button
                            (click)="viewLead(lead.id)">
                        <mat-icon>pageview</mat-icon>
                    </button>
                </td>
            </tr>
        </cb-table>
    </mat-card-content>
</mat-card>
