<form class="flex-col flex"
      #schemeSearchForm="ngForm">
    <mat-card class="cb-margin end flex">
        <div class="start-center flex-row flex"
             *cbWaitFor="lotMappedItem">
            <mat-icon class="cb-grey">search</mat-icon>
            <mat-form-field class="cb-margin-left cb-margin-right flex">
                <input matInput
                       type="text"
                       name="search"
                       placeholder="Search Schemes"
                       [(ngModel)]="userCacheItem.data.query"/>
            </mat-form-field>
            <span class="flex"></span>
            <div class="flex-row">
                <div class="button-container">
                    <button mat-raised-button
                            color="primary"
                            *ngIf="lotDesignPermissions.canRequestScheme()"
                            [disabled]="!lotMappedItem.canRequestDesignScheme"
                            (cbClick)="requestDesignSchemeSteps()">
                        <span>Request Design Scheme</span>
                    </button>
                    <cb-popup-tip icon="help"
                                  iconColour="cb-accent"
                                  horizontalPosition="left"
                                  class="blocking-reason-popup"
                                  *ngIf="!lotMappedItem.canRequestDesignScheme && !lotMappedItem.hasSpecTemplate">
                        <div class="flex-col">
                            <span class="mat-body-1 cb-margin">
                                Specification Template not applied.
                            </span>
                            <button class="cb-margin-top"
                                    mat-raised-button
                                    color="primary"
                                    [disabled]="lotMappedItem.lotSpecLocked"
                                    (click)="applySpecTemplate()">
                                Apply Spec Template
                            </button>
                        </div>
                    </cb-popup-tip>
                </div>
                <button mat-raised-button
                        color="primary"
                        *ngIf="lotDesignPermissions.canCreateScheme()"
                        [ngClass]="!lotMappedItem.canRequestDesignScheme && !lotMappedItem.hasSpecTemplate ? 'cb-margin-left-4x' : 'cb-margin-left-2x'"
                        (cbClick)="createDesignScheme()">
                    Create Design Scheme
                </button>
            </div>
        </div>
    </mat-card>
    <div class="scroll-container"
         [cbInfiniteScroll]="listContainer"
         [fetch]="fetchResults()"
         [queryUpdated]="queryUpdate"
         [enabled]="searchEnabled"
         [(results)]="results"
         [(currentPage)]="currentPage">
        <cb-info-message *ngIf="shouldShowNoResultsMessage()"
                         message="There are no Design Schemes available for your search criteria"
                         iconClass="cb-accent">
        </cb-info-message>
        <div class="flex-wrap"
             #listContainer>
            <ng-container *cbWaitFor="resultsLoaded$$ | async">
                <cb-design-scheme-card class="flex-col md-33 lg-33 sm-50 xl-25 xs-100 flex-25"
                                       *ngFor="let result of results"
                                       [mappedItem]="result"
                                       [lot]="lotMappedItem"
                                       (viewClick)="viewDesignScheme($event)">
                </cb-design-scheme-card>
            </ng-container>
        </div>
    </div>
</form>
