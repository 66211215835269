import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { IComplianceRegistrationLogicService } from './interfaces/i.compliance-registration.logic.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IComplianceRegistrationMappedItem } from './interfaces/i.compliance-registration.mapped';
import { ComplianceRegistrationMappedItem } from './compliance-registration.mapped';
import { IComplianceRegistrationDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Injectable()
export class ComplianceRegistrationLogicService
    extends BaseLogicService<IComplianceRegistrationDto, IComplianceRegistrationMappedItem>
    implements IComplianceRegistrationLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('complianceregistrations', ComplianceRegistrationMappedItem);
    }

    public generateReport(): Observable<any> {
        return this.$http.download(`/${this.$baseUri}/report`, 'Generating Compliance Registration Report...');
    }
}
