import {Component} from '@angular/core';
import {DocumentTypesLogicService} from '@app/logic/document-types/document-types.logic.service';
import {UsersLogicService} from '@app/logic/users/users.logic.service';
import {DocumentTypeMappedItem} from '@app/logic/document-types/document-type.mapped';
import {DocumentTypeDialogComponent} from './document-type-dialog/document-type-dialog.component';
import {BaseSimpleListViewDirective} from '@app/shared/base-views/base-simple-list-view.directive';
import {PermissionsPermissions} from '@app/core/permissions';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import { IDocumentTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-document-types',
    templateUrl: './document-types.component.html',
    styleUrls: ['./document-types.component.scss'],
    providers: [DocumentTypesLogicService, UsersLogicService]
})
export class DocumentTypesComponent extends BaseSimpleListViewDirective<IDocumentTypeDto, DocumentTypesLogicService> {
    public readonly columns = ['Document Type', 'Code', 'File Types', 'Max File Size (MB)', 'Active', ''];

    constructor(
        public readonly cbDialog: CbDialogService,
        protected readonly documentTypesLogicService: DocumentTypesLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
    ) {
        super(
            cbDialog,
            documentTypesLogicService,
            DocumentTypeDialogComponent,
            'Document Type',
            DocumentTypeMappedItem,
            permissionsPermissions,
        );
    }

    public sortSearchResults(searchResults: Array<IDocumentTypeDto>): Array<IDocumentTypeDto> {
        return searchResults.sort((a, b) => a.sortOrder - b.sortOrder);
    }

    public deleteItem(item: IDocumentTypeDto): void {
        this.deleteItemClicked(item, { heading: 'Document Type', message: `Are you sure you want to delete ${item.label} Document Type?` });
    }
}



