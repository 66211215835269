<cb-table class="flex"
          [stringColumns]="displayedColumns"
          [loaded]="historyItemsLoaded">
    <tr cbTableRow
        *ngFor="let history of historyItems$$ | async">
        <td>{{history.createdDate | date:'dd MMM yyyy hh:mm a'}}</td>
        <td>{{history.createdByName}}</td>
        <td>{{history.margin}}</td>
        <td>{{history.landPrice | cbCurrency}}</td>
        <td>{{history.buildCost | cbCurrency}}</td>
        <td>{{history.buildPrice | cbCurrency}}</td>
        <td>{{history.buildPrice | cbCurrency:'addGst'}}</td>
    </tr>
</cb-table>
