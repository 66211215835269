import { ITeamsLogicService } from './interfaces/i.teams.logic.service';
import { BaseMappedItem } from '../base/base-mapped-item';
import { ITeamsMappedItem } from './interfaces/i.teams.mapped';
import { DtoProp } from '../base/dto-prop.decorator';
import { ITeamDto } from '@classictechsolutions/hubapi-transpiled-enums';

export class TeamsMappedItem
    extends BaseMappedItem<ITeamDto, ITeamsMappedItem, ITeamsLogicService>
    implements ITeamsMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public name: string;
    @DtoProp public key: string;
    @DtoProp public members: number;
    @DtoProp public isActive: boolean;
    @DtoProp public isSystemTag: true;

    constructor(
        sourceData: any,
        logicService: ITeamsLogicService
    ) {
        super(sourceData, logicService, TeamsMappedItem);
    }
}
