import { BaseMappedItem } from '@app/logic/base/base-mapped-item';
import { IPreConsentPlansLogicService } from './interfaces/i.pre-consent-plans-logic.service';
import { IPreConsentPlanMappedItem } from './interfaces/i.pre-consent-plan.mapped';
import { DtoProp } from '../base/dto-prop.decorator';
import {
    IDesignComplexityDto,
    IIdAndLabelDto,
    IPreConsentPlanDto,
    IPreConsentPlanHistoryDto,
    IPreConsentPlanItemDto,
    IPreConsentPlanRejectionHistoryDto,
    PRECONSENT_PLAN_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { GuIdAndLabelDto } from '@app/shared/dtos/id-label.dto';
import { Computed } from '../base/computed-prop.decorator';
import { filter, orderBy } from 'lodash';

export class PreConsentPlanMappedItem
    extends BaseMappedItem<IPreConsentPlanDto, IPreConsentPlanMappedItem, IPreConsentPlansLogicService>
    implements IPreConsentPlanMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public lotNumber: number;
    @DtoProp public preConsentId: number;
    @DtoProp public estimatedHours: number;
    @DtoProp public elapsedHours: number;
    @DtoProp public dueDate: string;
    @DtoProp public planName: string;
    @DtoProp public isDeleted: boolean;
    @DtoProp public designComplexity: IDesignComplexityDto;
    @DtoProp public assignedToUser: GuIdAndLabelDto;
    @DtoProp public preConsentPlanStatus: IIdAndLabelDto;
    @DtoProp public rejectionReason: string;
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public preConsentPlanHistories: IPreConsentPlanHistoryDto[];
    @DtoProp public preConsentPlanRejectionHistories: IPreConsentPlanRejectionHistoryDto[];
    @DtoProp public designReviewRequired: boolean;
    @DtoProp public reviewerId: string;
    @DtoProp public reviewerName: string;
    @DtoProp public items: IPreConsentPlanItemDto[];
    @DtoProp public itemsCount: number;
    @DtoProp public lotId: number;

    @Computed() public get failedReviewHistories(): IPreConsentPlanHistoryDto[] {
        const designRejectedDesign: IPreConsentPlanHistoryDto[] = filter(this.preConsentPlanHistories,
            function(element) {
                return element.toStatus === PRECONSENT_PLAN_STATUS_ENUM.InQueueForRework && element.notes !== undefined;
            });
        return orderBy(designRejectedDesign, [function(i): IPreConsentPlanHistoryDto[] { return i.createdDate; }], 'desc');
    }

    public $designComplexityId: number;

    constructor(
        sourceData: IPreConsentPlanDto,
        logicService: IPreConsentPlansLogicService
    ) {
        super(sourceData, logicService, PreConsentPlanMappedItem);
    }

    public get documentEntityId(): number {
        return this.id;
    }

    public get documentEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    public getElapsedTime(): Observable<string> {
        return this.$logicService.getElapsedTime(this.id);
    }

    public request(dto: IPreConsentPlanDto): Observable<IPreConsentPlanDto> {
        return this.$logicService.request(dto);
    }

    public create(dto: IPreConsentPlanDto): Observable<IPreConsentPlanDto> {
        return this.$logicService.create(dto);
    }

    public reject(id: number, dto: IPreConsentPlanDto): Observable<IPreConsentPlanDto> {
        return this.$logicService.reject(id, dto);
    }
}
