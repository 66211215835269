<ng-container *cbWaitFor="lotMappedItem$$ | async">
    <cb-working-drawing-search *ngIf="!selectedItem"
                               [lotMappedItem]="lotMappedItem"
                               (workingDrawingSelected)="workingDrawingSelected($event)"></cb-working-drawing-search>
    <cb-working-drawing-view *ngIf="selectedItem"
                             [mappedItem]="selectedItem"
                             [lotMappedItem]="lotMappedItem"
                             (lotMappedItemChange)="lotMappedItemChanged()"
                             (goBack)="handleGoBack()"></cb-working-drawing-view>
</ng-container>
