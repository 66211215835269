import { takeOne } from 'cb-hub-lib';
import { ComponentType } from '@angular/cdk/portal';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { BusinessAccountsInputFindDialogDirective } from '../../dialog/extensions/business-accounts-input-find-dialogs/business-accounts-input-find-dialog.directive';
import { BaseInputFindDirective } from '../../base-input-find.directive';
import { Directive } from '@angular/core';
import { BusinessAccountsInputFindSearchService } from '@app/core/services/search/business-accounts-input-find-search.service';
import { IBusinessAccountDocumentDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Directive()
export class BusinessAccountsInputFindDirective extends BaseInputFindDirective {
    protected _myDialog: ComponentType<BusinessAccountsInputFindDialogDirective>;

    constructor(
        public readonly dialogRef: MatDialog,
        public readonly router: Router,
        public searchService: BusinessAccountsInputFindSearchService
    ) {
        super();
    }

    public openSearchDialog = (): void => {
        this.dialogRef.open(
            this._myDialog,
            {
                minWidth: '95%',
                data: {
                    extraSearchParams: this.searchParams,
                    placeholder: 'Type in Legal Name, Trading Name or ABM Code',
                    dialogHeading: 'Business Accounts',
                    contentHeading: 'Account Search',
                    searchService: this.searchService
                },
                panelClass: 'cb-dialog-container'
            }
        )
            .afterClosed()
            .pipe(takeOne())
            .subscribe({
                next: (businessAccount: IBusinessAccountDocumentDto) => {
                    if (businessAccount) {
                        this.text = businessAccount.tradingName;
                        this.value = businessAccount;
                    }
                }
            });
    };

    public viewItem = (): void => {
        this.router.navigate([`/businessaccounts/edit/${this.id}/summary`]);
    };
}
