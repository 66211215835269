<div class="cb-margin flex-col flex">
    <div class="flex-row flex"
         *ngIf="showHeaderAndAddButton">
        <span class="mat-body-2">
            Specific Items that the Designer needs to complete as part of this Pre-Consent Plan
        </span>
        <span class="flex"></span>
        <button mat-raised-button
                color="primary"
                (click)="addEditItem(FormMode.Add, null)">
            Add Item
        </button>
    </div>
    <mat-divider *ngIf="showHeaderAndAddButton"
                 class="cb-margin"></mat-divider>
    <cb-table class="flex"
              [loaded]="true"
              emptyMessage="No Items have been added for this Pre Consent Plan">
        <tbody cbTableBody>
            <ng-container *ngFor="let item of mappedItem.items; index as i">
                <tr *ngIf="!item.isDeleted">
                    <td>
                        Item #{{item.itemNumber}}
                    </td>
                    <td>
                        {{ item.statusId | cbEnumToLabel: PRE_CONSENT_PLAN_ITEM_STATUS_ENUM }}
                    </td>
                    <td>
                        <div class="cb-margin flex-col flex">
                            <div class="flex-row flex">{{ item.description }}</div>
                            <div *ngIf="item.comments"
                                 class="cb-margin flex-row flex">
                                <mat-icon class="cb-margin-right">message</mat-icon>
                                <mat-divider [vertical]="true"
                                             class="cb-margin-left cb-margin-right"></mat-divider>
                                {{item.comments}}
                            </div>
                        </div>
                    </td>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <div matTooltip="{{editTooltipText}}"
                             class="inline-button">
                            <button mat-icon-button
                                    class="cb-margin-right"
                                    (click)="addEditItem(FormMode.Edit, item, i)"
                                    [disabled]="editDisabled">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>
                        <div matTooltip="Delete Item"
                             class="inline-button">
                            <button *ngIf="showDeleteButton"
                                    mat-icon-button
                                    class="cb-margin-right"
                                    (click)="deleteItem(item)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </cb-table>
</div>
