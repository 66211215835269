import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BuildTypeMappedItem, IBuildTypeLogicService, IBuildTypeMappedItem } from '@app/logic/build-type';
import { IBuildTypeDto, LOT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { find, each, map, findIndex, some } from 'lodash';
import { ILocationDto } from '@app/logic/location';
import { IBuildProgrammeTemplateDto } from '@app/logic/build-programme-template';

interface IData {
    mappedItem: BuildTypeMappedItem;
    buildTypes: BuildTypeMappedItem[];
    locationRegions: ILocationDto[];
    buildTemplates: IBuildProgrammeTemplateDto[];
}

@Component({
    selector: 'cb-build-types-dialog',
    templateUrl: './build-types-dialog.component.html',
    styleUrls: ['./build-types-dialog.component.scss'],
})
export class BuildTypesDialogComponent extends BaseDialogFormViewDirective<IBuildTypeDto, IBuildTypeMappedItem, IBuildTypeLogicService> {
    public readonly displayedColumns: string[] = [
        'region',
        'buildTemplate',
    ];
    public static readonly MIN_WIDTH = '50%';
    public mappedItem: BuildTypeMappedItem;
    public lotTypes = LOT_TYPE_ENUM.toSelectList().slice().sort((a, b) => a.label.localeCompare(b.label));
    public paymentTemplates: any[];

    constructor(
        public readonly toastService: ToastService,
        public readonly dialogRef: MatDialogRef<BuildTypesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.mappedItem.buildProgrammeTemplates = this.mappedItem.buildProgrammeTemplates ?? [];

        this.paymentTemplates = map(this.mappedItem.paymentTemplates, (paymentTemplate: any) => {
            return {
                id: paymentTemplate.paymentTemplateId,
                label: paymentTemplate.label
            };
        });
    }

    public isCodeDuplicated(): boolean {
        return some(this.data.buildTypes, (buildType: BuildTypeMappedItem) =>
            buildType.code === this.mappedItem.code && buildType.id !== this.mappedItem.id);
    }

    public isLotTypeChecked(lotTypeId: number): boolean {
        return this.mappedItem.lotType && this.mappedItem.lotType.indexOf(lotTypeId) > -1;
    }

    public onLotTypeChanged(lotTypeId: number, isChecked: boolean): void {
        this.mappedItem.lotType = this.mappedItem.lotType && this.mappedItem.lotType.length ? this.mappedItem.lotType : [];
        if (isChecked) {
            if (this.mappedItem.lotType.indexOf(lotTypeId) === -1) {
                this.mappedItem.lotType.push(lotTypeId);
            }
        } else {
            const index = this.mappedItem.lotType.indexOf(lotTypeId);
            if (index > -1) {
                this.mappedItem.lotType.splice(index, 1);
            }
        }
    }

    public getDefaultPaymentTemplate(): number {
        const defaultTemplate: any = find(this.mappedItem.paymentTemplates, { isDefault: true });
        return defaultTemplate ? defaultTemplate.paymentTemplateId : undefined;
    }

    public onPaymentTemplateChanged(paymentTemplateId: number): void {
        each(this.mappedItem.paymentTemplates, (item: any) => {
            item.isDefault = item.paymentTemplateId === paymentTemplateId;
        });
    }

    public getDefaultBuildTemplate(currentLocationId: number): number {
        const defaultTemplate: any = find(this.mappedItem.buildProgrammeTemplates, { locationId: currentLocationId });
        return defaultTemplate ? defaultTemplate.buildProgrammeTemplateId : 0;
    }

    public onBuildTemplateChanged(currentLocationId: number, buildTemplateId: number): void {
        const index: any = findIndex(this.mappedItem.buildProgrammeTemplates, { locationId: currentLocationId });
        if (buildTemplateId) {
            if (index > -1) {
                this.mappedItem.buildProgrammeTemplates[index].buildProgrammeTemplateId = buildTemplateId;
            } else {
                this.mappedItem.buildProgrammeTemplates.push({ locationId: currentLocationId, buildProgrammeTemplateId: buildTemplateId });
            }
        } else if (!buildTemplateId && index > -1) {
            this.mappedItem.buildProgrammeTemplates.splice(index, 1);
        }
    }
}
