import { Component, OnInit } from '@angular/core';
import { ACCOUNT_TYPE_ENUM, CLIENT_ACCOUNT_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ClientAccountPermissions } from '@app/core/permissions';
import { ClientAccountLogicService } from '@app/logic/client-account/client-account.logic.service';
import { IClientAccountMappedItem } from '@app/logic/client-account/interfaces/i.client-account.mapped';
import { IContactDto } from '@app/logic/contacts/interfaces/i.contact.dto';
import { NZ_LOCALE } from '@app/shared/declarations/app.constants';
import { toPromise } from 'cb-hub-lib';
import { Subscription, switchMap } from 'rxjs';
import { CLIENT_ACCOUNT_FULL_ROUTE } from '../client-account-route.const';
import { HISTORY_SEARCH_ENTITY } from '@app/shared/enums/history/history-search-entity.enum';

@Component({
    selector: 'cb-client-account-view',
    templateUrl: './client-account-view.component.html',
    styleUrls: ['./client-account-view.component.scss']
})
export class ClientAccountViewComponent implements OnInit {

    public account: IClientAccountMappedItem;
    public editAccount: IClientAccountMappedItem;
    public subscription: Subscription;
    public contacts: IContactDto[];

    public CLIENT_ACCOUNT_STATUS_ENUM = CLIENT_ACCOUNT_STATUS_ENUM;
    public ACCOUNT_TYPE_ENUM = ACCOUNT_TYPE_ENUM;

    public readonly CLIENT_ACCOUNT_FULL_ROUTE = CLIENT_ACCOUNT_FULL_ROUTE;

    public isEdit = false;
    public mainAccountChanged = false;

    public editAccountForm: NgForm;

    constructor(
        public readonly accountsLogic: ClientAccountLogicService,
        public readonly permissions: ClientAccountPermissions,
        public readonly route: ActivatedRoute,
    ) { }

    public ngOnInit(): void {

        toPromise(this.route.params.pipe(
            switchMap((params: { id: string }) => {
                return this.accountsLogic.$getMappedItem(params.id);
            })
        )).then((mappedItem: IClientAccountMappedItem) => {
            this.account = mappedItem;
        });

        toPromise(this.route.params.pipe(
            switchMap((params: { id: number }) => {
                return this.accountsLogic.getContacts(params.id);
            })
        )).then((results: IContactDto[]) => {
            this.contacts = results;
        });
    }

    public getCreatedDisplayValue(): string {
        return `${this.account.createdByName} on ${new DatePipe(NZ_LOCALE).transform(this.account.createdDate)}`;
    }


    public getUpdatedDisplayValue(): string {
        return `${this.account.updatedByName} on ${new DatePipe(NZ_LOCALE).transform(this.account.updatedDate)}`;
    }

    public OpenEditForm(): void {
        this.isEdit = true;
        this.editAccount = this.account.$clone();
    }


    public cancelEdit(): void {
        this.isEdit = false;
    }

    public saveAccount(): void {
        this.editAccount.$save()
            .subOnce(() => {
                this.isEdit = false;
            });
    }


    protected readonly HISTORY_SEARCH_ENTITY = HISTORY_SEARCH_ENTITY;
}
