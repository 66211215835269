import { Injectable, Injector } from '@angular/core';
import { IDesignTeamWorkingDrawingsSearch } from '@app/core/services/user-cache/user-cache-areas';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import { IIdAndNameDto, IWorkingDrawingChangeDto, IWorkingDrawingDocumentDto, IWorkingDrawingDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { cleanObjectDeep } from 'cb-hub-lib';
import { ISearchResult } from 'projects/cb-hub-lib/src/lib/interfaces/search-result.interface';
import { map, Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicServiceWithSearch } from '../base/base-logic-service-with-search.component';
import { IWorkingDrawingSearchParams } from './interfaces/i.working-drawing-search-params';
import { IWorkingDrawingMappedItem } from './interfaces/i.working-drawing.mapped';
import { IWorkingDrawingsLogicService } from './interfaces/i.working-drawings-logic.service';
import { WorkingDrawingMappedItem } from './working-drawing.mapped';

@Injectable()
export class WorkingDrawingsLogicService
    extends BaseLogicServiceWithSearch<
    IWorkingDrawingDto,
    IWorkingDrawingMappedItem,
    IDesignTeamWorkingDrawingsSearch,
    ISearchResult<IWorkingDrawingDocumentDto>>
    implements IWorkingDrawingsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('workingdrawings', WorkingDrawingMappedItem);
    }

    public getSearchList(params: IWorkingDrawingSearchParams): Observable<ISearchResult<IWorkingDrawingDocumentDto>> {
        return this.$http
            .get<ISearchResult<IWorkingDrawingDocumentDto>>(`${this.$baseUri}/search`, cleanObjectDeep(params));
    }

    public requestWorkingDrawing(lotId: number): Observable<IWorkingDrawingDto> {
        return this.$http.post(`${this.$baseUri}/lot/${lotId}/request`, { id: lotId });
    }
    public request(workingDrawing: IWorkingDrawingDto): Observable<IWorkingDrawingDto> {
        return this.$http.post(`${this.$baseUri}/lot/${workingDrawing.lotId}/requestwithdetail`, workingDrawing);
    }
    public reject(comments: string, workingDrawingId: number): Observable<IWorkingDrawingDto> {
        return this.$http.post(`${this.$baseUri}/${workingDrawingId}/reject`, { id: workingDrawingId, label: comments });
    }

    public resubmit(workingDrawingId: number): Observable<IWorkingDrawingDto> {
        return this.$http.post(`${this.$baseUri}/${workingDrawingId}/resubmit`, {});
    }


    public updateWorkingDrawingChange(workingDrawingChange: IWorkingDrawingChangeDto): Observable<IWorkingDrawingChangeDto> {
        return this.$http.post(`${this.$baseUri}/changes/${workingDrawingChange.id}`, workingDrawingChange);
    }

    public getElapsedTime(workingDrawingId: number): Observable<string> {
        return this.$http.get<string>(`${this.$baseUri}/elapsedtime/${workingDrawingId}`);
    }

    public generateConsentDocuments(workingDrawingId: number): Observable<any> {
        return this.$http.download(`/${this.$baseUri}/${workingDrawingId}/consentdocs/zip`, 'Generating Consent Documents...');
    }

    public getLookupByLot(lotId: number): Observable<IIdAndNameDto[]> {
        return this.$http.get(`${this.$baseUri}/lot/${lotId}/skinny`);
    }
    public $getMappedSearchList(params: IDesignTeamWorkingDrawingsSearch): Observable<IWorkingDrawingMappedItem[]> {
        return this.$http.get<ISearchResult<IWorkingDrawingDocumentDto>>(`${this.$baseUri}/search`, cleanObjectDeep(cloneDeepSafe(params)))
            .pipe(map(results => {
                return results.items.map(result => {
                    const mappedItem = this.$createMappedItem(result);
                    mappedItem.$addressCity = result.addressCity;
                    mappedItem.$hasUnansweredQuestion = result.hasUnansweredQuestion;
                    mappedItem.$addressStreet = result.addressStreet;
                    mappedItem.$jobNumber = result.jobNumber;
                    mappedItem.$lotNumber = result.lotNumber;
                    mappedItem.$dueBy = result.dueBy;
                    mappedItem.$clientAccountName = result.clientAccountName;
                    mappedItem.$designComplexityLabel = result.designComplexityLabel;
                    mappedItem.$projectName = result.projectName;
                    return mappedItem;
                });
            }));
    }
}
