<label class="label">{{label}}</label>
<div class="start-center flex-row">
    <span cbEllipsis="applyEllipsis"
          [ngClass]="!applyEllipsis ? 'content' : 'truncate-content'"
          class="mat-body-1"
          *ngIf="!contentRef?.innerHTML?.trim()">{{value | cbDasher}}</span>
    <button *ngIf="showBtn && btnCondition !== false"
            mat-icon-button
            (click)="handleClick($event)"
            class="cb-grey">
        <mat-icon>{{btnIcon != null ? btnIcon : 'pageview'}}</mat-icon>
    </button>
</div>
<div cbEllipsis="applyEllipsis"
     #contentRef>
    <ng-content [ngClass]="!applyEllipsis ? 'content' : 'truncate-content'"
                class="mat-body-1"></ng-content>
</div>
