
    <div> Other slots have the same items that you might want to change: </div>
    <div class="product-grid" *ngIf="relatedSlots?.length > 0">
        <mat-card
            *ngFor="let product of relatedSlots; trackBy: trackById"
            [class.selected]="isSelected(product)"
            (click)="toggleSelection(product)">
            <mat-card-header>
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    [checked]="isSelected(product)"
                    (change)="toggleSelection(product)">
                </mat-checkbox>
                <mat-card-title>{{ product.name }}</mat-card-title>
                <mat-card-subtitle>{{ product.houseArea +' > ' + product.slotName }}</mat-card-subtitle>
            </mat-card-header>
            <div class="image-carousel">
                <img mat-card-image [src]="getProductImage(product)" alt="{{ product.name }}" />
            </div>
            <mat-card-content>
                <p><strong> Rate:</strong> ${{ product.nationalRate | number: '1.2-2' }}</p>
            </mat-card-content>
        </mat-card>
    </div>

