import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { IBankAccountsLogicService } from './interfaces/i.bank-accounts.logic.service';
import { BaseLogicService } from '../base/base-logic.service';
import { BankAccountMappedItem } from './bank-account.mapped';
import { IBankAccountMappedItem } from './interfaces/i.bank-account.mapped';
import { IBankAccountDetailsDto, IIdAndLabelDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Injectable()
export class BankAccountsLogicService extends BaseLogicService<IBankAccountDetailsDto, IBankAccountMappedItem> implements IBankAccountsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'bankaccounts',
            BankAccountMappedItem,
        );
    }

    public getAvailableBankAccountsForBusinessEntity(businessEntityId: number): Observable<Array<IIdAndLabelDto>> {
        return this.$http
            .get<Array<IIdAndLabelDto>>(`${this.$baseUri}/businessentity/${businessEntityId}`);
    }
}
