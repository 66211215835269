import { BaseMappedItem } from '../base/base-mapped-item';
import { IColourItemDto } from './interfaces/i.colour-item.dto';
import { IColourItemsLogicService } from './interfaces/i.colour-items.logic.service';
import { IColourItemMappedItem } from './interfaces/i.colour-item.mapped';
import { Observable } from 'rxjs';
import { DtoProp } from '../base/dto-prop.decorator';
import { environment } from '@src/environments/environment';

export class ColourItemMappedItem
    extends BaseMappedItem<IColourItemDto, IColourItemMappedItem, IColourItemsLogicService>
    implements IColourItemMappedItem {

    @DtoProp public id: number;
    @DtoProp public name: string;
    @DtoProp public isActive: boolean;
    @DtoProp public showInClientSpecification: boolean;
    @DtoProp public textForSpecification: string;
    @DtoProp public code: string;
    @DtoProp public deletedDate: string;
    @DtoProp public isDeleted: boolean;
    @DtoProp public image: any;
    @DtoProp public imageUrl: string;
    @DtoProp public specTemplateGroupId: number;
    @DtoProp public colourItemImageFile: string;
    @DtoProp public params: any;
    @DtoProp public removeImageOnSave: boolean;

    public $colourItemImageFile: File;

    constructor(
        sourceData: IColourItemDto,
        logicService: IColourItemsLogicService
    ) {
        super(sourceData, logicService, ColourItemMappedItem);
    }

    public static buildImageUrl(imgObj: any, colourItemId: number): string {
        let url: string;
        if (imgObj && imgObj.id) {
            url = `${environment.api}/colouritems/${colourItemId}/images/${imgObj.id}.${imgObj.extension}`;
        }
        return url;
    }

    public removeImage(): Observable<IColourItemDto> {
        return this.removeItemImage(this.id);
    }

    public saveImage(): Observable<IColourItemDto> {
        return this.uploadImageFile(this.id);
    }

    private uploadImageFile(itemId: number): Observable<IColourItemDto> {
        if (!this.$colourItemImageFile) {
            return;
        }

        const fd = new FormData();
        fd.append('file', this.$colourItemImageFile);

        const newItem = this.$logicService.uploadImage(itemId, fd);
        return newItem;
    }

    private removeItemImage(itemId: number): Observable<IColourItemDto> {
        this.removeImageOnSave = false;
        const updatedItem = this.$logicService.removeImage(itemId);
        return updatedItem;
    }

    protected $postLoad(): void {
        this.imageUrl = ColourItemMappedItem.buildImageUrl(this.image, this.id);
    }

    protected $preSave(): void {
        if (!this.id) {
            this.id = 0;
        }
    }
}
