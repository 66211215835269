<button mat-raised-button
        color="primary"
        [matMenuTriggerFor]="reportMenu">
    <mat-icon>arrow_drop_down</mat-icon>
    &nbsp;Reports
</button>
<mat-menu #reportMenu="matMenu">
    <button mat-menu-item
            [disabled]="cannotDownloadDraftSchedule()"
            (click)="lot.generateDraftCreateSchedule().subOnce()">
        Draft Create Schedule
    </button>
    <button mat-menu-item
            *ngIf="canViewHLFlyer"
            [disabled]="!isHLFlyerButtonEnabled"
            (click)="lot.generateHLFlyer().subOnce()">
        H&L Flyer
    </button>
    <button mat-menu-item
            (click)="
            lot.generateColourYourDreams().subOnce()"
            [disabled]="!lot.canGenerateColourYourDreams">
        Color Schedule
    </button>
    <button mat-menu-item
            (click)="lot.generatePropertyInformation().subOnce()">
        Property Information
    </button>
    <button mat-menu-item
            (click)="lot.generateClientPropertyInformation().subOnce()">
        Client Property Information
    </button>
    <button mat-menu-item
            (click)="generateSiteEvaluation()"
            [disabled]="!lot.canGenerateLeadSiteEvaluationForm">
        Site Evaluation Form
    </button>
    <button mat-menu-item
            (click)="lot.generateLotSpecReport().subOnce()">
        Lot Spec Report
    </button>
    <button *ngIf="lot.hasScheduleSpecTemplate"
            mat-menu-item
            (click)="lot.generateCostXItemsReport().subOnce()"
            [disabled]="!currentUser.isQSTeam()">
        CostX Items
    </button>
    <button mat-menu-item
            (click)="lot.generateFinalLotSpec().subOnce()"
            [disabled]="!lot.canGenerateLiveSchedule">
        Live Schedule
    </button>
    <button mat-menu-item
            [disabled]="!lot.isDesignAndBuild"
            (click)="lot.generateCreateBookCoverPage1().subOnce()">
        Create Book Cover Page I
    </button>
    <button mat-menu-item
            [disabled]="!lot.isDesignAndBuild"
            (click)="lot.generateCreateBookCoverPage2().subOnce()">
        Create Book Cover Page II
    </button>
    <button mat-menu-item
            [disabled]="!lot.isDesignAndBuild"
            (click)="lot.generateDesignAndBuildContractLetter().subOnce()">
        Design and Build Contract Letter
    </button>
    <button mat-menu-item
            (click)="downloadPricingTemplate()">
        Pricing Template
    </button>
</mat-menu>
