import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IDesignSchemeNotificationStageLogicService } from './interfaces/i.design-scheme-notification-stage.logic.service';
import { IDesignSchemeNotificationStageMappedItem } from './interfaces/i.design-scheme-notification-stage.mapped';
import { IIntLookupDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/IntLookupDto';

export class DesignSchemeNotificationStageMappedItem
    extends BaseMappedItem<IIntLookupDto, IDesignSchemeNotificationStageMappedItem, IDesignSchemeNotificationStageLogicService>
    implements IDesignSchemeNotificationStageMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public label: string;
    @DtoProp public code: string;
    @DtoProp public isActive: boolean;

    constructor(
        sourceData: IIntLookupDto,
        logicService: IDesignSchemeNotificationStageLogicService
    ) {
        super(
            sourceData,
            logicService,
            DesignSchemeNotificationStageMappedItem
        );
    }

}
