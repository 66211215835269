import {
    CLIENT_SALE_STATUS_ENUM,
    ClientSaleBalanceDueTriggerEnumId,
    ClientSaleStatusEnumId, IClientSaleClassicCareUpdateDto, IClientSaleClientAccountUpdateDto, IClientSaleLostDto, IClientSaleUpdateDto,
    ILotProgressPaymentDto,
    JobLostReasonEnumId,
    LOT_CONTRACT_TYPE_ENUM,
    LotContractTypeEnumId,
    PurchaseReasonEnumId
} from '@classictechsolutions/hubapi-transpiled-enums';
import {IClientSaleMappedItem, IStepCompletion} from './interfaces/i.client-sale.mapped';

import {BaseMappedItem} from '../base/base-mapped-item';
import {Computed} from '../base/computed-prop.decorator';
import {IClientSaleClientAccountDto} from './interfaces/i.client-sale-client-account.dto';
import {IClientSaleContractPricingUpdateDto} from './interfaces/i.client-sale-contract-pricing-update.dto';
import {IClientSaleDocumentInfoDto} from './interfaces/i.client-sale-document-info.dto';
import {IClientSaleDto} from './interfaces/i.client-sale.dto';
import {IClientSaleLogicService} from './interfaces/i.client-sale-logic.service';
import {IClientSaleLotDto} from './interfaces/i.client-sale-lot.dto';
import {IClientSaleLotUpdateDto} from './interfaces/i.client-sale-lot-update.dto';
import {Observable, tap} from 'rxjs';
import {isNullOrWhiteSpace} from 'cb-hub-lib';
import {DtoProp} from '../base/dto-prop.decorator';
import { DateUtil } from '@app/shared/utils/date.util';

export class ClientSaleMappedItem
    extends BaseMappedItem<IClientSaleDto, IClientSaleMappedItem, IClientSaleLogicService>
    implements IClientSaleMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public statusId: ClientSaleStatusEnumId;
    @DtoProp public statusLabel: string;
    @DtoProp public saleNumber: number;
    @DtoProp public leadId: number;
    @DtoProp public leadName: string;
    @DtoProp public buildingConsultantId: string;
    @DtoProp public buildingConsultantName: string;
    @DtoProp public qualifiedDate: string;
    @DtoProp public sunsetDate: string;
    @DtoProp public purchaseReasonId: PurchaseReasonEnumId;
    @DtoProp public purchaseReasonLabel: string;
    @DtoProp public insuredPartyName: string;
    @DtoProp public insuredPartyEmail: string;
    @DtoProp public allowNominee: boolean;
    @DtoProp public buildDuration: number;
    @DtoProp public classicCareEnbaled: boolean;
    @DtoProp public classicCareContactId: string;
    @DtoProp public classicCareContactName: string;
    @DtoProp public preferredContact: string;
    @DtoProp public contractMangerId: string;
    @DtoProp public contractMangerName: string;
    @DtoProp public paymentsIncludeGst: boolean;
    @DtoProp public designAndLandLotPurchasePrice: number;
    @DtoProp public designAndLandLotDeposit: number;
    @DtoProp public thirdPartyLotDeposit: number;
    @DtoProp public thirdPartyLotPurchasePrice: number;
    @DtoProp public thirdPartyClientAccountName: string;
    @DtoProp public thirdPartyClientAccountId: number;
    @DtoProp public jobLostReason: JobLostReasonEnumId;
    @DtoProp public jobLostComments: string;
    @DtoProp public readonly unconditionalDate: string;
    @DtoProp public readonly isUnconditional: boolean;
    @DtoProp public lot: IClientSaleLotDto;
    @DtoProp public clientAccount: IClientSaleClientAccountDto;
    @DtoProp public readonly documentInfo: IClientSaleDocumentInfoDto;
    @DtoProp public isLeadClientDetailsComplete: boolean;
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public lotProgressPayments: ILotProgressPaymentDto[];
    @DtoProp public clientSaleBalanceDueTrigger: ClientSaleBalanceDueTriggerEnumId;

    public get noteEntityId(): number {
        return this.id;
    }

    public get taskEntityId(): number {
        return this.id;
    }

    public get noteEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    public get taskEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    public get documentEntityId(): number {
        return this.id;
    }

    public get documentEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    public $preSave(toSave: IClientSaleMappedItem): void {
        toSave.paymentsIncludeGst = true;
    }

    public $postLoad(): void {
        this.paymentsIncludeGst = true;
    }

    constructor(
        sourceData: IClientSaleDto,
        logicService: IClientSaleLogicService
    ) {
        super(sourceData, logicService, ClientSaleMappedItem);
    }

    private getCurrentStepCompletion(): IStepCompletion {

        return {
            saleDetails: this.lot != null && this.buildingConsultantId && !!this.purchaseReasonId,

            buildContract: this.documentInfo
                && !isNullOrWhiteSpace(this.documentInfo?.buildContractReviewedDate as string)
                && !isNullOrWhiteSpace(this.documentInfo?.clientSpecificationReviewedDate as string)
                && this.documentInfo?.outstandingDocumentsHaveBeenReviewedOrUploaded,

            classicCare: this.classicCareEnbaled && !isNullOrWhiteSpace(this.classicCareContactId),

            clientAccount: this.clientAccount?.name
                && this.clientAccount?.legalName
                && this.clientAccount?.accountTypeId > 0
                && this.clientAccount?.physicalAddress?.id > 0
                && !isNullOrWhiteSpace(this.clientAccount?.mainContactId),

            contractPricing: (() => {
                if (!this.lot) {
                    return false;
                }

                const isHouseAndLandContractPricingComplete = (): boolean => {
                    return this.lot?.contractPrice
                        && this.lot?.paymentTemplateId > 0
                        && this.lot?.contractGenerationFee != null
                        && this.lot?.lotHoldingDeposit != null
                        && !isNullOrWhiteSpace(this.contractMangerId);
                };

                const isDesignAndBuildContractPricingComplete = (): boolean => {
                    return this.lot?.contractPrice
                        && this.lot?.paymentTemplateId > 0
                        && !isNullOrWhiteSpace(this.contractMangerId);
                };

                const isDesignAndLandContractPricingComplete = (): boolean => {
                    return this.lot.hasExternalLandVendor ?
                        isThirdPartyHouseAndLandContractPricingComplete() :
                        isHouseAndLandContractPricingComplete();
                };

                const isThirdPartyHouseAndLandContractPricingComplete = (): boolean => {
                    return this.thirdPartyLotDeposit != null
                        && this.thirdPartyLotPurchasePrice != null
                        && this.thirdPartyClientAccountId > 0
                        && this.lot?.sellingPrice
                        && this.lot?.contractPrice
                        && this.lot?.paymentTemplateId > 0
                        && !isNullOrWhiteSpace(this.contractMangerId);
                };

                switch (this.lot?.lotContractTypeId) {
                    case LOT_CONTRACT_TYPE_ENUM.HouseAndLand:
                        return isHouseAndLandContractPricingComplete();
                    case LOT_CONTRACT_TYPE_ENUM.DesignAndBuild:
                        return isDesignAndBuildContractPricingComplete();
                    case LOT_CONTRACT_TYPE_ENUM.DesignAndLand:
                        return isDesignAndLandContractPricingComplete();
                    case LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand:
                        return isThirdPartyHouseAndLandContractPricingComplete();
                    default:
                        return false;
                }
            })(),

            design: this.lot?.appliedConceptId > 0,

            lotDetails: (() => {
                if (!this.lot) {
                    return false;
                }
                const titleValid = this.lot?.hasTitle || this.lot?.expectedTitleDate != null;
                switch (this.lot?.lotContractTypeId) {
                    case LOT_CONTRACT_TYPE_ENUM.HouseAndLand:
                    case LOT_CONTRACT_TYPE_ENUM.DesignAndLand:
                        return titleValid;
                    case LOT_CONTRACT_TYPE_ENUM.DesignAndBuild:
                    case LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand:
                        return titleValid && this.lot?.buildTypeId > 0;
                    default:
                        return false;
                }
            })(),

            saleAndPurchase: !isNullOrWhiteSpace(this.documentInfo?.spInformationReviewedDate as string)
                && !isNullOrWhiteSpace(this.documentInfo?.clientSpecificationReviewedDate as string),

            unconditional: this.statusId === CLIENT_SALE_STATUS_ENUM.UnConditional
                || this.statusId === CLIENT_SALE_STATUS_ENUM.Complete,
        };
    }

    @Computed() public get stepCompletion(): IStepCompletion {
        return this.getCurrentStepCompletion();
    }

    @Computed() public get isEditable(): boolean {
        return this.statusId !== CLIENT_SALE_STATUS_ENUM.SaleLost;
    }

    @Computed() public get isHouseAndLand(): boolean {
        return this.lot && this.lot?.lotContractTypeId === LOT_CONTRACT_TYPE_ENUM.HouseAndLand;
    }

    @Computed() public get isThirdPartyHouseAndLand(): boolean {
        return this.lot && this.lot?.lotContractTypeId === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand;
    }

    @Computed() public get isDesignAndBuild(): boolean {
        return this.lot && this.lot?.lotContractTypeId === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild;
    }

    @Computed() public get isDesignAndLand(): boolean {
        return this.lot && this.lot?.lotContractTypeId === LOT_CONTRACT_TYPE_ENUM.DesignAndLand;
    }

    @Computed() public get isExternalDesignAndLand(): boolean {
        return this.lot && this.lot?.lotContractTypeId === LOT_CONTRACT_TYPE_ENUM.DesignAndLand && this.lot?.hasExternalLandVendor;
    }

    @Computed() public get canSetSaleLost(): boolean {
        return this.statusId === CLIENT_SALE_STATUS_ENUM.ContractPending
            || this.statusId === CLIENT_SALE_STATUS_ENUM.AwaitingAgreement
            || this.statusId === CLIENT_SALE_STATUS_ENUM.Conditional
            || this.statusId === CLIENT_SALE_STATUS_ENUM.StalledSale;
    }

    @Computed() public get canRevertPendingLost(): boolean {
        return this.statusId === CLIENT_SALE_STATUS_ENUM.StalledSale;
    }

    @Computed() public get canRevertUnconditional(): boolean {
        return this.isUnconditional;
    }

    @Computed() public get canEditLotDetails(): boolean {
        return this.statusId === CLIENT_SALE_STATUS_ENUM.ContractPending
            || this.statusId === CLIENT_SALE_STATUS_ENUM.AwaitingAgreement
            || this.statusId === CLIENT_SALE_STATUS_ENUM.Conditional
            || this.statusId === CLIENT_SALE_STATUS_ENUM.StalledSale;
    }

    @Computed() public get canEditSaleDetails(): boolean {
        return this.statusId === CLIENT_SALE_STATUS_ENUM.ContractPending
            || this.statusId === CLIENT_SALE_STATUS_ENUM.AwaitingAgreement;
    }

    @Computed() public get canEditClientAccount(): boolean {
        return this.statusId === CLIENT_SALE_STATUS_ENUM.ContractPending || this.clientAccount?.physicalAddress?.id == null;
    }

    @Computed() public get canEditContractDetails(): boolean {
        return (this.statusId === CLIENT_SALE_STATUS_ENUM.ContractPending ||
                this.statusId === CLIENT_SALE_STATUS_ENUM.AwaitingAgreement ||
                this.statusId === CLIENT_SALE_STATUS_ENUM.Conditional) &&
            this.lot && this.lot?.appliedConceptId > 0;
    }

    @Computed() public get canChangeContractType(): boolean {
        return this.statusId === CLIENT_SALE_STATUS_ENUM.ContractPending;
    }

    @Computed() public get canDownloadClientSpecAndSpInfo(): boolean {

        if (this.statusId === CLIENT_SALE_STATUS_ENUM.SaleLost) {
            return false;
        }

        const currentStepCompletion = this.getCurrentStepCompletion();

        switch (this.lot?.lotContractTypeId) {
            case LOT_CONTRACT_TYPE_ENUM.HouseAndLand:
            case LOT_CONTRACT_TYPE_ENUM.DesignAndLand:
                return currentStepCompletion?.saleDetails
                    && currentStepCompletion?.lotDetails
                    && currentStepCompletion?.clientAccount
                    && currentStepCompletion?.contractPricing;
            case LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand:
            case LOT_CONTRACT_TYPE_ENUM.DesignAndBuild:
                return currentStepCompletion?.saleDetails
                    && currentStepCompletion?.lotDetails
                    && currentStepCompletion?.clientAccount
                    && currentStepCompletion?.design
                    && currentStepCompletion?.contractPricing;
            default:
                return false;
        }
    }

    @Computed() public get canSetUnconditional(): boolean {
        if (!this.documentInfo?.outstandingDocumentsHaveBeenReviewedOrUploaded) {
            return false;
        }
        switch (this.lot?.lotContractTypeId) {
            case LOT_CONTRACT_TYPE_ENUM.HouseAndLand:
            case LOT_CONTRACT_TYPE_ENUM.DesignAndLand:
                return this.documentInfo?.spAgreementUploadDate != null
                    && this.documentInfo?.spInformationReviewedDate != null
                    && this.statusId !== CLIENT_SALE_STATUS_ENUM.UnConditional
                    && this.statusId !== CLIENT_SALE_STATUS_ENUM.Complete
                    && this.statusId !== CLIENT_SALE_STATUS_ENUM.SaleLost;
            case LOT_CONTRACT_TYPE_ENUM.DesignAndBuild:
                return !isNullOrWhiteSpace(this.documentInfo?.buildContractReviewedDate as string)
                    && this.statusId !== CLIENT_SALE_STATUS_ENUM.UnConditional
                    && this.statusId !== CLIENT_SALE_STATUS_ENUM.Complete
                    && this.statusId !== CLIENT_SALE_STATUS_ENUM.SaleLost;
            case LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand:
                return this.documentInfo.spInformationReviewedDate != null
                    && this.statusId !== CLIENT_SALE_STATUS_ENUM.UnConditional
                    && this.statusId !== CLIENT_SALE_STATUS_ENUM.Complete
                    && this.statusId !== CLIENT_SALE_STATUS_ENUM.SaleLost;
            default:
                return false;
        }
    }

    @Computed() public get canEditClassicCare(): boolean {
        return this.statusId === CLIENT_SALE_STATUS_ENUM.ContractPending
            || this.statusId === CLIENT_SALE_STATUS_ENUM.AwaitingAgreement
            || this.statusId === CLIENT_SALE_STATUS_ENUM.Conditional
            || this.statusId === CLIENT_SALE_STATUS_ENUM.UnConditional
            || this.statusId === CLIENT_SALE_STATUS_ENUM.StalledSale;
    }

    @Computed() public get canChangeConcept(): boolean {
        return this.lot && this.lot.numberOfConcepts > 1 && this.statusId === CLIENT_SALE_STATUS_ENUM.ContractPending && this.lot.canApplyConcept;
    }

    @Computed() public get canRequestScheme(): boolean {
        return true;
    }

    @Computed() public get canDownloadBuildContract(): boolean {
        return this.lot &&
            this.lot.appliedConceptId > 0 &&
                (this.statusId === CLIENT_SALE_STATUS_ENUM.ContractPending ||
                this.statusId === CLIENT_SALE_STATUS_ENUM.AwaitingAgreement ||
                this.statusId === CLIENT_SALE_STATUS_ENUM.Conditional) &&
            !isNullOrWhiteSpace(this.contractMangerId) &&
            this.isLeadClientDetailsComplete;
    }

    @Computed() public get canUploadBuildContract(): boolean {
        return this.statusId !== CLIENT_SALE_STATUS_ENUM.UnConditional
            && this.statusId !== CLIENT_SALE_STATUS_ENUM.Complete
            && this.statusId !== CLIENT_SALE_STATUS_ENUM.SaleLost;
    }

    public updateDetails(details: IClientSaleUpdateDto): Observable<IClientSaleDto> {
        if (!this.canEditSaleDetails) { return; }
        return this.$logicService
            .updateDetails(this.id, details)
            .pipe(
                tap(this.$updateThisAndOriginal.bind(this))
            );
    }

    public updateLotDetails(lotDetails: IClientSaleLotUpdateDto): Observable<IClientSaleDto> {
        if (!this.canEditLotDetails) { return; }
        return this.$logicService
            .updateLotDetails(this.id, lotDetails)
            .pipe(
                tap(this.$updateThisAndOriginal.bind(this))
            );
    }

    public updateClassicCareDetails(classicCareDetails: IClientSaleClassicCareUpdateDto): Observable<IClientSaleDto> {
        if (!this.canEditClassicCare) { return; }
        return this.$logicService
            .updateClassicCareDetails(this.id, classicCareDetails)
            .pipe(
                tap(this.$updateThisAndOriginal.bind(this))
            );
    }

    public updateContractPricingDetails(contractPricingDetails: IClientSaleContractPricingUpdateDto): Observable<IClientSaleDto> {
        if (!this.canEditContractDetails) { return; }
        return this.$logicService
            .updateContractPricingDetails(this.id, contractPricingDetails)
            .pipe(
                tap(this.$updateThisAndOriginal.bind(this))
            );
    }

    public applyPaymentTemplate(paymentTemplateId: number): Observable<void> {
        return this.$logicService
            .applyPaymentTemplate(this.id, paymentTemplateId);
    }

    public updateClientAccountDetails(clientAccountDetails: IClientSaleClientAccountUpdateDto): Observable<IClientSaleDto> {
        if (!this.canEditClientAccount) { return; }
        return this.$logicService
            .updateClientAccountDetails(this.id, clientAccountDetails)
            .pipe(
                tap(this.$updateThisAndOriginal.bind(this))
            );
    }

    public changeContractType(contractType: LotContractTypeEnumId): Observable<IClientSaleDto> {
        if (!this.canChangeContractType) { return; }
        return this.$logicService
            .changeContractType(this.id, contractType)
            .pipe(
                tap(this.$updateThisAndOriginal.bind(this))
            );
    }

    public changeDesignConcept(designConceptId: number): Observable<IClientSaleDto> {
        if (!this.canChangeConcept) { return; }
        return this.$logicService
            .changeDesignConcept(this.id, designConceptId)
            .pipe(
                tap(this.$updateThisAndOriginal.bind(this))
            );
    }

    public setSalePendingLost(dto: IClientSaleLostDto): Observable<IClientSaleDto> {
        if (!this.canSetSaleLost) { return; }
        return this.$logicService
            .setSalePendingLost(this.id, dto)
            .pipe(
                tap(this.$updateThisAndOriginal.bind(this))
            );
    }

    public confirmSaleLost(dto: IClientSaleLostDto): Observable<IClientSaleDto> {
        if (!this.canSetSaleLost) { return; }
        return this.$logicService
            .confirmSaleLost(this.id, dto)
            .pipe(
                tap(this.$updateThisAndOriginal.bind(this))
            );
    }

    public revertPendingLost(): Observable<IClientSaleDto> {
        if (!this.canRevertPendingLost) { return; }
        return this.$logicService
            .revertPendingLost(this.id)
            .pipe(
                tap(this.$updateThisAndOriginal.bind(this))
            );
    }

    public revertUnconditional(): Observable<IClientSaleDto> {
        if (!this.canRevertUnconditional) { return; }
        return this.$logicService
            .revertunconditional(this.id)
            .pipe(
                tap(this.$updateThisAndOriginal.bind(this))
            );
    }

    public setUnconditional(date: Date): Observable<IClientSaleDto> {
        if (!this.canSetUnconditional) { return; }
        return this.$logicService
            .setUnconditional(this.id, date)
            .pipe(
                tap(this.$updateThisAndOriginal.bind(this))
            );
    }

    public downloadCreateSchedule(hasScheduleSpecTemplate: boolean): Observable<any> {
        if (!this.canDownloadClientSpecAndSpInfo) { return; }

        if (hasScheduleSpecTemplate) {
            return this.$logicService.downloadSimCreateSchedule(this.id)
                .pipe(
                    tap({
                        next: () => {
                            this.documentInfo.clientSpecificationDownloadDate = DateUtil.formatDateToDateString(new Date());
                            this.$updateThisAndOriginal(this);
                        }
                    })
                );
        }
        else {
            return this.$logicService.downloadCreateSchedule(this.id)
                .pipe(
                    tap({
                        next: () => {
                            this.documentInfo.clientSpecificationDownloadDate = DateUtil.formatDateToDateString(new Date());
                            this.$updateThisAndOriginal(this);
                        }
                    })
                );
        }

    }

    public downloadSalePurchaseInformation(): Observable<any> {
        if (!this.canDownloadClientSpecAndSpInfo) { return; }
        return this.$logicService.downloadSalePurchaseInformation(this.id)
            .pipe(
                tap({
                    next: () => {
                        this.documentInfo.spInformationDownloadDate = DateUtil.formatDateToDateString(new Date());
                        this.$updateThisAndOriginal(this);
                    }
                })
            );
    }

    public downloadBuildContract(): Observable<any> {
        if (!this.canDownloadBuildContract) { return; }
        return this.$logicService
            .downloadBuildContract(this.id)
            .pipe(
                tap({
                    next: () => {
                        this.documentInfo.buildContractDownloadDate = DateUtil.formatDateToDateString(new Date());
                        this.$updateThisAndOriginal(this);
                    }
                })
            );
    }
}
