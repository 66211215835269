import { Directive, Input } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ConsentType } from '@app/logic/lot-consents';
import { getBaseTasksLocation } from '@app/shared/utils/task-path-util';
import { SYSTEM_AREA_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

@Directive()
export class DashboardBaseWidgetDirective<WidgetDataType> {
    @Input() public widget: IDashboardWidget;
    @Input() public label: string;

    public data: WidgetDataType;
    public isLoaded = false;

    public static readonly RETURN_MATCHED_CURLYS_AND_CONTENT_REGEX = /\{\{[^}]+\}\}/gm;
    public static readonly CURLY_BRACKETS_REGEX = /\{\{|\}\}/g;

    constructor(
        protected readonly navigationService: NavigationService,
        protected readonly $http: HttpWrapperService,
    ) {
    }

    public ngAfterViewInit(): void {
        const uri = DASHBOARD_WIDGET_URI[this.widget.type];
        this.$http.get(`/dashboard/${uri}`, this.widget.configuration).subOnce((result: WidgetDataType) => {
            this.setData(result);
            this.isLoaded = true;
        });
    }

    public setData(newData: WidgetDataType): void {
        this.data = newData;
    }

    public goToItem(params: IUrlAndParam, event: MouseEvent): void {
        this.navigationService.navigate([params.url], event, { queryParams: params.queryParams });
    }

    public viewItemUrl(item: IItemRequiringActionDto): IUrlAndParam {
        if (this.isLoaded) {
            let url = DASHBOARD_WIDGET_LOCATION[this.widget.type](item);
            const matchedStrs = url.match(DashboardBaseWidgetDirective.RETURN_MATCHED_CURLYS_AND_CONTENT_REGEX);
            matchedStrs?.forEach(match => {
                const prop = match?.replace(DashboardBaseWidgetDirective.CURLY_BRACKETS_REGEX, '');
                if (item[prop]?.toString()) {
                    url = url.replace(match, item[prop].toString());
                }
            });

            let queryParams: { [key: string]: string | number } = {};
            if (item.secondaryEntityType !== undefined) {
                switch (item.secondaryEntityType) {
                    case 'GenericQuestions':
                        queryParams = { paramEntityId: item.secondaryEntityId, qnsId: item.id };
                        break;
                    case 'DesignConcepts':
                        queryParams = { conceptId: item.secondaryEntityId };
                        break;
                    case ConsentType.BuildingConsent:
                        queryParams = { lotConsentId: item.secondaryEntityId, consentType: ConsentType.BuildingConsent };
                        break;
                    case ConsentType.ResourceConsent:
                        queryParams = { lotConsentId: item.secondaryEntityId, consentType: ConsentType.ResourceConsent };
                        break;
                    default:
                        queryParams = { paramEntityId: item.id };
                }
            }
            return { url, queryParams };
        }
    }
}

export interface IDashboardWidget {
    type: DASHBOARD_WIDGET;
    configuration: { [key: string]: string };
    order: number;
}

export interface IItemRequiringActionDto {
    id: number;
    label: string;
    entityId: number;
    secondaryLabel: string;
    furtherDescription: string;
    description: string;
    dueDate: string;
    primaryEnumId: number;
    primaryEnumLabel: string;
    secondaryEntityId: number;
    secondaryEntityType: string;
    orderBy?: string;
}

export interface IUrlAndParam {
    url: string;
    queryParams?: { [key: string]: string | number };
}

export enum DASHBOARD_WIDGET {
    DocumentsRequiringAction = 1,
    DesignSchemesRequiringAction = 2,
    PricingRevisionsRequiringAction = 3,
    WorkingDrawingsRequiringAction = 4,
    ChangeRecordsRequiringAction = 5,
    CouncilRfisRequiringAction = 6,
    BusinessAccountsRequiringAction = 7,
    QuestionsRequiringAction = 8,
    TakeOffTasksRequiringAction = 9,
    PreconsentDocumentsRequiringAction = 10,
    BaseTasksRequiringAction = 11,
    ManualPurchaseOrdersRequiringApproval = 12,
    OutstandingLotVariations = 13,
    RequiredQuotesAndPCSums = 14,
    LotDesignConceptsRequiringAction = 15,
    LotConsentsRequiringAction = 16,
    PreconsentsRequiringAction = 17,
}

export const DASHBOARD_WIDGET_URI = {
    [DASHBOARD_WIDGET.DocumentsRequiringAction]: 'documentsrequiringaction',
    [DASHBOARD_WIDGET.DesignSchemesRequiringAction]: 'designschemesrequiringaction',
    [DASHBOARD_WIDGET.PricingRevisionsRequiringAction]: 'pricingrevisionsrequiringaction',
    [DASHBOARD_WIDGET.WorkingDrawingsRequiringAction]: 'workingdrawingsrequiringaction',
    [DASHBOARD_WIDGET.ChangeRecordsRequiringAction]: 'changerecordsrequiringaction',
    [DASHBOARD_WIDGET.CouncilRfisRequiringAction]: 'councilrfisrequiringaction',
    [DASHBOARD_WIDGET.BusinessAccountsRequiringAction]: 'businessaccountsrequiringaction',
    [DASHBOARD_WIDGET.PreconsentDocumentsRequiringAction]: 'preconsentdocumentsrequiringaction',
    [DASHBOARD_WIDGET.QuestionsRequiringAction]: 'questionsrequiringaction',
    [DASHBOARD_WIDGET.TakeOffTasksRequiringAction]: 'takeofftasksrequiringaction',
    [DASHBOARD_WIDGET.BaseTasksRequiringAction]: 'basetasksrequiringaction',
    [DASHBOARD_WIDGET.ManualPurchaseOrdersRequiringApproval]: 'manualpurchaseordersrequiringapproval',
    [DASHBOARD_WIDGET.OutstandingLotVariations]: 'outstandinglotvariations',
    [DASHBOARD_WIDGET.RequiredQuotesAndPCSums]: 'requiredquotesandpcsums',
    [DASHBOARD_WIDGET.LotDesignConceptsRequiringAction]: 'lotdesignconceptsrequiringaction',
    [DASHBOARD_WIDGET.LotConsentsRequiringAction]: 'lotconsentsrequiringaction',
    [DASHBOARD_WIDGET.PreconsentsRequiringAction]: 'preconsentsrequiringaction',
};

export const DASHBOARD_WIDGET_LOCATION = {
    [DASHBOARD_WIDGET.DesignSchemesRequiringAction]: (item: IItemRequiringActionDto) => '/lots/{{entityId}}/design/schemes',
    [DASHBOARD_WIDGET.PricingRevisionsRequiringAction]: (item: IItemRequiringActionDto) => '/lots/{{entityId}}/costs/price-revisions',
    [DASHBOARD_WIDGET.WorkingDrawingsRequiringAction]: (item: IItemRequiringActionDto) => '/lots/{{entityId}}/design/working-drawings',
    [DASHBOARD_WIDGET.ChangeRecordsRequiringAction]: (item: IItemRequiringActionDto) => '/lots/{{entityId}}/changes/changes',
    [DASHBOARD_WIDGET.CouncilRfisRequiringAction]: (item: IItemRequiringActionDto) => '/lots/{{entityId}}/design/council-rfis',
    [DASHBOARD_WIDGET.BusinessAccountsRequiringAction]: (item: IItemRequiringActionDto) => '/business-accounts/edit/{{entityId}}/details',
    [DASHBOARD_WIDGET.PreconsentDocumentsRequiringAction]: (item: IItemRequiringActionDto) => '/lots/{{entityId}}/design/pre-consent',
    [DASHBOARD_WIDGET.QuestionsRequiringAction]: (item: IItemRequiringActionDto) => {
        switch (item.primaryEnumId) {
            case SYSTEM_AREA_ENUM.DesignScheme:
                return '/lots/{{entityId}}/design/schemes';
            case SYSTEM_AREA_ENUM.PricingRevision:
                return '/lots/{{entityId}}/costs/price-revisions';
            case SYSTEM_AREA_ENUM.WorkingDrawing:
                return '/lots/{{entityId}}/design/working-drawings';
            case SYSTEM_AREA_ENUM.CouncilRfi:
                return '/lots/{{entityId}}/design/council-rfis';
            case SYSTEM_AREA_ENUM.ChangeRecord:
                return '/lots/{{entityId}}/changes/changes';
            case SYSTEM_AREA_ENUM.PreConsentPlan:
                return '/lots/{{entityId}}/design/pre-consent';
            default:
                return '/lots/{{entityId}}/summary';
        }
    },
    [DASHBOARD_WIDGET.TakeOffTasksRequiringAction]: (item: IItemRequiringActionDto) => '/lots/{{entityId}}/costs/takeoffs',
    [DASHBOARD_WIDGET.BaseTasksRequiringAction]: (item: IItemRequiringActionDto) => getBaseTasksLocation(item.primaryEnumId, '{{entityId}}', '{{secondaryEntityId}}'),
    [DASHBOARD_WIDGET.ManualPurchaseOrdersRequiringApproval]: (item: IItemRequiringActionDto) => '/lots/{{entityId}}/build/orders',
    [DASHBOARD_WIDGET.OutstandingLotVariations]: (item: IItemRequiringActionDto) => '/lots/{{entityId}}/changes/variations',
    [DASHBOARD_WIDGET.RequiredQuotesAndPCSums]: (item: IItemRequiringActionDto) => '/lots/{{entityId}}/spec/quotes',
    [DASHBOARD_WIDGET.LotDesignConceptsRequiringAction]: (item: IItemRequiringActionDto) => '/lots/{{entityId}}/design/concepts',
    [DASHBOARD_WIDGET.LotConsentsRequiringAction]: (item: IItemRequiringActionDto) => '/lots/{{entityId}}/design/consent',
    [DASHBOARD_WIDGET.PreconsentsRequiringAction]: (item: IItemRequiringActionDto) => '/lots/{{entityId}}/design/pre-consent'
};
