import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormArray } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { debounceTime, distinctUntilChanged, Subscription, switchMap } from 'rxjs';
import { environment } from '@src/environments/environment';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { ISelectionCentreProduct } from '@app/views/products/tabs/selection-centre/ISelectionCentreProduct';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { RelatedSlotsComponent } from '@app/views/products/tabs/selection-centre/related-slots/related-slots.component';



@Component({
    selector: 'cb-selection-centre',
    templateUrl: './selection-centre.component.html',
    styleUrls: ['./selection-centre.component.scss']
})
export class SelectionCentreComponent implements OnInit, OnDestroy {
    @Input() public readonly isSlotSelection: boolean = false;
    @Input() public readonly lotId: number;
    @Input() public readonly slotId: number;
    @Input() public readonly slotName: string;
    @Input() public readonly houseArea: string;
    @Input() public readonly reg: string;
    @Input() public readonly cat: string;

    @Output() public relatedSlotsChanged = new EventEmitter<any[]>();
    @Output() public swappedItemChanged = new EventEmitter<ISelectionCentreProduct>();
    @Output() public selectedItemChanged = new EventEmitter<ISelectionCentreProduct>();

    // Component Properties
    products: ISelectionCentreProduct[] = [];
    filteredProducts: ISelectionCentreProduct[] = [];
    displayedProducts: ISelectionCentreProduct[] = [];
    popularProducts: ISelectionCentreProduct[] = [];
    selectedProduct: ISelectionCentreProduct;

    relatedSlots: any[];

    loading = false;
    loadingMenu = false;
    menuData: any;
    selectedItem: any = null;
    collapsedItems: { [id: string]: boolean } = {};

    priceRanges: string[] = [];
    selectedPriceRanges: string[] = [];

    collapsedFilters: { [id: string]: boolean } = {};


    private searchSubscription: Subscription;

    // Filters
    filters = {
        search: new FormControl(''),
        houseArea: new FormArray([]),
        slot: new FormArray([]),
        dynamicCategories: {}
    };

    dynamicFilters: { [key: string]: Set<any> } = {};

    swappedItem: ISelectionCentreProduct;

    searchInitialized = false;

    constructor(protected readonly $http: HttpWrapperService, public readonly cbDialog: CbDialogService,) {}

    getChipClass(tier: string): string {
        switch (tier.toLowerCase()) {
            case 'foundational': return 'chip-bronze';
            case 'traditional': return 'chip-silver';
            case 'premium': return 'chip-platinum';
            default: return 'chip-default';
        }
    }
    // Lifecycle Hooks
    ngOnInit() {
        if (this.isSlotSelection) {
            this.loadProductsForSlot();
        } else {
            this.initializeSearch();
        }
        this.fetchMenuData();
    }

    ngOnDestroy() {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }
    }


    loadProductsForSlot() {
        if (!this.lotId || !this.slotId) {
            console.error('lotId or slotId is missing');
            return;
        }

        this.loading = true;
        this.searchInitialized = true;


        if(this.isSlotSelection){
            const urlSlot =  `hack/lot/slot?lotId=${this.lotId}&slotId=${this.slotId}`;
            this.$http.get<any>(urlSlot).subscribe(
                (response) => {
                    this.popularProducts = response.resultObject.mostCommonProducts || [];
                    this.selectedProduct = response.resultObject;
                    this.relatedSlots = response.resultObject.relatedSlots || [];
                    this.relatedSlotsChanged.emit(this.relatedSlots);
                    this.selectedItemChanged.emit(this.selectedProduct);
                },
                (error) => {
                    console.error('Error loading popular products for slot:', error);
                    this.loading = false;
                }
            );
        }


        const url = `hack/search?reg=${this.reg}&cat=${this.cat}&reg=National`;
        this.$http.get<any>(url).subscribe(
            (response) => {
                this.products = response.items || [];
                this.filteredProducts = this.products;
                this.rePopulateDynamicFilters();

                this.loading = false;
            },
            (error) => {
                console.error('Error loading products for slot:', error);
                this.loading = false;
            }
        );
    }

    // Search Functionality
    initializeSearch() {
        const customOrder = ['Traditional', 'Premium', 'Foundational'];
        this.searchSubscription = this.filters.search.valueChanges
            .pipe(
                debounceTime(800), // Adjusted debounce time for better UX
                distinctUntilChanged(),
                switchMap((query: string) => {
                    this.searchInitialized = true;
                    this.loading = true;
                    this.selectedItem = null;
                    return this.$http.get<any>(`hack/search?query=${query}`);
                })
            )
            .subscribe(
                (products) => {
                    this.resetFilters();
                    this.products = products.items.sort((a, b) => {
                        const tierA = a.tier || ''; // Default to an empty string for undefined
                        const tierB = b.tier || ''; // Default to an empty string for undefined

                        const indexA = customOrder.indexOf(tierA); // Get the index in customOrder
                        const indexB = customOrder.indexOf(tierB); // Get the index in customOrder

                        // If either index is -1 (not in customOrder), treat it as lowest priority
                        return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
                    });
                    this.filteredProducts = this.products;
                    this.rePopulateDynamicFilters();
                    this.loading = false;
                },
                (error) => {
                    console.error('Error fetching products:', error);
                    this.loading = false;
                }
            );
    }


    swapProduct(product: ISelectionCentreProduct){


        if(this.relatedSlots){
            // Open new dialog to apply to others

            this.cbDialog.open(RelatedSlotsComponent, {
                data: {
                    relatedSlots: this.relatedSlots,
                },
                minWidth: '95%',
            });
        }

        else{
            // Swap the product only
        }
    }

    resetFilters() {
        // Reset text search
        // this.filters.search.setValue('');

        // Reset house area and slot filters
        (this.filters.houseArea as FormArray).clear();
        (this.filters.slot as FormArray).clear();

        // Reset price range
        this.selectedPriceRanges = [];

        // Reset dynamic categories
        this.filters.dynamicCategories = {};

    }

    rePopulateDynamicFilters(){
        this.dynamicFilters = {};

        this.filteredProducts = this.filteredProducts.map((product) => {
            // product.dynamicFilters = this.parseDynamicCategories(product.dynamicFilters);
            this.populateDynamicFilters(product);
            return product;
        });

        this.displayedProducts = this.filteredProducts.slice(0, 50);

        this.calculateBadgeCounts(this.filteredProducts);
        this.calculatePriceRanges();
    }

    // Menu Data
    fetchMenuData() {
        this.$http.get<any[]>('hack/all_categories').subscribe(
            (data) => {
                // eslint-disable-next-line
                this.menuData = data['categories'].filter(cat => !cat.label.toLowerCase().startsWith("labour"));
                this.loadingMenu = false;
                this.initializeCollapsedState(this.menuData, 0);
            },
            (error) => {
                console.error('Error fetching menu data:', error);
                this.loadingMenu = false;
            }
        );
    }

    initializeCollapsedState(items: any[], levelCount: number) {
        items.forEach((item) => {
            this.collapsedItems[item.label + item.id] = levelCount > 0;
            if (item.children) {
                this.initializeCollapsedState(item.children, levelCount + 1);
            }
        });
    }

    calculatePriceRanges() {
        if (this.filteredProducts.length <= 10) {return;}

        // Calculate min and max prices
        const minPrice = Math.max(0, Math.floor(Math.min(...this.filteredProducts.map(p => p.nationalRate))));
        const maxPrice = Math.ceil(Math.max(...this.filteredProducts.map(p => p.nationalRate)));

        // No ranges needed if min and max are the same
        if (minPrice === maxPrice) {
            this.priceRanges = [];
            return;
        }

        const rangeCount = 4; // Fixed number of ranges
        const rawInterval = (maxPrice - minPrice) / rangeCount;

        // Round the interval to a "nice" number (e.g., 10, 100, 1000)
        const interval = Math.pow(10, Math.floor(Math.log10(rawInterval)));
        const roundedInterval = rawInterval > interval * 2 ? interval * 2 : interval;

        // Generate price ranges
        this.priceRanges = [];
        let start = Math.floor(minPrice / roundedInterval) * roundedInterval; // Round down to the nearest interval
        for (let i = 0; i < rangeCount; i++) {
            const end = start + roundedInterval - 1;

            // Avoid adding ranges where start and end are the same
            if (start >= maxPrice) {break;}

            this.priceRanges.push(`${start}-${Math.min(end, maxPrice)}`);
            start += roundedInterval;
        }
    }



    togglePriceRange(range: string, isChecked: boolean) {
        if (isChecked) {
            this.selectedPriceRanges.push(range);
        } else {
            this.selectedPriceRanges = this.selectedPriceRanges.filter(r => r !== range);
        }
        this.applyFilters(false);
    }


    // Filters
    applyFilters(clearCollapsedState: boolean) {
        this.searchInitialized = true;
        this.loading = true;

        // this.clearBadgeCount(this.menuData);
        setTimeout(() => {
            // clear the collapsed state memory
            if (clearCollapsedState) {
                this.collapsedFilters = {};
            }
            this.filteredProducts = this.products.filter((product) => {
                return (
                    this.filterByCategory(product) &&
                    this.filterBySearch(product) &&
                    this.filterByHouseArea(product) &&
                    this.filterBySlot(product) &&
                    this.filterByPrice(product) &&
                    this.filterByDynamicCategories(product)
                );
            });

            this.rePopulateDynamicFilters();

            // Update displayed products for pagination
            this.displayedProducts = this.filteredProducts.slice(0, 50);
            this.loading = false;

            this.calculateBadgeCounts(this.filteredProducts);
        }, 500);
    }

    private calculateBadgeCounts(filteredProducts: ISelectionCentreProduct[]): void {
        // clear badge counts
        this.menuData?.forEach((item) => {
            item.badgeCount = 0;
            this.clearBadgeCount(item.children);
        });

        filteredProducts.forEach((product) => {

            const productCategories = product.categoryTree;
            for (const productCategory of productCategories) {
                this.incrementBadgeCount(productCategory, this.menuData);
            }
        });
    }

    clearBadgeCount(currentMenu: any[]){
        for(const subMenu of currentMenu){
            if (subMenu === undefined){
                continue;
            }
            subMenu.badgeCount = 0;
            if (Array.isArray(subMenu.children)){
                this.clearBadgeCount(subMenu.children);
            }
        }
    }

    incrementBadgeCount(productCategory: number, currentMenu: any[]) {

        if(currentMenu?.length  > 0){

            for(const subMenu of currentMenu){
                if (subMenu === undefined){
                    continue;
                }
                if (subMenu.badgeCount === undefined) {
                    subMenu.badgeCount = 0;
                }
                if (subMenu.id === productCategory){
                    subMenu.badgeCount++;
                }
                if ( Array.isArray(subMenu.children)){
                    this.incrementBadgeCount(productCategory, subMenu.children);
                }
            }

        }

    }

    filterByCategory(product: ISelectionCentreProduct): boolean {
        const searchValue = this.selectedItem?.id;
        return (
            !searchValue ||
            product.name.toLowerCase().includes(searchValue) ||
            product.categoryTree.includes(searchValue)
        );
    }

    filterBySearch(product: ISelectionCentreProduct): boolean {
        const searchValue = this.filters.search.value?.toLowerCase() || '';
        return (
            !searchValue ||
            product.name.toLowerCase().includes(searchValue) ||
            product.mainCategory.toLowerCase().includes(searchValue)
        );
    }

    filterByHouseArea(product: ISelectionCentreProduct): boolean {
        const houseAreaValues = (this.filters.houseArea as FormArray).value;
        return !houseAreaValues.length || houseAreaValues.includes(product.dynamicFilters?.houseArea);
    }

    filterBySlot(product: ISelectionCentreProduct): boolean {
        const slotValues = (this.filters.slot as FormArray).value;
        return !slotValues.length || slotValues.includes(product.dynamicFilters?.slot);
    }

    filterByPrice(product: ISelectionCentreProduct): boolean {
        if (this.selectedPriceRanges.length === 0) {return true;}

        return this.selectedPriceRanges.some(range => {
            const [min, max] = range.split('-').map(Number);
            return product.nationalRate >= min && product.nationalRate <= max;
        });
    }

    filterByDynamicCategories(product: ISelectionCentreProduct): boolean {
        const dynamicCategoryFilters = this.filters.dynamicCategories || {};

        return Object.keys(dynamicCategoryFilters).every((key) => {
            const selectedValues = dynamicCategoryFilters[key];
            const productValue =
                (key.toUpperCase() === 'REGIONS') ? product.regions
                    : (key.toUpperCase() === 'TIER') ? product.tier
                        : product.dynamicFilters[key];

            if (!selectedValues || selectedValues.length === 0) {return true;}

            if (Array.isArray(productValue)) {
                return productValue.some((value) => selectedValues.includes(value));
            }

            return selectedValues.includes(productValue);
        });
    }

    matchDynamicCategories(product: any, filters: any): boolean {
        return Object.keys(filters).every((key) => {
            const selectedValues = filters[key];
            const productValue = product.dynamicFilters[key];

            if (!selectedValues.length) {return true;}

            if (Array.isArray(productValue)) {
                return productValue.some((value) => selectedValues.includes(value));
            }

            if (key === 'Size' && typeof productValue === 'object') {
                const sizeString = `Height: ${productValue.Height || 'N/A'}, Length: ${productValue.Length || 'N/A'}`;
                return selectedValues.includes(sizeString);
            }

            return selectedValues.includes(productValue);
        });
    }

    updateFilter(filterKey: string, value: any, isChecked: boolean) {
        const formArray = this.filters[filterKey] as FormArray;
        if (isChecked) {
            formArray.push(new FormControl(value));
        } else {
            const index = formArray.controls.findIndex((control) => control.value === value);
            if (index > -1) {
                formArray.removeAt(index);
            }
        }
        this.applyFilters(false);
    }

    updateDynamicFilter(key: string, value: any, isChecked: boolean) {
        if (!this.filters.dynamicCategories[key]) {
            this.filters.dynamicCategories[key] = [];
        }

        if (isChecked) {
            this.filters.dynamicCategories[key].push(value);
        } else {
            const index = this.filters.dynamicCategories[key].indexOf(value);
            if (index > -1) {
                this.filters.dynamicCategories[key].splice(index, 1);
            }
        }

        this.applyFilters(false);
    }

    isSelected(filterType: string, option: any): boolean {
        if (filterType === 'dynamicCategories') {
            return this.filters.dynamicCategories[option.key]?.includes(option.value);
        } else {
            const formArray = this.filters[filterType] as FormArray;
            return formArray.value.includes(option);
        }
    }

    // Menu Interactions
    selectItem(item: any, event: Event) {
        event.stopPropagation();
        if (this.selectedItem === item){
            // we're deselecting a category
            this.selectedItem = null;
        } else {
            this.selectedItem = item;
        }

        this.applyFilters(false);

    }

    isSelectedMenu(item: any): boolean {
        return this.selectedItem === item || this.isAncestorSelected(item);
    }

    isAncestorSelected(item: any): boolean {
        if (!item.children) {
            return false;
        }
        return item.children.some((child: any) => this.isSelectedMenu(child));
    }

    toggleItem(item: any, event: Event) {
        event.stopPropagation();
        this.collapsedItems[item.label + item.id] = !this.collapsedItems[item.label + item.id];
    }

    // Pagination
    paginate(event: PageEvent) {
        const startIndex = event.pageIndex * event.pageSize;
        const endIndex = startIndex + event.pageSize;
        this.displayedProducts = this.filteredProducts.slice(startIndex, endIndex);
    }

    // Product Utilities
    previousImage(product: any) {
        if (!product.images || product.images.length === 0) {return;}
        product.currentImageIndex =
            product.currentImageIndex > 0
                ? product.currentImageIndex - 1
                : product.images.length - 1;
    }

    nextImage(product: any) {
        if (!product.images || product.images.length === 0) {return;}
        product.currentImageIndex =
            product.currentImageIndex < product.images.length - 1
                ? product.currentImageIndex + 1
                : 0;
    }

    getProductImage(product: any): string {
        if (!product.hasImage) {
            return 'https://productdistributionstrategy.com/wp-content/uploads/2019/09/retail-distribution-strategy.svg';
        }
        return `${environment.api}/${product.imageUrl}`;
    }

    getDynamicFilterKeys(product: any): string[] {
        return Object.keys(product.dynamicFilters || {});
    }

    // Miscellaneous
    reindex() {
        this.loading = true;
        // this.http.get(`${environment.api}/api/hack/reindex`).subscribe(
        this.$http.get('hack/reindex').subscribe(
            () => {
                this.loading = false;
                alert('Reindexing completed successfully.');
            },
            (error) => {
                this.loading = false;
                console.error('Error during reindexing:', error);
                alert('Error during reindexing. Please check the console for details.');
            }
        );
    }

    getDynamicFilterOptions() {
        const filterOrder: string[] = ['tier', 'range', 'model', 'style', 'size', 'shape', 'material', 'price', 'region'];

        const filterOptions = Object.entries(this.dynamicFilters).map(([key, values]) => ({
            key,
            values: Array.from(values),
        }));


        return filterOptions.length > 0 ? filterOptions.sort((a, b) => {
            const indexA = filterOrder.indexOf(a.key);
            const indexB = filterOrder.indexOf(b.key);

            // Handle keys not in the sort order
            return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
        })
            : [];
    }

    trackById(index: number, product: any): number {
        return index;
    }

    protected readonly Object = Object;

    parseDynamicCategories(dynamicCategories: string): any {

        try {
            return JSON.parse(dynamicCategories?.replace(/'/g, '"'));
        } catch (error) {
            console.error('Error parsing dynamicCategories:', error);
            return {};
        }
    }

    populateDynamicFilters(product: any): void {
        const categories = product.dynamicFilters || {};

        // Add regions, businessAccounts, and tier to dynamic filters only if they have items
        const additionalFilters: { [key: string]: any[] } = {};

        if (product.regions && product.regions.length > 0) {
            additionalFilters.regions = product.regions;
        }
        if (product.businessAccounts && product.businessAccounts.length > 0) {
            additionalFilters.businessAccounts = product.businessAccounts;
        }
        if (product.tier) {
            additionalFilters.tier = [product.tier]; // Ensure tier is handled as an array
        }

        const combinedCategories = { ...categories, ...additionalFilters };

        Object.keys(combinedCategories).forEach((key) => {
            const receivedValue = combinedCategories[key];
            let value = [];

            if (Array.isArray(receivedValue)) {
                value = receivedValue.filter((item: any) => item !== undefined);
            } else {
                value = receivedValue;
            }

            const mustShow =
                (Array.isArray(value) && value.length > 0) ||
                (!Array.isArray(value) && value !== undefined);

            if (mustShow) {
                if (!this.dynamicFilters[key]) {
                    this.dynamicFilters[key] = new Set();
                }
                if (Array.isArray(value)) {
                    value.forEach((item) => this.dynamicFilters[key].add(item));
                } else {
                    this.dynamicFilters[key].add(value);
                }
            }
        });

        this.calculatePriceRanges();

        // Update collapsedFilters while preserving the current state
        const dynamicFilterOptions = this.getDynamicFilterOptions();

        const defaultDynamicPanelCollapsedState = true; // true is collapsed, false is open
        if (!this.collapsedFilters) {
            this.collapsedFilters = Object.fromEntries(dynamicFilterOptions.map(key => [key, defaultDynamicPanelCollapsedState]));
            this.collapsedFilters.price = defaultDynamicPanelCollapsedState;
        } else {
            // Add new entries while keeping the existing state intact
            dynamicFilterOptions.forEach((key, index) => {
                if (this.collapsedFilters[key.key] === undefined) {
                    this.collapsedFilters[key.key] = defaultDynamicPanelCollapsedState; // Default new entries to expanded
                }
            });
            if (this.collapsedFilters.price === undefined) {
                this.collapsedFilters.price = defaultDynamicPanelCollapsedState; // Default new entries to expanded
            }
        }

    }

    public toggleDynamicFilter(key: string): void {
        console.log(key,  this.collapsedFilters[key], this.collapsedFilters);
        this.collapsedFilters[key] = !this.collapsedFilters[key];
    }


    toggleSwappedSelection(product: any): void {
        this.swappedItem = product;
        this.swappedItemChanged.emit(this.swappedItem);
    }

    isSwappedSelected(product: any): boolean {
        return this.swappedItem == product;
    }
}
