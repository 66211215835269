<mat-expansion-panel class="mat-elevation-z0"
                     [expanded]="true">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon class="cb-grey cb-margin-right">announcement</mat-icon>
            <b>Alerts</b>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <cb-alert-card-new type="info"
                       *ngIf="showPrimaryLotInBlockAlert()"
                       message="Primary Lot in Block">
    </cb-alert-card-new>
    <cb-alert-card-new type="error"
                       *ngIf="showSaleLostAlert()"
                       message="Sale Lost - Lot Requires Cleanup">
    </cb-alert-card-new>
    <cb-alert-card-new type="warning"
                       *ngIf="showIsShowHomeAlert()"
                       message="Lot is a show home">
    </cb-alert-card-new>
    <cb-alert-card-new type="warning"
                       *ngIf="showNoConceptAlert()"
                       message="There is no Concept on this Lot">
    </cb-alert-card-new>
    <cb-alert-card-new type="warning"
                       *ngIf="hasIncompleteLotSpecItems"
                       message="There are incomplete items on the Lot Specification">
    </cb-alert-card-new>
    <cb-alert-card-new type="warning"
                       *ngIf="showSpecTemplateAlert()"
                       message="A Spec Template needs to be applied">
    </cb-alert-card-new>
    <cb-alert-card-new type="warning"
                       *ngIf="hasPendingResourceConsent"
                       message="Resource Consent is required for the current Design Concept">
    </cb-alert-card-new>
    <cb-alert-card-new type="warning"
                       *ngIf="showIncompleteSpecItemAlert()"
                       message="Lot Spec has incomplete lot spec item attributes">
    </cb-alert-card-new>
    <cb-alert-card-new type="warning"
                       *ngIf="showEstimatePricingAlert()"
                       message="Applied Concept has estimated Pricing Revision">
    </cb-alert-card-new>
    <cb-alert-card-new type="info"
                       *ngIf="showHasOwnersCareAlert()"
                       message="Owner's Care Item exists on Lot Spec">
    </cb-alert-card-new>
    <cb-alert-card-new type="info"
                       *ngIf="hasPendingConcept"
                       message="Pending Concept">
    </cb-alert-card-new>
    <cb-alert-card-new type="warning"
                       *ngIf="showWithheldFromWebsiteAlert()"
                       message="Lot withheld from website">
    </cb-alert-card-new>
    <cb-alert-card-new type="info"
                       *ngIf="displayNoAlertsMsg()"
                       message="There are no alerts to display">
    </cb-alert-card-new>
</mat-expansion-panel>
