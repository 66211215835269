import {Component, Input} from '@angular/core';

@Component({
    selector: 'cb-td-enum[value][enum]',
    templateUrl: './enum.component.html',
    styleUrls: ['./enum.component.scss', './../table-cell.scss']
})
export class TdEnumComponent {
    @Input() public readonly value: number;
    @Input() public readonly enum: any; // TODO(39775): SHOULD BE THE CORRECT TYPE BUT NEED TO FIX IN THE TRANSPILER
}
