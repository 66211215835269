<cb-dialog>
    <form class="flex-col flex"
          #payemntFrequencyForm="ngForm">
        <h1 class="mat-title">
            Edit Payment Frequency
        </h1>
        <cb-display-value-new label="Payment Frequency"
                              [value]="mappedItem.label"></cb-display-value-new>
        <mat-form-field>
            <textarea matInput
                      type="text"
                      name="activityScheduleDescription"
                      placeholder="Activity Schedule Description"
                      [maxlength]="1000"
                      [(ngModel)]="mappedItem.activityScheduleDescription">
            </textarea>
        </mat-form-field>
        <footer class="cb-margin-top-2x end-center flex-row">
            <button mat-raised-button
                    class="save-button"
                    color="primary"
                    (click)="save()"
                    [disabled]="!payemntFrequencyForm.dirty || !payemntFrequencyForm.valid">
                Save
            </button>
            <button mat-raised-button
                    (click)="cancel()">
                Cancel
            </button>
        </footer>
    </form>
</cb-dialog>
