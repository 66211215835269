import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IExternalSystemFieldsLogicService } from '@app/logic/external-system-fields/interfaces/i.external-system-fields.logic-service';
import { IExternalSystemFieldMappedItem } from '@app/logic/external-system-fields/interfaces/i.external-system-fields.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IExternalSystemFieldTemplateItemDto, EXTERNAL_SYSTEM_FIELD_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import {positiveIntegerPattern} from "@app/shared/utils/regex-patterns";


interface IData {
    mappedItem: IExternalSystemFieldMappedItem;
}

@Component({
    selector: 'app-external-system-field-dialog',
    templateUrl: './external-system-field-dialog.component.html',
    styleUrls: ['./external-system-field-dialog.component.scss']
})
export class ExternalSystemFieldDialogComponent
    extends BaseDialogFormViewDirective<IExternalSystemFieldTemplateItemDto, IExternalSystemFieldMappedItem, IExternalSystemFieldsLogicService> {

    public static readonly MIN_WIDTH = '40%';
    public fieldTypes = EXTERNAL_SYSTEM_FIELD_TYPE_ENUM.toLookup();

    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<ExternalSystemFieldDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
    }

    protected readonly positiveIntegerPattern = positiveIntegerPattern;
}
