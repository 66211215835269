import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {COST_TYPE_ENUM, ISlotDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {ISlotMappedItem} from '@app/logic/slots/interfaces/i.slot.mapped';
import {SlotsLogicService} from '@app/logic/slots/slots.logic-service';
import {Subject, Subscription} from 'rxjs';
import {ISlotSearchFilters} from '@app/core/services/user-cache/user-cache-areas';
import {UserCacheService} from '@app/core/services/user-cache/user-cache.service';
import {Dictionary, orderBy} from 'lodash';
import {SpecGroupsLogicService} from '@app/logic/spec-groups';
import {PermissionsPermissions} from '@app/core/permissions';
import {cloneDeepSafe} from '@app/shared/utils/clone-object.util';
import {FeatureToggleStatesService} from '@app/core/services/feature-toggle-states/feature-toggle-states.service';

@Component({
    selector: 'cb-schedule-items-table',
    templateUrl: './schedule-items-table.component.html',
    styleUrls: ['./schedule-items-table.component.scss']
})
export class ScheduleItemsTableComponent {
    public COST_TYPE_ENUM = COST_TYPE_ENUM;
    public selectedItems: Dictionary<any> = {};
    public expandedParents = {} as { [parentId: number]: boolean };

    public currentPage = 1;
    public queryUpdate = new Subject<void>();

    private parentSlotsBackup: ISlotDto[];
    public parentSlots = [] as ISlotDto[];
    public parentSlotsDictionary = {} as { [slotId: number]: ISlotDto };
    public childSlots = {} as { [parentSlotId: number]: ISlotDto[] };

    private cancelChanges$ = new Subscription();

    @Input() public set cancelChanges(x: Subject<void>) {
        this.cancelChanges$.unsubscribe();
        if (x) {
            this.cancelChanges$ = x.subscribe(() => {
                this.parentSlots = cloneDeepSafe(this.parentSlotsBackup);
            });
        }
    }

    @Output() public scheduleItemAdded = new EventEmitter<IData>();


    private _houseAreaIdWithSkinnyItems: any;

    @Input() public set houseAreaIdWithSkinnyItems(x: any) {
        this._houseAreaIdWithSkinnyItems = x;
        this.runQueryUpdate();
    }

    public get houseAreaIdWithSkinnyItems(): any {
        return this._houseAreaIdWithSkinnyItems;
    }


    constructor(
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialogService: CbDialogService,
        private readonly slotsLogicService: SlotsLogicService,
        public readonly userCacheService: UserCacheService,
        @Inject(SpecGroupsLogicService) public readonly specGroupsService: SpecGroupsLogicService,
        public readonly featureToggle: FeatureToggleStatesService,
    ) { }


    public readonly runQueryUpdate = (): void => {
        this.currentPage = 1;
        this.parentSlots = [];
        this.parentSlotsDictionary = {};
        this.childSlots = {};
        this.fetchResultsForHouseArea();
    };


    public fetchResultsForHouseArea(houseAreaId: number[] = [this.houseAreaIdWithSkinnyItems.houseAreaId]): void {
        const params = {
            specgroupId: houseAreaId,
            isActive: true,
            currentPage: this.currentPage,
            pageSize: 2000
        } as ISlotSearchFilters & { currentPage: number; pageSize?: number };

        this.slotsLogicService.$getSearchList(params).subOnce(searchResult => {
            this.mapSlotsToResults(searchResult.items);
            if (searchResult.total !== -1 && searchResult.total > 0) {
                this.currentPage += 1;
                this.fetchResultsForHouseArea();
            }
        });
    }

    public mapSlotsToResults(items: ISlotDto[]): void {
        if (!items?.length) {
            return;
        }
        for (const slot of items) {
            if (!slot.parentSlotId) {
                this.parentSlotsDictionary[slot.id] = slot;
                // if necessary, remove slot from its previous position in child array
                for (const key of Object.keys(this.childSlots)) {
                    const value = this.childSlots[key];
                    const index = value.findIndex(x => x.id === slot.id);
                    if (index < 0) {
                        continue;
                    }
                    value.splice(index, 1);
                    if (value.length < 1) {
                        delete this.childSlots[key];
                    }
                    break;
                }
            } else {
                const dict = (this.childSlots[slot.parentSlotId] ?? [])
                    .reduce((toReturn, curr) => {
                        toReturn[curr.id] = curr;
                        return toReturn;
                    }, {}) as { [slotId: number]: ISlotDto };
                dict[slot.id] = slot;
                this.childSlots[slot.parentSlotId] = orderBy(Object.values(dict).filter(x => x?.id > 0), (x) => x.reportOrder);
                if (!this.parentSlotsDictionary[slot.parentSlotId]) {
                    this.parentSlotsDictionary[slot.parentSlotId] = slot.parent;
                }
                // if necessary, remove slot from its previous position in parent dictionary
                if (this.parentSlotsDictionary[slot.id]) {
                    delete this.parentSlotsDictionary[slot.id];
                }
            }
        }
        this.parentSlots = orderBy(Object.values(this.parentSlotsDictionary).filter(x => x?.id > 0), (x) => x.reportOrder);
        this.parentSlotsBackup = cloneDeepSafe(this.parentSlots);
    }



    public addItemToSpecificationTemplate(houseAreaId: number, scheduleItem: ISlotDto, isParent: boolean): void {

        let specTemplateItem;

        if (isParent) {

            let childscheduleItems: any[] = [];

            if (isParent && this.childSlots[scheduleItem.id]?.length > 0) {
                childscheduleItems = this.childSlots[scheduleItem.id].filter(x => x.isRequired).map(childScheduleItem => ({
                    costType: childScheduleItem.costType,
                    isRequired: childScheduleItem.isRequired,
                    slotDescription: childScheduleItem.description,
                    slotId: childScheduleItem.id,
                    slotName: childScheduleItem.name,
                    tags: childScheduleItem.tags,
                    parentSlotId: scheduleItem.id,
                    quantity: 1,
                }));
            }

            specTemplateItem = {
                childItems: childscheduleItems,
                costType: scheduleItem.costType,
                isRequired: scheduleItem.isRequired,
                slotDescription: scheduleItem.description,
                slotId: scheduleItem.id,
                slotName: scheduleItem.name,
                tags: scheduleItem.tags,
                quantity: 1
            };

        }

        // Optional Child Added
        else {
            specTemplateItem = {
                costType: scheduleItem.costType,
                isRequired: scheduleItem.isRequired,
                slotDescription: scheduleItem.description,
                slotId: scheduleItem.id,
                slotName: scheduleItem.name,
                tags: scheduleItem.tags,
                parentSlotId: scheduleItem.parentSlotId,
                quantity: 1
            };

        }
        this.expandChildren(specTemplateItem.slotId);
        this.scheduleItemAdded.emit({ houseAreaId, specTemplateItem, isParent });
    }

    // check if Parent Slot Exist on the Specification Template

    public isAlreadyExistonSpecificationTemplate(slotId: number): boolean {
        let returnValue = false;
        returnValue = this.houseAreaIdWithSkinnyItems.skinnyTemplateItems.some((item) => item.slotId === slotId);
        return returnValue;
    }

    public shouldDisableParentItem(parentSlotId: number): boolean {
        let returnValue = true;
        returnValue = this.houseAreaIdWithSkinnyItems.skinnyTemplateItems.some((item) => item.slotId === parentSlotId);
        return returnValue;
    }

    public shouldDisableChildItem(parentSlotId: number, childSlotId: number): boolean {

        let returnValue = true;

        // Optional Child Add Should be disabled Until a parent is Added.
        const hasParentBeenAdded = this.houseAreaIdWithSkinnyItems.skinnyTemplateItems.some((item) => item.slotId === parentSlotId);

        if (!hasParentBeenAdded) {
            returnValue = true;
        }
        else {
            // If child been already been added
            const hasChildBeenAdded = this.houseAreaIdWithSkinnyItems.skinnyTemplateItems.some((item) => item.slotId === childSlotId);
            returnValue = hasChildBeenAdded;
        }

        return returnValue;
    }

    private expandChildren(scheduleItemId: number): void {
        this.expandedParents[scheduleItemId] = true;
    }

}


interface IData {
    houseAreaId: number;
    specTemplateItem: any;
    isParent: boolean;
}
