import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import {
    IBusinessAccountLogicService,
    IDocTypeBizAccountTypesLogicService,
    IDocTypeSupplyTypesLogicService,
    IDocTypeTradeTypesLogicService
} from './interfaces/i.business-account.logic.service';
import { BaseLogicService } from '../base/base-logic.service';
import {
    IDocTypeTradeTypeDto,
    IDocTypeBusinessAccountTypeDto,
    IBusinessAccountHoldDto,
    IDocTypeSupplyTypeDto
} from './interfaces/i.business-account.dto';
import {
    BusinessAccountContactTypeEnumId,
    BUSINESS_ACCOUNT_APPROVAL_OVERRIDE_REQUEST_OUTCOME_ENUM,
    BUSINESS_ACCOUNT_SEVEN_DAY_PAYMENT_REQUEST_OUTCOME_ENUM,
     IBusinessAccountDocumentDto, IBusinessAccountDetailsDto, IParentBusinessAccountDto, IDocumentTypeDto, IDocumentTypeSupplyTypeDto, ISupplierProductDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { IBusinessAccountSearchParams } from './interfaces/i.business-account-search-params';
import { cleanObjectDeep } from 'cb-hub-lib';
import { IBusinessAccountMappedItem, IDocTypeTradeTypeMappedItem, IDocTypeBizAccountTypeMappedItem, IDocTypeSupplyTypeMappedItem } from './interfaces/i.business-account.mapped';
import { BusinessAccountMappedItem, DocTypeTradeTypeMappedItem, DocTypeBizAccountTypeMappedItem, DocTypeSupplyTypeMappedItem } from './business-account.mapped';
import {
    IBusinessAccountContactDetailsDto,
    IBusinessAccountLotQuoteUsageDto,
    IDocumentTypeBusinessAccountTypeDto,
    IDocumentTypeTradeTypeDto
} from '@classictechsolutions/hubapi-transpiled-enums/build/module';

@Injectable()
export class BusinessAccountLogicService extends BaseLogicService<IBusinessAccountDetailsDto, IBusinessAccountMappedItem> implements IBusinessAccountLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'businessaccounts',
            BusinessAccountMappedItem,
        );
    }

    public getParentAccounts(): Observable<IParentBusinessAccountDto[]> {
        return this.$http.get(`${this.$baseUri}/parentaccounts`);
    }

    public getBusinessAccountTypes(): Observable<IDocumentTypeBusinessAccountTypeDto[]> {
        return this.$http.get('/documenttypesforbiztypes');
    }

    public getBusinessAccountTypeDocTypes(): Observable<IDocumentTypeDto[]> {
        return this.$http.get('/documenttypesforbiztypes/doctypes');
    }

    public getTradeTypes(): Observable<IDocumentTypeTradeTypeDto[]> {
        return this.$http.get('/documenttypesfortradetypes');
    }

    public getSupplyTypes(): Observable<IDocumentTypeSupplyTypeDto[]> {
        return this.$http.get('/documenttypesforsupplytypes');
    }

    public getTradeTypeDocTypes(): Observable<IDocumentTypeDto[]> {
        return this.$http.get('/documenttypesfortradetypes/doctypes');
    }

    // TODO: This returns a dto not in the transpiler called LocationTreeDto, It should be added to the dtos and referenced here instead of any
    public orderContactAreas(id: number): Observable<any> {
        return this.$http.get(`${this.$baseUri}/${id}/contacts/ordercontactsareas`);
    }

    public getContactsByArea(businessAccountId: number, areaId: number, contactType: BusinessAccountContactTypeEnumId): Observable<IBusinessAccountContactDetailsDto[]> {
        return this.$http.get(`${this.$baseUri}/${businessAccountId}/contacts/area/${areaId}/${contactType}`);
    }

    public getAccountContacts(businessAccountId: number): Observable<IBusinessAccountContactDetailsDto[]> {
        return this.$http.get(`${this.$baseUri}/${businessAccountId}/contacts/details`);
    }

    public $getSearchList(params: IBusinessAccountSearchParams): Observable<ISearchResult<IBusinessAccountDocumentDto>> {
        Object.keys(params).forEach(key => {
            if (key !== 'query' && !params[key]) {
                delete params[key];
            }
        });

        return this.$http.get(`${this.$baseUri}/search`, cleanObjectDeep(params));
    }

    public export(): Observable<any> {
        return this.$http.download(`${this.$baseUri}/report`);
    }

    public getChildAccounts(accountId: number): Observable<IBusinessAccountDetailsDto[]> {
        return this.$http.get(`${this.$baseUri}/${accountId}/accounts`);
    }

    public getQuotes(accountId: number, params: any): Observable<IBusinessAccountLotQuoteUsageDto[]> {
        Object.keys(params).forEach(key => {
            if (key !== 'query' && !params[key]) {
                delete params[key];
            }
        });
        return this.$http.get(`${this.$baseUri}/businessaccountquoteslotusage/${accountId}`, cleanObjectDeep(params));
    }

    public getCatalogues(accountId: number, params: any): Observable<ISupplierProductDto[]> {
        return this.$http.get(`${this.$baseUri}/${accountId}/catalog`, cleanObjectDeep(params));
    }

    public addContact(accountId: number, contactId: string): Observable<IBusinessAccountContactDetailsDto> {
        return this.$http.post(`${this.$baseUri}/${accountId}/contacts`, { contactId });
    }

    public editContact(
        accountId: number, contactIndex: number, contactId: string,
        position?: string, isPortalAccessEnabled?: boolean, receivesDailyEmails?: boolean): Observable<IBusinessAccountContactDetailsDto> {
        return this.$http.post(`${this.$baseUri}/${accountId}/contacts/${contactIndex}`, { contactId, position, isPortalAccessEnabled, receivesDailyEmails });
    }

    public deleteContact(accountId: number, contactIndex: number): Observable<boolean> {
        return this.$http.delete(`${this.$baseUri}/${accountId}/contacts/${contactIndex}`);
    }

    public addEditContactLocation(
        accountId: number, contactId: number, contactLocationId: number,
        contactType: number, locationId: number, emailAddress: string, tradeTypeId: number): Observable<IBusinessAccountContactDetailsDto> {
        return this.$http.post(`${this.$baseUri}/${accountId}/contacts/${contactId}/areas${contactLocationId ? `/${contactLocationId}` : ''}`, {
            contactType,
            location: { id: locationId },
            emailAddress: emailAddress ? emailAddress : '',
            tradeTypeId: tradeTypeId ? tradeTypeId : undefined,
            isActive: true
        });
    }

    public deleteContactLocation(accountId: number, contactId: number, contactLocationId: number): Observable<IBusinessAccountContactDetailsDto> {
        return this.$http.delete(`${this.$baseUri}/${accountId}/contacts/${contactId}/areas/${contactLocationId}`);
    }

    public editFinancialInfo(accountId: number, bankName: string, bankNumber: string, abmCode: string): Observable<IBusinessAccountDetailsDto> {
        return this.$http.post(`${this.$baseUri}/${accountId}/bankdetails`, { bankName, bankNumber, abmCode });
    }

    public requestSevenDays(accountId: number, description: string): Observable<IBusinessAccountDetailsDto> {
        return this.$http.post(`${this.$baseUri}/${accountId}/requestsevenday`, { description });
    }

    public requestSevenDaysApproval(accountId: number, isDeclined: boolean): Observable<IBusinessAccountDetailsDto> {
        return this.$http.post(`${this.$baseUri}/${accountId}/requestsevendayreply`, {
            id: accountId,
            outcome: isDeclined ? BUSINESS_ACCOUNT_SEVEN_DAY_PAYMENT_REQUEST_OUTCOME_ENUM.Rejected : BUSINESS_ACCOUNT_SEVEN_DAY_PAYMENT_REQUEST_OUTCOME_ENUM.Approved
        });
    }

    public addAccountHolds(accountId: number, holds: IBusinessAccountHoldDto[]): Observable<IBusinessAccountDetailsDto> {
        return this.$http.post(`${this.$baseUri}/${accountId}/onholds`, holds);
    }

    public removeAccountHold(accountId: number, hold: IBusinessAccountHoldDto): Observable<IBusinessAccountDetailsDto> {
        return this.$http.post(`${this.$baseUri}/${accountId}/offhold`, hold);
    }

    public submitAccount(accountId: number): Observable<IBusinessAccountDetailsDto> {
        return this.$http.post(`${this.$baseUri}/${accountId}/submitaccountforapproval`);
    }

    public submitRequest(accountId: number): Observable<IBusinessAccountDetailsDto> {
        return this.$http.post(`${this.$baseUri}/${accountId}/submitrequestforapproval`);
    }

    public cancelAccountRequest(accountId: number): Observable<IBusinessAccountDetailsDto> {
        return this.$http.post(`${this.$baseUri}/${accountId}/cancelaccountrequest`);
    }

    public approveRequest(accountId: number): Observable<IBusinessAccountDetailsDto> {
        return this.$http.post(`${this.$baseUri}/${accountId}/approveRequest`);
    }

    public declineRequest(accountId: number, reason: string): Observable<IBusinessAccountDetailsDto> {
        return this.$http.post(`${this.$baseUri}/${accountId}/declineRequest`, { declinedReason: reason });
    }

    public approveContract(accountId: number): Observable<IBusinessAccountDetailsDto> {
        return this.$http.post(`${this.$baseUri}/${accountId}/approveprocurement`);
    }

    public declineContract(accountId: number, reason: string): Observable<IBusinessAccountDetailsDto> {
        return this.$http.post(`${this.$baseUri}/${accountId}/declineprocurement`, { declinedReason: reason });
    }

    public approveHealthAndSafety(accountId: number): Observable<IBusinessAccountDetailsDto> {
        return this.$http.post(`${this.$baseUri}/${accountId}/approvehealthandsafety`);
    }

    public declineHealthAndSafety(accountId: number, reason: string): Observable<IBusinessAccountDetailsDto> {
        return this.$http.post(`${this.$baseUri}/${accountId}/declinehealthandsafety`, { declinedReason: reason });
    }

    public requestOverrideApproval(accountId: number, description: string): Observable<IBusinessAccountDetailsDto> {
        return this.$http.post(`${this.$baseUri}/${accountId}/approveoverride`, { accountId, description });
    }

    public approveOverrideApproval(accountId: number, isDeclined?: boolean, reason?: string): Observable<IBusinessAccountDetailsDto> {
        return this.$http.post(`${this.$baseUri}/${accountId}/approveoverride/approve`, {
            id: accountId,
            outcome: isDeclined ? BUSINESS_ACCOUNT_APPROVAL_OVERRIDE_REQUEST_OUTCOME_ENUM.Rejected : BUSINESS_ACCOUNT_APPROVAL_OVERRIDE_REQUEST_OUTCOME_ENUM.Approved,
            declinedReason: reason
        });
    }
}

@Injectable()
export class DocTypeBizAccountTypesLogicService
    extends BaseLogicService<IDocTypeBusinessAccountTypeDto, IDocTypeBizAccountTypeMappedItem>
    implements IDocTypeBizAccountTypesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'documenttypesforbiztypes',
            DocTypeBizAccountTypeMappedItem,
        );
    }
    public getDocumentTypesList(): Observable<IDocumentTypeDto[]> {
        return this.$http.get(this.$baseUri);
    }
}

@Injectable()
export class DocTypeTradeTypesLogicService extends BaseLogicService<IDocTypeTradeTypeDto, IDocTypeTradeTypeMappedItem> implements IDocTypeTradeTypesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'documenttypesfortradetypes',
            DocTypeTradeTypeMappedItem,
        );
    }

    public getItem(id: number): Observable<IDocTypeTradeTypeDto> {
        return this.$http.get(`${this.$baseUri}/${id}`);
    }

    public getDocumentTypesList(): Observable<IDocumentTypeDto[]> {
        return this.$http.get(this.$baseUri);
    }
}

@Injectable()
export class DocTypeSupplyTypesLogicService extends BaseLogicService<IDocTypeSupplyTypeDto, IDocTypeSupplyTypeMappedItem> implements IDocTypeSupplyTypesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'documenttypesforsupplytypes',
            DocTypeSupplyTypeMappedItem,
        );
    }

    public getItem(id: number): Observable<IDocTypeSupplyTypeDto> {
        return this.$http.get(`${this.$baseUri}/${id}`);
    }

    public getDocumentTypesList(): Observable<IDocumentTypeDto[]> {
        return this.$http.get(this.$baseUri);
    }
}
