<div class="cb-margin-2x flex-col flex">
    <form class="flex-col flex"
          #productCatalogueImportForm="ngForm">
        <div class="flex-col"
             *ngIf="!catalogueUploadResults">
            <cb-drag-and-drop-file class="flex"
                                   [allowedFileTypes]="[FILE_TYPE_ENUM.Xlsx]"
                                   [file$]="file$"
                                   [isFileValidAndUploaded$]="isFileValidAndUploaded$"
                                   description="Product Catalogue Item Import">
               </cb-drag-and-drop-file>
            <div class="flex-row">
                <cb-datepicker name="dateForCatalogueItems"
                               [(ngModel)]="dateForCatalogueItems"
                               [required]="true"
                               label="Date for Catelog Items"
                               pickerType="calendar">
                    </cb-datepicker>
                <span class="flex"></span>
                <button mat-raised-button
                        color="primary"
                        [buttonControl]="uploadButton"
                        [disabled]="uploadDisabled$ | async">
                         Upload
                    </button>
            </div>
        </div>
        <div class="cb-margin center-center flex-row"
             *ngIf="isLoading$ | async">
            <h3 class="mat-title cb-margin-right-3x">Importing Catalogue Items...</h3>
            <mat-spinner></mat-spinner>
        </div>
    </form>
    <div class="flex-col flex"
         *ngIf="catalogueUploadResults">
        <div class="start-center flex-row">
            <mat-icon class="cb-margin-right">playlist_add_check</mat-icon>
            <h3 class="mat-title"
                style="margin-bottom: 0;">Import Finished</h3>
            <span class="flex"></span>
            <button mat-raised-button
                    color="primary"
                    (cbClick)="exportCatalogue()"
                    class="cb-margin-right">
                    Get Updated File
               </button>
            <button mat-raised-button
                    color="primary"
                    (cbClick)="resetCatalog()">
                    Start Over
               </button>
        </div>
        <div class="cb-margin-top end-center flex-row flex">
            <span class="cb-margin-right">Log Level:</span>
            <mat-radio-group name="closetask-outcome-followup"
                             [(ngModel)]="logLevel">
                <mat-radio-button [value]="1"
                                  class="cb-margin-right">Brief</mat-radio-button>
                <mat-radio-button [value]="0">Full</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="cb-margin flex-col">
            <div class="errorContainer"
                 *ngIf="catalogueUploadErrors?.length > 0">
                <div class="start-center flex-row"
                     *ngFor="let record of catalogueUploadErrors | cbCallback: logFilter; let index = index;"
                     [ngClass]="{ 'odd': index % 2 }">
                    <mat-icon *ngIf="record.logLevel === 3">warning</mat-icon>
                    <mat-icon *ngIf="record.logLevel !== 3"></mat-icon>
                    <span>{{record.message}}</span>
                    <span class="flex"></span>
                    <span *ngIf="record.row > 0"
                          class="rowCount">Row {{record.row}}</span>
                    <span *ngIf="record.row === 0"
                          class="rowCount">&nbsp;</span>
                </div>
            </div>
        </div>
        <div *ngFor="let item of catalogueUploadSheets | keyvalue"
             class="cb-margin flex-col">
            <h4>{{item.key}}</h4>
            <div class="errorContainer cb-margin">
                <div class="start-center flex-row"
                     *ngFor="let record of item.value | cbCallback: logFilter; let index = index;"
                     [ngClass]="{ 'odd': index % 2 }">
                    <mat-icon class="mat-warn"
                              *ngIf="record.logLevel === 3">warning</mat-icon>
                    <mat-icon *ngIf="record.logLevel !== 3"></mat-icon>
                    <span>{{record.message}}</span>
                    <span class="flex"></span>
                    <span *ngIf="record.row > 0"
                          class="rowCount">Row {{record.row}}</span>
                    <span *ngIf="record.row === 0"
                          class="rowCount">&nbsp;</span>
                </div>
            </div>
        </div>
    </div>
</div>
