export enum HISTORY_SEARCH_ENTITY {
    ClientSale = 'clientsale',
    Lead = 'lead',
    SpecTemplate = 'spectemplate',
    ProjectReleaseStage = 'projectreleasestage',
    OfferingProduct = 'offeringproduct',
    BuildProgrammeTemplate = 'buildprogrammetemplate',
    BusinessAccount = 'businessaccount',
    Account = 'account',
    Contact = 'contact',
    DesignScheme = 'designscheme',
    Lot = 'lot',
    Project = 'project',
}
