<cb-dialog dialogHeading="Reassign To">
    <form class="flex-col flex"
          #reassignConsentForm="ngForm">
        <cb-select label="Team"
                   name="assignedTeam"
                   [(ngModel)]="mappedItem.assignedTeamId"
                   [options]="teams"
                   [required]="true"
                   (change)="teamSelected()"></cb-select>
        <cb-user-autocomplete-scroller class="flex"
                                       label="User"
                                       name="user"
                                       [(ngModel)]="assignedUser"
                                       [requireMatch]="true"
                                       [matchProps]="['id']"
                                       [team]="[assignedTeam?.key]"
                                       [active]="true"
                                       [required]="true">
        </cb-user-autocomplete-scroller>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="reassignConsentForm.invalid || reassignConsentForm.pristine"
                (cbClick)="save()"
                [clickLimit]="1">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
