<cb-drag-and-drop-document [(documentEntityMappedItem)]="documentEntityMappedItem"
                           (documentEntityMappedItemChange)="onDocumentEntityMappedItemChange()"></cb-drag-and-drop-document>
<mat-form-field *ngIf="!hideName && documentEntityMappedItem?.document">
    <input matInput
           type="text"
           name="name"
           placeholder="Document Name"
           [maxlength]="100"
           required
           [(ngModel)]="documentEntityMappedItem.document.name"/>
</mat-form-field>
<mat-form-field *ngIf="!hideDescription && documentEntityMappedItem?.document">
    <textarea #description
              matInput
              type="text"
              name="subject"
              placeholder="Document Description"
              [(ngModel)]="documentEntityMappedItem.document.description"
              [maxlength]="500">
            </textarea>
    <mat-hint align="end">{{description?.value.length}} / 500</mat-hint>
</mat-form-field>
