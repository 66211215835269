import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subject, Subscription, map, BehaviorSubject } from 'rxjs';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { QSTeamPermissions } from '@app/core/permissions';
import { orderBy } from 'lodash';
import { SelectListItem } from '@app/core/services/enum/enum.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ITeamBuildProgrammeSearch } from '@app/core/services/user-cache/user-cache-areas';
import { BUILD_PROGRAMME_STATUS_ENUM, IBuildProgrammeDocumentDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { BuildProgrammeLogicService } from '@app/logic/build-programme';

@Component({
    selector: 'cb-team-build-programmes',
    templateUrl: './team-build-programmes.component.html',
    styleUrls: ['./team-build-programmes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamBuildProgrammesComponent implements OnDestroy, OnInit {

    public resultOrders$ = of([
        { id: 'jobNumber', label: 'Job Number' },
        { id: 'project', label: 'Project' },
        { id: 'region', label: 'Region' },
        { id: 'release', label: 'Release' },
        { id: 'status', label: 'Status' }
    ]);

    @Input() public resultDirections$: Observable<{
        id: string;
        label: string;
    }[]>;

    private _subscriptions = new Subscription();
    public searchFiltersLoaded$ = new Subject();
    public searchEnabled = false;
    public readonly searchFiltersChanged$ = new Subject();
    public currentPage: number;
    public readonly allUsers = '999';
    public readonly allStatuses = 999;
    public readonly unassigned = undefined;
    public loaded = false;
    public results: IBuildProgrammeDocumentDto[] = [];
    public BUILD_PROGRAMME_STATUS_ENUM = BUILD_PROGRAMME_STATUS_ENUM;
    public infiniteScrollEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public buildProgrammeStatusesOptions$ = of(BUILD_PROGRAMME_STATUS_ENUM.toSelectList())
        .pipe(
            map(buildprogrammeStatuses => {
                buildprogrammeStatuses.unshift({
                    id: this.allStatuses,
                    label: 'All',
                    disabled: false,
                    checked: false
                } as SelectListItem);
                return buildprogrammeStatuses;
            })
        );

    public get searchFilters(): UserCacheItem<ITeamBuildProgrammeSearch> {
        return this.userCacheService.teamBuildProgrammeSearch;
    }

    public framingManagersOptions$ = this.teamsLogicService
        .getFramingManagers().pipe(
            map(users => {
                users = orderBy(users, 'firstName');
                users.unshift({ id: this.allUsers, label: 'All' } as any);
                users.unshift({ id: this.unassigned, label: 'Unassigned' } as any);
                return users;
            })
        );

    public foundationManagersOptions$ = this.teamsLogicService
        .getFoundationManagers().pipe(
            map(users => {
                users = orderBy(users, 'firstName');
                users.unshift({ id: this.allUsers, label: 'All' } as any);
                users.unshift({ id: this.unassigned, label: 'Unassigned' } as any);
                return users;
            })
        );

    constructor(
        private readonly teamsLogicService: TeamsLogicService,
        private readonly logicService: BuildProgrammeLogicService,
        private readonly dialogService: CbDialogService,
        public cdRef: ChangeDetectorRef,
        private readonly navigationService: NavigationService,
        public readonly qsTeamPermissions: QSTeamPermissions,
        private readonly userCacheService: UserCacheService,
        public readonly route: ActivatedRoute,
    ) {

    }

    public ngOnInit(): void {
        this._initSearchFiltersCache();
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public viewBuildProgramme(lotId: number): void {
        this.navigationService.navigate([`/lots/${lotId}/build/programme`]);
    }

    public fetchResults(): Observable<IBuildProgrammeDocumentDto[]> {
        if (!this.searchEnabled) {
            return;
        }
        this.loaded = true;
        return this.logicService.$getSearchList(this._queryParams);
    }

    private get _queryParams(): ITeamBuildProgrammeSearch {
        return this.searchEnabled ?
            {
                ...this.searchFilters.copyData(),
                foundationManagerId: this.searchFilters.data.foundationManagerId === this.allUsers ?
                    undefined :
                    this.searchFilters.data.foundationManagerId ?
                        this.searchFilters.data.foundationManagerId :
                        undefined,
                framingManagerId: this.searchFilters.data.framingManagerId === this.allUsers ?
                    undefined :
                    this.searchFilters.data.framingManagerId ?
                        this.searchFilters.data.framingManagerId :
                        undefined,
                statusId: (this
                    .searchFilters.data.statusId as any) === this.allStatuses ?
                    undefined :
                    this.searchFilters.data.statusId,
                currentPage: this.currentPage
            } :
            undefined;
    }

    private _initSearchFiltersCache(): void {
        this.searchFilters.init().then(() => {
            this.searchFiltersLoaded$.next(true);
            this.searchEnabled = true;
            this._onSearchFiltersChanged();
            this._subscriptions.add(
                this.searchFilters.updated$.subscribe({
                    next: this._onSearchFiltersChanged
                })
            );
        });
    }

    private readonly _onSearchFiltersChanged = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.currentPage = 1;
        this.searchFiltersChanged$.next(null);
    };
}
