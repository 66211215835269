import { Computed } from '@app/logic/base/computed-prop.decorator';
import { BaseMappedItem } from '../base/base-mapped-item';
import { ISupplyTypeTreeMappedItem } from './interfaces/i.supply-type-tree.mapped';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { ISupplyTypesTreeLogicService } from './interfaces/i.supply-types-tree.logic.service';
import { DtoProp } from '../base/dto-prop.decorator';
import { IComplianceRegistrationDto, ISupplyTypeTreeDto } from '@classictechsolutions/hubapi-transpiled-enums';

export class SupplyTypeTreeMappedItem
    extends BaseMappedItem<ISupplyTypeTreeDto, ISupplyTypeTreeMappedItem, ISupplyTypesTreeLogicService>
    implements ISupplyTypeTreeMappedItem {
    @DtoProp public canRequestSevenDayPayments: boolean;
    @DtoProp public includeInLiveSchedule: boolean;
    @DtoProp public id: number;
    @DtoProp public isActive: boolean;
    @DtoProp public label: string;
    @DtoProp public parentId: number;
    @DtoProp public parentLabel: string;
    @DtoProp public requiredComplianceRegistrations: IComplianceRegistrationDto[];
    /** do not use, only for computing mappedChildren */
    @DtoProp public children: ISupplyTypeTreeDto[];

    private _mappedChildrenStore: ISupplyTypeTreeMappedItem[] = [];
    @Computed() public get mappedChildren(): ISupplyTypeTreeMappedItem[] {
        if (this._mappedChildrenStore == null) {
            this._mappedChildrenStore = [];
        }
        if (this.children != null) {
            this._mappedChildrenStore = this.children.map(x => this.$logicService.$createMappedItem(x));
        }
        this.filteredChildren = this._mappedChildrenStore;
        return this._mappedChildrenStore;
    }

    public filteredChildren: ISupplyTypeTreeMappedItem[];

    constructor(
        sourceData: ISupplyTypeTreeDto,
        logicService: ISupplyTypesTreeLogicService
    ) {
        super(sourceData, logicService, SupplyTypeTreeMappedItem, {
            requiredComplianceRegistrations: [],
            isActive: true
        });
    }

    protected $preSave(toSave: ISupplyTypeTreeDto): void {
        if (!toSave.parentId) {
            toSave.requiredComplianceRegistrations = undefined;
        }
    }

    public filterChildren(query?: string | null): void {
        if (isNullOrWhiteSpace(query)) {
            this.filteredChildren = this.mappedChildren;
            return;
        }
        this.filteredChildren = this.mappedChildren.filter(x => {
            return x.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
    }
}
