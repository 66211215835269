import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { IDocTypeBizAccountTypeMappedItem } from '@app/logic/business-accounts/interfaces/i.business-account.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { BusinessAccountLogicService } from '@app/logic/business-accounts';
import { IDocTypeBizAccountTypesLogicService } from '@app/logic/business-accounts/interfaces/i.business-account.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { SelectItemsService } from '@app/shared/components/forms/select-items-dialog/select-items-service';
import { IDocTypeBusinessAccountTypeDto } from '@app/logic/business-accounts/interfaces/i.business-account.dto';
import { IDocumentTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    mappedItem: IDocTypeBizAccountTypeMappedItem;
}

@Component({
    selector: 'cb-business-account-type-docs-dialog',
    templateUrl: './business-account-type-docs-dialog.component.html',
    styleUrls: ['./business-account-type-docs-dialog.component.scss'],
    providers: [BusinessAccountLogicService, SelectItemsService]
})
export class BusinessAccountTypeDocsDialogComponent extends BaseDialogFormViewDirective<
IDocTypeBusinessAccountTypeDto,
IDocTypeBizAccountTypeMappedItem,
IDocTypeBizAccountTypesLogicService> implements OnInit {
    public bizTypeDocTypes: Array<IDocumentTypeDto>;
    public mappedItem: IDocTypeBizAccountTypeMappedItem;
    public selectedDocs: Array<IDocumentTypeDto>;
    constructor(
        public readonly toastSerivce: ToastService,
        private readonly businessAccountLogicService: BusinessAccountLogicService,
        public readonly selectItemsService: SelectItemsService,
        public readonly dialogRef: MatDialogRef<BusinessAccountTypeDocsDialogComponent>,
        public readonly dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
        this.mappedItem.documentTypeIds = this.mappedItem.documentTypes.map(x => x.id);
    }

    public ngOnInit(): void {
        this.businessAccountLogicService
            .getBusinessAccountTypeDocTypes()
            .subOnce(result => {
                this.bizTypeDocTypes = result;
            });
    }
}
