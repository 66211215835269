<cb-dialog dialogHeading="{{getHeading() }}">
    <form class="flex-col flex"
          #loginForm="ngForm">
        <cb-input *ngIf="mode!=='resetPassword'"
                  type="text"
                  name="username"
                  label="Username"
                  [maxlength]="100"
                  [required]="true"
                  [(ngModel)]="mappedItem.portalUsername"></cb-input>
        <cb-input *ngIf="mode!=='changeUsername'"
                  type="text"
                  name="password"
                  label="Password"
                  [minlength]="9"
                  [maxlength]="100"
                  [required]="true"
                  [(ngModel)]="password"></cb-input>
    </form>
    <footer>
        <span class="flex"></span>
        <button mat-raised-button
                [disabled]="!loginForm.dirty || !loginForm.valid"
                color="primary"
                (click)="save()">
            Create Login
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
