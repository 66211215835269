import { PermissionsPermissions } from '@app/core/permissions';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ITradeTypeTreeMappedItem } from '@app/logic/trade-types/interfaces/i.trade-type-tree.mapped';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { TradeTypeDialogComponent } from '../trade-type-dialog/trade-type-dialog.component';
import { ITradeTypeTreeDto } from '@classictechsolutions/hubapi-transpiled-enums';
import {ITableColumn} from "cb-hub-lib";

@Component({
    selector: 'cb-trade-types-table',
    templateUrl: './trade-types-table.component.html',
    styleUrls: ['./trade-types-table.component.scss']
})
export class TradeTypesTableComponent {

    public readonly columns: ITableColumn[] = [
        { label: ' ', width: 48 },
        { label: 'Trade Type' },
        { label: 'Compliance Registrations' },
        { label: 'Include in Live Schedule', width: 200, },
        { label: 'Can Request 7 Days', width: 200, },
        { label: 'Active', width: 100, },
        { label: '' },
    ];

    @Input() public readonly tradeTypes: ITradeTypeTreeMappedItem[];
    @Input() public readonly loaded: boolean = true;
    @Input() public readonly expanded: { [tradeTypeId: number]: boolean } = {};
    @Output() public readonly reloadTradeTypes = new EventEmitter<void>();

    constructor(
        public readonly permissions: PermissionsPermissions,
        private readonly cbDialog: CbDialogService,
    ) { }

    public editTradeType(tradeType: ITradeTypeTreeMappedItem): void {
        const originalParentId = tradeType.parentId;
        this.cbDialog.open(TradeTypeDialogComponent, {
            data: {
                mappedItem: tradeType.$clone(),
            },
            minWidth: '50%',
        })
            .afterClosed()
            .subOnce((result: false | ITradeTypeTreeDto) => {
                if (result !== false && originalParentId !== result.parentId) {
                    this.reloadTradeTypes.emit();
                }
            });
    }

    public orderTradeTypes(arr: ITradeTypeTreeMappedItem[] = []): ITradeTypeTreeMappedItem[] {
        return [...arr].sort((a, b) => a.label > b.label ? 1 : a.label === b.label ? 0 : -1);
    }
}
