import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PricingRevisionsLogicService } from '@app/logic/pricing-revisions/pricing-revisions.logic.service';
import { IPricingRevisionPriceHistoryDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'cb-pricing-revision-history-tab',
    templateUrl: './pricing-revision-history-tab.component.html',
    styleUrls: ['./pricing-revision-history-tab.component.scss']
})
export class PricingRevisionHistoryTabComponent {
    @Input() public set entityId(id: number) {
        if (id) {
            this.historyItemsLoaded = false;
            this.logicService.getRevisionPriceHistory(id).subOnce({
                next: (_historyItems) => {
                    this.historyItems$$.next(_historyItems);
                },
                error: () => {
                    this.historyItemsLoaded = true;
                },
                complete: () => {
                    this.historyItemsLoaded = true;
                }
            });
        }
    }
    public historyItemsLoaded: boolean = false;
    public historyItems$$: BehaviorSubject<IPricingRevisionPriceHistoryDto[]> = new BehaviorSubject(null);
    public readonly displayedColumns: string[] = ['Date', 'User', 'Margin %', 'Land Price', 'Build Cost', 'Build Price', 'Build Price - (Gst Inclusive)'];

    constructor(
        public readonly dialog: MatDialog,
        public readonly logicService: PricingRevisionsLogicService,
    ) { }
}
