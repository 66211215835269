import {
    IBaseDefaultSystemAreaDocumentTypeDto, IDefaultSystemAreaDocumentTypeDto,
    IEntityDefaultSystemAreaDocumentTypeDto, IPossibleDocumentTypeDto,
    LotContractTypeEnumId,
    SystemAreaEnumId
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IDefaultSystemAreaDocumentTypesLogicService } from './interfaces/i.system-area-documents-logic.service';
import { BaseLogicService } from '../base/base-logic.service';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import {
    IndexedDocumentTypes
} from './interfaces/i.system-area-document-type.dto';


@Injectable()
export class DefaultSystemAreaDocumentTypesLogicService
    extends BaseLogicService<IDefaultSystemAreaDocumentTypeDto, Object>
    implements IDefaultSystemAreaDocumentTypesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('defaultsystemareadocumenttypes', Object);
    }

    public $getItem(): Observable<IDefaultSystemAreaDocumentTypeDto> {
        throw new Error('$getItem does not match any endpoints');
    }

    public $getList(): Observable<IDefaultSystemAreaDocumentTypeDto[]> {
        throw new Error('$getList does not match any endpoints');
    }

    public getList(systemArea: SystemAreaEnumId): Observable<IDefaultSystemAreaDocumentTypeDto[]> {
        return this.$http
            .get<IDefaultSystemAreaDocumentTypeDto[]>(`${this.$baseUri}/${systemArea}/list`);
    }

    public getListByLotContractType(
        systemArea: SystemAreaEnumId,
        lotContractType: LotContractTypeEnumId,
        documentGroupCode: string
    ): Observable<IDefaultSystemAreaDocumentTypeDto[]> {
        return this.$http
            .get<IDefaultSystemAreaDocumentTypeDto[]>(`${this.$baseUri}/${systemArea}/list/${lotContractType}/${documentGroupCode}`);
    }

    public getListByLot(systemArea: SystemAreaEnumId, lotId: number, documentGroupCode: string): Observable<IDefaultSystemAreaDocumentTypeDto[]> {
        return this.$http
            .get<IDefaultSystemAreaDocumentTypeDto[]>(`${this.$baseUri}/${systemArea}/list/lot/${lotId}/${documentGroupCode}`);
    }

    public getListByUploadedSystemArea(
        lotContractType: LotContractTypeEnumId,
        uploadedSystemArea: SystemAreaEnumId,
        systemArea: SystemAreaEnumId,
        entityId: number,
        documentGroupCode: string): Observable<IDefaultSystemAreaDocumentTypeDto[]> {
        return this.$http
            .get<IDefaultSystemAreaDocumentTypeDto[]>(
            `${this.$baseUri}/${uploadedSystemArea}/${systemArea}/list/byuploadedsystemarea/${entityId}/${lotContractType}/${documentGroupCode}/`);
    }

    public getIndexedLists(systemArea: SystemAreaEnumId): Observable<IndexedDocumentTypes> {
        return this.$http
            .get<IndexedDocumentTypes>(`${this.$baseUri}/${systemArea}/indexed`);
    }

    public getPossibleDocumentTypes(systemArea: SystemAreaEnumId, lotContactType: LotContractTypeEnumId, documentGroupCode: string): Observable<IPossibleDocumentTypeDto[]> {
        return this.$http
            .get<IPossibleDocumentTypeDto[]>(`${this.$baseUri}/possibledocumenttypes/${systemArea}/${lotContactType}/${documentGroupCode}`);
    }

    public addDocumentType(dto: IBaseDefaultSystemAreaDocumentTypeDto): Observable<IDefaultSystemAreaDocumentTypeDto> {
        return this.$http
            .post<IDefaultSystemAreaDocumentTypeDto>(`${this.$baseUri}`, dto);
    }

    public deleteDocumentType(dto: IEntityDefaultSystemAreaDocumentTypeDto): Observable<boolean> {
        return this.$http
            .delete<boolean>(`${this.$baseUri}/${dto.systemArea}/${dto.lotContractType}/${dto.documentGroupId}/${dto.documentTypeId}`);
    }
}
