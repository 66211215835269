<div class="flex-row flex"
     style="overflow: hidden; padding-top: 10px;">
    <div class="flex-col flex">
        <div class="flex-row flex">
            <cb-display-value-new class="flex"
                                  label="Bedroom">
                <div class="start-center">
                    <mat-icon>hotel</mat-icon>
                    <span class="mat-body-1">&nbsp;{{plan.amenities.bedrooms | cbDasher}}</span>
                </div>
            </cb-display-value-new>
            <cb-display-value-new class="flex"
                                  label="Bathroom">
                <div class="start-center">
                    <mat-icon>wc</mat-icon>
                    <span class="mat-body-1">&nbsp;{{plan.amenities.bathrooms | cbDasher}}</span>
                </div>
            </cb-display-value-new>
        </div>
        <div class="flex-row flex">
            <cb-display-value-new class="flex"
                                  label="Living">
                <div class="start-center">
                    <mat-icon>weekend</mat-icon>
                    <span class="mat-body-1">&nbsp;{{plan.amenities.living | cbDasher}}</span>
                </div>
            </cb-display-value-new>
            <cb-display-value-new class="flex"
                                  label="Garage">
                <div class="start-center">
                    <mat-icon>directions_car</mat-icon>
                    <span class="mat-body-1">&nbsp;{{plan.amenities.garages | cbDasher}}</span>
                </div>
            </cb-display-value-new>
        </div>
        <div class="flex-row flex">
            <cb-display-value-new class="flex"
                                  label="Floor Area">
                <div class="start-center">
                    <mat-icon>border_clear</mat-icon>
                    <span class="mat-body-1">&nbsp;{{plan.floorArea | cbDasher}} M²</span>
                </div>
            </cb-display-value-new>
        </div>
    </div>
    <div class="flex-col flex">
        <cb-display-value-new label="Category"
                              class="cb-margin-top"
                              [value]="plan.category?.name ?? 'none'">
        </cb-display-value-new>
        <mat-checkbox [(ngModel)]="plan.isPublished"
                      disabled>Published
        </mat-checkbox>
    </div>
</div>
