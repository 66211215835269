<mat-card class="cb-margin flex-col flex">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon"
             [matTooltip]="tooltipMessage.value">
            <mat-icon [ngClass]="cardIconClass.value">{{cardIcon.value}}</mat-icon>
        </div>
        <mat-card-title [ngClass]="cardIconClass.value">{{stepNumber}}. Sale &amp; Purchase Agreement</mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex-col flex">
        <div class="warning-message start-center flex-row"
             *ngIf="!clientSale?.canDownloadClientSpecAndSpInfo">
            <span class="mat-body-1">Complete Steps 1 - 4 to download sale documents</span>
        </div>
        <span class="mat-body-2">BCI</span>
        <cb-display-value-new label="Downloaded"
                              [value]="clientSale?.documentInfo?.spInformationDownloadDate | date">
        </cb-display-value-new>
        <cb-display-value-new label="Signed Copy Uploaded"
                              [value]="uploadLabels.value?.spInfo">
        </cb-display-value-new>
        <div class="flex-col">
            <button mat-stroked-button
                    class="cb-margin-bottom"
                    *ngIf="permissions?.canGenerateSaleAndPurchaseInfo()"
                    [disabled]="!clientSale?.canDownloadClientSpecAndSpInfo"
                    (click)="downloadSalePurchaseInformation()"
                    color="accent">
                <mat-icon>file_download</mat-icon>
                &nbsp;BCI
            </button>
            <button mat-stroked-button
                    [disabled]="!clientSaleDocuments?.documents?.spInfo?.entity?.canUpload"
                    (click)="clientSaleDocuments?.documents?.spInfo?.openUploadDialog()"
                    color="accent">
                <mat-icon>file_upload</mat-icon>
                &nbsp;Upload BCI
            </button>
        </div>
        &nbsp;
        <span class="mat-body-2">Create Schedule</span>
        <cb-display-value-new label="Downloaded"
                              [value]="clientSale?.documentInfo?.clientSpecificationDownloadDate | date">
        </cb-display-value-new>
        <cb-display-value-new label="Signed Copy Uploaded"
                              [value]="uploadLabels.value?.clientSpec">
        </cb-display-value-new>
    </mat-card-content>
    <mat-card-actions class="cb-padding-left cb-padding-right flex-col">
        <button mat-stroked-button
                class="cb-margin-bottom"
                *ngIf="permissions?.canDownloadCreateSchedule()"
                [disabled]="!clientSale?.canDownloadClientSpecAndSpInfo || (isLotSpecScheduleTemplate() && clientSale.isUnconditional)"
                (click)="downloadCreateSchedule()"
                color="accent">
            <mat-icon>file_download</mat-icon>
            &nbsp;Create Schedule
        </button>
        <button class="cb-no-margin"
                mat-stroked-button
                [disabled]="!clientSaleDocuments?.documents?.clientSpec?.entity?.canUpload || (isLotSpecScheduleTemplate() && clientSale.isUnconditional)"
                (click)="clientSaleDocuments?.documents?.clientSpec?.openUploadDialog()"
                color="accent">
            <mat-icon>file_upload</mat-icon>
            &nbsp;Upload Create Schedule
        </button>
    </mat-card-actions>
</mat-card>
