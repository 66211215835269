import { IClientSaleDto } from '..';
import { AccountTypeEnumId, IAddressDto, IClientSaleClientAccountUpdateDto } from '@classictechsolutions/hubapi-transpiled-enums';

export class ClientSaleClientAccountUpdateDto implements IClientSaleClientAccountUpdateDto {
    public clientAccountName: string;
    public clientAccountLegalName: string;
    public clientAccountType: AccountTypeEnumId;
    public mainContactId: string;
    public solicitorName: string;
    public solicitorEmail: string;
    public physicalAddress: IAddressDto;

    constructor(private clientSale: IClientSaleDto) {
        this.clientAccountName = clientSale.clientAccount.name;
        this.clientAccountLegalName = clientSale.clientAccount.legalName;
        this.clientAccountType = clientSale.clientAccount.accountTypeId;
        this.mainContactId = clientSale.clientAccount.mainContactId;
        this.solicitorName = clientSale.clientAccount.solicitorName;
        this.solicitorEmail = clientSale.clientAccount.solicitorEmail;
        this.physicalAddress = clientSale.clientAccount.physicalAddress ?? {} as IAddressDto;

        delete this.clientSale;
    }
}
