import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { WipFavoriteView } from '@app/logic/wip/wip.service';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { CbTruncateModule } from '@app/shared/pipe/truncate/truncate.module';

@Component({
    selector: 'cb-side-panel',
    templateUrl: './side-panel.component.html',
    styleUrls: ['./side-panel.component.scss'],
    standalone: true,
    imports: [
        MatLegacyCardModule,
        MatIconModule,
        CommonModule,
        MatLegacyTooltipModule,
        MatDividerModule,
        MatButtonModule,
        MatListModule,
        MatSidenavModule,
        MatLegacySlideToggleModule,
        CbTruncateModule,
    ],
})
export class SidePanelComponent {
    @Input() favoriteViews: WipFavoriteView[] = [];
    public collapsed = true;
    @Input() public selectedId = '';
    @Input() public isEditMode = false;
    @Output() viewSelected = new EventEmitter<WipFavoriteView>();
    @Output() modeChanged = new EventEmitter<boolean>();
    @Output() addNewClicked = new EventEmitter<void>();
    @Output() deleteView = new EventEmitter<WipFavoriteView>();
    constructor() {
    }

    public selectView(view: WipFavoriteView): void {
        this.viewSelected.emit(view);
        this.selectedId = view.id;
        this.collapsed = !this.collapsed;
        this.onEditModeChange(false);
    }

    public onEditModeChange(isEditMode: boolean): void {
        this.isEditMode = isEditMode;
        this.modeChanged.emit(this.isEditMode);
    }

    public addNewView(): void {
        this.addNewClicked.emit();
        this.selectedId = '';
        this.onEditModeChange(true);
    }

    public onDeleteView(view: WipFavoriteView, event: Event): void {
        event.stopPropagation();
        this.deleteView.emit(view);
    }

    public editClick(view: WipFavoriteView, $event: MouseEvent): void {
        this.selectView(view);
        this.onEditModeChange(true);
    }
}
