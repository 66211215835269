<div class="manage-pre-consent-container flex flex-row">
    <div class="cb-margin flex-col flex">
        <cb-pre-consent-action-bar [(mappedItem)]="mappedItem"
                                   [lotMappedItem]="lotMappedItem"
                                   (goBack)="goBack.emit()"
                                   [documents]="documents$$.getValue()"
                                   (mappedItemChange)="mappedItemChanged($event)">
        </cb-pre-consent-action-bar>
        <div class="card-container flex-row flex">
            <div class="flex-col flex-20">
                <cb-pre-consent-side-bar-card class="cb-margin-top flex flex-row"
                                              [mappedItem]="mappedItem">
                </cb-pre-consent-side-bar-card>
            </div>
            <div class="flex-col flex">
                <cb-manage-pre-consent-tabs [mappedItem]="mappedItem"
                                            (documentsChanged)="documents$$.next($event)"
                                            class="cb-margin-top cb-margin-left flex flex-row">
                </cb-manage-pre-consent-tabs>
            </div>
        </div>
    </div>
</div>
