<cb-dialog dialogHeading="Manage Images"
           (cancelDialog)="cancel()">
    <div class="flex-row">
        <form #documentForm="ngForm"
              class="cb-margin-right-3x flex-col flex-50">
            <div class="uploadfilecontainer dropzone start-center flex-row"
                 cbDragDrop
                 (onFileDropped)="selectFile($event)"
                 style="height: 100px;">
                <input hidden
                       type="file"
                       #fileInput
                       (click)="$event.target.value = null"
                       (change)="selectFile($event.target.files)"/>
                <span class="cb-margin-left-2x">{{document?.file?.name || 'Drag an Image File to Upload'}}</span>
                <span class="flex"></span>
                <div class="cb-margin-right-2x"
                     (click)="fileInput.click()">
                    <button style="width: 60px;height: 60px;"
                            mat-icon-button
                            color="accent">
                        <mat-icon>attach_file_outline</mat-icon>
                    </button>
                </div>
            </div>
            <div class="end start flex-row">
                <button mat-raised-button
                        color="primary"
                        class="cb-margin-top"
                        [disabled]="!document.file || !isFileExtensionValid || !isFilesizeValid"
                        (cbClick)="uploadFile()">
                    Upload
                </button>
            </div>
            <div class="flex-col">
                <div class="start-center flex-row"
                     *ngIf="!isFileExtensionValid && document.file">
                    <mat-icon color="warn">
                        report_problem
                    </mat-icon>
                    <span>
                        The file you have selected has the wrong file type. Please select a jpg, png, gif or bmp file.
                    </span>
                </div>
                <div class="start-center flex-row"
                     *ngIf="!isFilesizeValid && document.file">
                    <mat-icon color="warn">
                        report_problem
                    </mat-icon>
                    <span>
                        The file you have selected is too large. Please select a file under 50 MB.
                    </span>
                </div>
            </div>
        </form>
        <div class="flex-col flex-50">
            <div class="manageProductImages flex-col flex-100">
                <ul class="list"
                    [dragula]="ORDERED_IMAGES"
                    [(dragulaModel)]="dragulaModel">
                    <li *ngFor="let productImage of mappedItem.images">
                        <div class="flex-row">
                            <div class="flex-col flex">
                                <img src="{{api}}/products/{{mappedItem.id}}/images/{{productImage.id}}.{{productImage.extension}}?h=120&mode=max"
                                     alt="img">
                            </div>
                            <div class="flex-col">
                                <button mat-icon-button
                                        class="cb-margin-top cb-margin-right-2x"
                                        (cbClick)="deleteImage(productImage.id);">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="!imageOrderChanged"
                (click)="saveImageOrder()">
            Save Image Order
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Close
        </button>
    </footer>
</cb-dialog>
