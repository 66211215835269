import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IDocumentEntityMappedItem } from '@app/logic/documents';

@Component({
    selector: 'cb-upload-brochure-dialog',
    templateUrl: './upload-brochure-dialog.component.html',
    styleUrls: ['./upload-brochure-dialog.component.scss']
})
export class UploadBrochureDialogComponent {
    public static readonly MIN_WIDTH = '400px';

    constructor(
        public readonly dialogRef: MatDialogRef<UploadBrochureDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: { documentEntity: IDocumentEntityMappedItem }
    ) { }

    public save(): void {
        this.dialogRef.close(this.data.documentEntity.document.uploadedFile);
    }

    public cancel(): void {
        this.dialogRef.close();
    }
}
