import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { ILotSpecDto, ILotSpecItemDto, ILotSpecScheduleDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { map, Observable } from 'rxjs';
import {
    ILotSpecScheduleLogicService
} from '.';
import { BaseLogicService } from '../base/base-logic.service';
import { LotSpecMappedItem } from '../lot-spec/lot-spec.mapped';
import { ILotSpecScheduleMappedItem } from './interfaces/i.lot-spec-schedule.mapped';
import { LotSpecScheduleMappedItem } from './lot-spec-schedule.mapped';

@Injectable()
export class LotSpecScheduleLogicService
    extends BaseLogicService<ILotSpecScheduleDto, ILotSpecScheduleMappedItem>
    implements ILotSpecScheduleLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'lotspecschedule',
            LotSpecScheduleMappedItem
        );
    }

    public $getItems(entityId: number, lotId: number, versionNumber: number): Observable<ILotSpecItemDto> {
        throw new Error('Method not implemented.');
    }

    public getLotSpecScheduleAtVersion(lotId: number, versionNumber: number): Observable<LotSpecMappedItem> {
        return this.$http.get<ILotSpecDto>(`${lotId}/${this.$baseUri}/versions/${versionNumber}`)
            .pipe(
                map(result => this.$createMappedItem(result, LotSpecMappedItem))
            );
    }

    public getFriendlyDescriptionForSlot(lotId: number, slotId: number): Observable<string> {
        return this.$http.get<string>(`${lotId}/getExistingFriendlyDescription/${slotId}`);
    }

}
