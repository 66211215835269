import { Injectable, Injector } from '@angular/core';
import { IAddressRegionDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { map, Observable } from 'rxjs';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { AddressRegionMappedItem } from './address-region.mapped';
import { IAddressRegionMappedItem } from './interfaces/i.address-region.mapped';
import { IAddressRegionsLogicService } from './interfaces/i.address-regions.logic.service';

@Injectable()
export class AddressRegionsLogicService
    extends BaseLogicService<IAddressRegionDto, IAddressRegionMappedItem>
    implements IAddressRegionsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'addressregions',
            AddressRegionMappedItem
        );
    }

    public saveOrder(addressRegions: IAddressRegionDto[]): Observable<IAddressRegionMappedItem[]> {
        return this.$http
            .post<Array<IAddressRegionDto>>(`${this.$baseUri}/reorder`, addressRegions)
            .pipe(
                map(items => items.map(x => this.$createMappedItem(x)))
            );
    }
}
