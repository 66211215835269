<ng-container *ngIf="SCHEDULE_ITEMS != null">
    <cb-table class="cb-margin-top schedule-items-house-area-table"
              label="Schedule Items"
              [loaded]="true"
              [minWidth]="1000"
              [hideDefaultTable]="true">
        <table cbTable
               [dragula]="SCHEDULE_ITEMS"
               [(dragulaModel)]="dragulaModel">
            <thead>
                <tr>
                    <td></td>
                    <td>
                        Apply Tag
                    </td>
                    <td>
                        Name
                    </td>
                    <td>
                        Cost Type
                    </td>
                    <td>
                        Product Category Path
                    </td>
                    <td>
                        Tag(s)
                    </td>
                    <td>
                        Active
                    </td>
                    <td>
                        Req Child
                    </td>
                    <td></td>
                </tr>
            </thead>
            <tbody class="parent-slot"
                   [ngClass]="ENABLE_REORDERING"
                   *ngFor="let parentSlot of parentSlotsArray">
                <tr>
                    <td>
                        <button mat-icon-button
                                [disabled]="!childSlotsDict[parentSlot.id]?.length || reorderingEnabled"
                                (click)="expandedParents[parentSlot.id] = !expandedParents[parentSlot.id]">
                            <mat-icon>
                                {{ !reorderingEnabled && expandedParents[parentSlot.id] &&
                                childSlotsDict[parentSlot.id]?.length ?
                                'arrow_drop_up' : 'arrow_drop_down'}}
                            </mat-icon>
                        </button>
                    </td>
                    <td>
                        <mat-checkbox name="selectedSlots"
                                      [checked]="selectedItems[parentSlot.id]"
                                      (change)="itemSelected(parentSlot)">
                        </mat-checkbox>
                    </td>
                    <td>
                        <span>
                            <cb-td-text [value]="parentSlot.name"></cb-td-text>
                            <cb-info-popup [tooltip]="parentSlot.description"></cb-info-popup>
                        </span>
                    </td>
                    <cb-td-enum [value]="parentSlot.costType"
                                [enum]="COST_TYPE_ENUM">
                    </cb-td-enum>
                    <td>
                        <cb-td-text [value]="parentSlot?.categories[0]?.categoryPath"
                                    *ngIf="parentSlot.categories?.length">
                        </cb-td-text>
                        <span *ngIf="!parentSlot.categories?.length">
                            -
                        </span>
                    </td>
                    <cb-td-list [list]="parentSlot.tags">
                    </cb-td-list>
                    <cb-td-checkbox [value]="parentSlot.isActive"></cb-td-checkbox>
                    <td>
                        -
                    </td>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <button mat-icon-button
                                (click)="editItemClicked(parentSlot);">
                            <mat-icon>
                                edit
                            </mat-icon>
                        </button>
                    </td>
                </tr>
                <ng-container *ngIf="!reorderingEnabled && expandedParents[parentSlot.id]">
                    <tr *ngFor="let childSlot of childSlotsDict[parentSlot.id]">
                        <td></td>
                        <td>
                            <mat-checkbox name="showDeleted"
                                          [checked]="selectedItems[childSlot.id]"
                                          (change)="itemSelected(childSlot)"></mat-checkbox>
                        </td>
                        <td>
                            <span>
                                <cb-td-text [value]="childSlot.name"></cb-td-text>
                                <cb-info-popup [tooltip]="childSlot.description"></cb-info-popup>
                            </span>
                        </td>
                        <cb-td-enum [value]="childSlot.costType"
                                    [enum]="COST_TYPE_ENUM">
                        </cb-td-enum>
                        <td>
                            <cb-td-text *ngIf="childSlot.categories?.length"
                                        [value]="childSlot.categories[0].categoryPath">
                            </cb-td-text>
                            <span *ngIf="!childSlot.categories?.length">
                                -
                            </span>
                        </td>
                        <cb-td-list [list]="childSlot.tags">
                        </cb-td-list>
                        <cb-td-checkbox [value]="childSlot.isActive"></cb-td-checkbox>
                        <cb-td-checkbox [value]="childSlot.isRequired"></cb-td-checkbox>
                        <td cbTableShrinkCol
                            cbTableStickyCol="end">
                            <button mat-icon-button
                                    (click)="editItemClicked(childSlot);">
                                <mat-icon>
                                    edit
                                </mat-icon>
                            </button>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
            <tbody *ngIf="parentSlotsArray?.length < 1">
                <tr class="cb-table-footer-row">
                    <td colspan="100%">
                        <cb-info-message message="There are no Schedule Items to display for this House Area"
                                         icon="info_circle"></cb-info-message>
                    </td>
                </tr>
            </tbody>
        </table>
    </cb-table>
</ng-container>
