<div class="cb-margin-top cb-margin-bottom start flex-col"
     *ngIf="searchData">
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x start-center flex-row">
        <mat-icon>hotel</mat-icon>
        <div class="custom-slider">
            <ngx-slider [(value)]="searchData.numberOfBedrooms"
                        [options]="optionsBedRoom"
                        [(ngModel)]="searchData.numberOfBedrooms"
                        (valueChange)="searchQueryUpdate.next($event)"></ngx-slider>
        </div>
    </div>
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x start-center flex-row">
        <mat-icon>wc</mat-icon>
        <div class="custom-slider">
            <ngx-slider [(value)]="searchData.numberOfBathrooms"
                        [options]="optionsBathRoom"
                        [(ngModel)]="searchData.numberOfBathrooms"
                        (valueChange)="searchQueryUpdate.next($event)"></ngx-slider>
        </div>
    </div>
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x start-center flex-row">
        <mat-icon>weekend</mat-icon>
        <div class="custom-slider">
            <ngx-slider [(value)]="searchData.numberOfLivingRooms"
                        [options]="optionsLiving"
                        [(ngModel)]="searchData.numberOfLivingRooms"
                        (valueChange)="searchQueryUpdate.next($event)"></ngx-slider>
        </div>
    </div>
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x start-center flex-row">
        <mat-icon>directions_car</mat-icon>
        <mat-form-field>
            <mat-label>Garages</mat-label>
            <mat-select [(ngModel)]="searchData.numberOfGarages"
                        (ngModelChange)="searchQueryUpdate.next($event)">
                <mat-option *ngFor="let type of garageTypes"
                            [value]="type.id">
                    {{ type.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x start-center flex-row"
         *ngIf="optionsFloorAreaLoaded && floorAreas">
        <mat-icon svgIcon="floor-plan"
                  matTooltip="Floor Area"></mat-icon>
        <div class="custom-slider">
            <ngx-slider [(value)]="minFloorArea"
                        [(highValue)]="maxFloorArea"
                        [options]="optionsFloorArea"
                        (valueChange)="floorAreaChange($event)"
                        (highValueChange)="floorAreaChange($event)"></ngx-slider>
        </div>
    </div>
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x start-center flex-row"
         *ngIf="optionsLandAreaLoaded && landAreas">
        <mat-icon matTooltip="Land Area">border_style</mat-icon>
        <div class="custom-slider">
            <ngx-slider [(value)]="minLandArea"
                        [(highValue)]="maxLandArea"
                        [options]="optionsLandArea"
                        (valueChange)="landAreaChange($event)"
                        (highValueChange)="landAreaChange($event)"></ngx-slider>
        </div>
    </div>
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x start-center flex-row"
         *ngIf="optionsCostLoaded && costs">
        <mat-icon matTooltip="Price">attach_money</mat-icon>
        <div class="custom-slider">
            <ngx-slider [(value)]="minCost"
                        [(highValue)]="maxCost"
                        [options]="optionsCost"
                        (valueChange)="priceChange($event)"
                        (highValueChange)="priceChange($event)"></ngx-slider>
        </div>
    </div>
    <footer class="cb-margin-left-2x cb-margin-right-2x flex-row">
        <div class="flex"></div>
        <button mat-raised-button
                (click)="clearSearch()">
            <mat-icon>clear_all</mat-icon>
            &nbsp;Clear Filter
        </button>
    </footer>
</div>
