import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { IBundleTemplatesLogicService } from './interfaces/i.bundle-templates.logic.service';
import { BaseLogicService } from '../base/base-logic.service';
import { BundleTemplateMappedItem } from './bundle-template.mapped';
import { IBundleTemplateMappedItem } from './interfaces/i.bundle-template.mapped';
import { IBundleTemplateDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Injectable()
export class BundleTemplatesLogicService extends BaseLogicService<IBundleTemplateDto, IBundleTemplateMappedItem> implements IBundleTemplatesLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'bundletemplates',
            BundleTemplateMappedItem,
        );
    }
}
