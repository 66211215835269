import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {
    DATE_CALC_TYPE_ENUM,
    DateCalcTypeEnumId,
    BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM,
    LOT_TYPE_ENUM,
    IBuildProgrammeActivityLookupDto, IBuildProgrammeActivityDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { LotBuildProgrammeEventService } from '@app/views/lot/build/services/lot-build-programme-event.service';
import { IBuildProgrammeIndexedDto } from '@app/logic/build-programme/interfaces/i.build-programme-indexed.dto';
import {
    IBuildProgrammeActivityMappedItem
} from '@app/logic/build-programme-activity';
import { IBuildProgrammeActivityRelatedActivityDto } from '@classictechsolutions/hubapi-transpiled-enums/build/module/lib/dtos/BuildProgrammeActivityRelatedActivityDto';

interface IData {
    /** original Build Programme Activity Mapped Item */
    descendantBuildProgrammeActivity: IBuildProgrammeActivityMappedItem;
    oldPredecessorBuildProgrammeActivity: IBuildProgrammeActivityRelatedActivityDto;
}

@Component({
    templateUrl: './edit-predecessor-build-programme-activity-dialog.component.html',
    styleUrls: ['./edit-predecessor-build-programme-activity-dialog.component.scss']
})
export class EditPredecessorBuildProgrammeActivityDialogComponent implements OnInit {
    public static readonly MIN_WIDTH = '40%';

    public get buildProgramme(): IBuildProgrammeIndexedDto {
        return this.lotBuildProgrammeEvents?.buildProgramme;
    }
    public newPredecessorBuildProgrammeActivity: Partial<IBuildProgrammeActivityDto>;
    public dateCalcTypes = DATE_CALC_TYPE_ENUM.toLookup();
    public dateCalcType: DateCalcTypeEnumId;

    public exclStatuses = [
        BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM.Abandoned,
        BUILD_PROGRAMME_ACTIVITY_STATUS_ENUM.Complete
    ];
    public exclIds = [];
    /** only filter by isControlledByParentLot if it is true - null value will be ignored in filter  */
    public readonly isControlledByParentLot = new ComputedProperty(() => {
        return this.data?.descendantBuildProgrammeActivity?.isControlledByParentLot ? true : null;
    });

    constructor(
        public readonly dialogRef: MatDialogRef<EditPredecessorBuildProgrammeActivityDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(LotBuildProgrammeEventService) private readonly lotBuildProgrammeEvents: LotBuildProgrammeEventService,
    ) { }

    public ngOnInit(): void {
        this.exclIds.push(this.data.descendantBuildProgrammeActivity.id);
        if (this.data.oldPredecessorBuildProgrammeActivity) {
            this.dateCalcType = this.data.oldPredecessorBuildProgrammeActivity.dateCalcType;
        }
        this.isControlledByParentLot.recompute();
    }

    public save(): void {
        this.data.descendantBuildProgrammeActivity
            .updateRelationship({
                dateCalcType: this.dateCalcType,
                descendantActivityId: this.data.descendantBuildProgrammeActivity.id,
                newPredecessorActivityId: this.newPredecessorBuildProgrammeActivity.id,
                oldPredecessorActivityId:
                    this.data.oldPredecessorBuildProgrammeActivity ? this.data.oldPredecessorBuildProgrammeActivity.id : undefined
            })
            .subOnce(result => this.dialogRef.close(result));
    }

    public itemLabel = (item: IBuildProgrammeActivityLookupDto): string => {
        let label = item?.label ?? '';
        if (item && item.lotId !== this.lotBuildProgrammeEvents?.lotId) {
            label = `<strong>${LOT_TYPE_ENUM[item.lotType]} Lot ${item.lotNumber}</strong> - ${label}`;
        } else {
            label = `<strong>Current Lot ${item.lotNumber}</strong> - ${label}`;
        }
        return label;
    };

    public cancel(): void {
        this.dialogRef.close();
    }

}
