import { Component, Input, OnChanges } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { WipDocument, WipFavoriteView, WipSearchDto, WipService } from '@app/logic/wip/wip.service';
import { CbTruncateModule } from '@app/shared/pipe/truncate/truncate.module';
import { finalize } from 'rxjs/operators';
import { environment } from '@src/environments/environment';
import { WipConstants } from '@app/views/wip/helpers/wipConstants';
import { CbSelectListModule } from 'cb-hub-lib';
import { IUserDto } from '@app/logic/users';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { SelectListItem } from '@app/core/services/enum/enum.service';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
    selector: 'cb-wip-table',
    templateUrl: './wip-table.component.html',
    styleUrls: ['./wip-table.component.scss'],
    standalone: true,
    imports: [MatTableModule, CommonModule, CbTruncateModule, CbSelectListModule, MatIconModule,
        MatLegacyOptionModule, MatLegacySelectModule, ReactiveFormsModule, CbSelectListModule, MatToolbarModule]
})
export class WipTableComponent implements OnChanges {
    @Input() selectedView: WipFavoriteView;
    @Input() buildingConsultants: IUserDto[];
    @Input() businessEntitiesDict: { [id: number]: string };
    @Input() projects: string[];
    @Input() contractTypes: SelectListItem[];

    public filter = {
        projectNames: new FormControl<string>(''),
        businessEntities:new FormControl<number[]>([]),
        contractTypes:new FormControl<number[]>([]),
        buildingConsultants: new FormControl<string[]>([]),
    };

    public displayedColumns: string[] = WipConstants.displayedColumns;
    public maxLength = 20;
    public maxProjectLength = 25;
    public currentPage = 1;
    public dataSource: WipDocument[] = [];
    private hasMoreDocuments = true;
    private isLoading = false;
    private readonly pageSize = 100;
    private readonly preLoadDistance = 400;

    constructor(private wipService: WipService) {
    }

    private InitComponent(): void {
        if (this.areAllInputsAvailable()) {
            this.clearAllFilters();
            this.reloadTable();
        }
    }

    private reloadTable(): void {
        this.displayedColumns = this.selectedView.columns.filter(x => WipConstants.displayedColumns.includes(x));
        this.dataSource = [];
        this.currentPage = 1;
        this.hasMoreDocuments = true;
        this.isLoading = false;
        this.fetchWipDocuments();
    }

    public ngOnChanges(): void {
        this.InitComponent();
    }

    public onScroll(event: Event): void {
        const target = event.target as HTMLElement;
        const position = target.scrollTop + target.clientHeight;
        const maxScroll = target.scrollHeight;

        if (position >= maxScroll - this.preLoadDistance && !this.isLoading && this.hasMoreDocuments) {
            this.fetchWipDocuments();
        }
    }

    private areAllInputsAvailable(): boolean {
        return this.selectedView != null &&
            this.buildingConsultants != null &&
            this.businessEntitiesDict != null &&
            this.projects != null &&
            this.contractTypes != null;
    }

    private fetchWipDocuments(): void {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        const searchDto: WipSearchDto = {
            currentPage: this.currentPage,
            viewId: this.selectedView.id,
            buildingConsultants: this.filter.buildingConsultants.value,
            businessEntities: this.filter.businessEntities.value,
            projectName:  this.filter.projectNames.value,
            contractTypes: this.filter.contractTypes.value,
        };

        this.wipService.loadMoreWipDocuments(searchDto).pipe(
            finalize(() => this.isLoading = false)
        ).subscribe({
            next: (documents) => {
                this.dataSource = [...this.dataSource, ...documents];
                this.hasMoreDocuments = documents.length === this.pageSize;
                if (this.hasMoreDocuments) {
                    this.currentPage++;
                }
            },
            error: (error) => {
                console.error('Failed to fetch WIP documents', error);
            }
        });
    }

    public resetAllFilters(): void {
        this.clearAllFilters();
        this.reloadTable();
    }

    private clearAllFilters() {
        this.filter.projectNames.setValue('');
        this.filter.businessEntities.setValue([]);
        this.filter.contractTypes.setValue([]);
        this.filter.buildingConsultants.setValue([]);
    }

    public onJobNumberClick(id: string): void {
        const url = `${environment.uri}/lots/${id}/summary`;
        const newTab = window.open(url, '_blank');

        if (newTab) {
            newTab.focus();
        }
    }

    public onSelectionChange(): void {
        this.reloadTable();
    }

    public getBusinessEntitiesNames(): string[] {
        if (this.businessEntitiesDict) {
            return Object.keys(this.businessEntitiesDict);
        }
        return [];
    }


    public getBusinessEntitiesName(businessEntityId: number): string {
        if (this.businessEntitiesDict) {
            return this.businessEntitiesDict[businessEntityId];
        }
        return '';
    }
}
