import { ITagMappedItem } from './interfaces/i.tag.mapped';
import { BaseMappedItem } from '../base/base-mapped-item';
import { ITagsLogicService } from './interfaces/i.tags.logic.service';
import { DtoProp } from '../base/dto-prop.decorator';
import { ITagDto } from '@classictechsolutions/hubapi-transpiled-enums';

export class TagMappedItem
    extends BaseMappedItem<ITagDto, ITagMappedItem, ITagsLogicService>
    implements ITagMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public name: string;
    @DtoProp public key: string;
    @DtoProp public members: number;
    @DtoProp public isActive: boolean;
    @DtoProp public isSystemTag: boolean;

    constructor(
        sourceData: ITagDto,
        logicService: ITagsLogicService
    ) {
        super(sourceData, logicService, TagMappedItem);
    }
}
