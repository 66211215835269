import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeOptionLineLogicService } from '@app/logic/change-option-line/change-option-line.logic.service';
import { IChangeOptionLineMappedItem } from '@app/logic/change-option-line/interfaces/i.change-option-line.mapped';
import { IChangeOptionMappedItem } from '@app/logic/change-option/interfaces/i.change-option.mapped';
import { IChangeRecordMappedItem } from '@app/logic/change-records/interfaces/i.change-record.mapped';
import { ISpecGroupDto, SpecGroupsLogicService } from '@app/logic/spec-groups';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { FormMode } from '@app/shared/enums/form';
import { ChangeDirectionEnumId, CHANGE_DIRECTION_ENUM, CHANGE_STATUS_ENUM, COST_TYPE_ENUM, IChangeOptionLineDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { orderBy } from 'lodash';
import { ChangeOptionLineDialogComponent } from '../change-option-line-dialog/change-option-line-dialog.component';

@Component({
    selector: 'cb-schedule-items-add-credit-table',
    templateUrl: './schedule-items-add-credit-table.component.html',
    styleUrls: ['./schedule-items-add-credit-table.component.scss']
})
export class ScheduleItemsAddCreditTableComponent implements OnInit {

    @Input() public changeRecordMappedItem: IChangeRecordMappedItem;
    @Input() public changeOptionMappedItem: IChangeOptionMappedItem;
    @Input() public parentForm: UntypedFormGroup;

    public COST_TYPE_ENUM = COST_TYPE_ENUM;
    public CHANGE_DIRECTION_ENUM = CHANGE_DIRECTION_ENUM;

    public houseAreas: ISpecGroupDto[];

    public sumCreditAmounts = 0;
    public sumAddAmounts = 0;

    public readonly addDisplayedColumns = [
        {
            label: 'House Area',
            width: 150
        }
        ,
        {
            label: 'Item',
            width: 300
        },
        {
            label: 'Product Code',
            width: 150
        }
        ,
        {
            label: 'Product/Description',
            width: 300
        }
        ,
        {
            label: 'Qty',
            width: 50
        }
        ,
        {
            label: 'Add/Update',
            width: 150
        }
        ,
        {
            label: 'Amount',
            width: 100
        }
        ,
        {
            label: 'Cost Type',
            width: 150
        }
        ,
        {
            label: 'QRef',
            width: 100
        }
        ,
        {
            label: '',
            width: 50
        }
    ];

    public readonly creditDisplayedColumns = [
        {
            label: 'House Area',
            width: 150
        }
        ,
        {
            label: 'Item',
            width: 300
        },
        {
            label: 'Product Code',
            width: 150
        }
        ,
        {
            label: 'Product/Description',
            width: 300
        }
        ,
        {
            label: 'Qty',
            width: 50
        }
        ,
        {
            label: 'Credit/Update',
            width: 150
        }
        ,
        {
            label: 'Amount',
            width: 100
        }
        ,
        {
            label: 'Cost Type',
            width: 150
        }
        ,
        {
            label: 'QRef',
            width: 100
        }
        ,
        {
            label: '',
            width: 50
        }
    ];

    constructor(
        private readonly cbDialog: CbDialogService,
        private readonly changeOptionLineLogicService: ChangeOptionLineLogicService,
        private readonly specGroupLogic: SpecGroupsLogicService
    ) {
        this.specGroupLogic.$getList().subOnce((results) => {
            this.houseAreas = orderBy(results, 'sortOrder', 'asc');
        });
    }

    public ngOnInit(): void {
        this.calculateSumAmounts();
    }


    public addLineItem(changeDirection: ChangeDirectionEnumId): void {

        const _excludedSlotIds = this.changeOptionMappedItem.changeOptionLines?.map(x => x?.slotDto?.id);

        this.cbDialog
            .open(ChangeOptionLineDialogComponent, {
                data: {
                    houseAreas: this.houseAreas,
                    lotId: this.changeRecordMappedItem.lotId,
                    formMode: FormMode.Add,
                    mappedItem: this.changeOptionLineLogicService.$createMappedItem({ changeDirection }),
                    excludedSlotIds: _excludedSlotIds
                },
            }).afterClosed()
            .subOnce(result => {
                if (result) {
                    this.changeOptionMappedItem.changeOptionLines = this.changeOptionMappedItem.changeOptionLines || [];

                    // Add Item to Add Table
                    this.changeOptionMappedItem.changeOptionLines.push(result.changeOptionLineDto);

                    // Add Existing Slot to items to Credit Table
                    if (result.itemToCredit && changeDirection === CHANGE_DIRECTION_ENUM.Add) {
                        this.changeOptionMappedItem.changeOptionLines.push(result.itemToCredit);
                    }

                    this.calculateSumAmounts();

                    this.parentForm.markAsDirty();

                }
            });
    }

    public editLineItem(changeOptionLine: IChangeOptionLineDto): void {
        const _excludedSlotIds = this.changeOptionMappedItem.changeOptionLines?.map(x => x?.slotDto?.id);

        this.cbDialog
            .open(ChangeOptionLineDialogComponent, {
                data: {
                    houseAreas: this.houseAreas,
                    lotId: this.changeRecordMappedItem.lotId,
                    formMode: FormMode.Edit,
                    mappedItem: this.changeOptionLineLogicService.$createMappedItem(changeOptionLine),
                    excludedSlotIds: _excludedSlotIds
                },
            }).afterClosed()
            .subOnce(result => {
                if (result) {

                    const indexOfExistingchangeOptionLine = this.changeOptionMappedItem.changeOptionLines.indexOf(changeOptionLine);

                    // Replace the existing item with Edited Item
                    this.changeOptionMappedItem.changeOptionLines.splice(indexOfExistingchangeOptionLine, 1, result.changeOptionLineDto);

                    this.calculateSumAmounts();

                    this.parentForm.markAsDirty();

                }
            });

    }

    public removeOptionLine(changeOptionLine: IChangeOptionLineDto): void {

        const indexOfChangeOptionLineToDelete = this.changeOptionMappedItem.changeOptionLines.indexOf(changeOptionLine);

        if (indexOfChangeOptionLineToDelete > -1) {
            this.changeOptionMappedItem.changeOptionLines.splice(indexOfChangeOptionLineToDelete, 1);

            if (changeOptionLine.id > 0) {
                this.changeOptionMappedItem.changeOptionLinesToDelete.push(changeOptionLine.id);
            }

            this.parentForm.markAsDirty();
        }

        // If the item exists in the item to credit table, remove it from there as well
        const itemsToCredit = this.changeOptionMappedItem.changeOptionLines.filter(line => {
            return line.changeDirection !== changeOptionLine.changeDirection && line.slotDto.id === changeOptionLine.slotDto.id;
        });

        if (itemsToCredit && itemsToCredit.length === 1 && itemsToCredit[0]) {
            const itemToCreditIndex = this.changeOptionMappedItem.changeOptionLines.indexOf(itemsToCredit[0]);
            if (itemToCreditIndex > -1) {
                this.changeOptionMappedItem.changeOptionLines.splice(itemToCreditIndex, 1);
                if (itemsToCredit[0]?.id > 0) {
                    this.changeOptionMappedItem.changeOptionLinesToDelete.push(itemsToCredit[0].id);
                }
                this.parentForm.markAsDirty();
            }
        }

        this.calculateSumAmounts();

    }


    public get isActionMenuDisabled(): boolean {
        return this.changeRecordMappedItem.changeStatus !== CHANGE_STATUS_ENUM.InProgress;
    }

    public getQRef(optionLine: IChangeOptionLineDto): string {
        if (optionLine.quoteLineId > 0) {
            return 'Q' + optionLine.quoteLineId;
        } else {
            return null;
        }
    }

    public isQuoted(optionLine: IChangeOptionLineDto): boolean {
        return optionLine != null && optionLine.quoteLineId > 0;
    }

    public canRemoveCreditLineItem(optionLine: IChangeOptionLineDto): boolean {
        // If the slotId is present in the "items to add" table then you should not be allowed to remove the item from "items to credit"
        if (this.changeOptionMappedItem.changeOptionLines.filter(e => e.slotDto.id === optionLine.slotDto.id
            && e.changeDirection === CHANGE_DIRECTION_ENUM.Add).length > 0) {
            return false;
        } else {
            return true;
        }
    }

    public getRemoveButtonText(optionLine: IChangeOptionLineDto): string {

        const changeDirection = CHANGE_DIRECTION_ENUM[optionLine.changeDirection];

        return this.isQuoted(optionLine) ? 'Abandon Quote Line + Remove' : `Remove ${changeDirection}ed Item`;
    }

    public getEditButtonText(optionLine: IChangeOptionLineDto): string {
        const changeDirection = CHANGE_DIRECTION_ENUM[optionLine.changeDirection];

        return this.isQuoted(optionLine) ? ' Edit Quoted Item' : `Edit ${changeDirection}ed Item`;
    }

    public getAddOrUpdateText(optionLine: IChangeOptionLineMappedItem): string {
        // check for an item that is in the same slot
        // and has a different change direction (item to add/credit)
        const foundLine = this.changeOptionMappedItem.changeOptionLines.filter(obj => {
            const line = (obj as IChangeOptionLineDto);
            return line.changeDirection !== optionLine.changeDirection && line.slotDto.id === optionLine.slotDto.id;
        });

        if (optionLine.changeDirection === CHANGE_DIRECTION_ENUM.Credit) {
            return (foundLine.length > 0 || optionLine.itemToCredit?.slotDto?.id === optionLine.slotDto.id) ? 'Update' : 'Credit';
        } else {
            return (foundLine.length > 0 || optionLine.itemToCredit?.slotDto?.id === optionLine.slotDto.id) ? 'Update' : 'Add';
        }
    }

    public filterChangeDirection(changeDirection: number) {
        return (optionLine) => {
            return optionLine.changeDirection === changeDirection;
        };
    }

    public calculateSumAmounts(): void {
        this.sumCreditAmounts = 0;
        this.sumAddAmounts = 0;
        this.changeOptionMappedItem.changeOptionLines?.forEach(optionLine => {
            if (this.filterChangeDirection(CHANGE_DIRECTION_ENUM.Credit)(optionLine)) {
                if (optionLine.costAmount) {
                    this.sumCreditAmounts += optionLine.costAmount;
                }
            } else if (this.filterChangeDirection(CHANGE_DIRECTION_ENUM.Add)(optionLine)) {
                if (optionLine.costAmount) {
                    this.sumAddAmounts += optionLine.costAmount;
                }
            }
        });

    }
}
