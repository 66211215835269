import { Component, Input } from '@angular/core';
import { LotsLogicService } from '@app/logic/lots';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ViewHouseLandDialogComponent } from '@app/views/house-land/dialogs';
import { ILotDocumentDto, LOT_CONTRACT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { environment } from '@src/environments/environment';

@Component({
    selector: 'cb-house-and-land-card',
    templateUrl: './house-and-land-card.component.html',
    styleUrls: ['./house-and-land-card.component.scss']
})
export class HouseAndLandCardComponent {

    @Input() public lot: ILotDocumentDto;
    private apiUrl: string = environment.api;
    public LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;

    constructor(
        private readonly cbDialog: CbDialogService,
        public readonly lotsLogicService: LotsLogicService) { }

    public getDisplayImage(lot: ILotDocumentDto): string {
        if (lot.posterImage) {
            return `${this.apiUrl}/lots/${lot.id}/images/${lot.posterImage}?w=600&h=400&scale=canvas`;
        } else {
            return 'assets/img/no-image-placeholder.png?w=600&h=400&scale=canvas';
        }
    }

    public viewLot(lot: ILotDocumentDto): void {
        const blockRef = this.cbDialog.block('Please wait, loading...');

        this.lotsLogicService.$getItem(lot.id).subOnce((lotLogicItem) => {

            const lotMappedItem = this.lotsLogicService.$createMappedItem(lotLogicItem);

            blockRef.close();

            this.cbDialog
                .open(
                    ViewHouseLandDialogComponent,
                    {
                        data: { mappedItem: lotMappedItem, canCreateLead: true, canAddExistingLead: true },
                        minWidth: '60%',
                    }
                );
        }
        );
    }

}
