import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IBuildProgrammeIndexedDto } from '@app/logic/build-programme';
import {
    IBuildProgrammeActivityLogicService, IBuildProgrammeActivityMappedItem
} from '@app/logic/build-programme-activity';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { DateDurationCalculatorComponent } from '@app/shared/components/date-duration-calculator/date-duration-calculator.component';
import { IBuildProgrammeActivityDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { IBuildProgrammeActivityRelationshipsDto } from '@classictechsolutions/hubapi-transpiled-enums/build/module';

interface IData {
    buildProgrammeActivity: IBuildProgrammeActivityMappedItem;
    buildProgramme: IBuildProgrammeIndexedDto;
}

@Component({
    templateUrl: './edit-build-programme-activity-dialog.component.html',
    styleUrls: ['./edit-build-programme-activity-dialog.component.scss']
})
export class EditBuildProgrammeActivityDialogComponent
    extends BaseDialogFormViewDirective<IBuildProgrammeActivityDto, IBuildProgrammeActivityMappedItem, IBuildProgrammeActivityLogicService>
    implements OnInit {
    public static readonly MIN_WIDTH = '60%';

    public get mappedItem(): IBuildProgrammeActivityMappedItem {
        return this.data.buildProgrammeActivity;
    }
    public activityRelationships: IBuildProgrammeActivityRelationshipsDto;
    public dateCalculating = false;

    constructor(
        public readonly dialogRef: MatDialogRef<EditBuildProgrammeActivityDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
    ) {
        super(dialogRef, toastService);
    }

    public ngOnInit(): void {
        this.mappedItem
            .getRelationships()
            .subOnce(result => this.activityRelationships = result);
    }

    public durationHasChanged(newDuration: number): void {
        this.mappedItem.activityDurationDays = newDuration;
    }

    public handleSaveSuccess(): void {
        this.dialogRef.close(this.mappedItem);
    }

    public isDurationValid(): boolean {
        return this.mappedItem.activityDurationDays >= DateDurationCalculatorComponent.MinimumDuration &&
            this.mappedItem.activityDurationDays <= DateDurationCalculatorComponent.MaximumDuration;
    }

    public isDisabled(editBuildProgrammeActivityForm: NgForm): boolean {
        return this.saveInProgress ||
            this.dateCalculating ||
            editBuildProgrammeActivityForm.invalid ||
            editBuildProgrammeActivityForm.pristine ||
            !this.isDurationValid();
    }
}
