import { Subject } from 'rxjs';
import { IBuildProgrammeIndexedDto, IBuildProgrammeActivitySummaryDto } from '@app/logic/build-programme/interfaces/i.build-programme-indexed.dto';
import { IBuildProgrammeActivityDto, IBuildProgrammeStageIndexedDto } from '@classictechsolutions/hubapi-transpiled-enums';

export abstract class LotBuildProgrammeEvents {
    public readonly BP_RECEIVED = new Subject<IBuildProgrammeIndexedDto>();
    public readonly STAGE_RECEIVED = new Subject<IBuildProgrammeStageIndexedDto[]>();
    public readonly ACTIVITY_RECEIVED = new Subject<IBuildProgrammeActivityDto[]>();
    public readonly SUMMARY_RECEIVED = new Subject<IBuildProgrammeActivitySummaryDto[]>();
    /** buildStageId */
    public readonly STAGE_REQUIRES_UPDATE = new Subject<number>();
}
