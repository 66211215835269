<div class="start-center flex">
    <h3 class="mat-title">
        Build Contingency Configurations
    </h3>
</div>
<div class="flex-row flex start-end">
    <span>
        A Notification will be generated when a Manual Order is Confirmed and the following criteria are met
    </span>
    <span class="flex"></span>
    <button mat-raised-button
            (click)="newItemClicked();"
            color="primary"
            [disabled]="!mappedItem?.id">
        Add New Build Contingency Configuration
    </button>
</div>
<cb-table class="cb-margin-top"
          [loaded]="true"
          [stringColumns]="columns"
          [maxHeightOffset]="309">
    <tr cbTableRow
        *ngFor="let result of searchResults">
        <cb-td-enum [enum]="NOTIFIABLE_VALUE_TYPE_ENUM"
                    [value]="result.valueTypeId"></cb-td-enum>
        <cb-td-enum [enum]="NOTIFIABLE_VALUE_SCALE_ENUM"
                    [value]="result.scaleId"></cb-td-enum>
        <cb-td-enum [enum]="NOTIFIABLE_VALUE_DIRECTION_ENUM"
                    [value]="result.valueDirectionId"></cb-td-enum>
        <cb-td-text [value]="result.amount"></cb-td-text>
        <td cbTableStickyCol="end"
            cbTableShrinkCol>
            <button mat-icon-button
                    (click)="editItemClickedForDto(result)">
                <mat-icon>
                    edit
                </mat-icon>
            </button>
            <button class="cb-margin-left"
                    mat-icon-button
                    (click)="deleteClicked(result)">
                <mat-icon>
                    delete
                </mat-icon>
            </button>
        </td>
    </tr>
</cb-table>
