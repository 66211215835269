import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { ILeadLogicService } from './interfaces/i.lead-logic.service';
import { ILeadDto } from './interfaces/i.lead.dto';
import { ICreateLeadDto } from './interfaces/i.create-lead.dto';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { ILeadSearchParams } from './interfaces/i.lead-search-params';
import { cleanObjectDeep } from 'cb-hub-lib';
import { cloneDeep } from 'lodash';
import { ILeadSearchDto } from './interfaces/i.lead-search.dto';
import { ILeadMappedItem } from './interfaces/i.lead.mapped';
import { LeadMappedItem } from './lead.mapped';
import { ILeadLotInterestDto } from './interfaces/i.lead.lot.interest.dto';
import { ILeadLostVMDto } from './interfaces/i.lead.lost.vm.dto';
import { IQualifyLeadDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/QualifyLeadDto';

@Injectable()
export class LeadLogicService extends BaseLogicService<ILeadDto, ILeadMappedItem> implements ILeadLogicService {
    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('leads', LeadMappedItem);
    }

    public createlead(dto: ICreateLeadDto): Observable<ILeadDto> {
        return this.$http.post(`${this.$baseUri}/newcreate`, dto);
    }

    public search(params: ILeadSearchParams): Observable<ISearchResult<ILeadSearchDto>> {
        return this.$http.get<ISearchResult<ILeadSearchDto>>(`${this.$baseUri}/search`, cleanObjectDeep(cloneDeep(params)));
    }

    public $getSearchList(params: ILeadSearchParams): Observable<ISearchResult<ILeadSearchDto>> {
        return this.search(params);
    }

    public addContactToLead(contactId: string, leadId: number): Observable<ILeadDto> {
        return this.$http.post<ILeadDto>(`${this.$baseUri}/${leadId}/contacts/${contactId}`);
    }

    public setLeadContactAsMainContact(leadId: number, contactId: string): Observable<ILeadDto> {
        return this.$http.post<ILeadDto>(`${this.$baseUri}/${leadId}/contacts/${contactId}/setasmaincontact`);
    }

    public setPrimaryLotInterest(leadId: number, interestId: number): Observable<ILeadDto> {
        return this.$http.post<ILeadDto>(`${this.$baseUri}/${leadId}/interest/${interestId}/setasprimary`);
    }

    public removeContactFromLead(leadId: number, contactId: string): Observable<ILeadDto> {
        return this.$http.delete<ILeadDto>(`${this.$baseUri}/${leadId}/contacts/${contactId}`);
    }

    public removeInterest(leadId: number, interestId: number): Observable<ILeadDto> {
        return this.$http.delete<ILeadDto>(`${this.$baseUri}/${leadId}/interest/${interestId}`);
    }

    public addUpdateInterest(leadId: number, interest: ILeadLotInterestDto): Observable<ILeadDto> {
        return this.$http.post<ILeadDto>(`${this.$baseUri}/${leadId}/interest`, interest);
    }

    public lost(data: ILeadLostVMDto, id: number): Observable<ILeadDto> {
        return this.$http.post<ILeadDto>(`${this.$baseUri}/${id}/lost`, data);
    }

    public qualify(leadId: number, data: IQualifyLeadDto): Observable<ILeadDto> {
        return this.$http.post<ILeadDto>(`${this.$baseUri}/${leadId}/qualify`, data);
    }

    public manuallyUpdateStatus(leadDto: ILeadDto): Observable<ILeadDto> {
        return this.$http.post<ILeadDto>(`${this.$baseUri}/${leadDto.id}?ismanualstatusupdate=true`, leadDto);
    }

    public recalculateAllLeadFlags(): Observable<void> {
        return this.$http.post(`${this.$baseUri}/resetflags`);
    }
}
