<cb-dialog dialogHeading="Close {{taskTypeLabel}}">
    <form class="flex-col flex"
          #taskForm="ngForm">
        <label>Outcome</label>
        <mat-radio-group class="cb-margin-top flex-col"
                         name="closetask-outcome-followup"
                         [(ngModel)]="data.task.outcomeId">
            <mat-radio-button *ngFor="let option of outcomes | async"
                              [value]="option.id">{{option.name}}</mat-radio-button>
        </mat-radio-group>
        <mat-form-field class="cb-margin-top flex">
            <textarea matInput
                      name="close-task-dialog-outcome-notes"
                      type="text"
                      placeholder="Outcome Notes"
                      [maxlength]="1000"
                      required
                      [(ngModel)]="data.task.outcomeNotes"></textarea>
        </mat-form-field>
        <cb-checkbox [(ngModel)]="scheduleFollowUp"
                     name="closetask-schedule-followup"
                     label="Schedule follow-up?"
                     *ngIf="data.allowFollowUp && !data.task.isCallback"></cb-checkbox>
        <div class="flex-col"
             *ngIf="scheduleFollowUp || data.task.isCallback">
            <span class="mat-body-2"
                  *ngIf="data.task.isCallback">Next {{taskTypeLabel}}</span>
            <cb-datepicker name="closeTaskDialogFollowupDate"
                           [(ngModel)]="followUpTask.dueDate"
                           [required]="true"
                           label="Choose a date"></cb-datepicker>
            <cb-input type="text"
                      name="task-dialog-subject"
                      label="Subject"
                      [required]="true"
                      [(ngModel)]="followUpTask.subject"></cb-input>
            <cb-input *ngIf="data.task.isMeeting"
                      type="text"
                      name="task-dialog-location"
                      label="Location"
                      [required]="true"
                      [(ngModel)]="followUpTask.location"></cb-input>
            <mat-form-field class="flex">
                <textarea matInput
                          name="close-task-dialog-outcome-notes"
                          type="text"
                          placeholder="Follow-up Comments"
                          [maxlength]="1000"
                          required
                          [(ngModel)]="followUpTask.comments"></textarea>
            </mat-form-field>
        </div>
    </form>
    <footer>
        <button mat-raised-button
                color="primary"
                [disabled]="!taskForm.dirty || !taskForm.valid"
                (click)="save()">Close Task</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
