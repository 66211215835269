<cb-dialog dialogHeading="{{getDialogHeader()}}">
    <form class="flex-col"
          #standardPlanForm="ngForm">
        <mat-dialog-content>
            <mat-form-field [class.no-line]="isView()">
                <input matInput
                       type="text"
                       name="planName"
                       placeholder="Plan Name"
                       required
                       [(ngModel)]="data.plan.name"
                       [disabled]="isView()"/>
            </mat-form-field>
            <mat-form-field [class.no-line]="isView()">
                <textarea #description
                          matInput
                          type="text"
                          name="description"
                          placeholder="Plan Description"
                          [maxlength]="1000"
                          [(ngModel)]="data.plan.description"
                          [disabled]="isView()">
                </textarea>
                <mat-hint *ngIf="isEdit()"
                          align="end">{{description.value.length}} / 1000</mat-hint>
            </mat-form-field>
            <div class="image-tabs"
                 *ngIf="data.plan && data.plan.stylesWithUrl && !isAdd()">
                <mat-tab-group>
                    <mat-tab *ngFor="let style of data.plan.stylesWithUrl"
                             label="{{style.name}}">
                        <div class="flex-row flex">
                            <img #planImage
                                 mat-card-image
                                 src="{{style.url}}?w=600&h=400&scale=canvas"
                                 cbHandleInvalidImage
                                 [height]="400"
                                 [width]="600"
                                 alt="Plan Image"/>
                            <div class="bottom-right end-center flex-row">
                                <button *ngIf="!style.isPlaceHolderImage"
                                        mat-raised-button
                                        (click)="viewImage(style)"
                                        class="button-right-8px">
                                    <mat-icon>search</mat-icon>
                                    &nbsp;View Image
                                </button>
                                <button mat-raised-button
                                        class="button-right-8px"
                                        (click)="uploadImage(style)"
                                        *ngIf="isEdit() && !style.isPlaceHolderImage">
                                    <mat-icon>cloud_upload</mat-icon>
                                    &nbsp;Change Image
                                </button>
                                <button mat-raised-button
                                        class="button-right-8px"
                                        (click)="uploadImage(style)"
                                        *ngIf="isEdit() && style.isPlaceHolderImage">
                                    <mat-icon>cloud_upload</mat-icon>
                                    &nbsp;Upload Image
                                </button>
                                <button mat-raised-button
                                        class="button-right-8px"
                                        (click)="deleteImage(style)"
                                        *ngIf="isEdit() && !style.isPlaceHolderImage">
                                    <mat-icon>delete</mat-icon>
                                    &nbsp;Delete Image
                                </button>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <mat-tab-group>
                <mat-tab label="Plan Details">
                    <div class="flex-row flex">
                        <cb-standard-plan-details-view class = "flex"
                                                       [plan]="data.plan"
                                                       *ngIf="isView()"></cb-standard-plan-details-view>
                        <cb-standard-plan-details-edit class = "flex"
                                                       [plan]="data.plan"
                                                       *ngIf="isEdit() || isAdd()">
                        </cb-standard-plan-details-edit>
                    </div>
                </mat-tab>
                <mat-tab label="Costs"
                         *ngIf="data.plan && data.addressRegions && data.plan.planCosts ">
                    <div class="flex-row flex">
                        <cb-standard-plan-costs class= "flex"
                                                [plan]="data.plan"
                                                [addressRegions]="data.addressRegions"
                                                [formMode]="data.formMode"></cb-standard-plan-costs>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-dialog-content>
    </form>
    <footer class="flex-row">
        <button mat-raised-button
                *ngIf="isView()"
                (click)="duplicate()">Duplicate</button>
        <button mat-raised-button
                *ngIf="isEdit()"
                (click)="deleteStandardPlan()">Delete</button>
        <div class="flex"></div>
        <button *ngIf="isView()"
                mat-raised-button
                (click)="edit()">Edit</button>
        <button *ngIf="isView()"
                mat-raised-button
                (click)="close()">Close</button>
        <button *ngIf="isEdit() || isAdd()"
                [disabled]=" (!standardPlanForm.dirty && isEdit()) || !standardPlanForm.valid"
                mat-raised-button
                (click)="save()">Save</button>
        <button *ngIf="isEdit() || isAdd()"
                mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
