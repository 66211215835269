export class DateUtil {
    public static formatDateWithoutTimeZone(date: Date | string): string {
        const positionOfGmt = date.toString().indexOf('GMT');
        if (positionOfGmt > -1) {
            return date.toString().substring(0, positionOfGmt);
        }
        return date.toString();
    }
    public static formatUtcDateToDateWithLocalTimeZone(dateString: string): string {
        const utcStartDate = new Date(dateString);

        const localOffset = new Date().getTimezoneOffset();

        const localStartDate = new Date(utcStartDate.getTime() - (localOffset * 60 * 1000));

        return localStartDate.toISOString();
    }
    /*
    This function returns a new date as of Now, formatted in the same way that the server returns dates as strings.
    The format looks something like this 2024-09-11T11:31:56.944000+12:00
    It adds on the last 3 decimal places of the milliseconds because javascript does not actually have that level of precision
    but, it is consistent with server returned date strings.
     */
    public static formatDateToDateString(date): string {
        // Get timezone offset in minutes
        const offset = date.getTimezoneOffset();

        // Convert offset to hours and minutes (negative sign indicates ahead of UTC)
        const absOffset = Math.abs(offset);
        const hours = String(Math.floor(absOffset / 60)).padStart(2, '0');
        const minutes = String(absOffset % 60).padStart(2, '0');
        const timezone = `${offset <= 0 ? '+' : '-'}${hours}:${minutes}`;

        // Format date
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hoursStr = String(date.getHours()).padStart(2, '0');
        const minutesStr = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // Get milliseconds to 6 decimal places
        const ms = String(date.getMilliseconds()).padStart(3, '0') + '000'; // Adding extra '000' to ensure 6 decimal places

        return `${year}-${month}-${day}T${hoursStr}:${minutesStr}:${seconds}.${ms}${timezone}`;
    }
}
