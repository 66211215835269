<div class="flex-col flex-100">
    <span class="cb-margin-bottom">
        <b>Items to Add</b>
    </span>
    <cb-table label="Change Records"
              [stringColumns]="addCreditTableColumns"
              [loaded]="true"
              class="cb-margin-bottom-2x flex">
        <tr cbTableRow
            *ngFor="let optionLine of changeOptionMappedItem.changeOptionLines | cbFilter : 'changeDirection' : CHANGE_DIRECTION_ENUM.Add">
            <td>{{optionLine.specGroupName}}</td>
            <td>{{optionLine.quantity}}</td>
            <td>{{optionLine.selectedProduct?.name || optionLine.itemDetails}}</td>
            <td>{{optionLine.costAmount | cbCurrency }}</td>
            <cb-td-enum [value]="optionLine?.costType"
                        [enum]="COST_TYPE_ENUM">
            </cb-td-enum>
            <cb-td-checkbox [value]="optionLine.quoted"></cb-td-checkbox>
            <td>{{optionLine.comments }}</td>
            <td cbTableShrinkCol
                cbTableStickyCol="end">
                <button mat-icon-button
                        [matMenuTriggerFor]="addOptionMenu">
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #addOptionMenu="matMenu">
                    <button mat-menu-item
                            (click)="removeOptionLine(optionLine)">
                        <mat-icon>remove_circle</mat-icon>
                        Remove Item
                    </button>
                    <button mat-menu-item
                            (click)="editLineItem(optionLine)">
                        <mat-icon>edit</mat-icon>
                        Edit Item
                    </button>
                </mat-menu>
            </td>
        </tr>
        <tr cbTableRow>
            <td colspan="7">

            </td>
            <td cbTableShrinkCol
                cbTableStickyCol="end">
                <button mat-icon-button
                        (click)="addLineItem(CHANGE_DIRECTION_ENUM.Add)">
                    <mat-icon>add</mat-icon>
                </button>
            </td>
        </tr>
        <tr cbTableRow>
            <td colspan="3">

            </td>
            <td>
                <b>{{sumAddAmounts | cbCurrency}}</b>
            </td>
            <td colspan="4"></td>
        </tr>
    </cb-table>
    <span class="cb-margin-bottom"
          style="color:red;">
        <b>Items to Credit</b>
    </span>
    <cb-table label="Change Records"
              [stringColumns]="addCreditTableColumns"
              [loaded]="true"
              class="cb-margin-bottom-2x flex">
        <tr cbTableRow
            *ngFor="let optionLine of changeOptionMappedItem.changeOptionLines | cbFilter : 'changeDirection' : CHANGE_DIRECTION_ENUM.Credit">
            <td>{{optionLine.specGroupName}}</td>
            <td>{{optionLine.quantity}}</td>
            <td>{{optionLine.selectedProduct?.name || optionLine.itemDetails}}</td>
            <td>{{optionLine.costAmount | cbCurrency }}</td>
            <cb-td-enum [value]="optionLine?.costType"
                        [enum]="COST_TYPE_ENUM">
            </cb-td-enum>
            <cb-td-checkbox [value]="optionLine.quoted"></cb-td-checkbox>
            <td>{{optionLine.comments }}</td>
            <td cbTableShrinkCol
                cbTableStickyCol="end">
                <button mat-icon-button
                        [matMenuTriggerFor]="addOptionMenu">
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #addOptionMenu="matMenu">
                    <button mat-menu-item
                            (click)="removeOptionLine(optionLine)">
                        <mat-icon>remove_circle</mat-icon>
                        Remove Item
                    </button>
                    <button mat-menu-item
                            (click)="editLineItem(optionLine)">
                        <mat-icon>edit</mat-icon>
                        Edit Item
                    </button>
                </mat-menu>
            </td>
        </tr>
        <tr cbTableRow>
            <td colspan="7">

            </td>
            <td cbTableShrinkCol
                cbTableStickyCol="end">
                <button mat-icon-button
                        (click)="addLineItem(CHANGE_DIRECTION_ENUM.Credit)">
                    <mat-icon>add</mat-icon>
                </button>
            </td>
        </tr>
        <tr cbTableRow>
            <td colspan="3">

            </td>
            <td>
                <b>{{sumCreditAmounts | cbCurrency}}</b>
            </td>
            <td colspan="4"></td>
        </tr>
    </cb-table>
    <ng-content select="[netChange]"></ng-content>
    <span class="cb-margin-bottom">
        <b>Allocated Quote Lines</b>
    </span>
    <cb-table label="Allocated Quote Lines"
              [stringColumns]="allocatedLineTableColumns"
              [loaded]="true"
              class="cb-margin-bottom-2x flex">
        <tr cbTableRow
            *ngFor="let allocatedQuoteLine of changeOptionMappedItem.allocatedQuoteLines">
            <td>{{allocatedQuoteLine.reference}}</td>
            <cb-td-enum [value]="$any(allocatedQuoteLine)?.costType"
                        [enum]="COST_TYPE_ENUM"> </cb-td-enum>
            <td>{{allocatedQuoteLine.description}}</td>
            <td>{{allocatedQuoteLine.specGroupLabel}}</td>
            <td>{{allocatedQuoteLine.quantity}}</td>
            <td>{{$any(allocatedQuoteLine.offering).uomDisplay}}</td>
            <td>
                <b>{{allocatedQuoteLine.allocatedAmount | cbCurrency}}</b>
            </td>
        </tr>
    </cb-table>
</div>
