<cb-dialog dialogHeading="Generate Client Variation">
    <form class="flex-col"
          #submitChangesForPricingform="ngForm">
        <span class="cb-margin-bottom-2x">
            <b>Which accepted changes do you want to include in this Variation?</b>
        </span>
        <div class="cb-margin-bottom flex-row">
            <button mat-raised-button
                    class="cb-margin-right"
                    (click)="selectAll()">Select All</button>
            <button mat-raised-button
                    (click)="deSelectAll()">Deselect All</button>
        </div>
        <mat-dialog-content>
            <cb-table class="flex"
                      label="Change Records"
                      [stringColumns]="displayedColumns"
                      [loaded]="unassignedChangeRecordsLoaded">
                <tr cbTableRow
                    *ngFor="let change of unassignedChangeRecords">
                    <td>
                        <cb-checkbox class="checkbox-toggle"
                                     [name]="change.id + 'isSelected'"
                                     [(ngModel)]="selectedChangeRecordIds[change.changeRecordId]"
                                     (change)="checkboxClicked($event, change)">
                        </cb-checkbox>
                    </td>
                    <td>
                        {{ change.changeNumber }}
                    </td>
                    <td>{{change.changeRecordDetails}}</td>
                    <td>{{change.costChangeAmount | cbCurrency: 'addGst'}} (Inc Gst)</td>
                </tr>
            </cb-table>
        </mat-dialog-content>
        <footer class="end-center flex-row">
            <button color="primary"
                mat-raised-button
                [disabled]="isSaveDisabled()"
                [clickLimit]="1"
                (cbClick)="generateVariation()"
            >
                Create Variation
            </button>
            <button mat-raised-button
                    (cbClick)="cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
