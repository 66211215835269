import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IStandardPlanMappedItem } from '@app/logic/standard-plans';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { IStandardPlansSearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { StandardPlanLogicService } from '@app/logic/standard-plans/standard-plan.logic.service';
import { FormMode } from '@app/shared/enums/form';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { IAddressRegionDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { floorAreaSelect } from '../floor-area-types-constant';
import { StandardPlanDialogComponent } from '../standard-plan-dialog/standard-plan-dialog';

@Component({
    selector: 'cb-standard-plan-search',
    templateUrl: './standard-plan-search.component.html',
    styleUrls: ['./standard-plan-search.component.scss'],
})
export class StandardPlanSearchComponent implements OnInit, OnDestroy {

    @Input() public standardPlans: IStandardPlanMappedItem[];
    @Output() public standardPlansChange = new EventEmitter<IStandardPlanMappedItem[]>();
    @Input() public addressRegions: IAddressRegionDto[];

    public floorAreaTypes = floorAreaSelect;

    public costs: number[] = [];
    public areas: number[] = [];
    public costsAreasLoaded = false;

    public minCostLoaded = false;
    public maxCostLoaded = false;

    public minAreaLoaded = false;
    public maxAreaLoaded = false;

    public searchIsLoading = false;
    public noMoreResults = false;
    private currentPage = 0;
    public paramsSetupComplete = false;
    private readonly subscription$ = new Subscription();
    public searchParams: IStandardPlansSearch;

    public searchQueryUpdate = new Subject<string>();

    public filteredPlans = new ComputedProperty(
        () => this.standardPlans
    );

    constructor(
        private readonly standardPlanLogicService: StandardPlanLogicService,
        public readonly dialog: MatDialog,
        public readonly userCacheService: UserCacheService
    ) {
        // Debounce search.
        this.searchQueryUpdate.pipe(
            debounceTime(500),
            distinctUntilChanged())
            .subscribe(value => {
                this.doSearch();
            });

    }

    public ngOnInit(): void {
        this.initialiseData();
        this.initSearch();
    }

    public ngOnDestroy(): void {
        this.standardPlansChange.unsubscribe();
        this.subscription$.unsubscribe();
    }

    private readonly handleSearchResult = (results): void => {
        this.standardPlans.push.apply(this.standardPlans, results);
    };

    public initialiseData(): void {
        this.standardPlanLogicService
            .getMaxMinCostArea()
            .subOnce((result) => {
                this.costs = [Math.floor(result.costs[0]), Math.ceil(result.costs[1])];
                this.areas = [Math.floor(result.areas[0]), Math.ceil(result.areas[1])];
                this.costsAreasLoaded = true;
                this.minCostLoaded = true;
                this.maxCostLoaded = true;
                this.minAreaLoaded = true;
                this.maxAreaLoaded = true;
            });
    }

    public initSearch(): void {
        this.userCacheService.standardPlansSearch.init().then(() => {
            this.userCacheService.standardPlansSearch.silentData.canSeeAll = true;
            if (!this.userCacheService.standardPlansSearch.silentData.canSeeAll) {
                this.userCacheService
                    .standardPlansSearch
                    .silentData
                    .userId = this.userCacheService.standardPlansSearch.currentUserId;
            }
            this.queryUpdated();
            this.subscription$.add(
                this.userCacheService.standardPlansSearch.updated$.subscribe({
                    next: () => {
                        this.queryUpdated();
                    }
                })
            );
            this.paramsSetupComplete = true;
        });
    }

    public queryUpdated(): void {
        if (this.searchIsLoading) {
            return;
        }

        this.currentPage = 0;
        this.standardPlans = [];
        this.noMoreResults = false;
        this.doSearch();
    }

    public doSearch = (): void => {
        if (this.searchIsLoading || this.noMoreResults && this.currentPage > 0) {
            return;
        }

        if (this.currentPage === 0) {
            this.standardPlans = [];
        }

        this.currentPage++;
        this.searchParams = this.userCacheService.standardPlansSearch.data;
        if (this.searchParams.floorArea[0] === null) {
            this.searchParams.floorArea[0] = this.areas[0];
        }

        if (this.searchParams.floorArea[1] === null) {
            this.searchParams.floorArea[1] = this.areas[1];
        }

        if (this.searchParams.costToBuild[0] === null) {
            this.searchParams.costToBuild[0] = this.costs[0];
        }

        if (this.searchParams.costToBuild[1] === null) {
            this.searchParams.costToBuild[1] = this.costs[1];

        }

        this.searchIsLoading = true;
        this.standardPlanLogicService.searchItems(this.searchParams, this.currentPage, this.costs, this.areas).subOnce((results) => {
            if ((!results) || results.length === 0) {
                this.noMoreResults = true;
            }
            this.handleSearchResult(results);
            this.standardPlansChange.emit(this.standardPlans);
            this.searchIsLoading = false;
        });
    };

    public remove(prop: any): void {

        switch (prop) {
            case 'costToBuild[0]': {
                this.minCostLoaded = false;
                this.searchParams.costToBuild = [this.costs[0], this.searchParams.costToBuild[1]];
                this.minCostLoaded = true;
                break;
            }
            case 'costToBuild[1]': {
                this.maxCostLoaded = false;
                this.searchParams.costToBuild = [this.searchParams.costToBuild[0], this.costs[1]];
                this.maxCostLoaded = true;
                break;
            }
            case 'floorArea[0]': {
                this.minAreaLoaded = false;
                this.searchParams.floorArea = [this.areas[0], this.searchParams.floorArea[1]];
                this.minAreaLoaded = true;
                break;
            }
            case 'floorArea[1]': {
                this.maxAreaLoaded = false;
                this.searchParams.floorArea = [this.searchParams.floorArea[0], this.areas[1]];
                this.maxAreaLoaded = true;
                break;
            }
            default: {
                this.searchParams[prop] = null;
                break;
            }
        }

        this.doSearch();
    }

    public clearAll(): void {
        this.searchParams.costToBuild = this.costs;
        this.searchParams.floorArea = this.areas;
        this.searchParams.numberOfBathrooms = null;
        this.searchParams.numberOfBedrooms = null;
        this.searchParams.numberOfGarages = null;
        this.searchParams.numberOfLivingRooms = null;
        this.searchParams.query = null;

        this.doSearch();
    }

    public addStandardPlan = (): void => {

        const mappedItem = this.standardPlanLogicService.$createMappedItem();
        mappedItem.amenities = {
            bedrooms: null,
            bathrooms: null,
            living: null,
            garages: null
        };

        this.dialog.open(StandardPlanDialogComponent, {
            data: { plan: mappedItem, formMode: FormMode.Add, addressRegions: this.addressRegions },
            panelClass: 'cb-dialog-container',
            minWidth: 400,
            width: '600px',
        })
            .afterClosed()
            .subOnce(plan => {
                if (plan) {
                    this.standardPlans.unshift(plan);
                    this.filteredPlans.recompute();
                    this.openPlan(plan);
                }
            });

    };

    public openPlan(plan: IStandardPlanMappedItem): void {
        const dialogRef = this.dialog
            .open(
                StandardPlanDialogComponent,
                {
                    data: { standardPlans: this.standardPlans, plan: plan.$clone(), formMode: FormMode.Edit, addressRegions: this.addressRegions },
                    panelClass: 'cb-dialog-container',
                    minWidth: 400,
                    width: '600px',
                }
            );

        const subscription = dialogRef.componentInstance.onSave.subscribe((data) => {
            this.standardPlans = data;
        });

        dialogRef.afterClosed().subscribe(() => {
            subscription.unsubscribe();
        });
    }

    public isFiniteNumber(value: number): boolean {
        return Number.isFinite(value);
    }

    public anyFilterApplied(): boolean {
        return (this.searchParams.query !== '' && this.searchParams.query !== null) ||
            this.searchParams.numberOfBedrooms > 0 ||
            this.searchParams.numberOfBathrooms > 0 ||
            this.searchParams.numberOfLivingRooms > 0 ||
            this.searchParams.numberOfGarages > 0 ||
            this.showMinCostChip() ||
            this.showMaxCostChip() ||
            this.showMinAreaChip() ||
            this.showMaxCostChip();
    }


    public showMinCostChip(): boolean {
        return this.costsAreasLoaded &&
            this.minCostLoaded &&
            this.searchParams.costToBuild[0] !== this.costs[0] &&
            this.searchParams.costToBuild[0] > 0 &&
            this.isFiniteNumber(this.searchParams.costToBuild[0]);
    }

    public showMaxCostChip(): boolean {
        return this.costsAreasLoaded &&
            this.maxCostLoaded &&
            this.searchParams.costToBuild[1] !== this.costs[1] &&
            this.searchParams.costToBuild[1] > 0 &&
            this.isFiniteNumber(this.searchParams.costToBuild[1]);
    }

    public showMinAreaChip(): boolean {
        return this.costsAreasLoaded &&
            this.minAreaLoaded &&
            this.searchParams.floorArea[0] !== this.areas[0] &&
            this.searchParams.floorArea[0] > 0 &&
            this.isFiniteNumber(this.searchParams.floorArea[0]);
    }

    public showMaxAreaChip(): boolean {
        return this.costsAreasLoaded &&
            this.maxAreaLoaded &&
            this.searchParams.floorArea[1] !== this.areas[1] &&
            this.searchParams.floorArea[0] > 0 &&
            this.isFiniteNumber(this.searchParams.floorArea[1]);
    }


}
