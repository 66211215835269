<cb-dialog dialogHeading="Question Response">
    <form class="flex-col flex"
          #questionForm="ngForm">
        <cb-select *ngIf="data.childItems"
                   [label]="data.childItemLabel"
                   name="childItemSelect"
                   [(ngModel)]="mappedItem.childEntityId"
                   [options]="data.childItems"
                   [readonly]="true"></cb-select>
        <cb-display-value-new label="System Area"
                              [value]="SYSTEM_AREA_ENUM[mappedItem.systemArea]"></cb-display-value-new>
        <cb-display-value-new label="Requester"
                              [value]="mappedItem.createdByUser"></cb-display-value-new>
        <cb-text-area label="Request"
                      name="request"
                      [ngModel]="mappedItem.request"
                      [readonly]="true"></cb-text-area>
        <cb-text-area label="Response"
                      name="response"
                      [(ngModel)]="mappedItem.response"
                      [readonly]="data.readonly"
                      [required]="true"></cb-text-area>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                *ngIf="!data.readonly"
                color="primary"
                [disabled]="questionForm.invalid || questionForm.pristine"
                (cbClick)="save()"
                [clickLimit]="1">Save</button>
        <button mat-raised-button
                (click)="cancel()">{{ data.readonly ? 'Close' :'Cancel'}}</button>
    </footer>
</cb-dialog>
