import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule, OnDestroy } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration, MsalModule, MsalService
} from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { CoreModule } from './core/core.module';
import { LogicModule } from './logic/logic.module';
import { AppRootModule } from './views/app-root/app-root.module';
import { AppRootComponent } from './views/app-root/pages/app-root/app-root.component';
import { MatBadgeModule } from '@angular/material/badge';

import 'cb-hub-lib';
import { Subscription } from 'rxjs';
import { NZ_LOCALE } from './shared/declarations/app.constants';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

const ADAL_CLIENT_ID = '1c7bffac-f807-4d56-87fb-2feb8c03c967';
const ADAL_TENANT_ID = 'f79eb2be-b826-47a8-aef9-dabc8fc90a33';

export function loggerCallback(level: LogLevel, message: string): void {
    switch (level) {
        case LogLevel.Error:
            console.error(message);
            return;
        case LogLevel.Info:
            // eslint-disable-next-line no-console
            console.info(message);
            return;
        case LogLevel.Warning:
            console.warn(message);
            return;
    }
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: ADAL_CLIENT_ID,
            authority: 'https://login.microsoftonline.com/' + ADAL_TENANT_ID,
            redirectUri: '/',
            postLogoutRedirectUri: '/?isLogoutActioned=true'
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
            secureCookies: true
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false
            }
        }
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['user.read']
        },
        loginFailedRoute: '/login-failed'
    };
}


// TODO: Add Angular decorator.
@NgModule({
    imports: [
        MsalModule,

        // Default Modules
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        // Our Modules
        CoreModule,
        LogicModule,
        MatBadgeModule,
        AppRootModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: NZ_LOCALE },
        { provide: MAT_DATE_LOCALE, useValue: NZ_LOCALE },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        MsalService,
        MsalBroadcastService
    ],
    bootstrap: [AppRootComponent]
})
export class AppModule implements OnDestroy {

    private _subscriptions = new Subscription();

    constructor(
        private readonly matIconRegistry: MatIconRegistry,
        private readonly domSanitizer: DomSanitizer,
    ) {
        // load material design icons from dist/...
        this.matIconRegistry.addSvgIconSet(this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/mdi.svg'));
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }
}

