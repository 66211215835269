import { Component, OnInit } from '@angular/core';
import { IProductBundleProductDto, IRateDto, emptyAttribute } from '@app/logic/products/interfaces/i.product.dto';
import { IProductDto, IProductMappedItem, ProductLogicService } from '@app/logic/products';
import {
    IIdAndLabelDto,
    IOfferingBundleTemplateItemDto,
    OFFERING_STATUS_ENUM,
    PRODUCT_TYPE_ENUM,
    UNIT_OF_MEASURE_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { find, forEach } from 'lodash';

import { ActivatedRoute } from '@angular/router';
import { AddProductToBundleDialogComponent } from '../../dialogs/add-product-to-bundle-dialog/add-product-to-bundle-dialog.component';
import { AddProductToTemplatedBundleDialogComponent } from '../../dialogs/add-product-to-templated-bundle-dialog/add-product-to-templated-bundle-dialog.component';
import { AttributeOptionsDialogComponent } from '../../dialogs/attribute-options-dialog/attribute-options-dialog.component';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IProductAttributeDto } from '@app/logic/product-attributes/interfaces/i.product-attribute.dto';
import { ManageProductDialogComponent } from '@app/views/products/tabs/product-catalogue/product-catalogue-item-dialog/manage-product-dialog.component';
import { ManageRatesDialogComponent } from '../../dialogs/manage-rates-dialog/manage-rates-dialog.component';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ProductPermissions } from '@app/core/permissions';
import { RateCompositionDialogComponent } from '../../dialogs/rate-composition-dialog/rate-composition-dialog.component';
import { TableColumn } from '@app/shared/components/table/base-table.component';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { switchMap } from 'rxjs';

interface TRateRegion {
    rates: any[];
    region: { id: number; label: string };
}

@Component({
    selector: 'cb-product-details-tab',
    templateUrl: './product-details-tab.component.html',
    styleUrls: ['./product-details-tab.component.scss'],
    providers: [NavigationService, UserCacheService, CbDialogService]
})
export class ProductDetailsTabComponent implements OnInit {
    public mappedItem: IProductMappedItem;
    public suppliers: Array<IIdAndLabelDto>;
    public PRODUCT_TYPE_ENUM = PRODUCT_TYPE_ENUM;
    public OFFERING_STATUS_ENUM = OFFERING_STATUS_ENUM;
    public compositeProduct: IProductDto;
    public UNIT_OF_MEASURE_ENUM = UNIT_OF_MEASURE_ENUM;
    public rateIndex: any = {};
    public paramsSetupComplete = false;
    public allRates: TRateRegion = {
        rates: [],
        region: { id: 0, label: 'All Locations' }
    };
    public rateRegions: TRateRegion[] = [this.allRates];
    public ratesLoaded = false;


    public viewProduct = (productId: number): void => {
        window.sessionStorage.setItem('product_catalog_scroll', `${window.pageYOffset}`);
        this.navigationService.redirectTo(`view-product/${productId}/details`);
    };

    public viewBusinessAccount = (businessAccountId: number): void => {
        this.navigationService.redirectTo(`/business-accounts/edit/${businessAccountId}/details`);
    };

    public viewProductFromTableAction = (product: IProductDto): void => {
        this.viewProduct(product.id);
    };
    public viewCompositeProduct = (): void => {
        this.viewProduct(this.mappedItem.compositeItemId);
    };
    public viewBundleProduct(bundleProduct: IProductBundleProductDto): void {
        this.viewProduct(bundleProduct.offeringId);
    }

    public getBundleTemplateItemDisabled = (parentProduct: IProductDto, bundleTemplateItem: IOfferingBundleTemplateItemDto): boolean => {
        if (parentProduct && parentProduct.isActive) {
            return false;
        }

        const bundleItems: IProductBundleProductDto[] = parentProduct.products;
        const bundleTemplateItemProduct = find(bundleItems, bundleItem => {
            return bundleItem.bundleTemplateItemId === bundleTemplateItem.id;
        });

        if (bundleTemplateItem.isRequired && !bundleTemplateItemProduct) {
            return false;
        }
        return true;
    };

    public manageProduct = (product: IProductDto): void => {
        const mappedItem = this.productLogicService.$createMappedItem(product);
        this.cbDialogService.open(
            ManageProductDialogComponent,
            {
                data: {
                    mappedItem
                },
                fullWidth: true,
                minWidth: '70%',
            }
        )
            .afterClosed()
            .subOnce({
                next: this.handleEditProduct
            });
    };

    public handleNewAttribute = (product: IProductDto): void => {
        if (product) {
            this.mappedItem = this.productLogicService.$createMappedItem(product);
        }
    };

    public removeCompositeItem = (item: IProductDto): void => {
        this.cbDialogService.confirm({
            dialogHeading: 'Remove Item',
            message: `Are you sure you want to remove ${item.name} from this composite item?`,
            confirmed: () => this.productLogicService.removeCompositeItem(item.id).subOnce({
                next: this.handleRemoveItem
            })
        });
    };

    public addAttribute = (): void => {
        const copy = emptyAttribute();
        this.cbDialogService.open(
            AttributeOptionsDialogComponent,
            {
                data: {
                    mappedItem: this.mappedItem,
                    attribute: copy
                },
                fullWidth: true,
                minWidth: 600,
            }
        )
            .afterClosed()
            .subOnce({
                next: this.handleNewAttribute
            });
    };

    public handleRemoveItem = (item: any): void => {
        if (item) {
            this.mappedItem.componentItems = this.mappedItem.componentItems.filter((x: any) => x.id !== item.id);
        }
    };

    public handleRemoveBundleItem = (item: any): void => {
        if (item) {
            this.mappedItem.products = item.products;
        }
    };

    public handleEditProduct = (item: any): void => {
        if (item) {
            this.mappedItem.componentItems.forEach((sr: any) => {
                if (sr.id === item.id) {
                    this.updateProduct(sr, item);
                }
            });
        }
    };

    public handleEditAttribute = (item: IProductDto): void => {
        if (item) {
            this.mappedItem.restrictions.forEach((sr: any) => {
                item.restrictions.forEach((ir: any) => {
                    if (sr.id === ir.attributeId) {
                        ir.id = ir.attributeId;
                        this.updateProductAttributes(sr, ir);
                    }
                });
            });
        }
    };

    public handleEditTemplatedBundleItem = (item: IProductDto): void => {
        if (item) {
            this.mappedItem.templateItems.forEach((sr: any) => {
                item.templateItems.forEach((ir: any) => {
                    if (sr.id === ir.id) {
                        Object.assign(sr, ir);
                    }
                });
            });
        }
    };

    public editProductAttribute = (attribute: IProductAttributeDto): void => {
        attribute.newValues = attribute.newValues || [];
        attribute.id = attribute.attributeId;
        attribute.selectedOptions = [];
        const copy = JSON.parse(JSON.stringify(attribute));
        this.cbDialogService.open(
            AttributeOptionsDialogComponent,
            {
                data: {
                    mappedItem: this.mappedItem,
                    attribute: copy
                },
                fullWidth: true,
                minWidth: 600,
            }
        )
            .afterClosed()
            .subOnce({
                next: this.handleEditAttribute
            });
    };

    private updateProduct(sr: any, item: any): void {
        Object.assign(sr, item);
        sr.uomDisplay = UNIT_OF_MEASURE_ENUM[item.uom];
        sr.mainCategory = item.categoryPath[0].label;
    }

    private updateProductAttributes(sr: any, item: any): void {
        Object.assign(sr, item);
    }

    public assignedItemsColumns: Array<TableColumn> = [
        { name: 'Code', valueProp: 'code', type: 'text' },
        { name: 'Name', valueProp: 'name', type: 'text' },
        { name: 'Uom', valueProp: 'uom', type: 'enum', enum: UNIT_OF_MEASURE_ENUM },
        { name: 'Categories', valueProp: 'categoryPath', valueProp2: 'label', type: 'bread-crumbs' },
        {
            name: '', valueProp: 'actions', type: 'actions', actions: [
                {
                    function: this.viewProductFromTableAction,
                    icon: 'pageview'
                },
                {
                    function: this.manageProduct,
                    icon: 'edit'
                },
                {
                    function: this.removeCompositeItem,
                    icon: 'remove_circle'
                }
            ]
        }
    ];

    public restrictionsColumns: Array<TableColumn> = [
        { name: 'Option Name', valueProp: 'name', type: 'text' },
        { name: 'From Category', valueProp: 'category', valueProp2: 'label', type: 'text' },
        { name: 'Option Values', valueProp: 'options', valueProp2: 'name', type: 'array' },
        {
            name: '', valueProp: 'actions', type: 'actions', actions: [
                {
                    function: this.editProductAttribute,
                    icon: 'edit'
                }
            ]
        }
    ];

    public restrictionsFooterColumns: Array<TableColumn> = [
        { name: 'Option Name', value: '' },
        { name: 'From Category', value: '' },
        { name: 'Option Values', value: '' },
        { name: '', footerAction: { click: this.addAttribute, icon: 'add' } }
    ];

    public bundleItemsColumns: Array<TableColumn> = [
        {
            name: '',
            type: 'multiple-icons',
            multipleIcons: [
                {
                    prop: 'isCompositeItemWithoutAssignedItems',
                    title: 'Composite Item with no Items Assigned',
                    icon: 'hexagon-outline',
                    isSvg: true,
                    class: 'cb-grey'
                },
                {
                    prop: 'isCompositeItemWithAssignedItems',
                    title: 'Composite Item with Items Assigned',
                    icon: 'hexagon-slice-6',
                    isSvg: true,
                    class: 'cb-grey'
                }
            ]
        }
    ];

    constructor(
        public readonly navigationService: NavigationService,
        public readonly productLogicService: ProductLogicService,
        public readonly activatedRoute: ActivatedRoute,
        public readonly productPermissions: ProductPermissions,
        public readonly cbDialogService: CbDialogService,
        public readonly userCacheService: UserCacheService
    ) {
    }

    public ngOnInit(): void {

        this.activatedRoute.params.pipe(
            switchMap((params: { id: string }) => {
                return this.productLogicService.$getMappedItem(+params.id);
            })
        ).subOnce((mappedItem: IProductMappedItem) => {
            this.setupMappedItem(mappedItem);
            if (this.mappedItem.isCompositeItem) {
                this.refreshCompositeSuppliers(this.mappedItem);
                this.refreshCompositeRates();
            } else {
                this.refreshSuppliers(this.mappedItem.rates);
                this.refreshRates();
            }
            if (this.mappedItem.compositeItemId) {
                this.productLogicService.$getItem(this.mappedItem.compositeItemId).subOnce({
                    next: res => this.compositeProduct = res
                });
            }
            this.initSearch();
        });
    }

    public initSearch(): void {
        this.userCacheService.productSearch.init().then(() => {
            this.userCacheService.productSearch.updated$.subscribe({
                next: () => { }
            });
            this.paramsSetupComplete = true;
        });
    }

    public refreshCompositeSuppliers(product: IProductDto): void {
        this.suppliers = [{ id: 0, label: 'All' }];
        forEach(product.aggregateRates, (rate) => {
            if (!find(this.suppliers, (supplier) => supplier.id === rate.locations[0].businessAccountId) && rate.locations[0].vendor) {
                this.suppliers.push({ id: rate.locations[0].businessAccountId, label: rate.locations[0].vendor });
            }
        });
    }

    public manageRate(rate): void {
        const rateCopy = JSON.parse(JSON.stringify(rate));
        this.cbDialogService.open(
            ManageRatesDialogComponent,
            {
                data: {
                    mappedItem: this.mappedItem,
                    rate: rateCopy
                },
                fullWidth: true,
                minWidth: '70%',
            }
        )
            .afterClosed()
            .subOnce({
                next: (result) => {
                    if (result) {
                        this.viewProduct(this.mappedItem.id);
                    }
                }
            });
    }

    public openRateCompositionDialog(rate): void {
        this.cbDialogService.open(
            RateCompositionDialogComponent,
            {
                data: {
                    rate
                },
                fullWidth: true,
                minWidth: '70%',
            }
        );
    }

    public refreshRates(): void {
        this.ratesLoaded = false;

        const createRatesForRegion = (regionId: number, regionObject: { id: number; label: string }): TRateRegion => {
            let region;
            if (regionId) {
                region = regionObject;
            } else {
                region = { id: 0, label: 'National' };
            }
            const rateRegion: TRateRegion = {
                rates: [],
                region
            };

            this.rateIndex[regionId] = rateRegion; // index for lookups
            this.rateRegions.push(rateRegion); // array for ng-repeat iteration
            return rateRegion;
        };



        // reset the data
        this.allRates = {
            rates: [],
            region: { id: 0, label: 'All Locations' }
        };

        this.rateRegions = [this.allRates];
        this.rateIndex = {};

        // get all the rates ordered
        const rates = this.mappedItem.isCompositeItem ? this.mappedItem.aggregateRates : this.mappedItem.getOrderedRates();

        forEach(rates, (rate) => {
            if (!find(this.suppliers, (supplier) => supplier.id === rate.businessAccountId) && rate.vendor) {
                this.suppliers.push({ id: rate.businessAccountId, label: rate.vendor });
            }

            const areaId = rate.locationId ? rate.areaPath[0].id : 0;
            let rateRegion = this.rateIndex[areaId];
            if (!rateRegion) {
                rateRegion = createRatesForRegion(areaId, areaId ? rate.areaPath[0] : undefined);
            }
            rateRegion.rates.push(rate);
            this.allRates.rates.push(rate);
        });
        this.ratesLoaded = true;
        this.sortRateRegions();
    }

    private sortRateRegions(): void {
        const national = this.rateRegions.find(item => item.region.label === 'National');
        const allLocations = this.rateRegions.find(item => item.region.label === 'All Locations');
        this.rateRegions = this.rateRegions.filter(item => item.region.label !== 'National');
        if (national) {
            this.rateRegions.unshift(national);
        }
        this.rateRegions = this.rateRegions.filter(item => item.region.label !== 'All Locations');
        if (allLocations) {
            this.rateRegions.unshift(allLocations);
        }
    }

    public refreshCompositeRates(): void {
        this.ratesLoaded = false;

        const createRatesForRegion = (regionId: number, regionObject: { id: number; label: string }): TRateRegion => {
            let region;
            if (regionId) {
                region = regionObject;
            } else {
                region = { id: 0, label: 'National' };
            }
            const rateRegion: TRateRegion = {
                rates: [],
                region
            };

            this.rateIndex[regionId] = rateRegion; // index for lookups
            this.rateRegions.push(rateRegion); // array for ng-repeat iteration

            return rateRegion;
        };



        // reset the data
        this.allRates = {
            rates: [],
            region: { id: 0, label: 'All Locations' }
        };

        this.rateRegions = [this.allRates];
        this.rateIndex = {};

        // get all the rates ordered
        const rates = this.mappedItem.isCompositeItem ? this.mappedItem.aggregateRates : this.mappedItem.getOrderedRates();

        forEach(rates, (rate) => {
            if (!find(this.suppliers, (supplier) => supplier.id === rate.locations[0].businessAccountId) && rate.locations[0].vendor) {
                this.suppliers.push({ id: rate.locations[0].businessAccountId, label: rate.locations[0].vendor });
            }
            let areaId;
            if (rate.locations && rate.locations.length > 0 && rate.locations[0] && rate.locations[0].locatioPath && rate.locations[0].locatioPath.length > 0) {
                areaId = rate.locations[0].locationId ? rate.locations[0].locatioPath[0].id : 0;
            } else {
                areaId = 0;
            }
            let rateRegion = this.rateIndex[areaId];
            if (!rateRegion) {
                rateRegion = createRatesForRegion(areaId, areaId ? rate.locations[0].locatioPath[0] : undefined);
            }
            rateRegion.rates.push(rate);
            this.allRates.rates.push(rate);
        });
        this.ratesLoaded = true;
        this.sortRateRegions();
    }

    public rateFilter = (rate: any): boolean => {
        if (
            (this.userCacheService.productSearch.data.selectedSupplier !== 0 &&
                this.userCacheService.productSearch.data.selectedSupplier !== rate.businessAccountId)
            || (!this.userCacheService.productSearch.data.showInactiveRates && !rate.isActive)
            || (!this.userCacheService.productSearch.data.showFutureRates && this.isFutureRate(rate))
        ) {
            return false;
        }
        return true;
    };

    public readonly isFutureRate = (rate: any): boolean => {
        const applyRatesDate = new Date(rate.appliedRatesFrom);
        const dateNow = new Date();
        return applyRatesDate > dateNow;
    };

    public compositeRateFilter = (rate: any): boolean => {
        if (
            (this.userCacheService.productSearch.data.selectedSupplier !== 0 &&
                this.userCacheService.productSearch.data.selectedSupplier !== rate.locations[0].businessAccountId)
            || (!this.userCacheService.productSearch.data.showInactiveRates && !rate.locations[0].isActive)
            || (!this.userCacheService.productSearch.data.showFutureRates && this.isFutureRate(rate.locations[0]))
        ) {
            return false;
        }
        return true;
    };

    public addProductToBundle(): void {
        this.cbDialogService.open(
            AddProductToBundleDialogComponent,
            {
                data: {
                    mappedItem: this.mappedItem
                },
                fullWidth: true,
                minWidth: 600,
            }
        )
            .afterClosed()
            .subOnce({
                next: (item: IProductDto) => {
                    if (item) {
                        this.mappedItem.products = item.products;
                    }
                }
            });
    }

    public removeBundleProduct(item: IProductBundleProductDto): void {
        this.cbDialogService.confirm({
            dialogHeading: 'Remove Bundle Product',
            message: `Are you sure you want to remove ${item.offering} from this bundle?`,
            confirmed: () => this.productLogicService.removeBundleItem(this.mappedItem.id, item.id).subOnce({
                next: this.handleRemoveBundleItem
            })
        });
    }

    public canViewRates(): boolean {
        return this.productPermissions.canViewRates();
    }

    public addProductToTemplatedBundle(item: IProductBundleProductDto, bundleTemplateItem: IOfferingBundleTemplateItemDto): void {
        this.cbDialogService.open(
            AddProductToTemplatedBundleDialogComponent,
            {
                data: {
                    mappedItem: this.mappedItem,
                    bundleProductItem: item,
                    bundleTemplateItem
                },
                fullWidth: true,
                minWidth: 600,
            }
        )
            .afterClosed()
            .subOnce({
                next: this.handleEditAttribute
            });
    }

    public sortBundleItemProducts(): Array<IProductBundleProductDto> {
        return this.mappedItem.products.sort((a, b) => a.offering.localeCompare(b.offering));
    }

    private readonly setupMappedItem = (productMappedItem: IProductMappedItem): void => {
        this.mappedItem = productMappedItem;
        if (this.mappedItem.compositeItemId) {
            this.productLogicService.$getItem(this.mappedItem.compositeItemId).subOnce({
                next: (compositeProductDto) => {
                    this.compositeProduct = compositeProductDto;
                }
            });
        }
        // this.refreshSuppliers(this.mappedItem.rates);
    };

    public getHtmlClassFromStatus(): string {
        return this.mappedItem.status === OFFERING_STATUS_ENUM.Active ? 'cb-primary' : 'cb-warn';
    }

    public getStatusText(): string {
        switch (this.mappedItem.status) {
            case OFFERING_STATUS_ENUM.Active:
                return '(Active)';
            case OFFERING_STATUS_ENUM.TemporarilyUnavailable:
                return '(Unavailable)';
            case OFFERING_STATUS_ENUM.PermanentlyUnavailable:
                return '(Inactive)';
            default:
                return '';
        }
    }

    public refreshSuppliers(rates: Array<IRateDto>): void {
        this.suppliers = [{ id: 0, label: 'All' }];
        rates.forEach(rate => {
            if (!this.suppliers.find(supplier => supplier.id === rate.businessAccountId) && rate.vendor) {
                this.suppliers.push({ id: rate.businessAccountId, label: rate.vendor });
            }
        });
    }

    public getCompositeItemLabel(): string {
        return this.compositeProduct ? `${this.compositeProduct?.code} ${this.compositeProduct?.name}` : '';
    }

    public editBundleProductWithTemplate(
        item: IProductBundleProductDto,
        bundleTemplateItem: IOfferingBundleTemplateItemDto
    ): void {
        const itemCopy = JSON.parse(JSON.stringify(item));
        this.cbDialogService.open(
            AddProductToTemplatedBundleDialogComponent,
            {
                data: {
                    bundleProductItem: itemCopy,
                    bundleTemplateItem,
                    mappedItem: this.mappedItem
                },
                fullWidth: true,
                minWidth: 600,
            }
        )
            .afterClosed()
            .subOnce({
                next: this.handleEditTemplatedBundleItem
            });
    }

    public editBundleProductWithoutTemplate(
        item: IProductBundleProductDto
    ): void {
        this.cbDialogService.open(
            AddProductToBundleDialogComponent,
            {
                data: {
                    bundleItem: item,
                    mappedItem: this.mappedItem
                },
                fullWidth: true,
                minWidth: 600,
            }
        )
            .afterClosed()
            .subOnce({
                next: this.handleEditTemplatedBundleItem
            });
    }

    public scrollDown(): void {
        const element = document.querySelector('#scrollId');
        element.scrollIntoView();
    }
}
