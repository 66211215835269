import { Component } from '@angular/core';
import { ISpecHouseAreaDto, ISpecTemplateColourItemDto, ISpecTemplateDto, ISpecTemplateItemDto, SpecTemplateLogicService } from '@app/logic/spec-template';
import { filter, find, includes, orderBy } from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { SpecificationTemplatePermissions } from '@app/core/permissions';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';
import { COST_TYPE_ENUM, PRODUCT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { SpecTemplateColourItemDialogComponent } from '../spec-template-colour-item-dialog/spec-template-colour-item-dialog.component';
import { SpecTemplateItemDialogComponent } from '../spec-template-item-dialog/spec-template-item-dialog.component';
import { HISTORY_SEARCH_ENTITY } from '@app/shared/enums/history/history-search-entity.enum';

enum TabNames {
    Template = 'template',
    History = 'history',
}

enum Tabs {
    Template,
    History,
}

@Component({
    selector: 'cb-spec-template-view',
    templateUrl: './spec-template-view.component.html',
    styleUrls: ['./spec-template-view.component.scss']
})
export class SpecTemplateViewComponent {
    public templateId: number;
    public currentTabIndex = Tabs.Template;
    public template: ISpecTemplateDto;
    public houseAreas: ISpecHouseAreaDto[];
    public refresh$ = new BehaviorSubject<boolean>(undefined);
    public expansionPanelStates: { [key: number]: boolean } = {};
    public COST_TYPE_ENUM = COST_TYPE_ENUM;
    public HISTORY_SEARCH_ENTITY = HISTORY_SEARCH_ENTITY;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly cbDialog: CbDialogService,
        private readonly navigationService: NavigationService,
        private readonly currentUserService: CurrentUserService,
        private readonly featureToggle: FeatureToggleStatesService,
        private readonly specTemplateLogicService: SpecTemplateLogicService,
        private readonly specTemplatePermissions: SpecificationTemplatePermissions,
    ) {
        this.refresh$.subscribe(() => this.refresh());
    }

    public ngOnInit(): void {
        this.route.params.subOnce((params: { [key: string]: string }) => {
            this.templateId = params.id as unknown as number;
            this.currentTabIndex = params.tabName === TabNames.Template ? Tabs.Template : Tabs.History;
            this.refresh();
        });
    }

    public tabIndexChanged(tabIndex: Tabs): void {
        const tabName = tabIndex === Tabs.Template ? TabNames.Template : TabNames.History;
        this.navigationService.navigate([`/spec-templates/view/${this.templateId}/${tabName}`]);
    }

    public refresh(): void {
        if (this.templateId) {
            combineLatest([
                this.specTemplateLogicService.$getItem(this.templateId),
                this.specTemplateLogicService.getHouseAreas(this.templateId),
                this.specTemplateLogicService.getColourItems(this.templateId)
            ]).subOnce(result => {
                this.template = result && result[0] ? result[0] : {} as ISpecTemplateDto;
                this.houseAreas = result && result[1] ? orderBy(result[1], 'sortOrder') : [];
                this.houseAreas.forEach((houseArea: ISpecHouseAreaDto) => {
                    const match = find(this.template.specTemplateGroups, { specGroupId: houseArea.houseArea.id });
                    houseArea.items = match ? match.specTemplateItems : [];
                    const colours = filter(result[2] ?? [], { specGroupId: houseArea.houseArea.id });
                    houseArea.items.push(...colours);
                    houseArea.items = orderBy(houseArea.items, 'sortOrder');
                });
            });
        }
    }

    public expandOrCollapseAll(): void {
        if (this.houseAreas?.length) {
            if (this.isAllExpanded()) {
                this.houseAreas.forEach((houseArea: ISpecHouseAreaDto) => this.expansionPanelStates[houseArea.id] = false);
            } else {
                this.houseAreas.forEach((houseArea: ISpecHouseAreaDto) => this.expansionPanelStates[houseArea.id] = true);
            }
        }
    }

    public isAllExpanded(): boolean {
        return this.houseAreas?.length ? !find(this.houseAreas, (houseArea: ISpecHouseAreaDto) => !this.expansionPanelStates[houseArea.id]) : false;
    }

    public canEdit(): boolean {
        let canEdit = false;
        if (this.specTemplatePermissions.canEditAll()) {
            canEdit = true;
        } else {
            canEdit = this.template.regionIds.some(i => {
                return includes(this.currentUserService.regionsids ?? [], i) && this.specTemplatePermissions.canEdit();
            });
        }
        return canEdit
            && ((this.featureToggle.isScheduleItemSpecTemplatesEnabled && this.template.isScheduleSpecTemplate)
                || (!this.featureToggle.isScheduleItemSpecTemplatesEnabled && !this.template.isScheduleSpecTemplate));
    }

    public isBundle(item: ISpecTemplateItemDto): boolean {
        return item.product?.offeringType === PRODUCT_TYPE_ENUM.Bundle;
    }

    public isDescriptiveOnly(costType: number): boolean {
        return costType === COST_TYPE_ENUM.DescriptiveOnly;
    }

    public isProvisional(costType: number): boolean {
        return costType === COST_TYPE_ENUM.Provisional;
    }

    public isEstimate(costType: number): boolean {
        return costType === COST_TYPE_ENUM.Estimate;
    }

    public addColourItemClicked(specGroup?: ISpecHouseAreaDto): void {
        const dialog = this.cbDialog.open(SpecTemplateColourItemDialogComponent, {
            data: {
                dialogHeading: 'Add Colour Item',
                item: { specGroupId: specGroup?.houseArea?.id },
                specTemplateId: this.templateId,
                houseAreas: this.houseAreas
            }
        });
        dialog.afterClosed().subOnce(() => this.refresh());
    }

    public addLotSpecItemClicked(specGroup?: ISpecHouseAreaDto): void {
        const dialog = this.cbDialog.open(SpecTemplateItemDialogComponent, {
            data: {
                dialogHeading: 'Add Spec Template Item',
                item: { specGroupId: specGroup?.houseArea?.id },
                template: this.template
            }
        });
        dialog.afterClosed().subOnce(() => this.refresh());
    }

    public editItemClicked(item: ISpecTemplateItemDto | ISpecTemplateColourItemDto, specGroupId?: number): void {
        let dialog;
        if ((item as ISpecTemplateColourItemDto).colourItem) {
            dialog = this.cbDialog.open(SpecTemplateColourItemDialogComponent, {
                data: {
                    dialogHeading: 'Edit Colour Item',
                    item
                }
            });
        } else {
            dialog = this.cbDialog.open(SpecTemplateItemDialogComponent, {
                data: {
                    dialogHeading: 'Edit Spec Template Item',
                    item,
                    template: this.template,
                    specGroupId,
                }
            });
        }
        dialog.afterClosed().subOnce(() => this.refresh());
    }

    public removeItemClicked(item: ISpecTemplateItemDto): void {
        this.cbDialog.confirm({
            message: 'Are you sure you want to delete this Item?',
            dialogHeading: 'Are you sure?',
            yesLabel: 'Delete',
            noLabel: 'Cancel',
            confirmed: () => {
                this.specTemplateLogicService.deleteItem(this.templateId, item).subOnce(() => this.refresh());
            },
        });
    }

    public viewItemClicked(item: ISpecTemplateItemDto): void {
        this.navigationService.navigate([`/products/view/${item.offeringId}/details`]);
    }
}
