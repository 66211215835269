import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BusinessEntitiesLogicService, IBusinessEntityDto } from '@app/logic/business-entity';
import { IBusinessEntityDefaultSupplierDto } from '@app/logic/business-entity/interfaces/i.business-entity.dto';
import {
    IBusinessEntityMappedItem,
    IBusinessEntityDefaultInstallerMappedItem
} from '@app/logic/business-entity/interfaces/i.business-entity.mapped';
import { BusinessAccountLogicService, IBusinessAccountContactDto } from '@app/logic/business-accounts';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IBusinessEntityDefaultInstallersLogicService } from '@app/logic/business-entity/interfaces/i.business-entity.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { FormMode } from '@app/shared/enums/form';
import { IPromised, toPromisedArray } from 'cb-hub-lib';
import { IBusinessAccountContactAreaDto, ILookupDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import { IBusinessAccountContactDetailsDto } from '@classictechsolutions/hubapi-transpiled-enums/build/module';

interface IData {
    mappedItem: IBusinessEntityDefaultInstallerMappedItem;
    otherData: { region: IBusinessEntityMappedItem };
}

@Component({
    selector: 'cb-default-installer-dialog',
    templateUrl: './default-installer-dialog.component.html',
    styleUrls: ['./default-installer-dialog.component.scss'],
    providers: [BusinessAccountLogicService, BusinessEntitiesLogicService]
})
export class DefaultInstallerDialogComponent
    extends BaseDialogFormViewDirective<
    IBusinessEntityDefaultSupplierDto,
    IBusinessEntityDefaultInstallerMappedItem,
    IBusinessEntityDefaultInstallersLogicService
    > implements OnInit {
    public selectedContactId: number;
    public businessEntities: IBusinessEntityDto[];
    public mappedItem: IBusinessEntityDefaultInstallerMappedItem;
    public region: IBusinessEntityMappedItem;
    public availableTrades: any[];
    public availableContacts: ILookupDto[];
    public selectedTradeTypeId: number;
    public selectedSupplier: any;
    public invalidSupplier: boolean;
    public validLocationIds: IPromised<number[]>;

    constructor(
        public readonly toastService: ToastService,
        public readonly dialogRef: MatDialogRef<DefaultInstallerDialogComponent>,
        private readonly businessEntitiesLogicService: BusinessEntitiesLogicService,
        private readonly businessAccountLogicService: BusinessAccountLogicService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.region = data.otherData.region;
        this.validLocationIds = toPromisedArray(this.region.getValidInstallerLocations());
    }

    public ngOnInit(): void {
        if (this.mappedItem.businessEntityId) {
            this.formMode = FormMode.Edit;
            this.selectedTradeTypeId = this.mappedItem.tradeType.id;
            this.selectedSupplier = {
                id: this.mappedItem.supplier.id,
                tradingName: this.mappedItem.supplier.label
            };
            this.loadInstallerContacts(this.selectedSupplier);
        } else {
            this.formMode = FormMode.Add;
            this.businessEntitiesLogicService
                .getAvailableTrades(this.region.id)
                .subOnce({
                    next: x => {
                        // Sort the trade types alphabetically by label
                        x.sort((a, b) => a.label.localeCompare(b.label));
                        this.availableTrades = x;
                    }
                });
        }
    }

    public selectedTradeTypeChanged(): void {
        this.resetForm();
        this.mappedItem.tradeType = { id: this.selectedTradeTypeId, label: undefined, path: undefined };
    }

    private resetForm(): void {
        this.invalidSupplier = false;
        this.selectedSupplier = undefined;
        this.availableContacts = [];
        this.selectedContactId = undefined;
    }

    public save(): void {
        this.dialogRef.close(this.data.mappedItem);
    }

    public supplierChanged(): void {
        this.mappedItem.supplier = {
            label: this.selectedSupplier.tradingName,
            id: this.selectedSupplier.id
        };

        this.loadInstallerContacts(this.selectedSupplier);
    }

    public loadInstallerContacts(supplier: IBusinessAccountContactDto): any {
        this.invalidSupplier = false;
        this.businessAccountLogicService
            .getAccountContacts(supplier.id)
            .subOnce({
                next: x => {
                    this.selectedContactId = this.mappedItem.contact ? this.mappedItem.contact.id : undefined;
                    this.handleInstallerContacts(this.getInstallerContactLocationLookup(x));
                }
            });
    }

    private readonly getInstallerContactLocationLookup = (contacts: IBusinessAccountContactDetailsDto[]): ILookupDto[] => {
        return cloneDeepSafe(contacts)
            .filter((contact: IBusinessAccountContactDetailsDto) => {
                contact.areas = contact.areas.filter((area: IBusinessAccountContactAreaDto) => {
                    return area.tradeTypeId === this.selectedTradeTypeId
                        && this.validLocationIds.indexOf(area.location.id) >= 0;
                });
                return contact?.areas?.length > 0;
            })
            .map((contact) => {
                return {
                    id: contact?.areas[0]?.id,
                    label: contact?.name,
                } as ILookupDto;
            });
    };

    private readonly handleInstallerContacts = (installerContacts: ILookupDto[]): void => {
        if (installerContacts.length > 0) {
            this.availableContacts = installerContacts;
            // We just want to select any available contact as we no longer require
            // users to select a contact when setting up a default installer (all suppliers in this list has already been
            // filtered for the business entity location)
            this.selectedContactId = this.availableContacts[0].id;
            this.mappedItem.contact = {
                label: undefined,
                id: this.selectedContactId
            };
        } else {
            this.availableContacts = [];
            this.invalidSupplier = true;
        }
    };
}
