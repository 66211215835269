import { Observable, tap } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IDocumentGroupDto, IDocumentGroupSystemArea } from './interfaces/i.document-group.dto';
import { IDocumentGroupLogicService } from './interfaces/i.document-group.logic.service';
import { IDocumentGroupMappedItem } from './interfaces/i.document-group.mapped';
import { IDocumentTypeDocumentGroupDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/DocumentTypeDocumentGroupDto';

export class DocumentGroupMappedItem
    extends BaseMappedItem<IDocumentGroupDto, IDocumentGroupMappedItem, IDocumentGroupLogicService>
    implements IDocumentGroupMappedItem {

    @DtoProp public id: number;
    @DtoProp public name: string;
    @DtoProp public codeName: string;
    @DtoProp public codeOnly: boolean;
    @DtoProp public isActive: boolean;
    @DtoProp public isDeleted: boolean;
    @DtoProp public types: IDocumentTypeDocumentGroupDto[];
    @DtoProp public systemAreas: IDocumentGroupSystemArea[];
    @DtoProp public defaultSystemArea: number;

    public entityUri: string;
    public entityId: string | number;

    constructor(
        sourceData: IDocumentGroupDto,
        logicService: IDocumentGroupLogicService
    ) {
        super(sourceData, logicService, DocumentGroupMappedItem);
    }

    public saveDocument(): Observable<IDocumentGroupDto> {
        return this.$logicService.saveDocumentGroup(this.entityUri, this.entityId, this.$getMappedDtoItem())
            .pipe(tap(result => result && this.$updateThisAndOriginal(result)));
    }

    protected $preSave(toSave: IDocumentGroupDto): void {
        if (toSave.types) {
            toSave.types.forEach(type => {
                if (type.documentType) {
                    type.documentTypeId = type.documentType.id;
                    type.documentType = undefined;
                }
                type.approvalTags = type.approvalTags || [];
            });
        }
    }
}
