import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { LeadPermissions } from '@app/core/permissions';
import { ILeadSearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { ILeadSearchDto, ILeadSearchParams, LeadLogicService } from '@app/logic/leads';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ISearchResult } from '@app/shared/components/search/i.search';
import {
    LEAD_SEARCH_ORDER_ENUM,
    LEAD_STATUS_ENUM,
    LOT_CONTRACT_TYPE_ENUM,
} from '@classictechsolutions/hubapi-transpiled-enums';
import moment, { Moment } from 'moment';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

import { CreateLeadDialogComponent } from '../create-lead-dialog/create-lead-dialog.component';

@Component({
    selector: 'cb-my-leads-side-panel',
    templateUrl: './my-leads-side-panel.component.html',
    styleUrls: ['./my-leads-side-panel.component.scss'],
    providers: [
        UserCacheService,
    ]
})
export class MyLeadsSidePanelComponent implements OnInit, OnDestroy {

    @Input() public readonly currentLeadId: number | null;
    @Output() public readonly leadSelected = new EventEmitter<number>();

    private _leadUpdated: Observable<ILeadSearchDto>;
    @Input() public set leadUpdated(v: Observable<ILeadSearchDto>) {
        this._leadUpdated = v;
        if (this.leadUpdated) {
            this.leadUpdated$.unsubscribe();
            this.leadUpdated$ = this.leadUpdated.subscribe(this.handleUpdateListItem);
        }
    }
    public get leadUpdated(): Observable<ILeadSearchDto> {
        return this._leadUpdated;
    }

    public collapsed = false;
    public readonly leadStatuses = LEAD_STATUS_ENUM.toLookup().filter(x => x.id !== LEAD_STATUS_ENUM.None);
    public readonly LEAD_STATUS_ENUM = LEAD_STATUS_ENUM;
    public readonly LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;

    public readonly orders = [
        { label: 'None', id: LEAD_SEARCH_ORDER_ENUM.None },
        { label: 'Next Follow Up (Closest > Furthest)', id: LEAD_SEARCH_ORDER_ENUM.FollowUpDesc },
        { label: 'Next Follow Up (Furthest > Closest)', id: LEAD_SEARCH_ORDER_ENUM.FollowUpAsc },
        { label: 'Lead Age (Newest > Oldest)', id: LEAD_SEARCH_ORDER_ENUM.LeadAgeDesc },
        { label: 'Lead Age (Oldest > Newest)', id: LEAD_SEARCH_ORDER_ENUM.LeadAgeAsc },
        { label: 'Last Updated (Recent > Oldest)', id: LEAD_SEARCH_ORDER_ENUM.LastUpdatedDesc },
        { label: 'Last Updated (Oldest > Recent)', id: LEAD_SEARCH_ORDER_ENUM.LastUpdatedAsc },
        { label: 'Lead Name (A - Z)', id: LEAD_SEARCH_ORDER_ENUM.LeadNameAsc },
        { label: 'Lead Name (Z - A)', id: LEAD_SEARCH_ORDER_ENUM.LeadNameDesc },
    ];

    public get userCacheItem(): UserCacheItem<ILeadSearch> {
        return this.userCacheService.myLeadsSearch;
    }

    private readonly subscription$ = new Subscription();
    private leadUpdated$ = new Subscription();

    public readonly queryUpdate = new Subject();
    public currentPage: number;
    public results: (ILeadSearchDto)[] = [];
    public searchEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        public readonly cbDialog: CbDialogService,
        public readonly leadLogic: LeadLogicService,
        public readonly permissions: LeadPermissions,
        public readonly currentUser: CurrentUserService,
        private readonly userCacheService: UserCacheService,
    ) {
    }

    public ngOnInit(): void {
        Promise.all([
            this.userCacheItem.init(),
            this.currentUser.$promise,
        ]).then(() => {
            this.searchEnabled.next(true);
            this.queryUpdated();
            this.subscription$.add(
                this.userCacheItem.updated$.subscribe({
                    next: this.queryUpdated
                })
            );
        });
    }

    public viewLeadDisabled(leadId: number): boolean {
        return Number(this.currentLeadId) === Number(leadId);
    }

    public fetchResults(): Observable<ISearchResult<ILeadSearchDto>> {
        if (!this.searchEnabled.value) {
            return;
        }
        return this.leadLogic.search(this.getQueryParams());
    }

    public ngOnDestroy(): void {
        this.subscription$.unsubscribe();
        this.leadUpdated$.unsubscribe();
    }

    private readonly queryUpdated = (): void => {
        if (!this.searchEnabled.value) {
            return;
        }
        this.queryUpdate.next(null);
    };

    public getQueryParams(): ILeadSearchParams {
        if (!this.searchEnabled.value) {
            return;
        }
        const data = this.userCacheItem.copyData();
        return {
            query: data.query,
            status: data.status,
            buildingConsultantId: [this.currentUser.guid],
            order: data.order,
            currentpage: this.currentPage,
        } as ILeadSearchParams;
    }

    public loadLead(leadId: number): void {
        this.leadSelected.emit(leadId);
    }

    public createLead(): void {
        this.cbDialog
            .open(CreateLeadDialogComponent, {
                data: {},
                minWidth: '40%',
            })
            .afterClosed()
            .subOnce((result: false | ILeadSearchDto) => {
                if (result) {
                    this.results.unshift(result);
                    this.leadSelected.emit(result.id);
                }
            });
    }

    public getCallbackDays(date: string): string {
        return (moment(date) as Moment).fromNow();
    }

    public getFollowUpColour(lead: ILeadSearchDto): string {
        const nowM = moment() as Moment;
        const dateM = moment(lead.callbackDate) as Moment;
        const status = +lead.status ? lead.status : LEAD_STATUS_ENUM[lead.status];
        if (status !== LEAD_STATUS_ENUM.Cold && status !== LEAD_STATUS_ENUM.Hot) {
            return;
        }
        const fiveDaysHours = (24 * 5);
        return nowM.isAfter(dateM) ? 'follow-up-overdue' : (dateM.diff(nowM, 'hours') > fiveDaysHours ? 'follow-up-primary' : 'follow-up-due-soon');
    }

    public showFollowUp(lead: ILeadSearchDto): boolean {
        let statusId = Number(lead.status);
        if (isNaN(statusId)) {
            statusId = LEAD_STATUS_ENUM[lead.status];
        }
        return statusId === LEAD_STATUS_ENUM.Cold || statusId === LEAD_STATUS_ENUM.Hot;
    }

    public getStatus(lead: ILeadSearchDto): string {
        const status = +lead.status ? LEAD_STATUS_ENUM[lead.status] : lead.status;
        return lead.contractType !== LOT_CONTRACT_TYPE_ENUM.None ? `${status} Lead for ${LOT_CONTRACT_TYPE_ENUM[lead.contractType]}` : status;
    }

    private readonly handleUpdateListItem = (loaded: ILeadSearchDto): void => {
        const arr = this.results;
        if (!arr || !arr?.length) {
            return;
        }
        const idx = arr.findIndex(x => x.id === loaded.id);
        if (idx > -1 && arr[idx].id === loaded.id) {
            loaded.businessEntitities = arr[idx].businessEntitities;
            arr[idx] = loaded;
        }
    };
}
