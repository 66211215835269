import { Injectable, Injector } from '@angular/core';
import {
    IActualCostsDto,
    IManualPurchaseOrderDto,
    ISsrDataDto,
    ISsrDataLineDto,
    ISsrDetailsDto,
    ISsrLineItemDto,
    ManualOrderReasonEnumId
} from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { ISsrMappedItem } from './interfaces/i.ssr.mapped';
import { ISsrsLogicService } from './interfaces/i.ssrs.logic.service';
import { SsrMappedItem } from './ssr.mapped';

@Injectable()
export class SsrsLogicService
    extends BaseLogicService<ISsrDataDto, ISsrMappedItem>
    implements ISsrsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('ssrs', SsrMappedItem);
    }

    public removeLineItem(ssrId: number, lineItemId: number): Observable<ISsrLineItemDto> {
        return this.$http
            .delete(`${this.$baseUri}/${ssrId}/item/${lineItemId}`);
    }

    // TODO: Determine whether or not this is actually used anywhere
    public updateComments(ssrId: number, comment: string): Observable<string> {
        return this.$http
            .post(`${this.$baseUri}/comment`, { id: ssrId, comment });
    }

    public convertToManualOrder(ssrId: number, orderreason: { orderReasonId?: ManualOrderReasonEnumId; orderReason?: string }): Observable<IManualPurchaseOrderDto> {
        return this.$http
            .post(`${this.$baseUri}/${ssrId}/converttomanual`, orderreason);
    }

    public updateLineItemQuantity(lineItemId: number, quantity: number): Observable<ISsrDataLineDto> {
        return this.$http
            .post(`${this.$baseUri}/items/${lineItemId}/quantity`, {}, 'json', {}, { quantity: quantity.toString() });
    }

    public getPossibleLineItems(ssrId: number): Observable<ISsrLineItemDto[]> {
        return this.$http
            .get(`${this.$baseUri}/${ssrId}/possibleitems`);
    }

    public addLineItems(ssrId: number, lineItems: ISsrLineItemDto[]): Observable<ISsrLineItemDto[]> {
        return this.$http
            .post(`${this.$baseUri}/${ssrId}/item`, lineItems);
    }

    public cancelSsr(ssrId: number): Observable<ISsrDetailsDto> {
        return this.$http
            .delete<ISsrDetailsDto>(`${this.$baseUri}/${ssrId}`);
    }

    public completeSsr(ssrId: number): Observable<ISsrDetailsDto> {
        return this.$http
            .get(`${this.$baseUri}/${ssrId}/complete`);
    }

    public confirmSsr(ssrId: number): Observable<ISsrDetailsDto> {
        return this.$http
            .get(`${this.$baseUri}/${ssrId}/confirm`);
    }

    public redraftSsr(ssrId: number): Observable<ISsrDetailsDto> {
        return this.$http
            .get(`${this.$baseUri}/${ssrId}/redraft`);
    }

    public restartSsr(ssrId: number): Observable<ISsrDetailsDto> {
        return this.$http
            .get(`${this.$baseUri}/${ssrId}/restart`);
    }

    public downloadPo(ssrId: number): Observable<void> {
        return this.$http
            .download(`ssrs/${ssrId}/purchaseorder/download`);
    }

    public downloadSsr(ssrId: number): Observable<void> {
        return this.$http
            .download(`ssrs/${ssrId}/download`);
    }

    public flattenAllSsrs(): Observable<boolean> {
        return this.$http
            .post(`${this.$baseUri}/flatten`);
    }

    public getCostsForLot(lotId: number): Observable<IActualCostsDto> {
        return this.$http
            .get(`lots/${lotId}/actualcosts`);
    }
}
