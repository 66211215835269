import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SearchService } from '@app/core/services/search/base.search.service';
import { BuildProgrammeLogicService } from '@app/logic/build-programme';
import { ILocationDto, LocationLogicService } from '@app/logic/location';
import { takeOne } from 'cb-hub-lib';
import { map, Observable } from 'rxjs';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';
import { AutocompleteScrollerComponent, getBaseAutocompleteScrollerProvider } from '../../autocomplete-scroller.component';

@Component({
    selector: 'cb-location-autocomplete-scroller',
    templateUrl: '../../autocomplete-scroller.component.html',
    providers: [
        ...getBaseFormComponentDirectiveProvider(LocationAutocompleteScrollerComponent),
        getBaseAutocompleteScrollerProvider(LocationAutocompleteScrollerComponent),
        SearchService,
    ]
})
export class LocationAutocompleteScrollerComponent
    extends AutocompleteScrollerComponent<ILocationDto> {
    public results: ILocationDto[] = [];
    public hasDuplicates = false;
    private resultsLoaded = false;
    public previousReq: string;
    public previousQuery: string;
    private _regionId: number;

    @Input() public set regionId(v: number) {
        this._regionId = v;
        this.resultsLoaded = false;
        if (typeof (this.value) === 'string') {
            this.searchTextChanged.emit(this.value);
        }

        const display = this.displayWith(this.value) ?? '';
        if (typeof (display) === 'string') {
            this.searchTextChanged.emit(display);
        }
    }

    constructor(
        public readonly buildProgrammeLogicService: BuildProgrammeLogicService,
        public readonly dialog: MatDialog,
        public readonly searchService: SearchService,
        protected readonly locationLogicService: LocationLogicService
    ) {
        super(dialog, searchService);
        this.searchService.doSearch = this.doSearch;
    }

    public doSearch = (query: string): Observable<any[]> => {
        this.resultsLoaded = false;
        return this.getResults(query)
            .pipe(takeOne(), map((results) => {
                const filtered = results.filter(x =>
                    (this.displayWith(x) || '').toLowerCase().indexOf(query.toLowerCase()) > -1
                );
                return filtered;
            }));
    };

    public displayWith(item: any): string {
        return item ? item.label : '';
    }

    private getResults(query: string): Observable<ILocationDto[]> {
        return new Observable<ILocationDto[]>((subscriber) => {
            if (!this.resultsLoaded) {
                let resultsObservable: Observable<ILocationDto[]>;
                if (this._regionId) {
                    if (this.previousReq === `getByRegion${this._regionId}` && query === this.previousQuery) {
                        subscriber.next([]);
                        return;
                    }
                    this.previousReq = `getByRegion${this._regionId}`;
                    this.previousQuery = query;
                    resultsObservable = this.locationLogicService.getAreasByRegionId({ addressRegionId: this._regionId });
                } else {
                    if (this.previousReq === 'getAll' && query === this.previousQuery) {
                        subscriber.next([]);
                        return;
                    }
                    this.previousReq = 'getAll';
                    this.previousQuery = query;
                    resultsObservable = this.locationLogicService.getAreasLookup();
                }
                resultsObservable
                    .subOnce(x => {
                        this.resultsLoaded = true;
                        subscriber.next(x);
                        this.results = x;
                        subscriber.complete();
                    });
            } else {
                subscriber.complete();
            }
        });
    }
}
