<cb-dialog class="flex-col"
           [dialogHeading]="isEdit() ? 'Edit Trade Type' : 'Add Trade Type'"
           (cancelDialog)="cancel()">
    <form #tradeTypeEditForm="ngForm">
        <cb-input label="Trade Type"
                  type="text"
                  name="tradeType"
                  [(ngModel)]="mappedItem.label"
                  [readonly]="isView()"
                  [required]="true"
                  [maxlength]="100"></cb-input>
        <cb-select *ngIf="mappedItem?.mappedChildren?.length < 1"
                   label="Parent"
                   name="parentSelect"
                   [(ngModel)]="mappedItem.parentId"
                   [readonly]="isView()"
                   [options]="availableTradeTypes"></cb-select>
    </form>
    <form #tradeTypeTableForm="ngForm">
        <cb-table *ngIf="mappedItem.parentId"
                  class="cb-margin-top"
                  label="Compliance Registrations"
                  [stringColumns]="columns"
                  [loaded]="loaded">
            <tbody cbTableBody>
                <tr *ngFor="let compReg of mappedItem.requiredComplianceRegistrations">
                    <td>
                        {{compReg.label}}
                    </td>
                    <td>
                        <button mat-icon-button
                                (click)="removeCompReg(compReg.id)">
                            <mat-icon>
                                remove_circle
                            </mat-icon>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <cb-autocomplete-scroller class="flex"
                                                  *cbWaitFor="!autocompleteLoading"
                                                  label="Compliance Registration"
                                                  name="selectComplianceReg"
                                                  [(ngModel)]="selectedCompReg"
                                                  [requireMatch]="true"
                                                  [matchProps]="['id']"
                                                  [querySearch]="querySearch"></cb-autocomplete-scroller>
                    </td>
                    <td>
                        <button mat-icon-button
                                (click)="addCompReg()"
                                [disabled]="disableAddCompReg()">
                            <mat-icon>
                                add
                            </mat-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </cb-table>
    </form>
    <form #tradeTypeEditForm2="ngForm"
          class="cb-checkbox-list flex-col">
        <cb-checkbox *ngIf="mappedItem.parentId"
                     label="Include In Live Schedule"
                     name="includeInLiveSchedule"
                     [(ngModel)]="mappedItem.includeInLiveSchedule"></cb-checkbox>
        <cb-checkbox *ngIf="mappedItem.parentId"
                     label="Can Request 7 Day Payments"
                     name="canRequestSevenDayPayments"
                     [(ngModel)]="mappedItem.canRequestSevenDayPayments"></cb-checkbox>
        <cb-checkbox *ngIf="!activeChildren || isAdd()"
                     label="Active"
                     name="isActive"
                     [(ngModel)]="mappedItem.isActive"></cb-checkbox>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="!(tradeTypeEditForm.valid && tradeTypeEditForm2.valid && (complianceRegsChanged || tradeTypeEditForm.dirty || tradeTypeEditForm2.dirty))"
                (cbClick)="save()">
            {{isEdit() ? 'Save' : 'Add'}}
        </button>
        <button mat-raised-button
                (cbClick)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
