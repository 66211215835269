import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { DesignTeamPermissions, QSTeamPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ITeamQuestionSearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { DesignSchemesLogicService } from '@app/logic/design-schemes';
import { GenericQuestionsLogicService } from '@app/logic/generic-questions';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { GenericQuestionResponseDialogComponent } from '@app/shared/components/generic-questions/generic-question-response-dialog/generic-question-response-dialog.component';
import { FormMode } from '@app/shared/enums/form';
import {
    IDesignSchemeLineDto,
    IGenericQuestionDocumentDto,
    SYSTEM_AREA_ENUM, TEAM_CODES_CONST
} from '@classictechsolutions/hubapi-transpiled-enums';
import { orderBy } from 'lodash';
import { BehaviorSubject, iif, map, mergeMap, Observable, of, skipWhile, startWith, Subject, Subscriber, Subscription, switchMap } from 'rxjs';
import { ManageQuestionDialogComponent } from './manage-question-dialog/manage-question-dialog.component';

@Component({
    selector: 'cb-teams-questions',
    templateUrl: './questions.component.html',
    styleUrls: ['./questions.component.scss'],
})
export class TeamsQuestionsComponent implements OnDestroy, AfterViewInit, AfterViewChecked {
    @Input() public resultDirections$: Observable<{
        id: string;
        label: string;
    }[]>;

    @Input() public get searchFilters(): UserCacheItem<ITeamQuestionSearch> {
        return this.userCacheService.qsTeamQuestionSearch;
    }

    @Input() public set teamCode(value: string) {
        this._teamCode$.next(value);
    }

    private _isFormInitialised = false;

    @ViewChild(NgForm) public formLiteral: NgForm;
    private _form$ = new BehaviorSubject<NgForm>(null);

    @ViewChild(NgForm) public set form(form: NgForm) {
        if ((form && Object.keys(form.form?.controls).length > 0)) {
            this._form$?.next(form);
        }
    }

    public resultOrdersAll$ = of([
        { id: 'createdDate', label: 'Created Date' },
        { id: 'responseDate', label: 'Response Date' },
        { id: 'lotNumber', label: 'Lot Number' },
        { id: 'questionNumber', label: 'Question Number' }
    ]);

    public resultOrdersAwaitngResponseOnly$ = of([
        { id: 'createdDate', label: 'Created Date' },
        { id: 'lotNumber', label: 'Lot Number' },
        { id: 'questionNumber', label: 'Question Number' }
    ]);

    public resultOrders$ = this._form$.asObservable().pipe(
        skipWhile(form => !form),
        switchMap(form => form.form.controls.awaitingResponseOnly?.valueChanges.pipe(
            startWith(this.searchFilters.data.awaitingResponseOnly)
        )),
        mergeMap(isAwaitingResponseOnly => iif(() => isAwaitingResponseOnly,
            this.resultOrdersAwaitngResponseOnly$,
            this.resultOrdersAll$
        ))
    );

    public canEdit$(question: IGenericQuestionDocumentDto): Observable<boolean> {
        return new Observable((subscriber: Subscriber<boolean>) => {
            subscriber.next(this._isRequestingUser(question) &&
                (question.isAwaitingResponse === true) &&
                (question.isActive === true));
        });
    }

    public canRespond$(question: IGenericQuestionDocumentDto): Observable<boolean> {
        return new Observable((subscriber: Subscriber<boolean>) => {
            subscriber.next((question.assignedToUserId === this.currentUser.guid)
                && (question.isAwaitingResponse === true) && (question.isActive === true));
        });
    }

    private _teamCode$ = new BehaviorSubject<string>(null);
    public isUnassigned: boolean;
    private _subscriptions = new Subscription();
    public searchFiltersLoaded$ = new BehaviorSubject(null);
    public searchEnabled$ = new BehaviorSubject(null);
    public readonly searchFiltersChanged$ = new Subject();
    public currentPage: number;
    public readonly allUsers = '999';
    public readonly allStatuses = 999;
    public readonly unassigned = undefined;
    public loaded = false;
    public results: IGenericQuestionDocumentDto[] = [];
    private childItems: IDesignSchemeLineDto[];
    private childItemLabel = 'Change';
    private childSystemArea = SYSTEM_AREA_ENUM.ChangeRecord;
    public infiniteScrollEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public get searchEnabled(): boolean {
        return this.searchEnabled$.value;
    }

    public get searchFiltersLoaded(): boolean {
        return this.searchFiltersLoaded$.value;
    }

    public teamUsersOptions$ = this._teamCode$.asObservable().pipe(
        skipWhile(code => !code),
        switchMap(code => this.teamsLogicService.loadAllMembersByKey(code)),
        map(users => {
            users = orderBy(users, 'firstName');
            users.unshift({ id: this.allUsers, label: 'All' } as any);
            users.unshift({ id: this.unassigned, label: 'Unassigned' } as any);
            return users;
        })
    );

    private _setPermissions = (teamCode: string): QSTeamPermissions | DesignTeamPermissions => {
        switch (teamCode) {
            case TEAM_CODES_CONST.QsTeamKey:
                return this.qsTeamPermissions;
            case TEAM_CODES_CONST.DesignTeamKey:
                return this.designTeamPermissions;
            default:
                return this.qsTeamPermissions;
        }
    };

    private _permissions$ = new BehaviorSubject<QSTeamPermissions | DesignTeamPermissions>(null);
    public permissions$ = this._teamCode$.asObservable().pipe(
        skipWhile(code => !code),
        map(this._setPermissions)
    );

    constructor(
        private readonly teamsLogicService: TeamsLogicService,
        private readonly logicService: GenericQuestionsLogicService,
        private readonly dialogService: CbDialogService,
        public cdRef: ChangeDetectorRef,
        private readonly navigationService: NavigationService,
        public readonly qsTeamPermissions: QSTeamPermissions,
        public readonly designTeamPermissions: DesignTeamPermissions,
        private readonly userCacheService: UserCacheService,
        public readonly route: ActivatedRoute,
        private readonly currentUser: CurrentUserService,
        private readonly schemesLogicService: DesignSchemesLogicService
    ) {
        this.permissions$.subscribe(this._permissions$);
    }

    public ngAfterViewChecked(): void {
        if (Object.keys(this.formLiteral?.controls)
            .length > 0 && !this._isFormInitialised) {
            this.form = this.formLiteral;
            this._isFormInitialised = true;
        }
    }

    public ngAfterViewInit(): void {
        this._initSearchFiltersCache();
        this.cdRef.detectChanges();
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public cancelQuestion(question: IGenericQuestionDocumentDto): void {
        const mappedItem = this.logicService.$createMappedItem(question);
        this.dialogService.confirm({
            dialogHeading: 'Cancel Question',
            message: `Are you sure you want to cancel Question #${question.questionNumber}?`,
            confirmed: () => {
                mappedItem.cancel().subOnce(result => {
                    if (result) {
                        // remove row from list
                        this.results = this.results.filter(value => value !== question);
                        this.cdRef.detectChanges();
                    }
                });
            }
        });
    }

    public fetchResults(): Observable<IGenericQuestionDocumentDto[]> {
        if (!this.searchEnabled) {
            return;
        }
        this.loaded = true;
        return this.logicService.$getSearchList(this._queryParams);
    }

    public editQuestion(question: IGenericQuestionDocumentDto): void {
        if (question.systemArea === SYSTEM_AREA_ENUM.DesignScheme) {
            this._performEditDesignSchemeQuestion(question);
        } else {
            this.childItems = [];
            this._performEditQuestion(question);
        }
    }

    private get _queryParams(): ITeamQuestionSearch {
        return this.searchEnabled ?
            {
                ...this.searchFilters.copyData(),
                userId: this.searchFilters.data.userId === this.allUsers ?
                    undefined :
                    this.searchFilters.data.userId ?
                        this.searchFilters.data.userId :
                        undefined,
                currentPage: this.currentPage,
                isUnassigned: !this.searchFilters.data.userId
            } :
            undefined;
    }

    private _initSearchFiltersCache(): void {
        this.searchFilters.init().then(() => {
            this.searchFiltersLoaded$.next(true);
            this.searchEnabled$.next(true);
            this._onSearchFiltersChanged();
            this._subscriptions.add(
                this.searchFilters.updated$.subscribe({
                    next: this._onSearchFiltersChanged
                })
            );

            this.searchFilters.silentData.canSeeAll = this._permissions$.value.canSeeAll();
            if (!this.searchFilters.silentData.canSeeAll) {
                this.searchFilters
                    .silentData
                    .userId = this.searchFilters.currentUserId;
            } else {
                this.searchFilters.data.userId = this.allUsers;
            }
        });
    }

    private readonly _onSearchFiltersChanged = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.currentPage = 1;
        this.searchFiltersChanged$.next(null);
    };

    private _performEditDesignSchemeQuestion(question: IGenericQuestionDocumentDto): void {
        this.schemesLogicService.getDesignSchemeLines(question.entityId)
            .subOnce(this._handleDesignSchemeLines.bind(undefined, question));

    }

    private _handleDesignSchemeLines = (question: IGenericQuestionDocumentDto, results: IDesignSchemeLineDto[]): void => {
        results.forEach(line => line.label = `${line.changeNumber} - ${line.changeRecordDetails}`);
        this.childItems = results;
        this._performEditQuestion(question);
    };

    public viewLot(lotId: number): void {
        if (lotId) {
            this.navigationService.navigate([`/lots/${lotId}/summary`]);
        }
    }

    private _performEditQuestion(question: IGenericQuestionDocumentDto): void {
        this.dialogService
            .open(
                ManageQuestionDialogComponent,
                {
                    data: {
                        childItemLabel: this.childItemLabel,
                        childItems: this.childItems,
                        childSystemArea: this.childSystemArea,
                        entityId: question.entityId,
                        mappedItem: this.logicService.$createMappedItem(question),
                        lotId: question.lotId,
                        mode: FormMode.Edit
                    },
                }
            ).afterClosed().subOnce(result => {
                if (result) {
                    this.searchFiltersChanged$.next(null);
                }
            });
    }

    private _isRequestingUser(question: IGenericQuestionDocumentDto): boolean {
        return (question.createdById === this.currentUser.guid);
    }

    public respondQuestion(question: IGenericQuestionDocumentDto): void {
        this.dialogService
            .open(
                GenericQuestionResponseDialogComponent,
                {
                    data: {
                        childItemLabel: this.childItemLabel,
                        childItems: this.childItems,
                        childSystemArea: this.childSystemArea,
                        entityId: question.entityId,
                        genericQuestion: this.logicService.$createMappedItem(question),
                        lotId: question.lotId,
                        readonly: false
                    },
                }
            ).afterClosed().subOnce(result => {
                if (result) {
                    this.searchFiltersChanged$.next(null);
                }
            });
    }

}

