<div #infiniteScrollContainer
     class="cb-padding-left-2x cb-padding-right-2x cb-padding-bottom-2x cb-padding-top flex-col flex">
    <mat-card class="flex-row flex-100">
        <div class="cb-margin-right-3x flex-col flex-35">
            <div class="flex-col">
                <h3 class="mat-title flex"
                    style="margin: 0;">MyClassic User Access
                </h3>
                <mat-divider [inset]="true"
                             class="cb-margin-bottom"> </mat-divider>
            </div>
            <div *ngIf="!contact.portalUsername"
                 class="cb-margin-top">
                <cb-info-message [message]="'This Contact does not have access enabled'"
                                 [icon]="'info'"></cb-info-message>
                <button mat-raised-button
                        color="primary"
                        class="cb-margin-bottom-2x cb-margin-top-2x"
                        (click)="createLogin()">
                    Create Login
                </button>
            </div>
            <div *ngIf="contact.portalUsername"
                 class="cb-margin-top cb-margin-3x flex-col">
                <cb-display-value-new label="Username"
                                      [value]="contact.portalUsername"></cb-display-value-new>
                <button mat-raised-button
                        color="primary"
                        class="cb-margin-bottom-2x"
                        (click)="changeUsername()">
                    Change Username
                </button>
                <button mat-raised-button
                        color="primary"
                        class="cb-margin-bottom-2x"
                        (click)="resetPassword()">
                    Reset Password
                </button>
                <div class="flex-row flex">
                    <button *ngIf="!contact.isPortalAccessEnabled"
                            [disabled]="hasExistingActiveUserWithDuplicateEmail"
                            mat-raised-button
                            color="primary"
                            class="cb-margin-bottom-2x"
                            (click)="enableAccess(true)">
        Enable Access
    </button>
                    <cb-info-popup *ngIf="!contact.isPortalAccessEnabled && hasExistingActiveUserWithDuplicateEmail"
                                   tooltip="Existing Active User With Duplicate Email Found">
    </cb-info-popup>
                </div>
                <button *ngIf="contact.isPortalAccessEnabled"
                        mat-raised-button
                        color="warn"
                        class="cb-margin-bottom-2x"
                        (click)="enableAccess(false)">
                    Disable Access
                </button>
            </div>
        </div>
        <div class="flex-col flex-100">
            <div class="flex-col">
                <h3 class="mat-title flex"
                    style="margin: 0;">Account Access
                </h3>
                <mat-divider [inset]="true"
                             class="cb-margin-bottom"> </mat-divider>
            </div>
            <div class="cb-table cb-margin-top">
                <mat-table [dataSource]="businessAccounts">
                    <ng-container matColumnDef="tradingName">
                        <mat-header-cell *matHeaderCellDef> Trading Name </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.tradingName}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.type}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions"
                                  stickyEnd>
                        <mat-header-cell *matHeaderCellDef> Portal Access</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div *ngIf="element.isPortalAccessEnabled">
                                <button mat-icon-button>
                                    <mat-icon>check_circle</mat-icon>
                                </button>
                                <span>Access Enabled</span>
                            </div>
                            <div *ngIf="!element.isPortalAccessEnabled">
                                <button mat-icon-button>
                                    <mat-icon>block_black</mat-icon>
                                </button>
                                <span>Access Disabled</span>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <cb-info-message *ngIf="businessAccountsLoaded && businessAccounts.length === 0"
                                 [message]="'No Business Accounts Found'"
                                 [icon]="'info_circle'"></cb-info-message>
                <cb-loading-spinner *ngIf="!businessAccountsLoaded"></cb-loading-spinner>
            </div>
        </div>
    </mat-card>
</div>
