<div class="flex-col flex">
    <div class="mat-subheading-2 widget-name">{{label}}</div>
    <div class="widget-container flex-col">
        <div class="widget-list-item flex-col"
             *ngFor="let group of groups; let i = index">
            <div class="cb-padding-top cb-padding-bottom start-start flex-row flex">
                <div class="flex-col flex">
                    <span class="mat-body-1">{{group.key}}</span>
                    <div class="flex-col"
                         *ngFor="let child of group.children">
                        <span class="mat-caption">{{child.label}}</span>
                    </div>
                </div>
                <!-- TODO: Understand how this works and pass the itemRequiringAction to viewItemUrl instead
                     of whatever the group is. -->
                <button mat-icon-button
                        class="cb-margin-right"
                        (click)="goToItem(viewItemUrl($any(group)), $event)">
                    <mat-icon>pageview</mat-icon>
                </button>
            </div>
            <hr *ngIf="i < groups.length-1"
                class="mat-divider"/>
        </div>
        <span class="center-center flex layoutGap-6"
              *ngIf="!groups || !groups.length">
            <mat-icon class="mat-accent">info_circle</mat-icon>
            <div>
                There are no {{label || 'Items'}} requiring action
            </div>
        </span>
    </div>
</div>
