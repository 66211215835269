import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {DOCUMENT_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import { BehaviorSubject, debounceTime, defaultIfEmpty, filter, startWith, Subscription } from 'rxjs';

import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { IDocumentPermissions } from '@app/core/permissions/base.permissions';
import { IDocumentEntityDto } from '@app/logic/documents';
import { DocumentGroupLogicService } from '@app/logic/documents/document-group.logic.service';
import { DocumentsLogicService } from '@app/logic/documents/documents.logic.service';
import { IDocumentEntity } from '@app/logic/documents/interfaces/i.document-entity';
import { IDocumentGroupDto, IDocumentGroupFrontEndDto } from '@app/logic/documents/interfaces/i.document-group.dto';
import { IDocumentGroupMappedItem } from '@app/logic/documents/interfaces/i.document-group.mapped';
import { IDocumentEntityMappedItem } from '@app/logic/documents/interfaces/i.document.mapped';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { IBaseLogicService } from '../../../../../logic/base/interfaces/i.base-logic.service';
import { IBaseMappedItem } from '../../../../../logic/base/interfaces/i.base-mapped-item';
import { IDocumentTableExtraColumn } from '../../interfaces/i.document-table-column';
import { DocumentService } from '../../services/document.service';
import { DocumentUploadDialogComponent } from '../document-upload-dialog/document-upload-dialog';
import {DocumentStubDialogComponent} from '@app/shared/components/documents/components/document-stub-dialog/document-stub-dialog.component';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';

@Component({
    selector: 'cb-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit, OnDestroy {
    @Input() public readonly allowReviewAtUpload: boolean = false;
    @Input() public readonly entity: IBaseMappedItem<any, any, IBaseLogicService<any, any>> & IDocumentEntity<string | number>;
    @Input() public readonly permissions: IDocumentPermissions;
    @Input() public readonly lockRequired: boolean;
    @Input() public readonly includeDeleted: boolean = true;
    @Input() public readonly includeLinked: boolean;
    @Input() public readonly hideSearch: boolean = false;
    @Input() public readonly hideUploadButton: boolean;
    @Input() public readonly hideDocumentStubButton: boolean = true;
    @Input() public readonly hideDeletedToggle: boolean;
    @Input() public readonly hideRenewalColumn: boolean;
    @Input() public readonly noCard: boolean;
    @Input() public readonly edit: boolean = true;
    @Input() public readonly systemArea: number;
    @Input() public readonly prefixColumns: IDocumentTableExtraColumn[] = [];
    @Input() public readonly dtoDefinition: IDocumentEntityDto;
    @Input() public readonly enableCheckbox: boolean = false;
    @Input() public readonly documentGroupCode: string;
    @Input() public readonly documentStubGroupCode: string;
    @Input() public readonly documentStubDialogTitle: string;
    public selectedDocumentGroup: IDocumentGroupDto;

    @Output() public showDeletedEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() public pushAndRefreshDocumentsListEvent: EventEmitter<IDocumentEntityMappedItem> = new EventEmitter();

    @Output() public documentCountChange = new EventEmitter<number>();
    @Output() public documentsChange = new EventEmitter<IDocumentEntityMappedItem[]>();

    @Output() public documentUploaded = new EventEmitter<IDocumentEntityMappedItem>();

    public documents$$: BehaviorSubject<IDocumentEntityMappedItem[]> = new BehaviorSubject(null);
    @Input() public set documents(_documentMappedItems: IDocumentEntityMappedItem[]) {
        if (_documentMappedItems) {
            this.documents$$.next(_documentMappedItems);
            this.emitDocumentChanges();
        }
    }
    public get documents(): IDocumentEntityMappedItem[] {
        return this.documents$$?.value;
    }

    public readonly documentGroups$$: BehaviorSubject<IDocumentGroupMappedItem[]> = new BehaviorSubject(null);
    public set documentGroups(documentGroupMappedItem: IDocumentGroupMappedItem[]) {
        if (documentGroupMappedItem) {
            this.documentGroups$$.next(documentGroupMappedItem);
        }
    }
    public get documentGroups(): IDocumentGroupMappedItem[] {
        return this.documentGroups$$?.value;
    }


    public readonly filteredDocuments$$: BehaviorSubject<IDocumentEntityMappedItem[]> = new BehaviorSubject(null);

    public set filteredDocuments(mappedItem: IDocumentEntityMappedItem[]) {
        if (mappedItem) {
            this.filteredDocuments$$.next(mappedItem);
        }
    }

    public get filteredDocuments(): IDocumentEntityMappedItem[] {
        return this.filteredDocuments$$?.value;
    }

    public availableForUploadDocumentGroups: IDocumentGroupDto[];

    public readonly subscription = new Subscription();
    public searchText = new UntypedFormControl();

    public documentsLoaded = false;
    public showDeleted = false;

    constructor(
        public readonly documentsLogicService: DocumentsLogicService,
        public readonly documentGroupLogicService: DocumentGroupLogicService,
        public readonly dialog: MatDialog,
        public readonly cbDialog: CbDialogService,
        private readonly documentService: DocumentService,
    ) {
        this.subscription.add(
            this.documentService.any$.subscribe(this.handleDocumentUpdate),
        );

        this.subscription.add(
            this.documentService.documentUploaded.subscribe((uploadedDocument) => {
                this.documentUploaded.emit(uploadedDocument);
            }),
        );

        this.subscription.add(
            this.documentService.refreshDocumentList.subscribe(() => {
                this.loadDocuments();
            })
        );
    }

    private loadDocuments(): void {
        this.documentsLogicService
            .getMappedDocumentEntities(
                this.entity.$logicService.$baseUri,
                this.entity.documentEntityId,
                this.includeLinked,
                this.includeDeleted,
                this.dtoDefinition,
                this.documentGroupCode
            )
            .subOnce({
                next: (result) => {
                    this.documents = result;
                    this.filteredDocuments = this.documents;
                    this.createSearchTextSub();
                    this.emitDocumentChanges();
                }
            });
    }

    public ngOnInit(): void {
        this.loadDocuments();

        this.documentGroupLogicService.getMappedDocumentGroups(this.entity.$logicService.$baseUri, this.entity.documentEntityId, this.systemArea)
            .subOnce({
                next: (result) => {
                    if (isNullOrWhiteSpace(this.documentGroupCode)) {
                        this.documentGroups = result;
                    } else {
                        this.documentGroups = result.filter(x => x.codeName === this.documentGroupCode);
                    }
                }
            });
    }

    public onDocumentsChange(): void {
        this.loadDocuments();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private readonly handleDocumentUpdate = (document: IDocumentEntityMappedItem): void => {
        const existingIndex = this.documents.findIndex(x => x.id === document.id);
        if (existingIndex > -1) {
            this.documents[existingIndex] = document;
        } else {
            this.documents.push(document);
        }
        this.emitDocumentChanges();
    };

    private emitDocumentChanges(): void {
        this.documentsChange.emit(this.documents);
        const filteredDocuments = this.showDeleted ? this.documents : this.documents?.filter(x => x.documentStatus !== DOCUMENT_STATUS_ENUM.StubDeleted && !x.document?.isDeleted);
        this.documentCountChange.emit(filteredDocuments?.length ?? 0);
    }

    public showDeletedChanged(): void {
        this.showDeletedEvent.emit(this.showDeleted);
        this.emitDocumentChanges();
    }

    public uploadDocument(): void {

        this.filterAvailableGroups();

        const dialogRef = this.dialog
            .open(
                DocumentUploadDialogComponent,
                {
                    data: {
                        documentEntityMappedItem: this.documentsLogicService.createMappedDocumentEntityItem(this.entity.documentEntityUri, this.entity.documentEntityId),
                        documentGroups: this.documentGroups,
                        $baseUri: this.entity.$logicService.$baseUri,
                        $documentEntityId: this.entity.documentEntityId,
                        systemArea: this.systemArea,
                        allowReviewAtUpload: this.allowReviewAtUpload,
                        selectedDocumentGroupId: this.selectedDocumentGroup?.id
                    },
                    panelClass: 'cb-dialog-container',
                    minWidth: 400,
                    width: '600px',
                }
            );

        const subscribeDialog = dialogRef.componentInstance.pushAndRefreshDocumentsListEmitter.subscribe(data => {
            this.pushAndRefreshDocumentsListEvent.emit(data);
        });

        dialogRef.afterClosed().subOnce(result => {
            subscribeDialog.unsubscribe();
            this.documentService.documentUploaded.next(result);
        });

    }

    public addDocumentStubs(): void {
        const dialogRef = this.cbDialog.open(DocumentStubDialogComponent, {
            data: {
                entityId: this.entity.documentEntityId,
                systemArea: this.systemArea,
                documentStubGroupCode: this.documentStubGroupCode,
                stubTypeForTitle: this.documentStubDialogTitle
            },
        });

        dialogRef.afterClosed().subOnce((result) => {
            if (result) {
                this.documentService.refreshDocumentList.next(true);
            }
        });
    }

    public filterAvailableGroups(): void {
        this.availableForUploadDocumentGroups =
            this.documentsLogicService.filterAvailableGroups(this.documentGroups.map(x => x.$getMappedDtoItem() as IDocumentGroupFrontEndDto), this.systemArea);
    }

    public search(query: string): void {
        this.filteredDocuments = this.documents && this.documents.filter(x => {
            return [x.document && x.document.name, x.documentType && x.documentType.label]
                .filter(y => y != null)
                .join(' ')
                .toLowerCase()
                .trim()
                .includes(
                    query
                        .toLowerCase()
                        .trim()
                );
        });
    }

    private createSearchTextSub(): void {
        this.subscription.add(
            this.searchText.valueChanges
                .pipe(
                    defaultIfEmpty(''),
                    startWith(''),
                    debounceTime(400),
                    filter(q => typeof q === 'string')
                )
                .subscribe((query: string) => {
                    this.search(query);
                })
        );
    }

}
