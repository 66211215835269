import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IIntegrationMessageSearchDto, IntegrationLogsLogicService } from '@app/logic/integration-logs';
import { SearchIndexesLogicService } from '@app/logic/search-indexes/search-indexes.logic.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { SEARCH_INDEX_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { orderBy } from 'lodash';
import { BehaviorSubject, debounceTime, defaultIfEmpty, filter, Observable, startWith, Subject, Subscription } from 'rxjs';
import { MessageViewDialogComponent } from '../message-view-dialog/message-view-dialog.component';
import { MESSAGE_NAMES } from './message-names.enum';

@Component({
    selector: 'cb-message-history',
    templateUrl: './message-history.component.html',
    styleUrls: ['./message-history.component.scss']
})
export class MessageHistoryComponent implements OnInit, OnDestroy {

    public searchText = new UntypedFormControl();
    public dateFrom: string;
    public dateTo: string;
    public messageName: string;

    public messageNames = [
        { id: '0', label: 'Any' },
    ];

    public logs: IIntegrationMessageSearchDto[] = [];
    public currentPage: number;
    public readonly queryUpdated = new Subject();

    public readonly subscriptions$ = new Subscription();
    public infiniteScrollEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public readonly isNullOrWhiteSpace = isNullOrWhiteSpace;

    public readonly columns = [
        { label: 'Datetime', width: 150 },
        { label: 'Instance ID', width: 150 },
        { label: 'Instance IP', width: 150 },
        { label: 'Sent/Received' },
        { label: 'Message Name' },
        { label: 'Message Version' },
        { label: '' },
    ];

    constructor(
        private readonly integrationLogsLogic: IntegrationLogsLogicService,
        private readonly cbDialog: CbDialogService,
        private readonly searchIndexesLogic: SearchIndexesLogicService,
    ) {
        this.messageNames.push(
            ...orderBy(Object.keys(MESSAGE_NAMES).map(x => ({ id: x, label: x })), 'label')
        );
    }

    public ngOnInit(): void {
        this.createSearchTextSub();
    }

    public ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    public clearFilters(): void {
        this.dateFrom = null;
        this.dateTo = null;
        this.messageName = null;
        this.searchText.setValue('');
    }

    public clearDateTo(): void {
        this.dateTo = null;
        this.cleanSearch();
    }

    public clearDateFrom(): void {
        this.dateFrom = null;
        this.cleanSearch();
    }

    public clearMessageName(): void {
        this.messageName = null;
        this.cleanSearch();
    }

    public cleanSearch(): void {
        this.infiniteScrollEnabled.next(true);
        this.queryUpdated.next(null);
    }

    public search(): Observable<ISearchResult<IIntegrationMessageSearchDto>> {
        const params = {
            currentpage: this.currentPage,
            query: this.searchText.value ?? '',
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            messageName: isNaN(+this.messageName) ? this.messageName : null,
        };
        return this.integrationLogsLogic
            .searchMessages(params);
    }

    public viewMessage(message: IIntegrationMessageSearchDto): void {
        this.cbDialog.open(MessageViewDialogComponent, {
            data: {
                message
            },
            minWidth: '40%',
        });
    }

    public initiatePing(): void {
        const ref = this.cbDialog.block('Pinging...');
        this.integrationLogsLogic.sendPing().subOnce(() => {
            setTimeout(() => {
                ref.close();
                this.clearFilters();
            }, 1000);
        });
    }

    public reindexLogs(): void {
        const ref = this.cbDialog.block('Fetching Latest Messages...');
        this.searchIndexesLogic.reindex(SEARCH_INDEX_ENUM.FinanceMessagingLogs).subOnce(() => {
            setTimeout(() => {
                ref.close();
                this.cleanSearch();
            }, 1000);
        });
    }

    private createSearchTextSub(): void {
        this.subscriptions$.add(
            this.searchText.valueChanges
                .pipe(
                    defaultIfEmpty(''),
                    startWith(''),
                    debounceTime(400),
                    filter(q => typeof q === 'string')
                )
                .subscribe(() => {
                    this.cleanSearch();
                })
        );
    }
}
