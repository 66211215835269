import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ProductSearchService } from '@app/core/services/search/product.search.service';
import { ProductLogicService } from '@app/logic/products';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';
import { AutocompleteScrollerComponent, getBaseAutocompleteScrollerProvider } from '../../autocomplete-scroller.component';

@Component({
    selector: 'cb-product-autocomplete-scroller',
    templateUrl: 'product-autocomplete-scroller.component.html',
    styleUrls: ['../../autocomplete-scroller.component.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(ProductAutocompleteScrollerComponent),
        getBaseAutocompleteScrollerProvider(ProductAutocompleteScrollerComponent),
        ProductSearchService,
    ]
})
export class ProductAutocompleteScrollerComponent
    extends AutocompleteScrollerComponent<any> // TODO: The results of the product autocomplete scroller should be typed
    implements OnInit {
    @Input() public readonly displayCategories: boolean;
    @Input() public readonly productType: number;
    @Input() public readonly hideCompositeItems: boolean;
    @Input() public readonly includeCategories: boolean;
    @Input() public readonly orderByStandardProduct: boolean;

    private _strictSearch: boolean;
    public get strictSearch(): boolean {
        return this._strictSearch;
    }

    @Input() public set strictSearch(enabled: boolean) {
        this._strictSearch = enabled;
        this.searchService.strictSearch = enabled;
        // Do not re-perform a search as strict search is only a parameter to use when searching
        // and should not trigger a search as it will clear any selected product
    }

    private _categoryId: number;
    public get categoryId(): number {
        return this._categoryId;
    }

    @Input() public set categoryId(id: number) {
        this._categoryId = id;
        this.searchService.categoryId = id;
        this.reperformSearch();
    }

    private readonly separator = ' - ';

    public results: any[] = [];
    public searchResultsLoaded = false;

    constructor(
        public readonly productLogicService: ProductLogicService,
        public readonly dialog: MatDialog,
        public readonly searchService: ProductSearchService
    ) {
        super(dialog, searchService);
    }

    public ngOnInit(): void {
        this.searchService.productType = this.productType;
        this.searchService.categoryId = this.categoryId;
        this.searchService.hideCompositeItems = this.hideCompositeItems;
        this.searchService.includeCategories = this.includeCategories;
        this.searchService.strictSearch = this.strictSearch;
        this.searchService.orderByStandardProduct = this.orderByStandardProduct;
        super.ngOnInit();
    }

    public getDisplayText(item: any): string {
        const title: string[] = [];
        title.push(item.name || item.offering);
        if (item.code || item.offeringId) {
            title.push(item.code || item.offeringId);
        }
        return title.join(this.separator);
    }

    public getCategoryDisplay(item: any): string {
        if (item.categoryPath) {
            let terms = item.categoryPath;
            const firstCategory = item.categoryPath[0];
            if (firstCategory != null && firstCategory.label) {
                terms = terms.map(category => category.label);
            }
            const categoryList = (terms != null && terms.length > 0) ? terms.join(' > ') : '';
            return `${categoryList} ${this.separator}`;
        }
        return '';
    }

    public getItemUom(item: any): string {
        return item.uomDisplay ? ` (${item.uomDisplay})` : '';
    }

    public displayWith = (item: any): string => {
        let displayText = '';
        if (item) {
            if (this.displayCategories) {
                displayText = this.getCategoryDisplay(item);
            }
            displayText += this.getDisplayText(item) + this.getItemUom(item);
        }
        return displayText;
    };

    public getOptionIsStandardProduct(option: any): boolean {
        return option.isStandardProduct;
    }
}
