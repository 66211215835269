import { BaseMappedItem } from '../../base/base-mapped-item';
import { IBuildingConsentDto } from './interfaces/i.building-consent.dto';
import { IBuildingConsentMappedItem } from './interfaces/i.building-consent.mapped';
import { IBuildingConsentLogicService } from './interfaces/i.building-consent-logic.service';
import { BuildingConsentStatusEnumId, BUILDING_CONSENT_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { Computed } from '@app/logic/base/computed-prop.decorator';
import { Observable } from 'rxjs';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { DtoProp } from '@app/logic/base/dto-prop.decorator';

export class BuildingConsentMappedItem
    extends BaseMappedItem<IBuildingConsentDto, IBuildingConsentMappedItem, IBuildingConsentLogicService>
    implements IBuildingConsentMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public councilId: number;
    @DtoProp public councilName: string;
    @DtoProp public lotId: number;
    @DtoProp public lotLabel: string;
    @DtoProp public consentFor: string;
    @DtoProp public consentNumber: string;
    @DtoProp public consentStatus: BuildingConsentStatusEnumId;
    @DtoProp public consentStatusLabel: string;
    @DtoProp public consentType: number;
    @DtoProp public consentTypeLabel: string;
    @DtoProp public submittedToCouncilDate: string;
    @DtoProp public receivedByCouncilDate: string;
    @DtoProp public consentDueDate: string;
    @DtoProp public consentEstimatedDueDate: string;
    @DtoProp public consentReceivedDate: string;
    @DtoProp public consentExpiryDate: string;
    @DtoProp public codeOfComplianceExpiryDate: string;
    @DtoProp public consentGrantedDate: string;
    @DtoProp public assignedToUserId: string;
    @DtoProp public assignedToUserName: string;
    @DtoProp public consentGranted: boolean;
    @DtoProp public isPrimary: boolean;
    @DtoProp public lotHasExistingIsPrimary: boolean;
    @DtoProp public isDeleted: boolean;
    @DtoProp public hasUnresolvedRfi: boolean;
    @DtoProp public grantedById: string;
    @DtoProp public grantedByUserName: string;
    @DtoProp public grantedDateTime: string;
    @DtoProp public submittedToCouncilByUserId: string;
    @DtoProp public submittedToCouncilByUserName: string;
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;

    public get documentEntityId(): number {
        return this.id;
    }

    public get documentEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    @Computed() public get isApproved(): boolean {
        return this.consentStatus === BUILDING_CONSENT_STATUS_ENUM.Approved;
    }

    @Computed() public get isComplete(): boolean {
        return this.consentStatus === BUILDING_CONSENT_STATUS_ENUM.Approved
            || this.consentStatus === BUILDING_CONSENT_STATUS_ENUM.Declined
            || this.isDeleted;
    }

    @Computed() public get canSetInQueue(): boolean {
        return this.consentStatus === BUILDING_CONSENT_STATUS_ENUM.Pending
            && !isNullOrWhiteSpace(this.assignedToUserId);
    }

    @Computed() public get canSetApproved(): boolean {
        return this.consentStatus !== BUILDING_CONSENT_STATUS_ENUM.Approved
            && this.consentStatus !== BUILDING_CONSENT_STATUS_ENUM.Declined
            && this.consentStatus !== BUILDING_CONSENT_STATUS_ENUM.None;
    }

    @Computed() public get canSetInProgress(): boolean {
        return this.consentStatus !== BUILDING_CONSENT_STATUS_ENUM.Approved
            && this.consentStatus !== BUILDING_CONSENT_STATUS_ENUM.Declined
            && this.consentStatus !== BUILDING_CONSENT_STATUS_ENUM.None
            && !isNullOrWhiteSpace(this.assignedToUserId);
    }

    @Computed() public get canSetOnHold(): boolean {
        return this.consentStatus === BUILDING_CONSENT_STATUS_ENUM.InProgress;
    }

    @Computed() public get canSetSubmitted(): boolean {
        return this.consentStatus !== BUILDING_CONSENT_STATUS_ENUM.Approved
            && this.consentStatus !== BUILDING_CONSENT_STATUS_ENUM.Declined
            && this.consentStatus !== BUILDING_CONSENT_STATUS_ENUM.None
            && this.consentStatus !== BUILDING_CONSENT_STATUS_ENUM.SubmittedAwaitingConfirmation
            && this.consentStatus !== BUILDING_CONSENT_STATUS_ENUM.SubmittedCouncilConfirmed
            && !isNullOrWhiteSpace(this.assignedToUserId);
    }

    @Computed() public get canSetCouncilReceived(): boolean {
        return this.consentStatus === BUILDING_CONSENT_STATUS_ENUM.SubmittedAwaitingConfirmation
            && !isNullOrWhiteSpace(this.assignedToUserId);
    }

    @Computed() public get hasBeenReceived(): boolean {
        return this.consentStatus === BUILDING_CONSENT_STATUS_ENUM.SubmittedCouncilConfirmed
            || this.consentStatus === BUILDING_CONSENT_STATUS_ENUM.Approved;
    }

    @Computed() public get canAddCouncilRfisToConsent(): boolean {
        return this.consentStatus === BUILDING_CONSENT_STATUS_ENUM.SubmittedCouncilConfirmed;
    }

    constructor(
        sourceData: IBuildingConsentDto,
        logicService: IBuildingConsentLogicService
    ) {
        super(sourceData, logicService, BuildingConsentMappedItem);
    }

    public delete(): Observable<boolean> {
        return this.$logicService.$deleteItem<boolean>(this.id);
    }

    public getElapsedTime(): Observable<string> {
        return this.$logicService.getElapsedTime(this.id);
    }
}
