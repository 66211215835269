<div class="cb-margin-top start-center flex-row">
    <h3 class="mat-title flex">
        Design Scheme Notifications
    </h3>
    <button mat-raised-button
            (click)="newItemClicked();"
            color="primary">
        Add New Design Scheme Stage
    </button>
</div>
<cb-table class="cb-margin-top"
          [loaded]="true"
          [stringColumns]="columns"
          [maxHeightOffset]="256">
    <tr cbTableRow
        *ngFor="let result of searchResults">
        <cb-td-text [value]="result.label"></cb-td-text>
        <cb-td-checkbox [value]="result.isActive"></cb-td-checkbox>
        <td cbTableStickyCol="end"
            cbTableShrinkCol>
            <button mat-icon-button
                    (click)="editItemClickedForDto(result)">
                <mat-icon>
                    edit
                </mat-icon>
            </button>
        </td>
    </tr>
</cb-table>
