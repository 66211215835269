import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ChangeRecordLogicService} from '@app/logic/change-records/change-record.logic-service';
import {IWorkingDrawingMappedItem} from '@app/logic/working-drawings';
import {
    DESIGN_SCHEME_LINE_STATUS_ENUM,
    IUnassignedChangeRecordDto,
    IWorkingDrawingChangeDto,
    WORKING_DRAWING_CHANGE_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import {DragulaService} from 'ng2-dragula';
import {Subscription} from 'rxjs';

@Component({
    selector: 'cb-working-drawings-assigned-changes',
    templateUrl: './working-drawings-assigned-changes.component.html',
    styleUrls: ['./working-drawings-assigned-changes.component.scss']
})
export class WorkingDrawingsAssignedChangesComponent implements OnInit, OnDestroy {


    private _mappedItem: IWorkingDrawingMappedItem;
    @Input() public set mappedItem(_workingDrawing: IWorkingDrawingMappedItem) {
        this._mappedItem = _workingDrawing;
        this.assignedChangeRecords = _workingDrawing.changes;
    }

    public get mappedItem(): IWorkingDrawingMappedItem {
        return this._mappedItem;
    }

    @Input() public parentForm: NgForm;

    public readonly ITEMS = 'ITEMS';

    protected readonly WORKING_DRAWING_CHANGE_STATUS_ENUM = WORKING_DRAWING_CHANGE_STATUS_ENUM;

    public assignedChangeRecords: IWorkingDrawingChangeDto[] = [];
    public unassignedChangeRecords: IUnassignedChangeRecordDto[] = [];

    public subscriptions$ = new Subscription();

    constructor(
        private readonly dragulaService: DragulaService,
        private readonly changeRecordLogicService: ChangeRecordLogicService,) {
        this.setupDragula();
    }


    public ngOnInit(): void {
        this.loadUnassignedChangeRecords();
    }

    public ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
        this.dragulaService.destroy(this.ITEMS);
    }


    private loadUnassignedChangeRecords(): void {
        this.changeRecordLogicService
            .getUnassignedChangeRecords(this.mappedItem.lotId)
            .subOnce((results: any) => {
                this.unassignedChangeRecords = results || [];
            });
    }

    private setupDragula(): void {

        this.dragulaService.createGroup(this.ITEMS, {
            removeOnSpill: true,
            accepts: (el: Element) => !el.classList.contains('dragula-ignore'), // do not allow move of info
            moves: (el: Element) => !el.classList.contains('dragula-ignore'), // do not allow move of info
        });

        this.subscriptions$.add(
            this.dragulaService.drop().subscribe(
                args => {
                    this.mappedItem.changes = this.assignedChangeRecords;
                    this.parentForm.form.markAsDirty();
                }
            )
        );

    }
}
