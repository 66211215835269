import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QSTeamPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { IQsTeamTakeoffSearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { TakeoffsLogicService } from '@app/logic/takeoffs/takeoffs.logic.service';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { ITakeOffDtoSummary, ITakeOffTaskSearchResultDto, TAKE_OFF_TASK_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { orderBy } from 'lodash';
import { BehaviorSubject, map, Observable, of, Subject, Subscription } from 'rxjs';

@Component({
    selector: 'cb-takeoffs',
    templateUrl: './takeoffs.component.html',
    styleUrls: ['./takeoffs.component.scss'],
})
export class TakeoffsComponent implements OnDestroy, AfterViewInit {

    public resultOrders$ = of([
        { id: 'cost', label: 'Cost' },
        { id: 'importeddate', label: 'Imported Date' },
        { id: 'jobnumber', label: 'Job Number' },
        { id: 'takeoffnumber', label: 'TakeOff Number' },
    ]);

    @Input() public resultDirections$: Observable<{
        id: string;
        label: string;
    }[]>;

    private _subscriptions = new Subscription();
    public searchFiltersLoaded$ = new BehaviorSubject(null);
    public searchEnabled$ = new BehaviorSubject(null);
    public readonly searchFiltersChanged$ = new Subject();
    public currentPage: number;
    public readonly allUsers = '999';
    public readonly unassigned = undefined;
    public loaded = false;
    public results: ITakeOffTaskSearchResultDto[] = [];
    public infiniteScrollEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public get searchEnabled(): boolean {
        return this.searchEnabled$.value;
    }

    public get searchFiltersLoaded(): boolean {
        return this.searchFiltersLoaded$.value;
    }

    public takeoffStatuses$ = of(TAKE_OFF_TASK_STATUS_ENUM.toSelectList())
        .pipe(
            map(takeoffStatuses => {
                return orderBy(takeoffStatuses, tag => tag.label.toLowerCase());
            })
        );

    public get searchFilters(): UserCacheItem<IQsTeamTakeoffSearch> {
        return this.userCacheService.qsTeamTakeoffSearch;
    }

    public teamUsersOptions$ = this.teamsLogicService
        .loadAllMembersByKey('QSTEAM').pipe(
            map(users => {
                users = orderBy(users, 'firstName');
                users.unshift({ id: this.allUsers, label: 'All' } as any);
                users.unshift({ id: this.unassigned, label: 'Unassigned' } as any);
                return users;
            })
        );

    constructor(
        private readonly teamsLogicService: TeamsLogicService,
        private readonly logicService: TakeoffsLogicService,
        public cdRef: ChangeDetectorRef,
        private readonly navigationService: NavigationService,
        public readonly qsTeamPermissions: QSTeamPermissions,
        private readonly userCacheService: UserCacheService,
        public readonly route: ActivatedRoute,
    ) {

    }

    public ngAfterViewInit(): void {
        this._initSearchFiltersCache();
        this.cdRef.detectChanges();
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public canDownloadTakeOff(takeoff: ITakeOffDtoSummary): Observable<boolean> {
        return of(takeoff.transferHistoryId > 0);
    }

    public viewTakeOff(takeOff: ITakeOffTaskSearchResultDto): void {
        this.navigationService.navigate([`/lots/${takeOff.lotId}/costs/takeoffs`]);
    }

    public getTransferHistoryFile(transferHistoryId: number): void {
        if (transferHistoryId) {
            this.logicService.downloadTakeoffFile(transferHistoryId).subOnce();
        }
    }

    public fetchResults(): Observable<ITakeOffTaskSearchResultDto[]> {
        if (!this.searchEnabled) {
            return;
        }
        this.loaded = true;
        return this.logicService.$getSearchList(this._queryParams);
    }

    private get _queryParams(): IQsTeamTakeoffSearch {
        return this.searchEnabled ?
            {
                ...this.searchFilters.copyData(),
                userId: this.searchFilters.data.userId === this.allUsers ?
                    undefined :
                    this.searchFilters.data.userId ?
                        this.searchFilters.data.userId :
                        undefined,
                statusId: this.searchFilters.data.statusId,
                currentPage: this.currentPage,
                isUnassigned: !this.searchFilters.data.userId
            } :
            undefined;
    }

    private _initSearchFiltersCache(): void {
        this.searchFilters.init().then(() => {
            this.searchFiltersLoaded$.next(true);
            this.searchEnabled$.next(true);
            this._subscriptions.add(
                this.searchFilters.updated$.subscribe({
                    next: this._onSearchFiltersChanged
                })
            );

            this.searchFilters.silentData.canSeeAll = this.qsTeamPermissions.canSeeAll();
            if (!this.searchFilters.silentData.canSeeAll) {
                this.searchFilters
                    .silentData
                    .userId = this.searchFilters.currentUserId;
            }
            else {
                this.searchFilters.data.userId = this.allUsers;
            }
            this._onSearchFiltersChanged();
        });
    }

    private readonly _onSearchFiltersChanged = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.currentPage = 1;
        this.searchFiltersChanged$.next(null);
    };
}
