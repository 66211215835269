<cb-dialog [dialogHeading]="'Purchase Orders that include' + (data.lotSpecItem.quoteLineDescription ? data.lotSpecItem.quoteLineDescription : data.lotSpecItem.productDisplay)">
    <cb-table [loaded]="true"
              [stringColumns]="columns">
        <tbody cbTableBody>
            <tr *ngFor="let purchaseOrder of purchaseOrders">
                <cb-td-text [value]="purchaseOrder.id"></cb-td-text>
                <cb-td-date [value]="purchaseOrder.date"></cb-td-date>
                <cb-td-text [value]="purchaseOrder.quantity"></cb-td-text>
                <cb-td-text [value]="purchaseOrder.status"></cb-td-text>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <button *ngIf="purchaseOrder.id"
                            mat-icon-button
                            matTooltip="Download Purchase Order"
                            (click)="downloadPurchaseOrder(purchaseOrder.id)">
                        <mat-icon>file_download</mat-icon>
                    </button>
                </td>
            </tr>
            <tr *ngIf="purchaseOrders.$resolved && purchaseOrders.length < 1 ">
                <td colspan="5">
                    <mat-icon class="mat-accent">info</mat-icon>
                    There are no Purchase Orders that include this Lot Spec Item.
                </td>
            </tr>
            <tr *ngIf="!purchaseOrders.$resolved">
                <td colspan="5">
                    <cb-loading-spinner></cb-loading-spinner>
                </td>
            </tr>
        </tbody>
    </cb-table>
    <footer class="end-center flex-row">
        <button mat-raised-button
                (click)="cancel()">Close</button>
    </footer>
</cb-dialog>
