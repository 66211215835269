import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { IBuildContingencyRecordsLogicService } from './interfaces/i.build-contingency-records.logic.service';
import { IBuildContingencyRecordMappedItem } from './interfaces/i.build-contingency-record.mapped';
import { BuildContingencyRecordMappedItem } from './build-contingency-record.mapped';
import { IBuildContigencyRecordDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Injectable()
export class BuildContingencyRecordsLogicService
    extends BaseLogicService<IBuildContigencyRecordDto, IBuildContingencyRecordMappedItem>
    implements IBuildContingencyRecordsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'buildcontingencynotifications',
            BuildContingencyRecordMappedItem,
        );
    }

    public getByBusinessEntity(beId: number): Observable<IBuildContigencyRecordDto[]> {
        return this.$http
            .get<Array<IBuildContigencyRecordDto>>(`businessentities/${beId}/buildcontingencynotifications`);
    }
}
