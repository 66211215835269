import { IUpdateImageDto } from './../../shared/interfaces/i.update-image.dto';
import { BaseMappedItem } from '../base/base-mapped-item';
import { ITempLotsLogicService } from './interfaces/i.temp-lots.logic.service';
import { ITempLotMappedItem } from './interfaces/i.temp-lot.mapped';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { Computed } from '../base/computed-prop.decorator';
import { DtoProp } from '../base/dto-prop.decorator';
import { IAddressDto, IIdAndLabelDto, ILotAmenitiesDto, ISkinnyProjectReleaseStageDto, ITempLotDto, LotContractTypeEnumId } from '@classictechsolutions/hubapi-transpiled-enums';

export class TempLotMappedItem
    extends BaseMappedItem<ITempLotDto, ITempLotMappedItem, ITempLotsLogicService>
    implements ITempLotMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public lotNumber: number;
    @DtoProp public jobNumber: string;
    @DtoProp public status: IIdAndLabelDto;
    @DtoProp public sellingPrice: number;
    @DtoProp public amenities: ILotAmenitiesDto;
    @DtoProp public floorPlanUrl: string;
    @DtoProp public renderImageUrl: string;
    @DtoProp public lotAddress: IAddressDto;
    @DtoProp public projectReleaseStage: ISkinnyProjectReleaseStageDto;
    @DtoProp public project: IIdAndLabelDto;
    @DtoProp public isActive: boolean;
    @DtoProp public isPublished: boolean;
    @DtoProp public isKiwiBuild: boolean;
    @DtoProp public isGrowhome: boolean;
    @DtoProp public isKitSet: boolean;
    @DtoProp public isTransportable: boolean;
    @DtoProp public businessEntity: IIdAndLabelDto;
    @DtoProp public floorPlanImage: IUpdateImageDto;
    @DtoProp public renderImage: IUpdateImageDto;
    @DtoProp public renderImageId: string;
    @DtoProp public floorPlanId: string;
    @DtoProp public lotContractType: LotContractTypeEnumId;
    @DtoProp public houseAndLandBrochure: {
        documentEntityId: number;
        documentId: number;
    };

    public get documentEntityId(): number {
        return this.id;
    }

    public get documentEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    @Computed() public get canDownloadBrochure(): boolean {
        return this.houseAndLandBrochure && this.houseAndLandBrochure.documentId > 0;
    }

    @Computed() public get canDeleteBrochure(): boolean {
        return this.houseAndLandBrochure && this.houseAndLandBrochure.documentEntityId > 0 && this.houseAndLandBrochure.documentId > 0;
    }

    constructor(
        sourceData: ITempLotDto,
        logicService: ITempLotsLogicService
    ) {
        super(sourceData, logicService, TempLotMappedItem, {
            status: {} as IIdAndLabelDto,
            lotAddress: {} as IAddressDto,
            project: {} as IIdAndLabelDto,
            projectReleaseStage: {} as ISkinnyProjectReleaseStageDto,
            businessEntity: {} as IIdAndLabelDto,
            amenities: {} as ILotAmenitiesDto,
        });
    }

    public uploadBrochure(file: File): Observable<ITempLotDto> {
        const formData = new FormData();
        formData.append('file', file);
        return this.$logicService.uploadTempLotBrochure(this.id, formData).pipe(tap((result) => {
            this.$updateThisAndOriginal(result);
        }));
    }

    public downloadBrochure(): Observable<any> {
        return this.$logicService.downloadTempLotBrochure(this.houseAndLandBrochure.documentId);
    }

    public deleteBrochure(): Observable<any> {
        return this.$logicService.deleteTempLotBrochure(this.houseAndLandBrochure.documentEntityId)
            .pipe(tap((result) => {
                const dto = this.$getMappedDtoItem();
                dto.houseAndLandBrochure.documentId = null;
                this.$updateThisAndOriginal(dto);
            }));
    }
}
