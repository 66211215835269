<cb-dialog dialogHeading="Document History">
    <cb-table class="flex"
              label="Change Records"
              [stringColumns]="displayedColumns"
              [loaded]="true">
        <tr cbTableRow
            *ngFor="let document of documentPrecursors">
            <td>
                <cb-info-popup [label]="document.name"
                               [tooltip]="document.description">
                </cb-info-popup>
            </td>
            <td>
                {{ document.documentType.label}}
            </td>
            <td>{{document.updatedByName}}</td>
            <td>{{document.updatedDate | date:'dd MMM yyyy hh:mm:ss a' }}</td>
            <td cbTableShrinkCol
                cbTableStickyCol="end">
                <button mat-icon-button
                        (click)="downloadDocument(document)">
                    <mat-icon>file_download</mat-icon>
                </button>
            </td>
        </tr>
    </cb-table>
</cb-dialog>
