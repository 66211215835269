import { Component, OnInit, Inject } from '@angular/core';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IClientSaleDto, IClientSaleMappedItem, ClientSaleLogicService, ICreateHouseAndLandClientSaleDto } from '@app/logic/client-sale';
import { IClientSaleLogicService } from '@app/logic/client-sale/interfaces/i.client-sale-logic.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { USER_TAG_CONSTANTS_CONST, ENQUIRY_ORIGIN_ENUM, LOT_CONTRACT_TYPE_ENUM, ILotDto, CLIENT_ACCOUNT_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { cloneDeep } from 'lodash';
import { FormMode } from '@app/shared/enums/form';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { Observable } from 'rxjs';
import { LotsLogicService, ILotSearchDto } from '@app/logic/lots';
import { IClientAccountDto } from '@app/logic/client-account/interfaces/i.client-account.dto';
import { IUserDto } from '@app/logic/users';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IContactSearchDto } from '@app/logic/contacts';
import { CreateContactDialogComponent } from '@app/views/contacts/create-contact-dialog/create-contact-dialog.component';
import { IContactDto } from '@app/logic/contacts/interfaces/i.contact.dto';
import { DateUtil } from '@app/shared/utils/date.util';
import { IClientAccountSearchParams } from '@app/logic/client-account/interfaces/i.client-account-search-params';

export interface ICreateClientSaleDialogData {
    selectedLot: Partial<ILotSearchDto>;
}

@Component({
    selector: 'cb-create-client-sale-dialog',
    templateUrl: './create-client-sale-dialog.component.html',
    styleUrls: ['./create-client-sale-dialog.component.scss']
})
export class CreateClientSaleDialogComponent extends BaseDialogFormViewDirective<IClientSaleDto, IClientSaleMappedItem, IClientSaleLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '40%';

    public selectedLot: Partial<ILotSearchDto>;

    public createDto = {
        createNewClientAccount: false,
        enquiryDate: DateUtil.formatDateToDateString(new Date()),
    } as ICreateHouseAndLandClientSaleDto;

    public selectedAccount: IClientAccountDto;
    public mainContact: IContactDto;
    public additionalContact: IContactDto;
    public buildingConsultant: IUserDto;

    public readonly SHOWHOME_ORIGIN = ENQUIRY_ORIGIN_ENUM.Showhome;
    public BUILDING_CONSULTANT = [USER_TAG_CONSTANTS_CONST.BUILDING_CONSULTANT];
    public readonly enquiryOrigins = ENQUIRY_ORIGIN_ENUM.toLookup();
    public showhomes: ILotDto[];

    public readonly accountSearchParams: IClientAccountSearchParams = { status: [CLIENT_ACCOUNT_STATUS_ENUM.Open, CLIENT_ACCOUNT_STATUS_ENUM.Locked] };
    public readonly contactSearchParams = new ComputedProperty(() => {
        return {
            excludeIncomplete: true,
            excludedContactIds: [this.mainContact?.id, this.additionalContact?.id].filter(x => !isNullOrWhiteSpace(x)),
        };
    });
    public readonly allowedContractTypes = [LOT_CONTRACT_TYPE_ENUM.HouseAndLand, LOT_CONTRACT_TYPE_ENUM.DesignAndLand];

    constructor(
        public readonly dialogRef: MatDialogRef<CreateClientSaleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: ICreateClientSaleDialogData,
        public readonly clientSaleLogic: ClientSaleLogicService,
        public toastService: ToastService,
        private readonly currentUser: CurrentUserService,
        private readonly lotLogic: LotsLogicService,
        private readonly cbDialog: CbDialogService,
    ) {
        super(dialogRef, toastService, cbDialog);
        this.selectedLot = data?.selectedLot;
        this.formMode = FormMode.Add;
        this.currentUser.$promise.then(this.loadBuildingConsultant);
        this.lotLogic.getShowhomes().subOnce((showhomes) => {
            showhomes.unshift({ id: 0, label: 'Other' } as any);
            this.showhomes = showhomes;
        });
    }



    public saveMethod(): Observable<IClientSaleDto> {
        return this.clientSaleLogic
            .createHouseAndLandClientSale(this.getCorrectData());
    }

    public createClientAccountChange(): void {
        this.selectedAccount = {} as IClientAccountDto;
    }

    /** maps required properties and corrects/fixes conditional data on createDto */
    private getCorrectData(): ICreateHouseAndLandClientSaleDto {
        this.createDto.lotId = this.selectedLot.id;
        this.createDto.mainContactId = this.mainContact?.id;
        this.createDto.additionalContactId = this.additionalContact?.id;
        this.createDto.buildingConsultantId = this.buildingConsultant?.id;
        this.createDto.clientAccountId = Number(this.selectedAccount?.id) || null;
        if (this.createDto.enquiryOrigin !== this.SHOWHOME_ORIGIN) {
            this.createDto.showhomeId = null;
        }
        if (this.createDto.showhomeId != null && this.createDto.showhomeId !== 0) {
            this.createDto.otherShowhome = null;
        }
        if (this.createDto.createNewClientAccount) {
            this.createDto.clientAccountId = null;
        }
        const copy = cloneDeep(this.createDto);
        if (copy.showhomeId === 0) {
            copy.showhomeId = null;
        }
        return copy;
    }

    private readonly loadBuildingConsultant = (): void => {
        if (!this.currentUser.tags.some(tag => tag === USER_TAG_CONSTANTS_CONST.BUILDING_CONSULTANT)) {
            return;
        }
        this.buildingConsultant = {
            id: this.currentUser.guid,
            label: this.currentUser.profile.name,
        } as any;
    };

    private getContactName(contact: IContactDto | IContactSearchDto): string {
        return contact != null ? (contact as IContactSearchDto).name ?? `${(contact as IContactDto).firstName || ''} ${(contact as IContactDto).lastName || ''}` : undefined;
    }


    public createMainContact(): void {
        this.createContact().subOnce((result) => {
            if (result) {
                this.mainContact = { id: result.id, label: this.getContactName(result) } as any;
            }
        });
    }

    public createAdditionalContact(): void {
        this.createContact().subOnce((result) => {
            if (result) {
                this.additionalContact = { id: result.id, label: this.getContactName(result) } as any;
            }
        });
    }

    private createContact(): Observable<any> {
        return this.cbDialog
            .open(CreateContactDialogComponent, {
                data: {},
                minWidth: '66%',
            })
            .afterClosed();
    }

}
