<cb-dialog dialogHeading="{{(isEdit$ | async) ? 'Edit Question' : 'New Question'}}">
    <form class="flex-col flex"
          #form="ngForm">
        <div *cbWaitFor="mappedItem">
            <cb-select [label]="childItemLabel"
                       name="childEntityId"
                       [readonly]="isEdit$ | async"
                       [(ngModel)]="mappedItem.childEntityId"
                       [options]="childItems$ | async"
                       [required]="true">
            </cb-select>
            <div class="flex-row">
                <cb-display-value-new [value]="currentUser.profile.name"
                                      label="Current User"
                                      class="readonly-spacing">
                </cb-display-value-new>
                <span *ngIf="(currentUserTeams$ | async)?.length > 1"
                      style="padding-top: 20px">&nbsp;&nbsp;as&nbsp;&nbsp;</span>
                <cb-select label="Team"
                           name="createdByTeamCode"
                           *ngIf="(currentUserTeams$ | async)?.length > 1"
                           [(ngModel)]="mappedItem.createdByTeamCode"
                           [options]="currentUserTeams$ | async"
                           valueProp="key"
                           [required]="true">
                </cb-select>
            </div>
            <div class="flex-row">
                <cb-users-input-find-table class="flex flex-row"
                                           label="Question To"
                                           [(ngModel)]="recipient.id"
                                           name="recipient"
                                           [text]="recipient?.name"
                                           [removeable]="false"
                                           [required]="true">
                </cb-users-input-find-table>
                <span *ngIf="(recipientTeams$ | async)?.length > 1"
                      style="padding-top: 20px">&nbsp;&nbsp;as&nbsp;&nbsp;</span>
                <cb-select label="Team"
                           name="teamCode"
                           *ngIf="(recipientTeams$ | async)?.length > 1"
                           [(ngModel)]="mappedItem.teamCode"
                           valueProp="key"
                           [options]="recipientTeams$ | async"
                           [required]="true">
                </cb-select>
            </div>
            <cb-text-area label="Question"
                          name="request"
                          [required]="true"
                          [(ngModel)]="mappedItem.request"
                          [maxlength]="1024">
            </cb-text-area>
        </div>
        <footer class="cb-margin-top-2x end-center flex-row">
            <span class="flex"></span>
            <cb-button [disabled]="form.pristine || form.invalid"
                       [clickLimit]="1"
                       class="cb-margin-right"
                       (clicked)="save()">
                {{(isEdit$ | async) ? 'Save' : 'Create'}}
            </cb-button>
            <cb-button (clicked)="cancel()">
                Cancel
            </cb-button>
        </footer>
    </form>
</cb-dialog>
