<cb-dialog dialogHeading="Add Contact">
    <form #addContactForm="ngForm">
        <div class="flex-row">
            <h3 class="mat-title flex"
                style="margin: 0;">Contact Search
            </h3>
            <span class="flex"></span>
            <button mat-raised-button
                    color="primary"
                    class="cb-margin-bottom"
                    (click)="createContact()">
                Create Contact
            </button>
        </div>
        <div class="start flex-col">
            <div class="cb-margin flex-row flex">
                <cb-input class="flex"
                          type="text"
                          label="Type in First Name, Last Name or Phone Number to Search"
                          [(ngModel)]="userCacheItem.data.query"
                          name="search"></cb-input>
            </div>
        </div>
    </form>
    <div #contactScrollContainer
         class="scroll-container flex-col">
        <div infiniteScroll
             [infiniteScrollDistance]="1"
             [infiniteScrollContainer]="contactScrollContainer"
             [fromRoot]="true"
             (scrolled)="doSearch()"
             class="cb-margin start flex-col">
            <div class="flex-wrap xs-column">
                <div class="contact-card md-33 lg-33 sm-50 xl-20 xs-100 flex-33"
                     *ngFor="let contact of searchResults">
                    <mat-card>
                        <div>
                            <mat-card-title>{{contact.name}}</mat-card-title>
                            <span class="flex"></span>
                            <div class="help-cursor flex-col center-right">
                                <mat-icon *ngIf="contact.status === CONTACT_STATUS_ENUM.Active && !contact.incomplete"
                                          class="mat-primary"
                                          title="Active">check_circle</mat-icon>
                                <mat-icon *ngIf="contact.status !== CONTACT_STATUS_ENUM.Active"
                                          title="Inactive">radio_button_unchecked
                                </mat-icon>
                                <mat-icon *ngIf="contact.incomplete && contact.status === CONTACT_STATUS_ENUM.Active"
                                          class="mat-warn"
                                          title="Incomplete">warning</mat-icon>
                            </div>
                        </div>
                        <mat-card-content style="height: 140px;">
                            <div class="flex-row">
                                <div class="flex-col">
                                    <cb-display-value-new label="Address"
                                                          [value]="contact.address"></cb-display-value-new>
                                    <cb-display-value-new label="Email"
                                                          [value]="contact.email"></cb-display-value-new>
                                    <cb-display-value-new *ngIf="contact.preferred === 'Mobile Phone'"
                                                          label="Mobile Phone"
                                                          [value]="contact.preferredContactDetails">
                                    </cb-display-value-new>
                                </div>
                            </div>
                        </mat-card-content>
                        <mat-divider></mat-divider>
                        <mat-card-actions>
                            <span class="flex"></span>
                            <!-- TODO: This component handles 2 completely different models of contact
                                IContactDto is not the same as IContactDocumentDto. It needs to take into account
                                the differences between adding an existing contact and adding a new contact. It sets the
                                reference of the contacts (Wherever this dialog is called from)
                            -->
                            <button mat-stroked-button
                                    color="accent"
                                    (click)="addContact($any(contact))">
                                Add Contact
                            </button>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</cb-dialog>
