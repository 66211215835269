import { Component, Input } from '@angular/core';
import { BaseFormComponentDirective, getBaseFormComponentDirectiveProvider } from '../base-form-component';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { NonWorkingDayLogicService, INonWorkingDayDto } from '@app/logic/non-working-day';
// uses https://danielykpan.github.io/date-time-picker/

export declare type PickerType = 'both' | 'calendar' | 'timer';

@Component({
    selector: 'cb-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(DatepickerComponent),
        NonWorkingDayLogicService,
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class DatepickerComponent extends BaseFormComponentDirective {
    @Input() public minDate: Date | string | null;
    @Input() public maxDate: Date | string | null;
    @Input() public startAt: Date | string | null;
    @Input() public startView: 'month' | 'year' | 'multi-year' = 'month';
    @Input() public nonWorkingDays: INonWorkingDayDto[] | null;

    private _pickerType: PickerType = 'both';
    @Input() public set pickerType(v: PickerType | null) {
        this._pickerType = v;
    }
    public get pickerType(): PickerType {
        return this._pickerType || 'both';
    }

    constructor(
        private readonly nonWorkingDayLogic: NonWorkingDayLogicService,
    ) {
        super();
    }

    public readonly dateFilter = (date: Date): boolean => {
        if (!this.nonWorkingDays?.length) {
            return true;
        }
        if (!date) {
            return false;
        }
        const safeDate = new Date(date);

        return this.nonWorkingDayLogic.nonWorkingDaysFilter(this.nonWorkingDays, safeDate)
            && this.nonWorkingDayLogic.weekDaysFilter(safeDate);
    };
}
