import { Component, Input, OnChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';
import { getBaseAutocompleteScrollerProvider, AutocompleteScrollerComponent, IBasicAutocompleteLookup } from '../../autocomplete-scroller.component';
import { LotTypeEnumId, LOT_CONTRACT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { LotSearchService } from '@app/core/services/search/lot-search.service';
import { ILotSearchDto } from '@app/logic/lots';
@Component({
    selector: 'cb-lot-autocomplete-scroller',
    templateUrl: '../../autocomplete-scroller.component.html',
    providers: [
        ...getBaseFormComponentDirectiveProvider(LotAutocompleteScrollerComponent),
        getBaseAutocompleteScrollerProvider(LotAutocompleteScrollerComponent),
        LotSearchService,
    ]
})
export class LotAutocompleteScrollerComponent
    extends AutocompleteScrollerComponent<any> // TODO: The results of the lot autocomplete scroller should be typed
    implements OnChanges {
    @Input() public readonly floorArea: boolean;
    @Input() public readonly locationId: number;
    @Input() public readonly numberOfBathrooms: number;
    @Input() public readonly numberOfBedrooms: number;
    @Input() public readonly numberOfGarages: number;
    @Input() public readonly numberOfLivingRooms: number;
    @Input() public readonly order: 'projectLots' | null;
    @Input() public readonly lotType: LotTypeEnumId;
    @Input() public readonly lotContractTypes = [LOT_CONTRACT_TYPE_ENUM.HouseAndLand];
    @Input() public readonly requireActiveConcept: boolean;

    @Input() public set availableOnly(v: boolean) {
        this.searchService.availableOnly = v;
        this.searchTextChanged.emit(this.value);
    }
    public get availableOnly(): boolean {
        return this.searchService.availableOnly;
    }

    @Input() public readonly landArea: { min: number; max: number };
    @Input() public readonly price: { min: number; max: number };

    public results: any[];

    constructor(
        public readonly dialog: MatDialog,
        public searchService: LotSearchService
    ) {
        super(dialog, searchService);
    }

    public ngOnChanges(): void {
        this.searchService.extraSearchParams = {
            floorArea: this.floorArea,
            locationId: this.locationId,
            numberOfBathrooms: this.numberOfBathrooms,
            numberOfBedrooms: this.numberOfBedrooms,
            numberOfGarages: this.numberOfGarages,
            numberOfLivingRooms: this.numberOfLivingRooms,
            order: this.order,
            lotType: this.lotType,
            lotContractTypes: this.lotContractTypes,
            requireActiveConcept: this.requireActiveConcept
        };
    }

    public displayWith(lookup: any): string | null {
        return lookup ? (lookup as ILotSearchDto).displayText : null;
    }
}
