import { AfterViewInit, ChangeDetectorRef, Component, Input, NgZone } from '@angular/core';
import { BusinessAccountPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { BusinessAccountLogicService } from '@app/logic/business-accounts';
import { ISupplierProductDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Component({
    selector: 'cb-business-account-catalogues',
    templateUrl: './business-account-catalogues.component.html',
    styleUrls: ['./business-account-catalogues.component.scss']
})
export class BusinessAccountCataloguesComponent implements AfterViewInit {
    @Input() public accountId: number;

    public isInitialised = false;
    public catalogues: ISupplierProductDto[];
    public filters: any = {};
    public refreshData$ = new Subject();
    public infiniteScrollEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(
        public readonly permissions: BusinessAccountPermissions,
        protected readonly businessAccountLogicService: BusinessAccountLogicService,
        protected readonly navigationService: NavigationService,
        private readonly cdr: ChangeDetectorRef,
        private readonly ngZone: NgZone,
    ) {
    }

    public ngAfterViewInit(): void {
        this.filters = {
            currentPage: 1,
            isActive: true,
            query: ''
        };
        this.cdr.detectChanges();

        this.ngZone.run(() => {
            setTimeout(() => {
                this.refreshData();
            });
        });
    }

    public refreshData(): void {
        this.catalogues = [];
        this.isInitialised = true;
        this.refreshData$.next(null);
        this.cdr.detectChanges();
    }

    public search(): Observable<ISupplierProductDto[]> {
        return this.businessAccountLogicService.getCatalogues(this.accountId, this.filters);
    }

    public viewCatalogueClicked(catalogue: any): void {
        this.navigationService.redirectTo(`view-product/${catalogue.offeringId}/details`);
    }
}
