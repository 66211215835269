import { BaseMappedItem } from '../base/base-mapped-item';
import { IBuildContingencyRecordMappedItem } from './interfaces/i.build-contingency-record.mapped';
import { IBuildContingencyRecordsLogicService } from './interfaces/i.build-contingency-records.logic.service';
import { DtoProp } from '../base/dto-prop.decorator';
import { IBuildContigencyRecordDto } from '@classictechsolutions/hubapi-transpiled-enums';

export class BuildContingencyRecordMappedItem
    extends BaseMappedItem<IBuildContigencyRecordDto, IBuildContingencyRecordMappedItem, IBuildContingencyRecordsLogicService>
    implements IBuildContingencyRecordMappedItem {
    @DtoProp public businessEntityId: number;
    @DtoProp public amount: number;
    @DtoProp public valueDirectionId: number;
    @DtoProp public scaleId: number;
    @DtoProp public valueTypeId: number;
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public isActive: boolean;
    @DtoProp public id: number;

    constructor(
        sourceData: IBuildContigencyRecordDto,
        logicService: IBuildContingencyRecordsLogicService
    ) {
        super(
            sourceData,
            logicService,
            BuildContingencyRecordMappedItem);
    }
}
