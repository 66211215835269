<cb-dialog>
    <h3 class="mat-title">{{contentHeading}}</h3>
    <form class="flex-col flex"
          #inputFindTableForm="ngForm">
        <mat-form-field class="flex">
            <input type="text"
                   [placeholder]="placeholder"
                   [formControl]="searchText"
                   name="userSearch"
                   matInput/>
        </mat-form-field>
    </form>
    <cb-table class="cb-margin-top flex"
              maxHeightOffset="310"
              [loaded]="true"
              [fetch]="search()"
              [queryUpdated]="queryUpdated"
              [infiniteScrollEnabled]="infiniteScrollEnabled"
              [(results)]="results"
              [(currentPage)]="currentPage">
        <thead cbTableHead>
            <tr>
                <td>User Name</td>
                <td>Name</td>
                <td>Job</td>
                <td>Email</td>
                <td>Primary Business Entity</td>
                <td>Active</td>
                <td></td>
            </tr>
        </thead>
        <tbody cbTableBody>
            <tr *ngFor="let item of results">
                <cb-td-text [value]="item?.username"></cb-td-text>
                <cb-td-text [value]="item?.name"></cb-td-text>
                <cb-td-text [value]="item?.jobTitle"></cb-td-text>
                <cb-td-text [value]="item?.email"></cb-td-text>
                <cb-td-text [value]="item?.primaryBusinessEntity"></cb-td-text>
                <cb-td-checkbox [value]="item?.isActive"></cb-td-checkbox>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <button mat-icon-button
                            (click)="selectionChosen(item)">
                        <mat-icon>add</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </cb-table>
    <footer class="cb-margin-top-2x end-center flex-row">
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
