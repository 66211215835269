import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ITagMappedItem, ITagsLogicService } from '@app/logic/tags';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { Observable } from 'rxjs';
import { ITagDto, USER_TAG_CONSTANTS_CONST, UserTagConstantsConstId } from '@classictechsolutions/hubapi-transpiled-enums';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';

@Component({
    selector: 'cb-tag-dialog',
    templateUrl: './tags-dialog.component.html',
    styleUrls: ['./tags-dialog.component.scss']
})
export class TagsDialogComponent extends BaseDialogFormViewDirective<ITagDto, ITagMappedItem, ITagsLogicService> {

    public isSystemTag: boolean;

    public readonly userTags: IEnumLookup<UserTagConstantsConstId>[];

    constructor(
        public dialogRef: MatDialogRef<TagsDialogComponent>,
        public toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public readonly data: { mappedItem: ITagMappedItem; existingTags: string[] }
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.isSystemTag = this.mappedItem.isSystemTag;

        if (!this.data.existingTags) {
            this.data.existingTags = [];
        }
        this.userTags = USER_TAG_CONSTANTS_CONST.toLookup().filter(x => !this.data.existingTags.includes(x.id));
    }

    public saveMethod(): Observable<any> {
        return this.mappedItem.$save();
    }
}
