import { Injectable, Injector } from '@angular/core';
import { BaseLogicService } from '../base/base-logic.service';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { ISkinnyBuildProgrammeMappedItem } from './interfaces/i.skinny-build-programme.mapped';
import { ISkinnyBuildProgrammeLogicService } from './interfaces/i.skinny-build-programme-logic.service';
import { SkinnyBuildProgrammeMappedItem } from './skinny-build-programme.mapped';
import { Observable, map } from 'rxjs';
import { ISkinnyBuildProgrammeDto } from '@classictechsolutions/hubapi-transpiled-enums';


@Injectable()
export class SkinnyBuildProgrammeLogicService
    extends BaseLogicService<ISkinnyBuildProgrammeDto, ISkinnyBuildProgrammeMappedItem>
    implements ISkinnyBuildProgrammeLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'buildprogrammes',
            SkinnyBuildProgrammeMappedItem,
        );
    }

    public getMappedItemByLot(lotId: number): Observable<ISkinnyBuildProgrammeMappedItem> {
        return this.$http
            .get<ISkinnyBuildProgrammeDto>(`lots/${lotId}/buildprogramme/skinny`)
            .pipe(
                map(x => this.$createMappedItem(x))
            );
    }
}
