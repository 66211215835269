import { Injectable, Injector } from '@angular/core';

import { CurrentUserService } from '@app/core/authentication/current.user';
import { IClientSaleSearch } from '@app/core/services/user-cache/user-cache-areas';
import { ISearchResult } from '@app/shared/components/search/i.search';
import {
    IClientSaleClassicCareUpdateDto,
    IClientSaleClientAccountUpdateDto, IClientSaleDocumentDto, IClientSaleLostDto,
    IClientSaleReportLineDto,
    IClientSaleReportLineExtendedDto,
    IClientSaleReportTaskItemExtendedDto, IClientSaleUpdateDto,
    LotContractTypeEnumId
} from '@classictechsolutions/hubapi-transpiled-enums';
import { cleanObjectDeep } from 'cb-hub-lib';
import moment from 'moment';
import { map, Observable } from 'rxjs';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { ClientSaleMappedItem } from './client-sale.mapped';
import { IClientSaleContractPricingUpdateDto } from './interfaces/i.client-sale-contract-pricing-update.dto';
import { IClientSaleLogicService } from './interfaces/i.client-sale-logic.service';
import { IClientSaleLotUpdateDto } from './interfaces/i.client-sale-lot-update.dto';
import { IClientSaleDto } from './interfaces/i.client-sale.dto';
import { IClientSaleMappedItem } from './interfaces/i.client-sale.mapped';
import { ICreateHouseAndLandClientSaleDto } from './interfaces/i.create-client-sale.dto';


@Injectable()
export class ClientSaleLogicService
    extends BaseLogicService<IClientSaleDto, IClientSaleMappedItem>
    implements IClientSaleLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
        public readonly currentUser: CurrentUserService,
    ) {
        super(
            'clientsales',
            ClientSaleMappedItem,
        );
    }

    public $getSearchList(params: IClientSaleSearch, cleanParamsCheck = (value: any) => value == null): Observable<ISearchResult<IClientSaleDocumentDto>> {
        cleanObjectDeep(params, cleanParamsCheck);
        return this.$http
            .get<ISearchResult<IClientSaleDocumentDto>>(`${this.$baseUri}/search`, params);
    }

    public search(
        query = '',
        currentpage: number,
        params: IClientSaleSearch = {} as IClientSaleSearch
    ): Observable<ISearchResult<IClientSaleDocumentDto>> {
        params = cleanObjectDeep(params);
        if (params.ignoreEmptyQuery) {
            return new Observable((s) => s.complete());
        }
        return this.$http.get<ISearchResult<IClientSaleDocumentDto>>(
            `${this.$baseUri}/search`,
            {
                query,
                currentpage,
                ...params
            }
        );
    }

    public export(
        params: IClientSaleSearch = {} as IClientSaleSearch
    ): Observable<ArrayBuffer> {
        params = cleanObjectDeep(params);
        const date = moment().format('YYYY-MM-DD');
        return this.$http.get<ArrayBuffer>(
            `${this.$baseUri}/search/export`,
            params,
            'arraybuffer',
            {
                'Content-Type': 'text/csv',
                'Content-Disposition': `attachment;filename=ClientSale_${date}.csv`
            }
        );
    }

    public createHouseAndLandClientSale(dto: ICreateHouseAndLandClientSaleDto): Observable<IClientSaleDto> {
        return this.$http.post(`${this.$baseUri}/createforhouseland`, dto);
    }

    public applyPaymentTemplate(clientSaleId: number, paymentTemplateId: number): Observable<void> {
        return this.$http.post(`${this.$baseUri}/${clientSaleId}/applypaymenttemplate/${paymentTemplateId}`);
    }

    public reevaluateClientSalesHaveSignedContract(): Observable<number[]> {
        return this.$http.post(`${this.$baseUri}/reevaluate`, null);
    }

    public cancelAllSaleLostDocuments(): Observable<number[]> {
        return this.$http.post(`${this.$baseUri}/cancelallsalelostdocuments`, null);
    }

    public updateDetails(clientSaleId: number, clientSale: IClientSaleUpdateDto): Observable<IClientSaleDto> {
        return this.$http
            .post<IClientSaleDto>(`${this.$baseUri}/${clientSaleId}`, clientSale);
    }

    public updateLotDetails(clientSaleId: number, lotDetails: IClientSaleLotUpdateDto): Observable<IClientSaleDto> {
        return this.$http
            .post<IClientSaleDto>(`${this.$baseUri}/${clientSaleId}/lotdetails`, lotDetails);
    }

    public updateClassicCareDetails(clientSaleId: number, classicCareDetails: IClientSaleClassicCareUpdateDto): Observable<IClientSaleDto> {
        return this.$http
            .post<IClientSaleDto>(`${this.$baseUri}/${clientSaleId}/classiccare`, classicCareDetails);
    }

    public updateContractPricingDetails(clientSaleId: number, contractPricingDetails: IClientSaleContractPricingUpdateDto): Observable<IClientSaleDto> {
        return this.$http
            .post<IClientSaleDto>(`${this.$baseUri}/${clientSaleId}/contractpricing`, contractPricingDetails);
    }

    public updateClientAccountDetails(clientSaleId: number, clientAccountDetails: IClientSaleClientAccountUpdateDto): Observable<IClientSaleDto> {
        return this.$http
            .post<IClientSaleDto>(`${this.$baseUri}/${clientSaleId}/clientaccount`, clientAccountDetails);
    }

    public changeContractType(clientSaleId: number, contractType: LotContractTypeEnumId): Observable<IClientSaleDto> {
        return this.$http
            .post<IClientSaleDto>(`${this.$baseUri}/${clientSaleId}/changecontracttype/${contractType}`, null);
    }

    public changeDesignConcept(clientSaleId: number, designConceptId: number): Observable<IClientSaleDto> {
        return this.$http
            .post<IClientSaleDto>(`${this.$baseUri}/${clientSaleId}/changedesignconcept/${designConceptId}`, null);
    }

    public setSalePendingLost(clientSaleId: number, dto: IClientSaleLostDto): Observable<IClientSaleDto> {
        return this.$http
            .post<IClientSaleDto>(`${this.$baseUri}/${clientSaleId}/salependinglost`, dto);
    }

    public confirmSaleLost(clientSaleId: number, dto: IClientSaleLostDto): Observable<IClientSaleDto> {
        return this.$http
            .post<IClientSaleDto>(`${this.$baseUri}/${clientSaleId}/confirmsalelost`, dto);
    }

    public revertPendingLost(clientSaleId: number): Observable<IClientSaleDto> {
        return this.$http
            .post<IClientSaleDto>(`${this.$baseUri}/${clientSaleId}/revertpendinglost`, null);
    }

    public revertunconditional(clientSaleId: number): Observable<IClientSaleDto> {
        return this.$http
            .post<IClientSaleDto>(`${this.$baseUri}/${clientSaleId}/revertunconditional`, null);
    }

    public setUnconditional(clientSaleId: number, date: Date): Observable<IClientSaleDto> {
        return this.$http
            .post<IClientSaleDto>(`${this.$baseUri}/${clientSaleId}/setunconditional`, { date });
    }

    public downloadCreateSchedule(clientSaleId: number): Observable<any> {
        return this.$http.download(`${this.$baseUri}/${clientSaleId}/createscheduleform`);
    }

    public downloadSimCreateSchedule(clientSaleId: number): Observable<any> {
        return this.$http.download(`${this.$baseUri}/${clientSaleId}/createSimscheduleform`);
    }

    public downloadSalePurchaseInformation(clientSaleId: number): Observable<any> {
        return this.$http.download(`${this.$baseUri}/${clientSaleId}/spinformation`);
    }

    public downloadBuildContract(clientSaleId: number): Observable<any> {
        return this.$http.download(`${this.$baseUri}/${clientSaleId}/buildcontractform`);
    }

    public getClientSaleIdForLot(lotId: number): Observable<{ clientSaleId: number }> {
        return this.$http.get(`${this.$baseUri}/forlot/${lotId}`).pipe(map(data => ({ clientSaleId: +data || null })));
    }

    public getClientSaleSummaryReport(buildingConsultantId: string): Observable<IClientSaleReportLineDto[]> {
        return this.$http.get(`${this.$baseUri}/${buildingConsultantId}/summary`);
    }


    public getClientSaleSummaryExtendedReport(buildingConsultantId: string): Observable<IClientSaleReportLineExtendedDto[]> {
        return this.$http.get(`${this.$baseUri}/${buildingConsultantId}/extendedsummary`);
    }

    public getClientSaleNextSteps(clientSaleId: number): Observable<IClientSaleReportTaskItemExtendedDto[]> {
        return this.$http.get(`${this.$baseUri}/${clientSaleId}/nextsteps`);
    }
}
