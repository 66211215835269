<h3 class="mat-title">
    System Logs
</h3>
<form #dateFilterForm="ngForm"
      class="filter-row start-center">
    <cb-datepicker label="Date From"
                   name="dateFrom"
                   [(ngModel)]="dateFrom"
                   (change)="cleanSearch()"></cb-datepicker>
    <cb-datepicker label="Date To"
                   name="dateTo"
                   [(ngModel)]="dateTo"
                   (change)="cleanSearch()"></cb-datepicker>
    <mat-icon>
        search
    </mat-icon>
    <mat-form-field class="flex">
        <input type="text"
               placeholder="Search Logs"
               aria-label="Search System Logs"
               [formControl]="searchText"
               name="logsSearch"
               matInput/>
    </mat-form-field>
    <button [matMenuTriggerFor]="menu"
            mat-icon-button>
        <mat-icon>
            more_horiz
        </mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item
                (click)="reindexLogs()">
            Fetch Latest Logs
        </button>
    </mat-menu>
</form>
<div class="chips-container flex-col">
    <mat-chip-list>
        <mat-chip (removed)="clearFilters()">
            <strong class="mat-body-1">
                Clear All Filters
            </strong>
            <mat-icon matChipRemove>
                cancel
            </mat-icon>
        </mat-chip>
        <mat-chip *ngIf="dateFrom != null"
                  (removed)="clearDateFrom()">
            <strong class="mat-body-1">
                Date From - {{dateFrom | date}}
            </strong>
            <mat-icon matChipRemove>
                cancel
            </mat-icon>
        </mat-chip>
        <mat-chip *ngIf="dateTo != null"
                  (removed)="clearDateTo()">
            <strong class="mat-body-1">
                Date To - {{dateTo | date}}
            </strong>
            <mat-icon matChipRemove>
                cancel
            </mat-icon>
        </mat-chip>
        <mat-chip *ngIf="!isNullOrWhiteSpace(searchText.value)"
                  (removed)="searchText.setValue('')">
            <strong class="mat-body-1">
                Query - {{searchText.value}}
            </strong>
            <mat-icon matChipRemove>
                cancel
            </mat-icon>
        </mat-chip>
    </mat-chip-list>
</div>
<div>
    <cb-table class="cb-margin-top flex"
              maxHeightOffset="290"
              [objectColumns]="columns"
              [loaded]="true"
              [fetch]="search()"
              [queryUpdated]="queryUpdated"
              [infiniteScrollEnabled]="infiniteScrollEnabled"
              [(results)]="logs"
              [(currentPage)]="currentPage">
        <tr cbTableRow
            *ngFor="let element of logs">
            <cb-td-text [value]="element.eventDate | date:'dd/MM/yyyy hh:mm:ss'"></cb-td-text>
            <cb-td-text [value]="element.instanceId"></cb-td-text>
            <cb-td-text [value]="element.instancePrivateIp"></cb-td-text>
            <cb-td-text [value]="element.source"></cb-td-text>
            <cb-td-text [value]="getTruncatedDetails(element.logError || element.eventMessage)"></cb-td-text>
            <td cbTableShrinkCol
                cbTableStickyCol="end">
                <button mat-icon-button
                        (click)="viewSystemLog(element);">
                    <mat-icon>
                        help_outline
                    </mat-icon>
                </button>
            </td>
        </tr>
    </cb-table>
</div>
