import { Component, Input, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { ContactsLogicService } from '@app/logic/contacts';
import { ISkinnyLeadDto, LEAD_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { PermissionsPermissions } from '@app/core/permissions';
import { switchMap } from 'rxjs';
import { toPromise } from 'cb-hub-lib';
import { CreateLeadDialogComponent } from '@app/views/leads/create-lead-dialog/create-lead-dialog.component';
import { ILeadDto } from '@app/logic/leads';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IContactMappedItem } from '@app/logic/contacts/interfaces/i.contact.mapped';

@Component({
    selector: 'cb-contact-leads',
    templateUrl: './contact-leads.component.html',
    styleUrls: ['./contact-leads.component.scss']
})
export class ContactLeadsComponent implements OnInit {

    @Input() public contact: IContactMappedItem;

    public LEAD_STATUS_ENUM = LEAD_STATUS_ENUM;

    public readonly displayedColumns: string[] = [
        'name',
        'status',
        'createdDate',
        'noContacts',
        'mainContact',
        'lotInterests',
        'buildingConsultant',
        'lastCallBackDate',
        'lostDate',
        'actions'
    ];

    public contactLeads: ISkinnyLeadDto[];
    public contactLeadsLoaded = false;

    constructor(
        public readonly cbDialog: CbDialogService,
        protected readonly contactsLogicService: ContactsLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly route: ActivatedRoute,
        public readonly navigation: NavigationService,
    ) { }

    public ngOnInit(): void {

        toPromise(this.route.params.pipe(
            switchMap((params: { id: string }) => {
                return this.contactsLogicService.getLeadsList(params.id);
            })
        )).then((list: ISkinnyLeadDto[]) => {
            this.contactLeads = list;
            this.contactLeadsLoaded = true;
        });

    }

    public createLead(): void {
        this.cbDialog
            .open(CreateLeadDialogComponent, {
                data: {
                    contact: this.contact.$getMappedDtoItem(),
                },
                minWidth: '40%',
            })
            .afterClosed()
            .subOnce((result: false | ILeadDto) => {
                if (result) {
                    this.navigation.navigate([`/leads/${result.id}/details`]);
                }
            });
    }


    public viewLead(id: string, event: MouseEvent): void {
        this.navigation.navigate([`leads/${id}/details`], event);
    }
}
