import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { HISTORY_SEARCH_ENTITY } from '@app/shared/enums/history/history-search-entity.enum';
import { IHistoryDto } from '@classictechsolutions/hubapi-transpiled-enums';


@Injectable()
export class HistoryLogicService
    extends BaseLogicService<IHistoryDto, Object> {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'history',
            Object
        );
    }
    public getHistory(entity: HISTORY_SEARCH_ENTITY, entityId: number | string, query: string, pg: number): Observable<{ total: number; items: IHistoryDto[] }> {
        return this.$http
            .get<{ total: number; items: IHistoryDto[] }>(`${this.$baseUri}/${entity}/${entityId ? entityId : ''}`, { q: query, pg });
    }
}
