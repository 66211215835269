import { Component, Input } from '@angular/core';
import { PermissionsPermissions } from '@app/core/permissions';
import { ToastService } from '@app/core/services/toast/toast.service';
import { DefaultSystemAreaDocumentTypesLogicService } from '@app/logic/default-system-area-document-types';
import { DocumentGroupLogicService, IDocumentGroupDto } from '@app/logic/documents';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { LotContractTypeEnumId, LOT_CONTRACT_TYPE_ENUM, SystemAreaEnumId, IDefaultSystemAreaDocumentTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { from, merge, tap, toArray } from 'rxjs';
import { ComputedProperty } from './../../../../../shared/utils/computed-property.util';

type DefaultSystemAreaDocumentType = IDefaultSystemAreaDocumentTypeDto & { id: any };

@Component({
    selector: 'cb-default-system-area-documents[systemArea]',
    templateUrl: './default-system-area-documents.component.html',
    styleUrls: ['./default-system-area-documents.component.scss']
})
export class DefaultSystemAreaDocumentsComponent extends BaseSimpleListViewDirective<DefaultSystemAreaDocumentType, DefaultSystemAreaDocumentTypesLogicService> {
    public static readonly DELETE_SUCCESS = 'Delete Successful';
    public static readonly DELETE_ERROR = 'Delete Failed';
    public static readonly ADD_SUCCESS = 'Add Successful';
    public static readonly ADD_ERROR = 'Add Failed';

    public loaded = false;
    private _systemArea: SystemAreaEnumId;
    @Input() public set systemArea(v: SystemAreaEnumId) {
        this.loaded = false;
        this._systemArea = v;
        this.defaultSystemAreaDocumentTypesLogic.getList(this._systemArea).subOnce((results) => {
            this.systemAreaDocumentTypes = results;
            this.loaded = true;
        });
    }
    public get systemArea(): SystemAreaEnumId {
        return this._systemArea;
    }

    private _disableContractType = false;
    @Input() public set disableContractType(v: boolean) {
        this._disableContractType = v;
        if (this._disableContractType) {
            this.selectedContractType = LOT_CONTRACT_TYPE_ENUM.None;
        }
    }
    public get disableContractType(): boolean {
        return this._disableContractType;
    }

    private _documentGroupCodes: string[];
    @Input() public set documentGroupCodes(v: string[]) {
        this._documentGroupCodes = v;
        if (this._documentGroupCodes) {
            merge(
                ...this.documentGroupCodes.map(x => this.documentGroupLogic.getByCode(x))
            ).pipe(
                tap(x => this.documentGroupLabels[x.id] = x.name),
                toArray(),
            ).subOnce(x => this.documentGroups = x);
        } else {
            this.documentGroups = [];
        }
    }
    public get documentGroupCodes(): string[] {
        return this._documentGroupCodes;
    }

    public readonly documentGroupLabels: { [id: number]: string } = {};
    public documentGroups: IDocumentGroupDto[] = [];

    @Input() public readonly label: string;

    public readonly LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;
    public systemAreaDocumentTypes: IDefaultSystemAreaDocumentTypeDto[];
    public readonly lotContractTypes = LOT_CONTRACT_TYPE_ENUM.toLookup().filter(x => x.id !== LOT_CONTRACT_TYPE_ENUM.None);

    public get contractTypeInvalid(): boolean {
        return !this.selectedContractType && !this.disableContractType;
    }
    private _selectedContractType: LotContractTypeEnumId;
    public set selectedContractType(v: LotContractTypeEnumId) {
        this._selectedContractType = v;
        this.resetSelectedDocumentType();
    }
    public get selectedContractType(): LotContractTypeEnumId {
        return this._selectedContractType;
    }

    private _selectedDocumentGroupCode: string;
    public set selectedDocumentGroupCode(v: string) {
        this._selectedDocumentGroupCode = v;
        this.resetSelectedDocumentType();
    }
    public get selectedDocumentGroupCode(): string {
        return this._selectedDocumentGroupCode;
    }

    public selectedDocumentTypeId: number;
    public anyPossibleDocumentTypes = false;
    public readonly possibleDocumentTypes = new ComputedProperty(() => {
        let observable = this.defaultSystemAreaDocumentTypesLogic
            .getPossibleDocumentTypes(this.systemArea, this.selectedContractType, this.selectedDocumentGroupCode);
        if (this.contractTypeInvalid || !this.selectedDocumentGroupCode) {
            observable = from([]);
        }
        return observable
            .pipe(tap(x => this.anyPossibleDocumentTypes = x.length > 0));
    });

    constructor(
        public readonly cbDialog: CbDialogService,
        protected readonly defaultSystemAreaDocumentTypesLogic: DefaultSystemAreaDocumentTypesLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly toastService: ToastService,
        public readonly documentGroupLogic: DocumentGroupLogicService
    ) {
        super(
            cbDialog,
            defaultSystemAreaDocumentTypesLogic,
            {},
            'Default Document Types',
            {} as any,
            permissionsPermissions,
            false
        );
    }

    public addItem(): void {
        this.defaultSystemAreaDocumentTypesLogic
            .addDocumentType({
                systemArea: this.systemArea,
                lotContractType: this.selectedContractType,
                documentTypeId: this.selectedDocumentTypeId,
                documentGroupCode: this.selectedDocumentGroupCode
            }).subOnce({
                next: this.handleItemAddResponse,
                error: () => {
                    this.toastService.showToast(DefaultSystemAreaDocumentsComponent.ADD_ERROR);
                }
            });
    }

    public removeItem(item: IDefaultSystemAreaDocumentTypeDto): void {
        this.cbDialog.confirm({
            dialogHeading: 'Confirm Delete Document Type',
            message: `Are you you want to delete: ${LOT_CONTRACT_TYPE_ENUM[item.lotContractType]} - ${item.label}`,
            confirmed: () => {
                this.defaultSystemAreaDocumentTypesLogic
                    .deleteDocumentType(item)
                    .subOnce({
                        next: this.handleItemRemovalResponse.bind(this, item),
                        error: () => {
                            this.toastService.showToast(DefaultSystemAreaDocumentsComponent.DELETE_ERROR);
                        }
                    });
            }
        });
    }

    private readonly handleItemAddResponse = (result: IDefaultSystemAreaDocumentTypeDto): void => {
        if (!result) {
            this.toastService.showToast(DefaultSystemAreaDocumentsComponent.ADD_ERROR);
            return;
        }
        this.systemAreaDocumentTypes.push(result);
        this.refreshList();
        this.toastService.showToast(DefaultSystemAreaDocumentsComponent.ADD_SUCCESS);
    };

    private readonly handleItemRemovalResponse = (item: IDefaultSystemAreaDocumentTypeDto, response: boolean): void => {
        if (!response) {
            this.toastService.showToast(DefaultSystemAreaDocumentsComponent.DELETE_ERROR);
            return;
        }

        const index = this.systemAreaDocumentTypes.indexOf(item);
        if (index > -1) {
            this.systemAreaDocumentTypes.splice(index, 1);
            this.refreshList();
        } else {
            this.loadList();
        }
        this.toastService.showToast(DefaultSystemAreaDocumentsComponent.DELETE_SUCCESS);
    };

    private loadList(): void {
        this.defaultSystemAreaDocumentTypesLogic.$getList().subOnce((results) => {
            this.systemAreaDocumentTypes = results;
        });
    }

    /** call this after adding/removing a list item - due to caveat with matTable datasource */
    private refreshList(): void {
        this.resetSelectedDocumentType();
        this.systemAreaDocumentTypes = [...this.systemAreaDocumentTypes];
    }

    private resetSelectedDocumentType(): void {
        this.selectedDocumentTypeId = null;
        this.possibleDocumentTypes.recompute();
    }
}
