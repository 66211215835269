import { Injectable, Injector } from '@angular/core';
import { ITeamChangeRecordSearch } from '@app/core/services/user-cache/user-cache-areas';
import { IChangeRecordDocumentDto, IChangeRecordDto, IUnassignedChangeRecordDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Dictionary } from 'lodash';
import { map, Observable } from 'rxjs';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { BaseLogicServiceWithSearch } from '../base/base-logic-service-with-search.component';
import { ChangeRecordMappedItem } from './change-record.mapped';
import { IChangeRecordLogicService } from './interfaces/i.change-record.logic-service';
import { IChangeRecordMappedItem } from './interfaces/i.change-record.mapped';

@Injectable()
export class ChangeRecordLogicService
    extends BaseLogicServiceWithSearch<
    IChangeRecordDto,
    IChangeRecordMappedItem,
    ITeamChangeRecordSearch,
    IChangeRecordDocumentDto[]>
    implements IChangeRecordLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'changerecords',
            ChangeRecordMappedItem
        );
    }

    public getChangeRecords(lotId: number): Observable<IChangeRecordMappedItem[]> {
        return this.$http.get<IChangeRecordDto[]>(`lots/${lotId}/${this.$baseUri}`)
            .pipe(
                map(items => items.map(x => this.$createMappedItem(x)))
            );
    }

    public $saveItem(entity: IChangeRecordDto): Observable<IChangeRecordDto> {
        return this.$http.post<IChangeRecordDto>(`${this.$baseUri}/${entity.id || 0}`, entity);
    }

    public revertToInProgress(changeRecordId: number): Observable<IChangeRecordMappedItem> {
        return this.$http.post<IChangeRecordDto>(`${this.$baseUri}/${changeRecordId}/reverttoinprogress`)
            .pipe(
                map(item => this.$createMappedItem(item))
            );
    }

    public approveAndAcceptOption(changeRecordId: number, changeOptionId: number): Observable<IChangeRecordMappedItem> {
        return this.$http.post<IChangeRecordDto>(`${this.$baseUri}/${changeRecordId}/approveandaccept/${changeOptionId}`)
            .pipe(
                map(item => this.$createMappedItem(item))
            );
    }

    public submitForPricing(changeRecordIds: number[]): Observable<Dictionary<number, boolean>[]> {
        return this.$http.post(`${this.$baseUri}/submitforpricing`, changeRecordIds);
    }

    public generateChangeSummary(changeRecordId: number): Observable<any> {
        return this.$http.download(`/${this.$baseUri}/${changeRecordId}/summary`, 'Generating Change Summary...');
    }

    public generateChangesSignOffDocument(lotId: number): Observable<any> {
        return this.$http.download(`/allchangerecords/${lotId}`, 'Generating Changes SignOff Document...');
    }

    public getUnassignedChangeRecords(lotId: number): Observable<IUnassignedChangeRecordDto[]> {
        return this.$http.get(`lots/${lotId}/changerecords/unassigned`);
    }
}
