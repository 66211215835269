<ng-container *ngIf="isVariationsLoaded">
    <cb-lot-variations-action-bar *cbWaitFor="lotMappedItem"
                                  [lotMappedItem]="lotMappedItem"
                                  (variationRecordCreated)="variationCreated()">
    </cb-lot-variations-action-bar>
    <div class="cb-margin flex-row"
         style="gap: 16px">
        <cb-lot-variations-list *cbWaitFor="variationMappedItems"
                                [variationMappedItems]="variationMappedItems"
                                (variationSelected)="variationSelected($event)">
        </cb-lot-variations-list>
        <cb-lot-variation-view *ngIf="selectedVariation"
                               [variationMappedItem]="selectedVariation"
                               (variationMappedItemUpdated)="updateSelectedVariation($event)"
                               style="width: 100%">
        </cb-lot-variation-view>
        <cb-info-message *ngIf="!selectedVariation"
                         message="Select a Variation to view the Variation Details"> </cb-info-message>
    </div>
</ng-container>
<cb-loading-spinner *ngIf="!isVariationsLoaded"></cb-loading-spinner>
