import { Component, Input, OnInit } from '@angular/core';
import { DocumentTypesLogicService } from '@app/logic/document-types';
import { DocumentsLogicService, IDocumentEntityMappedItem, IDocumentGroupFrontEndDto, IDocumentDto } from '@app/logic/documents';
import { ILotMappedItem } from '@app/logic/lots';
import { SystemAreaLogicService } from '@app/logic/system-area';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { DOCUMENT_GROUP_CODES_CONST, IDocumentEntityDto, IDocumentUploadDto, SYSTEM_AREA_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { map } from 'lodash';
import { combineLatest } from 'rxjs';
import { IDocumentTypeDocumentGroupDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/DocumentTypeDocumentGroupDto';

@Component({
    selector: 'cb-request-design-scheme-step-four',
    templateUrl: './request-schemes-step-four.component.html',
    styleUrls: ['./request-schemes-step-four.component.scss'],
    viewProviders: [
        provideParentForm(),
    ]
})
export class RequestDesignSchemesStepFourComponent implements OnInit {
    public existingDocuments: IDocumentEntityMappedItem[] = [];
    public documentGroupsLoaded = false;
    public documentGroups: IDocumentEntityDto[] = [];
    public documentGroupsOptions = [];
    public selectedGroup: IDocumentGroupFrontEndDto;
    public selectedType: IDocumentTypeDocumentGroupDto;
    public availableForUploadDocumentGroups: IDocumentGroupFrontEndDto[];
    public availableForUploadDocumentTypes: IDocumentTypeDocumentGroupDto[];
    @Input() public documentsToUpload: any;
    public documentTypesLookup: any = {};

    public documentEntityMappedItem: IDocumentEntityMappedItem;
    @Input() public lotMappedItem: ILotMappedItem;

    constructor(
        public readonly documentsLogicService: DocumentsLogicService,
        public readonly systemAreaLogicService: SystemAreaLogicService,
        public readonly documentTypesLogicService: DocumentTypesLogicService,
    ) {
        this.documentTypesLogicService.$getList().subOnce(results => {
            this.documentTypesLookup = results.reduce(
                (prev, curr) => {
                    prev[curr.id] = curr;
                    return prev;
                },
                {}
            );
        });
    }

    public ngOnInit(): void {
        this.loadDocumentGroups();
    }

    public removeDocumentFromList(index: number): void {
        this.documentsToUpload.lotSitePhotoDocuments.splice(index, 1);
    }

    public onGroupSelection(value: IDocumentGroupFrontEndDto): void {
        this.documentEntityMappedItem.documentGroup = value;
        this.availableForUploadDocumentTypes = value.tempFilteredTypes;
    }

    public onTypeSelection(value: IDocumentTypeDocumentGroupDto): void {
        this.documentEntityMappedItem.documentType = value.documentType;
    }

    private loadDocumentGroups(): void {
        combineLatest([
            this.systemAreaLogicService.getBySystemArea(SYSTEM_AREA_ENUM.Lot),
            this.documentsLogicService
                .getMappedDocumentEntities(this.lotMappedItem.documentEntityUri, this.lotMappedItem.documentEntityId, false, false)
        ]).subOnce((result) => {
            let documentGroupsOriginal = result[0];
            this.existingDocuments = result[1];
            documentGroupsOriginal = documentGroupsOriginal.filter(x => x.codeName === DOCUMENT_GROUP_CODES_CONST.SITE_PHOTOS);

            this.availableForUploadDocumentGroups = this.documentsLogicService.filterAvailableGroups(documentGroupsOriginal as any, SYSTEM_AREA_ENUM.Lot) as any;


            this.documentGroupsOptions = map(this.availableForUploadDocumentGroups, (docGroup) => {
                return { ...docGroup, id: docGroup.id, label: docGroup.name };
            });
            this.documentEntityMappedItem = this.documentsLogicService.$createMappedItem();
            this.documentEntityMappedItem.document = {} as IDocumentDto;
            if (this.documentGroupsOptions.length === 1) {
                this.selectedGroup = this.documentGroupsOptions[0];
                this.onGroupSelection(this.selectedGroup);
            }
            this.documentGroupsLoaded = true;
        });
    }

    public addDocumentToList = (): void => {
        if (this.canAddDocumentToList()) {
            const clone = this.documentEntityMappedItem.$clone() as any;
            clone.documentGroupId = this.documentEntityMappedItem.documentGroup.id;
            clone.documentTypeId = this.documentEntityMappedItem.documentType.id;
            clone.file = this.documentEntityMappedItem.document.uploadedFile;
            clone.name = this.documentEntityMappedItem.document.uploadedFile.name;
            this.documentsToUpload.lotSitePhotoDocuments.push(clone as IDocumentUploadDto);
            this.resetDocumentForm();
        }
    };

    public canAddDocumentToList = (): boolean => {
        return !!this.documentEntityMappedItem;
    };

    public resetDocumentForm(): void {
        this.documentEntityMappedItem.document = {} as any;
    }
}
