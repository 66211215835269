<cb-dialog dialogHeading="{{ isEdit() ? 'Edit' : 'Add' }} Lot Interest">
    <form class="flex-col flex"
          #createLotForm="ngForm">
        <cb-lot-autocomplete-scroller *ngIf="enableHouseAndLandSearch()"
                                      label="House and Land Package"
                                      name="houseAndLandLot"
                                      [(ngModel)]="selectedLot"
                                      [availableOnly]="true"
                                      [requireMatch]="true"
                                      [matchProps]="['id']">
        </cb-lot-autocomplete-scroller>
        <cb-lot-autocomplete-scroller *ngIf="enableDesignAndLandSearch()"
                                      label="Design and Land Package"
                                      name="designAndLandLot"
                                      [(ngModel)]="selectedLot"
                                      [availableOnly]="true"
                                      [requireMatch]="true"
                                      [matchProps]="['id']"
                                      [lotContractTypes]="[LOT_CONTRACT_TYPE_ENUM.DesignAndLand]">
        </cb-lot-autocomplete-scroller>
        <div *ngIf="isDesignAndBuild()">
            <cb-input type="number"
                      label="Lot Number"
                      name="lotNumber"
                      [pattern]="positiveIntegerPattern"
                      [(ngModel)]="lot.lotNumber">
            </cb-input>
            <cb-address-new name="dbAddress"
                            [(ngModel)]="lot.lotAddress"
                            [required]="true"
                            [hideHeading]="true"
                            [regionId$]="regionId$">
            </cb-address-new>
            <cb-select label="Area"
                       name="area"
                       [(ngModel)]="selectedAreaId"
                       [options]="areas"
                       [required]="true"
                       noOptionsMessage="(Choose a Region to see available options)"
                       (change)="onAreaChanged()">
            </cb-select>
            <cb-select label="Build Consent Council"
                       name="sbBuildConsentCouncil"
                       [(ngModel)]="selectedBuildConsentCouncilId"
                       [options]="councils"
                       [required]="true"
                       noOptionsMessage="(Choose an Area to see available options)">
            </cb-select>
            <cb-input type="text"
                      label="Developer Project"
                      name="developerProject"
                      [(ngModel)]="developerProject">
            </cb-input>
            <label class="cb-margin-top-2x required">Does the client own land?</label>
            <mat-radio-group class="cb-margin-top flex-col"
                             aria-label="Does the client have land?"
                             name="dbClientHasLand"
                             [(ngModel)]="lot.isOwned"
                             [required]="true">
                <mat-radio-button [value]="true">Yes</mat-radio-button>
                <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
            <cb-select label="Build Type"
                       name="BuildType"
                       [(ngModel)]="selectedBuildTypeId"
                       [options]="buildTypes"
                       [required]="true">
            </cb-select>
        </div>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    [disabled]="createLotForm.pristine || createLotForm.invalid"
                    (cbClick)="addLot()"
                    [clickLimit]="1">{{ isEdit() ? 'Save' : 'Add' }}</button>
            <button mat-raised-button
                    (click)="cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
