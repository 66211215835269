<form #form="ngForm">
    <div class="flex-row"
         *cbWaitFor="roleDetails && securityRoleService.systemAreas">
        <div class="flex-col flex-80">
            <mat-tab-group>
                <mat-tab *ngFor="let area of securityRoleService.systemAreas | cbSortByPipe: 'label': 'asc'"
                         [label]="area.label">
                    <ng-template matTabContent>
                        <mat-card class="flex-col flex">
                            <div>
                                <h3>
                                    {{ area.label }} Permissions
                                </h3>
                            </div>
                            <div class="flex-wrap flex"
                                 *ngIf="getAreaPermissions(area.id).length > 0">
                                <mat-card class="permission-card flex-none"
                                          *ngFor="let permission of getAreaPermissions(area.id); let i = index">
                                    <mat-label class="not-set-label mat-form-field-label">
                                        {{toHumanizeWords(permission.name)}}
                                    </mat-label>
                                    <cb-nullable-boolean class="permission-select"
                                                         [label]="getLabel(permission)"
                                                         trueLabel="Allow"
                                                         falseLabel="Deny"
                                                         [name]="permission.area + '_' + i"
                                                         [(ngModel)]="permission.explicitAllow"
                                                         [disabled]="!isEditOrCreate()">
                                    </cb-nullable-boolean>
                                </mat-card>
                            </div>
                            <div class="flex-row flex-fill"
                                 *ngIf="getAreaPermissions(area.id).length < 1">
                                <mat-icon>info</mat-icon>
                                &nbsp;No Permissions available for this Area
                                <span class="flex"
                                      layout-padding>
                                </span>
                            </div>
                        </mat-card>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div class="side-bar flex-col flex-20">
            <mat-card class="side-bar-card">
                <div *ngIf="!isEditOrCreate()">
                    <h3 class="cb-title">
                        {{roleDetails.name}}
                    </h3>
                    <mat-divider></mat-divider>
                </div>
                <mat-checkbox name="isActive"
                              style="height: 25px;"
                              [(ngModel)]="roleDetails.isActive"
                              [disabled]="!isEditOrCreate()">
                    Active
                </mat-checkbox>
                <cb-input *ngIf="isEditOrCreate()"
                          type="text"
                          label="Role Name"
                          [(ngModel)]="roleDetails.name"
                          name="name"
                          [required]="true"
                          [maxlength]="100"
                          [disabled]="!isEditOrCreate()">
                </cb-input>
                <div class="flex-col">
                    <button *ngIf="isView() && !isEditOrCreate()"
                            mat-raised-button
                            (click)="editItem()">
                        Edit Role
                    </button>
                    <button *ngIf="isEditOrCreate()"
                            mat-raised-button
                            color="primary"
                            (click)="saveItem()"
                            [disabled]="form.pristine">
                        {{isEdit() ? 'Save Role' : 'Create'}}
                    </button>
                    <button *ngIf="isEditOrCreate()"
                            mat-raised-button
                            (click)="cancel($event)">
                        Cancel
                    </button>
                </div>
            </mat-card>
        </div>
    </div>
</form>
