import {Component, Input} from '@angular/core';

@Component({
    selector: 'cb-td-list[list]',
    templateUrl: './comma-seperated-list.component.html',
    styleUrls: ['./../table-cell.scss']
})
export class TdCommaSeperatedListComponent {
    @Input() public readonly list: any[];
    @Input() public readonly valueProp: string;

    public getText(item: any): string {
        if (typeof item === 'string') {
            return item || '-';
        } else {
            return item[this.valueProp] || item.name || item.label || '-';
        }
    }
}
