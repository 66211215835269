import { AutocompleteScrollerComponent, getBaseAutocompleteScrollerProvider } from '../../autocomplete-scroller.component';
import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SearchService } from '@app/core/services/search/base.search.service';
import { BuildProgrammeLogicService } from '@app/logic/build-programme';
import { SupplyTypesLogicService } from '@app/logic/supply-types/supply-types.logic.service';
import { IEnum } from '@app/shared/interfaces/i.lookup.dto';
import { takeOne } from 'cb-hub-lib';
import { Observable, map } from 'rxjs';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';
import { ISupplyTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-supply-type-autocomplete-scroller',
    templateUrl: '../../autocomplete-scroller.component.html',
    providers: [
        ...getBaseFormComponentDirectiveProvider(SupplyTypeAutocompleteScrollerComponent),
        getBaseAutocompleteScrollerProvider(SupplyTypeAutocompleteScrollerComponent),
        SearchService,
    ]
})
export class SupplyTypeAutocompleteScrollerComponent
    extends AutocompleteScrollerComponent<ISupplyTypeDto> {
    public results: ISupplyTypeDto[] = [];
    private resultsLoaded = false;

    @Input() public readonly isParentOnly = false;

    constructor(
        public readonly buildProgrammeLogicService: BuildProgrammeLogicService,
        public readonly dialog: MatDialog,
        public readonly searchService: SearchService,
        protected readonly supplyTypeLogicService: SupplyTypesLogicService
    ) {
        super(dialog, searchService);
        this.searchService.doSearch = this.doSearch;
    }

    public doSearch = (query: string): Observable<any[]> => {
        return this.getResults()
            .pipe(takeOne(), map((results) => {
                const filtered = results.filter(x =>
                    (this.displayWith(x as any) || '').toLowerCase().indexOf(query.toLowerCase()) > -1
                );
                return filtered;
            }));
    };

    public displayWith(supplyType: any): string {
        if (supplyType) {
            if (supplyType.parentLabel) {
                return `${supplyType.parentLabel} - ${supplyType.label}`;
            } else {
                return supplyType.label;
            }
        }
    }

    private getResults(): Observable<ISupplyTypeDto[]> {
        return new Observable<ISupplyTypeDto[]>((subscriber) => {
            if (!this.resultsLoaded) {
                this.resultsLoaded = true;
                const resultsObservable = this.supplyTypeLogicService.$getList();
                resultsObservable
                    .subOnce(x => {
                        if (this.isParentOnly) {
                            this.results = x.filter(st => !st.parentId);
                        } else {
                            this.results = x.filter(st => !!st.parentId);
                        }
                        subscriber.next(this.results);
                        subscriber.complete();
                    });
            } else {
                subscriber.next(this.results);
                subscriber.complete();
            }
        });
    }

    // overriding the parent Method
    public onScroll(): void {
        return;
    }
}
