<form #permissionsForm="ngForm">
    <mat-accordion [multi]="true"
                   class="accordion cb-padding-bottom">
        <ng-container *ngFor="let area of systemAreasOptions$ | async">
            <mat-expansion-panel class="panel cb-margin-right-3x"
                                 [id]="area.id"
                                 *ngIf="area.permissions?.length"
                                 [(expanded)]="expandedPanels[area.id]"
                                 (expandedChange)="setAllExpanded()">
                <mat-expansion-panel-header class="cb-padding-top cb-padding-bottom"
                                            style="min-height: 50px">
                    <mat-panel-title>
                        <span class="mat-body-2 cb-margin-top">{{area.label}}&nbsp;({{area.permissions?.length}})</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="end-center flex-col"
                     *ngFor="let permission of area.permissions | cbSortByPipe: '$label' : 'asc'; let index = index;">
                    <mat-divider class="cb-margin-bottom"
                                 style="width: 100%; height: 1px"
                                 *ngIf="index === 0">
                    </mat-divider>
                    <div class="start-center flex-row flex-fill"
                    >
                        <span class="flex-5"></span>
                        <span class="flex-30">{{permission.$label}}</span>
                        <span class="flex-30"
                              *ngIf="!(isEdit$ | async) && expandedPanels[area.id]">
                            {{permission.$value?.label}}
                        </span>
                        <span class="flex-30"
                              *ngIf="(isEdit$ | async) && expandedPanels[area.id]">
                            <cb-select name="permission_{{permission.$label}}_{{permission.$areaLabel}}_{{index}}"
                                       label="{{permission.$areaLabel}} {{permission.$label}}"
                                       [(ngModel)]="permission.$value"
                                       [valueProp]="WHOLE_OPTION_VALUE_PROP"
                                       [options]="permission.$statusOptions">
                            </cb-select>
                        </span>
                        <span class="flex-15">
                            <cb-button (clicked)="explain(permission)"
                                       color="white"
                                       icon="help_outline"
                                       tooltip="Some stuff and stuff">
                            </cb-button>
                        </span>
                    </div>
                    <mat-divider style="width: 100%; height: 1px"
                                 class="cb-margin-bottom cb-margin-top">
                    </mat-divider>
                </div>
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
</form>
