<div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-3x flex-col flex">
    <div class="start-center flex-row layoutGap-8">
        <h3 class="mat-title flex">Security Roles</h3>
        <button mat-raised-button
                (click)="generateReport()"
                [disabled]="!canView()"
                color="primary">
            Security Role Report
        </button>
        <button mat-raised-button
                (click)="generateRolePermissionReport()"
                [disabled]="!canView()"
                color="primary">
            Role Permissions Report
        </button>
        <button mat-raised-button
                (click)="createRole($event)"
                [disabled]="!canEdit()"
                color="primary">
            Create Role
        </button>
    </div>
    <cb-table *cbWaitFor="searchResults"
              class="cb-margin-top"
              label="Security Roles"
              [stringColumns]="displayedColumns"
              [loaded]="searchResultsLoaded"
              [minWidth]="1000"
              [maxHeightOffset]="239">
        <tbody cbTableBody>
            <tr *ngFor="let role of searchResults | cbSortByPipe: 'name': 'asc'">
                <td>{{role.name}}</td>
                <td>{{role.members}}</td>
                <cb-td-checkbox [value]="role.isActive"></cb-td-checkbox>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <button mat-icon-button
                            (click)="viewRole(role, $event)"
                            [disabled]="!canEdit()">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </cb-table>
</div>
