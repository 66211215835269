import { ChangeDetectorRef, Component, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject, Subscription, map } from 'rxjs';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { ITeamPricingRevisionSearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { orderBy } from 'lodash';
import { PricingRevisionsLogicService } from '@app/logic/pricing-revisions/pricing-revisions.logic.service';
import {
    IPricingRevisionDocumentDto,
    IPricingRevisionDto,
    PRICING_REVISION_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { QSTeamPermissions } from '@app/core/permissions';
import { FormMode } from '@app/shared/enums/form';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IPricingRevisionMappedItem } from '@app/logic/pricing-revisions/interfaces/i.pricing-revision.mapped';
import { ManagePricingRevisionDialogComponent } from '@app/views/lot/costs/lot-costs-pricing-revisions/manage-pricing-revision-dialog/manage-pricing-revision-dialog.component';
import {
    FinalisePricingRevisionDialogComponent
} from '@app/views/lot/costs/lot-costs-pricing-revisions/finalise-pricing-revision-dialog/finalise-pricing-revision-dialog.component';

@Component({
    selector: 'cb-pricing-revisions',
    templateUrl: './pricing-revisions.component.html',
    styleUrls: ['./pricing-revisions.component.scss'],
})
export class PricingRevisionsComponent implements OnDestroy, AfterViewInit {

    public resultOrders$ = of([
        { id: 'jobNumber', label: 'Job Number' },
        { id: 'assignedTo', label: 'Assigned To' },
        { id: 'createdDate', label: 'Created Date' },
        { id: 'dueDate', label: 'Due Date' },
        { id: 'status', label: 'Status' }
    ]);

    @Input() public resultDirections$: Observable<{
        id: string;
        label: string;
    }[]>;

    protected readonly String = String;
    private _subscriptions = new Subscription();
    public searchEnabled$ = new BehaviorSubject(null);
    public readonly searchFiltersChanged$ = new Subject();
    public currentPage: number;
    public teamCode: string;
    public loaded = false;
    public cacheLoaded$ = new BehaviorSubject(null);
    public results: IPricingRevisionDocumentDto[] = [];
    public readonly allUsers = '999';
    public readonly unassigned = undefined;
    public infiniteScrollEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public revisionStatuses$ = of(PRICING_REVISION_STATUS_ENUM.toLookup())
        .pipe(
            map(revisionStatuses => {
                const orderedRevisionStatuses = orderBy(revisionStatuses, tag => tag.label.toLowerCase());
                return orderedRevisionStatuses;
            })
        );

    public teamUsersOptions$ = this.teamsLogicService
        .loadAllMembersByKey('QSTEAM').pipe(
            map(users => {
                users = orderBy(users, 'firstName');
                users.unshift({ id: this.allUsers, label: 'All' } as any);
                users.unshift({ id: this.unassigned, label: 'Unassigned' } as any);
                return users;
            })
        );

    public get searchEnabled(): boolean {
        return this.searchEnabled$.value;
    }

    public get cacheLoaded(): boolean {
        return this.cacheLoaded$.value;
    }

    public get searchFiltersCache(): UserCacheItem<ITeamPricingRevisionSearch> {
        return this.userCacheService.qsTeamPricingRevisionSearch;
    }

    constructor(
        private readonly teamsLogicService: TeamsLogicService,
        private readonly logicService: PricingRevisionsLogicService,
        public cdRef: ChangeDetectorRef,
        private readonly userCacheService: UserCacheService,
        public readonly route: ActivatedRoute,
        private readonly dialogService: CbDialogService,
        private readonly qsTeamPermissions: QSTeamPermissions,
        private readonly navigationService: NavigationService
    ) {

    }

    public ngAfterViewInit(): void {
        this._initSearchFiltersCache();
        this.cdRef.detectChanges();
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public createRevision(): Observable<any> {
        return this.dialogService
            .open(
                ManagePricingRevisionDialogComponent,
                {
                    data: {
                        mappedItem: this.logicService.$createMappedItem(),
                        mode: FormMode.Add
                    },
                }
            ).afterClosed();
    }

    public fetchResults(): Observable<IPricingRevisionDocumentDto[]> {
        if (!this.searchEnabled) {
            return;
        }
        this.loaded = true;
        return this.logicService.$getSearchList(this._queryParams);
    }

    public hasPermissionsToEditRevision = (): boolean => {
        if (this.qsTeamPermissions) {
            return this.qsTeamPermissions.canEditRevision();
        }

        return false;
    };

    public hasPermissionsToFinaliseRevision = (): boolean => {
        if (this.qsTeamPermissions) {
            const can = this.qsTeamPermissions.canFinaliseRevision();
            return can;
        }

        return false;
    };

    public readonly editRevision = async (
        revision?: IPricingRevisionDocumentDto,
        mode = FormMode.Edit
    ): Promise<Subscription> => {
        let mappedItem: IPricingRevisionMappedItem;
        if (revision) {
            mappedItem = await this.logicService.$getMappedItem(revision.id).toPromise();
        } else {
            mappedItem = this.logicService.$createMappedItem();
        }
        return this.dialogService
            .open(
                ManagePricingRevisionDialogComponent,
                {
                    data: {
                        mappedItem,
                        mode
                    },
                }
            ).afterClosed()
            .subOnce({
                next: (result: IPricingRevisionMappedItem) => {
                    if (result && result.id) {
                        const foundIndex = this.results.findIndex(x => x.id === result.id);
                        // TODO: fix this. Doing this bypasses the type requirement ( result is not an IPricingRevisionDocumentDto )
                        Object.assign(this.results[foundIndex], result);
                        this.cdRef.detectChanges();
                    }
                }
            });
    };

    public async finaliseRevision(revision: IPricingRevisionDocumentDto): Promise<Subscription> {
        const currentMappedItem = await this.logicService.$getMappedItem(revision.id).toPromise();
        return this.dialogService
            .open(
                FinalisePricingRevisionDialogComponent,
                {
                    data: {
                        currentMappedItem
                    },
                }
            ).afterClosed()
            .subOnce();
    }

    public viewLot(lotId: number): void {
        if (lotId) {
            this.navigationService.navigate([`/lots/${lotId}/summary`]);
        }
    }

    private get _queryParams(): ITeamPricingRevisionSearch {
        return this.searchEnabled ?
            {
                ...this.searchFiltersCache.copyData(),
                userId: this.searchFiltersCache.data.userId === this.allUsers ?
                    undefined :
                    this.searchFiltersCache.data.userId ?
                        this.searchFiltersCache.data.userId :
                        undefined,
                statusId: this.searchFiltersCache.data.statusId,
                currentPage: this.currentPage,
                isUnassigned: !this.searchFiltersCache.data.userId
            } :
            undefined;
    }

    private readonly _onSearchFiltersChanged = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.currentPage = 1;
        this.searchFiltersChanged$.next(null);
    };

    public _initSearchFiltersCache(): void {
        this.searchFiltersCache.init().then(() => {
            this.cacheLoaded$.next(true);
            this.searchEnabled$.next(true);
            this._subscriptions.add(
                this.searchFiltersCache.updated$.subscribe({
                    next: this._onSearchFiltersChanged
                })
            );

            this.searchFiltersCache.silentData.canSeeAll = this.qsTeamPermissions.canSeeAll();
            if (!this.searchFiltersCache.silentData.canSeeAll) {
                this.searchFiltersCache
                    .silentData
                    .userId = this.searchFiltersCache.currentUserId;
            }
            else {
                this.searchFiltersCache.data.userId = this.allUsers;
            }
            this._onSearchFiltersChanged();
        });
    }
}
