import {Component, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {StandardPlanSearchServiceService} from '@app/core/services/search/standard-plan-search-service.service';
import {StandardPlanLogicService} from '@app/logic/standard-plans/standard-plan.logic.service';
import {getBaseFormComponentDirectiveProvider} from '../../../base-form-component';
import { AutocompleteScrollerComponent, getBaseAutocompleteScrollerProvider, IBasicAutocompleteLookup } from '../../autocomplete-scroller.component';

@Component({
    selector: 'cb-standard-plan-autocomplete-scroller',
    templateUrl: '../../autocomplete-scroller.component.html',
    providers: [
        ...getBaseFormComponentDirectiveProvider(StandardPlantAutocompleteScrollerComponent),
        getBaseAutocompleteScrollerProvider(StandardPlantAutocompleteScrollerComponent),
        StandardPlanSearchServiceService,
    ]
})
export class StandardPlantAutocompleteScrollerComponent extends AutocompleteScrollerComponent<IBasicAutocompleteLookup> implements OnInit {

    constructor(
        public readonly standardPlanLogicService: StandardPlanLogicService,
        public readonly dialog: MatDialog,
        public readonly searchService: StandardPlanSearchServiceService
    ) {
        super(dialog, searchService);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

}
