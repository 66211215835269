<div class="flex-col flex">
    <cb-display-value-new *ngIf="!buildProgrammeActivity.ssrId"
                          class="cb-margin-top"
                          label="Name"
                          [value]="buildProgrammeActivity.activity.name"></cb-display-value-new>
    <div class="flex-row layoutGap-20">
        <cb-date-duration-calculator *ngIf="lotBuildProgrammeEvents.lotId && buildProgrammeActivity.actualStartDate"
                                     name="actualDate"
                                     startDateLabel="Actual Start Date"
                                     [(startDate)]="buildProgrammeActivity.actualStartDate"
                                     [(duration)]="buildProgrammeActivity.activityDurationDays"
                                     [lotId]="lotBuildProgrammeEvents.lotId"
                                     (updated)="dateChange()"
                                     (calculating)="dateCalculating.emit($event)"
                                     (durationChange)="durationHasChanged($event)"
                                     [required]="true"
                                     pickerType="calendar"></cb-date-duration-calculator>
        <cb-date-duration-calculator *ngIf="lotBuildProgrammeEvents.lotId && !buildProgrammeActivity.actualStartDate"
                                     name="estimatedDate"
                                     startDateLabel="Estimated Start Date"
                                     [(startDate)]="buildProgrammeActivity.estimatedStartDate"
                                     [(duration)]="buildProgrammeActivity.activityDurationDays"
                                     [lotId]="lotBuildProgrammeEvents.lotId"
                                     (updated)="dateChange()"
                                     (calculating)="dateCalculating.emit($event)"
                                     [required]="true"
                                     pickerType="calendar"></cb-date-duration-calculator>
        <cb-display-value-new label="Template Duration (Days)"
                              [value]="buildProgrammeActivity.templateActivityDuration"></cb-display-value-new>
    </div>
    <div class="flex-row layoutGap-20">
        <cb-checkbox label="Move Confirmed Successor Activities"
                     name="moveConfirmedActivity"
                     [(ngModel)]="buildProgrammeActivity.moveConfirmedDescendants"
                     [disabled]="!datesHaveChanged"></cb-checkbox>
        <cb-checkbox label="Locked"
                     name="isLocked"
                     [(ngModel)]="buildProgrammeActivity.isLocked"
                     [disabled]="!buildProgrammeActivity.canEditIsLocked"></cb-checkbox>
    </div>
</div>
