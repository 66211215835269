import { ISearchResult } from '@app/shared/components/search/i.search';
import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { IIntegrationLogSearchDto } from './interfaces/i.integration-log-search.dto';
import { IIntegrationLogsLogicService } from './interfaces/i.integration-logs-logic.service';
import { cleanObjectDeep } from 'cb-hub-lib';
import { IIntegrationMessageSearchDto } from './interfaces/i.integration-message-search.dto';
import { HttpResponse } from '@angular/common/http';
import { IFinanceAbmLogDocumentDto } from '@classictechsolutions/hubapi-transpiled-enums';

const NOT_IMPLEMENTED_EXCEPTION = 'Method not implemented';
@Injectable()
export class IntegrationLogsLogicService
    extends BaseLogicService<IIntegrationLogSearchDto, Object>
    implements IIntegrationLogsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'integrationlog',
            Object
        );
    }

    public $getItem(entityId: number): Observable<IIntegrationLogSearchDto> {
        throw new Error(NOT_IMPLEMENTED_EXCEPTION);
    }

    public $saveItem(entity: IIntegrationLogSearchDto): Observable<IIntegrationLogSearchDto> {
        throw new Error(NOT_IMPLEMENTED_EXCEPTION);
    }

    public $deleteItem<ReturnType>(id: number): Observable<ReturnType> {
        throw new Error(NOT_IMPLEMENTED_EXCEPTION);
    }

    public $getList(): Observable<Array<IIntegrationLogSearchDto>> {
        throw new Error(NOT_IMPLEMENTED_EXCEPTION);
    }

    public searchFinanceExceptionLogs(
        params: { currentpage: number; query?: string; dateFrom?: string; dateTo?: string }
    ): Observable<ISearchResult<IIntegrationLogSearchDto>> {
        cleanObjectDeep(params);
        return this.$http
            .get<ISearchResult<IIntegrationLogSearchDto>>('financeexceptionlog/search', params);
    }

    public searchContactIntegrationExceptionLogs(
        params: { currentpage: number; query?: string; dateFrom?: string; dateTo?: string }
    ): Observable<ISearchResult<IIntegrationLogSearchDto>> {
        cleanObjectDeep(params);
        return this.$http
            .get<ISearchResult<IIntegrationLogSearchDto>>('contactintegrationexceptionlog/search', params);
    }

    public searchMessages(
        params: { currentpage: number; query?: string; dateFrom?: string; dateTo?: string; messageName?: string }
    ): Observable<ISearchResult<IIntegrationMessageSearchDto>> {
        cleanObjectDeep(params);
        return this.$http
            .get<ISearchResult<IIntegrationMessageSearchDto>>('financemessaginglog/search', params);
    }

    public searchAbmMessages(
        params: {
            currentpage: number;
            query?: string;
            dateFrom?: string;
            dateTo?: string;
            messageName?: string;
            orderNumber?: string;
        }): Observable<ISearchResult<IFinanceAbmLogDocumentDto>> {
        cleanObjectDeep(params);
        return this.$http.get<ISearchResult<IFinanceAbmLogDocumentDto>>('financeabmlog/search', params);
    }

    public sendPing(): Observable<void> {
        return this.$http
            .post<void>('financeintegration/ping');
    }

    public exportToCsv(
        params: {
            query?: string;
            dateFrom?: string;
            dateTo?: string;
            messageName?: string;
            orderNumber?: string;
        }
    ): Observable<HttpResponse<ArrayBuffer>> {
        cleanObjectDeep(params);
        return this.$http.download('financeabmlog/exportsearch', 'Exporting to CSV...', params);
    }
}
