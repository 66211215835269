import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '@src/environments/environment';

@Component({
    selector: 'cb-selection-centre-dialog',
    templateUrl: './selection-centre-dialog.component.html',
    styleUrls: ['./selection-centre-dialog.component.scss'],
})
export class SelectionCentreDialogComponent {

    public static readonly MIN_WIDTH = '100%';

    relatedSlots: any[] = [];
    swappedItem: any = null;
    selectedItem: any = null;

    swappedItems: Set<any>;
    isRelatedSlotsLoading = false;

    constructor(
        public readonly dialogRef: MatDialogRef<SelectionCentreDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: any,
        private cdr: ChangeDetectorRef,
        private sanitizer: DomSanitizer
    ) {
        console.log(data);
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    relatedSlotsUpdated(relatedSlots: any[]): void {
        this.isRelatedSlotsLoading = true;
        // Simulate data update
        setTimeout(() => {
            this.relatedSlots = relatedSlots;
            this.isRelatedSlotsLoading = false;
            this.cdr.detectChanges();
        }, 1000); // Simulate API delay
    }

    swappedItemChanged(swappedItem: any): void {
        this.swappedItem = swappedItem;
        this.cdr.detectChanges();
    }

    selectedItemChanged(selectedItem: any): void {
        this.selectedItem = selectedItem;
        this.cdr.detectChanges();
    }

    getProductImage(product: any): string {
        if (!product.hasImage) {
            return 'https://productdistributionstrategy.com/wp-content/uploads/2019/09/retail-distribution-strategy.svg';
        }
        return `${environment.api}/${product.imageUrl}`;
    }

    getArrowImage(): string{
        return 'https://www.svgrepo.com/show/221870/right-arrow-send.svg';
    }

    saveSelection(selectedSlots: Set<any>) {
        this.swappedItems = selectedSlots;
    }

    itemWasSwapped(item): boolean {
        if(!this.swappedItems){
            return false;
        }
        return this.hasObjectWithProperty(this.swappedItems, 'slotId', item.slotId);
    }

    hasObjectWithProperty(set: Set<any>, property: string, value: any): boolean {
        return Array.from(set).some(obj => obj[property] === value);
    }


    getRateChangeText(oldRate: number, newRate: number): SafeHtml {
        const rateIncreased = newRate - oldRate;
        const html = rateIncreased >= 0
            ? `<span style='color:green'><h3>+${rateIncreased.toFixed(2)}</h3></span>`
            : `<span style='color:red'><h3>-${Math.abs(rateIncreased).toFixed(2)}</h3></span>`;

        // Sanitize the HTML to make it safe for binding
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}
