<cb-dialog dialogHeading="Quote Line">
    <ng-container *ngIf="canGenerateChangesForQuoteLines()">
        <cb-info-message [message]="'Submitting this Quote will generate the following Changes.'"></cb-info-message>
        <mat-card *ngFor="let key of keys"
                  class="cb-margin-top">
            <div class="cb-margin-bottom">
                <strong> {{variationsToGenerate[key][0]?.costNatureId | cbEnumToLabel:COST_NATURE_ENUM }}</strong>
            </div>
            <cb-table [loaded]="true">
                <thead cbTableHead>
                    <tr>
                        <td>Ref</td>
                        <td>Product/Description</td>
                        <td style="text-align: right">Rate</td>
                    </tr>
                </thead>
                <tbody cbTableBody>
                    <tr *ngFor="let variationQuoteLine of variationsToGenerate[key]">
                        <td>{{variationQuoteLine.reference}}</td>
                        <td>{{variationQuoteLine.description | cbDasher}}</td>
                        <td style="text-align: right">{{variationQuoteLine.allocatedAmount | cbCurrency}}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <strong>Variation Total</strong>
                        </td>
                        <td style="text-align: right">
                            <strong>{{calcVariationTotal(variationsToGenerate[key]) |
                                cbCurrency}}</strong>
                        </td>
                    </tr>
                </tbody>
            </cb-table>
        </mat-card>
    </ng-container>
    <cb-info-message *ngIf="!canGenerateChangesForQuoteLines() || isVariationsEmpty()"
                     [message]="getNoChangesMessage()"
                     [icon]="'info_circle'">
    </cb-info-message>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                (click)="submitQuote()">Submit</button>
        <button class="raised"
                mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
