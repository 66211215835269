import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ILotSpecAllItem } from '@app/logic/lot-spec/interfaces/i.lot-spec-all-item.dto';
import { ILotSpecMappedItem } from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import { COST_TYPE_ENUM, ILotSpecColourItemDto, ILotSpecItemDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { ColWidthSyncService } from '../services/col-width-sync.service';
import {ITableColumn} from "cb-hub-lib";

@Component({
    selector: 'cb-lot-spec-item-list-table',
    templateUrl: './lot-spec-item-list-table.component.html',
    styleUrls: ['./lot-spec-item-list-table.component.scss'],
})
export class LotSpecItemListTableComponent {

    @Input() public readonly lotSpec: ILotSpecMappedItem;
    @Input() public readonly items: ILotSpecAllItem[];
    @Output() public readonly specItemDeleted = new EventEmitter<ILotSpecItemDto>();
    @Output() public readonly colourItemDeleted = new EventEmitter<ILotSpecItemDto>();
    @Output() public readonly specItemEdited = new EventEmitter<ILotSpecItemDto>();
    @Output() public readonly colourItemEdited = new EventEmitter<ILotSpecColourItemDto>();

    public readonly COST_TYPE_ENUM = COST_TYPE_ENUM;
    public columns: ITableColumn[];

    constructor(
        /** provided by LotSpecItemsListComponent */
        public readonly colWidthSync: ColWidthSyncService,
    ) {
        this.setColumns();
    }

    private setColumns = (): void => {
        const ctrl = this;
        this.columns = [
            { label: '', width: 96 },
            {
                label: 'Code', get width() { return ctrl.colWidthSync.code.value; }
            },
            { label: 'Line Item' },
            { label: 'Category', get width() { return ctrl.colWidthSync.category.value; } },
            { label: 'Cost Type', width: 106 },
            {
                label: 'Supplier', get width() { return ctrl.colWidthSync.supplier.value; }
            },
            { label: ''}
        ];
    };
}
