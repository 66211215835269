<div class="flex-col flex">
    <div class="end-center flex-row flex">
        <button mat-raised-button
                class="cb-margin-bottom-2x cb-margin-top-2x cb-margin-right-2x"
                color="primary"
                (cbClick)="requestImagesDialog()"
                [disabled]="lots?.length < 1"
                *ngIf="projectReleaseStagePermissions.canRequestImages()">
            Request Images
        </button>
        <button mat-raised-button
                class="cb-margin-bottom-2x  cb-margin-top-2x cb-margin-right-2x"
                color="primary"
                (cbClick)="requestSchemeDialog()"
                [disabled]="!hasLotsToRequestSchemesOn()"
                *ngIf="projectReleaseStagePermissions.canRequestScheme()">
            Request Scheme
        </button>
        <button mat-raised-button
                class="cb-margin-bottom-2x  cb-margin-top-2x"
                color="primary"
                (cbClick)="requestPricingDialog()"
                [disabled]="!hasLotsToRequestPricingOn()"
                *ngIf="projectReleaseStagePermissions.canRequestPricing()">
            Request Pricing
        </button>
    </div>
    <cb-table *cbWaitFor="mappedItem"
              class="cb-margin-top cb-margin-bottom-3x"
              [loaded]="loaded">
        <thead cbTableHead>
            <tr>
                <td>Lot #</td>
                <td>Job #</td>
                <td>Build Link</td>
                <td>Scheme Status</td>
                <td>Applied Concept</td>
                <td>Image Status</td>
                <td>Floor Area</td>
                <td>Bed</td>
                <td>Bath</td>
                <td>Living</td>
                <td>Garage</td>
                <td></td>
            </tr>
        </thead>
        <tbody cbTableBody>
            <tr *ngFor="let lot of lots | cbSortByPipe: 'lotNumber': 'asc'">
                <cb-td-text [value]="lot.lotNumber"></cb-td-text>
                <cb-td-text [value]="lot.jobNumber"></cb-td-text>
                <cb-td-text [value]="lot.lotBuildTypeLink"></cb-td-text>
                <td>
                    <cb-td-text *ngIf="!lot.designSchemeStatusId"
                                value="Any"></cb-td-text>
                    <cb-td-enum *ngIf="lot.designSchemeStatusId"
                                [value]="lot.designSchemeStatusId"
                                [enum]="DESIGN_SCHEME_STATUS_ENUM">
                    </cb-td-enum>
                </td>
                <cb-td-text [value]="lot.appliedConceptName"></cb-td-text>
                <td>
                    <mat-icon class="cb-grey"
                              *ngIf="lot.hasAppliedConceptSingleImage">photo</mat-icon>
                    <mat-icon class="cb-grey"
                              *ngIf="lot.hasAppliedConceptBothImages">collections</mat-icon>
                </td>
                <cb-td-text [value]="lot.amenities.floorArea"></cb-td-text>
                <td style="white-space: nowrap;">
                    <mat-icon class="cb-grey"
                              *ngIf="!isBlockLotType(lot.lotType)">hotel</mat-icon>
                    &nbsp;{{getAmenitiesDisplay(lot.amenities.noBedrooms,
                    lot.lotType)}}
                </td>
                <td style="white-space: nowrap;">
                    <mat-icon class="cb-grey"
                              *ngIf="!isBlockLotType(lot.lotType)">wc</mat-icon>
                    &nbsp;{{getAmenitiesDisplay(lot.amenities.noBathrooms,
                    lot.lotType)}}
                </td>
                <td style="white-space: nowrap;">
                    <mat-icon class="cb-grey"
                              *ngIf="!isBlockLotType(lot.lotType)">weekend</mat-icon>
                    &nbsp;{{getAmenitiesDisplay(lot.amenities.noLivingRooms,
                    lot.lotType)}}
                </td>
                <td style="white-space: nowrap;">
                    <mat-icon class="cb-grey"
                              *ngIf="!isBlockLotType(lot.lotType)">directions_car</mat-icon>
                    &nbsp;{{getAmenitiesDisplay(lot.amenities.noGarages,
                    lot.lotType)}}
                </td>
                <td cbTableStickyCol="end"
                    cbTableShrinkCol>
                    <button [matMenuTriggerFor]="menu"
                             mat-icon-button>
                        <mat-icon class="cb-grey">more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item
                                *ngIf="lotPermissions.canView()"
                                (cbClick)="viewLot(lot)">
                            <mat-icon class="cb-grey">pageview</mat-icon>
                            &nbsp;
                            View Lot
                        </button>
                        <button *ngIf="lotPermissions.canEdit()"
                                mat-menu-item
                                [disabled]="!canEditLot(lot) || lot.hasClientSale"
                                (cbClick)="editLot(lot)">
                            <mat-icon class="cb-grey">mode_edit</mat-icon>
                            &nbsp;
                            Edit Lot
                        </button>
                        <button mat-menu-item
                                *ngIf="lotPermissions.canView()"
                                (cbClick)="viewLotDetails(lot)">
                            <mat-icon class="cb-grey">mode_edit</mat-icon>
                            &nbsp;View Lot Details
                        </button>
                        <button *ngIf="lotPermissions.canApplyLotSpec()"
                                mat-menu-item
                                [disabled]="!canSelectSpecTemplate(lot)"
                                (cbClick)="applyTemplate(lot)">
                            <mat-icon class="cb-grey">library_books</mat-icon>
                            &nbsp;Select Spec Template
                        </button>
                        <button *ngIf="projectReleaseStagePermissions.canRequestScheme()"
                                mat-menu-item
                                [disabled]="!canRequestDesignScheme(lot)"
                                (cbClick)="requestScheme(lot)">
                            <mat-icon class="cb-grey">library_books</mat-icon>
                            &nbsp;Request Scheme
                        </button>
                        <button *ngIf="projectReleaseStagePermissions.canRequestPricing()"
                                mat-menu-item
                                [disabled]="!canRequestPricing(lot)"
                                (cbClick)="requestPricing(lot)">
                            <mat-icon class="cb-grey">library_books</mat-icon>
                            &nbsp;Request Pricing
                        </button>
                        <button *ngIf="projectReleaseStagePermissions.canRequestImages()"
                                mat-menu-item
                                [disabled]="!canRequestRender(lot)"
                                (cbClick)="requestRender(lot)">
                            <mat-icon class="cb-grey">library_books</mat-icon>
                            &nbsp;Request Render
                        </button>
                        <button *ngIf="projectReleaseStagePermissions.canRequestImages()"
                                mat-menu-item
                                [disabled]="!canRequestFloorPlan(lot)"
                                (cbClick)="requestFloorPlan(lot)">
                            <mat-icon class="cb-grey">library_books</mat-icon>
                            &nbsp;Request Floorplan
                        </button>
                        <button *ngIf="projectReleaseStagePermissions.getLotPermissions().canSwitchReleaseStage() || true"
                                mat-menu-item
                                [disabled]="!canSwitchReleaseStage(lot)"
                                (cbClick)="switchReleaseStage(lot)">
                            <mat-icon class="cb-grey">library_books</mat-icon>
                            &nbsp;Switch Release Stage
                        </button>
                    </mat-menu>
                </td>
            </tr>
            <tr *ngIf="lots?.length < 1 && loaded">
                <td colspan="17">
                    <mat-icon class="cb-accent">info</mat-icon>
                    &nbsp;No Lots have been added to this Release Stage
                </td>
            </tr>
        </tbody>
    </cb-table>
</div>
