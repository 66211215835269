<div class="flex-col flex"
     *ngIf="buildActivity">
    <div class="flex-row flex">
        <div class="cb-margin flex-col flex">
            <mat-form-field>
                <input matInput
                       placeholder="Activity Code"
                       [pattern]="activityCodePattern"
                       [(ngModel)]="buildActivity.code"
                       required
                       name="code"/>
            </mat-form-field>
            <mat-form-field>
                <input matInput
                       placeholder="Activity Name"
                       [(ngModel)]="buildActivity.name"
                       required
                       name="name"/>
            </mat-form-field>
            <mat-form-field>
                <input matInput
                       placeholder="Value Retained % (% Value that must be Retained Against the Original Activity)"
                       [(ngModel)]="buildActivity.retain"
                       required
                       name="retained"
                       type="number"/>
            </mat-form-field>
        </div>
    </div>
    <div class="flex-row flex">
        <div class="cb-margin flex-col flex">
            <div class="flex-row flex">
                <cb-checkbox name="taskAnalysisFormRequired"
                             (valueChange)="taskAnalysisChanged()"
                             label="Task Analysis Form is required"
                             class="cb-margin-right-3x"
                             [(ngModel)]="taskAnalysisFormRequired">
                </cb-checkbox>
                <cb-select *ngIf="taskAnalysisFormRequired"
                           [required]="true"
                           label="Task Analysis Form"
                           name="taskAnalysisFormRequiredLevel"
                           [(ngModel)]="buildActivity.taskAnalysisFormRequiredLevel"
                           [options]="buildActivityRequiredLevels">
                </cb-select>
            </div>
            <cb-checkbox name="canPerformBeforeQsConsentTakeOffCheck"
                         label="Can be performed before QS"
                         [(ngModel)]="buildActivity.canPerformBeforeQsConsentTakeOffCheck">
            </cb-checkbox>
            <cb-checkbox name="canPerformPriorToBuildingConsent"
                         label="Can be performed prior to Building Consent"
                         [(ngModel)]="buildActivity.canPerformPriorToBuildingConsent">
            </cb-checkbox>
        </div>
    </div>
    <cb-select-items-autocomplete-scroller class="flex"
                                           heading="Rates"
                                           [displayFn]="displayFn"
                                           name="rates1"
                                           [(ngModel)]="buildActivity.labourRatesList"
                                           [selectedItem]="selectedProduct">
        <cb-product-autocomplete-scroller class="cb-margin-top"
                                          #autocompleteControl
                                          label="Rates"
                                          name="rates"
                                          [displayCategories]="true"
                                          [productType]="3"
                                          [(ngModel)]="selectedProductModel"
                                          (optionSelected)="productSelected($event)"
                                          [hideCompositeItems]="true"
                                          [ignoreDirty]="true">
        </cb-product-autocomplete-scroller>
    </cb-select-items-autocomplete-scroller>
    <div class="cb-margin-top-3x flex-row">
        <button mat-icon-button
                (click)="incrementVersion()"
                class="cb-margin">
            <mat-icon>
                arrow_upward
            </mat-icon>
        </button>
        <div class="flex-col">
            <mat-form-field class="cb-margin">
                <input matInput
                       placeholder="Activity Version"
                       [(ngModel)]="buildActivity.version"
                       required
                       numeric="true"
                       [readonly]="true"
                       name="activityVersion"/>
            </mat-form-field>
        </div>
    </div>
    <div class="cb-margin flex-col flex">
        <cb-checkbox name="canDetermineBuildProgrammeStart"
                     label="Can set the Build Programme Actual Start Date"
                     [(ngModel)]="buildActivity.canDetermineBuildProgrammeStart">
        </cb-checkbox>
        <cb-checkbox name="excludeFromPracticalCompletion"
                     label="Exclude from Practical Completion"
                     [(ngModel)]="buildActivity.excludeFromPracticalCompletion">
        </cb-checkbox>
        <cb-checkbox name="showOnClientsConstructionPlan"
                     label="Show on Client's Build Programme Report"
                     [(ngModel)]="buildActivity.showOnClientsConstructionPlan">
        </cb-checkbox>
        <cb-checkbox name="isActive"
                     label="Is Active"
                     [(ngModel)]="buildActivity.isActive">
        </cb-checkbox>
        <cb-checkbox name="showDraftActivityToSupplier"
                     label="Show Draft Activity To Supplier"
                     [(ngModel)]="buildActivity.showDraftActivityToSupplier">
        </cb-checkbox>
    </div>
</div>
