import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { IUserSearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { TagsLogicService } from '@app/logic/tags';
import { UsersLogicService } from '@app/logic/users';
import { WHOLE_OPTION_VALUE_PROP } from '@app/shared/utils/select.util';
import { IClassicUserDocumentDto, ITagDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { orderBy } from 'lodash';
import { Observable, Subject, Subscription, map, BehaviorSubject } from 'rxjs';
import { IOption } from '@app/shared/components/forms/select/select.component';

enum ActiveStatusEnum {
    Any = 0,
    ActiveOnly = 1,
    InactiveOnly = 2
}
@Component({
    selector: 'cb-user-search',
    templateUrl: './user-search.component.html',
    styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit, OnDestroy {
    public userRoleTagSelectedValue: ITagDto;
    public userRoleTagsOptions$: Observable<ITagDto[]>;
    public selectedIsActiveOnlyStatus: IOption;
    public WHOLE_OPTION_VALUE_PROP = WHOLE_OPTION_VALUE_PROP;
    public fetchInProgress = false;
    public noMoreResults = false;
    public readonly isActiveOnlyStatusOptions: IOption[] = [
        {
            id: ActiveStatusEnum.Any,
            label: 'Any'
        },
        {
            id: ActiveStatusEnum.ActiveOnly,
            label: 'Active Only'
        },
        {
            id: ActiveStatusEnum.InactiveOnly,
            label: 'Inactive Only'
        }
    ];
    public readonly searchFiltersChanged$ = new Subject();
    public readonly anyUserRoleTag = 999;
    public currentPage: number;
    public results: IClassicUserDocumentDto[] = [];
    public searchEnabled= new BehaviorSubject<boolean>(false);
    private readonly _subscription$ = new Subscription();

    constructor(
        private readonly logicService: UsersLogicService,
        private readonly tagsLogicService: TagsLogicService,
        private readonly userCacheService: UserCacheService,
        private readonly navigationService: NavigationService,
    ) {
    }

    public ngOnInit(): void {
        this._initSearchFiltersCache();
    }

    public ngOnDestroy(): void {
        this._subscription$.unsubscribe();
    }

    public get searchFiltersCache(): UserCacheItem<IUserSearch> {
        return this.userCacheService.userSearch;
    }

    public fetchResults(): Observable<IClassicUserDocumentDto[]> {
        if (!this.searchEnabled.value) {
            return;
        }

        return this.logicService.$getSearchList(this._queryParams);
    }

    public onIsActiveOnlyValueChanged(value: ActiveStatusEnum): void {
        switch (value) {
            case ActiveStatusEnum.InactiveOnly:
                this.searchFiltersCache.data.active = false;
                break;
            case ActiveStatusEnum.ActiveOnly:
                this.searchFiltersCache.data.active = true;
                break;
            default:
                this.searchFiltersCache.data.active = undefined;
                break;
        }
        this.searchFiltersChanged$.next(null);
    }

    public onUserRoleTagsValueChanged(value: ITagDto | 999): void {
        switch (value) {
            case this.anyUserRoleTag:
                this.searchFiltersCache.data.tag = [undefined];
                break;
            default:
                this.searchFiltersCache.data.tag = [value.key];
                break;
        }
        this.searchFiltersChanged$.next(null);
    }

    public generateUserSecurityReport(): void {
        this.logicService.generateUserSecurityReport().subOnce();
    }

    public generateUserDetailsReport(): void {
        this.logicService.generateUserDetailsReport().subOnce();
    }

    public createNewUser(): void {
        this.navigationService
            .navigate(
                ['/users'],
                undefined,
                {
                    queryParams: {
                        mappedItem: this.logicService.$createMappedItem({ isActive: true })
                    }
                } as NavigationExtras
            );
    }

    private get _queryParams(): IUserSearch {
        return this.searchEnabled.value ?
            {
                ...this.searchFiltersCache.copyData(),
                currentPage: this.currentPage
            } :
            undefined;
    }

    public _initSearchFiltersCache(): void {
        this.searchFiltersCache.init().then(() => {
            this.searchEnabled.next(true);
            this._onSearchFiltersChanged();
            this.userRoleTagsOptions$ = this.tagsLogicService
                .$getList()
                .pipe(
                    map(userRoleTags => {
                        const orderedUserRoleTags = orderBy(userRoleTags, tag => tag.name.toLowerCase());
                        orderedUserRoleTags.unshift({ id: this.anyUserRoleTag, name: 'Any' } as ITagDto);
                        return orderedUserRoleTags;
                    })
                );
            this.userRoleTagsOptions$.subOnce(userRoleTags => {
                this._initUserRoleTagSelectedValue(userRoleTags);
                this._initActiveOnlySelectedValue();
            });
            this._subscription$.add(
                this.searchFiltersCache.updated$.subscribe({
                    next: this._onSearchFiltersChanged
                })
            );
        });
    }

    private _initUserRoleTagSelectedValue(userRoleTags: ITagDto[]): void {
        this.userRoleTagSelectedValue = userRoleTags
            .find(option => {
                if (this.searchFiltersCache?.data?.tag) {
                    return option.key === this.searchFiltersCache?.data?.tag[0];
                }
            }) ||
            { id: this.anyUserRoleTag, name: 'Any' } as ITagDto;
    }

    private _initActiveOnlySelectedValue(): void {
        switch (this.searchFiltersCache?.data?.active) {
            case true:
                this.selectedIsActiveOnlyStatus = this.isActiveOnlyStatusOptions[ActiveStatusEnum.ActiveOnly];
                break;
            case false:
                this.selectedIsActiveOnlyStatus = this.isActiveOnlyStatusOptions[ActiveStatusEnum.InactiveOnly];
                break;
            default:
                this.selectedIsActiveOnlyStatus = this.isActiveOnlyStatusOptions[ActiveStatusEnum.Any];
                break;
        }
    }

    private readonly _onSearchFiltersChanged = (): void => {
        if (!this.searchEnabled.value) {
            return;
        }
        this.currentPage = 1;
        this.searchFiltersChanged$.next(null);
    };
}
