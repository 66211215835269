import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { NavigationExtras } from '@angular/router';
import { Observable, of, Subject, Subscriber, Subscription, map, BehaviorSubject } from 'rxjs';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { orderBy } from 'lodash';
import { DesignTeamPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IDesignTeamWorkingDrawingsSearch } from '@app/core/services/user-cache/user-cache-areas';
import { DesignTeamWorkLoadDialogComponent } from '../work-load-dialog/design-team-work-load-dialog';
import { WORKING_DRAWING_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { IWorkingDrawingMappedItem, WorkingDrawingsLogicService } from '@app/logic/working-drawings';
import { WorkingDrawingDialogComponent } from '@app/views/lot/design/working-drawings/working-drawing-dialog/working-drawing-dialog.component';
import { LotsLogicService } from '@app/logic/lots';

@Component({
    selector: 'cb-design-team-working-drawings',
    templateUrl: './design-team-working-drawings.component.html',
    styleUrls: ['./design-team-working-drawings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesignTeamWorkingDrawingsComponent implements OnDestroy, OnInit {

    public resultOrders$ = of([
        { id: 'jobNumber', label: 'Job Number' },
        { id: 'assignedTo', label: 'Assigned To' },
        { id: 'createdDate', label: 'Created Date' },
        { id: 'dueDate', label: 'Due Date' },
        { id: 'status', label: 'Status' }
    ]);

    @Input() public resultDirections$: Observable<{
        id: string;
        label: string;
    }[]>;

    private readonly _subscriptions = new Subscription();
    public searchEnabled = false;
    public readonly searchFiltersChanged$ = new Subject();
    public currentPage: number;
    public teamCode: string;
    public loaded = false;
    public cacheLoaded$ = new Subject();
    public results: IWorkingDrawingMappedItem[] = [];
    public readonly allUsers = '999';
    public readonly allStatuses = 999;
    public readonly unassigned = undefined;
    public infiniteScrollEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public workingDrawingStatuses$ = of(WORKING_DRAWING_STATUS_ENUM.toLookup())
        .pipe(
            map(workingDrawingStatuses => {
                const orderedWorkingDrawingStatuses = orderBy(workingDrawingStatuses, tag => tag.label.toLowerCase());
                return orderedWorkingDrawingStatuses;
            })
        );

    public hasPermissionsToEdit$ = of(this.designTeamPermissions.canEditWorkingDrawing());

    public canEdit$(workingDrawing: IWorkingDrawingMappedItem): Observable<boolean> {
        return new Observable((subscriber: Subscriber<boolean>) => {
            subscriber.next(workingDrawing?.canManage);
        });
    }

    public teamUsersOptions$ = this.teamsLogicService
        .loadAllMembersByKey('DESIGNTEAM').pipe(
            map(users => {
                users = orderBy(users, 'firstName');
                users.unshift({ id: this.allUsers, label: 'All' } as any);
                return users;
            })
        );

    public get searchFiltersCache(): UserCacheItem<IDesignTeamWorkingDrawingsSearch> {
        return this.userCacheService.designTeamWorkingDrawingsSearch;
    }

    constructor(
        public readonly designTeamPermissions: DesignTeamPermissions,
        private readonly teamsLogicService: TeamsLogicService,
        private readonly logicService: WorkingDrawingsLogicService,
        private readonly lotLogicService: LotsLogicService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly userCacheService: UserCacheService,
        private readonly dialogService: CbDialogService,
        private readonly navigationService: NavigationService
    ) {

    }

    public ngOnInit(): void {
        this._initSearchFiltersCache();
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public fetchResults(): Observable<IWorkingDrawingMappedItem[]> {
        if (!this.searchEnabled) {
            return;
        }
        this.loaded = true;
        return this.logicService.$getMappedSearchList(this._queryParams);
    }

    public hasPermissionsToEditWorkingDrawing = (): boolean => {
        if (this.designTeamPermissions) {
            return this.designTeamPermissions.canEditWorkingDrawing();
        }

        return false;
    };

    public getStreetAddress(workingDrawing: IWorkingDrawingMappedItem): string {
        return `${workingDrawing.$addressStreet}, ${workingDrawing.$addressCity}`;
    }

    public openDesignTeamWorkLoadDialog(): void {
        this.dialogService
            .open(DesignTeamWorkLoadDialogComponent)
            .afterClosed()
            .subOnce();
    }

    public readonly editWorkingDrawing = async (workingDrawing: IWorkingDrawingMappedItem): Promise<Subscription> => {
        const mappedItem = await this.logicService.$getMappedItem(workingDrawing.id).toPromise();
        const lotMappedItem = await this.lotLogicService.$getMappedItem(workingDrawing.lotId).toPromise();
        return this.dialogService
            .open(
                WorkingDrawingDialogComponent,
                {
                    data: {
                        mappedItem,
                        lotTypeEnumId: lotMappedItem.lotType,
                        lotMappedItem
                    },
                }
            ).afterClosed()
            .subOnce({
                next: (result: IWorkingDrawingMappedItem) => {
                    if (result && result.id) {
                        const foundIndex = this.results.findIndex(x => x.id === result.id);
                        Object.assign(this.results[foundIndex], result);
                        this.cdRef.detectChanges();
                    }
                }
            });
    };

    public viewWorkingDrawing(workingDrawing: IWorkingDrawingMappedItem): void {
        this.navigationService.navigate(
            [`/lots/${workingDrawing.lotId}/design/working-drawings`],
            undefined,
            {
                queryParams: {
                    workingDrawingId: workingDrawing.id.toString(),
                    tabToOpen: 'files'
                }
            } as NavigationExtras);
    }

    private get _queryParams(): IDesignTeamWorkingDrawingsSearch {
        return this.searchEnabled ?
            {
                ...this.searchFiltersCache.copyData(),
                userId: this.searchFiltersCache.data.userId === this.allUsers ?
                    undefined :
                    this.searchFiltersCache.data.userId ?
                        this.searchFiltersCache.data.userId :
                        undefined,
                statusId: (this
                    .searchFiltersCache.data.statusId as any) === this.allStatuses ?
                    undefined :
                    this.searchFiltersCache.data.statusId,
                currentPage: this.currentPage
            } :
            undefined;
    }

    private readonly _onSearchFiltersChanged = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.currentPage = 1;
        this.searchFiltersChanged$.next(null);
    };

    public _initSearchFiltersCache(): void {
        this.searchFiltersCache.init().then(() => {
            this.cacheLoaded$.next(true);
            this.searchEnabled = true;
            this._onSearchFiltersChanged();
            this._subscriptions.add(
                this.searchFiltersCache.updated$.subscribe({
                    next: this._onSearchFiltersChanged
                })
            );

            this.searchFiltersCache.silentData.canSeeAll = this.designTeamPermissions.canSeeAll();
            if (!this.searchFiltersCache.silentData.canSeeAll) {
                this.searchFiltersCache
                    .silentData
                    .userId = this.searchFiltersCache.currentUserId;
            }
        });
    }
}
