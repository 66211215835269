import * as moment from 'moment';
import { Injectable, Injector } from '@angular/core';
import { WeekDay } from '@angular/common';
import { Observable } from 'rxjs';
import { DATE_CALC_TYPE_ENUM, DateCalcTypeEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { BaseLogicService } from '../base/base-logic.service';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { INonWorkingDayDto } from './interfaces/i.non-working-day.dto';
import { INonWorkingDayLogicService } from './interfaces/i.non-working-day.logic.service';
import { INonWorkingDayMappedItem } from './interfaces/i.non-working-day.mapped';
import { NonWorkingDayMappedItem } from './non-working-day.mapped';
import { INonWorkingDayLocationDto } from './interfaces/i.non-working-day-location.dto';

@Injectable()
export class NonWorkingDayLogicService
    extends BaseLogicService<INonWorkingDayDto, INonWorkingDayMappedItem>
    implements INonWorkingDayLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('nonworkingdays', NonWorkingDayMappedItem);
    }

    public $getList(year: number): Observable<INonWorkingDayDto[]> {
        return this.$http
            .get<INonWorkingDayDto[]>(`${this.$baseUri}?year=${year}`);
    }

    public getNonWorkingDaysForLot(lotId: number): Observable<INonWorkingDayDto[]> {
        return this.$http
            .get<INonWorkingDayDto[]>(`${this.$baseUri}/lot/${lotId}`);
    }

    public getNonWorkingDaysForLotLocation(lotId: number): Observable<INonWorkingDayDto[]> {
        return this.$http
            .get<INonWorkingDayDto[]>(`${this.$baseUri}/lot/${lotId}/location`);
    }

    public getFutureNonWorkingDays(): Observable<INonWorkingDayDto[]> {
        return this.$http
            .get<INonWorkingDayDto[]>(`${this.$baseUri}/future`);
    }

    public nonWorkingDaysFilter = (nonWorkingDays: INonWorkingDayDto[] | any, date: Date): boolean => {
        return !this.dateInNonWorkingDaysFilter(nonWorkingDays, date);
    };

    public weekDaysFilter = (date: Date): boolean => {
        const day = date.getDay();
        return day === 1 || day === 2 || day === 3 || day === 4 || day === 5;
    };

    public getDueDate(startDate: string, numberHours: number): Observable<string> {
        return this.$http.post('/holidays/dueby', { startDate, numberHours });
    }

    /** returns a new date that is (workingDaysToAdd) workings from the (startDate) */
    public addWorkingDays(nonWorkingDays: INonWorkingDayDto[], startDate: Date | string, workingDaysToAdd: number): string {
        const newDate = moment(startDate);
        const validNonWorkingDays = nonWorkingDays.filter(x => moment(x.date).isSameOrAfter(newDate, 'day'));

        for (let currentWorkingDay = 1; currentWorkingDay < workingDaysToAdd; currentWorkingDay++) {
            this.incrementDayForNonWorkingDays(validNonWorkingDays, newDate);
        }

        return newDate.toJSON();
    }

    /** increment by 1 day or until the next working day (next working day cannot be in nonWorkingDays, Saturday or Sunday) */
    private incrementDayForNonWorkingDays(nonWorkingDays: INonWorkingDayDto[], date: moment.Moment): void {
        date.businessAdd(1);
        if (this.dateInNonWorkingDaysFilter(nonWorkingDays, date.toDate())) {
            this.incrementDayForNonWorkingDays(nonWorkingDays, date);
        }
    }

    public getNumberOfWorkingDays(start: Date | string, end: Date | string, nonWorkingDays: INonWorkingDayDto[], dateCalcType?: DateCalcTypeEnumId): number {
        let direction = 1;

        let startD = moment();
        let endD = moment();

        if (start <= end) {
            startD = moment(start);
            endD = moment(end);
        } else {
            startD = moment(end);
            endD = moment(start);
            direction = -1;
        }

        const diff = direction ? endD.diff(startD, 'days') : startD.diff(endD, 'days');
        let calcBusinessDays = 1 + (diff * 5 - (startD.weekday() - endD.weekday()) * 2) / 7;

        if (endD.weekday() === WeekDay.Saturday) {
            calcBusinessDays--;
        }
        if (startD.weekday() === WeekDay.Sunday) {
            calcBusinessDays--;
        }

        nonWorkingDays.forEach(nwd => {
            const d = moment(nwd.date);
            if (d >= startD && d <= endD && d.weekday() !== WeekDay.Saturday && d.weekday() !== WeekDay.Sunday) {
                calcBusinessDays--;
            }
        });

        if (dateCalcType === DATE_CALC_TYPE_ENUM.FinishToStart) {
            calcBusinessDays -= 1;
        }

        if (direction < 0) {
            return Math.floor(calcBusinessDays) * direction;
        }
        return Math.round(calcBusinessDays) * direction;
    }

    public getNonWorkingDayLocations(id: number, showActiveOnly: boolean): Observable<INonWorkingDayLocationDto[]> {
        return this.$http.get(`locations/nonworkingdayflattree/${id}?activeOnly=${showActiveOnly}`);
    }

    public updateRegions(id: number, regionIds: number[]): Observable<boolean> {
        return this.$http.post(`${this.$baseUri}/regions/update/${id}`, regionIds);
    }

    private readonly dateInNonWorkingDaysFilter = (nonWorkingDays: INonWorkingDayDto[] | any, date: Date): boolean => {
        return nonWorkingDays.some((nonWorkingDay: INonWorkingDayDto) => moment(nonWorkingDay.date).isSame(moment(date), 'day'));
    };
}
