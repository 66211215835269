<div class="cb-margin flex-col">
    <app-house-and-land-search (emitFilterEvent)="getFilterredLots($event)"
                               [lots]="lots"
                               (emitResultSizeEvent)="setResultSize($event)"></app-house-and-land-search>
    <div class="flex-wrap">
        <cb-house-and-land-card class="flex-col"
                                *ngFor="let item of lots"
                                [ngStyle]="{'flex-basis': defaultCardSize}"
                                [lot]="item">
        </cb-house-and-land-card>
        <cb-loading-spinner *ngIf="!lotsLoaded"
                            message="Loading House and Land Lots"></cb-loading-spinner>
        <cb-info-message class="cb-margin-top"
                         message="There are no House and Land Lots found"
                         *ngIf="lotsLoaded && lots.length < 1">
        </cb-info-message>
    </div>
    <cb-info-message class="cb-margin-top"
                     message="There are no more search results"
                     *ngIf="lotsLoaded &&  lots.length > 0 && noMoreResults ">
    </cb-info-message>
</div>
