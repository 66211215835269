import { Component, Input } from '@angular/core';
import { BaseFormComponentDirective, getBaseFormComponentDirectiveProvider } from '../base-form-component';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { SelectComparator, WHOLE_OPTION_VALUE_PROP } from '@app/shared/utils/select.util';

export interface IOption {
    id: number | string;
    name?: string;
    label?: string;
}

@Component({
    selector: 'cb-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(SelectComponent),
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class SelectComponent extends BaseFormComponentDirective {
    @Input() public options: any[]; // Must be any type because valueProp could be any property.
    @Input() public labelProp: string;
    @Input() public valueProp: string;
    @Input() public noOptionsMessage = '(No options available)';
    @Input() public wholeOptionComparisonProp = 'id';
    public selectComparator: SelectComparator;

    public WHOLE_OPTION_VALUE_PROP = WHOLE_OPTION_VALUE_PROP;

    /** This is for when there is an option such as 'all' or
     * 'none' which can have an id of 'null', which will cause
     * the model value to be null, but still display the option as selected.
     * Note: set control to non-required even if it is required*/
    public valueSetter(v: any): { value: any; modelValue: any } {
        return {
            value: v,
            modelValue: v === 'null' ? null : v
        };
    }

    public getDisplay(opt: any): string {
        const label1 = this.labelProp ? opt[this.labelProp] : (opt.label || opt.name || 'Unknown');
        return label1;
    }

    public ngOnInit(): void {
        this.selectComparator = new SelectComparator(this.wholeOptionComparisonProp, this.valueProp);
    }

    public getLabel(): string {
        const label = this.label ?? '';
        if (!this.options?.length) {
            return `${label} ${this.noOptionsMessage}`;
        }
        return label;
    }

    public getDisplayValueFromObject(value: any) {
        return value?.label ?? value?.name ?? value?.fieldName;
    }

    public getDisplayValueFromPrimitive(value: string | number) {
        const opt = this.options?.find(x => x.id === value);
        if (opt) {
            const val = opt[this.labelProp] || opt?.label || opt?.name;
            return val;
        }
    }

    public get readonlyDisplayValue() {
        if (Number.isInteger(this.value) || typeof this.value === 'string') {
            return this.getDisplayValueFromPrimitive(this.value);
        } else {
            return this.getDisplayValueFromObject(this.value);
        }
    }
}
