<cb-dialog dialogHeading="Document Review">
    <form class="flex-col flex"
          #documentReviewForm="ngForm">
        <h4 class="mat-subheading-2">Review {{data.documentType.label}} : {{data.document.name}}</h4>
        <div class="cb-margin-bottom-2x">
            <label>Status</label>
            <mat-radio-group name="closetask-outcome-followup"
                             [(ngModel)]="approvalStatus.documentReviewStatus">
                <mat-radio-button [value]="DOCUMENT_REVIEW_STATUS_ENUM.Approved">Approved</mat-radio-button>
                <mat-radio-button [value]="DOCUMENT_REVIEW_STATUS_ENUM.Rejected">Rejected</mat-radio-button>
            </mat-radio-group>
        </div>
        <cb-text-area *ngIf="isRejecting()"
                      label="Rejection Comments"
                      name="rejectionComments"
                      [(ngModel)]="approvalStatus.reviewComments"
                      [maxlength]="500"
                      [required]="true"></cb-text-area>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="onSubmit()"
                [disabled]="!documentReviewForm.dirty || !documentReviewForm.valid">Save</button>
        <button mat-raised-button
                (click)="cancel()">Close</button>
    </footer>
</cb-dialog>
