import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IntegrationLogsLogicService } from '@app/logic/integration-logs';
import { SearchIndexesLogicService } from '@app/logic/search-indexes/search-indexes.logic.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { MESSAGE_NAMES } from '@app/views/settings/pages/integration-logs/abm-logs/message-names.enum';
import { IFinanceAbmLogDocumentDto, SEARCH_INDEX_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import {isNullOrWhiteSpace, ITableColumn} from 'cb-hub-lib';
import { orderBy } from 'lodash';
import { BehaviorSubject, debounceTime, defaultIfEmpty, filter, Observable, startWith, Subject, Subscription } from 'rxjs';

@Component({
    selector: 'cb-abm-logs',
    templateUrl: './abm-logs.component.html',
    styleUrls: ['./abm-logs.component.scss']
})

export class AbmLogsComponent implements OnInit, OnDestroy {

    public orderNumberText = new UntypedFormControl();
    public searchText = new UntypedFormControl();
    public dateFrom: string;
    public dateTo: string;
    public messageName: string;

    private defaultDialogTimeoutMs = 1000;
    private typeDelayBeforeSearchingMs = 400;

    public messageNames = [
        { id: '0', label: 'Any' },
    ];

    public logs: IFinanceAbmLogDocumentDto[] = [];
    public currentPage: number;
    public readonly queryUpdated = new Subject();

    public readonly subscriptions$ = new Subscription();
    public infiniteScrollEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public readonly isNullOrWhiteSpace = isNullOrWhiteSpace;

    public readonly columns: ITableColumn[] = [
        { label: 'Datetime', width: 150 },
        { label: 'Message Name' },
        { label: 'Order Number' },
        { label: 'Event Message' }
    ];

    constructor(
        private readonly integrationLogsLogic: IntegrationLogsLogicService,
        private readonly cbDialog: CbDialogService,
        private readonly searchIndexesLogic: SearchIndexesLogicService,
    ) {
        this.messageNames.push(
            ...orderBy(Object.keys(MESSAGE_NAMES).map(x => ({ id: x, label: x })), 'label')
        );
    }

    public ngOnInit(): void {
        this.createSearchTextSub();
        this.createOrderNumberTextSub();
    }

    public ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    public clearFilters(): void {
        this.dateFrom = null;
        this.dateTo = null;
        this.messageName = null;
        this.searchText.setValue('');
        this.orderNumberText.setValue('');
    }

    public clearDateTo(): void {
        this.dateTo = null;
        this.cleanSearch();
    }

    public clearDateFrom(): void {
        this.dateFrom = null;
        this.cleanSearch();
    }

    public clearMessageName(): void {
        this.messageName = null;
        this.cleanSearch();
    }

    public cleanSearch(): void {
        this.infiniteScrollEnabled.next(true);
        this.queryUpdated.next(null);
    }

    public search(): Observable<ISearchResult<IFinanceAbmLogDocumentDto>> {
        const params = {
            currentpage: this.currentPage,
            query: this.searchText.value ?? '',
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            messageName: isNaN(+this.messageName) ? this.messageName : null,
            orderNumber: this.orderNumberText.value,
        };
        return this.integrationLogsLogic.searchAbmMessages(params);
    }

    public reindexLogs(): void {
        const ref = this.cbDialog.block('Fetching Latest Messages...');
        this.searchIndexesLogic.reindex(SEARCH_INDEX_ENUM.FinanceAbmLogs).subOnce(() => {
            setTimeout(() => {
                ref.close();
                this.cleanSearch();
            }, this.defaultDialogTimeoutMs);
        });
    }

    private createSearchTextSub(): void {
        this.subscriptions$.add(
            this.searchText.valueChanges
                .pipe(
                    defaultIfEmpty(''),
                    startWith(''),
                    debounceTime(this.typeDelayBeforeSearchingMs),
                    filter(q => typeof q === 'string')
                )
                .subscribe(() => {
                    this.cleanSearch();
                })
        );
    }

    private createOrderNumberTextSub(): void {
        this.subscriptions$.add(
            this.orderNumberText.valueChanges
                .pipe(
                    defaultIfEmpty(''),
                    startWith(''),
                    debounceTime(this.typeDelayBeforeSearchingMs),
                    filter(q => typeof q === 'string')
                )
                .subscribe(() => {
                    this.cleanSearch();
                })
        );
    }

    public exportToCsv(): void {
        const params = {
            query: this.searchText.value ?? '',
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            messageName: isNaN(+this.messageName) ? this.messageName : null,
            orderNumber: this.orderNumberText.value,
        };

        this.integrationLogsLogic.exportToCsv(params).subOnce();
    }
}
