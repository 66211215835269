import { Injectable, Injector } from '@angular/core';
import { BaseLogicService } from '../base/base-logic.service';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';
import { ITagMappedItem } from './interfaces/i.tag.mapped';
import { ITagsLogicService } from './interfaces/i.tags.logic.service';
import { TagMappedItem } from './tag.mapped';
import { IUserMappedItem } from '../users/interfaces/i.user.mapped';
import { ITagDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Injectable()
export class TagsLogicService
    extends BaseLogicService<ITagDto, ITagMappedItem>
    implements ITagsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('auth/tags', TagMappedItem);
    }

    public $saveItem(entity: ITagDto): Observable<ITagDto> {
        return this.$http.post<ITagDto>(this.$baseUri, entity);
    }

    public generateReport(): Observable<any> {
        return this.$http.download(`${this.$baseUri}/report`, 'Downloading Tags Report...');
    }

    /** Load Active Tag Members by Key */
    public loadActiveMembersByTag(key: string): Observable<IUserMappedItem[]> {
        return this.$http.get(`${this.$baseUri}/${key}/active`);
    }
}
