import { Injectable, Injector } from '@angular/core';
import { ILotDesignQuestionsSearch, ITeamQuestionSearch } from '@app/core/services/user-cache/user-cache-areas';
import { IGenericQuestionDocumentDto, IGenericQuestionDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { map, Observable } from 'rxjs';
import { IGenericQuestionMappedItem } from '.';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicServiceWithSearch } from '../base/base-logic-service-with-search.component';
import { GenericQuestionMappedItem } from './generic-question.mapped';
import { IGenericQuestionsLogicService } from './interfaces/i.generic-questions-logic.service';

@Injectable()
export class GenericQuestionsLogicService
    extends BaseLogicServiceWithSearch<
    IGenericQuestionDto,
    IGenericQuestionMappedItem,
    ITeamQuestionSearch | ILotDesignQuestionsSearch,
    IGenericQuestionDocumentDto[]>
    implements IGenericQuestionsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('genericquestions', GenericQuestionMappedItem);
    }

    public $saveItem(entity: any): Observable<IGenericQuestionDto> {
        let uri = this.$baseUri;
        if (entity.id != null) {
            uri += `/${entity.id}`;
        }
        return this.$http.post<IGenericQuestionDto>(uri, entity);
    }

    public getByEntity(entityId: number, systemArea: number): Observable<IGenericQuestionMappedItem[]> {
        return this.$http
            .get<IGenericQuestionDto[]>(`${this.$baseUri}/${systemArea}/${entityId}`)
            .pipe(
                map(results => results.map(item => this.$createMappedItem(item)))
            );
    }
}
