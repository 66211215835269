import { IDocumentTypeDto } from '@app/logic/document-types';
import { ILocationDto } from '@app/logic/location';
import {
    BusinessAccountStatusEnumId,
    BusinessAccountTypeEnumId,
    IAddressDto,
    ISupplyTypeDto,
    ITradeTypeDto,
} from '@classictechsolutions/hubapi-transpiled-enums';
import { BusinessAccountTradeTypeStatusEnumId } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/enums/BusinessAccountTradeTypeStatus';
import { BusinessAccountSupplyTypeStatusEnumId } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/enums/BusinessAccountSupplyTypeStatus';

export interface IDocTypeTradeTypeDto {
    id: number;
    tradeType: ITradeTypeDto;
    documentTypeIds: Array<number>;
    documentTypes: Array<IDocumentTypeDto>;
}

export interface IDocTypeSupplyTypeDto {
    id: number;
    supplyType: ISupplyTypeDto;
    documentTypeIds: Array<number>;
    documentTypes: Array<IDocumentTypeDto>;
}

export interface IDocTypeBusinessAccountTypeDto {
    id: BusinessAccountTypeEnumId;
    documentTypeIds: Array<number>;
    documentTypes: Array<IDocumentTypeDto>;
}

export interface IBusinessAccountContactDto {
    id: number;
    receivesDailyEmails: boolean;
    regions: number[];
    districts: number[];
    suburbs: number[];
    areas: any[];
    tradeTypes: number[];
    contactId: string;
    position: string;
    contactTypes: number[];
    name: string;
    email: string;
    phoneMobile: string;
    contactComplianceRegistrations: string;
    isPortalAccessEnabled: boolean;
}

export interface IBusinessAccountLocationDto {
    id?: number;
    name: string;
    contactType: string;
    contactTypeId: number;
    contactTypeIcon: string;
    email: string;
    position: string;
    phoneMobile: string;
    region: number;
    regionLabel: string;
    district: number;
    districtLabel: string;
    area: number;
    areaLabel: string;
    locationLabel?: string;
    tradeTypes: string[];
    location?: ILocationDto;
    emailAddress?: string;
    tradeTypeId?: number;
}

export interface IBusinessAccountHoldDto {
    comments: string;
    holdAction: number;
    holdDate: string;
    holdPlaceBy: string;
    holdType: number;
    id: number;
}

export interface IBusinessAccountBaseDto {
    businessName: string;
    tradingName: string;
    isSupplier: boolean;
    isInstaller: boolean;
    gstNumber: string;
    physicalAddress: IAddressDto;
    postalAddress: IAddressDto;
    status: BusinessAccountStatusEnumId;
    email: string;
    phoneNumber: string;
}

export interface IBusinessAccountEditBaseDto extends IBusinessAccountBaseDto {
    isRatesOnly: boolean;
    tradeTypes: number[];
    supplyTypes: number[];
    requestedById: string;
    locations: number[];
    reason: string;
    abmCode: string;
    id?: number;
}

export interface IBusinessAccountTradeTypeDto {
    id?: number;
    parentId?: number;
    parentLabel?: string;
    label?: string;
    children?: IChildTradeTypeWithStatus[];
    isOpen?: boolean;
    status?: BusinessAccountTradeTypeStatusEnumId;
}

interface IChildTradeTypeWithStatus extends ITradeTypeDto {
    status?: BusinessAccountTradeTypeStatusEnumId;
}

export interface IBusinessAccountSupplyTypeDto {
    id?: number;
    parentId?: number;
    parentLabel?: string;
    label?: string;
    children?: IChildSupplyTypeWithStatus[];
    isOpen?: boolean;
}

interface IChildSupplyTypeWithStatus extends ISupplyTypeDto {
    status?: BusinessAccountSupplyTypeStatusEnumId;
}

export enum ParentDocsMaintainance {
    BothParentAndChildAccounts = 1,
    ParentAccountOnly = 2,
    ChildAccountsOnly = 3
}
