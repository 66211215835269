import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IPricingComplexityMappedItem } from '@app/logic/pricing-complexity/interfaces/i.pricing-complexity.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IPricingComplexityLogicService } from '@app/logic/pricing-complexity/interfaces/i.pricing-complexity.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { PricingComplexityMappedItem } from '@app/logic/pricing-complexity/pricing-complexity.mapped';
import { IPricingComplexityDto } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    mappedItem: PricingComplexityMappedItem;
}

@Component({
    selector: 'cb-pricing-complexity-dialog',
    templateUrl: './pricing-complexity-dialog.component.html',
    styleUrls: ['./pricing-complexity-dialog.component.scss']
})
export class PricingComplexityDialogComponent extends BaseDialogFormViewDirective<IPricingComplexityDto, IPricingComplexityMappedItem, IPricingComplexityLogicService> {
    public mappedItem: PricingComplexityMappedItem;
    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<PricingComplexityDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
    }
}
