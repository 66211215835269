<cb-dialog [dialogHeading]="config.value.title">
    <form class="flex-col flex"
          #form="ngForm">
        <h4 class="mat-title">{{config.value.subTitle}}</h4>
        <div class="cb-margin-bottom flex-row">
            <button mat-raised-button
                    class="cb-margin-right"
                    (click)="selectAll()">Select All</button>
            <button mat-raised-button
                    (click)="deSelectAll()">Deselect All</button>
        </div>
        <div class="standardTable scroll-container">
            <table>
                <thead>
                    <tr>
                        <td></td>
                        <td>Stage</td>
                        <td>Activity</td>
                        <td>Supplier</td>
                        <td>Start Date</td>
                        <td>End Date</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let line of activities; let index = index;">
                        <td class="actions">
                            <cb-checkbox [slim]="true"
                                         [name]="checkboxName(index)"
                                         [(ngModel)]="selectedActivities[line.id]"
                                         (change)="canSave.recompute()"></cb-checkbox>
                        </td>
                        <td>{{getStageLabel(line.buildStageId)}}</td>
                        <td>{{line.activity.name}}</td>
                        <td>{{line?.businessAccount?.tradingName}}</td>
                        <td>{{(line.actualStartDate || line.estimatedStartDate) | date}}</td>
                        <td>{{(line.actualEndDate || line.estimatedEndDate) | date}}</td>
                        <td class="check-mark-col">
                            <mat-icon title="SSR Completed"
                                      color="primary"
                                      *ngIf="hasSaved[line.id]">check_circle
                            </mat-icon>
                        </td>
                    </tr>
                    <tr *ngIf="activities.length < 1">
                        <td colspan="6">
                            <span class="mat-body-1">
                                <mat-icon color="accent">info_circle</mat-icon>
                                {{config.value.noActivitiesMessage}}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                *ngIf="config.value.canComplete"
                (click)="completeSsrs()"
                [disabled]="!canSave.value">Complete Activities</button>
        <button mat-raised-button
                color="primary"
                *ngIf="config.value.canConfirm"
                (click)="confirmSsrs()"
                [disabled]="!canSave.value">Confirm Activities</button>
        <button mat-raised-button
                color="primary"
                *ngIf="config.value.canCreate"
                (click)="generateSsrs()"
                [disabled]="!canSave.value">Create SSRs</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
