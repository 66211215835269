<mat-card class="cb-margin flex-col flex">
    <form class="flex-row flex">
        <cb-input label="Search"
                  name="query"
                  class="cb-margin-right-2x"
                  [(ngModel)]="filters.query"
                  (change)="refreshData()"></cb-input>
        <cb-checkbox label="Active Only"
                     name="isActive"
                     [(ngModel)]="filters.isActive"
                     (change)="refreshData()"></cb-checkbox>
    </form>
    <cb-table class="flex"
              *cbWaitFor="isInitialised"
              maxHeightOffset="255"
              [loaded]="true"
              [infiniteScrollEnabled]="infiniteScrollEnabled"
              [fetch]="search()"
              [queryUpdated]="refreshData$"
              [(results)]="catalogues"
              [(currentPage)]="filters.currentPage">
        <thead cbTableHead>
            <tr>
                <td width="24"></td>
                <td width="24"></td>
                <td>ID</td>
                <td>Sku</td>
                <td>Name</td>
                <td>UOM</td>
                <td>Main Category</td>
                <td>Active</td>
                <td></td>
            </tr>
        </thead>
        <tbody cbTableBody>
            <tr *ngFor="let catalogue of catalogues">
                <td>
                    <!-- hasImage does not exist on ISupplierProductDto -->
<!--                    <mat-icon *ngIf="catalogue.hasImage"-->
<!--                              class="cb-grey">photo</mat-icon>-->
                </td>
                <td>
                    <!-- inSpec does not exist on ISupplierProductDto -->
<!--                    <mat-icon *ngIf="catalogue.inSpec"-->
<!--                              class="cb-grey">style</mat-icon>-->
                </td>
                <td>{{catalogue.offeringCode}}</td>
                <td>{{catalogue.sku }}</td>
                <td>{{catalogue.productName}}</td>
                <td>{{catalogue.uom}}</td>
                <td>{{catalogue.categoryPath[0].label}}</td>
                <td>
                    <mat-icon class="cb-grey">
                        {{catalogue.isActive ? 'check_box' : 'check_box_outline_blank'}}
                    </mat-icon>
                </td>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <button mat-icon-button
                            (click)="viewCatalogueClicked(catalogue)">
                        <mat-icon>pageview</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </cb-table>
</mat-card>
