<mat-card class="flex-col gt-lg-row wrap flex">
    <div class="preload-hide cb-margin-bottom"
         #generateBuildProgrammeContainer>
        <div class="start-center flex-wrap"
             *ngIf="canGenerateBuildProgrammeButtonEnabled$ | async">
            <button mat-raised-button
                    class="cb-margin-right"
                    (click)="generateBuildProgramme()">Generate Build Programme</button>
        </div>
    </div>
    <div class="start-center flex-wrap"
         *ngIf="lotDto?.hasBuildProgrammeGenerated === true || lotDto?.hasBuildProgrammeTemplateApplied === true">
        <div class="start-center flex-wrap">
            <button mat-raised-button
                    ngClass.lt-xl="cb-margin-bottom"
                    class="cb-margin-right"
                    *ngIf="lotDto?.hasBuildProgrammeGenerated === true"
                    (click)="createManualOrder()">Create Manual Order</button>
            <button mat-raised-button
                    class="cb-margin-right"
                    (click)="generateMultiple()">
                Generate All PO<small>s</small> &amp; SSR<small>s</small>
            </button>
            <button mat-raised-button
                    ngClass.lt-xl="cb-margin-bottom"
                    class="cb-margin-right"
                    (click)="setMultipleSsrConfirmed()">Confirm Multiple</button>
            <button mat-raised-button
                    ngClass.lt-xl="cb-margin-bottom"
                    class="cb-margin-right"
                    (click)="setMultipleSsrCompleted()">Complete Multiple</button>
        </div>
        <span class="lt-xl-hide flex-0"></span>
        <div class="start-center flex-wrap">
            <mat-slide-toggle *ngIf="lotBuildProgrammeEvents.resolved$$ && !lotBuildProgrammeEvents.hasSummaries"
                              class="cb-margin-top cb-margin-right-2x"
                              [(ngModel)]="lotBuildProgrammeActionBar.reorderingEnabled"
                              [ngModelOptions]="{ standalone: true}">Enable Reordering</mat-slide-toggle>
            <mat-slide-toggle class="cb-margin-top cb-margin-right-2x"
                              [(ngModel)]="lotBuildProgrammeActionBar.viewManualOrders"
                              [ngModelOptions]="{ standalone: true}">View Manual Orders</mat-slide-toggle>
            <mat-slide-toggle class="cb-margin-top cb-margin-right-2x"
                              [(ngModel)]="userCache.lotBuildProgrammeView.data.viewAllStages"
                              (change)="viewAllStages()"
                              [ngModelOptions]="{ standalone: true}">View All Stages</mat-slide-toggle>
            <span class="lt-xl-100"></span>
            <cb-lot-build-reports-menu *ngIf="isJobBuildReportEnabled()"
                                       [lot]="lotDto"></cb-lot-build-reports-menu>
            <button mat-raised-button
                    ngClass.lt-xl="cb-margin-top"
                    (click)="expandCollapseAll()">
                <span class="start-center flex-row"
                      *ngIf="!lotBuildProgrammeActionBar.allExpanded">
                    <span class="flex">Expand All&nbsp;</span>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </span>
                <span class="start-center flex-row"
                      *ngIf="lotBuildProgrammeActionBar.allExpanded">
                    <span class="flex">Collapse All&nbsp;</span>
                    <mat-icon>keyboard_arrow_up</mat-icon>
                </span>
            </button>
            <button class="cb-margin-left"
                    ngClass.lt-xl="cb-margin-top"
                    mat-icon-button
                    *ngIf="!fullscreen"
                    (click)="viewFullscreen()">
                <mat-icon>fullscreen</mat-icon>
            </button>
        </div>
    </div>
</mat-card>
