import * as _ from 'lodash';

import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
    DocumentStatusEnumId, DOCUMENT_REQUIRED_TYPE_ENUM,
    DOCUMENT_REVIEW_STATUS_ENUM, DOCUMENT_STATUS_ENUM, IDocumentApprovalStatusDto, SYSTEM_AREA_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { DatePipe, Location } from '@angular/common';
import { Observable, Subscription, Subscriber } from 'rxjs';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { DocumentInfoDialogComponent } from '../document-info-dialog/document-info-dialog';
import { DocumentReviewDialogComponent } from '../document-review-dialog/document-review-dialog';
import { DocumentService } from '../../services/document.service';
import { DocumentUploadDialogComponent } from '../document-upload-dialog/document-upload-dialog';
import { IDocumentEntityDto } from '@app/logic/documents/interfaces/i.document.dto';
import { IDocumentEntityMappedItem } from '@app/logic/documents/interfaces/i.document.mapped';
import { IDocumentGroupDto } from '@app/logic/documents/interfaces/i.document-group.dto';
import { IDocumentPermissions } from '@app/core/permissions/base.permissions';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DownloadMultipleDocumentsDialogComponent } from '../download-multiple-documents-dialog/download-multiple-documents-dialog.component';
import { IDocumentTableExtraColumn } from '../../interfaces/i.document-table-column';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { DocumentHistoryComponent } from '../document-history/document-history.component';

export enum ColumnNames {
    Checkbox = 'checkbox',
    Name = 'name',
    FileType = 'fileType',
    Renewal = 'renewal',
    Status = 'status',
    DateUploaded = 'dateUploaded',
    Reviews = 'reviews',
    Actions = 'actions',
}

@Component({
    selector: 'cb-document-list',
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentListComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() public readonly allowReviewAtUpload: boolean = false;

    private _documents: IDocumentEntityMappedItem[];
    public get documents(): IDocumentEntityMappedItem[] {
        return this._documents || [];
    }
    @Input() public set documents(val: IDocumentEntityMappedItem[]) {
        this._documents = val;
        this.applyFilter(this.selectedDocumentGroup);
    }
    @Output() public readonly documentsChange = new EventEmitter<IDocumentEntityMappedItem[]>();
    @Output() public readonly documentChanged = new EventEmitter<IDocumentEntityMappedItem>();
    private readonly documentChangeSub$ = this.documentChanged.subscribe(() => {
        this.documentsChange.emit(this.documents);
    });

    @Input() public documentGroups: IDocumentGroupDto[];

    @Input() public readonly permissions: IDocumentPermissions;
    @Input() public lockRequired: boolean;
    @Input() public ignorePermissions = false;
    @Input() public readonly edit: boolean = true;

    private _prefixColumns: IDocumentTableExtraColumn[] = [];
    @Input() public set prefixColumns(v: IDocumentTableExtraColumn[]) {
        this._prefixColumns = v;
        this._displayedColumns.recompute();
    }
    public get prefixColumns(): IDocumentTableExtraColumn[] {
        return this._prefixColumns;
    }

    private _hideRenewalColumn: boolean;
    @Input() public set hideRenewalColumn(v: boolean) {
        this._hideRenewalColumn = v;
        this._displayedColumns.recompute();
    }
    public get hideRenewalColumn(): boolean {
        return this._hideRenewalColumn;
    }

    private _enableCheckbox = false;
    @Input() public set enableCheckbox(v: boolean) {
        this._enableCheckbox = v;
        this._displayedColumns.recompute();
    }
    public get enableCheckbox(): boolean {
        return this._enableCheckbox;
    }
    /** checked document entity ids */
    public checkedDocumentIds: { [documentEntityId: number]: boolean } = {};
    public allChecked = false;

    @Input() public showDeleted = false;
    @Input() public showDeletedEvent: Observable<boolean>;

    @Input() public pushAndRefreshDocumentsListEvent: Observable<IDocumentEntityMappedItem>;
    @Input() public $baseUri: string;
    @Input() public $documentEntityId: string | number;

    @ViewChild(MatSort, { static: true }) public sort: MatSort;

    private readonly _prefixDefaultColumns = {
        [ColumnNames.Checkbox]: () => this.enableCheckbox,
    };
    private readonly _defaultColumns = {
        [ColumnNames.Name]: () => true,
        [ColumnNames.FileType]: () => true,
        [ColumnNames.Renewal]: () => !this.hideRenewalColumn,
        [ColumnNames.Status]: () => true,
        [ColumnNames.DateUploaded]: () => true,
        [ColumnNames.Reviews]: () => true,
        [ColumnNames.Actions]: () => true,
    };

    private readonly _displayedColumns = new ComputedProperty(() => {
        return []
            // default prefixed columns
            .concat(Object.keys(this._prefixDefaultColumns).filter(colKey => this._prefixDefaultColumns[colKey]()))
            // custom prefixed columns
            .concat(this.prefixColumns.map(x => x.col))
            // default columns
            .concat(Object.keys(this._defaultColumns).filter(colKey => this._defaultColumns[colKey]()));
    });
    public get displayedColumns(): string[] {
        return this._displayedColumns.value;
    }
    public readonly COLUMN_NAMES = ColumnNames;


    private showDeletedSubscription = new Subscription();
    private pushAndRefreshDocumentsListSubscription = new Subscription();
    public loading = true;
    public DOCUMENT_STATUS_ENUM = DOCUMENT_STATUS_ENUM;

    @Input() public selectedDocumentGroup: IDocumentGroupDto;
    @Output() public selectedDocumentGroupChange = new EventEmitter<IDocumentGroupDto>();

    public selectedDocuments: IDocumentEntityMappedItem[];
    public dataSource = new MatTableDataSource<IDocumentEntityMappedItem>([]);

    public documentId: number;

    private readonly dialogClass = 'cb-dialog-container';

    constructor(
        private readonly datePipe: DatePipe,
        public readonly cbDialog: CbDialogService,
        public location: Location,
        private readonly documentService: DocumentService,
        private readonly navigationService: NavigationService,
    ) {
    }

    public ngOnInit(): void {

        this.loadQueryParams();

        this.getOrderedDocList();

        this.showDeletedSubscription = this.showDeletedEvent.subscribe(showDeleted =>
            this.subscribeEvent(this.selectedDocumentGroup, showDeleted)
        );

        this.pushAndRefreshDocumentsListSubscription = this.pushAndRefreshDocumentsListEvent.subscribe(data =>
            this.pushAndRefreshDocumentsList(data)
        );
    }

    private loadQueryParams(): void {
        const queryParams = this.navigationService.getQueryParams<{ documentEntityId?: string; paramEntityId?: string }>();
        this.documentId = Number(queryParams.documentEntityId ?? queryParams.paramEntityId) || null;
    }

    public ngAfterViewInit(): void {
        this.dataSource.sortingDataAccessor = (item, property) => {

            switch (property) {
                case ColumnNames.Name: {
                    if (item.document) {
                        return item.document.name;
                    } else {
                        return null;
                    }
                }
                case ColumnNames.FileType: {
                    return item.documentType.label;
                }
                case ColumnNames.Renewal: {
                    if (item.document) {
                        return item.document.renewalDate;
                    } else {
                        return null;
                    }
                }
                case ColumnNames.Status: {
                    return item.documentStatus;
                }
                case ColumnNames.DateUploaded: {
                    return item.document?.updatedDate;
                }
                case ColumnNames.Reviews: {
                    if (item.document) {
                        return item.document.approvalStatuses;
                    } else {
                        return null;
                    }
                }
            }
        };
    }

    public ngOnDestroy(): void {
        this.showDeletedSubscription.unsubscribe();
        this.pushAndRefreshDocumentsListSubscription.unsubscribe();
        this.documentChangeSub$.unsubscribe();
    }

    // #region EVENT SUBSCRIPTION

    public subscribeEvent = (documentGroup: IDocumentGroupDto, showDeleted = false): void => {
        this.showDeleted = showDeleted;
        this.applyFilter(documentGroup);
    };

    public pushAndRefreshDocumentsList = (data: IDocumentEntityMappedItem): void => {
        const existingIndex = this.documents.findIndex(x => x.id === data.id);
        if (existingIndex > -1) {
            this.documents[existingIndex] = data;
        } else {
            this.documents.push(data);
        }
        this.getOrderedDocList();
    };

    // #endregion


    // #region DATA SORTING AND FILTERING

    public getOrderedDocList = (): void => {
        this.applyFilter(this.selectedDocumentGroup);
    };

    private sortDocuments(documentEntities: IDocumentEntityMappedItem[]): IDocumentEntityMappedItem[] {
        return _.sortBy(documentEntities, entity => {
            return entity.document && entity.document.name != null
                ? entity.document.name.toLowerCase()
                : '';
        });
    }

    public applyFilter = (documentGroup: IDocumentGroupDto): void => {
        this.selectedDocumentGroup = documentGroup;
        this.selectedDocumentGroupChange.emit(this.selectedDocumentGroup);

        if (documentGroup == null) {
            this.selectedDocuments = this.documents;
            if (this.showDeleted) {
                this.selectedDocuments = this.documents;
            } else {
                this.selectedDocuments = this.documents.filter(
                    documentEntity =>
                        (documentEntity.document == null && documentEntity.documentStatus !== DocumentStatusEnumId.StubDeleted)
                        || documentEntity.document?.isDeleted === this.showDeleted
                );
            }
        } else {
            if (this.showDeleted) {
                this.selectedDocuments = this.documents.filter(
                    documentEntity =>
                        documentEntity.documentGroup.id === documentGroup.id
                );
            } else {
                this.selectedDocuments = this.documents.filter(
                    documentEntity =>
                        (documentEntity.document == null &&
                            documentEntity.documentGroup.id === documentGroup.id &&
                            documentEntity.documentStatus !== DocumentStatusEnumId.StubDeleted) ||
                        (documentEntity.documentGroup.id === documentGroup.id &&
                            documentEntity.document?.isDeleted === this.showDeleted)
                );
            }
        }

        const sortedData = this.sortDocuments(this.selectedDocuments);
        this.dataSource.data = sortedData;
        this.dataSource.sort = this.sort;
        this.loading = false;

        this.allChecked = this.selectedDocuments.every(x => this.checkedDocumentIds[x.id]);
    };

    // #endregion


    // #region COMPONENT'S ICONS/COMMENT/GROUP SIZE GETTERS

    public getReviewIcon(approvalStatus: IDocumentApprovalStatusDto): string {
        switch (approvalStatus.documentReviewStatus) {
            case DOCUMENT_REVIEW_STATUS_ENUM.Approved:
                return 'check_circle';
            case DOCUMENT_REVIEW_STATUS_ENUM.Rejected:
                return 'cancel';
            default:
                return 'info_circle';
        }
    }

    public getReviewComment(approvalStatus: IDocumentApprovalStatusDto): string {
        switch (approvalStatus.documentReviewStatus) {
            case DOCUMENT_REVIEW_STATUS_ENUM.Approved:
                return (
                    `${approvalStatus.reviewedByName} : ${this.datePipe.transform(approvalStatus.reviewedDate, 'dd MMM yyyy')}`
                );
            case DOCUMENT_REVIEW_STATUS_ENUM.Rejected:
                return (
                    `${approvalStatus.reviewComments} - ${approvalStatus.reviewedByName} : ${this.datePipe.transform(approvalStatus.reviewedDate, 'dd MMM yyyy')}`
                );
            default:
                return 'Awaiting Review';
        }
    }

    public getGroupLength(groupId: number): number {
        if (this.showDeleted) {
            return this.documents.filter(
                document => document.documentGroup.id === groupId
            ).length;
        } else {
            return this.documents.filter(
                document => document.documentGroup.id === groupId &&
                    ((document.document && !document.document.isDeleted) || !document.document)
            ).length;

        }
    }

    public getAllDocumentsLength(): number {
        if (!this.showDeleted) {
            return this.documents.filter(
                document => (document.document && !document.document.isDeleted) || !document.document
            ).length;
        } else {
            return this.documents.length;
        }
    }

    public toHumanizeWords(label: string): any {
        const words = (label.charAt(0).toUpperCase() + label.substring(1)).match(/[A-Za-z][a-z]*/g) || [];
        return words.join(' ');
    }

    // #endregion


    // #region MENU ACTIONS


    public viewDocumentHistory(documentEntityMappedItem: IDocumentEntityMappedItem): void {
        documentEntityMappedItem.viewDocumentHistory().subOnce(documentDto => {
            this.cbDialog.open(
                DocumentHistoryComponent,
                {
                    data: documentDto,
                    minWidth: '60%',
                }
            );
        });
    }

    public viewDocumentDetails(documentEntityMappedItem: IDocumentEntityMappedItem): void {
        this.cbDialog.open(
            DocumentInfoDialogComponent,
            {
                data: { documentEntityMappedItem: documentEntityMappedItem.$clone(), isEdit: false },
                panelClass: this.dialogClass,
                minWidth: 400,
                width: '450px',
            }
        );
    }

    public editDocumentDetails(documentEntityMappedItem: IDocumentEntityMappedItem): void {
        this.cbDialog.open(
            DocumentInfoDialogComponent,
            {
                data: { documentEntityMappedItem: documentEntityMappedItem.$clone(), isEdit: true },
                panelClass: this.dialogClass,
                minWidth: 400,
            }
        )
            .afterClosed()
            .subOnce(() => this.documentChanged.emit(documentEntityMappedItem));
    }

    public downloadDocument(documentEntityMappedItem: IDocumentEntityMappedItem): void {
        documentEntityMappedItem.downloadDocument().subOnce();
    }

    public reviewDocument(documentEntityMappedItem: IDocumentEntityMappedItem): void {

        this.cbDialog.open(
            DocumentReviewDialogComponent,
            {
                data: documentEntityMappedItem.$clone(),
                panelClass: this.dialogClass,
                minWidth: 400,
            }
        )
            .afterClosed()
            .subOnce(() => {
                this.documentsChange.emit(this.documents);
                this.documentChanged.emit(documentEntityMappedItem);
            });
    }

    public uploadDocument(documentEntityMappedItem: IDocumentEntityMappedItem): void {
        this.cbDialog.open(
            DocumentUploadDialogComponent,
            {
                data: {
                    documentEntityMappedItem: documentEntityMappedItem.$clone(),
                    documentGroups: this.documentGroups,
                    isDocumentReupload: true,
                    $baseUri: this.$baseUri,
                    $documentEntityId: this.$documentEntityId,
                    allowReviewAtUpload: this.allowReviewAtUpload
                },
                panelClass: this.dialogClass,
                minWidth: 400,
                width: '600px',
            }
        )
            .afterClosed()
            .subOnce(() => this.documentChanged.emit(documentEntityMappedItem));
    }

    public deleteStub(documentEntity: IDocumentEntityMappedItem): void {
        this.cbDialog.confirm({
            dialogHeading: 'Delete Document Stub',
            message: `Are you sure you want to delete this document stub '${documentEntity.documentType.label}'?`,
            confirmed: this.handleDeleteDocumentStub.bind(this, documentEntity),
        });
    }

    public deleteDocument(documentEntity: IDocumentEntityMappedItem): void {
        this.cbDialog.confirm({
            message: `Are you sure you want to delete this document ${documentEntity.document.name}?`,
            confirmed: this.handleDeleteDocument.bind(this, documentEntity),
        });
    }

    private readonly handleDeleteDocument = (documentEntity: IDocumentEntityMappedItem): void => {
        documentEntity.deleteDocument()
            .subOnce(_document => {
                this.applyFilter(this.selectedDocumentGroup);
                this.documentChanged.emit(documentEntity);
                this.documentService.documentDeleted.next(documentEntity);
            });
    };

    private readonly handleDeleteDocumentStub = (documentEntity: IDocumentEntityMappedItem): void => {
        documentEntity.deleteDocumentStub()
            .subOnce(_document => {
                this.applyFilter(this.selectedDocumentGroup);
                this.documentChanged.emit(documentEntity);
                this.documentService.documentDeleted.next(documentEntity);
            });
    };

    // #endregion


    // #region MENU ACTIONS PERMISSIONS

    public isRowDisabled(documentEntity: IDocumentEntityMappedItem): boolean {
        return (documentEntity.document && documentEntity.document.isDeleted)
            || documentEntity.documentStatus === DOCUMENT_STATUS_ENUM.StubDeleted
            || documentEntity.documentStatus === DOCUMENT_STATUS_ENUM.Cancelled;
    }


    public isActionMenuHidden(documentEntity: IDocumentEntityMappedItem): boolean {
        return (documentEntity.document && documentEntity.document.isDeleted)
            || documentEntity.documentStatus === DOCUMENT_STATUS_ENUM.StubDeleted
            || documentEntity.documentStatus === DOCUMENT_STATUS_ENUM.Cancelled
            || (documentEntity.document && documentEntity.document.isLinkedDocument)
            || documentEntity.document == null;
    }

    public isUploadOnly(documentEntity: IDocumentEntityMappedItem): boolean {
        return !documentEntity.document;
    }

    public isLinkedDocument(documentEntity: IDocumentEntityMappedItem): boolean {
        return documentEntity.document && documentEntity.document.isLinkedDocument;
    }

    private islockedRequiredDocument(document: IDocumentEntityDto): boolean {

        return (document.documentRequiredType === DOCUMENT_REQUIRED_TYPE_ENUM.Required
            || document.documentRequiredType === DOCUMENT_REQUIRED_TYPE_ENUM.CodeRequired)
            && this.lockRequired;
    }

    public canEdit(document: IDocumentEntityMappedItem): boolean {
        return this.edit
            && !document.isLinkedDocument
            && document.document
            && !document.document.isDeleted
            && (this.ignorePermissions || this.permissions.canEditDocuments())
            && !this.islockedRequiredDocument(document);
    }

    public canReview(document: IDocumentEntityMappedItem): boolean {
        return document.canReview && (this.ignorePermissions || this.permissions.canReviewDocuments())
            && !this.islockedRequiredDocument(document);
    }

    public canUpload(document: IDocumentEntityMappedItem, onlyIfEmpty = false): boolean {
        if (!this.edit) {
            return false;
        }
        if (onlyIfEmpty) {
            return !document.document
                && (this.ignorePermissions || this.permissions.canUploadDocument())
                && !this.islockedRequiredDocument(document);
        }
        return document.canUpload
            && (this.ignorePermissions || this.permissions.canUploadDocument())
            && !this.islockedRequiredDocument(document);
    }

    public canDelete$(document: IDocumentEntityMappedItem): Observable<boolean> {
        return new Observable((subscriber: Subscriber<boolean>) => {
            subscriber.next(
                document.document
                && !document.document.isDeleted
                && this.isAllowedToDelete(document)
            );
        });
    }

    public canDeleteStub$(document: IDocumentEntityMappedItem): Observable<boolean> {
        return new Observable((subscriber: Subscriber<boolean>) => {
            subscriber.next(
                !document.document
                && document.documentRequiredType === DOCUMENT_REQUIRED_TYPE_ENUM.SoftRequired
                && document.documentStatus !== DocumentStatusEnumId.StubDeleted
                && this.isAllowedToDelete(document)
            );
        });
    }

    private isAllowedToDelete(document: IDocumentEntityMappedItem): boolean {
        return this.edit
            && !document.isLinkedDocument
            && (this.ignorePermissions || this.permissions.canDeleteDocuments())
            && !this.islockedRequiredDocument(document);
    }

    // #endregion

    // #region Checked/Selected row functions

    public toggleAllChecked(event: MouseEvent | null, value = !this.allChecked): void {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.allChecked = value;
        if (!this.selectedDocumentGroup?.name) {
            this.checkedDocumentIds = {};
        }
        if (this.allChecked) {
            this.selectedDocuments.filter(x => x.document?.id > 0 && !x.document?.isDeleted).forEach(x => this.checkedDocumentIds[x.id] = true);
        } else {
            this.selectedDocuments.forEach(x => this.checkedDocumentIds[x.id] = false);
        }
    }

    public downloadAllChecked(): void {
        const noneChecked = !this.anyDocumentsChecked();
        this.cbDialog.open(DownloadMultipleDocumentsDialogComponent, {
            data: {
                documents: noneChecked ? this.selectedDocuments : this.documents.filter(x => this.checkedDocumentIds[x.id]),
                prefixColumns: this.prefixColumns,
            },
            minWidth: 980,
            maxWidth: '95%',
        });
    }

    public toggleAllLabel(): string {
        let str = this.allChecked ? 'Deselect All' : 'Select All';
        if (this.selectedDocumentGroup?.name) {
            str += ` (${this.selectedDocumentGroup.name})`;
        }
        return str;
    }

    public downloadAllLabel(): string {
        const noneChecked = !this.anyDocumentsChecked();
        let str = noneChecked ? 'Download All' : 'Download Selected';
        if (noneChecked && this.selectedDocumentGroup?.name) {
            str += ` (${this.selectedDocumentGroup.name})`;
        }
        return str;
    }

    public anyDocumentsChecked(): boolean {
        return Object.values(this.checkedDocumentIds)?.some(x => x);
    }

    public documentActionsTooltip(): string {
        const noneChecked = !this.anyDocumentsChecked();
        let str = noneChecked ? 'All Document Actions' : 'Selected Document Actions';
        if (noneChecked && this.selectedDocumentGroup?.name) {
            str += ` (${this.selectedDocumentGroup.name})`;
        }
        return str;
    }

    public canViewHistory(documentEntity: IDocumentEntityMappedItem): boolean {
        return documentEntity?.documentGroup?.systemAreas?.some(x => x.systemArea === SYSTEM_AREA_ENUM.DesignScheme ||
            x.systemArea === SYSTEM_AREA_ENUM.PreConsentPlan)
            && !documentEntity?.allowMultiples && documentEntity?.documentRequiredType === DOCUMENT_REQUIRED_TYPE_ENUM.Required;
    }

    // #endregion
}
