<div class="flex flex-col">
    <mat-button-toggle-group
        class="date-toggle flex"
        [(ngModel)]="dateValue.type"
        aria-label="Date Type">
        <mat-button-toggle class="flex" value="absolute">Absolute Date</mat-button-toggle>
        <mat-button-toggle class="flex" value="relative">Relative Date</mat-button-toggle>
    </mat-button-toggle-group>

    <!-- Absolute Date Range -->
    <div *ngIf="dateValue.type === 'absolute'" class="flex flex-row">
        <mat-form-field class="flex center">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input
                    matStartDate
                    placeholder="Start date"
                    [(ngModel)]="dateValue.fromDate"
                    (ngModelChange)="onDateValueChange()">
                <input
                    matEndDate
                    placeholder="End date"
                    [(ngModel)]="dateValue.toDate"
                    (ngModelChange)="onDateValueChange()">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    </div>

    <!-- Relative Date -->
    <div *ngIf="dateValue.type === 'relative'" class="flex flex-row">
        <mat-form-field appearance="fill" class="flex-25">
            <mat-label>Number</mat-label>
            <input
                matInput
                type="number"
                [(ngModel)]="dateValue.relative.value"
                (ngModelChange)="onDateValueChange()"
                min="1" />
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Relative by</mat-label>
            <mat-select
                [(ngModel)]="dateValue.relative.unit"
                (selectionChange)="onDateValueChange()">
                <mat-option *ngFor="let unit of units" [value]="unit">
                    {{ unit | titlecase }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="unit-input">
            <mat-label>Time Flow</mat-label>
            <mat-select
                [(ngModel)]="dateValue.relative.direction"
                (selectionChange)="onDateValueChange()">
                <mat-option value="future">In the Future</mat-option>
                <mat-option value="past">In the Past</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
