import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LotQuotePermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ILotMappedItem } from '@app/logic/lots';
import { QuoteLogicService } from '@app/logic/quote';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IQuoteDocumentDto, IQuoteDto, QUOTE_STATUS_ENUM, QuoteStatusEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import moment from 'moment';
import { LotQuoteCreateDialogComponent } from '../lot-quote-create-dialog/lot-quote-create-dialog.component';
import { LotQuoteDialogComponent } from '../lot-quote-dialog/lot-quote-dialog.component';
import { LotQuoteVariationOrdersDialogComponent } from '../lot-quote-variation-orders-dialog/lot-quote-variation-orders-dialog.component';

@Component({
    selector: 'cb-lot-quote-card',
    templateUrl: './lot-quote-card.component.html',
    styleUrls: ['./lot-quote-card.component.scss']
})
export class LotQuoteCardComponent implements OnInit {

    public QUOTE_STATUS_ENUM = QUOTE_STATUS_ENUM;

    @Input() public lotQuoteSearchItem: IQuoteDocumentDto;
    @Input() public isLotSpecLocked: boolean;
    @Input() public lotMappedItem: ILotMappedItem;
    @Output() public quoteDuplicated = new EventEmitter<IQuoteDocumentDto>();
    @Output() public refreshSearch = new EventEmitter<any[]>();

    constructor(
        private readonly lotQuotePermissions: LotQuotePermissions,
        private readonly cbDialog: CbDialogService,
        private readonly logicService: QuoteLogicService,
        private readonly navigationService: NavigationService) { }

    public ngOnInit(): void {
        this.loadSelectedQuote();
    }

    public loadSelectedQuote(): void {
        const navParams = this.navigationService.getQueryParams<{ quoteId: string }>();
        const quoteId = navParams.quoteId;
        if (+quoteId === this.lotQuoteSearchItem.id) {
            this.manageQuote();
        }

    }

    public getSupplierName(): string {
        return this.lotQuoteSearchItem.supplierTradingName ?? this.lotQuoteSearchItem.freeTextSupplierName;
    }

    public hasExpired(): boolean {
        return moment().format() > this.lotQuoteSearchItem?.expiryDate;
    }


    public canCreateQuote(): boolean {
        return this.lotQuotePermissions.canCreate();
    }

    public canManageQuote(): boolean {
        return this.lotQuotePermissions.canManage();
    }

    public canEditQuote(): boolean {
        return this.lotQuotePermissions.canView() &&
            this.lotQuotePermissions.canEdit() &&
            !this.lotQuotePermissions.canManage();
    }

    public canDownload(): boolean {
        return this.lotQuoteSearchItem?.quoteStatus === QUOTE_STATUS_ENUM.Submitted ||
            this.lotQuoteSearchItem?.quoteStatus === QUOTE_STATUS_ENUM.Accepted ||
            this.lotQuoteSearchItem?.quoteStatus === QUOTE_STATUS_ENUM.Pending ||
            this.lotQuoteSearchItem?.quoteStatus === QUOTE_STATUS_ENUM.Abandoned;
    }

    public canAbandonQuote(): boolean {
        return this.lotQuotePermissions.canAbandon()
            && (this.lotQuoteSearchItem?.quoteStatus !== this.QUOTE_STATUS_ENUM.Abandoned)
            && ((this.lotQuoteSearchItem?.isScheduleSpecTemplate && !this.isLotSpecLocked && !this.lotQuoteSearchItem?.lotHasADesignConcept)
                || this.lotQuoteSearchItem?.quoteStatus === this.QUOTE_STATUS_ENUM.Required
                || this.lotQuoteSearchItem?.quoteStatus === this.QUOTE_STATUS_ENUM.Pending);
    }

    public downloadQuote(): void {
        this.logicService.downloadQuote(this.lotQuoteSearchItem.id).subOnce();
    }


    public receiveQuote(): void {
        this.logicService.$getMappedItem(this.lotQuoteSearchItem.id).subOnce((quoteMappedItem) => {

            const dialogRef = this.cbDialog.open(LotQuoteCreateDialogComponent, {
                data: {
                    mappedItem: quoteMappedItem,
                    lotMappedItem: this.lotMappedItem,
                    isRequiredQuote: false
                }
            });

            dialogRef.afterClosed().subOnce(quoteDto => {
                if (quoteDto.id > 0) {
                    // Get fresh quote
                    this.logicService.$getItem(quoteDto.id).subOnce((_freshQuoteDto: IQuoteDto) => {
                        const lotQuoteSearchItem: IQuoteDocumentDto = {
                            ..._freshQuoteDto,
                            supplierNameLabel: _freshQuoteDto.supplierTradingName,
                            isWaitingForChangesToProcess: this.isQuoteWaitingOnChangesToProcess(_freshQuoteDto)
                        };

                        this.lotQuoteSearchItem = lotQuoteSearchItem;
                    });
                }
            });

        });

    }

    public abandonQuote(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Abandon Quote',
            message: 'Are you sure you want to Abandon this Quote?',
            confirmed: () => {
                this.logicService.abandonQuote(this.lotQuoteSearchItem.id).subOnce((quoteDto) => {

                    const lotQuoteSearchItem: IQuoteDocumentDto = {
                        ...quoteDto,
                        supplierNameLabel: quoteDto?.supplierTradingName,
                        isWaitingForChangesToProcess: false
                    };

                    this.lotQuoteSearchItem = lotQuoteSearchItem;
                });
            }
        });
    }

    public viewQuoteVO(): void {
        this.cbDialog.open(LotQuoteVariationOrdersDialogComponent, {
            data: this.lotQuoteSearchItem
        });
    }

    public duplicateQuote(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Duplicate Quote',
            message: 'Are you sure you want to Duplicate this Quote?',
            confirmed: () => {
                this.logicService.duplicateQuote(this.lotQuoteSearchItem.id).subOnce((quoteDto) => {

                    const duplicateLotQuoteSearchItem: IQuoteDocumentDto = {
                        ...quoteDto,
                        supplierNameLabel: quoteDto.supplierTradingName,
                        isWaitingForChangesToProcess: false
                    };

                    this.quoteDuplicated.emit(duplicateLotQuoteSearchItem);
                });
            }
        });
    }

    public manageQuote(): void {
        this.logicService.$getMappedItem(this.lotQuoteSearchItem.id).subOnce((_quoteMappedItem) => {

            const dialogRef = this.cbDialog.open(LotQuoteDialogComponent, {
                data: {
                    quoteMappedItem: _quoteMappedItem,
                    isLotSpecLocked: this.isLotSpecLocked,
                    lotMappedItem: this.lotMappedItem
                }
            });

            dialogRef.afterClosed().subOnce((quoteDto: IQuoteDto) => {
                if (quoteDto) {
                   this.refreshSearch.emit();
                }
            });

        });

    }


    public editQuote(): void {
        this.logicService.$getMappedItem(this.lotQuoteSearchItem.id).subOnce((result) => {
            this.cbDialog.open(LotQuoteCreateDialogComponent, {
                data: {
                    mappedItem: result,
                    lotMappedItem: this.lotMappedItem,
                }
            });
        });

    }

    private isQuoteWaitingOnChangesToProcess(quoteDto: IQuoteDto): boolean {
        return quoteDto &&
            quoteDto.quoteStatus === QuoteStatusEnumId.Submitted &&
            quoteDto.changeRecordIds?.length > 0;
    }

}
