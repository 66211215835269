<div class="cb-margin flex-col">
    <cb-standard-plan-search *ngIf="standardPlanssLoaded && addressRegionsLoaded"
                             [(standardPlans)]="standardPlans"
                             [addressRegions]="addressRegions"></cb-standard-plan-search>
    <cb-standard-plan-card *ngIf="standardPlanssLoaded && addressRegionsLoaded && stylesLoaded"
                           [standardPlans]="standardPlans"
                           [addressRegions]="addressRegions"
                           [styles]="styles"></cb-standard-plan-card>
    <cb-loading-spinner *ngIf="!standardPlanssLoaded || !addressRegionsLoaded || !stylesLoaded"
                        message="Loading standard plans"></cb-loading-spinner>
    <cb-info-message message="There are no Standard Plans found"
                     *ngIf="standardPlanssLoaded && standardPlans.length < 1">
    </cb-info-message>
</div>
