import { CONTACT_STATUS_ENUM, IContactDocumentDto, IContactDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Component, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BaseSearchViewDirective } from '@app/shared/base-views/base-search-view.directive';
import { ContactsLogicService } from '@app/logic/contacts';
import { PermissionsPermissions } from '@app/core/permissions';
import { ToastService } from '@app/core/services/toast/toast.service';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { ISearchResult } from '@app/core/services/user-cache/user-cache-areas';
import { USER_CACHE_AREA } from 'cb-hub-lib';
import { IClientAccountMappedItem } from '@app/logic/client-account/interfaces/i.client-account.mapped';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { CreateContactDialogComponent } from '../create-contact-dialog/create-contact-dialog.component';
import { NoteDialogComponent } from '@app/shared/components/notes/note-dialog/note-dialog.component';

@Component({
    selector: 'cb-add-contact-dialog',
    templateUrl: './add-contact-dialog.component.html',
    styleUrls: ['./add-contact-dialog.component.scss']
})
export class AddContactDialogComponent extends BaseSearchViewDirective<ISearchResult> implements AfterViewInit, OnDestroy {
    public static readonly MIN_WIDTH = '600px';

    public searchResults: IContactDocumentDto[] = [];

    public readonly CONTACT_STATUS_ENUM = CONTACT_STATUS_ENUM;

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly dialogRef: MatDialogRef<NoteDialogComponent>,
        public readonly permissionsPermissions: PermissionsPermissions,
        private readonly contactsLogicService: ContactsLogicService,
        public readonly toastService: ToastService,
        public readonly userCacheService: UserCacheService,
        public readonly cbDialog: CbDialogService,
    ) {
        super(
            contactsLogicService,
            toastService,
            userCacheService,
            USER_CACHE_AREA.ContactSearch
        );

    }

    public ngAfterViewInit(): void {
        this.loadSearchParams();
    }

    protected getSearchParams(): ISearchResult {
        const data = this.userCacheItem.copyData();
        return {
            query: data.query,
        };
    }

    public addContact = (contact: IContactDto): void => {
        this.data.account?.contactIds?.push(contact.id);
        this.data.contacts?.push(contact);
        this.data.addedContacts?.push(contact.id);
        this.dialogRef.close(true);
    };

    public createContact(): void {
        let defaultData;
        if (this.data?.account) {
            defaultData = {};
            defaultData.physicalAddress = this.data.account.physicalAddress?.id ? this.data.account.physicalAddress : undefined;
            defaultData.postalAddress = this.data.account.postalAddress?.id ? this.data.account.postalAddress : defaultData.physicalAddress;
        }
        this.cbDialog
            .open(CreateContactDialogComponent, {
                data: { defaultData },
                minWidth: 1250
            })
            .afterClosed()
            .subOnce((contact: false | IContactDto) => {
                if (contact) {
                    this.addContact(contact);
                }
            });
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}

export interface IData {
    account: IClientAccountMappedItem;
    contacts: IContactDto[];
    addedContacts: string[];

}
