import { Component, Input } from '@angular/core';
import { IAmenitiesDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-amenities',
    templateUrl: './amenities.component.html',
    styleUrls: ['./amenities.component.scss']
})
export class AmenitiesComponent {

    @Input() public amenities: IAmenitiesDto;

    constructor() { }
}
