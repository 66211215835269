import { Component } from '@angular/core';
import { SignalrService } from '@app/core/services/signalr/signalr.service';
import { NotificationLogicService } from '@app/logic/notification';
import { ISkinnyUserConnectionsDto } from '@classictechsolutions/hubapi-transpiled-enums';

interface ISkinnyUserConnectionsDtoWithMessage extends ISkinnyUserConnectionsDto {
    message: string;
}

@Component({
    selector: 'cb-connections',
    templateUrl: './connections.component.html',
    styleUrls: ['./connections.component.scss']
})
export class ConnectionsComponent {

    public message: string;

    constructor(
        public notificationHubService: SignalrService,
        public notificationLogicService: NotificationLogicService,
    ) {
    }

    public get users(): ISkinnyUserConnectionsDtoWithMessage[] {
        let users: ISkinnyUserConnectionsDtoWithMessage[] = [];
        if (this.notificationHubService && this.notificationHubService.connectedUsers) {
            users = this.notificationHubService.connectedUsers.sort((n1, n2) => {
                if (n1.firstName > n2.firstName) {
                    return 1;
                }
                if ((n1.firstName + n1.firstName) < (n2.firstName + n2.lastName)) {
                    return -1;
                }
                return 0;
            });
        }
        return users;
    }

    public broadcastMessage(): void {
        for (const user of this.users) {
            this.sendMessage(user, this.message);
        }
    }

    public sendMessage(user: ISkinnyUserConnectionsDto, message: string): void {
        if (user?.id && message) {
            this.notificationLogicService.sendNotificationRead(user.id, message).subOnce();
        }
    }
}
