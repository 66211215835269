import {NgForm} from '@angular/forms';
import {BusinessEntitiesLogicService, IBusinessEntityDto} from '@app/logic/business-entity';
import {AddressRegionsLogicService} from '@app/logic/address-regions';
import {
    IAddressRegionDto,
    IIdAndLabelDto,
    IProjectDetailsDto,
    ITempLotDto,
    LOT_CONTRACT_TYPE_ENUM,
    LOT_EXTERNAL_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import {isEmpty} from 'lodash';
import {Component, Inject} from '@angular/core';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {ITempLotMappedItem} from '@app/logic/temp-lots';
import {ITempLotsLogicService} from '@app/logic/temp-lots/interfaces/i.temp-lots.logic.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ToastService} from '@app/core/services/toast/toast.service';
import {GARAGES_LOOKUP} from '@app/shared/declarations/garages.lookup';
import {ProjectsLogicService} from '@app/logic/projects';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';

interface IData {
    mappedItem: ITempLotMappedItem;
}

@Component({
    selector: 'cb-temp-lot-edit-dialog',
    templateUrl: './temp-lot-edit-dialog.component.html',
    styleUrls: ['./temp-lot-edit-dialog.component.scss'],
    providers: [
        AddressRegionsLogicService,
        BusinessEntitiesLogicService,
        ProjectsLogicService,
    ]
})
export class TempLotEditDialogComponent extends BaseDialogFormViewDirective<ITempLotDto, ITempLotMappedItem, ITempLotsLogicService> {

    public readonly lotStatusLookup = LOT_EXTERNAL_STATUS_ENUM.toLookup();
    public readonly garages = GARAGES_LOOKUP;
    public readonly LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;

    public regions: IAddressRegionDto[];
    private fullBusinessEntities: IBusinessEntityDto[];
    public businessEntities: IBusinessEntityDto[];
    public fullProjects: IProjectDetailsDto[];
    public projects: IProjectDetailsDto[];
    public projectReleases: IIdAndLabelDto[];

    public newRenderImageBase64: SafeUrl;
    public newFloorPlanImageBase64: SafeUrl;

    constructor(
        public readonly addressRegionsLogic: AddressRegionsLogicService,
        public readonly businessEntityLogic: BusinessEntitiesLogicService,
        public readonly projectsLogic: ProjectsLogicService,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        public readonly domSanitizer: DomSanitizer,
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<TempLotEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
        if (this.mappedItem.status == null || isEmpty(this.mappedItem.status)) {
            this.mappedItem.status = { id: 0, label: 'None' };
        }

        this.addressRegionsLogic.$getList().subOnce(x => {
            this.regions = x.filter(y => y.isActive);

            this.businessEntityLogic.$getList().subOnce((result) => {
                this.fullBusinessEntities = result;
                this.businessEntities = this.fullBusinessEntities.filter(y => y.physicalAddress.region === this.mappedItem.lotAddress.region);
            });

            this.projectsLogic.$getList().subOnce((result) => {
                this.fullProjects = result;
                this.projects = this.fullProjects.filter(y => y.address.region === this.mappedItem.lotAddress.region);
            });
        });
        if (this.mappedItem?.project?.id) {
            this.projectChanged();
        }
    }

    public addressRegionChanged(): void {
        this.businessEntities = this.fullBusinessEntities.filter(y => y.physicalAddress.region === this.mappedItem.lotAddress.region);
        this.projects = this.fullProjects.filter(y => y.address.region === this.mappedItem.lotAddress.region);
        this.mappedItem.businessEntity = { id: null, label: null };
        this.mappedItem.project = { id: null, label: null };
        this.mappedItem.projectReleaseStage = { id: null, label: null, allowedLotContractTypes: null };
    }

    public projectChanged(): void {
        this.projectsLogic.getProjectReleaseLabels(this.mappedItem.project.id).subOnce(x => {
            this.projectReleases = x;
        });
    }

    public releaseStageChanged(): void {
        this.projectsLogic.getAllowedLotContractTypesForRelease(this.mappedItem.projectReleaseStage.id).subOnce(x => {
            this.mappedItem.lotContractType = 0;
            this.mappedItem.projectReleaseStage.allowedLotContractTypes = x;
        });
    }

    public removeFloorPlanImage(form: NgForm): void {
        this.cbDialog.confirm({
            confirmed: () => {
                this.mappedItem.floorPlanImage = null;
                this.mappedItem.floorPlanUrl = null;
                this.mappedItem.floorPlanId = null;
                this.newFloorPlanImageBase64 = null;
                form.form.markAsDirty();
            }
        });
    }

    public removeRenderImage(form: NgForm): void {
        this.cbDialog.confirm({
            confirmed: () => {
                this.mappedItem.renderImage = null;
                this.mappedItem.renderImageUrl = null;
                this.mappedItem.renderImageId = null;
                this.newRenderImageBase64 = null;
                form.form.markAsDirty();
            }
        });
    }

    public uploadFloorPlan(file: File, form: NgForm): void {
        if (!file.type.includes('jpeg') && !file.type.includes('jpg') && !file.type.includes('png')) {
            this.toastSerivce.showToast('Image must be a jpeg, jpg or png');
            return;
        }

        const reader = new FileReader();
        reader.onload = (e: any) => {
            this.newFloorPlanImageBase64 = this.domSanitizer.bypassSecurityTrustUrl(e.target.result);
            this.mappedItem.floorPlanImage = {
                file: {
                    filename: file.name,
                    contentType: file.type,
                    content: e.target.result.split('base64,').pop()
                }
            };
            form.form.markAsDirty();
        };

        reader.readAsDataURL(file);
    }

    public uploadRender(file: File, form: NgForm): void {
        if (!file.type.includes('jpeg') && !file.type.includes('jpg') && !file.type.includes('png')) {
            this.toastSerivce.showToast('Image must be a jpeg, jpg or png');
            return;
        }

        const reader = new FileReader();

        reader.onload = (e: any) => {
            this.newRenderImageBase64 = this.domSanitizer.bypassSecurityTrustUrl(e.target.result);
            this.mappedItem.renderImage = {
                file: {
                    filename: file.name,
                    contentType: file.type,
                    content: e.target.result.split('base64,').pop()
                }
            };
            form.form.markAsDirty();
        };

        reader.readAsDataURL(file);
    }

}
