<cb-dialog class="flex-col"
           dialogHeading="Import Release Lots">
    <form class="flex-col flex"
          #form="ngForm">
        <div class="end-center flex-row">
            <div class="uploadfilecontainer dropzone start-center flex-row flex"
                 cbDragDrop
                 (onFileDropped)="selectFile($event, form.form)"
                 style="height: 100px;">
                <input hidden
                       type="file"
                       #fileInput
                       (click)="$event.target.value = null"
                       (change)="selectFile($event.target.files, form.form)"/>
                <span class="cb-margin-left-2x"
                      *ngIf="document?.file?.name">{{document?.file?.name}}</span>
                <div class="cb-margin-left-2x flex-col"
                     *ngIf="!document?.file?.name">
                    <h4>Allowed File Types</h4>
                    <ul>
                        <li>
                            csv
                        </li>
                    </ul>
                    <ul>
                        <li>
                            xlsx
                        </li>
                    </ul>
                    <ul>
                        <li>
                            xlsm
                        </li>
                    </ul>
                </div>
                <span class="flex"></span>
                <div class="cb-margin-right-2x"
                     (click)="fileInput.click()">
                    <button style="width: 60px;height: 60px;"
                            mat-icon-button
                            color="accent">
                        <mat-icon>attach_file_outline</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <cb-checkbox label="Import Custom Job Numbers"
                     name="importCustomJobNumbers"
                     [(ngModel)]="importCustomJobNumbers"
                     class="cb-margin-top-2x">
        </cb-checkbox>
        <div class="flex-col">
            <div class="start-center flex-row"
                 *ngIf="errorMessage">
                <mat-icon color="warn">
                    report_problem
                </mat-icon>
                <span>
                    {{errorMessage}}
                </span>
            </div>
        </div>
        <footer class="end-center flex-row">
            <a mat-raised-button
               color="primary"
               [href]="lotTemplateUrl">Download Template</a>
            <button mat-raised-button
                    color="primary"
                    [disabled]="disabled(form.form)"
                    (cbClick)="uploadFile(form.form, fileInput)">Upload</button>
            <button mat-raised-button
                    (click)="cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
