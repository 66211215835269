<cb-dialog>
    <form class="flex-col flex"
          #colourItemForm="ngForm">
        <cb-input type="text"
                  name="code"
                  label="Item Code"
                  [required]="true"
                  [readonly]="isEdit()"
                  [(ngModel)]="mappedItem.code">
        </cb-input>
        <cb-input type="text"
                  name="name"
                  label="Item Name"
                  [required]="true"
                  [(ngModel)]="mappedItem.name">
        </cb-input>
        <cb-checkbox name="showInClientSpecification"
                     label="Show on Create Schedule"
                     [(ngModel)]="mappedItem.showInClientSpecification">
        </cb-checkbox>
        <cb-text-area label="Text for Specification"
                      name="textForSpecification"
                      [(ngModel)]="mappedItem.textForSpecification"
                      [maxlength]="1024">
        </cb-text-area>
        <cb-checkbox name="isActive"
                     label="Active"
                     [(ngModel)]="mappedItem.isActive">
        </cb-checkbox>

        <!-- [dynamicImageLoader]="true" -->
        <cb-image-button [baseUrl]="mappedItem.imageUrl"
                         *ngIf="mappedItem.imageUrl">
        </cb-image-button>
        <cb-image-button [baseUrl]="mappedItem.colourItemImageFile"
                         *ngIf="mappedItem.colourItemImageFile"
                         [images]="[mappedItem.colourItemImageFile]">
        </cb-image-button>
        <input hidden
               type="file"
               #fileInput
               (change)="selectFile($event.target.files[0])"
               (click)="$event.target.value = null"
               accept=".jpg,.jpeg,.png,.gif,image/jpeg,image/gif,image/png"/>
        <div>
            <button mat-button
                    (click)="fileInput.click()"
                    style="display: block;">
                <mat-icon>
                    delete
                </mat-icon>
                <span *ngIf="!mappedItem.image && !mappedItem.colourItemImageFile">
                    &nbsp;Upload Image
                </span>
                <span *ngIf="mappedItem.image || mappedItem.colourItemImageFile">
                    &nbsp;Replace Image
                </span>
            </button>
            <button mat-button
                    style="display: block;"
                    (click)="removeImage()"
                    *ngIf="mappedItem.image || mappedItem.colourItemImageFile">
                <mat-icon>
                    delete
                </mat-icon>
                &nbsp;Remove Image
            </button>
        </div>
    </form>
    <footer class="flex-row flex">
        <span class="flex"></span>
        <button mat-raised-button
                color="primary"
                (click)="save()"
                [disabled]="colourItemForm.pristine || colourItemForm.invalid">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </footer>
</cb-dialog>
