import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { IDesignComplexityLogicService } from './interfaces/i.design-complexity.logic.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IDesignComplexityMappedItem } from './interfaces/i.design-complexity.mapped';
import { DesignComplexityMappedItem } from './design-complexity.mapped';
import { Observable } from 'rxjs';
import { IDesignComplexityDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Injectable()
export class DesignComplexityLogicService extends BaseLogicService<IDesignComplexityDto, IDesignComplexityMappedItem> implements IDesignComplexityLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('designcomplexity', DesignComplexityMappedItem);
    }

    public getDesignSchemeComplexitiesInUse(): Observable<{ scheme: string[]; preConsent: string[]; workingDrawing: string[] }> {
        return this.$http.get(`${this.$baseUri}/currentcomplexities`);
    }

}
