import {IDateMeasure} from '@app/shared/interfaces/i.date.measure';
import {IFileResponse} from '@app/shared/interfaces/i.file-response.dto';
import {
    CompassDirectionTypeEnumId,
    ConstructionManagerRoleEnumId,
    ContractStatusEnumId,
    DESIGN_SCHEME_STATUS_ENUM,
    ExternalSystemEnumId, IAddressDto,
    IDesignConceptDto,
    IDesignSchemeDto,
    IIdAndLabelDto,
    IInsuranceDto,
    ILocationDetailDto,
    ILookupDto,
    ILotAmenitiesDto,
    ILotClientSaleDto,
    ILotContactsDto,
    ILotContingencyAmounDto,
    ILotDto,
    ILotFinancialsDto,
    ILotImageDto,
    IRevisionStatusDateDto,
    ISkinnyAccountDto,
    ISkinnyBuildingConsentDto,
    ISkinnyProjectReleaseDto,
    ISkinnySpecTemplateDto,
    ISkinnyUserDto,
    ITakeOffDtoSummary,
    LandTitleStatusEnumId,
    LOT_CONTRACT_TYPE_ENUM,
    LOT_JOB_STATUS_ENUM,
    LOT_TYPE_ENUM,
    LotContractTypeEnumId,
    LotJobStatusEnumId,
    LotTypeEnumId,
    MarketStatusEnumId,
    ProjectReleaseStageTypeEnumId,
    SiteStatusEnumId
} from '@classictechsolutions/hubapi-transpiled-enums';
import {Observable} from 'rxjs';
import {BaseMappedItem} from '../base/base-mapped-item';
import {Computed} from '../base/computed-prop.decorator';
import {DtoProp} from '../base/dto-prop.decorator';
import {ILotMappedItem} from './interfaces/i.lot.mapped';
import {ILotsLogicService} from './interfaces/i.lots.logic.service';

export class LotMappedItem
    extends BaseMappedItem<ILotDto, ILotMappedItem, ILotsLogicService>
    implements ILotMappedItem {

    @DtoProp public contingencyAmount: ILotContingencyAmounDto;
    @DtoProp public id: number;
    @DtoProp public lotType: LotTypeEnumId;
    @DtoProp public isShowHome: boolean;
    @DtoProp public lotNumber: number;
    @DtoProp public locationId: number;
    @DtoProp public hasBuildProgrammeGenerated: boolean;
    @DtoProp public canHandover: boolean;
    @DtoProp public buildType: ILookupDto;
    @DtoProp public hasFloorDownDate: string;
    @DtoProp public buildingConsultantsWithLotInterest: ISkinnyUserDto[];
    @DtoProp public dpNumber: string;
    @DtoProp public hasTitle: boolean;
    @DtoProp public expectedTitleDate: string;
    @DtoProp public expectedTitleDateObject: Date;
    @DtoProp public parentId: number;
    @DtoProp public parentLotType: LotTypeEnumId;
    @DtoProp public lotBuildTypeLink: string;
    @DtoProp public floorDownUserId: string;
    @DtoProp public floorDownUserName: string;
    @DtoProp public parentJobNumber: string;
    @DtoProp public lotSpecId: number;
    @DtoProp public hasOwnersCareItem: boolean;
    @DtoProp public hasOutstandingChangesAndVariations: boolean;
    @DtoProp public insuranceCoverNumber: string;
    @DtoProp public qsConsentTakeOffCheck: boolean;
    @DtoProp public pendingConcepts: string[];
    @DtoProp public latestPreConsentPlan: IRevisionStatusDateDto;
    @DtoProp public latestWorkingDrawing: IRevisionStatusDateDto;
    @DtoProp public preConsentRequiredDocumentCount: number;
    @DtoProp public councilId: number;
    @DtoProp public councilName: string;
    @DtoProp public developerProject: string;
    @DtoProp public hasDesignSchemeInProgress: boolean;
    @DtoProp public currentDesignConcept: IDesignConceptDto;
    @DtoProp public currentPricingRevision: string;
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public canProcessPricing: boolean;
    @DtoProp public businessEntityId: number;
    @DtoProp public buildingCoordinatorId: string;
    @DtoProp public buildTypeId: number;
    @DtoProp public siteNumber: string;
    @DtoProp public owned: boolean;
    @DtoProp public priceLand: number;
    @DtoProp public priceSell: number;
    @DtoProp public noBedrooms: number;
    @DtoProp public noBathrooms: number;
    @DtoProp public noLivingRooms: number;
    @DtoProp public noGarages: number;
    @DtoProp public houseAndLandSpecialistId: string;
    @DtoProp public houseAndLandSpecialistName: string;
    @DtoProp public includesGst: boolean;
    @DtoProp public image: ILotImageDto;
    @DtoProp public allPreconsentDocumentsUploaded: boolean;
    @DtoProp public hasSignedWorkingDrawing: boolean;
    @DtoProp public hasCouncilRfi: boolean;
    @DtoProp public qsConsentedTakeoffGrantedById: string;
    @DtoProp public qsConsentedTakeoffGrantedBy: string;
    @DtoProp public qsConsentedTakeoffGrantedByDate: string;
    @DtoProp public isKiwibuild: boolean;
    @DtoProp public isGrowhome: boolean;
    @DtoProp public isSpecBuild: boolean;
    @DtoProp public isWithheldFromWebsite: boolean;
    @DtoProp public isKitset: boolean;
    @DtoProp public isTransportable: boolean;
    @DtoProp public jobNumber: string;
    @DtoProp public consentNumber: string;
    @DtoProp public contractGenerationFee: number;
    @DtoProp public estimatedStartDate: string;
    @DtoProp public estimatedEndDate: string;
    @DtoProp public hasConsent: boolean;
    @DtoProp public hasConsents: boolean;
    @DtoProp public consentDue: string;
    @DtoProp public consentDueObject: Date;
    @DtoProp public permitLodged: string;
    @DtoProp public permitLodgedObject: Date;
    @DtoProp public canGenerateLeadSiteEvaluationForm: boolean;
    @DtoProp public hasIncompleteRfiOrResourceConsent: boolean;
    @DtoProp public isCancelled: boolean;
    @DtoProp public canCreateRFI: boolean;
    @DtoProp public hasLotRfi: boolean;
    @DtoProp public hasMoreThanOneNonAbandonedDesignConcept: boolean;
    @DtoProp public jobStart: IDateMeasure;
    @DtoProp public jobComplete: IDateMeasure;
    @DtoProp public siteStatus: SiteStatusEnumId;
    @DtoProp public marketStatus: MarketStatusEnumId;
    @DtoProp public contractStatus: ContractStatusEnumId;
    @DtoProp public landTitleStatus: LandTitleStatusEnumId;
    @DtoProp public jobStatus: LotJobStatusEnumId;
    @DtoProp public contractType: LotContractTypeEnumId;
    @DtoProp public releaseStageType: ProjectReleaseStageTypeEnumId;
    @DtoProp public isOwned: boolean;
    @DtoProp public financials: ILotFinancialsDto;
    @DtoProp public amenities: ILotAmenitiesDto;
    @DtoProp public contacts: ILotContactsDto;
    @DtoProp public insurancePrice: number;
    @DtoProp public insuranceInterestedParty: string;
    @DtoProp public insuranceInterestedPartyEmail: string;
    @DtoProp public lotAddress: IAddressDto;
    @DtoProp public clientAccount: ISkinnyAccountDto;
    @DtoProp public clientAccountId: number;
    @DtoProp public clientAccountSolicitor: string;
    @DtoProp public clientAccountSolicitorEmail: string;
    @DtoProp public clientAccountContactIds: any;
    @DtoProp public project: ILookupDto;
    @DtoProp public projectAccount: any;
    @DtoProp public projectReleaseStage: ISkinnyProjectReleaseDto;
    @DtoProp public account: any;
    @DtoProp public LotSpecId: number;
    @DtoProp public exteriorImageUrl: string;
    @DtoProp public floorPlanImageUrl: string;
    @DtoProp public activeDesignConceptId: number;
    @DtoProp public currentDesignScheme: IDesignSchemeDto;
    @DtoProp public hasActiveDesignSchemes: boolean;
    @DtoProp public paymentTemplateId: number;
    @DtoProp public location: ILocationDetailDto;
    @DtoProp public businessEntity: IIdAndLabelDto;
    @DtoProp public insurance: IInsuranceDto;
    @DtoProp public specTemplateId: number;
    @DtoProp public standardPlanId: number;
    @DtoProp public clientSaleId: number;
    @DtoProp public clientSale: ILotClientSaleDto;
    @DtoProp public hasClientSale: boolean;
    @DtoProp public lotSpecLocked: boolean;
    @DtoProp public buildDuration: number;
    @DtoProp public thirdPartyLotVendor: string;
    @DtoProp public thirdPartyLotPurchasePrice: number;
    @DtoProp public hasSignedContract: boolean;
    @DtoProp public hasUnconditionalContract: boolean;
    @DtoProp public buildProgrammeTemplateId: number;
    @DtoProp public hasPriceRevisions: boolean;
    @DtoProp public hasSchemes: boolean;
    @DtoProp public hasSchemesInProgess: boolean;
    @DtoProp public hasIncompletedDesignSchemes: boolean;
    @DtoProp public hasConceptRevisions: boolean;
    @DtoProp public canRequestDesignSchemePricing: boolean;
    @DtoProp public appliedConceptName: string;
    @DtoProp public appliedConceptExteriorImageUploaded: boolean;
    @DtoProp public appliedConceptFloorPlanImageUploaded: boolean;
    @DtoProp public imageStatus: string;
    @DtoProp public numberOfInterests: number;
    @DtoProp public lotId: number;
    @DtoProp public lotName: string;
    @DtoProp public hasCodeCompliance: boolean;
    @DtoProp public hasFloorDown: boolean;
    @DtoProp public hasCompletedWorkingDrawing: boolean;
    @DtoProp public hasWorkingDrawings: boolean;
    @DtoProp public lotHoldingDeposit: number;
    @DtoProp public hasAnyDesignSchemes: boolean;
    @DtoProp public buildingConsent: ISkinnyBuildingConsentDto;
    @DtoProp public preConsentId: number;
    @DtoProp public hasNonDeletedConsentRecord: boolean;
    @DtoProp public hasFirstAcceptedScheme: boolean;
    @DtoProp public hasApprovedAllPreConsentDocuments: boolean;
    @DtoProp public hasCompletedAPreConsentPlan: boolean;
    @DtoProp public hasPrimaryBuildingConsentWithRfi: boolean;
    @DtoProp public hasIncompleteResourceConsent: boolean;
    @DtoProp public hasGrantedPrimaryBuildingConsent: boolean;
    @DtoProp public hasCompletedAllBuildProgrammeActivities: boolean;
    @DtoProp public qsConsentTakeoffGranted: boolean;
    @DtoProp public qsConsentTakeoffGrantedByName: string;
    @DtoProp public qsConsentTakeoffGrantedById: number;
    @DtoProp public qsConsentTakefffGrantedDate: number;
    @DtoProp public hasCurrentTakeOff: boolean;
    @DtoProp public lotSpecVersion: number;
    @DtoProp public designSchemeId: number;
    @DtoProp public designSchemeStatusId: number;
    @DtoProp public roadDirection: CompassDirectionTypeEnumId;
    @DtoProp public mostRecentDesignConceptVersion: number;
    @DtoProp public canApplyConcept: boolean;
    @DtoProp public canProcessVariations: boolean;
    @DtoProp public hasEstimatedPriceRevisionOnAppliedConcept: boolean;
    @DtoProp public hasExpiredPriceRevisionOnAppliedConcept: boolean;
    @DtoProp public previousClientSaleLost: boolean;
    @DtoProp public hasExtraToClientVariations: boolean;
    @DtoProp public hasPracticalCompletionDate: string;
    @DtoProp public hasPracticalCompletion: boolean;
    @DtoProp public practicalCompletionByName: string;
    @DtoProp public hasYourBuildProgrammeDate: string;
    @DtoProp public hasYourBuildProgramme: boolean;
    @DtoProp public isHandedOverDate: string;
    @DtoProp public isHandedOver: boolean;
    @DtoProp public hasBuildProgrammeTemplateApplied: boolean;
    @DtoProp public latestScheme: IRevisionStatusDateDto;
    @DtoProp public latestPriceRevision: IRevisionStatusDateDto;
    @DtoProp public rfis: IRevisionStatusDateDto[];
    @DtoProp public handedOverByName: string;
    @DtoProp public constructionManagerRoleId: ConstructionManagerRoleEnumId;
    @DtoProp public constructionManagerRoleLabel: string;
    @DtoProp public houseAndLandBrochure: {
        documentEntityId: number;
        documentId: number;
    };
    @DtoProp public unitConstructionOrder: number;
    @DtoProp public isPrimaryUnit: boolean;
    @DtoProp public latestCompleteDesignSchemeNumber: string;
    @DtoProp public hasExternalLandVendor: boolean;
    @DtoProp public hasScheduleSpecTemplate: boolean;
    @DtoProp public lotHeading: string;
    @DtoProp public lotDescription: string;

    @DtoProp public alternativePrice: number;
    @DtoProp public priceOptionId: number;

    public get noteEntityId(): number {
        return this.id;
    }

    public get noteEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    public get documentEntityId(): number {
        return this.id;
    }

    public get documentEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    @Computed() public get isDesignAndBuild(): boolean {
        return this.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild;
    }

    @Computed() public get isHouseAndLand(): boolean {
        return this.contractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand;
    }

    @Computed() public get isDesignAndLand(): boolean {
        return this.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand;
    }

    private get _canGenerateColourYourDreams(): boolean {
        return this.activeDesignConceptId != null
            && !this.canApplyConcept;
    }
    @Computed() public get canGenerateColourYourDreams(): boolean {
        return this._canGenerateColourYourDreams;
    }

    private get _canGenerateLiveSchedule(): boolean {
        return this.activeDesignConceptId != null
            && !this.canApplyConcept
            && this.hasBuildProgrammeTemplateApplied;
    }
    @Computed() public get canGenerateLiveSchedule(): boolean {
        return this._canGenerateLiveSchedule;
    }

    @Computed() public get canRequestDesignScheme(): boolean {
        return (this.hasSpecTemplate || !this.hasAnyDesignSchemes)
            && (!this.currentDesignScheme ? true :
                (this.currentDesignScheme.statusId === DESIGN_SCHEME_STATUS_ENUM.Completed
                    || this.currentDesignScheme.statusId === DESIGN_SCHEME_STATUS_ENUM.Declined
                    || this.currentDesignScheme.statusId === DESIGN_SCHEME_STATUS_ENUM.Cancelled))
            && (this.hasActiveDesignSchemes !== true);
    }

    @Computed() public get canGenerateReports(): boolean {
        return this._canGenerateColourYourDreams ||
            this.canGenerateLeadSiteEvaluationForm ||
            this._canGenerateLiveSchedule;
    }

    @Computed() public get hasSpecTemplate(): boolean {
        return !!this.specTemplateId;
    }

    @Computed() public get hasFirstScheme(): boolean {
        return this.jobStatus === LOT_JOB_STATUS_ENUM.Scheme ||
            this.jobStatus === LOT_JOB_STATUS_ENUM.Concepts ||
            this.jobStatus === LOT_JOB_STATUS_ENUM.PreConsent ||
            this.jobStatus === LOT_JOB_STATUS_ENUM.WorkingDrawings ||
            this.jobStatus === LOT_JOB_STATUS_ENUM.Consent ||
            this.jobStatus === LOT_JOB_STATUS_ENUM.Build ||
            this.jobStatus === LOT_JOB_STATUS_ENUM.Handover;
    }

    @Computed() public get hasCreatedFirstWorkingDrawing(): boolean {
        return this.jobStatus === LOT_JOB_STATUS_ENUM.WorkingDrawings ||
            this.jobStatus === LOT_JOB_STATUS_ENUM.Consent ||
            this.jobStatus === LOT_JOB_STATUS_ENUM.Build ||
            this.jobStatus === LOT_JOB_STATUS_ENUM.Handover;
    }

    @Computed() public get hasPreconsentDocument(): boolean {
        return this.jobStatus === LOT_JOB_STATUS_ENUM.PreConsent ||
            this.jobStatus === LOT_JOB_STATUS_ENUM.WorkingDrawings ||
            this.jobStatus === LOT_JOB_STATUS_ENUM.Consent ||
            this.jobStatus === LOT_JOB_STATUS_ENUM.Build ||
            this.jobStatus === LOT_JOB_STATUS_ENUM.Handover;
    }

    @Computed() public get hasAppliedDesignConcept(): boolean {
        return !!this.activeDesignConceptId;
    }

    @Computed() public get hasUnits(): boolean {
        return this.lotType === LOT_TYPE_ENUM.ApartmentBlock ||
            this.lotType === LOT_TYPE_ENUM.TerracedBlock ||
            this.lotType === LOT_TYPE_ENUM.Duplex;
    }


    constructor(
        sourceData: ILotDto,
        logicService: ILotsLogicService,
    ) {
        super(
            sourceData,
            logicService,
            LotMappedItem,
            {
                financials: {} as ILotFinancialsDto
            }
        );
    }

    public updateContingencyAmount(contingencyAmount: ILotContingencyAmounDto): Observable<ILotContingencyAmounDto> {
        return this.$logicService.updateContingencyAmount(this.id, contingencyAmount);
    }

    public generateDesignAndBuildContractLetter(): Observable<IFileResponse> {
        return this.$logicService.generateDesignAndBuildContractLetter(this.id);
    }

    public generateCreateBookCoverPage1(): Observable<IFileResponse> {
        if (!this.isDesignAndBuild) {
            return;
        }
        return this.$logicService.generateCreateBookCoverPage1(this.id);
    }

    public getHasIncompleteLotSpecItems(): Observable<boolean> {
        return this.$logicService.getHasIncompleteLotSpecItems(this.id);
    }

    public generateCreateBookCoverPage2(): Observable<IFileResponse> {
        if (!this.isDesignAndBuild) {
            return;
        }
        return this.$logicService.generateCreateBookCoverPage2(this.id);
    }

    public generateFinalLotSpec(): Observable<IFileResponse> {
        return this.$logicService.generateFinalLotSpec(this.id, this.hasScheduleSpecTemplate);
    }

    public generateLotSpecReport(): Observable<IFileResponse> {
        return this.$logicService.generateLotSpecReport(this.id, this.lotSpecVersion);
    }

    public generateSiteEvaluation(): Observable<IFileResponse> {
        if (!this.canGenerateLeadSiteEvaluationForm) {
            return;
        }
        return this.$logicService.generateSiteEvaluation(this.id);
    }

    public generateClientPropertyInformation(): Observable<IFileResponse> {
        return this.$logicService.generateClientPropertyInformation(this.id);
    }

    public generatePropertyInformation(): Observable<IFileResponse> {
        return this.$logicService.generatePropertyInformation(this.id);
    }

    public generateDraftCreateSchedule(): Observable<IFileResponse> {
        return this.$logicService.generateDraftSimCreateSchedule(this.id, this.clientSaleId);
    }

    public generateHLFlyer(): Observable<IFileResponse> {
        return this.$logicService.generateHLFlyer(this.id);
    }

    public generateColourYourDreams(): Observable<IFileResponse> {
        if (this.hasScheduleSpecTemplate) {
            return this.$logicService.generateSimColourSchedule(this.id);
        } else {
            return this.$logicService.generateColourYourDreams(this.id);
        }
    }

    public generateCostXItemsReport(lotSpecVersion?: number): Observable<IFileResponse> {
        return this.$logicService.generateExternalSystemExportReport(this.id, ExternalSystemEnumId.CostX, lotSpecVersion);
    }

    public getAvailableSpecTemplates(): Observable<ISkinnySpecTemplateDto[]> {
        return this.$logicService.getAvailableSpecTemplates(this.id);
    }

    public getTakeOffs(): Observable<ITakeOffDtoSummary[]> {
        return this.$logicService.getTakeOffs(this.id);
    }

    public getLotActualBuildCost(): Observable<number> {
        return this.$logicService.getLotActualBuildCost(this.id);
    }
}
