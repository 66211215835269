<button
    mat-icon-button
    color="accent"
    class="collapse-button"
    (click)="collapsed = !collapsed"
    [matTooltip]="collapsed ? 'Show Favourite Views' : 'Hide Favourite Views'"
>
    <mat-icon class="collapse-icon">
        {{ collapsed ? 'chevron_right' : 'chevron_left' }}
    </mat-icon>
</button>

<div class="side-panel" [ngClass]="{ 'expanded': !collapsed }">
    <ng-container *ngIf="!collapsed">
        <mat-sidenav mode="side" opened class="sidenav flex-row">
            <mat-selection-list
                [multiple]="false"
                (selectionChange)="selectView($event.options[0].value)"
                [hideSingleSelectionIndicator]="true"
            >
                <mat-list-option *ngFor="let view of favoriteViews"
                                 [value]="view"
                                 [selected]="view.id == selectedId"
                >
                    <div class="start-center list-item view-line">
                        <span class="center" title="{{ view.name }}">
                            {{ view.name | cbTruncate:18 }}
                        </span>
                        <div class="button-group">
                            <button
                                mat-icon-button
                                (click)="editClick(view, $event)"
                                class="icon-button settings-icon"
                            >
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button
                                mat-icon-button
                                (click)="onDeleteView(view, $event)"
                                class="icon-button"
                            >
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>

                    <mat-divider [inset]="true"> </mat-divider>
                </mat-list-option>
            </mat-selection-list>

            <button mat-button (click)="addNewView()" class="flex-row list-item add-new-button flex start-center">
                Add New View
            </button>
        </mat-sidenav>
    </ng-container>
</div>
