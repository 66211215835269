<div class="cb-margin-top cb-margin-bottom flex-col">

    <!-- Bedroom -->
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x flex-row start-center">
        <mat-icon class="cb-grey">
            hotel
        </mat-icon>
        <div class="custom-slider">
            <ngx-slider [(value)]="searchData.numberOfBedrooms"
                        [(ngModel)]="searchData.numberOfBedrooms"
                        [options]="optionsBedRoom"
                        (ngModelChange)="filterUpdated()"></ngx-slider>
        </div>
    </div>

    <!-- Bathroom -->
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x flex-row start-center">
        <mat-icon class="cb-grey">
            hot_tub
        </mat-icon>
        <div class="custom-slider">
            <ngx-slider [(value)]="searchData.numberOfBathrooms"
                        [(ngModel)]="searchData.numberOfBathrooms"
                        [options]="optionsBathRoom"
                        (ngModelChange)="filterUpdated()"></ngx-slider>
        </div>
    </div>

    <!-- Living -->
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x flex-row start-center">
        <mat-icon class="cb-grey">
            weekend
        </mat-icon>
        <div class="custom-slider">
            <ngx-slider [(value)]="searchData.numberOfLivingRooms"
                        [(ngModel)]="searchData.numberOfLivingRooms"
                        [options]="optionsLiving"
                        (ngModelChange)="filterUpdated()"></ngx-slider>
        </div>
    </div>

    <!-- Garages -->
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x flex-row start-center">
        <mat-icon class="cb-grey">
            directions_car
        </mat-icon>
        <cb-select class="cb-margin-left-2x cb-margin-right-2x flex"
                   name="garageType"
                   label="Garages"
                   [options]="garageTypes"
                   [(ngModel)]="searchData.numberOfGarages"
                   (ngModelChange)="filterUpdated()">
        </cb-select>
    </div>

    <!-- Floor Area -->
    <div *ngIf="floorAreasLoaded"
         class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x flex-row start-center">
        <mat-icon class="cb-grey"
                  matTooltip="Floor Area">
            border_clear
        </mat-icon>
        <div class="custom-slider">
            <ngx-slider [(value)]="minValue"
                        [(highValue)]="maxValue"
                        [options]="options"
                        (valueChange)="floorAreaChange()"
                        (highValueChange)="floorAreaChange()"></ngx-slider>
        </div>
    </div>

    <!-- Sort By -->
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x flex-row start-center">
        <mat-icon class="cb-grey">
            sort
        </mat-icon>
        <cb-select class="cb-margin-left-2x cb-margin-right-2x flex"
                   name="order"
                   label="Sort By"
                   [options]="sortingOptions"
                   [(ngModel)]="searchData.order"
                   (ngModelChange)="filterUpdated()">
        </cb-select>
    </div>

    <!-- List View -->
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x flex-row start-center">
        <cb-checkbox label="List View"
                     name="enableListView"
                     [(ngModel)]="searchData.enableListView"
                     (ngModelChange)="filterUpdated()">
        </cb-checkbox>
    </div>
    <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x flex-row end-center">
        <button mat-stroked-button
                (click)="clearAll()">
            <mat-icon>
                clear_all
            </mat-icon>
            &nbsp;Clear Filter
        </button>
    </div>
</div>
