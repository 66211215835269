<cb-dialog dialogHeading="{{isEdit() ? 'Edit Change Details' : 'Add Change Details'}}">
    <form class="flex-col flex"
          #createChangeForm="ngForm">
        <span class="cb-margin-bottom"
              *ngIf="isEdit()">
            <strong>Change #{{mappedItem.changeNumber}}</strong>
        </span>
        <cb-select label="Change Payer"
                   name="costNature"
                   [(ngModel)]="mappedItem.costNature"
                   [options]="costNatures"
                   [required]="true"
                   [disabled]="mappedItem.quoteId > 0">
        </cb-select>
        <cb-info-message *ngIf="mappedItem.quoteId > 0"
                         [message]="'Change Payer cannot be changed for Change Records that were generated for a Quote'"
                         [icon]="'warning'"></cb-info-message>
        <cb-business-accounts-input-find-cards *ngIf="isExtraToSupplier()"
                                               label="Supplier"
                                               [(ngModel)]="selectedBusinessAccount"
                                               name="businessAccount"
                                               [text]="selectedBusinessAccount?.label"
                                               [required]="true">
        </cb-business-accounts-input-find-cards>
        <cb-select label="Source of Change"
                   name="changeSource"
                   [(ngModel)]="mappedItem.changeSource"
                   [options]="sourcesOfChange"
                   [required]="true">
        </cb-select>
        <cb-select *ngIf="isCouncilRequest()"
                   label="Council RFI"
                   name="councilRfiId"
                   [(ngModel)]="mappedItem.councilRfiId"
                   [options]="councilRfis"
                   [required]="true">
        </cb-select>
        <cb-text-area label="Details"
                      name="changeDetails"
                      [(ngModel)]="mappedItem.changeDetails"
                      [maxlength]="1024"
                      [required]="true"></cb-text-area>
        <cb-user-autocomplete-scroller class="cb-margin-top flex"
                                       label="Change Owner"
                                       name="owner"
                                       [(ngModel)]="selectedOwner"
                                       [requireMatch]="true"
                                       [matchProps]="['id']"
                                       [active]="true"
                                       [required]="true"
                                       (change)="ownerChanged()">
        </cb-user-autocomplete-scroller>
        <cb-select *ngIf="isAdd() && selectedOwnerteams?.length > 0"
                   label="Team"
                   name="team"
                   [(ngModel)]="mappedItem.teamCode"
                   [options]="selectedOwnerteams"
                   [required]="true"
                   (change)="teamSelected()"
                   valueProp="key"></cb-select>
        <cb-checkbox name="qsReviewRequired"
                     label="QS Review Required"
                     [(ngModel)]="qsReviewRequired"
                     [disabled]="!currentUserIsInQS()"
                     (change)="qsRequiredChanged()"></cb-checkbox>
        <cb-user-autocomplete-scroller class="flex"
                                       *ngIf="qsReviewRequired"
                                       label="Reviewer"
                                       name="reviewer"
                                       [(ngModel)]="selectedReviewer"
                                       [requireMatch]="true"
                                       [matchProps]="['id']"
                                       [team]="QS_TEAM"
                                       [active]="true"
                                       [required]="true">
        </cb-user-autocomplete-scroller>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    [disabled]="createChangeForm.pristine || createChangeForm.invalid"
                    (cbClick)="save()"
                    [clickLimit]="1">{{ isEdit() ? 'Save' : 'Add'
                }}</button>
            <button mat-raised-button
                    (click)="
                    cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
