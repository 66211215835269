<div class="manage-scheme-container flex flex-row">
    <div class="cb-margin flex-col flex"
         *cbWaitFor="mappedItem$$ | async">
        <cb-design-scheme-action-bar [(mappedItem)]="mappedItem"
                                     [lotMappedItem]="lotMappedItem"
                                     (goBack)="goBack.emit()"></cb-design-scheme-action-bar>
        <div class="card-container flex-row flex">
            <div class="flex-col flex-20">
                <cb-design-scheme-side-bar-card class="cb-margin-top flex flex-row"
                                                [mappedItem]="mappedItem"></cb-design-scheme-side-bar-card>
            </div>
            <div class="flex-col flex">
                <cb-manage-scheme-tabs [lotMappedItem]="lotMappedItem"
                                       [mappedItem]="mappedItem"
                                       class="cb-margin-top cb-margin-left flex flex-row"
                                       [selectedIndex]="lastSelectedTabIndex"
                                       (selectedTabChanged)="storeLastIndex($event)"></cb-manage-scheme-tabs>
            </div>
        </div>
    </div>
</div>
