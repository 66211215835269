import {Component, Inject, OnInit} from '@angular/core';
import {BaseDialogFormViewDirective} from "@app/shared/base-views/base-dialog-form-view.directive";
import {IPurchaseOrderDto, IPurchaseOrderMappedItem} from "@app/logic/purchase-orders";
import {IPurchaseOrdersLogicService} from "@app/logic/purchase-orders/interfaces/i.purchase-orders.logic.service";
import {Observable} from "rxjs";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {SelectVariationDialogComponent} from "@app/views/lot/build/orders/select-variation/select-variation.component";
import {ToastService} from "@app/core/services/toast/toast.service";
import {LotSpecItemColourYourDreamsLogicService} from "@app/logic/lot-spec/lot-spec.logic.service";
import {ILotSpecItemColourYourDreamsMappedItem} from "@app/logic/lot-spec/interfaces/i.lot-spec.mapped";
import _ from 'lodash';
import {LotPermissions} from "@app/core/permissions";
import {ILotSpecCreateItemUpdateItemDto} from "@classictechsolutions/hubapi-transpiled-enums";
import { MatCheckboxChange } from '@angular/material/checkbox';

interface IData {
    lotId: number;
    specVersion: IPurchaseOrderMappedItem;
    isLotSpecScheduleItem: boolean;
}

interface IGroup {
    id: number;
    name: string;
    sortOrder: number;
}

interface IGroupWithExpansion {
    id: number;
    name: string;
    expanded: boolean;
    sortOrder: number;
}

interface IFlagChangedItem {
    id: number;
    showInCreateSchedule: boolean;
}


@Component({
    selector: 'cb-manage-lot-spec-create-schedule-item',
    templateUrl: './manage-lot-spec-create-schedule-item-dialog.component.html',
    styleUrls: ['./manage-lot-spec-create-schedule-item-dialog.component.scss']
})
export class ManageLotSpecCreateScheduleItemDialogComponent
    extends BaseDialogFormViewDirective<IPurchaseOrderDto, IPurchaseOrderMappedItem, IPurchaseOrdersLogicService>
    implements OnInit {
    public static readonly MIN_WIDTH = '70%';

    public groups: IGroup[] = [];
    public groupedItems;
    public groupWithExpansion: IGroupWithExpansion[] = [];

    public finishedLoading = false;
    public expandAll = true;

    public readonly displayedColumns: string[] = ['productDisplay'];

    public flagChangedItemToSave: Array<IFlagChangedItem> = [];

    constructor(
        public readonly dialogRef: MatDialogRef<SelectVariationDialogComponent>,
        public toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly lotSpecItemColourYourDreamsLogicService: LotSpecItemColourYourDreamsLogicService,
        private readonly lotPermissions: LotPermissions,
    ) {
        super(dialogRef, toastService);
    }


    public ngOnInit(): void {
        if (this.data.isLotSpecScheduleItem) {
            this.lotSpecItemColourYourDreamsLogicService.getMappedLotSpecSheduleItemColorSchedule(this.data.lotId)
                .subOnce(this.handleLoadItems);
        }
    }


    private readonly handleLoadItems = (items: ILotSpecItemColourYourDreamsMappedItem[]): void => {
        this.groupedItems = _.groupBy(items, 'specGroupName');

        items.forEach(element => {

            if (!(this.groups.some(x => x.id === element.specGroupId))) {
                const group = {name: element.specGroupName, id: element.specGroupId, sortOrder: element.specGroupOrder};
                this.groups.push(group);
            }
        });

        this.expandCollapseAll();
        this.finishedLoading = true;
    };

    public expandCollapseAll(): void {
        this.expandAll = !this.expandAll;
        this.groupWithExpansion = [];

        this.groups.forEach(
            item => {
                const setItem: IGroupWithExpansion = {
                    expanded: this.expandAll,
                    id: item.id,
                    name: item.name,
                    sortOrder: item.sortOrder,
                };
                this.groupWithExpansion.push(setItem);
            }
        );

    }

    public getItemsOnThisGroup(groupName: string): ILotSpecItemColourYourDreamsMappedItem[] {
        return this.groupedItems[groupName];
    }

    public getItemsSizeOnThisGroup(groupName: string): number {
        return (this.groupedItems[groupName]).length;
    }

    public saveMethod(): Observable<ILotSpecItemColourYourDreamsMappedItem[]> {
        const updateItems: ILotSpecCreateItemUpdateItemDto = {lotSpecItemWithCreateScheduleFlags: this.flagChangedItemToSave};

        if (this.data.isLotSpecScheduleItem) {
            return this.lotSpecItemColourYourDreamsLogicService.updateCreateSchedule(this.data.lotId, updateItems);
        }
    }

    public readonly handleSaveSuccess = (): void => {
        this.flagChangedItemToSave = [];
    };
    protected readonly handleNext = (): void => {
        this.toastSerivce.saveSuccess();
        this.dialogRef.close(true);
    };

    public onCreateScheduleFlagChange(eve: MatCheckboxChange, item: ILotSpecItemColourYourDreamsMappedItem): void {
        const isChangeAlreadyOnArray = this.flagChangedItemToSave.some(x => x.id === item.id);
        let indexOfRecentItem;

        if (isChangeAlreadyOnArray) {
            const recentItem = _.find(this.flagChangedItemToSave, source => source.id === item.id);
            indexOfRecentItem = this.flagChangedItemToSave.indexOf(recentItem);
            this.flagChangedItemToSave[indexOfRecentItem].showInCreateSchedule = eve.checked;

        } else {
            const itemToAdd = {id: item.id, showInCreateSchedule: eve.checked};
            this.flagChangedItemToSave.push(itemToAdd);
        }
    }

    public isFormEnabled(): boolean {
        return !this.saveInProgress && (this.flagChangedItemToSave.length > 0);
    }

    public canChangeSpecification(): boolean {
        return this.lotPermissions.canChangeSpecification();
    }
}
