import { AGE_RANGES_ENUM, CONTACT_METHOD_ENUM, CONTACT_STATUS_ENUM, IAddressDto, SALUTATION_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { Component, Input, OnInit } from '@angular/core';
import { IContactMappedItem } from '@app/logic/contacts/interfaces/i.contact.mapped';

@Component({
    selector: 'cb-contact-details',
    templateUrl: './contact-details.component.html',
    styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent implements OnInit {

    @Input() public contact: IContactMappedItem;

    public CONTACT_STATUS_ENUM = CONTACT_STATUS_ENUM;
    public SALUTATION_ENUM = SALUTATION_ENUM;
    public CONTACT_METHOD_ENUM = CONTACT_METHOD_ENUM;
    public AGE_RANGES_ENUM = AGE_RANGES_ENUM;

    public postalAddress: IAddressDto;

    constructor() {
    }

    public ngOnInit(): void {
        if (this.contact.postalAddress?.id) {
            this.postalAddress = this.contact.postalAddress;
        } else {
            this.postalAddress = this.contact.physicalAddress;
        }
    }

    public getGender(): string {
        if (this.contact.gender === 'M') {
            return 'Male';
        } else if (this.contact.gender === 'F') {
            return 'Female';
        } else {
            return '';
        }
    }

    public getFullName(): string {
        return `${this.contact.firstName} ${this.contact.lastName}`;
    }
}
