import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { LotsLogicService } from '@app/logic/lots';
import { ReleaseStagesLogicService } from '@app/logic/release-stages/release-stages.logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import {
    IProjectReleaseLotDto,
    LotJobStatusEnumId,
    LotTypeEnumId,
    LOT_JOB_STATUS_ENUM,
    LOT_TYPE_ENUM,
    MarketStatusEnumId,
    MARKET_STATUS_ENUM, IBuildTypeDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { Dictionary, forEach, keys, orderBy, pickBy } from 'lodash';
import { Observable } from 'rxjs';

interface IData {
    projectReleaseStageId: number;
    projectId: number;
}

@Component({
    selector: 'cb-project-release-stage-release-lots-to-sales-dialog',
    templateUrl: './release-lots-to-sales-dialog.component.html',
    styleUrls: ['./release-lots-to-sales-dialog.component.scss']
})
export class ProjectReleaseStageReleaseLotsToSalesDialogComponent extends BaseDialogFormViewDirective<any, any, any> implements OnInit {
    public static readonly MIN_WIDTH = '70%';
    public projectReleaseStageId: number;
    public projectId: number;
    public selectedMarketStatus: number;
    public selectedLots: Dictionary<any> = {};
    public lots: IProjectReleaseLotDto[];
    public lotTypes: IEnumLookup<LotTypeEnumId>[];
    public buildType: IBuildTypeDto;
    public loaded = false;
    public jobStatuses: IEnumLookup<LotJobStatusEnumId>[];
    public marketStatuses: IEnumLookup<MarketStatusEnumId>[];
    public allMarketStatuses: IEnumLookup<MarketStatusEnumId>[];
    public MARKET_STATUS_ENUM = MARKET_STATUS_ENUM;
    public LOT_JOB_STATUS_ENUM = LOT_JOB_STATUS_ENUM;
    constructor(
        private readonly lotLogicService: LotsLogicService,
        private readonly releaseStagesLogicService: ReleaseStagesLogicService,
        public readonly dialogRef: MatDialogRef<ProjectReleaseStageReleaseLotsToSalesDialogComponent>,
        private readonly toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {

        super(dialogRef, toastService);
        this.projectReleaseStageId = data.projectReleaseStageId;
        this.projectId = data.projectId;
    }

    public ngOnInit(): void {
        this.lotTypes = LOT_TYPE_ENUM.toLookup();
        this.jobStatuses = LOT_JOB_STATUS_ENUM.toLookup();
        this.allMarketStatuses = MARKET_STATUS_ENUM.toLookup({
            transform: (results) => {
                this.marketStatuses = results.filter(status => status.id !== MARKET_STATUS_ENUM.Sold);
                return results;
            }
        });

        this.releaseStagesLogicService.getProjectReleaseStageLots(this.projectReleaseStageId).subOnce((results) => {
            this.lots = orderBy(results, 'lotNumber', 'asc');
            this.lots = this.lots.filter(lot => lot.marketStatus !== MARKET_STATUS_ENUM.Sold);
            this.loaded = true;
        });
    }

    public hasLots() {
        return keys(pickBy(this.selectedLots, (value, key) => value)).length > 0;
    }

    public clicked = (lotId: number) => {
        this.selectedLots[lotId] = !this.selectedLots[lotId];
    };

    public canCheck(lot: IProjectReleaseLotDto) {
        if (lot.lotType !== LOT_TYPE_ENUM.Standalone &&
            lot.lotType !== LOT_TYPE_ENUM.Land &&
            lot.lotType !== LOT_TYPE_ENUM.Unit
        ) {
            return false;
        }

        if (this.selectedMarketStatus === MARKET_STATUS_ENUM.Available) {
            if (!this.hasAppliedDesignConcept(lot) ||
                lot.hasEstimatedPricingRevisionOnAppliedConcept) {
                return false;
            }
        }

        return !(lot.clientAccountId &&
            (this.selectedMarketStatus !== MARKET_STATUS_ENUM.NotAvailable ||
                lot.marketStatus === MARKET_STATUS_ENUM.NotAvailable));
    }

    public onStatusChange() {
        forEach(this.lots, (lot) => {
            if (!this.canCheck(lot)) {
                this.selectedLots[lot.id] = false;
            }
        });
    }

    public saveMethod(): Observable<any> {
        const lotIds = keys(pickBy(this.selectedLots, (value, key) => value));
        return this.lotLogicService.updateMarketStatus(lotIds, this.selectedMarketStatus);
    }

    public hasAppliedDesignConcept(lot: IProjectReleaseLotDto): boolean {
        return !!lot.activeConceptId;
    }

    public canSave(): boolean {
        return keys(pickBy(this.selectedLots, (value, key) => value)).length > 0;
    }

}
