<cb-dialog dialogHeading="Design Team Workload">
    <div *ngIf="isColumnsLoaded && isWorkloadLoaded"
         style="overflow: auto">
        <table mat-table
               [dataSource]="dataSource"
               class="mat-elevation-z8">

            <!-- Header Designer group -->
            <ng-container matColumnDef="header-row-designer-group"
                          sticky>
                <th mat-header-cell
                    *matHeaderCellDef
                    [attr.colspan]="1">
                </th>
            </ng-container>

            <!-- Header Design Schemes group -->
            <ng-container matColumnDef="header-row-first-group">
                <th mat-header-cell
                    *matHeaderCellDef
                    [style.background-color]="'#4485f366'"
                    [style.font-size]="'14px'"
                    [attr.colspan]="schemeColumns.length">
                    Schemes
                </th>
            </ng-container>

            <!-- Header Pre-Consent Plans group -->
            <ng-container matColumnDef="header-row-second-group">
                <th mat-header-cell
                    *matHeaderCellDef
                    [style.background-color]="'#7bb1f469'"
                    [style.font-size]="'14px'"
                    [attr.colspan]="preConsentColumns.length"> Pre-Consent Plans </th>
            </ng-container>

            <!-- Header Working Drawings group -->
            <ng-container matColumnDef="header-row-third-group">
                <th mat-header-cell
                    *matHeaderCellDef
                    [style.background-color]="'#4485f366'"
                    [style.font-size]="'14px'"
                    [attr.colspan]="workingDrawingColumns.length"> Working Drawings </th>
            </ng-container>

            <!-- Sub Header Designer sub-group -->
            <ng-container matColumnDef="designer"
                          sticky>
                <th style="min-width: 150px;"
                    mat-header-cell
                    *matHeaderCellDef
                    [style.text-align]="'left !important'"> Designer </th>
                <td mat-cell
                    [style.text-align]="'left'"
                    *matCellDef="let element">
                    <b>{{element.designer.label}}</b>
                </td>
            </ng-container>

            <!-- Sub Header Design Schemes sub-group -->
            <ng-container *ngFor="let columnName of schemeColumns"
                          [matColumnDef]="columnName">
                <th mat-header-cell
                    [style.background-color]="'#4485f366'"
                    *matHeaderCellDef>{{ columnName.substring(1) }}</th>
                <td mat-cell
                    [style.background-color]="'#4485f366'"
                    *matCellDef="let element">{{element.schemes[columnName.substring(1)] | cbDasher}}</td>
            </ng-container>

            <!-- Sub Header Design Pre Consent sub-group -->
            <ng-container *ngFor="let columnName of preConsentColumns"
                          [matColumnDef]="columnName">
                <th mat-header-cell
                    [style.background-color]="'#7bb1f469'"
                    *matHeaderCellDef>{{ columnName.substring(1) }}</th>
                <td mat-cell
                    [style.background-color]="'#7bb1f469'"
                    *matCellDef="let element">{{element.preconsentPlans[columnName.substring(1)] | cbDasher}}</td>
            </ng-container>

            <!-- Sub Header Design Working Drawing sub-group -->
            <ng-container *ngFor="let columnName of workingDrawingColumns"
                          [matColumnDef]="columnName">
                <th mat-header-cell
                    [style.background-color]="'#4485f366'"
                    *matHeaderCellDef>{{ columnName.substring(1) }}</th>
                <td mat-cell
                    [style.background-color]="'#4485f366'"
                    *matCellDef="let element">{{element.workingDrawings[columnName.substring(1)] | cbDasher}}</td>
            </ng-container>

            <!-- Table Rows -->
            <tr mat-header-row
                *matHeaderRowDef="['header-row-designer-group','header-row-first-group', 'header-row-second-group', 'header-row-third-group']">
            </tr>
            <tr mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <cb-loading-spinner *ngIf="!(isColumnsLoaded && isWorkloadLoaded)"></cb-loading-spinner>
</cb-dialog>
