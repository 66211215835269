<form class="flex-col flex"
      #searchClientSalesForm="ngForm">
    <mat-card class="cb-margin start flex-col flex"
              *cbWaitFor="userCacheItem.silentData">
        <div class="flex-row flex">
            <div class="cb-margin start-center gt-sm-row flex">
                <div class="flex-col flex">
                    <div class="start-center flex-row flex">
                        <mat-icon>
                            search
                        </mat-icon>
                        <mat-form-field class="cb-margin-left cb-margin-right flex">
                            <input matInput
                                   type="text"
                                   name="search"
                                   placeholder="Keywords"
                                   [(ngModel)]="userCacheItem.data.query"
                                   (ngModelChange)="searchQueryUpdate.next($event)"/>
                        </mat-form-field>
                    </div>
                </div>
                <div class="filter-selects cb-margin flex-col flex"
                     *ngIf="jobStatuses?.length > 0">
                    <cb-select [required]="true"
                               name="jobStatus"
                               label="Job Status"
                               [options]="jobStatuses"
                               [(ngModel)]="userCacheItem.data.jobStatus">
                    </cb-select>
                </div>
                <div class="filter-selects cb-margin flex-col flex"
                     *ngIf="contractTypes?.length > 0 && userCacheItem?.data?.contractTypes">
                    <cb-select-list class="flex"
                                    label="Contract Type"
                                    [allOptions]="contractTypes"
                                    [multiple]="true"
                                    [value]="userCacheItem?.data?.contractTypes"
                                    (selectChanged)="contractTypesChanged($event)">
                    </cb-select-list>
                </div>
                <div class="filter-selects cb-margin flex-col flex"
                     *ngIf="buildingConsultants?.length > 0 && userCacheItem?.data?.buildingConsultants">
                    <cb-select-list class="flex"
                                    label="Building Consultant"
                                    [allOptions]="buildingConsultants"
                                    [multiple]="true"
                                    [value]="userCacheItem?.data?.buildingConsultants"
                                    (selectChanged)="selectedBuildConsultantIdsChanged($event)">
                    </cb-select-list>
                </div>
                <div class="filter-selects cb-margin flex-col end-center"
                     *ngIf="canIncludeLost">
                    <cb-checkbox name="includeSaleLost"
                                 label="Include Sale Lost"
                                 class="SaleLostCheckbox"
                                 [(ngModel)]="userCacheItem.data.includeLostJobs">
                    </cb-checkbox>
                </div>
                <span class="flex"></span>
                <button mat-raised-button
                        color="primary"
                        (cbClick)="exportToCsv()">
                    Export to CSV
                </button>
            </div>
        </div>
        <div class="flex-wrap">
            <div class="flex-col lt-lg-100 flex-25">
                <div class="cb-margin-right-2x flex-row">
                    <cb-select label="Region"
                               class="cb-margin-right-2x flex"
                               name="selectedRegionId"
                               [(ngModel)]="userCacheItem.silentData.selectedRegionId"
                               (change)="onRegionSelected(true)"
                               [options]="regions">
                    </cb-select>
                    <button mat-icon-button
                            [disabled]="!userCacheItem.silentData.selectedRegionId"
                            (click)="clearFilter()">
                        <mat-icon>
                            remove_circle
                        </mat-icon>
                    </button>
                </div>
                <cb-select label="District"
                           class="cb-margin-right-2x"
                           name="selectedDistrictId"
                           [(ngModel)]="userCacheItem.silentData.selectedDistrictId"
                           (change)="onDistrictSelected(true)"
                           [options]="districts">
                </cb-select>
                <cb-select label="Areas"
                           class="cb-margin-right-2x"
                           name="selectedAreaId"
                           [(ngModel)]="userCacheItem.silentData.selectedAreaId"
                           (change)="onAreaSelected(true)"
                           [options]="areas">
                </cb-select>
            </div>
            <div class="lt-lg-100 flex-75">
                <cb-table class="cb-margin-top flex"
                          label="Client Sales"
                          maxHeightOffset="250"
                          [infiniteScrollEnabled]="infiniteScrollEnabled"
                          [fetch]="fetchResults()"
                          [loaded]="true"
                          [queryUpdated]="queryUpdate"
                          [(results)]="results"
                          [(currentPage)]="currentPage">
                    <thead cbTableHead>
                        <tr>
                            <td></td>
                            <td>
                                #
                            </td>
                            <td>
                                Job Number
                            </td>
                            <td>
                                Lot Number
                            </td>
                            <td>
                                Client Account
                            </td>
                            <td>
                                Region
                            </td>
                            <td>
                                Status
                            </td>
                            <td>
                                Building Consultant
                            </td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody cbTableBody>
                        <tr *ngFor="let clientSale of results">
                            <td *ngIf="!shouldShowWarning(clientSale)"></td>
                            <cb-td-icon *ngIf="shouldShowWarning(clientSale)"
                                        value="warning"
                                        class="cb-warn">
                            </cb-td-icon>
                            <cb-td-text [value]="clientSale?.saleNumber"></cb-td-text>
                            <cb-td-text [value]="clientSale?.lotJobNumber || 'None'"></cb-td-text>
                            <cb-td-text [value]="clientSale?.lotNumber"></cb-td-text>
                            <cb-td-text [value]="clientSale?.clientAccountName"></cb-td-text>
                            <cb-td-text [value]="clientSale?.region"></cb-td-text>
                            <cb-td-enum [value]="clientSale?.status"
                                        [enum]="CLIENT_SALE_STATUS_ENUM">
                            </cb-td-enum>
                            <cb-td-text [value]="clientSale?.buildingConsultant"></cb-td-text>
                            <td cbTableShrinkCol
                                cbTableStickyCol="end">
                                <button mat-icon-button
                                        *ngIf="hasPermissionsToViewClientSale()"
                                        (click)="viewClientSale(clientSale, $event)">
                                    <mat-icon>
                                        pageview
                                    </mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </cb-table>
            </div>
        </div>
    </mat-card>
</form>
