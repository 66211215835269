import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ToastService} from '@app/core/services/toast/toast.service';
import {IDesignSchemeNotificationStageMappedItem} from '@app/logic/design-scheme-notification-stages';
import {DesignSchemeNotificationStageMappedItem} from '@app/logic/design-scheme-notification-stages/design-scheme-notification-stage.mapped';
import {IDesignSchemeNotificationStageLogicService} from '@app/logic/design-scheme-notification-stages/interfaces/i.design-scheme-notification-stage.logic.service';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import { IIntLookupDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/IntLookupDto';

interface IData {
    mappedItem: DesignSchemeNotificationStageMappedItem;
}

@Component({
    selector: 'cb-design-scheme-notifications-dialog',
    templateUrl: './design-scheme-notifications-dialog.component.html',
    styleUrls: ['./design-scheme-notifications-dialog.component.scss']
})
export class DesignSchemeNotificationsDialogComponent
    extends BaseDialogFormViewDirective<IIntLookupDto, IDesignSchemeNotificationStageMappedItem, IDesignSchemeNotificationStageLogicService> {
    public static readonly MIN_WIDTH = '25%';

    public mappedItem: DesignSchemeNotificationStageMappedItem;
    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<DesignSchemeNotificationsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
    }
}
