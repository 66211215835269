import { Component, OnInit, Input } from '@angular/core';
import { IBuildTypeDto, ILotDto, LOT_CONTRACT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { BuildTypeLogicService } from '@app/logic/build-type';
import { ILeadLotInterestDto } from '@app/logic/leads/interfaces/i.lead.lot.interest.dto';
import { ILeadMappedItem } from '@app/logic/leads/interfaces/i.lead.mapped';
import { find, map } from 'lodash';
import { LotsLogicService } from '@app/logic/lots';
import { Observable, of } from 'rxjs';
import { LeadPermissions } from '@app/core/permissions';
import { ILotInterestPermissions } from '@app/core/permissions/base.permissions';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { AddLotDialogComponent } from '../lead-house-land-interests-card';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { Location } from '@angular/common';
import { LeadLogicService } from '@app/logic/leads';

@Component({
    selector: 'cb-lead-design-build-card',
    templateUrl: './lead-design-build-card.component.html',
    styleUrls: ['./lead-design-build-card.component.scss']
})
export class LeadDesignBuildCardComponent implements OnInit {
    @Input() public mappedItem: ILeadMappedItem;
    @Input() public canEdit: boolean;
    public buildType: IBuildTypeDto;
    public lot: ILotDto;
    public permissions: ILotInterestPermissions;

    public get designAndBuildInterest(): ILeadLotInterestDto | any {
        return find(this?.mappedItem?.interests, { isPrimary: true, contractType: LOT_CONTRACT_TYPE_ENUM.DesignAndBuild });
    }

    constructor(
        private readonly buildTypeLogic: BuildTypeLogicService,
        private readonly lotLogic: LotsLogicService,
        public leadPermissions: LeadPermissions,
        private readonly leadLogicService: LeadLogicService,
        private readonly cbDialog: CbDialogService,
        private readonly location: Location,
        private readonly navigationService: NavigationService
    ) {
        this.permissions = leadPermissions?.getLotInterestPermissions();
    }

    public ngOnInit(): void {
        this.buildTypeLogic.$getList()
            .subOnce((buildTypes) => {
                this.buildType = buildTypes.find(bt => bt.id === this.designAndBuildInterest?.buildTypeId);
            });
    }

    public edit = (): void => {
        if (!this.canEdit) {
            return;
        }
        const existingInterest = this?.designAndBuildInterest;
        let lotObservable: Observable<ILotDto | any>;
        if (existingInterest && existingInterest?.lotId > 0) {
            lotObservable = this.lotLogic.$getItem(existingInterest?.lotId);
        } else {
            lotObservable = of({
                id: 0,
                lotAddress: {} as any
            });
        }
        lotObservable.subOnce((result) => {
            this.lot = result;
            const existingIds = map(this.mappedItem.interests, (interest) => interest.lot.id);
            this.cbDialog.open(AddLotDialogComponent, {
                data: {
                    existingIds,
                    lotTypeId: LOT_CONTRACT_TYPE_ENUM.DesignAndBuild,
                    editInterest: existingInterest,
                    lot: this.lot,
                    leadMappedItem: this.mappedItem
                },
                minWidth: 600
            })
                .afterClosed()
                .subOnce(dialogResult => {
                    if (dialogResult) {
                        this.mappedItem = this.leadLogicService.$createMappedItem(dialogResult);
                    }
                });
        });
    };
}
