import { Injectable, Injector } from '@angular/core';
import { IPurchaseOrderSearch } from '@app/core/services/user-cache/user-cache-areas';
import { ISearchResult } from '@app/shared/components/search/i.search';
import {
    IPurchaseOrderDocumentDto,
    IPurchaseOrderHistoryDto,
    IVariationDto,
    PurchaseOrderApprovalStatusEnumId,
    PurchaseOrderTypeEnumId
} from '@classictechsolutions/hubapi-transpiled-enums';
import { environment } from '@src/environments/environment';
import { cleanObjectDeep, prefixStr } from 'cb-hub-lib';
import { map, Observable } from 'rxjs';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { IBuildProgrammeActivity } from '../build-programme/interfaces/buildprogramme.model';
import { CreateBuildProgrammeActivityForManualPurchaseOrderDto } from './dtos/create-build-programme-activity-for-manual-po.dto';
import { IPurchaseOrderLineDto } from './interfaces/i.purchase-order-line.dto';
import { IPurchaseOrderDto } from './interfaces/i.purchase-order.dto';
import { IPurchaseOrderMappedItem } from './interfaces/i.purchase-order.mapped';
import { IPurchaseOrdersLogicService } from './interfaces/i.purchase-orders.logic.service';
import { PurchaseOrderMappedItem } from './purchase-order.mapped';

export interface IPurchaseOrderSearchParams {
    query?: string;
    currentPage?: number;
    pageSize?: number;
    status?: number[];
    constructionManagerId?: string[];
    orderType?: PurchaseOrderTypeEnumId;
    lotId?: number;
    onlyCompletable?: boolean;
    supplierId?: number;
    order?: string;
    direction?: string;
}

@Injectable()
export class PurchaseOrdersLogicService
    extends BaseLogicService<IPurchaseOrderDto, IPurchaseOrderMappedItem>
    implements IPurchaseOrdersLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('purchaseorders', PurchaseOrderMappedItem);
    }

    public $getSearchList(params: IPurchaseOrderSearchParams, cleanParamsCheck = (value: any) => value == null): Observable<ISearchResult<IPurchaseOrderDocumentDto>> {
        cleanObjectDeep(params, cleanParamsCheck);
        return this.$http
            .get<ISearchResult<IPurchaseOrderDocumentDto>>(`${this.$baseUri}/search`, params);
    }
    public getAvailableVariations(lotId: number): Observable<Array<IVariationDto>> {
        return this.$http.get<Array<IVariationDto>>(`${this.$baseUri}/${lotId}/availablevariations`);
    }

    public downloadSsrPO(ssrId: number): Observable<any> {
        return this.$http.download(`ssrs/${ssrId}/purchaseorder/download`, 'Downloading Purchase Order...');
    }
    public downloadManualPO(id: number): Observable<any> {
        return this.$http.download(`${this.$baseUri}/${id}/download`, 'Downloading Purchase Order...');
    }

    public exportPOSearchToCSV(searchParams: IPurchaseOrderSearch): Observable<any> {
        return this.$http.download('purchaseorders/search/export', 'Downloading Purchase Order Search...', searchParams);
    }

    public viewPO(purchaseOrderId: number): void {
        const path = ['open-pdf'];
        const documentFetchUrl = `${this.$baseUri}/${purchaseOrderId}/download`;
        const documentName = 'Purchase Order Document';
        window.open(environment.uri + prefixStr(path.join(''), '/') + `?documentFetchUrl=${documentFetchUrl}&documentName=${documentName}`);
    }

    public getMappedSearchList(params: IPurchaseOrderSearchParams): Observable<ISearchResult<IPurchaseOrderMappedItem>> {
        return this.$getSearchList(params)
            .pipe(
                map(result => ({
                    total: result.total,
                    items: result.items.map((i) => this.$createMappedItem(i))
                }))
            );
    }

    public assignvariation(purchaseOrderId: number, variationid: number): Observable<IPurchaseOrderDto> {
        return this.$http.post<IPurchaseOrderDto>(`${this.$baseUri}/${purchaseOrderId}/assignvariation/${variationid}`);
    }

    public unassignvariation(purchaseOrderId: number): Observable<IPurchaseOrderDto> {
        return this.$http.post<IPurchaseOrderDto>(`${this.$baseUri}/${purchaseOrderId}/unassignvariation`);
    }

    public createBuildProgrammeActivityForManualPurchaseOrder(dto: CreateBuildProgrammeActivityForManualPurchaseOrderDto): Observable<IBuildProgrammeActivity> {
        return this.$http
            .post<IBuildProgrammeActivity>(`${this.$baseUri}/${dto.purchaseOrderId}/createbuildprogrammeactivity`, dto);
    }

    public redraftPurchaseOrder(id: number): Observable<IPurchaseOrderDto> {
        return this.$http.post(`${this.$baseUri}/${id}/redraft`);
    }

    public submitPurchaseOrder(id: number): Observable<IPurchaseOrderDto> {
        return this.$http.post<IPurchaseOrderDto>(`${this.$baseUri}/${id}/submit`);
    }

    public unsubmitPurchaseOrder(id: number): Observable<IPurchaseOrderDto> {
        return this.$http.post<IPurchaseOrderDto>(`${this.$baseUri}/${id}/unsubmit`);
    }

    public resendAbmConfirmMessage(id: number): Observable<boolean> {
        return this.$http.post<boolean>(`${this.$baseUri}/${id}/abm/confirm`);
    }

    public submitApprovalDecision(id: number, status: PurchaseOrderApprovalStatusEnumId, comments = ''): Observable<IPurchaseOrderDto> {
        return this.$http.post<IPurchaseOrderDto>(
            `${this.$baseUri}/${id}/approvaldecision`,
            {
                purchaseOrderId: id,
                status,
                comments
            }
        );
    }

    public cancelPurchaseOrder(id: number): Observable<IPurchaseOrderDto> {
        return this.$http.post<IPurchaseOrderDto>(`${this.$baseUri}/${id}/cancel`);
    }

    public saveLineItem(purhcaseOrderId: number, dto: IPurchaseOrderLineDto): Observable<IPurchaseOrderLineDto> {
        return this.$http.post<IPurchaseOrderLineDto>(`${this.$baseUri}/${purhcaseOrderId}/lines`, dto);
    }

    public deleteLineItem(purhcaseOrderId: number, lineId: number): Observable<boolean> {
        return this.$http.delete(`${this.$baseUri}/${purhcaseOrderId}/lines/${lineId}`);
    }

    public getPurchaseOrderHistory(lotId: number, buildProgrammeActivityId: number): Observable<Array<IPurchaseOrderHistoryDto>> {
        return this.$http.get<Array<IPurchaseOrderHistoryDto>>(`${this.$baseUri}/${lotId}/${buildProgrammeActivityId}/history`);
    }

}
