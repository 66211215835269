import { BaseMappedItem } from '../base/base-mapped-item';
import { IBankAccountsLogicService } from './interfaces/i.bank-accounts.logic.service';
import { IBankAccountMappedItem } from './interfaces/i.bank-account.mapped';
import { DtoProp } from '../base/dto-prop.decorator';
import { IBankAccountDetailsDto, IIdAndLabelDto } from '@classictechsolutions/hubapi-transpiled-enums';

export class BankAccountMappedItem
    extends BaseMappedItem<IBankAccountDetailsDto, IBankAccountMappedItem, IBankAccountsLogicService>
    implements IBankAccountMappedItem {
    @DtoProp public id: number;
    @DtoProp public readonly label: string;
    @DtoProp public bankName: string;
    @DtoProp public accountName: string;
    @DtoProp public accountNumber: string;
    @DtoProp public isTrustAccount: boolean;
    @DtoProp public isActive: boolean;
    @DtoProp public allowedBusinessEntities: IIdAndLabelDto[];

    constructor(
        sourceData: IBankAccountDetailsDto,
        logicService: IBankAccountsLogicService
    ) {
        super(sourceData, logicService, BankAccountMappedItem, {
            allowedBusinessEntities: []
        });
    }
}
