import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IDesignComplexityMappedItem } from '@app/logic/design-complexity/interfaces/i.design-complexity.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IDesignComplexityLogicService } from '@app/logic/design-complexity/interfaces/i.design-complexity.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { DesignComplexityMappedItem } from '@app/logic/design-complexity/design-complexity.mapped';
import { IDesignComplexityDto } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    mappedItem: DesignComplexityMappedItem;
}

@Component({
    selector: 'cb-design-complexity-dialog',
    templateUrl: './design-complexity-dialog.component.html',
    styleUrls: ['./design-complexity-dialog.component.scss']
})
export class DesignComplexityDialogComponent extends BaseDialogFormViewDirective<IDesignComplexityDto, IDesignComplexityMappedItem, IDesignComplexityLogicService> {
    public static readonly MIN_WIDTH = '25%';

    public mappedItem: DesignComplexityMappedItem;
    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<DesignComplexityDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
    }
}
