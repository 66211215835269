<cb-dialog dialogHeading="Manage Attribute Items">
    <div class="end-center flex-row">
        <button class="cb-margin-bottom"
                mat-raised-button
                (click)="expandCollapseAll()">
            <span class="start-center flex-row"
                  *ngIf="!allPanelsExpanded()">
                <span>Expand All&nbsp;</span>
                <mat-icon>keyboard_arrow_down</mat-icon>
            </span>
            <span class="start-center flex-row"
                  *ngIf="allPanelsExpanded()">
                <span>Collapse All&nbsp;</span>
                <mat-icon>keyboard_arrow_up</mat-icon>
            </span>
        </button>
    </div>
    <div class="form-container">
        <form class="flex-col flex"
              #form="ngForm">
            <mat-accordion [multi]="true"
                           class="accordion">
                <mat-expansion-panel class="panel"
                                     *ngFor="let group of groupedItems"
                                     [(expanded)]="group.expanded">
                    <mat-expansion-panel-header class="cb-padding-top cb-padding-bottom">
                        <mat-panel-title class="start-center flex-row">
                            <mat-icon class="cb-error cb-help-cursor"
                                      *ngIf="finishedLoading && group.anyEmptyItems()"
                                      matTooltip="Some Items Requiring Attributes">panorama_fish_eye</mat-icon>
                            <mat-icon class="mat-primary cb-help-cursor"
                                      *ngIf="finishedLoading && !group.anyEmptyItems()"
                                      matTooltip="All Item Attributes Set">check_circle</mat-icon>
                            <span class="cb-margin-left mat-body-1">
                                {{group.label}}&nbsp;({{group.items.length}})
                            </span>
                        </mat-panel-title>
                        <mat-panel-description class="end-center flex-row">
                            <button mat-icon-button
                                    (click)="togglePanelPinned(group.id)">
                                <mat-icon [ngClass]="{'cb-accent' : userCacheItem.silentData[group.id]}">pin
                                </mat-icon>
                            </button>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="cb-margin flex-col"
                         *ngFor="let item of group.items; let index = index;">
                        <cb-table [loaded]="true">
                            <thead cbTableHead>
                                <tr>
                                    <td width="50%">
                                        <div class="start-center flex-row">
                                            <mat-icon style="color: white"
                                                      title="Lot Specification Item">
                                                style</mat-icon>
                                            <span class="mat-body-1">&nbsp;
                                                {{item.slotName}} - {{item.offeringName}}</span>
                                        </div>
                                    </td>
                                    <td width="50%"></td>
                                </tr>
                            </thead>
                            <tbody cbTableBody>
                                <tr *ngFor="let restriction of item.restrictions">
                                    <td>
                                        <cb-select [label]="restriction.name"
                                                   [name]="restriction.name + '_option_' + index + '_' + item.lotSpecScheduleItemVersionId"
                                                   [(ngModel)]="restriction.selectedOptionId"
                                                   [options]="restriction.options"
                                                   (change)="!item.$hasChanged && attributeChanged(item)">
                                        </cb-select>
                                    </td>
                                    <td style="font-size: 12px;">
                                        <cb-text-area label="Notes"
                                                      [name]="restriction.name + '_notes_' + index + '_' + item.lotSpecScheduleItemVersionId"
                                                      [(ngModel)]="restriction.notes"
                                                      [maxlength]="256"
                                                      [rows]="1"
                                                      (change)="!item.$hasChanged && attributeChanged(item)">
                                        </cb-text-area>
                                    </td>
                                </tr>
                                <tr *ngIf="item.manualColourEntryRequired">
                                    <td>
                                        <cb-text-area label="Manual Colour Entry"
                                                      [name]="'manualColour_' + item.id + item.slotName + item.lotSpecScheduleItemVersionId"
                                                      [(ngModel)]="item.manualColourName"
                                                      [maxlength]="256"
                                                      [rows]="1"
                                                      (change)="!item.$hasChanged && attributeChanged(item)">
                                        </cb-text-area>
                                    </td>
                                    <td style="font-size: 12px;">
                                        <cb-text-area label="Notes"
                                                      [(ngModel)]="item.manualColourNotes"
                                                      [name]="'manualColourNotes_' + item.id + item.slotName + item.lotSpecScheduleItemVersionId"
                                                      [maxlength]="256"
                                                      [rows]="1"
                                                      (change)="!item.$hasChanged && attributeChanged(item)">
                                        </cb-text-area>
                                    </td>
                                </tr>
                            </tbody>
                        </cb-table>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <div class="cb-margin center-start flex-col flex"
                 *ngIf="finishedLoading && groupedItems?.length < 1">
                <div class="center-center flex-row flex">
                    <cb-info-message message="There are no Items that need an attribute selection for this Lot">
                    </cb-info-message>
                </div>
            </div>
            <cb-loading-spinner class="cb-margin"
                                *ngIf="!finishedLoading"></cb-loading-spinner>
        </form>
    </div>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                (cbClick)="save()"
                [clickLimit]="1"
                [disabled]="form.invalid || form.pristine || !itemsHaveChanged">Save
        </button>
        <button mat-raised-button
                (click)="cancel(form.dirty || itemsHaveChanged)">Cancel</button>
    </footer>
</cb-dialog>
