import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DesignSchemePermissions } from '@app/core/permissions';
import { IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { ILotMappedItem } from '@app/logic/lots';
import { SYSTEM_AREA_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { Subscription } from 'rxjs';
import { ManageSchemeDataService } from '../manage-scheme-view/manage-scheme-data.service';
import { HISTORY_SEARCH_ENTITY } from '@app/shared/enums/history/history-search-entity.enum';

@Component({
    selector: 'cb-manage-scheme-tabs',
    templateUrl: './manage-scheme-tabs.component.html',
    styleUrls: ['./manage-scheme-tabs.component.scss']
})
export class ManageDesignSchemeTabsComponent implements OnDestroy {

    @Output() public selectedTabChanged = new EventEmitter<number>();
    @Input() public selectedIndex: number;

    private sub$ = new Subscription();
    private _mappedItem: IDesignSchemeMappedItem;
    @Input() public set mappedItem(item: IDesignSchemeMappedItem) {
        this._mappedItem = item;
        this.sub$.unsubscribe();
        if (this.mappedItem) {
            this.loadDesignSchemeLines();
            this.sub$ = this.mappedItem.$updated.subscribe(() => {
                this.loadDesignSchemeLines();
            });
        }
    }
    public get mappedItem(): IDesignSchemeMappedItem {
        return this._mappedItem;
    }

    @Input() public readonly lotMappedItem: ILotMappedItem;
    public systemArea = SYSTEM_AREA_ENUM.DesignScheme;
    public documentCount = 0;
    public questionCount = 0;
    public childQuestionSystemArea = SYSTEM_AREA_ENUM.ChangeRecord;
    public designSchemeLines: any[];

    constructor(
        public readonly designSchemePermissions: DesignSchemePermissions,
        public readonly manageSchemeData: ManageSchemeDataService,
    ) { }

    public ngOnDestroy(): void {
        this.sub$.unsubscribe();
    }

    private loadDesignSchemeLines(): void {
        this.mappedItem.getLines().subOnce(result => {
            this.designSchemeLines = result;
        });
    }

    public onTabChanged(idx): void {
        this.selectedTabChanged.emit(idx);
    }

    protected readonly HISTORY_SEARCH_ENTITY = HISTORY_SEARCH_ENTITY;
}
