
<div class="landing"><!-- Welcome Header -->
    <!-- Search Section -->
    <div class="search-container">
        <mat-form-field appearance="outline" class="search-bar">
            <mat-label>Search Products</mat-label>
            <input
                matInput
                placeholder="E.g., Shower, Cooktop"
                [formControl]="filters.search"
                (input)="applyFilters(true)"
            />
            <mat-icon matSuffix *ngIf="!loading">search</mat-icon>
            <mat-icon matSuffix *ngIf="loading" class="spinner-icon">
                <mat-spinner [diameter]="24" [strokeWidth]="4"></mat-spinner>
            </mat-icon>
        </mat-form-field>
    </div>

<div class="welcome-header" *ngIf="!searchInitialized">
    <h1>Product Selection Centre</h1>
    <p>Start by searching for a keyword...</p>

    <img src="https://productdistributionstrategy.com/wp-content/uploads/2019/09/retail-distribution-strategy.svg" alt="No Products Found" />
</div>



<!-- No Results Section -->
<div *ngIf="!loading && searchInitialized && displayedProducts.length === 0" class="no-results">
    <img src="https://static.vecteezy.com/system/resources/thumbnails/040/691/343/small_2x/search-not-found-no-result-data-or-information-not-available-concept-illustration-flat-design-simple-modern-graphic-element-for-empty-state-ui-infographic-icon-vector.jpg" alt="No Products Found" />
    <h3>No Products Found</h3>
    <p>Try adjusting your search or explore our popular categories below.</p>
</div>
</div>


<div class="container" *ngIf="searchInitialized && products.length > 0">
    <!-- Filters Section -->
    <div class="filters">

        <mat-card *ngIf="isSlotSelection && selectedProduct">
            <mat-card-header>
                <mat-card-title>{{ selectedProduct.name }}</mat-card-title>
                <mat-card-subtitle>{{ selectedProduct.mainCategory }}</mat-card-subtitle>
            </mat-card-header>
            <div class="image-carousel">
                <img mat-card-image [src]="getProductImage(selectedProduct)" alt="{{ selectedProduct.name }}" />
            </div>
            <mat-card-content>
                <p><strong> Rate:</strong> ${{ selectedProduct.nationalRate | number: '1.2-2' }}</p>
            </mat-card-content>
        </mat-card>

        <span *ngIf="!isSlotSelection">
        <h4 class="filter-heading">CATEGORIES</h4>
            <mat-expansion-panel [expanded]="true" style="all: unset; padding: 0">
                <ng-container *ngIf="menuData?.length; else noCategories">
                    <div class="menu-container">
                        <ng-container *ngTemplateOutlet="recursiveListTmpl; context: { $implicit: menuData, level: 0 }">
                        </ng-container>
                    </div>
                </ng-container>

                <ng-template #noCategories>
                    <p *ngIf="loadingMenu">Loading categories...</p>
                    <p *ngIf="!loadingMenu">No categories available</p>
                </ng-template>
            </mat-expansion-panel>


        <ng-template #recursiveListTmpl let-items let-level="level">
            <ul [ngClass]="{'menu-list': level === 0, 'submenu': level > 0}">
                <li *ngFor="let item of items" class="menu-item-container">
                    <ng-container *ngIf="item.badgeCount > 0">
                    <div
                            class="menu-item"
                            [class.selected]="isSelectedMenu(item)"
                            (click)="selectItem(item, $event)"
                            matBadge="{{ item.badgeCount }}"
                            matBadgePosition="after"
                            matBadgeColor="primary">
                        <span>{{ item.label }}</span>

                        <span
                                *ngIf="item.children?.length"
                                class="icon-toggle"
                                (click)="toggleItem(item, $event)">
                    <mat-icon>
                        {{ collapsedItems[item.label + item.id] ? 'expand_more' : 'expand_less' }}
                    </mat-icon>
                </span>
                    </div>

                    <ng-container *ngIf="!collapsedItems[item.label + item.id] && item.children?.length">
                        <ng-container
                                *ngTemplateOutlet="recursiveListTmpl;
                    context: {
                        $implicit: item.children,
                        level: level + 1
                    }">
                        </ng-container>
                    </ng-container>
                    </ng-container>
                </li>
            </ul>
        </ng-template>
            </span>

        <!-- Dynamic Filters -->
        <div  *ngIf="getDynamicFilterOptions().length > 0">
            <div class="filter-group" *ngFor="let filter of getDynamicFilterOptions(); let i = index">
                <h4 (click)="toggleDynamicFilter(filter.key)" class="filter-heading">
                    {{ filter.key.toUpperCase() }}
                    <mat-icon>{{ collapsedFilters[filter.key] ? 'expand_more' : 'expand_less' }}</mat-icon>
                </h4>
                <div *ngIf="!collapsedFilters[filter.key]" class="filter-options">
                    <div *ngFor="let option of filter.values">
                        <mat-checkbox
                                class="custom-checkbox"
                                [checked]="isSelected('dynamicCategories', { key: filter.key, value: option })"
                                (change)="updateDynamicFilter(filter.key, option, $event.checked)">
                            {{ option }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="filter-group" *ngIf="priceRanges?.length > 0">
                <h4 class="filter-heading"></h4>
                <h4 (click)="toggleDynamicFilter('price')" class="filter-heading">
                    PRICE RANGE
                    <mat-icon>{{ collapsedFilters['price'] ? 'expand_more' : 'expand_less' }}</mat-icon>
                </h4>
                <div *ngIf="!collapsedFilters['price']" class="filter-options">
                    <div *ngFor="let range of priceRanges">
                        <mat-checkbox
                                class="custom-checkbox"
                                [checked]="selectedPriceRanges.includes(range)"
                                (change)="togglePriceRange(range, $event.checked)">
                            {{ range }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>


        <button mat-raised-button color="primary" class="reindex-button" (click)="reindex()">Reindex</button>
<!--        <button mat-raised-button  color="primary" class="reindex-button" (click)="resetFilters()">Reset Filters</button>-->
    </div>

    <!-- Products Section -->
    <div class="products">
        <!-- Paginator -->
        <mat-paginator *ngIf="filteredProducts.length > 50" [length]="filteredProducts.length" [pageSize]="50" (page)="paginate($event)"></mat-paginator>


        <!-- Product Grid -->
        <ng-container *ngIf="isSlotSelection">

            <h2>Popular Products</h2>
        <div class="product-grid" *ngIf="!loading && popularProducts.length > 0">
            <mat-card  [class.selected]="isSwappedSelected(product)"
                       (click)="toggleSwappedSelection(product)" *ngFor="let product of popularProducts; trackBy: trackById">
                <mat-card-header>
                    <mat-card-title>{{ product.name }}</mat-card-title>
                    <mat-card-subtitle>{{ product.mainCategory }}</mat-card-subtitle>
                </mat-card-header>
                <div class="image-carousel">
                    <img mat-card-image [src]="getProductImage(product)" alt="{{ product.name }}" />
                </div>
                <mat-card-content>
                    <p><strong> Rate:</strong> ${{ product.nationalRate | number: '1.2-2' }}</p>
                </mat-card-content>
            </mat-card>
        </div>

        </ng-container>

        <ng-container *ngIf="!loading && displayedProducts.length > 0">
            <h2> Products</h2>
        <div class="product-grid" >
            <mat-card [class.selected]="isSwappedSelected(product)"
                      (click)="toggleSwappedSelection(product)" *ngFor="let product of displayedProducts; trackBy: trackById">
                <mat-card-header>
                    <mat-card-title>
                        {{ product.name }}</mat-card-title>
                    <mat-card-subtitle>
                        <span>{{ product.mainCategory }}</span>
                        <span>
                            <mat-chip-list>
                                <mat-chip *ngIf="product.tier" [ngClass]="getChipClass(product.tier)">
                                    <mat-icon>star</mat-icon>
                                    {{ product.tier }}
                                </mat-chip>
                            </mat-chip-list>
                        </span>
                    </mat-card-subtitle>
                </mat-card-header>
                <div class="image-carousel">
                    <img mat-card-image [src]="getProductImage(product)" alt="{{ product.name }}" />
                </div>
                <mat-card-content>
                    <p><strong>Rate:</strong> ${{ product.nationalRate | number: '1.2-2' }}</p>
                </mat-card-content>
            </mat-card>
        </div>
            

        </ng-container>

        <!-- Paginator -->
        <mat-paginator *ngIf="filteredProducts.length > 50" [length]="filteredProducts.length" [pageSize]="50" (page)="paginate($event)"></mat-paginator>
    </div>
</div>
