import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { WipConstants, WipHelper } from '@app/views/wip/helpers/wipConstants';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { WipFavoriteView, WipFavoriteViewFilter, WipService } from '@app/logic/wip/wip.service';
import { CommonModule } from '@angular/common';
import { WipTableComponent } from '@app/views/wip/wip-table/wip-table.component';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { WipFiltersTabComponent } from '@app/views/wip/wip-favorite-views-page/wip-filters-tab/wip-filters-tab.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';

interface Column {
    name: string;
    selected: boolean;
}

@Component({
    selector: 'cb-wip-favorite-views-page',
    templateUrl: './wip-favorite-views-page.component.html',
    styleUrls: ['./wip-favorite-views-page.component.scss'],
    standalone: true,
    imports: [
        WipTableComponent,
        MatTabsModule,
        FormsModule,
        CommonModule,
        MatInputModule,
        MatSidenavModule,
        DragDropModule,
        MatListModule,
        MatSelectModule,
        MatIconModule,
        MatCheckboxModule,
        MatButtonModule,
        WipFiltersTabComponent,
    ],
})
export class WipFavoriteViewsPageComponent implements OnInit, OnChanges {
    @Input() selectedViewInput: WipFavoriteView;
    @Output() viewCreated = new EventEmitter<WipFavoriteView>();
    @Output() viewUpdated = new EventEmitter<WipFavoriteView>();
    @Output() viewSelected = new EventEmitter<WipFavoriteView>();
    @Output() saveClicked = new EventEmitter<boolean>();
    @Output() backToViewClicked = new EventEmitter<boolean>();

    public selectedView: WipFavoriteView | null = null;
    public isCreatingNewView = false;

    public displayedColumns: string[] = WipConstants.displayedColumns;

    public viewModel = {
        name: '',
        isDefault: false,
        columns: [] as Column[],
        filters: [] as WipFavoriteViewFilter[],
    };
    @Input() favoriteViews: WipFavoriteView[];

    constructor(private wipService: WipService) {}

    public ngOnChanges(): void {
        if (this.selectedViewInput == null) {
            this.addNewView();
        } else {
            this.selectView(this.selectedViewInput);
        }
    }

    public ngOnInit(): void {}

    public selectView(view: WipFavoriteView): void {
        this.isCreatingNewView = false;
        this.selectedView = view;
        this.viewModel.name = view.name;
        this.viewModel.isDefault = view.isDefault;

        this.viewModel.columns = view.columns
            .concat(
                this.displayedColumns.filter(x => !view.columns.includes(x))
            )
            .map(name => ({
                name,
                selected: view.columns.includes(name),
            }));
        this.viewModel.filters = view.filters ? [...view.filters] : [];
        this.viewSelected.emit(view);
    }

    public addNewView(): void {
        this.isCreatingNewView = true;
        this.selectedView = null;
        this.viewModel.name = 'New View';
        this.viewModel.isDefault = false;
        this.viewModel.columns = this.displayedColumns.map(name => ({
            name,
            selected: true,
        }));
        this.viewModel.filters = [];
    }

    public createNew(): void {
        const favoriteView: WipFavoriteView = {
            id: null,
            userId: null,
            name: this.viewModel.name,
            isDefault: this.viewModel.isDefault,
            columns: this.getSelectedColumns(),
            filters: this.viewModel.filters,
        };

        this.wipService.createFavoriteView(favoriteView).subscribe(newView => {
            this.isCreatingNewView = false;
            this.viewCreated.emit(newView);
            this.saveClicked.next(newView.isDefault);
        });
    }

    public saveView(): void {
        if (this.isCreatingNewView) {
            this.createNew();
            return;
        }
        const favoriteView: WipFavoriteView = {
            id: this.selectedView.id,
            userId: this.selectedView.userId,
            name: this.viewModel.name,
            isDefault: this.viewModel.isDefault,
            columns: this.getSelectedColumns(),
            filters: this.viewModel.filters,
        };

        this.wipService.updateFavoriteView(favoriteView).subscribe(updatedView => {
            this.viewUpdated.emit(updatedView);
            this.saveClicked.next(updatedView.isDefault);
        });
    }

    private getSelectedColumns(): string[] {
        return this.viewModel.columns
            .filter(col => col.selected)
            .map(col => col.name);
    }

    public drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.viewModel.columns, event.previousIndex, event.currentIndex);
    }

    protected readonly WipHelper = WipHelper;

    public backToView(): void {
        this.backToViewClicked.next(this.isCreatingNewView);
    }
}
