<mat-card class="cb-margin-bottom cb-margin-top start-center flex-row">
    <button class="cb-margin-left"
            mat-raised-button
            color="primary"
            (click)="goBackToSearch()">
        <mat-icon>arrow_back</mat-icon>
        Concepts
    </button>
    <button class="cb-margin-left"
            mat-raised-button
            color="primary"
            [matMenuTriggerFor]="optionsMenu"
            [disabled]="optionsMenuDisabled()">
        <mat-icon>more_horiz</mat-icon>
        Options
    </button>
    <mat-menu #optionsMenu="matMenu">
        <button mat-menu-item
                *ngIf="canApplyConcept()"
                (click)="applyDesignConceptToLot()"
                [disabled]="!canApplyToLot()">
            <mat-icon>date_range</mat-icon>
            Apply To Lot
        </button>
        <button mat-menu-item
                *ngIf="canSetConceptExpiryDate()"
                (click)="setExpiryDate()">
            <mat-icon>date_range</mat-icon>
            Set Expiry Date
        </button>
        <button mat-menu-item
                *ngIf="hasPermissionToSetLotAvailableForSale()"
                (click)="makeLotAvailableForSale()"
                [disabled]="!canSetLotAvailableForSale()">
            <mat-icon>library_books</mat-icon>
            Make Lot Available for Sale
        </button>
        <button mat-menu-item
                *ngIf="canSetConceptSellPrice()"
                (click)="setSellPrice()">
            <mat-icon>attach_money</mat-icon>
            Set Sell Price
        </button>
        <button mat-menu-item
                *ngIf="canRequestImages()"
                (click)="requestExteriorImage()">
            <mat-icon>panorama</mat-icon>
            Request Exterior Image
        </button>
        <button mat-menu-item
                *ngIf="canRequestImages()"
                (click)="requestFloorPlanImage()">
            <mat-icon>image</mat-icon>
            Request Floor Plan Image
        </button>
        <button mat-menu-item
                *ngIf="canAbandonConcept()"
                (click)="abandonConcept()"
                [disabled]="abandonDisabled()">
            <mat-icon>cancel</mat-icon>
            Abandon Concept
        </button>
    </mat-menu>
</mat-card>
<div class="flex-row"
     style="gap: 16px">
    <cb-design-concept-info [designConcept]="designConcept">

    </cb-design-concept-info>
    <cb-design-concept-view class="flex"
                            [mappedItem]="designConcept">
    </cb-design-concept-view>
</div>
