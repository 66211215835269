<div class="flex-row flex-50">
    <div class="cb-margin-2x flex-col">
        <span class="mat-body-2 cb-margin-bottom-2x">Upload Design Info</span>
        <p class="mat-body-1j cb-margin-bottom-3x">Upload any Design Info documents for this Lot. (Any marked up plans
            for
            this Scheme can be
            uploaded at Step 5)</p>
        <mat-form-field>
            <mat-select [(value)]="selectedGroup"
                        placeholder="Document Group"
                        required
                        (selectionChange)="onGroupSelection($event.value)">
                <mat-option *ngFor="let group of documentGroupsOptions"
                            [value]="group">
                    {{ group.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="availableForUploadDocumentTypes?.length > 0">
            <mat-select [(value)]="selectedType"
                        placeholder="Document Type"
                        required
                        (selectionChange)="onTypeSelection($event.value)">
                <mat-option *ngFor="let type of availableForUploadDocumentTypes"
                            [value]="type">
                    {{ type.documentType?.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <cb-document-upload *ngIf="selectedType && documentEntityMappedItem"
                            [(documentEntityMappedItem)]="documentEntityMappedItem"
                            class="cb-margin-bottom-3x">
        </cb-document-upload>
        <div class="end-center flex-row">
            <button class="cb-margin-right cb-margin-top-3x cb-margin-bottom-3x"
                    mat-raised-button
                    color="primary"
                    [disabled]="!documentEntityMappedItem?.document?.name"
                    (cbClick)="resetDocumentForm()">Clear</button>
            <button mat-raised-button
                    color="primary"
                    [disabled]="!documentEntityMappedItem?.document?.name"
                    (cbClick)="addDocumentToList()">Upload</button>
        </div>
    </div>
</div>
<div class="flex-row flex-50">
    <div class="cb-margin-2x flex-col flex"
         *cbWaitFor="existingDocuments && documentsToUpload">
        <span class="mat-body-2"
              layout-margin>Documents</span>
        <cb-table class="flex"
                  [loaded]="true">
            <thead cbTableHead>
                <tr>
                    <td></td>
                    <td>File Name</td>
                    <td>File Type</td>
                    <td></td>
                </tr>
            </thead>
            <tbody cbTableBody>
                <tr *ngFor="let document of existingDocuments">
                    <cb-td-icon value="info_circle"
                                class="cb-help-cursor"
                                toolTip="Pre-existing Document">
                    </cb-td-icon>
                    <cb-td-text [value]="document?.document?.name"></cb-td-text>
                    <cb-td-text [value]="document?.documentType?.label"></cb-td-text>
                    <td></td>
                </tr>
                <tr *ngFor="let document of documentsToUpload?.lotSitePhotoDocuments; let index = index;">
                    <cb-td-icon value="info_circle"
                                class="cb-help-cursor cb-primary"
                                toolTip="New Document"></cb-td-icon>
                    <cb-td-text [value]="document?.document?.name"></cb-td-text>
                    <cb-td-text [value]="documentTypesLookup[document?.documentType?.id]?.label"></cb-td-text>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <button mat-icon-button
                                (cbClick)="removeDocumentFromList(index)">
                            <mat-icon>remove_circle</mat-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </cb-table>
    </div>
</div>
