<mat-card class="flex-col flex">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon">
            <mat-icon class="cb-primary"
                      title="Complete"
                      *ngIf="mappedItem?.qualifiedDate || canQualify()">
                check_circle
            </mat-icon>
            <mat-icon class="cb-error"
                      title="Incomplete"
                      *ngIf="!mappedItem?.qualifiedDate && !canQualify()">
                error
            </mat-icon>
        </div>
        <mat-card-title [ngClass]="!(mappedItem?.stepFinanceInfoComplete) ? 'cb-error' : ''">
            {{stepNumber}}. Qualify
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="start-start flex-col flex">
        <span class="mat-body-1">
            Once these steps have been completed the Lead can be qualified to start the Create Process
        </span>
        <ul class="cb-margin list">
            <li class="start-center flex-row"
                *ngIf="mappedItem.contacts.length > 0">
                <mat-icon class="mat-primary">check_circle</mat-icon>
                &nbsp;Client Details Specified
            </li>
            <li *ngIf="mappedItem.contacts.length < 1"
                class="cb-error start-center flex-row">
                <mat-icon class="cb-error">error</mat-icon>
                &nbsp;Client Details to be Specified
            </li>
            <li class="start-center flex-row"
                *ngIf="lotDetailsEntered()">
                <mat-icon class="mat-primary">check_circle</mat-icon>
                &nbsp;Lot Details Entered
            </li>
            <li *ngIf="mappedItem.contractType == LOT_CONTRACT_TYPE_ENUM.None"
                class="cb-error start-center flex-row">
                <mat-icon class="cb-error">error</mat-icon>
                &nbsp;Choose a Contract Type
            </li>
            <li *ngIf="mappedItem.contractType != LOT_CONTRACT_TYPE_ENUM.None && !lotDetailsEntered()"
                class="cb-error start-center flex-row">
                <mat-icon class="cb-error">error</mat-icon>
                &nbsp;Lot Details to be Entered
            </li>
            <li *ngIf="numberOfHouseAndLandInterests() < 1 && mappedItem.contractType == LOT_CONTRACT_TYPE_ENUM.HouseAndLand"
                class="cb-error start-center flex-row">
                <mat-icon class="cb-error">error</mat-icon>
                &nbsp;Lot Interests to be Entered
            </li>
            <li *ngIf="numberOfDesignAndLandInterests() < 1 && mappedItem.contractType == LOT_CONTRACT_TYPE_ENUM.DesignAndLand"
                class="cb-error start-center flex-row">
                <mat-icon class="cb-error">error</mat-icon>
                &nbsp;Lot Interests to be Entered
            </li>
            <li class="start-center flex-row"
                *ngIf="mappedItem.stepEnquiryOriginComplete">
                <mat-icon class="mat-primary">check_circle</mat-icon>
                &nbsp;Enquiry Origin Entered
            </li>
            <li *ngIf="!mappedItem.stepEnquiryOriginComplete"
                class="cb-error start-center flex-row">
                <mat-icon class="cb-error">error</mat-icon>
                &nbsp;Enquiry Origin to be Entered
            </li>
            <li class="start-center flex-row"
                *ngIf="mappedItem.stepFinanceInfoComplete">
                <mat-icon class="mat-primary">check_circle</mat-icon>
                &nbsp;Finance Informaton Entered
            </li>
            <li *ngIf="!mappedItem.stepFinanceInfoComplete"
                class="cb-error start-center flex-row">
                <mat-icon class="cb-error">error</mat-icon>
                &nbsp;Budget Needs To Be Entered
            </li>
            <li class="cb-margin-top-3x"
                *ngIf="mappedItem.qualifiedDate"
                class="mat-body-1 start-center flex-row">
                <mat-icon>info</mat-icon>
                &nbsp;
                This Lead is Qualified
            </li>
        </ul>
    </mat-card-content>
    <div position="relative"
         *ngIf="(canSeeMarkAsLost() || canSeeQualify()) && canEdit && !mappedItem.isQualified()">
        <mat-divider></mat-divider>
    </div>
    <mat-card-actions class="end-center flex-row layoutGap-16"
                      *ngIf="(canSeeMarkAsLost() || canSeeQualify()) && canEdit && !mappedItem.isQualified()">
        <button mat-stroked-button
                color="warn"
                *ngIf="canSeeMarkAsLost()"
                [disabled]="!canMarkAsLost()"
                (cbClick)="markAsLost()">
            <mat-icon>cancel</mat-icon>
            &nbsp;Lost
        </button>
        <button mat-raised-button
                color="primary"
                *ngIf="canSeeQualify() && !mappedItem.isQualified()"
                [disabled]="!canQualify()"
                (cbClick)="qualifyLead()"
                style="margin-right: 0">
            <mat-icon>check_circle</mat-icon>
            &nbsp;Qualify
        </button>
    </mat-card-actions>
</mat-card>
