export class WipConstants {
    public static displayedColumns: string[]= [
        'jobNumber', 'lotNumber', 'jobStatus', 'businessEntity', 'projectName', 'releaseStage',
        'address', 'contractType', 'client', 'buildingConsultant', 'constructionManager', 'buildType',
        'titleStatus', 'titleDate', 'consentLodgedDate', 'consentDueOutDate', 'consentApprovedDate', 'unconditionalDate'
    ];
}

export class WipHelper {
    public static formatColumnName(name: string): string {
        return name
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    }
}
