import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IDocumentEntityMappedItem } from '@app/logic/documents/interfaces/i.document.mapped';
import { DocumentsLogicService } from '@app/logic/documents/documents.logic.service';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';

@Component({
    selector: 'cb-document-upload',
    templateUrl: './document-upload.component.html',
    styleUrls: ['./document-upload.component.scss'],
    viewProviders: [
        provideParentForm()
    ]
})
export class DocumentUploadComponent implements OnInit {
    @Input() public documentEntityMappedItem: IDocumentEntityMappedItem;
    @Input() public hideName = false;
    @Input() public hideDescription = false;

    @Output() public documentEntityMappedItemChange = new EventEmitter();

    public documentName: string;
    public documentDescription: string;

    constructor(public readonly documentsLogicService: DocumentsLogicService) { }

    public ngOnInit(): void {

        if (this.documentEntityMappedItem.document == null) {
            this.documentEntityMappedItem.document = {
                id: null,
                approvalStatuses: null,
                contentType: null,
                createdByName: '',
                createdDate: '',
                deletedDate: '',
                description: '',
                documentType: null,
                documentTypeId: null,
                fileSize: '',
                filename: '',
                isDeleted: false,
                name: '',
                precursorDocumentId: null,
                renewalDate: null,
                updatedByName: '',
                updatedDate: '',
                isLinkedDocument: false,
            };
        } else {
            if (this.documentEntityMappedItem?.document?.name) {
                this.documentEntityMappedItem.document.name = '';
            }

            if (this.documentEntityMappedItem?.document?.description) {
                this.documentEntityMappedItem.document.description = '';
            }
        }
    }

    public onDocumentEntityMappedItemChange() {
        this.documentEntityMappedItemChange.emit(this.documentEntityMappedItem)
    }
}
