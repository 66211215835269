import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { BusinessAccountPermissions } from '@app/core/permissions';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import {
    BusinessAccountLogicService, BusinessAccountMappedItem, IBusinessAccountHoldDto,
    IBusinessAccountSupplyTypeDto,
    IBusinessAccountTradeTypeDto, ParentDocsMaintainance
} from '@app/logic/business-accounts';
import { ILocationDto, LocationLogicService } from '@app/logic/location';
import { SupplyTypesLogicService } from '@app/logic/supply-types';
import { TradeTypesLogicService } from '@app/logic/trade-types';
import { IUserDto, UsersLogicService } from '@app/logic/users';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import {
    BUSINESS_ACCOUNT_HOLD_ACTION_ENUM, BUSINESS_ACCOUNT_HOLD_TYPE_ENUM,
    BUSINESS_ACCOUNT_SEVEN_DAY_PAYMENT_REQUEST_OUTCOME_ENUM,
    BUSINESS_ACCOUNT_SUPPLY_TYPE_STATUS_ENUM,
    BUSINESS_ACCOUNT_TRADE_TYPE_STATUS_ENUM, IAddressDto,
    PAYMENT_FREQUENCY_ENUM,
    USER_TAG_CONSTANTS_CONST
} from '@classictechsolutions/hubapi-transpiled-enums';
import { clone, filter, find, map, orderBy, some } from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'cb-business-account-details',
    templateUrl: './business-account-details.component.html',
    styleUrls: ['./business-account-details.component.scss']
})
export class BusinessAccountDetailsComponent implements OnInit, OnChanges {
    @Input() public account: BusinessAccountMappedItem;
    @Output() public accountChange = new EventEmitter();

    @Input() public isCreate$: BehaviorSubject<boolean>;
    @Input() public isEditing$: BehaviorSubject<boolean>;
    @Output() public editFinanceInformationClicked = new EventEmitter();
    @Output() public accountUpdated = new EventEmitter();

    public BUSINESS_ACCOUNT_SEVEN_DAY_PAYMENT_REQUEST_OUTCOME_ENUM = BUSINESS_ACCOUNT_SEVEN_DAY_PAYMENT_REQUEST_OUTCOME_ENUM;
    public BUSINESS_ACCOUNT_HOLD_ACTION_ENUM = BUSINESS_ACCOUNT_HOLD_ACTION_ENUM;
    public BUSINESS_ACCOUNT_HOLD_TYPE_ENUM = BUSINESS_ACCOUNT_HOLD_TYPE_ENUM;
    public BUSINESS_ACCOUNT_TRADE_TYPE_STATUS_ENUM = BUSINESS_ACCOUNT_TRADE_TYPE_STATUS_ENUM;
    public BUSINESS_ACCOUNT_SUPPLY_TYPE_STATUS_ENUM = BUSINESS_ACCOUNT_SUPPLY_TYPE_STATUS_ENUM;
    public PAYMENT_FREQUENCY_ENUM = PAYMENT_FREQUENCY_ENUM;
    public readonlyTradeTypes: IBusinessAccountTradeTypeDto[];
    public readonlySupplyTypes: IBusinessAccountSupplyTypeDto[];
    public availableTradeTypes: IBusinessAccountTradeTypeDto[];
    public availableSupplyTypes: IBusinessAccountSupplyTypeDto[];
    public availableRequestUsers: IUserDto[];
    public availableParentAccounts: IBusinessAccountTradeTypeDto[];
    public availableRegions: ILocationDto[];
    public showLocations: boolean;
    public isSupplierTypesEnabled: boolean;

    constructor(
        public readonly permissions: BusinessAccountPermissions,
        protected readonly cbDialog: CbDialogService,
        protected readonly toastService: ToastService,
        protected readonly businessAccountLogicService: BusinessAccountLogicService,
        protected readonly tradeTypesLogicService: TradeTypesLogicService,
        protected readonly supplyTypesLogicService: SupplyTypesLogicService,
        protected readonly usersLogicService: UsersLogicService,
        protected readonly currentUserService: CurrentUserService,
        protected readonly route: ActivatedRoute,
        protected readonly locationLogicService: LocationLogicService,
        protected readonly featureToggleService: FeatureToggleStatesService,
    ) {
        featureToggleService.init().then(() => {
            this.isSupplierTypesEnabled = featureToggleService.isSupplierTypesEnabled;
            if (this.isSupplierTypesEnabled){
                this.loadSupplyTypes();
            }
        });
    }

    public ngOnInit(): void {
        this.availableTradeTypes = [];
        this.locationLogicService.$getList().subOnce(result => this.availableRegions = filter(result, { isActive: true }));
        this.tradeTypesLogicService.$getList().subOnce(result => {
            result.forEach(item => {
                if (item.parentId) {
                    let parent = find(this.availableTradeTypes, { id: item.parentId });
                    if (parent) {
                        parent.children.push(item);
                    } else {
                        parent = { id: item.parentId, label: item.parentLabel } as IBusinessAccountTradeTypeDto;
                        parent.children = [item];
                        this.availableTradeTypes.push(parent);
                    }
                }
            });
            this.availableTradeTypes = orderBy(this.availableTradeTypes, 'label');
            this.availableTradeTypes.forEach(item => item.children = orderBy(item.children, 'label'));
        });

        this.availableParentAccounts = [];
        this.businessAccountLogicService.getParentAccounts().subOnce((result: any[]) => {
            if (result?.length) {
                result.forEach(item => item.label = item.tradingName);
                result = orderBy(result, 'label');
                result.unshift({ id: 0, label: 'No Parent' });
                this.availableParentAccounts = result;
            }
        });

        if (!this.account.id) {
            this.currentUserService.$promise.then((user: any) => {
                this.account.requestedById = this.account.requestedById || this.currentUserService.guid;
            });

            this.availableRequestUsers = [];
            this.usersLogicService.getListByTag({ tag: USER_TAG_CONSTANTS_CONST.BRANCH_BUSINESS_ACCOUNT_REQUESTER }).subOnce((result) => {
                this.availableRequestUsers = map(result, item => {
                    return {
                        id: item.id,
                        label: item.label
                    } as IUserDto;
                });
            });
        }
    }

    private loadSupplyTypes(): void{
        this.availableSupplyTypes = [];
        if(this.isSupplierTypesEnabled) {
            this.supplyTypesLogicService.$getList().subOnce(result => {
                result.forEach(item => {
                    if (item.parentId) {
                        let parent = find(this.availableSupplyTypes, { id: item.parentId });
                        if (parent) {
                            parent.children.push(item);
                        } else {
                            parent = { id: item.parentId, label: item.parentLabel } as IBusinessAccountSupplyTypeDto;
                            parent.children = [item];
                            this.availableSupplyTypes.push(parent);
                        }
                    }
                });
                this.availableSupplyTypes = orderBy(this.availableSupplyTypes, 'label');
                this.availableSupplyTypes.forEach(item => item.children = orderBy(item.children, 'label'));
            });
        }
    }

    public ngOnChanges(): void {
        this.account.physicalAddress = this.account.physicalAddress || {} as IAddressDto;
        this.account.postalAddress = this.account.postalAddress || {} as IAddressDto;
        this.account.tradeTypes = this.account.tradeTypes || [];
        this.account.supplyTypes = this.account.supplyTypes || [];
        this.showLocations = !this.account.locations?.length;

        this.readonlyTradeTypes = [];
        if (this.account.businessAccountTradeTypes) {
            this.account.tradeTypes = map(this.account.businessAccountTradeTypes, 'id');
            this.account.businessAccountTradeTypes.forEach(item => {
                let parent = find(this.readonlyTradeTypes, { id: item.parent.id });
                if (parent) {
                    parent.children.push(item);
                } else {
                    parent = clone(item.parent);
                    parent.children = [item];
                    this.readonlyTradeTypes.push(parent);
                }
            });
            this.readonlyTradeTypes = orderBy(this.readonlyTradeTypes, 'label');
            this.readonlyTradeTypes.forEach(item => item.children = orderBy(item.children, 'label'));
        }

        this.readonlySupplyTypes = [];
        if (this.isSupplierTypesEnabled && this.account.businessAccountSupplyTypes) {
            this.account.supplyTypes = map(this.account.businessAccountSupplyTypes, 'id');
            this.account.businessAccountSupplyTypes.forEach(item => {
                let parent = find(this.readonlySupplyTypes, { id: item.parent.id });
                if (parent) {
                    parent.children.push(item);
                } else {
                    parent = clone(item.parent);
                    parent.children = [item];
                    this.readonlySupplyTypes.push(parent);
                }
            });
            this.readonlySupplyTypes = orderBy(this.readonlySupplyTypes, 'label');
            this.readonlySupplyTypes.forEach(item => item.children = orderBy(item.children, 'label'));
        }

        if (this.account.isParentAccount) {
            if (this.account.shouldMaintainRequiredDocumentsOnParentAccount && !this.account.shouldMaintainRequiredDocumentsOnChildAccounts) {
                this.account.parentDocsMaintainedOn = ParentDocsMaintainance.ParentAccountOnly;
            } else if (!this.account.shouldMaintainRequiredDocumentsOnParentAccount && this.account.shouldMaintainRequiredDocumentsOnChildAccounts) {
                this.account.parentDocsMaintainedOn = ParentDocsMaintainance.ChildAccountsOnly;
            } else {
                this.account.parentDocsMaintainedOn = ParentDocsMaintainance.BothParentAndChildAccounts;
            }
        }
    }

    public isRegionChecked(regionId: number): boolean {
        return this.account && find(this.account?.locations ?? [], { id: regionId });
    }

    public onRegionChanged(regionId: number, value: boolean): void {
        const found = some(this.account.locations, { id: regionId });
        if (value && !found) {
            this.account.locations = this.account.locations ?? [];
            this.account.locations.push({ id: regionId } as any);
        } else if (!value) {
            this.account.locations = filter(this.account.locations, location => location.id !== regionId);
        }
    }

    public isTradeTypeRegionChecked(tradeType: IBusinessAccountTradeTypeDto): boolean {
        return (this.account?.tradeTypes ?? []).indexOf(tradeType.id) > -1;
    }

    public isSupplyTypeRegionChecked(supplyType: IBusinessAccountSupplyTypeDto): boolean {
        return (this.account?.supplyTypes ?? []).indexOf(supplyType.id) > -1;
    }

    public onTradeTypeChanged(tradeType: IBusinessAccountTradeTypeDto, value: boolean): void {
        const found = this.account.tradeTypes.indexOf(tradeType.id) > -1;
        if (value && !found) {
            this.account.tradeTypes.push(tradeType.id);
        } else if (!value) {
            this.account.tradeTypes = filter(this.account.tradeTypes, item => item !== tradeType.id);
        }
    }

    public onSupplyTypeChanged(supplyType: IBusinessAccountSupplyTypeDto, value: boolean): void {
        const found = this.account.supplyTypes.indexOf(supplyType.id) > -1;
        if (value && !found) {
            this.account.supplyTypes.push(supplyType.id);
        } else if (!value) {
            this.account.supplyTypes = filter(this.account.supplyTypes, item => item !== supplyType.id);
        }
    }

    public onApproveSevenDaysClicked(isDeclined?: boolean): void {
        const action = isDeclined ? 'Decline' : 'Approve';
        this.cbDialog.confirm({
            dialogHeading: `${action} 7 Day Payment?`,
            message: `Are you sure you wish to ${action.toLowerCase()} the seven day payment request?`,
            confirmed: (): void => {
                this.businessAccountLogicService.requestSevenDaysApproval(this.account.id, isDeclined)
                    .subOnce(savedAccount => this.account = this.businessAccountLogicService.$createMappedItem(savedAccount, BusinessAccountMappedItem));
            }
        });
    }

    public onRemoveAccountHoldClicked(hold: IBusinessAccountHoldDto): void {
        this.cbDialog.confirm({
            dialogHeading: 'Remove Account Hold',
            message: 'Are you sure you want to remove this Hold?',
            confirmed: (): void => {
                this.businessAccountLogicService.removeAccountHold(this.account.id, { holdAction: hold.holdAction, holdType: hold.holdType } as IBusinessAccountHoldDto)
                    .subOnce(savedAccount => this.account = this.businessAccountLogicService.$createMappedItem(savedAccount, BusinessAccountMappedItem));
            }
        });
    }

    public emitAccountUpdated(): void {
        this.accountUpdated.emit();
    }
}
