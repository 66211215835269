<div class="filters-tab flex flex-col center">
    <div *ngFor="let filter of filters; let i = index" class="filter-item flex center flex-row">
        <mat-form-field appearance="fill" class="filter-field">
            <mat-select placeholder="Column" [(ngModel)]="filter.columnName">
                <mat-option *ngFor="let column of displayedColumns" [value]="column" required="true">
                    {{ WipHelper.formatColumnName(column) }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="filter-field">
            <ng-container [ngSwitch]="filter.columnName">
                <ng-container *ngSwitchCase="'jobStatus'">
                    <cb-select-list
                        label="Job Status"
                        [allOptions]="jobStatuses"
                        [multiple]="true"
                        [(value)]="filter.jobStatuses"
                    ></cb-select-list>
                </ng-container>

                <ng-container *ngSwitchCase="'contractType'">
                    <cb-select-list
                        label="Contract Type"
                        [allOptions]="contractTypes"
                        [multiple]="true"
                        [(value)]="filter.contractTypes"
                    ></cb-select-list>
                </ng-container>

                <ng-container *ngSwitchCase="'buildingConsultant'">
                    <cb-user-autocomplete-scroller
                        [tag]="[BUILDING_CONSULTANT]"
                        label="Building Consultant"
                        name="buildingConsultant"
                        [(ngModel)]="buildingConsultantModel"
                        [requireMatch]="true"
                        [matchProps]="['id']"
                        [required]="true"
                        [active]="true">
                    </cb-user-autocomplete-scroller>
                </ng-container>
                <ng-container *ngSwitchCase="'unconditionalDate'">
                    <app-date-filter class="input-value"
                                     [dateValue]="filter.dateValue"
                                     (dateValueChange)="onDateValueChanged($event, filter)">

                    </app-date-filter>
                </ng-container>
                <ng-container *ngSwitchCase="'consentApprovedDate'">
                    <app-date-filter class="input-value"
                                     [dateValue]="filter.dateValue"
                                     (dateValueChange)="onDateValueChanged($event, filter)">

                    </app-date-filter>
                </ng-container>
                <ng-container *ngSwitchCase="'consentLodgedDate'">
                    <app-date-filter class="input-value"
                                     [dateValue]="filter.dateValue"
                                     (dateValueChange)="onDateValueChanged($event, filter)">

                    </app-date-filter>
                </ng-container>
                <ng-container *ngSwitchCase="'consentDueOutDate'">
                    <app-date-filter class="input-value"
                                     [dateValue]="filter.dateValue"
                                     (dateValueChange)="onDateValueChanged($event, filter)">

                    </app-date-filter>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <mat-form-field appearance="fill" class="input-value">
                        <input matInput placeholder="Filter Value" [(ngModel)]="filter.filterValue" />
                    </mat-form-field>
                </ng-container>

            </ng-container>
        </div>

        <button mat-icon-button color="black" (click)="removeFilter(i)"
                matTooltip="Delete filter">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
    <button mat-button color="primary" (click)="addFilter()">
        <mat-icon>add</mat-icon>
        Add Filter
    </button>
</div>
