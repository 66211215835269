<div class="cb-margin-3x">
    <cb-table label="Build Programmes"
              #infiniteScrollTable
              maxHeightOffset="200"
              [stringColumns]="['Job Number', 'Business Entity', 'Foundation Manager', 'Framing Manager', 'Job Status', 'Est Start Date', 'Actual Start Date', '']"
              [loaded]="true"
              infiniteScroll
              [immediateCheck]="false"
              [infiniteScrollContainer]="infiniteScrollTable?.infiniteScrollContainer"
              (scrolled)="doSearch()">
        <tbody cbTableBody>
            <tr *ngFor="let buildProgramme of searchResults">
                <cb-td-text [value]="buildProgramme?.jobNumber"></cb-td-text>
                <cb-td-text [value]="buildProgramme?.businessEntityName"></cb-td-text>
                <cb-td-text [value]="buildProgramme?.foundationManager"></cb-td-text>
                <cb-td-text [value]="buildProgramme?.framingManager"></cb-td-text>
                <cb-td-enum [value]="buildProgramme?.jobStatus"
                            [enum]="LOT_JOB_STATUS_ENUM">
                </cb-td-enum>
                <cb-td-date [value]="buildProgramme?.estimatedStartDate"></cb-td-date>
                <cb-td-date [value]="buildProgramme?.actualStartDate"></cb-td-date>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <button mat-icon-button
                            (click)="viewBuildProgramme(buildProgramme)">
                        <mat-icon>pageview</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </cb-table>
</div>
