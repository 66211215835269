<form class="flex-row flex"
      #form="ngForm">
    <cb-input label="Template Name"
              name="templateName"
              class="cb-margin-right-2x"
              [(ngModel)]="selectedValues.searchText"
              [ngModelOptions]="{ updateOn: 'blur' }"
              (change)="emitFilterChanged()"></cb-input>
    <cb-checkbox label="Is Active"
                 name="isActive"
                 class="cb-margin-right-2x cb-padding-top"
                 [(ngModel)]="selectedValues.isActive"
                 (change)="emitFilterChanged()"></cb-checkbox>
    <cb-select label="Business Entity"
               name="businessEntity"
               class="cb-margin-right-2x"
               [options]="businessEnitities"
               [(ngModel)]="selectedValues.businessEntityId"
               (change)="emitFilterChanged()"></cb-select>
    <cb-select label="Project"
               name="project"
               [options]="projects"
               [(ngModel)]="selectedValues.projectId"
               (change)="emitFilterChanged()"></cb-select>
</form>
