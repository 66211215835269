<div class="cb-margin-3x">
    <cb-table label="Spec Template Items"
              #infiniteScrollTable
              maxHeightOffset="244"
              [stringColumns]="['Template Name', 'Visibility', 'Last Updated', 'Active', '']"
              [loaded]="true"
              infiniteScroll
              [immediateCheck]="false"
              [infiniteScrollContainer]="infiniteScrollTable?.infiniteScrollContainer"
              (scrolled)="doSearch()">
        <tbody cbTableBody>
            <tr *ngFor="let specTemplate of searchResults">
                <cb-td-text [value]="specTemplate?.templateName"></cb-td-text>
                <cb-td-text [value]="specTemplate?.visibility"></cb-td-text>
                <cb-td-date [value]="specTemplate?.lastUpdatedDate"></cb-td-date>
                <cb-td-checkbox [value]="specTemplate?.isActive"></cb-td-checkbox>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <button mat-icon-button
                            (click)="viewSpecTemplate(specTemplate)">
                        <mat-icon>pageview</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </cb-table>
</div>
