import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { HistoryLogicService } from '@app/logic/history';
import { HISTORY_SEARCH_ENTITY } from '@app/shared/enums/history/history-search-entity.enum';
import { IHistoryDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { BehaviorSubject, debounceTime, defaultIfEmpty, filter, Observable, startWith, Subject, Subscription } from 'rxjs';
import { ISearchResult } from '../../search/i.search';
import { HistoryViewDialogComponent } from '../history-view-dialog/history-view-dialog.component';

@Component({
    selector: 'cb-history-list',
    templateUrl: './history-list.component.html',
    styleUrls: ['./history-list.component.scss']
})
export class HistoryListComponent implements OnInit, OnDestroy {

    @Input() public entity: HISTORY_SEARCH_ENTITY;
    @Input() public entityId: number | string;

    public searchText = new UntypedFormControl();
    public historyItems: IHistoryDto[] = [];
    public currentPage: number;
    public readonly queryUpdated = new Subject();
    public infiniteScrollEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public readonly subscriptions = new Subscription();
    public readonly displayedColumns: string[] = ['date', 'action', 'user', 'actions'];

    constructor(
        public readonly dialog: MatDialog,
        public readonly historyLogicService: HistoryLogicService,
    ) { }

    public ngOnInit(): void {
        this.createSearchTextSub();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public viewHistoryInfo(history: IHistoryDto): void {
        this.dialog.open(
            HistoryViewDialogComponent,
            {
                data: {
                    history
                },
                panelClass: 'cb-dialog-container',
                minWidth: 400
            }
        );
    }

    public cleanSearch(): void {
        this.infiniteScrollEnabled.next(true);
        this.queryUpdated.next(null);
    }

    public search(): Observable<ISearchResult<IHistoryDto>> {
        return this.historyLogicService
            .getHistory(this.entity, this.entityId, this.searchText.value || '', this.currentPage);
    }

    private createSearchTextSub(): void {
        this.subscriptions.add(
            this.searchText.valueChanges
                .pipe(
                    defaultIfEmpty(''),
                    startWith(''),
                    debounceTime(400),
                    filter(q => typeof q === 'string')
                )
                .subscribe(() => {
                    this.cleanSearch();
                })
        );
    }
}
