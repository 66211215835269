import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'cb-select-list',
    templateUrl: './select-list.component.html',
    styleUrls: ['./select-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SelectListComponent {
    @Input() public allOptions = [];
    @Input() public label = '';
    @Input() public multiple = false;
    @Input() public hasSelectAll = true;
    @Input() public panelClass = '';
    @Input() public disabled = false;

    private _value: any;
    public get value(): any {
        return this._value;
    }

    @Input()
    public set value(value: any) {
        this._value = value;
        // ensure value is set on init
        this.form.controls.selectElement.setValue(value);
    }

    /** used for [(value)] two way binding */
    @Output() public valueChange = new EventEmitter<any>();

    @Output() public selectChanged = new EventEmitter<UntypedFormControl>();

    public form: UntypedFormGroup;
    public selectElement: UntypedFormControl;

    public constructor() {
        this.form = new UntypedFormGroup({
            selectElement: new UntypedFormControl(this.allOptions)
        });
    }

    public updateSelectElement = (): void => {
        this.form.controls.selectElement.setValue(this.value);
        this.valueChange.emit(this.value);
        this.selectChanged.emit(this.form.controls.selectElement as UntypedFormControl);
    };

    public checkAllCheckboxElementChanged = (selectElement: AbstractControl): void => {
        this.value = selectElement.value.map(x => x.id);
        this.form.controls.selectElement.setValue(selectElement.value);
    };
}
