import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NavigationService } from '@app/core/services/navigation/navigation.service';

@Component({
    selector: 'cb-display-value-new',
    templateUrl: './display-value.component.html',
    styleUrls: ['./display-value.component.scss']
})
export class DisplayValueComponent {

    @Output() public readonly click = new EventEmitter<MouseEvent>();
    @Input() public btnCondition: boolean;
    @Input() public showBtn: boolean;
    @Input() public btnHref: string;
    @Input() public btnIcon: string;
    @Input() public inputStyle: string;
    @Input() public label: string;
    @Input() public value: string | number;
    @Input() public applyEllipsis: boolean;

    constructor(
        public readonly navigationService: NavigationService
    ) { }
    public handleClick(event: MouseEvent): void {
        this.click.emit(event);
        if (this.btnHref) {
            this.navigationService.navigate([this.btnHref], event);
        }
    }
}
