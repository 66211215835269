import { AfterViewInit, Component, Input } from '@angular/core';
import { PermissionsPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { BusinessAccountLogicService } from '@app/logic/business-accounts';
import { BUSINESS_ACCOUNT_STATUS_ENUM, IBusinessAccountDetailsDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-business-account-child-accounts',
    templateUrl: './business-account-child-accounts.component.html',
    styleUrls: ['./business-account-child-accounts.component.scss']
})
export class BusinessAccountChildAccountsComponent implements AfterViewInit {
    @Input() public accountId: number;

    public isInitialised = false;
    public accounts: IBusinessAccountDetailsDto[] = [];
    public BUSINESS_ACCOUNT_STATUS_ENUM = BUSINESS_ACCOUNT_STATUS_ENUM;

    constructor(
        public readonly permissionsPermissions: PermissionsPermissions,
        protected readonly businessAccountLogicService: BusinessAccountLogicService,
        protected readonly navigationService: NavigationService,
    ) {
    }

    public ngAfterViewInit(): void {
        this.businessAccountLogicService.getChildAccounts(this.accountId).subOnce(result => {
            this.isInitialised = true;
            this.accounts = result;
        });
    }

    public onViewAccountClicked(account: IBusinessAccountDetailsDto): void {
        this.navigationService.redirectTo(`/business-accounts/edit/${account.id}/details`);
    }
}
