import { Component, Input } from '@angular/core';

@Component({
    selector: 'cb-td-currency[value]',
    templateUrl: './currency.component.html',
    styleUrls: ['./../table-cell.scss', 'currency.component.scss']
})
export class TdCurrencyComponent {
    @Input() public readonly value: string | number;
    @Input() public readonly gstMode: null | 'addGst' | 'removeGst';
}
