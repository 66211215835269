import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ISlotTagsLogicService } from '@app/logic/slot-tags/interfaces/i.slot-tags.logic-service';
import { ISlotTagMappedItem } from '@app/logic/slot-tags/interfaces/i.slot-tag.mapped';
import { ITagDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-slot-tag-dialog',
    templateUrl: './slot-tags-dialog.component.html',
    styleUrls: ['./slot-tags-dialog.component.scss']
})
export class SlotTagsDialogComponent extends BaseDialogFormViewDirective<ITagDto, ISlotTagMappedItem, ISlotTagsLogicService> {

    public isSystemTag: boolean;

    // There are no slot tag keys yet so just commenting out for now
    // public readonly userTags: IEnumLookup<UserTagConstantsConstId>[];

    constructor(
        public dialogRef: MatDialogRef<SlotTagsDialogComponent>,
        public toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public readonly data: { mappedItem: ISlotTagMappedItem; existingTags: string[] }
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.isSystemTag = this.mappedItem.isSystemTag;

        if (!this.data.existingTags) {
            this.data.existingTags = [];
        }
        // this.userTags = USER_TAG_CONSTANTS_CONST.toLookup().filter(x => !this.data.existingTags.includes(x.id));
    }
}
