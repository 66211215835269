import { BaseMappedItem } from '@app/logic/base/base-mapped-item';
import { DtoProp } from '@app/logic/base/dto-prop.decorator';
import { IIdAndNameDto, IQuoteLineDto, ISkinnyOfferingDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { IQuoteLineMappedItem } from './interfaces/i.quote-line.mapped';
import { IQuoteLineLogicService } from './interfaces/i.quote-line.logic.service';


export class QuoteLineMappedItem
    extends BaseMappedItem<IQuoteLineDto, IQuoteLineMappedItem, IQuoteLineLogicService>
    implements IQuoteLineMappedItem {

    constructor(
        sourceData: IQuoteLineDto,
        logicService: IQuoteLineLogicService) {
        super(sourceData, logicService, QuoteLineMappedItem);
    }

    @DtoProp public costTypeId: number;
    @DtoProp public costTypeLabel: string;
    @DtoProp public costNatureId: number;
    @DtoProp public costNatureLabel: string;
    @DtoProp public specGroupId: number;
    @DtoProp public specGroupLabel: string;
    @DtoProp public amount: number;
    @DtoProp public allocatedAmount: number;
    @DtoProp public assignedAmount: number;
    @DtoProp public description: string;
    @DtoProp public quantity: number;
    @DtoProp public gstApplies: boolean;
    @DtoProp public reference: string;
    @DtoProp public offeringId: number;
    @DtoProp public lotSpecItemId: number;
    @DtoProp public lotSpecItemIsDeleted: boolean;
    @DtoProp public changeOptionLineId: number;
    @DtoProp public offering: ISkinnyOfferingDto;
    @DtoProp public quoteId: number;
    @DtoProp public quoteNumber: string;
    @DtoProp public sortOrder: number;
    @DtoProp public removedDate: string;
    @DtoProp public isRemoved: boolean;
    @DtoProp public finalisedDate: string;
    @DtoProp public isFinalised: boolean;
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public lotSpecGroupId: number;
    @DtoProp public slot: IIdAndNameDto;
    @DtoProp public purchaseOrderOnly: boolean;
    @DtoProp public id: number;
    @DtoProp public includeInCreateSchedule: boolean;
    @DtoProp public includeInColourSchedule: boolean;
    @DtoProp public manualColourEntryRequired: boolean;
    @DtoProp public clientFriendlyDescription: string;

    public abandonQuoteLine(): Observable<boolean> {
        return this.$logicService
            .abandonQuoteLine(this.id);
    }
}
