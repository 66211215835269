import { LeadStatusEnumId, LEAD_STATUS_ENUM, LotContractTypeEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { find } from 'lodash';
import { Observable, tap } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { ISwapMap } from '../base/interfaces/i.swap-map';
import { SwapMap } from '../base/swap-map';
import { IContactDto } from '../contacts/interfaces/i.contact.dto';
import { leadSearchDto } from './dtos/lead-search.dto';
import { ILeadLogicService } from './interfaces/i.lead-logic.service';
import { ILeadSearchDto } from './interfaces/i.lead-search.dto';
import { ILeadDto } from './interfaces/i.lead.dto';
import { ILeadLostVMDto } from './interfaces/i.lead.lost.vm.dto';
import { ILeadLotInterestDto } from './interfaces/i.lead.lot.interest.dto';
import { ILeadMappedItem } from './interfaces/i.lead.mapped';
import { IQualifyLeadDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/QualifyLeadDto';

export class LeadMappedItem
    extends BaseMappedItem<ILeadDto, ILeadMappedItem, ILeadLogicService>
    implements ILeadMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public contractType: LotContractTypeEnumId;
    @DtoProp public lostDate: string;
    @DtoProp public name: string;
    @DtoProp public status: LeadStatusEnumId;
    @DtoProp public contacts: IContactDto[];
    @DtoProp public mainContact: IContactDto;
    @DtoProp public mainContactId: string;
    @DtoProp public lotIds: number[];
    @DtoProp public interests: ILeadLotInterestDto[];
    @DtoProp public bank: string;
    @DtoProp public budget: number;
    @DtoProp public hasApproval: boolean;
    @DtoProp public hasFinance?: boolean;
    @DtoProp public financeIsCash: boolean;
    @DtoProp public requireClassicAdvantageCall: boolean;
    @DtoProp public enquiryOrigin: string;
    @DtoProp public showHome: any;
    @DtoProp public showHomeId: number;
    @DtoProp public otherShowHome: string;
    @DtoProp public visitedAShowHome: boolean;
    @DtoProp public enquiryOriginOn: string;
    @DtoProp public callbackDate: string;
    @DtoProp public callbackDateObject: string;
    @DtoProp public referredById: string;
    @DtoProp public referredBy: string;
    @DtoProp public referredByPhone: string;
    @DtoProp public referredByMobilePhone: string;
    @DtoProp public referredByHomePhone: string;
    @DtoProp public notes: any[];
    @DtoProp public buildingConsultantId: string;
    @DtoProp public buildingConsultantName: string;
    @DtoProp public accountId: number;
    @DtoProp public lostReason: number;
    @DtoProp public leadLostCompany: string;
    @DtoProp public canQualify: boolean;
    @DtoProp public stepFinanceInfoComplete: boolean;
    @DtoProp public stepDesignAndBuildComplete: boolean;
    @DtoProp public stepClientDetailsComplete: boolean;
    @DtoProp public stepHouseAndLandComplete: boolean;
    @DtoProp public stepEnquiryOriginComplete: boolean;
    @DtoProp public qualifiedDate: string;
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;
    @DtoProp public statusLabel: string;

    public get noteEntityId(): number {
        return this.id;
    }

    public get taskEntityId(): number {
        return this.id;
    }

    public get noteEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    public get taskEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    public get documentEntityId(): number {
        return this.id;
    }

    public get documentEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    public get historyEntityUri(): string {
        return this.$logicService.$baseUri;
    }

    @SwapMap<ILeadSearchDto, ILeadDto>({
        dto: leadSearchDto,
        up: (target, src) => {
            (target as any).id = src.id;
            target.name = src.name;
            target.qualifiedDate = src.qualifiedDate;
            target.callbackDate = src.rawCallbackDate;
            target.statusLabel = src.status;
            target.updatedDate = src.updatedDate;
            target.createdDate = src.createdDate;
            target.contractType = src.contractType;
            target.callbackDate = src.callbackDate;
            target.buildingConsultantId = src.buildingConsultantId;
            target.buildingConsultantName = src.buildingConsultant;
        },
        down: (target, src) => {
            target.id = src.id;
            target.name = src.name;
            target.qualifiedDate = src.qualifiedDate;
            target.rawCallbackDate = src.callbackDate;
            target.status = LEAD_STATUS_ENUM[src.status];
            target.updatedDate = src.updatedDate;
            target.createdDate = src.createdDate;
            target.contractType = src.contractType;
            target.callbackDate = src.callbackDate;
            target.buildingConsultantId = src.buildingConsultantId;
            target.buildingConsultant = src.buildingConsultantName;
            // skipped: target.businessEntitities
        }
    }) public readonly leadSearchDto: ISwapMap<ILeadSearchDto, ILeadDto>;

    constructor(
        sourceData: ILeadDto,
        logicService: ILeadLogicService
    ) {
        super(
            sourceData,
            logicService,
            LeadMappedItem
        );
    }

    public addContactToLead(contactId: string): Observable<ILeadDto> {
        return this.$logicService.addContactToLead(contactId, this.id);
    }

    public getReadOnlyPrimaryInterest(): ILeadLotInterestDto {
        return find(this.interests, { isPrimary: true });
    }

    public isQualified(): boolean {
        return this.status === LEAD_STATUS_ENUM.Qualified;
    }

    public qualify(data: IQualifyLeadDto): Observable<ILeadDto> {
        return this.$logicService.qualify(this.id, data)
            .pipe(
                tap(x => x && this.$updateThisAndOriginal(x))
            );
    }

    public isHotOrCold(): boolean {
        return this.isHot() || this.isCold();
    }

    public isHot(): boolean {
        return this.status === LEAD_STATUS_ENUM.Hot;
    }

    public isCold(): boolean {
        return this.status === LEAD_STATUS_ENUM.Cold;
    }

    public setLeadContactAsMainContact(contactId: string): void {
        this.$logicService.setLeadContactAsMainContact(this.id, contactId).subOnce(lead => {
            this.$updateThisAndOriginal(lead);
        });
    }

    public setPrimaryLotInterest(interestId: number): void {
        this.$logicService.setPrimaryLotInterest(this.id, interestId).subOnce(lead => {
            this.$updateThisData(lead);
        });
    }

    public removeContactFromLead(contactId: string): void {
        this.$logicService.removeContactFromLead(this.id, contactId).subOnce(lead => {
            this.$updateThisAndOriginal(lead);
        });
    }

    public removeInterest(interestId: number): void {
        this.$logicService.removeInterest(this.id, interestId)
            .subOnce(lead => this.$updateThisData(lead));
    }

    public addUpdateInterest(interest: ILeadLotInterestDto): void {
        interest = this.mapInterestForSave(interest);
        this.$logicService
            .addUpdateInterest(this.id, interest)
            .subOnce(lead => this.$updateThisData(lead));
    }

    public addUpdateInterestObservable(interest: ILeadLotInterestDto): Observable<ILeadDto> {
        interest = this.mapInterestForSave(interest);
        return this.$logicService.addUpdateInterest(this.id, interest);
    }

    private mapInterestForSave(interest: ILeadLotInterestDto): ILeadLotInterestDto {
        const result = {
            buildTypeId: interest.buildTypeId,
            buildingConsentCouncilId: interest.buildingConsentCouncilId,
            contractType: interest.contractType,
            developerProject: interest.developerProject,
            id: interest.id,
            isPrimary: interest.isPrimary,
            locationId: interest.locationId,
            lotId: interest.lot != null ? interest.lot.id : undefined,
            newLot: undefined,
            lotNumber: interest?.lot?.lotNumber,
            newLotOwned: undefined,
            roadDirection: interest.lot != null ? interest.lot.roadDirection : undefined
        };

        result.newLot = interest.lot != null ? interest.lot.lotAddress : undefined;
        result.newLotOwned = interest.lot != null ? interest.lot.isOwned : undefined;
        if (result.newLotOwned == null) {
            result.newLotOwned = false;
        }

        return result as any;
    }

    public lost(data: ILeadLostVMDto): Observable<ILeadDto> {
        return this.$logicService.lost(data, this.id);
    }
}
