import { PermissionsPermissions } from '@app/core/permissions';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import {SupplyTypeDialogComponent} from '@app/views/settings/pages/supply-types/supply-type-dialog/supply-type-dialog.component';
import {ISupplyTypeTreeMappedItem} from '@app/logic/supply-types/interfaces/i.supply-type-tree.mapped';
import { ISupplyTypeTreeDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-supply-types-table',
    templateUrl: './supply-types-table.component.html',
    styleUrls: ['./supply-types-table.component.scss']
})
export class SupplyTypesTableComponent {

    public readonly columns = [
        { label: '', width: 48, },
        { label: 'Supply Type' },
        { label: 'Compliance Registrations' },
        { label: 'Active', width: 100, },
        { label: '' }
    ];

    @Input() public readonly supplyTypes: ISupplyTypeTreeMappedItem[];
    @Input() public readonly loaded: boolean = true;
    @Input() public readonly expanded: { [supplyTypeId: number]: boolean } = {};
    @Output() public readonly reloadSupplyTypes = new EventEmitter<void>();

    constructor(
        public readonly permissions: PermissionsPermissions,
        private readonly cbDialog: CbDialogService,
    ) { }

    public editSupplyType(supplyType: ISupplyTypeTreeMappedItem): void {
        const originalParentId = supplyType.parentId;
        this.cbDialog.open(SupplyTypeDialogComponent, {
            data: {
                mappedItem: supplyType.$clone(),
            },
            minWidth: '50%',
        })
            .afterClosed()
            .subOnce((result: false | ISupplyTypeTreeDto) => {
                if (result !== false && originalParentId !== result.parentId) {
                    this.reloadSupplyTypes.emit();
                }
            });
    }

    public orderSupplyTypes(arr: ISupplyTypeTreeMappedItem[] = []): ISupplyTypeTreeMappedItem[] {
        return [...arr].sort((a, b) => a.label > b.label ? 1 : a.label === b.label ? 0 : -1);
    }
}
