import {IBusinessAccountSearchDto} from '@app/logic/business-accounts/interfaces/i.business-account-search.dto';
import {Observable} from 'rxjs';
import {takeOne} from 'cb-hub-lib';
import {Injectable} from '@angular/core';
import {SearchService} from './base.search.service';
import {BusinessAccountLogicService} from '@app/logic/business-accounts';
import { IBusinessAccountDocumentDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Injectable()
export class BusinessAccountsSearchService extends SearchService {

    constructor(
        public readonly businessAccountsLogic: BusinessAccountLogicService,
    ) {
        super();
    }

    public getResults(query: string, currentpage: number): Observable<IBusinessAccountDocumentDto[]> {
        return new Observable<IBusinessAccountDocumentDto[]>((subscriber) => {
            if (!this.searchResultsLoaded) {
                this.searchResultsLoaded = true;

                const resultsObservable = this.businessAccountsLogic.$getSearchList({
                    query,
                    currentpage,
                    ...this.extraSearchParams
                });

                if (!resultsObservable) {
                    return;
                }

                resultsObservable
                    .subOnce(x => {
                        this.setSearchResults<IBusinessAccountDocumentDto>(x);
                        subscriber.next(this.searchResults$.value as IBusinessAccountDocumentDto[]);
                        subscriber.complete();
                    });
            } else {
                subscriber.next(this.searchResults$.value as IBusinessAccountDocumentDto[]);
                subscriber.complete();
            }
        });
    }

    public readonly doSearch = (query: string, page: number): Observable<IBusinessAccountSearchDto[]> => {
        this.searchResultsLoaded = false;
        return this.getResults(query, page).pipe(takeOne());
    };
}
