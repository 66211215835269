<form #addProductToBundleForm="ngForm"
      class="cb-margin-3x flex-col flex">
    <div class="cb-margin start-center flex-row">
        <cb-select label="Lot Status"
                   class="cb-margin flex"
                   name="lotStatus"
                   [(ngModel)]="userCacheService.productItemUsageLotSpecSearch.data.lotStatus"
                   [options]="lotStatuses">
        </cb-select>
        <cb-select label="Job Status"
                   class="cb-margin flex"
                   name="jobStatus"
                   [(ngModel)]="userCacheService.productItemUsageLotSpecSearch.data.jobStatus"
                   [options]="jobStatuses">
        </cb-select>
        <cb-checkbox name="HasClientSale"
                     class="cb-margin"
                     style="height: 20px"
                     label="Has Client Sale"
                     [(ngModel)]="userCacheService.productItemUsageLotSpecSearch.data.hasClientSale">
        </cb-checkbox>
    </div>
    <cb-table label="Lot Specification Items"
              #infiniteScrollTable
              maxHeightOffset="350"
              [stringColumns]="['Job Number', 'Business Entity', 'Project', 'Project Release', 'Lot Status', 'Job Status', 'Client Sale', '']"
              [loaded]="true"
              infiniteScroll
              [immediateCheck]="false"
              [infiniteScrollContainer]="infiniteScrollTable?.infiniteScrollContainer"
              (scrolled)="doSearch()">
        <tbody cbTableBody>
            <tr *ngFor="let lotSpec of searchResults">
                <cb-td-text [value]="lotSpec?.jobNumber"></cb-td-text>
                <cb-td-text [value]="lotSpec?.businessEntityName"></cb-td-text>
                <cb-td-text [value]="lotSpec?.projectName"></cb-td-text>
                <cb-td-text [value]="lotSpec?.projectReleaseName"></cb-td-text>
                <cb-td-text [value]="lotSpec?.lotStatusName"></cb-td-text>
                <cb-td-text [value]="lotSpec?.jobStatus"></cb-td-text>
                <cb-td-text [value]="lotSpec?.clientSaleNumber"></cb-td-text>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <button mat-icon-button
                            (click)="viewLot(lotSpec)">
                        <mat-icon>pageview</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </cb-table>
</form>
