import { HttpResponse } from '@angular/common/http';
import { CurrentUserService } from '@app/core/authentication/current.user';
import {
    DocumentStatusEnumId, DOCUMENT_APPROVAL_TYPE_ENUM,
    DOCUMENT_REVIEW_STATUS_ENUM, IDocumentReviewDto, IDocumentTypeDto, IDocumentUploadDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable, tap } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { Computed } from '../base/computed-prop.decorator';
import { DepInject } from '../base/dep-inject.decorator';
import { DtoProp } from '../base/dto-prop.decorator';
import { IClonedMappedItemSetup } from '../base/interfaces/i.cloned-mapped-item';
import { IDocumentGroupDto } from './interfaces/i.document-group.dto';
import {
    IDocumentDto,
    IDocumentEntityDto
} from './interfaces/i.document.dto';
import { IDocumentsLogicService } from './interfaces/i.document.logic.service';
import { IDocumentEntityMappedItem } from './interfaces/i.document.mapped';

export class DocumentEntityMappedItem<DtoType extends IDocumentEntityDto>
    extends BaseMappedItem<IDocumentEntityDto, IDocumentEntityMappedItem, IDocumentsLogicService>
    implements IDocumentEntityMappedItem {
    public entityUri: string;
    public entityId: string | number;

    @DtoProp public readonly id: number;
    @DtoProp public documentId?: number;
    @DtoProp public document: IDocumentDto;
    @DtoProp public primaryDocumentEntityId: number;
    @DtoProp public isLinkedDocument: boolean;
    @DtoProp public documentGroup?: IDocumentGroupDto;
    @DtoProp public documentType?: IDocumentTypeDto;
    @DtoProp public canSetRenewalDate: boolean;
    @DtoProp public defaultMonthsUntilRenewal: number;
    @DtoProp public allowMultiples: boolean;
    @DtoProp public documentRequiredType: number;
    @DtoProp public documentApprovalType: number;
    @DtoProp public documentReview: IDocumentReviewDto;
    @DtoProp public documentStatus: DocumentStatusEnumId;
    @DtoProp public extraInformation: string;

    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;


    @DepInject(CurrentUserService) private readonly currentUser: CurrentUserService;

    @Computed() public get canReview(): boolean {
        let returnValue = false;

        // work out if this document can be reviewed by the current user
        if (this.documentApprovalType !== DOCUMENT_APPROVAL_TYPE_ENUM.NoApprovalRequired
            && this.document
            && this.document.approvalStatuses
            && this.document.approvalStatuses.length > 0) {

            const listOfStatuses = this.document.approvalStatuses;

            returnValue = listOfStatuses.findIndex(approvalStatus => {
                return approvalStatus.documentReviewStatus === DOCUMENT_REVIEW_STATUS_ENUM.None &&
                    this.$logicService.currentUser.hasTag(approvalStatus.needsApprovalByTagKey);
            }) >= 0;
        }

        return returnValue;
    }

    @Computed() public get canUpload(): boolean {
        // work out if uploading over this document is allowed
        return this.allowMultiples === false;
    }

    constructor(
        sourceData: IDocumentEntityDto,
        logicService: IDocumentsLogicService,
        _documentEntityDto?: DtoType | null,
    ) {
        super(
            sourceData,
            logicService,
            DocumentEntityMappedItem,
            {},
            _documentEntityDto,
        );
    }

    protected $cloneConstructor(): IClonedMappedItemSetup<IDocumentEntityDto, IDocumentEntityMappedItem, IDocumentsLogicService> {
        return new DocumentEntityMappedItem(this.$getMappedDtoItem(), this.$logicService, this.$dtoDefinitionOverride) as unknown as
            IClonedMappedItemSetup<IDocumentEntityDto, IDocumentEntityMappedItem, IDocumentsLogicService>;
    }

    public saveDocument(): Observable<IDocumentEntityDto> {
        return this.$logicService
            .saveDocumentEntity(
                this.$getMappedDtoItem()
            )
            .pipe(tap(result => result && this.$updateThisAndOriginal(result)));
    }

    public downloadDocument(): Observable<any> {
        return this.$logicService
            .downloadDocument(this.document.id)
            .pipe(tap(result => result && this.$updateThisAndOriginal(result)));
    }

    public viewDocumentHistory(): Observable<any> {
        return this.$logicService
            .getDocumentPrecursors(this.document.id)
            .pipe(tap(result => result));
    }

    public downloadDocumentSilent(): Observable<HttpResponse<ArrayBuffer>> {
        return this.$logicService
            .downloadDocumentSilent(this.document.id);
    }

    public postDocument(documentReview?: IDocumentReviewDto): Observable<IDocumentEntityDto> {
        const dtoData = this.$getMappedDtoItem();
        const uploadDocumentDto: IDocumentUploadDto = {
            name: dtoData.document.name,
            documentTypeId: dtoData.documentType.id,
            documentGroupId: dtoData.documentGroup.id,
            documentEntityId: dtoData.id,
            file: dtoData.document.uploadedFile,
            description: dtoData.document.description,
            renewalDate: dtoData.document.renewalDate,
            ...(documentReview?.status && { reviewStatus: documentReview.status }),
            ...(documentReview?.comments && { reviewComments: documentReview.comments }),
        };

        if (dtoData.id != null) {
            return this.$logicService
                .reUploadDocumentEntity(uploadDocumentDto)
                .pipe(tap(result => result && this.$updateThisAndOriginal(result)));
        } else {
            return this.$logicService
                .uploadDocumentEntity(this.entityUri, this.entityId, uploadDocumentDto)
                .pipe(tap(result => result && this.$updateThisAndOriginal(result)));
        }

    }

    public deleteDocument(): Observable<IDocumentEntityDto> {
        return this.$logicService
            .deleteDocumentEntity(this.id)
            .pipe(tap(result => result && this.$updateThisAndOriginal(result)));
    }

    public deleteDocumentStub(): Observable<IDocumentEntityDto> {
        return this.$logicService
            .deleteDocumentStub(this.id)
            .pipe(tap(result => result && this.$updateThisAndOriginal(result)));
    }

    public reviewDocument(): Observable<IDocumentEntityDto> {
        return this.$logicService
            .reviewDocumentEntity(
                this.$getMappedDtoItem()
            )
            .pipe(tap(result => result && this.$updateThisAndOriginal(result)));
    }
}
