<div class="content container">
    <div class="header">
        <div class="save-as-tab center-center flex-row">
            <mat-form-field appearance="fill" class="name-field">
                <input matInput style="text-align: center;" [(ngModel)]="viewModel.name" />
            </mat-form-field>
            <div class="center-center flex-row default-checkbox">
                <mat-checkbox [(ngModel)]="viewModel.isDefault">
                    Default
                </mat-checkbox>
            </div>
        </div>

        <button mat-raised-button color="primary" (click)="backToView()" class="cancel-button">
            Cancel
        </button>

        <button mat-raised-button color="primary" (click)="saveView()" class="save-button">
            Save
        </button>
    </div>
    <mat-divider [inset]="true"> </mat-divider>
    <mat-tab-group *ngIf="selectedView || isCreatingNewView">
        <mat-tab label="Column Management" class="flex flex-row">
            <div class="columns-tab start-center flex flex-row">
                <div cdkDropList (cdkDropListDropped)="drop($event)">
                    <div
                        class="column-item"
                        *ngFor="let column of viewModel.columns"
                        cdkDrag
                    >
                        <div cdkDragHandle class="drag-item flex center-center"
                             [ngClass]="{ 'selected': column.selected }"
                        >
                            <mat-icon fontSet="material-symbols-outlined">drag_indicator</mat-icon>
                            <mat-checkbox class="flex" [(ngModel)]="column.selected">
                                {{  WipHelper.formatColumnName(column.name) }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Filters">
            <wip-filters [filters]="viewModel.filters" [displayedColumns]="displayedColumns"></wip-filters>
        </mat-tab>
    </mat-tab-group>
</div>
