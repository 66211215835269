import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { IPreConsentPlanMappedItem } from '@app/logic/pre-consent-plans';
import { FormMode } from '@app/shared/enums/form';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { IPreConsentPlanItemDto, PRECONSENT_PLAN_STATUS_ENUM, PRE_CONSENT_PLAN_ITEM_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { PreConsentItemDialogComponent } from '../pre-consent-item-dialog/pre-consent-item-dialog.component';
import { remove } from 'lodash';

@Component({
    selector: 'cb-pre-consent-items',
    templateUrl: './pre-consent-items.component.html',
    styleUrls: ['./pre-consent-items.component.scss'],
    viewProviders: [
        provideParentForm(),
    ]
})
export class PreConsentItemsComponent {
    @Input() public selectedTabIndex: number;
    @Input() public mappedItem: IPreConsentPlanMappedItem;
    @Input() public showHeaderAndAddButton = true;
    @Input() public showDeleteButton = true;
    @Input() public addAndSave = false;
    @Input() public parentForm: NgForm;

    public FormMode = FormMode;
    public PRE_CONSENT_PLAN_ITEM_STATUS_ENUM = PRE_CONSENT_PLAN_ITEM_STATUS_ENUM;

    constructor(
        public readonly matDialog: MatDialog,
        private readonly cdRef: ChangeDetectorRef) { }

    public addEditItem(formMode: FormMode, item: IPreConsentPlanItemDto, itemIndex = 0): void {
        let nextItemNumber = 1;

        if (this.mappedItem.items) {
            const itemNumbers = this.mappedItem.items.map(i => i.itemNumber);
            if (itemNumbers?.length > 0) {
                const highestItemNumber = Math.max(...itemNumbers);
                nextItemNumber = highestItemNumber + 1;
            }
        }

        if (!item) {
            item = { ...item, itemNumber: nextItemNumber, id: 0 } as IPreConsentPlanItemDto;
        }

        this.matDialog.open(PreConsentItemDialogComponent, {
            data: {
                item,
                mappedItem: this.mappedItem,
                formMode,
                addAndSave: this.addAndSave,
                itemIndex
            },
            panelClass: 'cb-dialog-container',
            minWidth: '40%',
        })
            .afterClosed()
            .subOnce(result => {
                if (result) {
                    this.parentForm?.form.markAsDirty();
                    this.cdRef.detectChanges();
                }
            });
    }

    public deleteItem(mappedItem: IPreConsentPlanItemDto): void {
        mappedItem.isDeleted = true;

        // If this is a new item that has just been deleted, remove it.
        remove(this.mappedItem.items, item => item.isDeleted && item.id === 0);

        this.parentForm.form.markAsDirty();
        this.cdRef.detectChanges();
    }

    public get editDisabled(): boolean {
        return this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.Finalised ||
            this.mappedItem.preConsentPlanStatus.id === PRECONSENT_PLAN_STATUS_ENUM.Cancelled;
    }

    public get editTooltipText(): string {
        switch (this.mappedItem?.preConsentPlanStatus.id) {
            case PRECONSENT_PLAN_STATUS_ENUM.Finalised:
                return 'Uneditable due to a Finalised Pre-Consent Status';
            case PRECONSENT_PLAN_STATUS_ENUM.Cancelled:
                return 'Uneditable due to a Cancelled Pre-Consent Status';
            default:
                return 'Edit Item';
        }
    }
}
