import { Input, Component, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { find, orderBy } from 'lodash';
import { IBusinessAccountDocumentDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-business-account-card',
    templateUrl: './business-account-card.component.html',
    styleUrls: ['./business-account-card.component.scss']
})
export class BusinessAccountCardComponent {
    @Input() public canCreate: boolean;
    @Input() public cursorPointer = true;
    @Input() public containingForm: NgForm;
    @Input() public id: string | number;
    @Input() public modelOptions = { allowInvalid: true };
    @Input() public removeable: boolean;
    @Input() public searchParams: Array<string>;
    @Input() public dto: IBusinessAccountDocumentDto;
    @Input() public cardType: string;
    @Output() public readonly selectionChosenEmitter = new EventEmitter<IBusinessAccountDocumentDto>();

    public regions = [
        { businessEntityId: 1, label: 'Auckland', code: 'AKL', isActive: true, id: 12 },
        { businessEntityId: 2, label: 'Bay of Plenty', code: 'BOP', isActive: true, id: 1 },
        { businessEntityId: 7, label: 'Canterbury', code: 'CTB', isActive: true, id: 91 },
        { businessEntityId: 3, label: 'Northland', code: 'NTH', isActive: true, id: 15 },
        { businessEntityId: 4, label: 'Otago', code: 'OTG', isActive: true, id: 14 },
        { businessEntityId: 5, label: 'Waikato', code: 'WKO', isActive: true, id: 13 },
        { businessEntityId: 6, label: 'Wellington', code: 'WLG', isActive: true, id: 18 }
    ];

    public openSearchDialog: () => any;
    public viewItem: () => any;

    constructor() {
    }

    public selectionChosen(val: IBusinessAccountDocumentDto): void {
        this.selectionChosenEmitter.emit(val);
    }

    public transposeRegions(d: number[]): any {

        const s = [];
        d.forEach((id: number) => {
            const currentRegion = find(this.regions, { id });
            if (currentRegion && currentRegion.isActive) {
                s.push(currentRegion.label);
            }
        });
        return orderBy(s, (item) => item, 'asc').join(', ');
    }

    public getAccountType(): string {
        const types = [];
        if (this.dto.isInstaller) {
            types.push('Installer');
        }
        if (this.dto.isSupplier) {
            types.push('Supplier');
        }
        return types.join(' / ');
    }
}
