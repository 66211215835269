import { Component, EventEmitter, Input, Output , Inject } from '@angular/core';
import {
    COST_TYPE_ENUM,
    ILotSpecScheduleItemDto,
    IScheduleTemplateItemDto, IScheduleTemplateListChildItemDto,
    IScheduleTemplateListItemDto,
    ISkinnyScheduleTemplateItemDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { Subject, Subscription } from 'rxjs';

import { SpecGroupsLogicService } from '@app/logic/spec-groups';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ScheduleTemplatesItemsLogicService } from '@app/logic/schedule-template-items/schedule-template-items.logic.service';
import { ManageScheduleTemplateItemDialogComponent } from '../manage-schedule-template-item-dialog/manage-schedule-template-item-dialog.component';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';
import { ScheduleTemplatesLogicService } from '@app/logic/schedule-templates/schedule-templates.logic.service';
import { IScheduleTemplateMappedItem } from '@app/logic/schedule-templates/interfaces/i.schedule-template.mapped';


interface HouseAreaIdWithItems {
    houseAreaId: number;
    scheduleTemplateItems: IScheduleTemplateListItemDto[];
}

@Component({
    selector: 'cb-schedule-template-items-table',
    templateUrl: './schedule-template-items-table.component.html',
    styleUrls: ['./schedule-template-items-table.component.scss']
})
export class ScheduleTemplateItemsTableComponent {

    public COST_TYPE_ENUM = COST_TYPE_ENUM;
    public parentMappedItem: IScheduleTemplateMappedItem;
    public expandedParents = {} as { [parentId: number]: boolean };

    private _houseAreaIdWithItems: HouseAreaIdWithItems;

    @Input() public set houseAreaIdWithItems(x: HouseAreaIdWithItems) {
        this._houseAreaIdWithItems = x;
    }

    public get houseAreaIdWithItems(): HouseAreaIdWithItems {
        return this._houseAreaIdWithItems;
    }


    private _isEdit: boolean;

    @Input() public set isEdit(x: boolean) {
        this._isEdit = x;
    }

    public get isEdit(): boolean {
        return this._isEdit;
    }


    private cancelChanges$ = new Subscription();
    @Input() public set cancelChanges($event: Subject<void>) {
        this.cancelChanges$.unsubscribe();
        if ($event) {
            this.cancelChanges$ = $event.subscribe(() => {

            });
        }
    }

    @Output() public scheduleItemRemoved = new EventEmitter<IRemovedData>();
    @Output() public scheduleItemChange = new EventEmitter<void>();

    constructor(
        @Inject(SpecGroupsLogicService) public readonly specGroupsService: SpecGroupsLogicService,
        public readonly dialogService: CbDialogService,
        public readonly scheduleTemplatesItemsLogicService: ScheduleTemplatesItemsLogicService,
        public readonly featureToggle: FeatureToggleStatesService,
        public readonly specTemplateLogicService: ScheduleTemplatesLogicService
    ) { }

    public handleScheduleItemAdded(scheduleTemplateItem: ILotSpecScheduleItemDto): void {
        this.expandChildren(scheduleTemplateItem.slotId);
    }

    public ngOnDestroy(): void {
        this.cancelChanges$.unsubscribe();
    }


    public removeItemFromSpecificationTemplate(houseAreaId: number, item: ISkinnyScheduleTemplateItemDto, isParent: boolean): void {
        this.scheduleItemRemoved.emit({ houseAreaId, item, isParent });
    }

    private openDialog(mappedItem: IScheduleTemplateItemDto, parentMappedItem: IScheduleTemplateMappedItem, dialogHeading: string): any {
        return this.dialogService
            .open(
                ManageScheduleTemplateItemDialogComponent,
                {
                    data: {
                        dialogHeading,
                        mappedItem,
                        parentMappedItem: this.parentMappedItem
                    },
                }
            );
    }

    public editItemClickedAlternative(dto: IScheduleTemplateListItemDto | IScheduleTemplateListChildItemDto): void {
        let dialogHeading: string;
        if (dto.parentScheduleTemplateItemId) {
            dialogHeading = 'Edit Child Schedule Item';
        } else {
            dialogHeading = 'Edit Schedule Item';
        }
        this.scheduleTemplatesItemsLogicService.$getMappedItem(dto.id).subOnce(scheduleTemplateItemMappedItem => {
            this.specTemplateLogicService.$getMappedItem(scheduleTemplateItemMappedItem.specTemplateId).subOnce(parentMappedItem => {
                this.parentMappedItem = parentMappedItem;
                this.openDialog(scheduleTemplateItemMappedItem, parentMappedItem, dialogHeading)
                    .afterClosed()
                    .subOnce((result: IScheduleTemplateListItemDto) => {
                        if (result) {
                            Object.assign(dto, result);
                        }
                    });
            });
        });
    }

    private expandChildren(scheduleTemplateItemId: number): void {
        this.expandedParents[scheduleTemplateItemId] = true;
    }

    public onFlyerCheckboxChange(scheduleTemplateListItemDto: IScheduleTemplateListItemDto): void {
        this.scheduleTemplatesItemsLogicService.toggleShowInClientSpecification(scheduleTemplateListItemDto.id).subOnce(x=>{
            this.scheduleItemChange.next();
        });
    }
}

interface IRemovedData {
    houseAreaId: number;
    item: ISkinnyScheduleTemplateItemDto;
    isParent: boolean;
}
