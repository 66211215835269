<mat-tab-group class="viewContainer flex">
    <mat-tab label="Details">
        <ng-template matTabContent>
            <cb-design-concept-details *cbWaitFor="mappedItem"
                                       [mappedItem]="mappedItem"></cb-design-concept-details>
        </ng-template>
    </mat-tab>
    <mat-tab label="Specification">
        <ng-template matTabContent>
            <cb-lot-spec-view *cbWaitFor="mappedItem && lotMappedItem"
                              [lotMappedItem]="lotMappedItem"
                              [isSkinnyView]="true"
                              [lotSpecVersion]="mappedItem.lotSpecVersion"></cb-lot-spec-view>
        </ng-template>
    </mat-tab>
</mat-tab-group>
