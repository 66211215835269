import { Observable } from 'rxjs';
import { Component, OnInit, Inject } from '@angular/core';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IGenericQuestionsLogicService, IGenericQuestionMappedItem } from '@app/logic/generic-questions';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IGenericQuestionDto, ITeamDto, SystemAreaEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { UsersLogicService, IUserDto } from '@app/logic/users';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { IPromised, toPromisedArray } from 'cb-hub-lib';
import { CurrentUserService } from '@app/core/authentication/current.user';

interface IData {
    genericQuestion: IGenericQuestionMappedItem;
    entityId: number;
    lotId: number;
    systemArea: SystemAreaEnumId;
    childItemLabel: string;
    childItems: any[];
    childSystemArea: SystemAreaEnumId;
}

@Component({
    selector: 'cb-generic-question-edit-dialog',
    templateUrl: './generic-question-edit-dialog.component.html',
    styleUrls: ['./generic-question-edit-dialog.component.scss']
})
export class GenericQuestionEditDialogComponent
    extends BaseDialogFormViewDirective<IGenericQuestionDto, IGenericQuestionMappedItem, IGenericQuestionsLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '40%';

    public get mappedItem(): IGenericQuestionMappedItem {
        return this.data.genericQuestion;
    }

    public selectedUserId: string;
    public recipient = {} as { name: string; id: string };
    public teams: IPromised<ITeamDto[]> = toPromisedArray(this.teamsLogic.$getList());
    public recipientTeams: ITeamDto[] = [];
    public currentUserTeams: ITeamDto[] = [];
    public selectedChildItemId: number;

    constructor(
        public readonly dialogRef: MatDialogRef<GenericQuestionEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(UsersLogicService) public readonly userLogic: UsersLogicService,
        @Inject(TeamsLogicService) public readonly teamsLogic: TeamsLogicService,
        @Inject(CurrentUserService) public readonly currentUser: CurrentUserService,
    ) {
        super(dialogRef, toastService);
    }

    public ngOnInit(): void {
        this.setupUsers();
    }

    public recipientChanged(): void {
        if (this.recipient.id) {
            this.userLogic.$getItem(this.recipient.id).subOnce(this.setRecipientTeams);
        }
    }

    public saveMethod(): Observable<IGenericQuestionDto> {
        this.mappedItem.assignedToUserId = this.recipient.id;

        if (!this.mappedItem.id) {
            this.mappedItem.entityId = this.data.entityId;
            this.mappedItem.systemArea = this.data.systemArea;
            this.mappedItem.lotId = this.data.lotId;
            this.mappedItem.childSystemArea = this.data.childSystemArea;
        }

        return this.mappedItem.$save();
    }

    private readonly setRecipientTeams = (user: IUserDto): void => {
        this.teams.$promise.then(() => {
            this.recipientTeams = this.teams.filter(team => user.selectedTeams.includes(team.id));
            if (this.recipientTeams.length > 0 && !this.mappedItem.teamCode) {
                this.mappedItem.teamCode = this.recipientTeams[0].key;
            }
        });
    };

    private readonly setCurrentUserTeams = (teams: ITeamDto[]): void => {
        this.currentUserTeams = teams.filter(team => this.currentUser.teams.includes(team.key));
        if (this.currentUserTeams.length > 0 && !this.mappedItem.createdByTeamCode) {
            this.mappedItem.createdByTeamCode = this.currentUserTeams[0].key;
        }
    };

    private setupUsers(): void {
        this.recipient.name = this.mappedItem.assignedToUserName;
        this.recipient.id = this.mappedItem.assignedToUserId;
        this.recipientChanged();
        this.teams.$promise.then(this.setCurrentUserTeams);
    }

}
