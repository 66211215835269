<ng-container [ngSwitch]="clientSale?.lot?.lotContractTypeId">
    <div class="flex-wrap"
         *ngSwitchCase="LOT_CONTRACT_TYPE_ENUM.HouseAndLand">
        <cb-client-sale-details-card class="flex-33 flex-row"
                                     [clientSale]="clientSale"
                                     [stepNumber]="1"></cb-client-sale-details-card>
        <cb-client-sale-lot-details-card class="flex-33 flex-row"
                                         [clientSale]="clientSale"
                                         [stepNumber]="2"></cb-client-sale-lot-details-card>
        <cb-client-sale-client-account-card class="flex-33 flex-row"
                                            [clientSale]="clientSale"
                                            [stepNumber]="3"></cb-client-sale-client-account-card>
        <cb-client-sale-contract-pricing-card class="flex-33 flex-row"
                                              [clientSale]="clientSale"
                                              [stepNumber]="4"></cb-client-sale-contract-pricing-card>
        <cb-client-sale-sale-purchase-agreement-card class="flex-33 flex-row"
                                                     [clientSale]="clientSale"
                                                     [stepNumber]="5"></cb-client-sale-sale-purchase-agreement-card>
        <cb-client-sale-unconditional-card class="flex-33 flex-row"
                                           [clientSale]="clientSale"
                                           [stepNumber]="6"></cb-client-sale-unconditional-card>
        <cb-client-sale-classic-care-card class="flex-33 flex-row"
                                          [clientSale]="clientSale"
                                          [stepNumber]="7"></cb-client-sale-classic-care-card>
    </div>
    <div class="flex-wrap"
         *ngSwitchCase="LOT_CONTRACT_TYPE_ENUM.DesignAndLand">
        <cb-client-sale-details-card class="flex-33 flex-row"
                                     [clientSale]="clientSale"
                                     [stepNumber]="1">
        </cb-client-sale-details-card>
        <cb-client-sale-lot-details-card class="flex-33 flex-row"
                                         [clientSale]="clientSale"
                                         [stepNumber]="2">
        </cb-client-sale-lot-details-card>
        <cb-client-sale-client-account-card class="flex-33 flex-row"
                                            [clientSale]="clientSale"
                                            [stepNumber]="3">
        </cb-client-sale-client-account-card>
        <cb-client-sale-design-card class="flex-33 flex-row"
                                    [clientSale]="clientSale"
                                    [stepNumber]="4">
        </cb-client-sale-design-card>
        <cb-client-sale-contract-pricing-card class="flex-33 flex-row"
                                              [clientSale]="clientSale"
                                              [stepNumber]="5">
        </cb-client-sale-contract-pricing-card>
        <cb-client-sale-sale-purchase-agreement-card class="flex-33 flex-row"
                                                     [clientSale]="clientSale"
                                                     [stepNumber]="6">
        </cb-client-sale-sale-purchase-agreement-card>
        <cb-client-sale-unconditional-card class="flex-33 flex-row"
                                           [clientSale]="clientSale"
                                           [stepNumber]="7">
        </cb-client-sale-unconditional-card>
        <cb-client-sale-classic-care-card class="flex-33 flex-row"
                                          [clientSale]="clientSale"
                                          [stepNumber]="8">
        </cb-client-sale-classic-care-card>
    </div>
    <div class="flex-wrap"
         *ngSwitchCase="LOT_CONTRACT_TYPE_ENUM.DesignAndBuild">
        <cb-client-sale-details-card class="flex-33 flex-row"
                                     [clientSale]="clientSale"
                                     [stepNumber]="1"></cb-client-sale-details-card>
        <cb-client-sale-lot-details-card class="flex-33 flex-row"
                                         [clientSale]="clientSale"
                                         [stepNumber]="2"></cb-client-sale-lot-details-card>
        <cb-client-sale-client-account-card class="flex-33 flex-row"
                                            [clientSale]="clientSale"
                                            [stepNumber]="3"></cb-client-sale-client-account-card>
        <cb-client-sale-design-card class="flex-33 flex-row"
                                    [clientSale]="clientSale"
                                    [stepNumber]="4"></cb-client-sale-design-card>
        <cb-client-sale-contract-pricing-card class="flex-33 flex-row"
                                              [clientSale]="clientSale"
                                              [stepNumber]="5"></cb-client-sale-contract-pricing-card>
        <cb-client-sale-build-contract-card class="flex-33 flex-row"
                                            [clientSale]="clientSale"
                                            [stepNumber]="6"></cb-client-sale-build-contract-card>
        <cb-client-sale-unconditional-card class="flex-33 flex-row"
                                           [clientSale]="clientSale"
                                           [stepNumber]="7"></cb-client-sale-unconditional-card>
        <cb-client-sale-classic-care-card class="flex-33 flex-row"
                                          [clientSale]="clientSale"
                                          [stepNumber]="8"></cb-client-sale-classic-care-card>
    </div>
    <div class="flex-wrap"
         *ngSwitchCase="LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand">
        <cb-client-sale-details-card class="flex-33 flex-row"
                                     [clientSale]="clientSale"
                                     [stepNumber]="1"></cb-client-sale-details-card>
        <cb-client-sale-lot-details-card class="flex-33 flex-row"
                                         [clientSale]="clientSale"
                                         [stepNumber]="2"></cb-client-sale-lot-details-card>
        <cb-client-sale-client-account-card class="flex-33 flex-row"
                                            [clientSale]="clientSale"
                                            [stepNumber]="3"></cb-client-sale-client-account-card>
        <cb-client-sale-design-card class="flex-33 flex-row"
                                    [clientSale]="clientSale"
                                    [stepNumber]="4"></cb-client-sale-design-card>
        <cb-client-sale-contract-pricing-card class="flex-33 flex-row"
                                              [clientSale]="clientSale"
                                              [stepNumber]="5"></cb-client-sale-contract-pricing-card>
        <cb-client-sale-sale-purchase-agreement-card class="flex-33 flex-row"
                                                     [clientSale]="clientSale"
                                                     [stepNumber]="6"></cb-client-sale-sale-purchase-agreement-card>
        <cb-client-sale-unconditional-card class="flex-33 flex-row"
                                           [clientSale]="clientSale"
                                           [stepNumber]="7"></cb-client-sale-unconditional-card>
        <cb-client-sale-classic-care-card class="flex-33 flex-row"
                                          [clientSale]="clientSale"
                                          [stepNumber]="8"></cb-client-sale-classic-care-card>
    </div>
</ng-container>
