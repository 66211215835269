import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { ISkinnyLeadDto, LEAD_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'cb-lot-lead-list',
    templateUrl: './lot-lead-list.component.html',
    styleUrls: ['./lot-lead-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotLeadListComponent {

    public readonly displayedColumns = [
        'Name',
        'Status',
        'Created',
        'No. Contacts',
        'Main Contact',
        'Interests',
        'Building Consultant',
        'Last Callback',
        ''
    ];
    public lotLeadsLoaded: boolean = false;

    public LEAD_STATUS_ENUM = LEAD_STATUS_ENUM;

    public lotLeads$$: BehaviorSubject<ISkinnyLeadDto[]> = new BehaviorSubject(null);

    public lotMappedItem$$: BehaviorSubject<ILotMappedItem> = new BehaviorSubject(null);

    @Input() public set lotMappedItem(mappedItem: ILotMappedItem) {
        if (mappedItem) {
            this.lotMappedItem$$.next(mappedItem);
            this.lotLeadsLoaded = false;
            this.lotsLogicService.getLotLeads(mappedItem.id).subOnce({
                next: (_leads) => {
                    this.lotLeads$$.next(_leads);
                },
                error: () => {
                    this.lotLeadsLoaded = true;
                },
                complete: () => {
                    this.lotLeadsLoaded = true;
                }
            });
        }
    }

    public get lotMappedItem(): ILotMappedItem {
        return this.lotMappedItem$$?.value;
    }

    constructor(
        private readonly lotsLogicService: LotsLogicService,
        private readonly navigationService: NavigationService) { }


    public viewLead(id: number): void {
        this.navigationService.redirectTo(`leads/${id}/details`);
    }
}
