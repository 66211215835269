import { ISupplyTypeMappedItem } from './interfaces/i.supply-type.mapped';
import { BaseMappedItem } from '../base/base-mapped-item';
import { ISupplyTypesLogicService } from './interfaces/i.supply-types.logic.service';
import { DtoProp } from '../base/dto-prop.decorator';
import { IComplianceRegistrationDto, ISupplyTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';

export class SupplyTypeMappedItem
    extends BaseMappedItem<ISupplyTypeDto, ISupplyTypeMappedItem, ISupplyTypesLogicService>
    implements ISupplyTypeMappedItem {
    @DtoProp public id: number;
    @DtoProp public isActive: boolean;
    @DtoProp public label: string;
    @DtoProp public parentId: number;
    @DtoProp public parentLabel: string;
    @DtoProp public requiredComplianceRegistrations: IComplianceRegistrationDto[];

    constructor(
        sourceData: ISupplyTypeDto,
        logicService: ISupplyTypesLogicService
    ) {
        super(sourceData, logicService, SupplyTypeMappedItem, {
            requiredComplianceRegistrations: [],
            isActive: true
        });
    }
}
