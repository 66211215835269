import { AddressRegionsLogicService } from '@app/logic/address-regions';
import { BaseFormComponentDirective, getBaseFormComponentDirectiveProvider } from '../base-form-component';
import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { takeOne } from 'cb-hub-lib';
import { IAddressDto, IAddressRegionDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-address-new',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
    providers: [
        AddressRegionsLogicService,
        ...getBaseFormComponentDirectiveProvider(AddressComponent),
        provideParentForm()
    ]
})
export class AddressComponent extends BaseFormComponentDirective {
    @Input() public addressTitle: string;
    @Input() public regions: IAddressRegionDto[];
    @Input() public lockRegion: boolean;
    @Input() public edit = true;
    @Input() public hideHeading = false;
    @Input() public hideLabels = true;
    @Input() public hideSweepAndLabels = false;

    public get regionName(): string {
        return this.regions?.find(region => region.id === this.value.region)?.name;
    }

    private regionValue$: BehaviorSubject<number>;

    @Input() public get regionId$(): BehaviorSubject<number> {
        return this.regionValue$;
    }

    public set regionId$(value) {
        this.regionValue$ = value;
    }

    constructor(
        private readonly addressRegionsLogicService: AddressRegionsLogicService) {
        super();
        this.addressRegionsLogicService.$getList().pipe(takeOne()).subscribe({
            next: (addressRegions) => {
                this.regions = addressRegions.filter(x => x.isActive);
            },
            error: (err) => {
                console.error(err);
            }
        });
    }

    public isPopulated(address: IAddressDto): boolean {
        if (!address) {
            return false;
        }
        return (address.street && address.street.length > 0) ||
            (address.suburb && address.suburb.length > 0) ||
            (address.city && address.city.length > 0) ||
            (address.postCode && address.postCode.length > 0) ||
            address.region !== undefined;
    }

    public getIsRequired(): boolean {
        return this.required || this.isPopulated(this.value);
    }

    public clearAllFields(): void {
        for (const prop of Object.getOwnPropertyNames(this.value)) {
            this.value[prop] = undefined;
        }
        this.regionId$?.next(undefined);
    }
}
