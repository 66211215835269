<cb-dialog dialogHeading="{{isEdit() ? 'Edit Quote' : 'Create Quote'}}">
    <form class="flex-col flex"
          #itemForm="ngForm"
          id="itemForm">
        <cb-info-message *ngIf="!isRequiredQuote && displayLotConceptWarning"
                         [message]="'This Lot is not currently locked.'"
                         [icon]="'warning'"
                         [iconClass]="'mat-warn'"></cb-info-message>
        <div class="start-center flex-row flex">
            <cb-business-account-autocomplete-scroller class="cb-margin-right flex"
                                                       label="Supplier"
                                                       name="supplierSearch"
                                                       [(ngModel)]="selectedSupplier"
                                                       [required]="true"
                                                       [requireMatch]="!isRequiredQuote"
                                                       [matchProps]="['id']"
                                                       (searchTextChanged)="supplierSearchTextChanged($event)"
                                                       (optionSelected)="supplierSelected()"
                                                       [status]="expectedStatuses"
                                                       [displayNonActiveStatus]="true">
            </cb-business-account-autocomplete-scroller>
        </div>
        <cb-datepicker *ngIf="isRequiredQuote"
                       name="requiredDate"
                       [(ngModel)]="mappedItem.requiredDate"
                       [required]="true"
                       label="Required By"
                       [pickerType]="'calendar'"></cb-datepicker>
        <div class="start-center flex-row layoutGap-10">
            <cb-datepicker class="flex"
                           *ngIf="!isRequiredQuote"
                           name="quoteDate"
                           [(ngModel)]="mappedItem.quoteDate"
                           [required]="true"
                           label="Quote Date"
                           [pickerType]="'calendar'"></cb-datepicker>
            <cb-datepicker class="flex"
                           name="expiryDate"
                           [(ngModel)]="mappedItem.expiryDate"
                           label="Expiry Date"
                           [pickerType]="'calendar'"></cb-datepicker>
            <button mat-icon-button
                    matTooltip="Clear Expiry Date"
                    (click)="mappedItem.expiryDate = null">
                <mat-icon>delete_sweep</mat-icon>
            </button>
        </div>
        <cb-input type="text"
                  *ngIf="!isRequiredQuote"
                  name="quoteNumber"
                  label="Quote Number"
                  [maxlength]="100"
                  [required]="true"
                  [(ngModel)]="mappedItem.quoteNumber"></cb-input>
        <cb-currency class="flex"
                     label="Quote Total (GST Exclusive)"
                     *ngIf="!isRequiredQuote"
                     type="number"
                     name="quoteTotal"
                     [required]="true"
                     [(ngModel)]="mappedItem.quoteTotal">
        </cb-currency>
        <cb-text-area label="Description - Appears on Quote Cards and Change Records generated for this Quote"
                      name="reasonDescription"
                      [maxlength]="512"
                      [required]="true"
                      [(ngModel)]="mappedItem.description">
        </cb-text-area>
        <cb-select *ngIf="!isRequiredQuote"
                   [required]="true"
                   name="changeSource"
                   label="Change Source"
                   [options]="changeSourcesLookup"
                   [(ngModel)]="mappedItem.changeSource">
        </cb-select>
        <cb-checkbox *ngIf="!displayLotConceptWarning"
                     [(ngModel)]="mappedItem.changeRecordRequired"
                     name="changeRecordRequired"
                     label="Change Record Required?"
                     [readonly]="!canSkipChangeRecordCreation()"></cb-checkbox>
        <span *ngIf="mappedItem.changeRecordRequired">
            <span class="cb-field-label cb-margin-bottom cb-required">Does this quote need to be shown as multiple lines
                on the variation?</span>
            <mat-radio-group class="cb-margin-top flex-col"
                             name="createMultipleChangeRecords"
                             [(ngModel)]="mappedItem.createMultipleChangeRecords"
                             [required]="true">
                <mat-radio-button [value]="true">Yes</mat-radio-button>
                <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
        </span>
        <div *ngIf="!isRequiredQuote"
             class="cb-margin-bottom cb-margin-top flex-col">
            <span class="cb-field-label cb-margin-bottom cb-required">Upload Document</span>
            <cb-drag-and-drop-document class="cb-margin-bottom"
                                       *cbWaitFor="documentEntity"
                                       [(documentEntityMappedItem)]="documentEntity"
                                       [showExistingDocumentInfo]="showExistingDocumentInfo()">
            </cb-drag-and-drop-document>
        </div>
        <footer class="end-center flex-row">
            <button mat-raised-button
                    color="primary"
                    (cbClick)="save()"
                    [clickLimit]="1"
                    [disabled]="itemForm.invalid || (!isDocumentUploaded() && !isRequiredQuote)">
                {{isEdit() ? 'Update' : 'Create'}}
            </button>
            <button mat-raised-button
                    (cbClick)="cancel()">Cancel</button>
        </footer>
    </form>
</cb-dialog>
