<div class="cb-margin flex-col">
    <mat-card class="top-panel start-center flex-row">
        <div class="start-center flex-row md-50 sm- xs- gt-md-33">
            <mat-icon>search</mat-icon>
            <form #searchForm="ngForm">
                <cb-input class="cb-margin-left flex"
                          label="Search"
                          type="text"
                          name="councilRfiSearch"
                          [(ngModel)]="userCacheItem.data.query"
                          [ngModelOptions]="{ standalone: true }"></cb-input>
            </form>
        </div>
        <span class="flex"></span>
        <button mat-raised-button
                color="primary"
                (click)="addCouncilRfi()"
                [disabled]="!lotMappedItem?.canCreateRFI"
                *ngIf="canCreateRFI()">Add Council RFI</button>
    </mat-card>
    <div #scrollContainer
         class="cb-margin-top cb-lot-council-rfi-scroller">
        <div class="flex-wrap"
             infiniteScroll
             (scrolled)="doSearch()"
             [immediateCheck]="false"
             [fromRoot]="true"
             [infiniteScrollContainer]="scrollContainer">
            <cb-council-rfi-list-card class="rfi-list-item md-33 lg-33 sm-50 xl-20 xs-100"
                                      *ngFor="let item of searchResults"
                                      [councilRfiDocumentDto]="item"></cb-council-rfi-list-card>
        </div>
    </div>
</div>
