<div class="absolute-message start-center flex-row"
     *ngIf="!lotSpec?.items?.length && !lotSpec?.colourItems?.length; else noFilterResults">
    <mat-icon class="cb-accent">info_circle</mat-icon>
    <span class="mat-body-1">&nbsp;There are no Items in the Lot Specification</span>
</div>
<ng-template #noFilterResults>
    <div class="absolute-message start-center flex-row"
         *ngIf="!houseAreaItems.length">
        <mat-icon class="cb-accent">info_circle</mat-icon>
        <span class="mat-body-1">&nbsp;No items found</span>
    </div>
</ng-template>
<cdk-virtual-scroll-viewport #viewport
                             cbFillHeight
                             [ngClass]="fullscreen ?'fullscreen' : '' "
                             id="viewport"
                             [itemSize]="50"
                             [minBufferPx]="viewport?.elementRef?.nativeElement?.clientHeight"
                             [maxBufferPx]="viewport?.elementRef?.nativeElement?.clientHeight">
    <mat-accordion [multi]="true"
                   class="accordion cb-padding-bottom">
        <mat-expansion-panel class="panel"
                             *ngFor="let houseArea of houseAreaItems; trackBy: trackBySpecGroup;"
                             [id]="HOUSE_AREA_PIN_PREFIX + houseArea.specGroupId"
                             [(expanded)]="expandedPanels[houseArea.specGroupId]"
                             (expandedChange)="expandedChange()">
            <mat-expansion-panel-header class="cb-padding-top cb-padding-bottom">
                <mat-panel-title>
                    <span class="mat-body-2 cb-margin-top">{{$any(houseArea).label}}</span>
                </mat-panel-title>
                <mat-panel-description class="end-center flex-row">
                    <button mat-icon-button
                            (click)="togglePanelPinned($event, HOUSE_AREA_PIN_PREFIX + houseArea.specGroupId)">
                        <mat-icon svgIcon="pin"
                                  [ngClass]="{'cb-accent' : userCacheItem.silentData[HOUSE_AREA_PIN_PREFIX + houseArea.specGroupId]}">
                        </mat-icon>
                    </button>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <!-- List View -->
            <div class="flex-row flex"
                 *ngIf="listViewEnabled && expandedPanels[houseArea.specGroupId]"
                 (cbAfterInit)="initSpecGroup(houseArea.specGroupId)"
                 (cbOnDestroy)="exitSpecGroup(houseArea.specGroupId)">
                <cb-lot-spec-item-list-table class="flex-col flex"
                                             *ngIf="readyPanels[houseArea.specGroupId]"
                                             [lotSpec]="lotSpec"
                                             [items]="$any(houseArea).items"
                                             (specItemDeleted)="specItemDeleted($event)"
                                             (colourItemDeleted)="colourItemDeleted($any($event))"
                                             (specItemEdited)="specItemEdited($event)"
                                             (colourItemEdited)="colourItemEdited($event)">
                </cb-lot-spec-item-list-table>
            </div>

            <!-- Card View -->
            <div class="flex-wrap flex"
                 *ngIf="!listViewEnabled && expandedPanels[houseArea.specGroupId]"
                 (cbAfterInit)="initSpecGroup(houseArea.specGroupId)"
                 (cbOnDestroy)="exitSpecGroup(houseArea.specGroupId)">
                <ng-container *ngIf="readyPanels[houseArea.specGroupId]">
                    <div class="flex-col md-50 sm-50 xs-100 gt-md-33"
                         *ngFor="let lotSpecItem of $any(houseArea).items; trackBy: trackByItem">
                        <div #itemMarker></div>
                        <div class="flex-row"
                             *ngIf="isSpecItemInViewport(viewport?.elementRef?.nativeElement, itemMarker); else elseBlock">
                            <cb-lot-spec-item-card class="card cb-margin-right cb-margin-top flex"
                                                   *ngIf="!lotSpecItem.isColourItem"
                                                   [mappedItem]="lotSpecItem.item"
                                                   [lotSpec]="lotSpec"
                                                   (itemDeleted)="specItemDeleted($event)"
                                                   (itemEdited)="specItemEdited($event)"></cb-lot-spec-item-card>
                            <cb-lot-spec-colour-item-card class="card cb-margin-right cb-margin-top flex"
                                                          *ngIf="lotSpecItem.isColourItem"
                                                          [mappedItem]="lotSpecItem.item"
                                                          [lotSpec]="lotSpec"
                                                          (itemDeleted)="colourItemDeleted($event)"
                                                          (itemEdited)="colourItemEdited($event)">
                            </cb-lot-spec-colour-item-card>
                        </div>
                        <ng-template #elseBlock>
                            <div class="placeholder-card"></div>
                        </ng-template>
                    </div>
                </ng-container>
            </div>
        </mat-expansion-panel>
        <cb-loading-spinner *ngIf="!houseAreaItems"
                            class="loading-spinner">
        </cb-loading-spinner>
    </mat-accordion>
</cdk-virtual-scroll-viewport>
