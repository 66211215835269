<mat-tab-group class="flex"
               (selectedTabChange)="onTabChanged($event);"
               [selectedIndex]="selectedIndex">
    <mat-tab label="Details">
        <cb-pre-consent-details [mappedItem]="mappedItem"></cb-pre-consent-details>
    </mat-tab>
    <mat-tab label="Items & Changes ({{mappedItem.items?.length || 0}})">
        <cb-pre-consent-items *cbWaitFor="mappedItem"
                              [mappedItem]="mappedItem"
                              [showHeaderAndAddButton]="false"
                              [showDeleteButton]="false"
                              [addAndSave]="true">
        </cb-pre-consent-items>
    </mat-tab>
    <mat-tab label="Files ({{documentCount}})">
        <cb-document *cbWaitFor="mappedItem"
                     (documentsChange)="documentsChanged.emit($event)"
                     (documentCountChange)="documentCount = $event"
                     [entity]="mappedItem"
                     [permissions]="lotDesignPermissions?.getPreconsentDocumentPermissions()"
                     [includeLinked]="true"
                     [systemArea]="SYSTEM_AREA"
                     [enableCheckbox]="true"
                     [noCard]="true"></cb-document>
    </mat-tab>
    <mat-tab label="Questions ({{questionsCount}})">
        <cb-generic-question-list *cbWaitFor="mappedItem"
                                  [entityId]="mappedItem.id"
                                  [systemArea]="SYSTEM_AREA"
                                  [lotId]="mappedItem.lotId"
                                  [edit]="canAddQuestions"
                                  (questionCountChange)="questionsCount = $event">
        </cb-generic-question-list>
    </mat-tab>
</mat-tab-group>
