<cb-dialog [dialogHeading]="!mappedItem.id ? 'New Question' : 'Edit Question'">
    <form class="flex-col flex"
          #questionForm="ngForm">
        <cb-select *ngIf="data.childItems"
                   [label]="data.childItemLabel"
                   name="childEntityId"
                   [(ngModel)]="mappedItem.childEntityId"
                   [options]="data.childItems"
                   [readonly]="mappedItem?.id != null"></cb-select>
        <div class="start-center flex-row">
            <cb-input type="text"
                      label="Current User"
                      name="currentUserName"
                      [ngModel]="currentUser.profile.name"
                      [readonly]="true"></cb-input>
            <span *ngIf="currentUserTeams.length > 1"
                  class="cb-margin-left cb-margin-right">&nbsp;as&nbsp;</span>
            <cb-select *ngIf="currentUserTeams.length > 1"
                       label="Team"
                       name="userTeam"
                       [(ngModel)]="mappedItem.createdByTeamCode"
                       [options]="currentUserTeams"
                       [required]="true"
                       valueProp="key"></cb-select>
        </div>
        <div class="start-center flex-row">
            <cb-user-autocomplete-scroller label="Question To"
                                           name="questionTo"
                                           [(ngModel)]="recipient"
                                           [required]="true"
                                           (change)="recipientChanged()"
                                           [active]="true"></cb-user-autocomplete-scroller>
            <span class="cb-margin-left cb-margin-right"
                  *ngIf="recipientTeams.length > 1">&nbsp;as&nbsp;</span>
            <cb-select *ngIf="recipientTeams.length > 1"
                       label="Team"
                       name="recipientTeam"
                       [(ngModel)]="mappedItem.teamCode"
                       [required]="true"
                       [options]="recipientTeams"
                       valueProp="key"></cb-select>
        </div>
        <cb-text-area label="Question"
                      name="question"
                      [(ngModel)]="mappedItem.request"
                      [maxlength]="1024"
                      [required]="true"></cb-text-area>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="!recipient.name"
                (cbClick)="save()"
                [clickLimit]="1">{{!mappedItem.id ? 'Create' : 'Save'}}</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </footer>
</cb-dialog>
