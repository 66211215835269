<cb-dialog dialogHeading="Convert SSR to Manual Order">
    <form #convertToManualOrderForm="ngForm">
        <div class="cb-margin flex-row">
            <div class="flex-col flex">
                <cb-select label="Reason"
                           name="manualOrderReason"
                           [(ngModel)]="orderReasonId"
                           [options]="manualOrderReasonList"
                           [required]="true">
        </cb-select>
                <cb-text-area label="Reason Description"
                              name="reasonDescription"
                              [(ngModel)]="orderReason">
        </cb-text-area>
            </div>
        </div>
    </form>
    <footer class="end-center flex-row">
        <button mat-raised-button
                color="primary"
                [disabled]="convertToManualOrderForm.pristine || convertToManualOrderForm.invalid"
                (click)="saveDialog()">Yes
    </button>
        <button mat-raised-button
                (click)="cancel()">No</button>
    </footer>
</cb-dialog>
