import {Component} from '@angular/core';
import { HISTORY_SEARCH_ENTITY } from '@app/shared/enums/history/history-search-entity.enum';

@Component({
    selector: 'cb-product-history-tab',
    templateUrl: './product-history-tab.component.html',
    styleUrls: ['./product-history-tab.component.scss']
})
export class ProductHistoryTabComponent {

    constructor() { }

    protected readonly HISTORY_SEARCH_ENTITY = HISTORY_SEARCH_ENTITY;
}
