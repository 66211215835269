// header-control.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface HeaderButton {
    element: HTMLElement;
    onClick: () => void;
}

@Injectable({
    providedIn: 'root'
})
export class HeaderControlService {
    private headerButtonSource = new BehaviorSubject<HeaderButton[]>([]);
    public currentButtons = this.headerButtonSource.asObservable();

    public updateHeaderButtons(buttons: HeaderButton[]): void {
        this.headerButtonSource.next(buttons);
    }

    constructor() { }
}
