import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {CurrentUserService} from '@app/core/authentication/current.user';
import {DesignComplexityLogicService} from '@app/logic/design-complexity';
import {NonWorkingDayLogicService} from '@app/logic/non-working-day';
import {IPreConsentPlanMappedItem} from '@app/logic/pre-consent-plans';
import {provideParentForm} from '@app/shared/providers/provide-parent-form.provider';
import {IDesignComplexityDto, TEAM_CODES_CONST, USER_TAG_CONSTANTS_CONST} from '@classictechsolutions/hubapi-transpiled-enums';
import moment from 'moment';

@Component({
    selector: 'cb-manage-pre-consent-dialog-details',
    templateUrl: './manage-pre-consent-dialog-details.component.html',
    styleUrls: ['./manage-pre-consent-dialog-details.component.scss'],
    viewProviders: [
        provideParentForm(),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManagePreConsentDialogDetailsComponent implements OnInit {

    public designComplexities: IDesignComplexityDto[];
    public designComplexityId: number;

    private _mappedItem: IPreConsentPlanMappedItem;

    @Input() public set mappedItem(_mappedItem: IPreConsentPlanMappedItem) {
        this.designComplexityId = _mappedItem?.designComplexity?.id;
        this._mappedItem = _mappedItem;
    }

    public get mappedItem(): IPreConsentPlanMappedItem {
        return this._mappedItem;
    }

    public readonly DESIGN_TEAM = [TEAM_CODES_CONST.DesignTeamKey];
    public readonly DESIGN_SUPERVISOR = [USER_TAG_CONSTANTS_CONST.DESIGNSUPERVISOR];
    public reviewerUser: { name: string; id: string };

    constructor(
        private readonly designComplexityLogicService: DesignComplexityLogicService,
        private readonly nonWorkingDayLogicService: NonWorkingDayLogicService,
        private readonly currentUserService: CurrentUserService,
        private readonly cdRef: ChangeDetectorRef
    ) { }

    public ngOnInit(): void {
        this.loadDesignComplexities();

        if (this.mappedItem.reviewerId) {
            this.reviewerUser = {
                id: this.mappedItem.reviewerId,
                name: this.mappedItem.reviewerName
            };
        }

        this.cdRef.detectChanges();
    }

    private loadDesignComplexities(): void {
        this.designComplexityLogicService.$getList().subOnce(result => {
            this.designComplexities = result;
        });
    }

    public updatedDesignComplexity(): void {
        if (this.designComplexityId) {
            const selectedDesignComplexity = this.designComplexities.find(designComplexity => designComplexity.id === this.designComplexityId);
            this.mappedItem.designComplexity = selectedDesignComplexity;
            this.mappedItem.estimatedHours = selectedDesignComplexity.targetWorkHours;
            const startDate = this.mappedItem.createdDate != null ? this.mappedItem.createdDate : moment().format();
            this.nonWorkingDayLogicService.getDueDate(startDate, selectedDesignComplexity.targetCompletionHours).subOnce((result) => {
                this.mappedItem.dueDate = result;
            });
        }
    }

    public userIsInDesignTeam(): boolean {
        return this.currentUserService.isDesignTeam();
    }

    public designReviewRequiredChanged(): void {
        if (!this.mappedItem.designReviewRequired) {
            this.mappedItem.reviewerId = undefined;
            this.mappedItem.reviewerName = undefined;
        }
    }

    public reviewerChanged(): void {
        if (this.reviewerUser) {
            this.mappedItem.reviewerId = this.reviewerUser.id;
            this.mappedItem.reviewerName = this.reviewerUser.name;
        }
    }
}
