import { Component, Input, OnInit } from '@angular/core';
import { UsersLogicService } from '@app/logic/users/users.logic.service';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { IBusinessEntityMappedItem } from '@app/logic/business-entity/interfaces/i.business-entity.mapped';
import { NgForm } from '@angular/forms';
import { ToastService } from '@app/core/services/toast/toast.service';
import { Observable } from 'rxjs';
import { BuildContingencyRecordsLogicService } from '@app/logic/build-contingency-record/build-contingency-records-logic.service';
import { BuildContingencyRecordMappedItem } from '@app/logic/build-contingency-record/build-contingency-record.mapped';
import { BuildContingencyRecordDialogComponent } from './business-entity-contingency-dialog/business-entity-contingency-dialog.component';
import { IBuildContingencyRecordsLogicService } from '@app/logic/build-contingency-record/interfaces/i.build-contingency-records.logic.service';
import { NOTIFIABLE_VALUE_DIRECTION_ENUM, NOTIFIABLE_VALUE_TYPE_ENUM, NOTIFIABLE_VALUE_SCALE_ENUM, IBuildContigencyRecordDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';

@Component({
    selector: 'cb-business-entity-build-contingency-records',
    templateUrl: './business-entity-contingencies.component.html',
    styleUrls: ['./business-entity-contingencies.component.scss'],
    providers: [
        UsersLogicService,
        BuildContingencyRecordsLogicService
    ]
})
export class BusinessEntityBuildContingencyRecordsComponent
    extends BaseSimpleListViewDirective<IBuildContigencyRecordDto, IBuildContingencyRecordsLogicService>
    implements OnInit {
    @Input() public mappedItem: IBusinessEntityMappedItem;
    @Input() public businessEntityForm: NgForm;

    public readonly NOTIFIABLE_VALUE_TYPE_ENUM = NOTIFIABLE_VALUE_TYPE_ENUM;
    public readonly NOTIFIABLE_VALUE_SCALE_ENUM = NOTIFIABLE_VALUE_SCALE_ENUM;
    public readonly NOTIFIABLE_VALUE_DIRECTION_ENUM = NOTIFIABLE_VALUE_DIRECTION_ENUM;
    public readonly columns = ['Value Type', 'Scale', 'Direction', 'Amount', ''];

    constructor(
        public readonly toastSerivce: ToastService,
        public readonly cbDialog: CbDialogService,
        public readonly permissionsPermissions: PermissionsPermissions,
        _buildContingencyRecordsLogicService: BuildContingencyRecordsLogicService
    ) {
        super(
            cbDialog,
            _buildContingencyRecordsLogicService,
            BuildContingencyRecordDialogComponent,
            'Build Contingency Configuration',
            BuildContingencyRecordMappedItem,
            permissionsPermissions,
            false
        );
    }

    public ngOnInit(): void {
        this.otherData = { businessEntityId: this.mappedItem.id };
        if (this.mappedItem.id > 0) {
            this.getSearchResults().subOnce(x => {
                this.handleSearchResults(x);
            });
        }
    }

    public deleteClicked(item: IBuildContigencyRecordDto): void {
        this.deleteItemClicked(item, {
            heading: 'Build Contingency Configuration',
            message: 'Are you sure you want to delete this Build Contingency Configuration?'
        });
    }

    public getSearchResults(): Observable<Array<IBuildContigencyRecordDto>> {
        return this.logicService.getByBusinessEntity(this.mappedItem.id);
    }
}
