import {
    BuildProgrammeActivityStatusEnumId,
    ISkinnyBusinessAccountDto,
    ManualOrderReasonEnumId,
    SsrStateEnumId,
    SSR_STATE_ENUM,
    ILookupDto,
    IBuildProgrammeActivityRulesDto,
    IBuildProgrammeActivityPurchaseOrderSummaryDto,
    ISkinnyBuildActivityDto,
    IBuildProgrammeActivitySupplierChangeDetailsDto,
    IBuildProgrammeActivityAffectedBySupplierChangeDto,
    IBuildProgrammeActivityDto,
    DateCalcTypeEnumId, IBuildActivityRelationshipDto, ISsrDetailsDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { map, Observable, Subject, tap } from 'rxjs';
import { BuildProgrammeLogicService } from '@app/logic/build-programme';
import { SsrsLogicService } from '@app/logic/ssrs';
import { LotBuildProgrammeEventService } from '@app/views/lot/build/services/lot-build-programme-event.service';
import { BaseMappedItem } from '../base/base-mapped-item';
import { Computed } from '../base/computed-prop.decorator';
import { DepInject } from '../base/dep-inject.decorator';
import { DtoProp } from '../base/dto-prop.decorator';
import { IClonedMappedItemSetup } from '../base/interfaces/i.cloned-mapped-item';
import {
    IBuildProgrammeActivityUpdateSupplier
} from '../build-programme/interfaces/buildprogramme.model';
import { IPurchaseOrderDto } from '@app/logic/purchase-orders';
import { IBuildProgrammeActivityLogicService } from './interfaces/i.build-programme-activity-logic.service';
import { IBuildProgrammeActivityMappedItem } from './interfaces/i.build-programme-activity.mapped';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';
import { IBuildProgrammeActivityRelationshipsDto } from '@classictechsolutions/hubapi-transpiled-enums/build/module';
import { IBuildProgrammeActivityRelatedActivityDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/BuildProgrammeActivityRelatedActivityDto';

export class BuildProgrammeActivityMappedItem
    extends BaseMappedItem<IBuildProgrammeActivityDto, IBuildProgrammeActivityMappedItem, IBuildProgrammeActivityLogicService>
    implements IBuildProgrammeActivityMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public buildProgrammeActivityIdForPrimaryLot: number;
    @DtoProp public readonly buildProgrammeId: number;
    @DtoProp public readonly parentBuildProgrammeId: number | null;
    @DtoProp public splitFromBuildActivityId: number;
    @DtoProp public buildProgrammeStageId: number;
    @DtoProp public buildStageId: number;
    @DtoProp public statusId: BuildProgrammeActivityStatusEnumId;
    @DtoProp public statusLabel: string;
    @DtoProp public ssrId: number;
    @DtoProp public ssrStateId: SsrStateEnumId;
    @DtoProp public ssrStateLabel: string;
    @DtoProp public ssrComment: string;
    @DtoProp public businessAccount: ISkinnyBusinessAccountDto;
    @DtoProp public estimatedStartDate: string;
    @DtoProp public actualStartDate: string;
    @DtoProp public estimatedEndDate: string;
    @DtoProp public actualEndDate: string;
    @DtoProp public latestPortalSsrDownloadDate: string;
    @DtoProp public hasDownloadedCurrentVersion: boolean;
    @DtoProp public updatedDate: string;
    @DtoProp public isLocked: boolean;
    @DtoProp public isFromManualPurchaseOrder: boolean;
    @DtoProp public manualOrderId: number;
    @DtoProp public manualOrderStatusId: number;
    @DtoProp public sortOrder: number;
    @DtoProp public isComplianceDoneOrNotRequired: boolean;
    @DtoProp public isComplianceDone: boolean;
    @DtoProp public hasUnallocatedQuantity: boolean;
    @DtoProp public costXGroupName: string;
    @DtoProp public costXPercentage: number;
    @DtoProp public templateActivityDuration: number;
    @DtoProp public moveConfirmedDescendants: boolean;
    @DtoProp public tradeTypes: ILookupDto[];
    @DtoProp public supplyTypes: ILookupDto[];
    @DtoProp public isOwnersCare: boolean;
    @DtoProp public ownersCareOwner: string;
    @DtoProp public activity: ISkinnyBuildActivityDto;
    @DtoProp public activityDurationDays: number;
    @DtoProp public rules: IBuildProgrammeActivityRulesDto;
    @DtoProp public isTransformedToManualOrder: boolean;
    @DtoProp public isComplianceRejected: boolean;
    @DtoProp public complianceRequiresReview: boolean;
    @DtoProp public manualOrderSupplier: string;
    @DtoProp public isControlledByParentLot: boolean;
    @DtoProp public hasHorizontalPredecessor: boolean;
    @DtoProp public isBlockLevelActivity: boolean;
    @DtoProp public hasQsConsent: boolean;
    @DtoProp public hasMatchedInvoices: boolean;
    @DtoProp public hasPayments: boolean;
    @DtoProp public lotNumber: string;
    @DtoProp public isActivityFromUnit: boolean;
    @DtoProp public unitConstructionOrder: number;
    @DtoProp public lotId: number;
    @DtoProp public purchaseOrderSummaryDto: IBuildProgrammeActivityPurchaseOrderSummaryDto;
    @DtoProp public isComplianceNotRequiredBecauseSplit: boolean;
    @DtoProp public anyPredecessorsNotControlledByParent: boolean;
    @DtoProp public anyDescendantsControlledByParent: boolean;
    @DtoProp public hasSsrItems: boolean;
    @DtoProp public hasAnySsrs: boolean;
    @DtoProp public hasUnknownRate: boolean;
    @DtoProp public manualOrderCostNature: number;
    @DtoProp public hasVariation: boolean;
    @DtoProp public hasPercentageSplit: boolean;
    @DtoProp public parentLotBuildProgrammeActivityId: number;
    @DtoProp public predecessorId: number;
    @DtoProp public hasActivitySplitFromThis: boolean;
    @DtoProp public isHorizontalPredecessor: boolean;
    @DtoProp public horizontalPredecessorDateCalcType: DateCalcTypeEnumId;

    public CHANGED = new Subject<IBuildProgrammeActivityDto>();

    @Computed()
    public get canEditIsLocked(): boolean {
        return this.ssrStateId === SSR_STATE_ENUM.None
            || this.ssrStateId === SSR_STATE_ENUM.Draft
            || this.ssrStateId === SSR_STATE_ENUM.Confirmed;
    }

    @Computed()
    public get allRulesFalse(): boolean {
        return this.rules != null && !Object.values(this.rules).some(x => x === false);
    }

    @Computed()
    public get supplierName(): string {
        return this.businessAccount?.tradingName ?? this.manualOrderSupplier ?? this.ownersCareOwner;
    }

    @DepInject(BuildProgrammeLogicService) private readonly buildProgrammeLogic: BuildProgrammeLogicService;
    @DepInject(SsrsLogicService) private readonly ssrLogic: SsrsLogicService;
    @DepInject(FeatureToggleStatesService) private readonly featureToggleStateService: FeatureToggleStatesService;

    constructor(
        sourceData: IBuildProgrammeActivityDto,
        logicService: IBuildProgrammeActivityLogicService,
        private readonly lotBuildProgrammeEvents?: LotBuildProgrammeEventService
    ) {
        super(sourceData, logicService, BuildProgrammeActivityMappedItem);
    }

    /** Override this if you need to pass extra args into the clone constructor for this mapped item */
    protected $cloneConstructor(): IClonedMappedItemSetup<IBuildProgrammeActivityDto, IBuildProgrammeActivityMappedItem, IBuildProgrammeActivityLogicService> {
        return new BuildProgrammeActivityMappedItem(this.$getMappedDtoItem(), this.$logicService, this.lotBuildProgrammeEvents) as any;
    }

    public $postLoad = (): void => {
        if (!this.CHANGED) {
            this.CHANGED = new Subject<IBuildProgrammeActivityDto>();
        }
        this.CHANGED.next(this.$getMappedDtoItem());
    };

    public $reload(): Observable<IBuildProgrammeActivityDto> {
        return this.buildProgrammeLogic
            .getBuildProgrammeActivity(this.buildProgrammeId, this.id)
            .pipe(
                tap((response) => {
                    // not using $updateThisAndOriginal here because
                    // - the intention may be to reload only one item
                    // - in general, reloading the clone should not affect the original
                    this.$updateThisData(response);
                })
            );
    }

    public $save(): Observable<any & IBuildProgrammeActivityDto[]> {
        const toSave = this.$getMappedDtoItem();
        this.$preSave(toSave);
        return this.$logicService
            .$saveItem(toSave)
            .pipe(
                tap(this.handleActivityArrayResponse)
            );
    }

    public saveOrder(): Observable<IBuildProgrammeActivityDto> {
        const toSave = this.$getMappedDtoItem();

        return this.$logicService
            .saveBuildProgrammeActivityOrder(toSave.buildStageId, toSave)
            .pipe(
                tap((response) => {
                    this.$updateThisData(response);
                })
            );
    }

    /** Possible responses, any one of the following:
     * - NULL
     * - this updated build programme activity
     * - an entirely different build programme activity
     */
    public toggleControl(): Observable<IBuildProgrammeActivityDto[]> {
        return this.$logicService
            .toggleBuildProgammeActivityControl(this.buildProgrammeId, this.id, !this.isControlledByParentLot)
            .pipe(
                map(results => results.filter(x => x != null)),
                tap(results => {
                    const updated = results.find(x => x.id === this.id);
                    if (!updated) {
                        return;
                    }
                    this.$updateThisAndOriginal(updated);
                })
            );
    }

    public setToAbandoned(): Observable<IBuildProgrammeActivityDto> {
        return this.buildProgrammeLogic
            .setBuildProgrammeActivityToAbandoned(this.buildProgrammeId, this.id)
            .pipe(
                tap((result) => {
                    this.ssrId = null;
                    this.$updateThisAndOriginal(result);
                })
            );
    }

    public completeManualOrder(): Observable<IBuildProgrammeActivityDto> {
        return this.$logicService
            .completeManualOrder(this.id)
            .pipe(
                tap(result => this.$updateThisAndOriginal(result))
            );
    }

    public convertToManualOrder(orderReason: { orderReasonId?: ManualOrderReasonEnumId; orderReason?: string }): Observable<IPurchaseOrderDto> {
        return this.ssrLogic
            .convertToManualOrder(this.ssrId, orderReason)
            .pipe(
                tap(this.handleSsrDetailsResponse)
            );
    }


    public cancelSsr(): Observable<ISsrDetailsDto> {
        return this.ssrLogic
            .cancelSsr(this.ssrId)
            .pipe(
                tap(() => {
                    this.ssrId = null;
                    this.$reload().subOnce();
                })
            );
    }

    public completeSsr(): Observable<ISsrDetailsDto> {
        return this.ssrLogic
            .completeSsr(this.ssrId)
            .pipe(
                tap(this.handleSsrDetailsResponse)
            );
    }

    public confirmSsr(): Observable<ISsrDetailsDto> {
        return this.ssrLogic
            .confirmSsr(this.ssrId)
            .pipe(
                tap(this.handleSsrDetailsResponse)
            );
    }

    public redraftSsr(): Observable<ISsrDetailsDto> {
        return this.ssrLogic
            .redraftSsr(this.ssrId)
            .pipe(
                tap(this.handleSsrDetailsResponse)
            );
    }

    public restartSsr(): Observable<ISsrDetailsDto> {
        return this.ssrLogic
            .restartSsr(this.ssrId)
            .pipe(
                tap(this.handleSsrDetailsResponse)
            );
    }

    public splitActivity(): Observable<IBuildProgrammeActivityDto> {
        return this.$logicService
            .splitActivity(this.buildProgrammeId, this.id)
            .pipe(
                tap((result) => {
                    this.$reload().subOnce();
                    this.lotBuildProgrammeEvents?.ACTIVITY_RECEIVED?.next([result]);
                })
            );
    }

    public downloadPo(): void {
        this.ssrLogic
            .downloadPo(this.ssrId)
            .subOnce();
    }

    public downloadSsr(): void {
        this.ssrLogic
            .downloadSsr(this.ssrId)
            .subOnce();
    }

    public createSsr(): Observable<ISsrDetailsDto> {
        return this.$logicService
            .createSsrForBuildProgrammeActivity(this.id)
            .pipe(
                tap(this.handleSsrDetailsResponse)
            );
    }

    public getRelationships(): Observable<IBuildProgrammeActivityRelationshipsDto> {
        return this.$logicService
            .getActivityRelationships(this.id);
    }

    public clearPredecessorRelationship(predecessorId: number): Observable<IBuildProgrammeActivityDto[]> {
        return this.$logicService
            .clearBuildProgrammeActivityRelationship(this.id, predecessorId)
            .pipe(
                tap(this.handleActivityArrayResponse)
            );
    }

    public updateRelationship(relationshipDto: IBuildActivityRelationshipDto): Observable<IBuildProgrammeActivityRelatedActivityDto> {
        relationshipDto.descendantActivityId = this.id;
        return this.$logicService
            .updateBuildProgrammeActivityRelationship(this.id, relationshipDto)
            .pipe(
                tap(res => this.handleActivityArrayResponse(res.affectedActivties)),
                map(res => res.relatedActivity)
            );
    }

    public setSupplier(supplier: IBuildProgrammeActivityUpdateSupplier): Observable<IBuildProgrammeActivityDto[]> {
        return this.$logicService
            .updateBuildProgrammeActivitySupplier(this.id, supplier)
            .pipe(
                tap(this.handleActivityArrayResponse)
            );
    }

    public getAffectedActivitiesForSupplierChange(dto: IBuildProgrammeActivitySupplierChangeDetailsDto): Observable<IBuildProgrammeActivityAffectedBySupplierChangeDto[]> {
        return this.$logicService
            .getAffectedActivitiesForSupplierChange(this.id, dto)
            .pipe(
                tap(() => this.handleActivityArrayResponse)
            );
    }

    private readonly handleSsrDetailsResponse = (): void => {
        this.$reload().subOnce();

        if (this.featureToggleStateService.isAutoSplitBuildActivitiesEnabled) {
            // We need to refresh the build stage as creating an SSR can create one (or more) split activities and they need to show up
            this.buildProgrammeLogic
                .getIndexedBuildProgrammeActivitiesByLotIdAndStage(this.lotId, this.buildProgrammeStageId)
                .subOnce((results) => {
                    this.lotBuildProgrammeEvents?.ACTIVITY_RECEIVED?.next(results);
                });
        }
    };

    /** handle IBuildProgrammeActivityDto array response */
    private readonly handleActivityArrayResponse = (results: IBuildProgrammeActivityDto[]): void => {
        const newData = results.find(x => x.id === this.id);
        if (newData) {
            this.$updateThisAndOriginal(newData);
        }
        this.lotBuildProgrammeEvents?.ACTIVITY_RECEIVED?.next(results);
    };
}
