import { Component, Inject } from '@angular/core';
import { ISsrMappedItem, SsrsLogicService } from '@app/logic/ssrs';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ISsrsLogicService } from '@app/logic/ssrs/interfaces/i.ssrs.logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ISsrDataDto, MANUAL_ORDER_REASON_ENUM, ManualOrderReasonEnumId } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-convert-to-manual-order-dialog',
    templateUrl: './convert-to-manual-order-dialog.component.html',
    styleUrls: ['./convert-to-manual-order-dialog.component.scss']
})
export class ConvertToManualOrderDialogComponent extends BaseDialogFormViewDirective<ISsrDataDto, ISsrMappedItem, ISsrsLogicService> {
    public static readonly MIN_WIDTH = '33%';

    public orderReasonId: ManualOrderReasonEnumId;
    public orderReason: string;
    public manualOrderReasonList = MANUAL_ORDER_REASON_ENUM.toSelectList();

    constructor(
        public readonly dialogRef: MatDialogRef<ConvertToManualOrderDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {},
        public readonly toastService: ToastService,
        public readonly ssrsLogic: SsrsLogicService,
    ) {
        super(dialogRef, toastService);
    }

    public saveDialog(): void {
        this.dialogRef.close({ orderReasonId: this.orderReasonId, orderReason: this.orderReason });
    }

}
