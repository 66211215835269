import { Injectable, Injector } from '@angular/core';
import { IDesignTeamCounfilRfiSearch } from '@app/core/services/user-cache/user-cache-areas';
import { ICouncilRfiDocumentDto, ICouncilRfiDto, ISkinnyChangeRecordDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable, switchMap, take } from 'rxjs';
import { ICouncilRfiMappedItem } from '.';
import { HttpWrapperService } from '../../core/services/http-wrapper/http-wrapper.service';
import { BaseLogicServiceWithSearch } from '../base/base-logic-service-with-search.component';
import { DocumentsLogicService, IDocumentEntityMappedItem } from '../documents';
import { CouncilRfiMappedItem } from './council-rfi.mapped';
import { ICouncilRfiLogicService } from './interfaces/i.council-rfi-logic.service';


@Injectable()
export class CouncilRfiLogicService extends
    BaseLogicServiceWithSearch<
    ICouncilRfiDto,
    ICouncilRfiMappedItem,
    IDesignTeamCounfilRfiSearch, ICouncilRfiDocumentDto
    > implements ICouncilRfiLogicService {
    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
        private readonly documentsLogic: DocumentsLogicService,
    ) {
        super('councilrfis', CouncilRfiMappedItem);
    }

    public downloadLatestRfiDocument(councilRfiId: number): Observable<void> {
        return this.documentsLogic
            .getMappedDocumentEntities(this.$baseUri, councilRfiId, false, false)
            .pipe(
                switchMap(entities => {
                    const latestDocument = entities.pop();
                    return latestDocument.downloadDocument();
                })
            );
    }

    public getLatestRfiDocument(councilRfiId: number): Observable<IDocumentEntityMappedItem> {
        return this.documentsLogic
            .getMappedDocumentEntities(this.$baseUri, councilRfiId, false, false)
            .pipe(switchMap(i => i), take(1));
    }

    public getChangeRecords(councilRfiId: number): Observable<ISkinnyChangeRecordDto[]> {
        return this.$http.get(`${this.$baseUri}/${councilRfiId}/changerecords`);
    }

    public getByConsentId(lotConsentId: number): Observable<ICouncilRfiDto[]> {
        return this.$http.get(`${this.$baseUri}/consent/${lotConsentId}`);
    }

    public getByLotId(lotId: number): Observable<ICouncilRfiDto[]> {
        return this.$http.get(`${this.$baseUri}/lot/${lotId}`);
    }
}
