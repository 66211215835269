import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import { find } from 'lodash';
import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IBuildActivitiesLogicService } from './interfaces/i.build-activities.logic.service';
import { IBuildActivityMappedItem } from './interfaces/i.build-activity.mapped';
import { IBuildActivityDto, IBuildActivityScheduleDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { BuildActivityRequiredLevelEnumId } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/enums/BuildActivityRequiredLevel';
import { ISkinnyOfferingDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/SkinnyOfferingDto';
import { ITradeTypeDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/TradeTypeDto';
import { ISupplyTypeDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/SupplyTypeDto';
import { IDocumentTypeDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/DocumentTypeDto';
import { IBuildActivityRelatedActivityDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/BuildActivityRelatedActivityDto';
import { IBuildActivityScheduleSpecificationsLineItemDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/BuildActivityScheduleSpecificationsLineItemDto';
import { IBuildActivityScheduleComplianceLineItemDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/BuildActivityScheduleComplianceLineItemDto';
import {
    IBuildActivityScheduleProductsSuppliedByLineItemDto
} from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/BuildActivityScheduleProductsSuppliedByLineItemDto';
import { IBuildActivityScheduleScopeOfWorkLineItemDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/BuildActivityScheduleScopeOfWorkLineItemDto';

export class BuildActivityMappedItem
    extends BaseMappedItem<IBuildActivityDto, IBuildActivityMappedItem, IBuildActivitiesLogicService>
    implements IBuildActivityMappedItem {

    @DtoProp public activityDurationDays: number;
    @DtoProp public requiredComplianceId: number;
    @DtoProp public shouldExcludeRequiredComplianceForDuplicates: boolean;
    @DtoProp public taskAnalysisFormRequiredLevel: BuildActivityRequiredLevelEnumId;
    @DtoProp public labourRates: number[];
    @DtoProp public labourRatesList: ISkinnyOfferingDto[];
    @DtoProp public version: number;
    @DtoProp public buildActivitySchedules: IBuildActivityScheduleDto[];
    @DtoProp public tradeTypes: number[];
    @DtoProp public supplyTypes: number[];
    @DtoProp public tradeTypesList: ITradeTypeDto[];
    @DtoProp public supplyTypesList: ISupplyTypeDto[];
    @DtoProp public documentTypes: number[];
    @DtoProp public documentTypesList: IDocumentTypeDto[];
    @DtoProp public buildActivityRelatedActivities: IBuildActivityRelatedActivityDto[];
    @DtoProp public code: string;
    @DtoProp public name: string;
    @DtoProp public isActive: boolean;
    @DtoProp public retain: number;
    @DtoProp public hasCompliance: boolean;
    @DtoProp public id: number;
    @DtoProp public canPerformPriorToBuildingConsent: boolean;
    @DtoProp public canPerformBeforeQsConsentTakeOffCheck: boolean;
    @DtoProp public excludeFromPracticalCompletion: boolean;
    @DtoProp public showOnClientsConstructionPlan: boolean;
    @DtoProp public canDetermineBuildProgrammeStart: boolean;
    @DtoProp public showDraftActivityToSupplier: boolean;
    @DtoProp public isActivityValuePercentageOfLotSpecItemValue: boolean;
    @DtoProp public lotSpecificationItemOfferingId: number;
    @DtoProp public lotSpecificationItemOffering: ISkinnyOfferingDto;
    @DtoProp public lotSpecificationItemValuePercentage: number;
    @DtoProp public buildProgrammeStageId: number;
    @DtoProp public sortOrder: number;
    @DtoProp public isControlledByParentLot: boolean;
    @DtoProp public isBlockLevelActivity: boolean;

    constructor(
        sourceData: IBuildActivityDto,
        logicService: IBuildActivitiesLogicService
    ) {
        super(sourceData, logicService, BuildActivityMappedItem, {
            version: 1,
            retain: 0,
            buildActivitySchedules: [],
        });
    }

    public getNationalSchedule(): IBuildActivityScheduleDto {
        if (!this.buildActivitySchedules || !this.buildActivitySchedules[0]) {
            this.createActivitySchedule();
        }
        return this.buildActivitySchedules[0];
    }

    public createActivitySchedule(id?: number): IBuildActivityScheduleDto {
        const obj = {
            buildActivityScheduleSpecificationsLineItems: [{ sortOrder: 1, text: '' } as IBuildActivityScheduleSpecificationsLineItemDto],
            businessEntityId: id,
            complianceLineItems: [{ sortOrder: 1, text: '' } as IBuildActivityScheduleComplianceLineItemDto],
            paymentTermsLineItems: [{
                buildActivitySchedulePaymentTermsId: 0,
                id: 0,
                sortOrder: 1,
                text: '',
            }],
            productsSuppliedByLineItems: [{ sortOrder: 1, text: '' } as IBuildActivityScheduleProductsSuppliedByLineItemDto],
            scopeOfWorkLineItems: [{ sortOrder: 1, text: '' } as IBuildActivityScheduleScopeOfWorkLineItemDto],
            shouldHideSsrLineQuantities: false,
            shouldHideSsrLineSubtotals: false
        } as IBuildActivityScheduleDto;
        if (!this.buildActivitySchedules) {
            this.buildActivitySchedules = [];
        }
        this.buildActivitySchedules.push(obj);
        return obj;
    }

    public incrementVersion(): void {
        if (this.version && this.version >= 0) {
            this.version++;
        }
    }

    public getActivityScheduleByRegion(id: number): IBuildActivityScheduleDto {
        const regionalSchedule = find(this.buildActivitySchedules, { businessEntityId: id });
        if (!regionalSchedule) {
            return this.createActivitySchedule(id);
        }
        return regionalSchedule;
    }

    public copyContentFromNational(id: number, prop: string): void {
        this.getActivityScheduleByRegion(id)[prop] = cloneDeepSafe(this.buildActivitySchedules[0][prop]);
    }

    protected $preSave(toSave: IBuildActivityDto): void {
        if (!toSave.taskAnalysisFormRequiredLevel) {
            toSave.taskAnalysisFormRequiredLevel = 0;
        }
    }
}
