import { BaseMappedItem } from '../base/base-mapped-item';
import { IBundleTemplatesLogicService } from './interfaces/i.bundle-templates.logic.service';
import { IBundleTemplateMappedItem } from './interfaces/i.bundle-template.mapped';
import { DtoProp } from '../base/dto-prop.decorator';
import { IBundleTemplateDto, IBundleTemplateItemDto } from '@classictechsolutions/hubapi-transpiled-enums';

export class BundleTemplateMappedItem
    extends BaseMappedItem<IBundleTemplateDto, IBundleTemplateMappedItem, IBundleTemplatesLogicService>
    implements IBundleTemplateMappedItem {
    @DtoProp public readonly id: number;
    @DtoProp public name: string;
    @DtoProp public isActive: boolean;
    @DtoProp public bundleItems: IBundleTemplateItemDto[];
    @DtoProp public createdDate: string;
    @DtoProp public createdByName: string;
    @DtoProp public updatedDate: string;
    @DtoProp public updatedByName: string;

    constructor(
        sourceData: IBundleTemplateDto,
        logicService: IBundleTemplatesLogicService
    ) {
        super(sourceData, logicService, BundleTemplateMappedItem);
    }

    protected $preSave(toSave: IBundleTemplateDto): void {
        toSave.bundleItems = toSave.bundleItems.filter(x => x.name);
    }
}
