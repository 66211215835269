import { Component } from '@angular/core';
import { PermissionsPermissions } from '../../../../core/permissions';
import { TeamsLogicService } from '../../../../logic/teams/teams.logic.service';
import { TeamsDialogComponent } from './components/teams-dialog/teams-dialog.component';
import { TeamsMappedItem } from '@app/logic/teams/teams.mapped';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ITeamsMappedItem } from '@app/logic/teams/interfaces/i.teams.mapped';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { ITeamDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-teams',
    templateUrl: './teams.component.html',
    styleUrls: ['./teams.component.scss'],
    providers: [TeamsLogicService],
})
export class TeamsComponent extends BaseSimpleListViewDirective<ITeamDto, TeamsLogicService> {

    public searchResults: ITeamDto[] = [];
    public searchResultsLoaded = false;
    public displayedColumns: string[] = ['name', 'key', 'active', 'actions'];

    constructor(
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: CbDialogService,
        private readonly teamsLogicService: TeamsLogicService,
    ) {
        super(
            dialog,
            teamsLogicService,
            TeamsDialogComponent,
            'Team',
            TeamsMappedItem,
            permissionsPermissions
        );
    }

    public openDialog(mappedItem: ITeamsMappedItem, dialogHeading: string): MatDialogRef<any> {
        return this.cbDialog
            .open(
                this.dialogComponent,
                {
                    minWidth: '95%',
                    data: {
                        dialogHeading,
                        mappedItem,
                        existingKeys: this.searchResults.map(x => x.key).filter(x => !isNullOrWhiteSpace(x) && mappedItem.key !== x)
                    },
                }
            );
    }
}
