import { ACCOUNT_TYPE_ENUM, CLIENT_ACCOUNT_STATUS_ENUM, CONTACT_STATUS_ENUM, IAddressDto, IContactDto, SALUTATION_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isEmpty, remove } from 'lodash';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IClientAccountMappedItem } from '@app/logic/client-account/interfaces/i.client-account.mapped';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { cleanObjectDeep } from 'cb-hub-lib';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { IContactSearchDto } from '@app/logic/contacts';
import { AddContactDialogComponent } from '@app/views/contacts/add-contact-dialog/add-contact-dialog.component';

@Component({
    selector: 'cb-client-account-edit',
    templateUrl: './client-account-edit.component.html',
    styleUrls: ['./client-account-edit.component.scss'],
    providers: [
        provideParentForm()
    ]
})
export class ClientAccountEditComponent implements OnInit {

    @Input() public account: IClientAccountMappedItem;
    @Output() public accountChange: EventEmitter<IClientAccountMappedItem> = new EventEmitter<IClientAccountMappedItem>();
    @Input() public contacts: IContactDto[];

    public readonly accountTypesLookup = ACCOUNT_TYPE_ENUM.toLookup();
    public readonly accountStatusLookup = CLIENT_ACCOUNT_STATUS_ENUM.toLookup();
    public CONTACT_STATUS_ENUM = CONTACT_STATUS_ENUM;
    public SALUTATION_ENUM = SALUTATION_ENUM;
    public CLIENT_ACCOUNT_STATUS_ENUM = CLIENT_ACCOUNT_STATUS_ENUM;
    private contactsAddedButNotSaved: string[] = [];

    public readonly displayedColumns: string[] = [
        'isMainContact',
        'title',
        'name',
        'email',
        'phoneHome',
        'phoneWork',
        'phoneMobile',
        'address',
        'isStaffMember',
        'status',
        'actions'
    ];

    public dataSource = new MatTableDataSource<IContactDto>([]);

    constructor(
        public readonly cbDialog: CbDialogService,
    ) { }

    public ngOnInit(): void {
        this.dataSource.data = this.contacts;
    }

    public removeContact(id: string, event: MouseEvent): void {
        const index = this.account.contactIds.indexOf(id, 0);
        if (index > -1) {
            this.account.contactIds.splice(index, 1);
            this.accountChange.emit(this.account);
        }

        remove(this.contacts, t => t.id === id);
        remove(this.contactsAddedButNotSaved, t => t === id);
        this.dataSource.data = this.contacts;
    }

    public changeOption(event, contactId): void {
        if (event.checked) {
            this.account.mainContactId = contactId;
        }
    }

    public addContact(): void {
        this.cbDialog.open(AddContactDialogComponent, {
            data: {
                contacts: this.contacts,
                account: this.account,
                dataSource: this.dataSource,
                addedContacts: this.contactsAddedButNotSaved,
            },
            minWidth: 600
        })
            .afterClosed()
            .subOnce(() => {
                this.dataSource.data = this.contacts;
                if (this.contacts?.length === 1) {
                    this.account.mainContactId = this.contacts[0].id;
                }
            });
    }


    public canRemoveContact(contactId: string): boolean {
        if (this.contactsAddedButNotSaved.includes(contactId)) {
            return true;
        } else {
            return contactId !== this.account.mainContactId && this.account.status !== CLIENT_ACCOUNT_STATUS_ENUM.Locked;
        }
    }

    public getFullName(contact: IContactDto | IContactSearchDto): string {
        const name = (contact as IContactSearchDto)?.name;
        const fullContact = contact as IContactDto;
        return name ? name : `${fullContact.firstName} ${fullContact.lastName}`;
    }

    public allowPostalAddressBlank(address: IAddressDto): boolean {
        return isEmpty(address) || isEmpty(cleanObjectDeep(address));
    }
}
